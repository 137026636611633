/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow, @typescript-eslint/naming-convention */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
export type CancelAutochargeEnrollmentMutationVariables = Exact<{
  input: CancelAutochargeEnrollmentInput;
}>;

export type CancelAutochargeEnrollmentMutation = {
  __typename?: 'Mutation';
  cancelAutochargeEnrollment: { __typename?: 'AccountVehicle'; driverId: string };
};

export type CreateAccountOwnerMutationVariables = Exact<{
  input?: InputMaybe<CreateAccountOwnerInput>;
}>;

export type CreateAccountOwnerMutation = {
  __typename?: 'Mutation';
  createAccountOwner: {
    __typename?: 'Driver';
    altId?: string | null;
    account?: { __typename?: 'Account'; altId: string } | null;
  };
};

export type DeleteAccountVehicleMutationVariables = Exact<{
  input: DeleteAccountVehicleInput;
}>;

export type DeleteAccountVehicleMutation = {
  __typename?: 'Mutation';
  deleteAccountVehicle?: { __typename?: 'Success'; success?: boolean | null } | null;
};

export type DisassociateAccountVehicleMutationVariables = Exact<{
  input: DisassociateAccountVehicleInput;
}>;

export type DisassociateAccountVehicleMutation = {
  __typename?: 'Mutation';
  disassociateAccountVehicle?: { __typename?: 'Success'; success?: boolean | null } | null;
};

export type GetAccountQueryVariables = Exact<{
  accountInput?: InputMaybe<GetAccountInput>;
  accountVehiclesInput?: InputMaybe<ListAccountVehiclesInput>;
  cardsInput?: InputMaybe<ListCardsInput>;
  driversInput?: InputMaybe<ListDriversInput>;
  preferencesInput?: InputMaybe<ListNotificationPreferencesInput>;
}>;

export type GetAccountQuery = {
  __typename?: 'Query';
  getAccount?: {
    __typename?: 'Account';
    altId: string;
    id?: string | null;
    enrolledOn?: any | null;
    accountStatus: AccountStatus;
    accountType?: string | null;
    vendorId?: number | null;
    updatedAt?: any | null;
    drivers?: {
      __typename?: 'DriversWithMeta';
      total?: number | null;
      edges?: Array<{
        __typename?: 'Driver';
        accountId?: string | null;
        id?: string | null;
        altId?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: any | null;
        deletedAt?: any | null;
        driverType?: DriverType | null;
        firebaseId?: string | null;
        createdAt?: any | null;
        driverStatus?: { __typename?: 'FalconConstraint'; id: string; columnText?: string | null } | null;
        accountVehicles?: {
          __typename?: 'AccountVehiclesWithMeta';
          total?: number | null;
          edges?: Array<{
            __typename?: 'AccountVehicle';
            altId?: any | null;
            nickName?: any | null;
            vin?: any | null;
            macAddress?: any | null;
            deletedAt?: any | null;
            autochargeStatus?: {
              __typename?: 'FalconConstraint';
              id: string;
              columnText?: string | null;
              columnValue?: string | null;
            } | null;
            vehicle?: {
              __typename?: 'Vehicle';
              altId?: string | null;
              makeModelYear?: string | null;
              vehicleConnectors?: Array<{
                __typename?: 'VehicleConnector';
                id?: number | null;
                connectorName?: string | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        notificationPreferencesForOp?: {
          __typename?: 'NotificationPreferencesWithMeta';
          edges?: Array<{
            __typename?: 'NotificationPreference';
            driverId?: string | null;
            notificationItemId?: string | null;
            emailOn?: boolean | null;
            smsOn?: boolean | null;
            pushOn?: boolean | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    cards?: {
      __typename?: 'CardsWithMeta';
      total?: number | null;
      edges?: Array<{
        __typename?: 'Card';
        altId: string;
        brand?: string | null;
        cardStatus?: CardStatus | null;
        cardTypeId?: number | null;
        externalNumber?: string | null;
        internalNumber?: string | null;
        updatedAt?: any | null;
        createdAt?: any | null;
        driver?: {
          __typename?: 'Driver';
          altId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
        account?: { __typename?: 'Account'; accountStatus: AccountStatus } | null;
      } | null> | null;
    } | null;
    cardOrders?: {
      __typename?: 'CardOrdersWithMeta';
      total?: number | null;
      edges?: Array<{
        __typename?: 'CardOrder';
        orderStatus?: CardOrdersStatus | null;
        orderedAt?: any | null;
        fulfilledAt?: any | null;
        driver?: { __typename?: 'Driver'; email?: string | null } | null;
      } | null> | null;
    } | null;
    contracts?: {
      __typename?: 'ContractsWithMeta';
      total?: number | null;
      edges?: Array<{
        __typename?: 'Contract';
        altId?: string | null;
        startDate?: any | null;
        endDate?: any | null;
        closureDate?: any | null;
        plan?: {
          __typename?: 'Plan';
          altId?: string | null;
          planName?: any | null;
          terminationBehavior?: string | null;
        } | null;
        status?: { __typename?: 'FalconConstraint'; id: string; columnText?: string | null } | null;
      } | null> | null;
    } | null;
    mailingAddress?: {
      __typename?: 'Address';
      altId: string;
      address1: string;
      address2?: string | null;
      locality: string;
      administrativeArea: string;
      postalCode: string;
      country: string;
    } | null;
    billingAddress?: {
      __typename?: 'Address';
      altId: string;
      address1: string;
      address2?: string | null;
      locality: string;
      administrativeArea: string;
      postalCode: string;
      country: string;
    } | null;
  } | null;
};

export type SearchFirebaseAndDriivzAndFalconAccountsQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
}>;

export type SearchFirebaseAndDriivzAndFalconAccountsQuery = {
  __typename?: 'Query';
  searchFirebaseAndDriivzAndFalconAccounts?: {
    __typename?: 'AccountSearchResult';
    driivz?: Array<{
      __typename?: 'DriivzAccount';
      firstName?: string | null;
      lastName?: string | null;
      billingAddress1?: string | null;
      billingAddress2?: string | null;
      billingRegion?: string | null;
      billingLocality?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null> | null;
    falcon?: Array<{
      __typename?: 'Driver';
      firstName?: string | null;
      lastName?: string | null;
      accountId?: string | null;
      email?: string | null;
      phone?: any | null;
      altId?: string | null;
      driverType?: DriverType | null;
      account?: {
        __typename?: 'Account';
        altId: string;
        billingAddress?: {
          __typename?: 'Address';
          address1: string;
          address2?: string | null;
          city?: string | null;
          postalCode: string;
          locality: string;
        } | null;
      } | null;
    } | null> | null;
    firebase?: Array<{
      __typename?: 'FirebaseAccount';
      email?: string | null;
      phoneNumber?: string | null;
    } | null> | null;
  } | null;
};

export type SyncUserMutationVariables = Exact<{
  email: Scalars['String'];
  setPhoneNumber?: InputMaybe<Scalars['String']>;
}>;

export type SyncUserMutation = { __typename?: 'Mutation'; syncUser?: any | null };

export type UpdateAccountMutationVariables = Exact<{
  input?: InputMaybe<UpdateAccountInput>;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  account?: {
    __typename?: 'Account';
    altId: string;
    evcId?: string | null;
    enrolledOn?: any | null;
    mailingAddress?: {
      __typename?: 'Address';
      address1: string;
      address2?: string | null;
      locality: string;
      administrativeArea: string;
      postalCode: string;
      country: string;
    } | null;
    billingAddress?: {
      __typename?: 'Address';
      address1: string;
      address2?: string | null;
      locality: string;
      administrativeArea: string;
      postalCode: string;
      country: string;
    } | null;
  } | null;
};

export type UpdateAccountOwnerMutationVariables = Exact<{
  accountInput?: InputMaybe<UpdateAccountOwnerInput>;
  preferencesInput?: InputMaybe<ListNotificationPreferencesInput>;
}>;

export type UpdateAccountOwnerMutation = {
  __typename?: 'Mutation';
  updateAccountOwner: {
    __typename?: 'Driver';
    altId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: any | null;
    email?: string | null;
    driverType?: DriverType | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    deletedAt?: any | null;
    driverStatus?: { __typename?: 'FalconConstraint'; id: string; columnText?: string | null } | null;
    account?: {
      __typename?: 'Account';
      altId: string;
      enrolledOn?: any | null;
      vendorId?: number | null;
      optInPromotions?: boolean | null;
      vendorAccountType?: { __typename?: 'FalconConstraint'; id: string; columnText?: string | null } | null;
      mailingAddress?: {
        __typename?: 'Address';
        altId: string;
        address1: string;
        address2?: string | null;
        locality: string;
        administrativeArea: string;
        postalCode: string;
        country: string;
      } | null;
      billingAddress?: {
        __typename?: 'Address';
        altId: string;
        address1: string;
        address2?: string | null;
        locality: string;
        administrativeArea: string;
        postalCode: string;
        country: string;
      } | null;
    } | null;
    notificationPreferences?: {
      __typename?: 'NotificationPreferencesWithMeta';
      edges?: Array<{
        __typename?: 'NotificationPreference';
        driverId?: string | null;
        notificationItemId?: string | null;
        emailOn?: boolean | null;
        smsOn?: boolean | null;
        pushOn?: boolean | null;
      } | null> | null;
    } | null;
  };
};

export type UpdateAccountVehicleMutationVariables = Exact<{
  accountVehicleInput?: InputMaybe<UpdateAccountVehicleInput>;
}>;

export type UpdateAccountVehicleMutation = {
  __typename?: 'Mutation';
  updateAccountVehicle?: {
    __typename?: 'AccountVehicle';
    altId?: any | null;
    nickName?: any | null;
    vin?: any | null;
    vehicle?: { __typename?: 'Vehicle'; altId?: string | null; makeModelYear?: string | null } | null;
  } | null;
};

export type ValidateAddressMutationVariables = Exact<{
  addressInput?: InputMaybe<ValidateAddressInput>;
}>;

export type ValidateAddressMutation = {
  __typename?: 'Mutation';
  validateAddress: {
    __typename?: 'Address';
    address1: string;
    address2?: string | null;
    address3?: string | null;
    address4?: string | null;
    locality: string;
    administrativeArea: string;
    postalCode: string;
    country: string;
    latitude?: string | null;
    longitude?: string | null;
  };
};

export type ActivateCardsMutationVariables = Exact<{
  input: ActivateCardsInput;
}>;

export type ActivateCardsMutation = {
  __typename?: 'Mutation';
  activateCards?: Array<{
    __typename?: 'Card';
    altId: string;
    externalNumber?: string | null;
    cardStatus?: CardStatus | null;
    driver?: { __typename?: 'Driver'; altId?: string | null } | null;
  } | null> | null;
};

export type AssignCardsMutationVariables = Exact<{
  input: AssignCardsInput;
}>;

export type AssignCardsMutation = {
  __typename?: 'Mutation';
  assignCards?: Array<{
    __typename?: 'Card';
    altId: string;
    externalNumber?: string | null;
    cardStatus?: CardStatus | null;
    driver?: { __typename?: 'Driver'; altId?: string | null } | null;
  } | null> | null;
};

export type CreateCardMutationVariables = Exact<{
  input: CreateCardInput;
}>;

export type CreateCardMutation = {
  __typename?: 'Mutation';
  createCard?: { __typename?: 'Card'; altId: string; cardStatus?: CardStatus | null; activatedAt?: any | null } | null;
};

export type DeactivateCardsMutationVariables = Exact<{
  input: DeactivateCardsInput;
}>;

export type DeactivateCardsMutation = {
  __typename?: 'Mutation';
  deactivateCards?: Array<{
    __typename?: 'Card';
    altId: string;
    externalNumber?: string | null;
    cardStatus?: CardStatus | null;
    driver?: { __typename?: 'Driver'; altId?: string | null } | null;
  } | null> | null;
};

export type DeleteVehicleIdCardMutationVariables = Exact<{
  input: DeleteVehicleIdCardInput;
}>;

export type DeleteVehicleIdCardMutation = {
  __typename?: 'Mutation';
  deleteVehicleIdCard?: { __typename?: 'Success'; success?: boolean | null } | null;
};

export type ListCardOrdersQueryVariables = Exact<{
  input?: InputMaybe<ListCardOrdersInput>;
}>;

export type ListCardOrdersQuery = {
  __typename?: 'Query';
  listCardOrders?: {
    __typename?: 'CardOrdersWithMeta';
    total?: number | null;
    page?: number | null;
    pageSize?: number | null;
    filter?: {
      __typename?: 'ListCardOrdersFilterType';
      orderStatus?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
    } | null;
    search?: {
      __typename?: 'ListCardOrdersSearchType';
      driver_firstName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      driver_lastName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      account_billingAddress_address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      account_billingAddress_locality?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      account_billingAddress_administrativeArea?: {
        __typename?: 'TableStringFilterType';
        iLike?: string | null;
      } | null;
      account_billingAddress_postalCode?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      accountId?: { __typename?: 'TableIntFilterType'; eq?: number | null } | null;
    } | null;
    edges?: Array<{
      __typename?: 'CardOrder';
      altId?: string | null;
      orderStatus?: CardOrdersStatus | null;
      orderedAt?: any | null;
      fulfilledAt?: any | null;
      driver?: { __typename?: 'Driver'; firstName?: string | null; lastName?: string | null } | null;
      account?: {
        __typename?: 'CardOrderAccount';
        altId?: string | null;
        id?: string | null;
        billingAddress?: {
          __typename?: 'Address';
          address1: string;
          address2?: string | null;
          locality: string;
          administrativeArea: string;
          postalCode: string;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListCardsQueryVariables = Exact<{
  input?: InputMaybe<ListCardsInput>;
}>;

export type ListCardsQuery = {
  __typename?: 'Query';
  listCards?: {
    __typename?: 'CardsWithMeta';
    total?: number | null;
    page?: number | null;
    pageSize?: number | null;
    filter?: {
      __typename?: 'ListCardsFilterType';
      cardStatus?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
      brand?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
    } | null;
    search?: {
      __typename?: 'ListCardsSearchType';
      externalNumber?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      internalNumber?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      driver_firstName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      driver_lastName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      accountId?: { __typename?: 'TableIntFilterType'; eq?: number | null } | null;
    } | null;
    edges?: Array<{
      __typename?: 'Card';
      altId: string;
      internalNumber?: string | null;
      externalNumber?: string | null;
      brand?: string | null;
      cardStatus?: CardStatus | null;
      cardTypeId?: number | null;
      account?: { __typename?: 'Account'; id?: string | null; altId: string } | null;
      driver?: { __typename?: 'Driver'; firstName?: string | null; lastName?: string | null } | null;
    } | null> | null;
  } | null;
};

export type ReassignCardMutationVariables = Exact<{
  input: ReassignCardInput;
}>;

export type ReassignCardMutation = {
  __typename?: 'Mutation';
  reassignCard?: {
    __typename?: 'Card';
    altId: string;
    cardStatus?: CardStatus | null;
    driver?: { __typename?: 'Driver'; altId?: string | null } | null;
  } | null;
};

export type ReserveCardsMutationVariables = Exact<{
  input: ReserveCardsInput;
}>;

export type ReserveCardsMutation = {
  __typename?: 'Mutation';
  reserveCards?: Array<{
    __typename?: 'Card';
    altId: string;
    externalNumber?: string | null;
    cardStatus?: CardStatus | null;
    driver?: { __typename?: 'Driver'; altId?: string | null } | null;
  } | null> | null;
};

export type SuspendCardsMutationVariables = Exact<{
  input: SuspendCardsInput;
}>;

export type SuspendCardsMutation = {
  __typename?: 'Mutation';
  suspendCards?: Array<{
    __typename?: 'Card';
    altId: string;
    externalNumber?: string | null;
    cardStatus?: CardStatus | null;
    driver?: { __typename?: 'Driver'; altId?: string | null } | null;
  } | null> | null;
};

export type UnassignCardsMutationVariables = Exact<{
  input: UnassignCardsInput;
}>;

export type UnassignCardsMutation = {
  __typename?: 'Mutation';
  unassignCards?: Array<{
    __typename?: 'Card';
    altId: string;
    externalNumber?: string | null;
    cardStatus?: CardStatus | null;
    driver?: { __typename?: 'Driver'; altId?: string | null } | null;
  } | null> | null;
};

export type UpdateCardOrdersMutationVariables = Exact<{
  input: UpdateCardOrdersInput;
}>;

export type UpdateCardOrdersMutation = {
  __typename?: 'Mutation';
  updateCardOrders?: Array<{
    __typename?: 'CardOrder';
    altId?: string | null;
    orderStatus?: CardOrdersStatus | null;
  } | null> | null;
};

export type FullChargerFragment = {
  __typename?: 'Charger';
  altId: string;
  additionalEquipment?: string | null;
  additionalSerial?: string | null;
  ccReaderType?: string | null;
  certified?: boolean | null;
  chargePointIdentity?: string | null;
  chargerIsManaged?: boolean | null;
  chargerName?: string | null;
  chargerStatusId?: number | null;
  cid?: string | null;
  communicationMethod?: string | null;
  connectionUri?: string | null;
  coBranding?: string | null;
  description?: string | null;
  disabled?: boolean | null;
  equipmentId?: string | null;
  evcSyncedAt?: any | null;
  excluded?: boolean | null;
  fieldStationStatus?: FieldStationStatus | null;
  fundingSource?: string | null;
  hidden?: boolean | null;
  identityKey?: string | null;
  ignoreStatusNotifications?: boolean | null;
  installDate?: string | null;
  ipAddress?: string | null;
  lastReceivedCommSignal?: any | null;
  lastReportedFirmwareVersion?: string | null;
  localLoadBalancingEnabled?: boolean | null;
  localNetworkMaster?: boolean | null;
  macAddress?: string | null;
  maintenanceComments?: string | null;
  maintenanceVendorId?: number | null;
  meterSigningSupported?: boolean | null;
  modelNotes?: string | null;
  mountType?: string | null;
  nextMaintenanceDate?: any | null;
  nonNetworked: boolean;
  evseId?: string | null;
  connectionDate?: string | null;
  usageStartDate?: string | null;
  maxPower?: number | null;
  excludeFromOcpi?: boolean | null;
  authenticationModeId?: number | null;
  networkConnectionTypeId?: number | null;
  wirelessConnectionCarrierId?: number | null;
  serialNumber?: string | null;
  showInThirdPartyFilters: boolean;
  simultaneousChargingEnabled: boolean;
  smartChargingEnabled: boolean;
  teslaAdapter: boolean;
  validateMeterSignature: boolean;
  warrantyLengthId?: number | null;
  warrantyStatusId?: number | null;
  warrantyStartDate?: any | null;
  warrantyExpirationDate?: any | null;
  chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
  evses?: {
    __typename?: 'EvsesWithMeta';
    edges?: Array<{
      __typename?: 'Evse';
      altId?: string | null;
      evseId?: string | null;
      connectors?: {
        __typename?: 'ConnectorsWithMeta';
        edges?: Array<{
          __typename?: 'Connector';
          altId: string;
          uniqueConnectorId?: string | null;
          identityKey?: number | null;
          inMaintenance?: boolean | null;
          connectorType?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
  site?: {
    __typename?: 'Site';
    access?: SiteAccess | null;
    address1?: string | null;
    address2?: string | null;
    administrativeArea?: string | null;
    altId: string;
    latitude?: number | null;
    locality?: string | null;
    locationMarket?: SiteMarket | null;
    longitude?: number | null;
    postalCode?: string | null;
    siteName?: string | null;
    sid?: string | null;
    timeZone?: string | null;
    type?: SiteType | null;
    hoursOfOperation?: {
      __typename?: 'HoursType';
      custom?: boolean | null;
      from?: string | null;
      to?: string | null;
      monday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      tuesday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      wednesday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      thursday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      friday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      saturday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      sunday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
    } | null;
  } | null;
  tags?: {
    __typename?: 'TagsWithMeta';
    total?: number | null;
    edges?: Array<{ __typename?: 'Tag'; altId?: string | null; tagName?: string | null } | null> | null;
  } | null;
  warehouse?: {
    __typename?: 'Warehouse';
    altId?: string | null;
    warehouseName?: string | null;
    address1?: string | null;
    address2?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    postalCode?: string | null;
    country?: string | null;
  } | null;
};

export type CreateChargerMutationVariables = Exact<{
  chargerInput?: InputMaybe<CreateChargerInput>;
}>;

export type CreateChargerMutation = {
  __typename?: 'Mutation';
  createCharger: {
    __typename?: 'Charger';
    altId: string;
    additionalEquipment?: string | null;
    additionalSerial?: string | null;
    ccReaderType?: string | null;
    certified?: boolean | null;
    chargePointIdentity?: string | null;
    chargerIsManaged?: boolean | null;
    chargerName?: string | null;
    chargerStatusId?: number | null;
    cid?: string | null;
    communicationMethod?: string | null;
    connectionUri?: string | null;
    coBranding?: string | null;
    description?: string | null;
    disabled?: boolean | null;
    equipmentId?: string | null;
    evcSyncedAt?: any | null;
    excluded?: boolean | null;
    fieldStationStatus?: FieldStationStatus | null;
    fundingSource?: string | null;
    hidden?: boolean | null;
    identityKey?: string | null;
    ignoreStatusNotifications?: boolean | null;
    installDate?: string | null;
    ipAddress?: string | null;
    lastReceivedCommSignal?: any | null;
    lastReportedFirmwareVersion?: string | null;
    localLoadBalancingEnabled?: boolean | null;
    localNetworkMaster?: boolean | null;
    macAddress?: string | null;
    maintenanceComments?: string | null;
    maintenanceVendorId?: number | null;
    meterSigningSupported?: boolean | null;
    modelNotes?: string | null;
    mountType?: string | null;
    nextMaintenanceDate?: any | null;
    nonNetworked: boolean;
    evseId?: string | null;
    connectionDate?: string | null;
    usageStartDate?: string | null;
    maxPower?: number | null;
    excludeFromOcpi?: boolean | null;
    authenticationModeId?: number | null;
    networkConnectionTypeId?: number | null;
    wirelessConnectionCarrierId?: number | null;
    serialNumber?: string | null;
    showInThirdPartyFilters: boolean;
    simultaneousChargingEnabled: boolean;
    smartChargingEnabled: boolean;
    teslaAdapter: boolean;
    validateMeterSignature: boolean;
    warrantyLengthId?: number | null;
    warrantyStatusId?: number | null;
    warrantyStartDate?: any | null;
    warrantyExpirationDate?: any | null;
    chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
    evses?: {
      __typename?: 'EvsesWithMeta';
      edges?: Array<{
        __typename?: 'Evse';
        altId?: string | null;
        evseId?: string | null;
        connectors?: {
          __typename?: 'ConnectorsWithMeta';
          edges?: Array<{
            __typename?: 'Connector';
            altId: string;
            uniqueConnectorId?: string | null;
            identityKey?: number | null;
            inMaintenance?: boolean | null;
            connectorType?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    site?: {
      __typename?: 'Site';
      access?: SiteAccess | null;
      address1?: string | null;
      address2?: string | null;
      administrativeArea?: string | null;
      altId: string;
      latitude?: number | null;
      locality?: string | null;
      locationMarket?: SiteMarket | null;
      longitude?: number | null;
      postalCode?: string | null;
      siteName?: string | null;
      sid?: string | null;
      timeZone?: string | null;
      type?: SiteType | null;
      hoursOfOperation?: {
        __typename?: 'HoursType';
        custom?: boolean | null;
        from?: string | null;
        to?: string | null;
        monday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        tuesday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        wednesday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        thursday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        friday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        saturday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        sunday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
      } | null;
    } | null;
    tags?: {
      __typename?: 'TagsWithMeta';
      total?: number | null;
      edges?: Array<{ __typename?: 'Tag'; altId?: string | null; tagName?: string | null } | null> | null;
    } | null;
    warehouse?: {
      __typename?: 'Warehouse';
      altId?: string | null;
      warehouseName?: string | null;
      address1?: string | null;
      address2?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      postalCode?: string | null;
      country?: string | null;
    } | null;
  };
};

export type GetChargerQueryVariables = Exact<{
  chargerInput?: InputMaybe<GetChargerInput>;
}>;

export type GetChargerQuery = {
  __typename?: 'Query';
  getCharger?: {
    __typename?: 'Charger';
    altId: string;
    additionalEquipment?: string | null;
    additionalSerial?: string | null;
    ccReaderType?: string | null;
    certified?: boolean | null;
    chargePointIdentity?: string | null;
    chargerIsManaged?: boolean | null;
    chargerName?: string | null;
    chargerStatusId?: number | null;
    cid?: string | null;
    communicationMethod?: string | null;
    connectionUri?: string | null;
    coBranding?: string | null;
    description?: string | null;
    disabled?: boolean | null;
    equipmentId?: string | null;
    evcSyncedAt?: any | null;
    excluded?: boolean | null;
    fieldStationStatus?: FieldStationStatus | null;
    fundingSource?: string | null;
    hidden?: boolean | null;
    identityKey?: string | null;
    ignoreStatusNotifications?: boolean | null;
    installDate?: string | null;
    ipAddress?: string | null;
    lastReceivedCommSignal?: any | null;
    lastReportedFirmwareVersion?: string | null;
    localLoadBalancingEnabled?: boolean | null;
    localNetworkMaster?: boolean | null;
    macAddress?: string | null;
    maintenanceComments?: string | null;
    maintenanceVendorId?: number | null;
    meterSigningSupported?: boolean | null;
    modelNotes?: string | null;
    mountType?: string | null;
    nextMaintenanceDate?: any | null;
    nonNetworked: boolean;
    evseId?: string | null;
    connectionDate?: string | null;
    usageStartDate?: string | null;
    maxPower?: number | null;
    excludeFromOcpi?: boolean | null;
    authenticationModeId?: number | null;
    networkConnectionTypeId?: number | null;
    wirelessConnectionCarrierId?: number | null;
    serialNumber?: string | null;
    showInThirdPartyFilters: boolean;
    simultaneousChargingEnabled: boolean;
    smartChargingEnabled: boolean;
    teslaAdapter: boolean;
    validateMeterSignature: boolean;
    warrantyLengthId?: number | null;
    warrantyStatusId?: number | null;
    warrantyStartDate?: any | null;
    warrantyExpirationDate?: any | null;
    chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
    evses?: {
      __typename?: 'EvsesWithMeta';
      edges?: Array<{
        __typename?: 'Evse';
        altId?: string | null;
        evseId?: string | null;
        connectors?: {
          __typename?: 'ConnectorsWithMeta';
          edges?: Array<{
            __typename?: 'Connector';
            altId: string;
            uniqueConnectorId?: string | null;
            identityKey?: number | null;
            inMaintenance?: boolean | null;
            connectorType?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    site?: {
      __typename?: 'Site';
      access?: SiteAccess | null;
      address1?: string | null;
      address2?: string | null;
      administrativeArea?: string | null;
      altId: string;
      latitude?: number | null;
      locality?: string | null;
      locationMarket?: SiteMarket | null;
      longitude?: number | null;
      postalCode?: string | null;
      siteName?: string | null;
      sid?: string | null;
      timeZone?: string | null;
      type?: SiteType | null;
      hoursOfOperation?: {
        __typename?: 'HoursType';
        custom?: boolean | null;
        from?: string | null;
        to?: string | null;
        monday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        tuesday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        wednesday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        thursday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        friday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        saturday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        sunday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
      } | null;
    } | null;
    tags?: {
      __typename?: 'TagsWithMeta';
      total?: number | null;
      edges?: Array<{ __typename?: 'Tag'; altId?: string | null; tagName?: string | null } | null> | null;
    } | null;
    warehouse?: {
      __typename?: 'Warehouse';
      altId?: string | null;
      warehouseName?: string | null;
      address1?: string | null;
      address2?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      postalCode?: string | null;
      country?: string | null;
    } | null;
  } | null;
};

export type ListChargersQueryVariables = Exact<{
  chargersInput?: InputMaybe<ListChargersInput>;
}>;

export type ListChargersQuery = {
  __typename?: 'Query';
  listChargers?: {
    __typename?: 'ChargersWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'Charger';
      altId: string;
      chargerName?: string | null;
      cid?: string | null;
      fieldStationStatus?: FieldStationStatus | null;
      chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
      site?: {
        __typename?: 'Site';
        altId: string;
        address1?: string | null;
        address2?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        postalCode?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
      } | null;
      warehouse?: {
        __typename?: 'Warehouse';
        altId?: string | null;
        warehouseName?: string | null;
        address1?: string | null;
        address2?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        postalCode?: string | null;
      } | null;
    } | null> | null;
    search?: {
      __typename?: 'ListChargersSearchType';
      cid?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      chargerName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      site_address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      site_locality?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      site_administrativeArea?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      site_postalCode?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      site_property_propertyName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
    sort?: {
      __typename?: 'ListChargersSortType';
      cid?: TableSortType | null;
      chargerName?: TableSortType | null;
      fieldStationStatus?: TableSortType | null;
      propertyName?: TableSortType | null;
      chargerModel_modelName?: TableSortType | null;
    } | null;
    filter?: {
      __typename?: 'ListChargersFilterType';
      chargerModel_altId?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
      fieldStationStatus?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
      tags?: {
        __typename?: 'TableFilterType';
        or?: Array<{ __typename?: 'TableIntFilterType'; contains?: Array<number | null> | null } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateConnectorReservableMutationVariables = Exact<{
  input: UpdateConnectorReservableInput;
}>;

export type UpdateConnectorReservableMutation = {
  __typename?: 'Mutation';
  updateConnectorReservable?: { __typename?: 'ConnectorV2'; altId: string; reservable?: boolean | null } | null;
};

export type StartChargeMutationVariables = Exact<{
  input: StartChargeInput;
}>;

export type StartChargeMutation = { __typename?: 'Mutation'; startCharge?: any | null };

export type StopChargeMutationVariables = Exact<{
  input: StopChargeInput;
}>;

export type StopChargeMutation = { __typename?: 'Mutation'; stopCharge?: any | null };

export type UpdateChargerMutationVariables = Exact<{
  chargerInput?: InputMaybe<UpdateChargerInput>;
}>;

export type UpdateChargerMutation = {
  __typename?: 'Mutation';
  updateCharger: {
    __typename?: 'Charger';
    altId: string;
    additionalEquipment?: string | null;
    additionalSerial?: string | null;
    ccReaderType?: string | null;
    certified?: boolean | null;
    chargePointIdentity?: string | null;
    chargerIsManaged?: boolean | null;
    chargerName?: string | null;
    chargerStatusId?: number | null;
    cid?: string | null;
    communicationMethod?: string | null;
    connectionUri?: string | null;
    coBranding?: string | null;
    description?: string | null;
    disabled?: boolean | null;
    equipmentId?: string | null;
    evcSyncedAt?: any | null;
    excluded?: boolean | null;
    fieldStationStatus?: FieldStationStatus | null;
    fundingSource?: string | null;
    hidden?: boolean | null;
    identityKey?: string | null;
    ignoreStatusNotifications?: boolean | null;
    installDate?: string | null;
    ipAddress?: string | null;
    lastReceivedCommSignal?: any | null;
    lastReportedFirmwareVersion?: string | null;
    localLoadBalancingEnabled?: boolean | null;
    localNetworkMaster?: boolean | null;
    macAddress?: string | null;
    maintenanceComments?: string | null;
    maintenanceVendorId?: number | null;
    meterSigningSupported?: boolean | null;
    modelNotes?: string | null;
    mountType?: string | null;
    nextMaintenanceDate?: any | null;
    nonNetworked: boolean;
    evseId?: string | null;
    connectionDate?: string | null;
    usageStartDate?: string | null;
    maxPower?: number | null;
    excludeFromOcpi?: boolean | null;
    authenticationModeId?: number | null;
    networkConnectionTypeId?: number | null;
    wirelessConnectionCarrierId?: number | null;
    serialNumber?: string | null;
    showInThirdPartyFilters: boolean;
    simultaneousChargingEnabled: boolean;
    smartChargingEnabled: boolean;
    teslaAdapter: boolean;
    validateMeterSignature: boolean;
    warrantyLengthId?: number | null;
    warrantyStatusId?: number | null;
    warrantyStartDate?: any | null;
    warrantyExpirationDate?: any | null;
    chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
    evses?: {
      __typename?: 'EvsesWithMeta';
      edges?: Array<{
        __typename?: 'Evse';
        altId?: string | null;
        evseId?: string | null;
        connectors?: {
          __typename?: 'ConnectorsWithMeta';
          edges?: Array<{
            __typename?: 'Connector';
            altId: string;
            uniqueConnectorId?: string | null;
            identityKey?: number | null;
            inMaintenance?: boolean | null;
            connectorType?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    site?: {
      __typename?: 'Site';
      access?: SiteAccess | null;
      address1?: string | null;
      address2?: string | null;
      administrativeArea?: string | null;
      altId: string;
      latitude?: number | null;
      locality?: string | null;
      locationMarket?: SiteMarket | null;
      longitude?: number | null;
      postalCode?: string | null;
      siteName?: string | null;
      sid?: string | null;
      timeZone?: string | null;
      type?: SiteType | null;
      hoursOfOperation?: {
        __typename?: 'HoursType';
        custom?: boolean | null;
        from?: string | null;
        to?: string | null;
        monday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        tuesday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        wednesday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        thursday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        friday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        saturday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        sunday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
      } | null;
    } | null;
    tags?: {
      __typename?: 'TagsWithMeta';
      total?: number | null;
      edges?: Array<{ __typename?: 'Tag'; altId?: string | null; tagName?: string | null } | null> | null;
    } | null;
    warehouse?: {
      __typename?: 'Warehouse';
      altId?: string | null;
      warehouseName?: string | null;
      address1?: string | null;
      address2?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      postalCode?: string | null;
      country?: string | null;
    } | null;
  };
};

export type CreateContactMutationVariables = Exact<{
  contactInput?: InputMaybe<CreateContactInput>;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact?: {
    __typename?: 'Contact';
    altId?: string | null;
    contactName: string;
    workTitle?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
};

export type CreateContractMutationVariables = Exact<{
  contractInput: CreateContractInput;
}>;

export type CreateContractMutation = {
  __typename?: 'Mutation';
  createContract?: {
    __typename?: 'Contract';
    altId?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    closureDate?: any | null;
    account?: { __typename?: 'Account'; altId: string } | null;
    plan?: { __typename?: 'Plan'; altId?: string | null } | null;
    status?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
  } | null;
};

export type GetContractQueryVariables = Exact<{
  input: GetContractInput;
}>;

export type GetContractQuery = {
  __typename?: 'Query';
  getContract?: {
    __typename?: 'Contract';
    altId?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    closureDate?: any | null;
    account?: { __typename?: 'Account'; altId: string } | null;
    plan?: { __typename?: 'Plan'; altId?: string | null } | null;
    status?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
  } | null;
};

export type ListContractsQueryVariables = Exact<{
  input?: InputMaybe<ListContractsInput>;
}>;

export type ListContractsQuery = {
  __typename?: 'Query';
  listContracts?: {
    __typename?: 'ContractsWithMeta';
    total?: number | null;
    page?: number | null;
    pageSize?: number | null;
    filter?: {
      __typename?: 'ListContractsFilterType';
      accountId?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
    sort?: { __typename?: 'ListContractsSortType'; statusId?: TableSortType | null } | null;
    search?: {
      __typename?: 'ListContractsSearchType';
      accountId?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
    edges?: Array<{
      __typename?: 'Contract';
      altId?: string | null;
      startDate?: any | null;
      endDate?: any | null;
      closureDate?: any | null;
      account?: { __typename?: 'Account'; altId: string } | null;
      plan?: { __typename?: 'Plan'; altId?: string | null } | null;
      status?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
    } | null> | null;
  } | null;
};

export type UpdateContractMutationVariables = Exact<{
  contractInput: UpdateContractInput;
}>;

export type UpdateContractMutation = {
  __typename?: 'Mutation';
  updateContract?: {
    __typename?: 'Contract';
    altId?: string | null;
    startDate?: any | null;
    endDate?: any | null;
    closureDate?: any | null;
    account?: { __typename?: 'Account'; altId: string } | null;
    plan?: { __typename?: 'Plan'; altId?: string | null } | null;
    status?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
  } | null;
};

export type CreateDriverMutationVariables = Exact<{
  input?: InputMaybe<CreateDriverInput>;
}>;

export type CreateDriverMutation = {
  __typename?: 'Mutation';
  createDriver: {
    __typename?: 'Driver';
    firstName?: string | null;
    lastName?: string | null;
    driverType?: DriverType | null;
    phone?: any | null;
    email?: string | null;
  };
};

export type DeleteDriverMutationVariables = Exact<{
  input?: InputMaybe<DeleteDriverInput>;
}>;

export type DeleteDriverMutation = {
  __typename?: 'Mutation';
  deleteDriver: { __typename?: 'Driver'; altId?: string | null };
};

export type GetDriverQueryVariables = Exact<{
  input?: InputMaybe<GetDriverInput>;
  cardsInput?: InputMaybe<ListCardsInput>;
  preferencesInput?: InputMaybe<ListNotificationPreferencesInput>;
}>;

export type GetDriverQuery = {
  __typename?: 'Query';
  getDriver?: {
    __typename?: 'Driver';
    id?: string | null;
    altId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: any | null;
    email?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    deletedAt?: any | null;
    driverStatus?: { __typename?: 'FalconConstraint'; id: string; columnText?: string | null } | null;
    cards?: {
      __typename?: 'CardsWithMeta';
      total?: number | null;
      edges?: Array<{
        __typename?: 'Card';
        altId: string;
        brand?: string | null;
        internalNumber?: string | null;
        externalNumber?: string | null;
        cardStatus?: CardStatus | null;
        driver?: {
          __typename?: 'Driver';
          altId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null> | null;
    } | null;
    account?: {
      __typename?: 'Account';
      id?: string | null;
      altId: string;
      accountStatus: AccountStatus;
      accountType?: string | null;
      contracts?: {
        __typename?: 'ContractsWithMeta';
        total?: number | null;
        edges?: Array<{
          __typename?: 'Contract';
          altId?: string | null;
          startDate?: any | null;
          endDate?: any | null;
          closureDate?: any | null;
          plan?: { __typename?: 'Plan'; altId?: string | null; planName?: any | null } | null;
          status?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
        } | null> | null;
      } | null;
      mailingAddress?: {
        __typename?: 'Address';
        address1: string;
        address2?: string | null;
        locality: string;
        administrativeArea: string;
        postalCode: string;
      } | null;
    } | null;
    accountVehicles?: {
      __typename?: 'AccountVehiclesWithMeta';
      edges?: Array<{
        __typename?: 'AccountVehicle';
        altId?: any | null;
        nickName?: any | null;
        vin?: any | null;
        vehicle?: { __typename?: 'Vehicle'; makeModelYear?: string | null } | null;
      } | null> | null;
    } | null;
    notificationPreferences?: {
      __typename?: 'NotificationPreferencesWithMeta';
      edges?: Array<{
        __typename?: 'NotificationPreference';
        driverId?: string | null;
        notificationItemId?: string | null;
        emailOn?: boolean | null;
        smsOn?: boolean | null;
        pushOn?: boolean | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ListDriversQueryVariables = Exact<{
  driversInput?: InputMaybe<ListDriversInput>;
}>;

export type ListDriversQuery = {
  __typename?: 'Query';
  listDrivers?: {
    __typename?: 'DriversWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'Driver';
      altId?: string | null;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      driverType?: DriverType | null;
      driverStatus?: { __typename?: 'FalconConstraint'; id: string; columnValue?: string | null } | null;
      account?: {
        __typename?: 'Account';
        altId: string;
        id?: string | null;
        accountStatus: AccountStatus;
        billingAddress?: {
          __typename?: 'Address';
          address1: string;
          address2?: string | null;
          locality: string;
          administrativeArea: string;
          postalCode: string;
          country: string;
        } | null;
      } | null;
    } | null> | null;
    filter?: {
      __typename?: 'ListDriversFilterType';
      driverStatusId?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
      account_billingAddress_administrativeArea?: {
        __typename?: 'TableStringFilterType';
        in?: Array<string | null> | null;
      } | null;
      account_accountStatus?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
    } | null;
    search?: {
      __typename?: 'ListDriversSearchType';
      account_id?: { __typename?: 'TableIntFilterType'; eq?: number | null } | null;
      account_billingAddress_address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      email?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      firstName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      lastName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
    sort?: {
      __typename?: 'ListDriversSortType';
      account_id?: TableSortType | null;
      account_balance?: TableSortType | null;
      account_billingAddress_address1?: TableSortType | null;
      account_accountStatus?: TableSortType | null;
      driverStatusId?: TableSortType | null;
      email?: TableSortType | null;
      firstName?: TableSortType | null;
      lastName?: TableSortType | null;
    } | null;
  } | null;
};

export type ResetDriverPasswordMutationVariables = Exact<{
  driverInput?: InputMaybe<ResetDriverPasswordInput>;
}>;

export type ResetDriverPasswordMutation = {
  __typename?: 'Mutation';
  resetDriverPassword: { __typename?: 'MandrillResponse'; mandrillId: string; recipientEmail: string; status: string };
};

export type UpdateDriverMutationVariables = Exact<{
  driverInput?: InputMaybe<UpdateDriverInput>;
  cardsInput?: InputMaybe<ListCardsInput>;
  preferencesInput?: InputMaybe<ListNotificationPreferencesInput>;
}>;

export type UpdateDriverMutation = {
  __typename?: 'Mutation';
  updateDriver: {
    __typename?: 'Driver';
    id?: string | null;
    altId?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phone?: any | null;
    email?: string | null;
    cards?: {
      __typename?: 'CardsWithMeta';
      total?: number | null;
      edges?: Array<{
        __typename?: 'Card';
        altId: string;
        brand?: string | null;
        internalNumber?: string | null;
        externalNumber?: string | null;
        cardStatus?: CardStatus | null;
        driver?: {
          __typename?: 'Driver';
          altId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null> | null;
    } | null;
    account?: {
      __typename?: 'Account';
      id?: string | null;
      altId: string;
      accountStatus: AccountStatus;
      mailingAddress?: {
        __typename?: 'Address';
        address1: string;
        address2?: string | null;
        locality: string;
        administrativeArea: string;
        postalCode: string;
      } | null;
    } | null;
    accountVehicles?: {
      __typename?: 'AccountVehiclesWithMeta';
      edges?: Array<{
        __typename?: 'AccountVehicle';
        altId?: any | null;
        nickName?: any | null;
        vin?: any | null;
        vehicle?: { __typename?: 'Vehicle'; makeModelYear?: string | null } | null;
      } | null> | null;
    } | null;
    notificationPreferences?: {
      __typename?: 'NotificationPreferencesWithMeta';
      edges?: Array<{
        __typename?: 'NotificationPreference';
        driverId?: string | null;
        notificationItemId?: string | null;
        emailOn?: boolean | null;
        smsOn?: boolean | null;
        pushOn?: boolean | null;
      } | null> | null;
    } | null;
  };
};

export type AddChargersToChargerGroupForExtendMutationVariables = Exact<{
  input: AddChargersToChargerGroupForExtendInput;
}>;

export type AddChargersToChargerGroupForExtendMutation = {
  __typename?: 'Mutation';
  addChargersToChargerGroupForExtend?: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
    tariff?: {
      __typename?: 'TariffForExtend';
      altId: string;
      tariffDescription?: any | null;
      tariffDisplayName?: any | null;
      tariffName: any;
      tariffRank?: number | null;
      status?: TariffForExtendSyncStatus | null;
      chargerGroups?: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
        chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
      } | null> | null;
      defaultPricing?: {
        __typename?: 'TariffPricingForExtend';
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing?: {
          __typename?: 'PricingForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        } | null;
      } | null;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
      reservationTerm?: {
        __typename?: 'TariffItemReservationTermForExtend';
        fee?: number | null;
        noShowFee?: number | null;
      } | null;
    } | null;
    planTariffs: Array<{
      __typename?: 'PlanTariffForExtend';
      altId: string;
      tariffName: any;
      tariffDescription?: any | null;
      chargerGroups: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
      }>;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
    } | null>;
    chargers: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        chargerName?: string | null;
        chargerStatus?: string | null;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        displayTariffSynced?: boolean | null;
        setTariffSynced?: boolean | null;
        tariffSyncStatus?: TariffSyncStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        evses?: {
          __typename?: 'EvsesWithMeta';
          edges?: Array<{
            __typename?: 'Evse';
            connectors?: {
              __typename?: 'ConnectorsWithMeta';
              edges?: Array<{
                __typename?: 'Connector';
                chargingLevel: ChargingLevel;
                reservable?: boolean | null;
                connectorStatusConstraint?: {
                  __typename?: 'FalconConstraint';
                  columnValue?: string | null;
                  columnText?: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          siteName?: string | null;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
    };
  } | null;
};

export type CreateChargerPriceGroupForExtendMutationVariables = Exact<{
  input: CreateChargerPriceGroupForExtendInput;
}>;

export type CreateChargerPriceGroupForExtendMutation = {
  __typename?: 'Mutation';
  createChargerPriceGroupForExtend?: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
    tariff?: {
      __typename?: 'TariffForExtend';
      altId: string;
      tariffDescription?: any | null;
      tariffDisplayName?: any | null;
      tariffName: any;
      tariffRank?: number | null;
      status?: TariffForExtendSyncStatus | null;
      chargerGroups?: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
        chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
      } | null> | null;
      defaultPricing?: {
        __typename?: 'TariffPricingForExtend';
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing?: {
          __typename?: 'PricingForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        } | null;
      } | null;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
      reservationTerm?: {
        __typename?: 'TariffItemReservationTermForExtend';
        fee?: number | null;
        noShowFee?: number | null;
      } | null;
    } | null;
    planTariffs: Array<{
      __typename?: 'PlanTariffForExtend';
      altId: string;
      tariffName: any;
      tariffDescription?: any | null;
      chargerGroups: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
      }>;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
    } | null>;
    chargers: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        chargerName?: string | null;
        chargerStatus?: string | null;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        displayTariffSynced?: boolean | null;
        setTariffSynced?: boolean | null;
        tariffSyncStatus?: TariffSyncStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        evses?: {
          __typename?: 'EvsesWithMeta';
          edges?: Array<{
            __typename?: 'Evse';
            connectors?: {
              __typename?: 'ConnectorsWithMeta';
              edges?: Array<{
                __typename?: 'Connector';
                chargingLevel: ChargingLevel;
                reservable?: boolean | null;
                connectorStatusConstraint?: {
                  __typename?: 'FalconConstraint';
                  columnValue?: string | null;
                  columnText?: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          siteName?: string | null;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
    };
  } | null;
};

export type CreateChargerGroupPlanForExtendMutationVariables = Exact<{
  input: CreateChargerGroupPlanForExtendInput;
}>;

export type CreateChargerGroupPlanForExtendMutation = {
  __typename?: 'Mutation';
  createChargerGroupPlanForExtend?: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
    tariff?: {
      __typename?: 'TariffForExtend';
      altId: string;
      tariffDescription?: any | null;
      tariffDisplayName?: any | null;
      tariffName: any;
      tariffRank?: number | null;
      status?: TariffForExtendSyncStatus | null;
      chargerGroups?: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
        chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
      } | null> | null;
      defaultPricing?: {
        __typename?: 'TariffPricingForExtend';
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing?: {
          __typename?: 'PricingForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        } | null;
      } | null;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
      reservationTerm?: {
        __typename?: 'TariffItemReservationTermForExtend';
        fee?: number | null;
        noShowFee?: number | null;
      } | null;
    } | null;
    planTariffs: Array<{
      __typename?: 'PlanTariffForExtend';
      altId: string;
      tariffName: any;
      tariffDescription?: any | null;
      chargerGroups: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
      }>;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
    } | null>;
    chargers: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        chargerName?: string | null;
        chargerStatus?: string | null;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        displayTariffSynced?: boolean | null;
        setTariffSynced?: boolean | null;
        tariffSyncStatus?: TariffSyncStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        evses?: {
          __typename?: 'EvsesWithMeta';
          edges?: Array<{
            __typename?: 'Evse';
            connectors?: {
              __typename?: 'ConnectorsWithMeta';
              edges?: Array<{
                __typename?: 'Connector';
                chargingLevel: ChargingLevel;
                reservable?: boolean | null;
                connectorStatusConstraint?: {
                  __typename?: 'FalconConstraint';
                  columnValue?: string | null;
                  columnText?: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          siteName?: string | null;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
    };
  } | null;
};

export type CreateExtendHostMutationVariables = Exact<{
  input: CreateExtendHostInput;
}>;

export type CreateExtendHostMutation = {
  __typename?: 'Mutation';
  createExtendHost: {
    __typename?: 'ExtendHost';
    altId: string;
    createdAt?: any | null;
    host: {
      __typename?: 'Host';
      altId?: string | null;
      hostName?: string | null;
      hid?: string | null;
      status?: HostStatus | null;
      vendorId?: string | null;
    };
  };
};

export type CreateExtendUserMutationVariables = Exact<{
  extenduserInput?: InputMaybe<CreateExtendUserInput>;
}>;

export type CreateExtendUserMutation = {
  __typename?: 'Mutation';
  createExtendUser?: {
    __typename?: 'ExtendUser';
    altId: string;
    hosts: {
      __typename?: 'HostsWithMeta';
      edges?: Array<{ __typename?: 'Host'; hostName?: string | null; hid?: string | null } | null> | null;
    };
  } | null;
};

export type CreatePlanTariffsForExtendMutationVariables = Exact<{
  input: CreatePlanTariffsForExtendInput;
}>;

export type CreatePlanTariffsForExtendMutation = {
  __typename?: 'Mutation';
  createPlanTariffsForExtend: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
    tariff?: {
      __typename?: 'TariffForExtend';
      altId: string;
      tariffDescription?: any | null;
      tariffDisplayName?: any | null;
      tariffName: any;
      tariffRank?: number | null;
      status?: TariffForExtendSyncStatus | null;
      chargerGroups?: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
        chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
      } | null> | null;
      defaultPricing?: {
        __typename?: 'TariffPricingForExtend';
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing?: {
          __typename?: 'PricingForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        } | null;
      } | null;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
      reservationTerm?: {
        __typename?: 'TariffItemReservationTermForExtend';
        fee?: number | null;
        noShowFee?: number | null;
      } | null;
    } | null;
    planTariffs: Array<{
      __typename?: 'PlanTariffForExtend';
      altId: string;
      tariffName: any;
      tariffDescription?: any | null;
      chargerGroups: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
      }>;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
    } | null>;
    chargers: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        chargerName?: string | null;
        chargerStatus?: string | null;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        displayTariffSynced?: boolean | null;
        setTariffSynced?: boolean | null;
        tariffSyncStatus?: TariffSyncStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        evses?: {
          __typename?: 'EvsesWithMeta';
          edges?: Array<{
            __typename?: 'Evse';
            connectors?: {
              __typename?: 'ConnectorsWithMeta';
              edges?: Array<{
                __typename?: 'Connector';
                chargingLevel: ChargingLevel;
                reservable?: boolean | null;
                connectorStatusConstraint?: {
                  __typename?: 'FalconConstraint';
                  columnValue?: string | null;
                  columnText?: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          siteName?: string | null;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
    };
  };
};

export type CreateTariffForExtendMutationVariables = Exact<{
  input: CreateTariffForExtendInput;
}>;

export type CreateTariffForExtendMutation = {
  __typename?: 'Mutation';
  createTariffForExtend: {
    __typename?: 'TariffForExtend';
    altId: string;
    tariffDescription?: any | null;
    tariffDisplayName?: any | null;
    tariffName: any;
    tariffRank?: number | null;
    status?: TariffForExtendSyncStatus | null;
    chargerGroups?: Array<{
      __typename?: 'ChargerGroupForExtend';
      altId: string;
      chargerGroupName: string;
      chargerGroupType: string;
      chargerGroupTypeId: number;
      description?: string | null;
      createdAt: any;
      deletedAt?: any | null;
      planDuration?: number | null;
      planId?: string | null;
      status: ChargerGroupSyncStatus;
      chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
    } | null> | null;
    defaultPricing?: {
      __typename?: 'TariffPricingForExtend';
      displayRank?: number | null;
      displayName?: any | null;
      displayDescription1?: any | null;
      displayDescription2?: any | null;
      pricing?: {
        __typename?: 'PricingForExtend';
        perKwh?: any | null;
        perMinute?: any | null;
        perSession?: any | null;
      } | null;
    } | null;
    customPricing?: Array<{
      __typename?: 'TariffCustomPricingForExtend';
      altId: string;
      displayRank?: number | null;
      displayName?: any | null;
      displayDescription1?: any | null;
      displayDescription2?: any | null;
      pricing: {
        __typename?: 'TariffCustomPricesForExtend';
        perKwh?: any | null;
        perMinute?: any | null;
        perSession?: any | null;
      };
      tariffSchedules: Array<{
        __typename?: 'TariffScheduleForExtend';
        daysOfWeek: Array<Day>;
        endTime: string;
        startTime: string;
      }>;
    }> | null;
    reservationTerm?: {
      __typename?: 'TariffItemReservationTermForExtend';
      fee?: number | null;
      noShowFee?: number | null;
    } | null;
  };
};

export type DeactivateChargerGroupPlanForExtendMutationVariables = Exact<{
  input: DeactivateChargerGroupPlanForExtendInput;
}>;

export type DeactivateChargerGroupPlanForExtendMutation = {
  __typename?: 'Mutation';
  deactivateChargerGroupPlanForExtend?: { __typename?: 'ChargerGroupForExtend'; altId: string } | null;
};

export type DeleteChargerGroupForExtendMutationVariables = Exact<{
  input: DeleteChargerGroupForExtendInput;
}>;

export type DeleteChargerGroupForExtendMutation = {
  __typename?: 'Mutation';
  deleteChargerGroupForExtend?: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    status: ChargerGroupSyncStatus;
  } | null;
};

export type DeleteExtendHostMutationVariables = Exact<{
  input: DeleteExtendHostInput;
}>;

export type DeleteExtendHostMutation = {
  __typename?: 'Mutation';
  deleteExtendHost?: { __typename?: 'Success'; success?: boolean | null } | null;
};

export type DeleteExtendUserHostsMutationVariables = Exact<{
  deleteExtendUserHostsInput?: InputMaybe<DeleteExtendUserHostsInput>;
}>;

export type DeleteExtendUserHostsMutation = {
  __typename?: 'Mutation';
  deleteExtendUserHosts?: { __typename?: 'ExtendUser'; altId: string } | null;
};

export type DeleteTariffForExtendMutationVariables = Exact<{
  input: DeleteTariffForExtendInput;
}>;

export type DeleteTariffForExtendMutation = { __typename?: 'Mutation'; deleteTariffForExtend?: any | null };

export type ChargerGroupForExtendFragment = {
  __typename?: 'ChargerGroupForExtend';
  altId: string;
  chargerGroupName: string;
  chargerGroupType: string;
  chargerGroupTypeId: number;
  description?: string | null;
  createdAt: any;
  deletedAt?: any | null;
  planDuration?: number | null;
  planId?: string | null;
  status: ChargerGroupSyncStatus;
};

export type ChargersWithMetaFragment = {
  __typename?: 'ChargersWithMeta';
  page?: number | null;
  pageSize?: number | null;
  total?: number | null;
  edges?: Array<{
    __typename?: 'Charger';
    altId: string;
    chargerName?: string | null;
    chargerStatus?: string | null;
    cid?: string | null;
    fieldStationStatus?: FieldStationStatus | null;
    displayTariffSynced?: boolean | null;
    setTariffSynced?: boolean | null;
    tariffSyncStatus?: TariffSyncStatus | null;
    chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
    evses?: {
      __typename?: 'EvsesWithMeta';
      edges?: Array<{
        __typename?: 'Evse';
        connectors?: {
          __typename?: 'ConnectorsWithMeta';
          edges?: Array<{
            __typename?: 'Connector';
            chargingLevel: ChargingLevel;
            reservable?: boolean | null;
            connectorStatusConstraint?: {
              __typename?: 'FalconConstraint';
              columnValue?: string | null;
              columnText?: string | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
    site?: {
      __typename?: 'Site';
      altId: string;
      siteName?: string | null;
      address1?: string | null;
      address2?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      postalCode?: string | null;
      country?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
    } | null;
  } | null> | null;
};

export type PlanTariffTagFragment = {
  __typename?: 'PlanTariffTag';
  planChargerGroup?: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    status: ChargerGroupSyncStatus;
    deletedAt?: any | null;
  } | null;
};

export type PlanTariffForExtendFragment = {
  __typename?: 'PlanTariffForExtend';
  altId: string;
  tariffName: any;
  tariffDescription?: any | null;
  chargerGroups: Array<{
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
  }>;
  customPricing?: Array<{
    __typename?: 'TariffCustomPricingForExtend';
    altId: string;
    displayRank?: number | null;
    displayName?: any | null;
    displayDescription1?: any | null;
    displayDescription2?: any | null;
    pricing: {
      __typename?: 'TariffCustomPricesForExtend';
      perKwh?: any | null;
      perMinute?: any | null;
      perSession?: any | null;
    };
    tariffSchedules: Array<{
      __typename?: 'TariffScheduleForExtend';
      daysOfWeek: Array<Day>;
      endTime: string;
      startTime: string;
    }>;
  }> | null;
};

export type TariffPricingForExtendFragment = {
  __typename?: 'TariffPricingForExtend';
  displayRank?: number | null;
  displayName?: any | null;
  displayDescription1?: any | null;
  displayDescription2?: any | null;
  pricing?: {
    __typename?: 'PricingForExtend';
    perKwh?: any | null;
    perMinute?: any | null;
    perSession?: any | null;
  } | null;
};

export type TariffItemReservationTermForExtendFragment = {
  __typename?: 'TariffItemReservationTermForExtend';
  fee?: number | null;
  noShowFee?: number | null;
};

export type TariffScheduleForExtendFragment = {
  __typename?: 'TariffScheduleForExtend';
  daysOfWeek: Array<Day>;
  endTime: string;
  startTime: string;
};

export type TariffCustomPricingForExtendFragment = {
  __typename?: 'TariffCustomPricingForExtend';
  altId: string;
  displayRank?: number | null;
  displayName?: any | null;
  displayDescription1?: any | null;
  displayDescription2?: any | null;
  pricing: {
    __typename?: 'TariffCustomPricesForExtend';
    perKwh?: any | null;
    perMinute?: any | null;
    perSession?: any | null;
  };
  tariffSchedules: Array<{
    __typename?: 'TariffScheduleForExtend';
    daysOfWeek: Array<Day>;
    endTime: string;
    startTime: string;
  }>;
};

export type TariffForExtendFragment = {
  __typename?: 'TariffForExtend';
  altId: string;
  tariffDescription?: any | null;
  tariffDisplayName?: any | null;
  tariffName: any;
  tariffRank?: number | null;
  status?: TariffForExtendSyncStatus | null;
  chargerGroups?: Array<{
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
    chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
  } | null> | null;
  defaultPricing?: {
    __typename?: 'TariffPricingForExtend';
    displayRank?: number | null;
    displayName?: any | null;
    displayDescription1?: any | null;
    displayDescription2?: any | null;
    pricing?: {
      __typename?: 'PricingForExtend';
      perKwh?: any | null;
      perMinute?: any | null;
      perSession?: any | null;
    } | null;
  } | null;
  customPricing?: Array<{
    __typename?: 'TariffCustomPricingForExtend';
    altId: string;
    displayRank?: number | null;
    displayName?: any | null;
    displayDescription1?: any | null;
    displayDescription2?: any | null;
    pricing: {
      __typename?: 'TariffCustomPricesForExtend';
      perKwh?: any | null;
      perMinute?: any | null;
      perSession?: any | null;
    };
    tariffSchedules: Array<{
      __typename?: 'TariffScheduleForExtend';
      daysOfWeek: Array<Day>;
      endTime: string;
      startTime: string;
    }>;
  }> | null;
  reservationTerm?: {
    __typename?: 'TariffItemReservationTermForExtend';
    fee?: number | null;
    noShowFee?: number | null;
  } | null;
};

export type GetActiveSessionsTotalsQueryVariables = Exact<{
  input: ActiveSessionsTotalsInput;
}>;

export type GetActiveSessionsTotalsQuery = {
  __typename?: 'Query';
  getActiveSessionsTotals?: { __typename?: 'ActiveSessionsTotals'; totalKwh: number } | null;
};

export type GetChargerExtendQueryVariables = Exact<{
  chargerInput?: InputMaybe<GetChargerInput>;
}>;

export type GetChargerExtendQuery = {
  __typename?: 'Query';
  getCharger?: {
    __typename?: 'Charger';
    altId: string;
    chargerName?: string | null;
    serialNumber?: string | null;
    chargerId?: string | null;
    setTariffSynced?: boolean | null;
    displayTariffSynced?: boolean | null;
    tariffSyncStatus?: TariffSyncStatus | null;
    chargerModel?: {
      __typename?: 'ChargerModel';
      modelName?: string | null;
      firmwareVersion?: string | null;
      breakerRating?: number | null;
      simultaneousChargeSupported?: boolean | null;
    } | null;
    site?: { __typename?: 'Site'; altId: string; siteName?: string | null } | null;
    evses?: {
      __typename?: 'EvsesWithMeta';
      edges?: Array<{
        __typename?: 'Evse';
        evseId?: string | null;
        connectors?: {
          __typename?: 'ConnectorsWithMeta';
          edges?: Array<{
            __typename?: 'Connector';
            altId: string;
            cableLength?: CableLength | null;
            connectorMaxCurrent?: number | null;
            maxConnectorPower?: number | null;
            energyUtilization?: number | null;
            outputVoltageRange?: string | null;
            reservable?: boolean | null;
            connectorType?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
            connectorStatusConstraint?: {
              __typename?: 'FalconConstraint';
              columnValue?: string | null;
              columnText?: string | null;
            } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetChargerGroupForExtendQueryVariables = Exact<{
  input: GetChargerGroupForExtendInput;
}>;

export type GetChargerGroupForExtendQuery = {
  __typename?: 'Query';
  getChargerGroupForExtend?: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
    tariff?: {
      __typename?: 'TariffForExtend';
      altId: string;
      tariffDescription?: any | null;
      tariffDisplayName?: any | null;
      tariffName: any;
      tariffRank?: number | null;
      status?: TariffForExtendSyncStatus | null;
      chargerGroups?: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
        chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
      } | null> | null;
      defaultPricing?: {
        __typename?: 'TariffPricingForExtend';
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing?: {
          __typename?: 'PricingForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        } | null;
      } | null;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
      reservationTerm?: {
        __typename?: 'TariffItemReservationTermForExtend';
        fee?: number | null;
        noShowFee?: number | null;
      } | null;
    } | null;
    planTariffs: Array<{
      __typename?: 'PlanTariffForExtend';
      altId: string;
      tariffName: any;
      tariffDescription?: any | null;
      chargerGroups: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
      }>;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
    } | null>;
    chargers: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        chargerName?: string | null;
        chargerStatus?: string | null;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        displayTariffSynced?: boolean | null;
        setTariffSynced?: boolean | null;
        tariffSyncStatus?: TariffSyncStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        evses?: {
          __typename?: 'EvsesWithMeta';
          edges?: Array<{
            __typename?: 'Evse';
            connectors?: {
              __typename?: 'ConnectorsWithMeta';
              edges?: Array<{
                __typename?: 'Connector';
                chargingLevel: ChargingLevel;
                reservable?: boolean | null;
                connectorStatusConstraint?: {
                  __typename?: 'FalconConstraint';
                  columnValue?: string | null;
                  columnText?: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          siteName?: string | null;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
    };
  } | null;
};

export type GetChargerGroupPlansForExtendQueryVariables = Exact<{
  input: GetChargerGroupForExtendInput;
}>;

export type GetChargerGroupPlansForExtendQuery = {
  __typename?: 'Query';
  getChargerGroupForExtend?: {
    __typename?: 'ChargerGroupForExtend';
    planTariffTags?: Array<{
      __typename?: 'PlanTariffTag';
      planChargerGroup?: {
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
      } | null;
    } | null> | null;
  } | null;
};

export type GetChargerGroupsForChargerPriceQueryVariables = Exact<{
  input: GetTariffForExtendInput;
}>;

export type GetChargerGroupsForChargerPriceQuery = {
  __typename?: 'Query';
  getTariffForExtend: {
    __typename?: 'TariffForExtend';
    chargerGroups?: Array<{
      __typename?: 'ChargerGroupForExtend';
      altId: string;
      chargerGroupName: string;
    } | null> | null;
  };
};

export type GetExtendCalculatedHostPlansQueryVariables = Exact<{ [key: string]: never }>;

export type GetExtendCalculatedHostPlansQuery = {
  __typename?: 'Query';
  getExtendCalculatedHostPlans: Array<{ __typename?: 'ExtendCalculatedHostPlan'; planId: number }>;
};

export type GetExtendUserQueryVariables = Exact<{
  extenduserInput?: InputMaybe<GetExtendUserInput>;
}>;

export type GetExtendUserQuery = {
  __typename?: 'Query';
  getExtendUser?: {
    __typename?: 'ExtendUser';
    altId: string;
    hosts: {
      __typename?: 'HostsWithMeta';
      edges?: Array<{
        __typename?: 'Host';
        altId?: string | null;
        hostName?: string | null;
        customPlansEnabled?: boolean | null;
      } | null> | null;
    };
  } | null;
};

export type GetFirebaseUserForExtendQueryVariables = Exact<{
  input: GetFirebaseUserForExtendInput;
}>;

export type GetFirebaseUserForExtendQuery = {
  __typename?: 'Query';
  getFirebaseUserForExtend: { __typename?: 'FirebaseUserForExtend'; username: string; firebaseId: string };
};

export type GetPlanEnrollmentsForExtendQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlanEnrollmentsForExtendQuery = {
  __typename?: 'Query';
  getPlanEnrollmentsForExtend: Array<{
    __typename?: 'PlanEnrollmentsForExtend';
    planName: string;
    totalEnrollments: number;
  }>;
};

export type GetCustomPlanBreakdownQueryVariables = Exact<{
  input?: InputMaybe<GetSessionSummaryByPlanInput>;
}>;

export type GetCustomPlanBreakdownQuery = {
  __typename?: 'Query';
  getSessionSummaryByPlan?: Array<{
    __typename?: 'SessionSummaryByPlan';
    planName: string;
    powerCostValue: number;
  }> | null;
};

export type GetSessionsConsumptionForExtendQueryVariables = Exact<{
  input: GetSessionsConsumptionForExtendInput;
}>;

export type GetSessionsConsumptionForExtendQuery = {
  __typename?: 'Query';
  getSessionsConsumptionForExtend?: {
    __typename?: 'GetSessionsConsumptionForExtendResponse';
    timeSeries?: Array<{
      __typename?: 'SessionsConsumptionForExtendData';
      totalSessions: number;
      powerCostValue: number;
      powerValue: number;
      timestamp?: any | null;
    } | null> | null;
  } | null;
};

export type GetSitesEvseCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetSitesEvseCountQuery = {
  __typename?: 'Query';
  getSitesForExtend?: Array<{ __typename?: 'Site'; evseCount?: number | null } | null> | null;
};

export type GetSiteForExtendQueryVariables = Exact<{
  siteInput?: InputMaybe<GetSiteForExtendInput>;
}>;

export type GetSiteForExtendQuery = {
  __typename?: 'Query';
  getSiteForExtend?: {
    __typename?: 'Site';
    altId: string;
    displayName?: string | null;
    directions?: string | null;
    address1?: string | null;
    address2?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    postalCode?: string | null;
  } | null;
};

export type GetSitesForExtendQueryVariables = Exact<{ [key: string]: never }>;

export type GetSitesForExtendQuery = {
  __typename?: 'Query';
  getSitesForExtend?: Array<{
    __typename?: 'Site';
    altId: string;
    siteName?: string | null;
    siteStatusId?: number | null;
    address1?: string | null;
    address2?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    postalCode?: string | null;
    country?: string | null;
    evseCount?: number | null;
    chargers?: {
      __typename?: 'ChargersWithMeta';
      total?: number | null;
      edges?: Array<{ __typename?: 'Charger'; altId: string; chargerName?: string | null } | null> | null;
    } | null;
  } | null> | null;
};

export type GetSitesShallowForExtendQueryVariables = Exact<{ [key: string]: never }>;

export type GetSitesShallowForExtendQuery = {
  __typename?: 'Query';
  getSitesForExtend?: Array<{
    __typename?: 'Site';
    siteName?: string | null;
    postalCode?: string | null;
  } | null> | null;
};

export type GetTariffForExtendQueryVariables = Exact<{
  input: GetTariffForExtendInput;
}>;

export type GetTariffForExtendQuery = {
  __typename?: 'Query';
  getTariffForExtend: {
    __typename?: 'TariffForExtend';
    altId: string;
    tariffDescription?: any | null;
    tariffDisplayName?: any | null;
    tariffName: any;
    tariffRank?: number | null;
    status?: TariffForExtendSyncStatus | null;
    chargerGroups?: Array<{
      __typename?: 'ChargerGroupForExtend';
      altId: string;
      chargerGroupName: string;
      chargerGroupType: string;
      chargerGroupTypeId: number;
      description?: string | null;
      createdAt: any;
      deletedAt?: any | null;
      planDuration?: number | null;
      planId?: string | null;
      status: ChargerGroupSyncStatus;
      chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
    } | null> | null;
    defaultPricing?: {
      __typename?: 'TariffPricingForExtend';
      displayRank?: number | null;
      displayName?: any | null;
      displayDescription1?: any | null;
      displayDescription2?: any | null;
      pricing?: {
        __typename?: 'PricingForExtend';
        perKwh?: any | null;
        perMinute?: any | null;
        perSession?: any | null;
      } | null;
    } | null;
    customPricing?: Array<{
      __typename?: 'TariffCustomPricingForExtend';
      altId: string;
      displayRank?: number | null;
      displayName?: any | null;
      displayDescription1?: any | null;
      displayDescription2?: any | null;
      pricing: {
        __typename?: 'TariffCustomPricesForExtend';
        perKwh?: any | null;
        perMinute?: any | null;
        perSession?: any | null;
      };
      tariffSchedules: Array<{
        __typename?: 'TariffScheduleForExtend';
        daysOfWeek: Array<Day>;
        endTime: string;
        startTime: string;
      }>;
    }> | null;
    reservationTerm?: {
      __typename?: 'TariffItemReservationTermForExtend';
      fee?: number | null;
      noShowFee?: number | null;
    } | null;
  };
};

export type ListChargerGroupsForExtendQueryVariables = Exact<{
  input: ListChargerGroupsForExtendInput;
}>;

export type ListChargerGroupsForExtendQuery = {
  __typename?: 'Query';
  listChargerGroupsForExtend?: {
    __typename?: 'ChargerGroupsForExtendWithMeta';
    total: number;
    edges: Array<{
      __typename?: 'ChargerGroupForExtend';
      altId: string;
      chargerGroupName: string;
      chargerGroupType: string;
      chargerGroupTypeId: number;
      description?: string | null;
      createdAt: any;
      deletedAt?: any | null;
      planDuration?: number | null;
      planId?: string | null;
      status: ChargerGroupSyncStatus;
      chargers: {
        __typename?: 'ChargersV2WithMeta';
        total?: number | null;
        edges?: Array<{ __typename?: 'ChargerV2'; altId: string } | null> | null;
      };
    }>;
  } | null;
};

export type ListChargerGroupsForChargerPricesQueryVariables = Exact<{
  input: ListChargerGroupsForExtendInput;
}>;

export type ListChargerGroupsForChargerPricesQuery = {
  __typename?: 'Query';
  listChargerGroupsForExtend?: {
    __typename?: 'ChargerGroupsForExtendWithMeta';
    total: number;
    edges: Array<{
      __typename?: 'ChargerGroupForExtend';
      altId: string;
      chargerGroupName: string;
      chargerGroupType: string;
      chargerGroupTypeId: number;
      description?: string | null;
      createdAt: any;
      deletedAt?: any | null;
      planDuration?: number | null;
      planId?: string | null;
      status: ChargerGroupSyncStatus;
      tariff?: { __typename?: 'TariffForExtend'; altId: string } | null;
      chargers: {
        __typename?: 'ChargersV2WithMeta';
        total?: number | null;
        edges?: Array<{ __typename?: 'ChargerV2'; altId: string } | null> | null;
      };
    }>;
  } | null;
};

export type ListChargerGroupsPlanTariffTagsForExtendQueryVariables = Exact<{
  input: ListChargerGroupsForExtendInput;
}>;

export type ListChargerGroupsPlanTariffTagsForExtendQuery = {
  __typename?: 'Query';
  listChargerGroupsForExtend?: {
    __typename?: 'ChargerGroupsForExtendWithMeta';
    edges: Array<{
      __typename?: 'ChargerGroupForExtend';
      altId: string;
      chargerGroupName: string;
      planTariffTags?: Array<{
        __typename?: 'PlanTariffTag';
        planChargerGroup?: {
          __typename?: 'ChargerGroupForExtend';
          altId: string;
          chargerGroupName: string;
          status: ChargerGroupSyncStatus;
          deletedAt?: any | null;
        } | null;
      } | null> | null;
    }>;
  } | null;
};

export type ListChargerGroupsTariffsForExtendQueryVariables = Exact<{
  input: ListChargerGroupsForExtendInput;
}>;

export type ListChargerGroupsTariffsForExtendQuery = {
  __typename?: 'Query';
  listChargerGroupsForExtend?: {
    __typename?: 'ChargerGroupsForExtendWithMeta';
    edges: Array<{
      __typename?: 'ChargerGroupForExtend';
      altId: string;
      tariff?: {
        __typename?: 'TariffForExtend';
        altId: string;
        tariffDescription?: any | null;
        tariffDisplayName?: any | null;
        tariffName: any;
        tariffRank?: number | null;
        status?: TariffForExtendSyncStatus | null;
        chargerGroups?: Array<{
          __typename?: 'ChargerGroupForExtend';
          altId: string;
          chargerGroupName: string;
          chargerGroupType: string;
          chargerGroupTypeId: number;
          description?: string | null;
          createdAt: any;
          deletedAt?: any | null;
          planDuration?: number | null;
          planId?: string | null;
          status: ChargerGroupSyncStatus;
          chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
        } | null> | null;
        defaultPricing?: {
          __typename?: 'TariffPricingForExtend';
          displayRank?: number | null;
          displayName?: any | null;
          displayDescription1?: any | null;
          displayDescription2?: any | null;
          pricing?: {
            __typename?: 'PricingForExtend';
            perKwh?: any | null;
            perMinute?: any | null;
            perSession?: any | null;
          } | null;
        } | null;
        customPricing?: Array<{
          __typename?: 'TariffCustomPricingForExtend';
          altId: string;
          displayRank?: number | null;
          displayName?: any | null;
          displayDescription1?: any | null;
          displayDescription2?: any | null;
          pricing: {
            __typename?: 'TariffCustomPricesForExtend';
            perKwh?: any | null;
            perMinute?: any | null;
            perSession?: any | null;
          };
          tariffSchedules: Array<{
            __typename?: 'TariffScheduleForExtend';
            daysOfWeek: Array<Day>;
            endTime: string;
            startTime: string;
          }>;
        }> | null;
        reservationTerm?: {
          __typename?: 'TariffItemReservationTermForExtend';
          fee?: number | null;
          noShowFee?: number | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type ListChargersConsumptionForExtendQueryVariables = Exact<{
  input: ListChargersConsumptionForExtendInput;
}>;

export type ListChargersConsumptionForExtendQuery = {
  __typename?: 'Query';
  listChargersConsumptionForExtend?: Array<{
    __typename?: 'ChargersConsumptionForExtendData';
    chargerId: string;
    chargerName: string;
    connectorMaxOutput: number;
    connectorStatus?: string | null;
    connectorType?: string | null;
    connectorId: string;
    evseId: string;
    totalSessions: number;
    totalSessionsPower: number;
    totalSessionsTime: string;
    lastSessionVendorId?: number | null;
    reservable?: boolean | null;
  }> | null;
};

export type ListChargersConsumptionOnlyStatusForExtendQueryVariables = Exact<{
  input: ListChargersConsumptionForExtendInput;
}>;

export type ListChargersConsumptionOnlyStatusForExtendQuery = {
  __typename?: 'Query';
  listChargersConsumptionForExtend?: Array<{
    __typename?: 'ChargersConsumptionForExtendData';
    connectorStatus?: string | null;
  }> | null;
};

export type ListChargersV2QueryVariables = Exact<{
  input?: InputMaybe<ListChargersV2Input>;
}>;

export type ListChargersV2Query = {
  __typename?: 'Query';
  listChargersV2?: {
    __typename?: 'ChargersV2WithMeta';
    total?: number | null;
    edges?: Array<{
      __typename?: 'ChargerV2';
      altId: string;
      setTariffSynced?: boolean | null;
      displayTariffSynced?: boolean | null;
      tariffSyncStatus?: TariffSyncStatus | null;
      chargerName?: string | null;
      chargerStatus?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
      evses?: Array<{
        __typename?: 'EvseV2';
        connectors?: Array<{
          __typename?: 'ConnectorV2';
          reservable?: boolean | null;
          chargingLevel: ChargingLevel;
          connectorStatus?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
        } | null> | null;
      } | null> | null;
      chargerModel?: { __typename?: 'ChargerModel'; modelName?: string | null } | null;
      site?: {
        __typename?: 'Site';
        address1?: string | null;
        address2?: string | null;
        postalCode?: string | null;
        administrativeArea?: string | null;
        siteName?: string | null;
        locality?: string | null;
        country?: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListChargersV2ShallowQueryVariables = Exact<{
  input?: InputMaybe<ListChargersV2Input>;
}>;

export type ListChargersV2ShallowQuery = {
  __typename?: 'Query';
  listChargersV2?: { __typename?: 'ChargersV2WithMeta'; total?: number | null } | null;
};

export type ListExtendHostsQueryVariables = Exact<{
  input?: InputMaybe<ListExtendHostsInput>;
}>;

export type ListExtendHostsQuery = {
  __typename?: 'Query';
  listExtendHosts?: {
    __typename?: 'ExtendHostsWithMeta';
    total?: number | null;
    edges?: Array<{
      __typename?: 'ExtendHost';
      altId: string;
      createdAt?: any | null;
      prefix?: string | null;
      host: {
        __typename?: 'Host';
        altId?: string | null;
        hostName?: string | null;
        hid?: string | null;
        status?: HostStatus | null;
        vendorId?: string | null;
      };
    }> | null;
  } | null;
};

export type ListExtendUsersQueryVariables = Exact<{
  input?: InputMaybe<ListExtendUsersInput>;
}>;

export type ListExtendUsersQuery = {
  __typename?: 'Query';
  listExtendUsers?: {
    __typename?: 'ExtendUsersWithMeta';
    total?: number | null;
    edges?: Array<{
      __typename?: 'ExtendUser';
      altId: string;
      username: string;
      firebaseId: string;
      createdAt?: any | null;
      hosts: {
        __typename?: 'HostsWithMeta';
        total?: number | null;
        edges?: Array<{ __typename?: 'Host'; altId?: string | null; hostName?: string | null } | null> | null;
      };
    } | null> | null;
  } | null;
};

export type ListSessionForDashboardLiveSessionsQueryVariables = Exact<{
  input: ListSessionsV2Input;
}>;

export type ListSessionForDashboardLiveSessionsQuery = {
  __typename?: 'Query';
  listSessionsV2?: {
    __typename?: 'SessionsV2WithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'SessionV2';
      vendorId: string;
      meterStart?: number | null;
      lastMeterValue?: number | null;
      connector?: {
        __typename?: 'ConnectorV2';
        evse?: {
          __typename?: 'EvseV2';
          charger?: {
            __typename?: 'ChargerV2';
            altId: string;
            site?: { __typename?: 'Site'; sid?: string | null; siteName?: string | null } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListSessionsForMapQueryVariables = Exact<{
  input: ListSessionsV2Input;
}>;

export type ListSessionsForMapQuery = {
  __typename?: 'Query';
  listSessionsV2?: {
    __typename?: 'SessionsV2WithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'SessionV2';
      connector?: {
        __typename?: 'ConnectorV2';
        evse?: {
          __typename?: 'EvseV2';
          charger?: {
            __typename?: 'ChargerV2';
            site?: {
              __typename?: 'Site';
              latitude?: number | null;
              longitude?: number | null;
              administrativeArea?: string | null;
              siteName?: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListSessionsV2ExtendQueryVariables = Exact<{
  input: ListSessionsV2Input;
}>;

export type ListSessionsV2ExtendQuery = {
  __typename?: 'Query';
  listSessionsV2?: {
    __typename?: 'SessionsV2WithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'SessionV2';
      altId: string;
      vendorId: string;
      startTime?: any | null;
      endTime?: any | null;
      meterStart?: number | null;
      lastMeterValue?: number | null;
      cost?: number | null;
      connector?: {
        __typename?: 'ConnectorV2';
        connectorType?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
        evse?: {
          __typename?: 'EvseV2';
          charger?: {
            __typename?: 'ChargerV2';
            chargerName?: string | null;
            site?: { __typename?: 'Site'; siteName?: string | null; timeZone?: string | null } | null;
          } | null;
        } | null;
      } | null;
      plan?: {
        __typename?: 'PlanV2';
        planName?: string | null;
        chargerGroup?: { __typename?: 'ChargerGroupForExtend'; chargerGroupName: string; altId: string } | null;
      } | null;
      stopSource?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
      startSource?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
      transaction?: {
        __typename?: 'TransactionV2';
        altId: string;
        tax?: number | null;
        type?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListSitesConsumptionForExtendQueryVariables = Exact<{
  input: ListSitesConsumptionForExtendInput;
}>;

export type ListSitesConsumptionForExtendQuery = {
  __typename?: 'Query';
  listSitesConsumptionForExtend: {
    __typename?: 'ListSitesConsumptionForExtendWithMeta';
    page: number;
    pageSize: number;
    edges?: Array<{
      __typename?: 'ListSitesConsumptionForExtendData';
      address?: string | null;
      siteId: string;
      siteName: string;
      siteStatus: string;
      administrativeArea?: string | null;
      siteLatitude?: number | null;
      siteLongitude?: number | null;
      totalChargers: number;
      totalSessions: number;
      totalSessionsCost: number;
      totalSessionsPower: number;
      averageSessionPower: number;
      totalSessionsTime?: string | null;
      averageSessionTime?: string | null;
    }> | null;
  };
};

export type ListTariffsForExtendQueryVariables = Exact<{
  input: ListTariffsForExtendInput;
}>;

export type ListTariffsForExtendQuery = {
  __typename?: 'Query';
  listTariffsForExtend: {
    __typename?: 'TariffsForExtendWithMeta';
    total?: number | null;
    edges?: Array<{
      __typename?: 'TariffForExtend';
      altId: string;
      tariffDescription?: any | null;
      tariffDisplayName?: any | null;
      tariffName: any;
      tariffRank?: number | null;
      status?: TariffForExtendSyncStatus | null;
      chargerGroups?: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
        chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
      } | null> | null;
      defaultPricing?: {
        __typename?: 'TariffPricingForExtend';
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing?: {
          __typename?: 'PricingForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        } | null;
      } | null;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
      reservationTerm?: {
        __typename?: 'TariffItemReservationTermForExtend';
        fee?: number | null;
        noShowFee?: number | null;
      } | null;
    }> | null;
  };
};

export type ListTransactionsV2ExtendQueryVariables = Exact<{
  input: ListTransactionsV2Input;
}>;

export type ListTransactionsV2ExtendQuery = {
  __typename?: 'Query';
  listTransactionsV2?: {
    __typename?: 'TransactionsV2WithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'TransactionV2';
      vendorId?: string | null;
      altId: string;
      tax?: number | null;
      amount?: string | null;
      calculatedAmount?: number | null;
      calculatedTaxAmount?: number | null;
      isCalculated?: boolean | null;
      items?: Array<{
        __typename?: 'TransactionItemV2';
        altId: string;
        quantity?: number | null;
        unitPrice?: number | null;
        taxRate?: number | null;
        calculatedAmount?: number | null;
        calculatedUnitPrice?: number | null;
        productType?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
      } | null> | null;
      session?: {
        __typename?: 'SessionV2';
        altId: string;
        vendorId: string;
        startTime?: any | null;
        endTime?: any | null;
        startTimeLocal?: any | null;
        endTimeLocal?: any | null;
        meterStart?: number | null;
        meterEnd?: number | null;
        cost?: number | null;
        connector?: {
          __typename?: 'ConnectorV2';
          connectorType?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
          evse?: {
            __typename?: 'EvseV2';
            charger?: {
              __typename?: 'ChargerV2';
              chargerName?: string | null;
              site?: { __typename?: 'Site'; siteName?: string | null; timeZone?: string | null } | null;
            } | null;
          } | null;
        } | null;
        plan?: {
          __typename?: 'PlanV2';
          planName?: string | null;
          chargerGroup?: { __typename?: 'ChargerGroupForExtend'; altId: string; chargerGroupName: string } | null;
        } | null;
        stopSource?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
        startSource?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
      } | null;
      type?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
    } | null> | null;
  } | null;
};

export type RemoveChargersFromChargerGroupForExtendMutationVariables = Exact<{
  input: RemoveChargersFromChargerGroupForExtendInput;
}>;

export type RemoveChargersFromChargerGroupForExtendMutation = {
  __typename?: 'Mutation';
  removeChargersFromChargerGroupForExtend?: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
    tariff?: {
      __typename?: 'TariffForExtend';
      altId: string;
      tariffDescription?: any | null;
      tariffDisplayName?: any | null;
      tariffName: any;
      tariffRank?: number | null;
      status?: TariffForExtendSyncStatus | null;
      chargerGroups?: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
        chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
      } | null> | null;
      defaultPricing?: {
        __typename?: 'TariffPricingForExtend';
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing?: {
          __typename?: 'PricingForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        } | null;
      } | null;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
      reservationTerm?: {
        __typename?: 'TariffItemReservationTermForExtend';
        fee?: number | null;
        noShowFee?: number | null;
      } | null;
    } | null;
    planTariffs: Array<{
      __typename?: 'PlanTariffForExtend';
      altId: string;
      tariffName: any;
      tariffDescription?: any | null;
      chargerGroups: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
      }>;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
    } | null>;
    chargers: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        chargerName?: string | null;
        chargerStatus?: string | null;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        displayTariffSynced?: boolean | null;
        setTariffSynced?: boolean | null;
        tariffSyncStatus?: TariffSyncStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        evses?: {
          __typename?: 'EvsesWithMeta';
          edges?: Array<{
            __typename?: 'Evse';
            connectors?: {
              __typename?: 'ConnectorsWithMeta';
              edges?: Array<{
                __typename?: 'Connector';
                chargingLevel: ChargingLevel;
                reservable?: boolean | null;
                connectorStatusConstraint?: {
                  __typename?: 'FalconConstraint';
                  columnValue?: string | null;
                  columnText?: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          siteName?: string | null;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
    };
  } | null;
};

export type ResetChargerForExtendMutationVariables = Exact<{
  chargerInput: ResetChargerForExtendInput;
}>;

export type ResetChargerForExtendMutation = { __typename?: 'Mutation'; resetChargerForExtend?: any | null };

export type UpdateChargerPriceGroupForExtendMutationVariables = Exact<{
  input: UpdateChargerPriceGroupForExtendInput;
}>;

export type UpdateChargerPriceGroupForExtendMutation = {
  __typename?: 'Mutation';
  updateChargerPriceGroupForExtend?: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
    tariff?: {
      __typename?: 'TariffForExtend';
      altId: string;
      tariffDescription?: any | null;
      tariffDisplayName?: any | null;
      tariffName: any;
      tariffRank?: number | null;
      status?: TariffForExtendSyncStatus | null;
      chargerGroups?: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
        chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
      } | null> | null;
      defaultPricing?: {
        __typename?: 'TariffPricingForExtend';
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing?: {
          __typename?: 'PricingForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        } | null;
      } | null;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
      reservationTerm?: {
        __typename?: 'TariffItemReservationTermForExtend';
        fee?: number | null;
        noShowFee?: number | null;
      } | null;
    } | null;
    planTariffs: Array<{
      __typename?: 'PlanTariffForExtend';
      altId: string;
      tariffName: any;
      tariffDescription?: any | null;
      chargerGroups: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
      }>;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
    } | null>;
    chargers: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        chargerName?: string | null;
        chargerStatus?: string | null;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        displayTariffSynced?: boolean | null;
        setTariffSynced?: boolean | null;
        tariffSyncStatus?: TariffSyncStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        evses?: {
          __typename?: 'EvsesWithMeta';
          edges?: Array<{
            __typename?: 'Evse';
            connectors?: {
              __typename?: 'ConnectorsWithMeta';
              edges?: Array<{
                __typename?: 'Connector';
                chargingLevel: ChargingLevel;
                reservable?: boolean | null;
                connectorStatusConstraint?: {
                  __typename?: 'FalconConstraint';
                  columnValue?: string | null;
                  columnText?: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          siteName?: string | null;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
    };
  } | null;
};

export type UpdateChargerGroupPlanForExtendMutationVariables = Exact<{
  input: UpdateChargerGroupPlanForExtendInput;
}>;

export type UpdateChargerGroupPlanForExtendMutation = {
  __typename?: 'Mutation';
  updateChargerGroupPlanForExtend?: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
    tariff?: {
      __typename?: 'TariffForExtend';
      altId: string;
      tariffDescription?: any | null;
      tariffDisplayName?: any | null;
      tariffName: any;
      tariffRank?: number | null;
      status?: TariffForExtendSyncStatus | null;
      chargerGroups?: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
        chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
      } | null> | null;
      defaultPricing?: {
        __typename?: 'TariffPricingForExtend';
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing?: {
          __typename?: 'PricingForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        } | null;
      } | null;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
      reservationTerm?: {
        __typename?: 'TariffItemReservationTermForExtend';
        fee?: number | null;
        noShowFee?: number | null;
      } | null;
    } | null;
    planTariffs: Array<{
      __typename?: 'PlanTariffForExtend';
      altId: string;
      tariffName: any;
      tariffDescription?: any | null;
      chargerGroups: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
      }>;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
    } | null>;
    chargers: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        chargerName?: string | null;
        chargerStatus?: string | null;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        displayTariffSynced?: boolean | null;
        setTariffSynced?: boolean | null;
        tariffSyncStatus?: TariffSyncStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        evses?: {
          __typename?: 'EvsesWithMeta';
          edges?: Array<{
            __typename?: 'Evse';
            connectors?: {
              __typename?: 'ConnectorsWithMeta';
              edges?: Array<{
                __typename?: 'Connector';
                chargingLevel: ChargingLevel;
                reservable?: boolean | null;
                connectorStatusConstraint?: {
                  __typename?: 'FalconConstraint';
                  columnValue?: string | null;
                  columnText?: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          siteName?: string | null;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
    };
  } | null;
};

export type UpdateExtendUserHostsMutationVariables = Exact<{
  extenduserInput?: InputMaybe<UpdateExtendUserHostsInput>;
}>;

export type UpdateExtendUserHostsMutation = {
  __typename?: 'Mutation';
  updateExtendUserHosts?: {
    __typename?: 'ExtendUser';
    altId: string;
    hosts: {
      __typename?: 'HostsWithMeta';
      edges?: Array<{ __typename?: 'Host'; hostName?: string | null; hid?: string | null } | null> | null;
    };
  } | null;
};

export type UpdatePlanTariffsForExtendMutationVariables = Exact<{
  input: UpdatePlanTariffsForExtendInput;
}>;

export type UpdatePlanTariffsForExtendMutation = {
  __typename?: 'Mutation';
  updatePlanTariffsForExtend: {
    __typename?: 'ChargerGroupForExtend';
    altId: string;
    chargerGroupName: string;
    chargerGroupType: string;
    chargerGroupTypeId: number;
    description?: string | null;
    createdAt: any;
    deletedAt?: any | null;
    planDuration?: number | null;
    planId?: string | null;
    status: ChargerGroupSyncStatus;
    tariff?: {
      __typename?: 'TariffForExtend';
      altId: string;
      tariffDescription?: any | null;
      tariffDisplayName?: any | null;
      tariffName: any;
      tariffRank?: number | null;
      status?: TariffForExtendSyncStatus | null;
      chargerGroups?: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
        chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
      } | null> | null;
      defaultPricing?: {
        __typename?: 'TariffPricingForExtend';
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing?: {
          __typename?: 'PricingForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        } | null;
      } | null;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
      reservationTerm?: {
        __typename?: 'TariffItemReservationTermForExtend';
        fee?: number | null;
        noShowFee?: number | null;
      } | null;
    } | null;
    planTariffs: Array<{
      __typename?: 'PlanTariffForExtend';
      altId: string;
      tariffName: any;
      tariffDescription?: any | null;
      chargerGroups: Array<{
        __typename?: 'ChargerGroupForExtend';
        altId: string;
        chargerGroupName: string;
        chargerGroupType: string;
        chargerGroupTypeId: number;
        description?: string | null;
        createdAt: any;
        deletedAt?: any | null;
        planDuration?: number | null;
        planId?: string | null;
        status: ChargerGroupSyncStatus;
      }>;
      customPricing?: Array<{
        __typename?: 'TariffCustomPricingForExtend';
        altId: string;
        displayRank?: number | null;
        displayName?: any | null;
        displayDescription1?: any | null;
        displayDescription2?: any | null;
        pricing: {
          __typename?: 'TariffCustomPricesForExtend';
          perKwh?: any | null;
          perMinute?: any | null;
          perSession?: any | null;
        };
        tariffSchedules: Array<{
          __typename?: 'TariffScheduleForExtend';
          daysOfWeek: Array<Day>;
          endTime: string;
          startTime: string;
        }>;
      }> | null;
    } | null>;
    chargers: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        chargerName?: string | null;
        chargerStatus?: string | null;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        displayTariffSynced?: boolean | null;
        setTariffSynced?: boolean | null;
        tariffSyncStatus?: TariffSyncStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        evses?: {
          __typename?: 'EvsesWithMeta';
          edges?: Array<{
            __typename?: 'Evse';
            connectors?: {
              __typename?: 'ConnectorsWithMeta';
              edges?: Array<{
                __typename?: 'Connector';
                chargingLevel: ChargingLevel;
                reservable?: boolean | null;
                connectorStatusConstraint?: {
                  __typename?: 'FalconConstraint';
                  columnValue?: string | null;
                  columnText?: string | null;
                } | null;
              } | null> | null;
            } | null;
          } | null> | null;
        } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          siteName?: string | null;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          latitude?: number | null;
          longitude?: number | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
    };
  };
};

export type UpdateSiteForExtendMutationVariables = Exact<{
  siteInput?: InputMaybe<UpdateSiteForExtendInput>;
}>;

export type UpdateSiteForExtendMutation = {
  __typename?: 'Mutation';
  updateSiteForExtend: { __typename?: 'Site'; altId: string; directions?: string | null };
};

export type UpdateTariffForExtendMutationVariables = Exact<{
  input: UpdateTariffForExtendInput;
}>;

export type UpdateTariffForExtendMutation = {
  __typename?: 'Mutation';
  updateTariffForExtend: {
    __typename?: 'TariffForExtend';
    altId: string;
    tariffDescription?: any | null;
    tariffDisplayName?: any | null;
    tariffName: any;
    tariffRank?: number | null;
    status?: TariffForExtendSyncStatus | null;
    chargerGroups?: Array<{
      __typename?: 'ChargerGroupForExtend';
      altId: string;
      chargerGroupName: string;
      chargerGroupType: string;
      chargerGroupTypeId: number;
      description?: string | null;
      createdAt: any;
      deletedAt?: any | null;
      planDuration?: number | null;
      planId?: string | null;
      status: ChargerGroupSyncStatus;
      chargers: { __typename?: 'ChargersV2WithMeta'; total?: number | null };
    } | null> | null;
    defaultPricing?: {
      __typename?: 'TariffPricingForExtend';
      displayRank?: number | null;
      displayName?: any | null;
      displayDescription1?: any | null;
      displayDescription2?: any | null;
      pricing?: {
        __typename?: 'PricingForExtend';
        perKwh?: any | null;
        perMinute?: any | null;
        perSession?: any | null;
      } | null;
    } | null;
    customPricing?: Array<{
      __typename?: 'TariffCustomPricingForExtend';
      altId: string;
      displayRank?: number | null;
      displayName?: any | null;
      displayDescription1?: any | null;
      displayDescription2?: any | null;
      pricing: {
        __typename?: 'TariffCustomPricesForExtend';
        perKwh?: any | null;
        perMinute?: any | null;
        perSession?: any | null;
      };
      tariffSchedules: Array<{
        __typename?: 'TariffScheduleForExtend';
        daysOfWeek: Array<Day>;
        endTime: string;
        startTime: string;
      }>;
    }> | null;
    reservationTerm?: {
      __typename?: 'TariffItemReservationTermForExtend';
      fee?: number | null;
      noShowFee?: number | null;
    } | null;
  };
};

export type ListGeographicalRegionsQueryVariables = Exact<{
  usaInput: ListGeographicalRegionsInput;
  canadaInput: ListGeographicalRegionsInput;
}>;

export type ListGeographicalRegionsQuery = {
  __typename?: 'Query';
  listGeographicalRegionsUsa?: {
    __typename?: 'GeographicalRegionsWithMeta';
    total?: number | null;
    edges?: Array<{ __typename?: 'GeographicalRegion'; value?: string | null; label?: string | null } | null> | null;
  } | null;
  listGeographicalRegionsCanada?: {
    __typename?: 'GeographicalRegionsWithMeta';
    total?: number | null;
    edges?: Array<{ __typename?: 'GeographicalRegion'; value?: string | null; label?: string | null } | null> | null;
  } | null;
};

export type CreateHostMutationVariables = Exact<{
  input?: InputMaybe<CreateHostInput>;
}>;

export type CreateHostMutation = {
  __typename?: 'Mutation';
  createHost: {
    __typename?: 'Host';
    hid?: string | null;
    altId?: string | null;
    address1?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    hostName?: string | null;
    postalCode?: string | null;
    status?: HostStatus | null;
  };
};

export type GetHostByIdQueryVariables = Exact<{
  hostInput?: InputMaybe<GetHostInput>;
  propertiesInput?: InputMaybe<ListPropertiesInput>;
  sitesInput?: InputMaybe<ListSitesInput>;
  contactsInput?: InputMaybe<ListContactsInput>;
}>;

export type GetHostByIdQuery = {
  __typename?: 'Query';
  getHost?: {
    __typename?: 'Host';
    hid?: string | null;
    altId?: string | null;
    hostName?: string | null;
    address1?: string | null;
    address2?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    postalCode?: string | null;
    country?: string | null;
    status?: HostStatus | null;
    contacts?: {
      __typename?: 'ContactsWithMeta';
      edges?: Array<{
        __typename?: 'Contact';
        altId?: string | null;
        contactName: string;
        workTitle?: string | null;
        email?: string | null;
        phone?: string | null;
      } | null> | null;
    } | null;
    properties?: {
      __typename?: 'PropertiesWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Property';
        address1?: string | null;
        address2?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        postalCode?: string | null;
        country?: string | null;
        altId: string;
        propertyName?: string | null;
        pid?: string | null;
        status?: PropertyStatus | null;
        sites?: {
          __typename?: 'SitesWithMeta';
          total?: number | null;
          edges?: Array<{
            __typename?: 'Site';
            altId: string;
            siteName?: string | null;
            address1?: string | null;
            address2?: string | null;
            locality?: string | null;
            administrativeArea?: string | null;
            postalCode?: string | null;
            country?: string | null;
            sid?: string | null;
            displayName?: string | null;
            latitude?: number | null;
            longitude?: number | null;
            access?: SiteAccess | null;
            siteStatusId?: number | null;
            type?: SiteType | null;
            chargers?: { __typename?: 'ChargersWithMeta'; total?: number | null } | null;
          } | null> | null;
        } | null;
      } | null> | null;
      sort?: {
        __typename?: 'ListPropertiesSortType';
        altId?: TableSortType | null;
        pid?: TableSortType | null;
        propertyName?: TableSortType | null;
        hostName?: TableSortType | null;
        status?: TableSortType | null;
      } | null;
      search?: {
        __typename?: 'ListPropertiesSearchType';
        pid?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        propertyName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        locality?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        administrativeArea?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        postalCode?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      } | null;
    } | null;
  } | null;
};

export type GetHostByNameQueryVariables = Exact<{
  input?: InputMaybe<GetHostInput>;
}>;

export type GetHostByNameQuery = {
  __typename?: 'Query';
  getHost?: { __typename?: 'Host'; altId?: string | null } | null;
};

export type GetHostsTotalQueryVariables = Exact<{
  input?: InputMaybe<ListHostsInput>;
}>;

export type GetHostsTotalQuery = {
  __typename?: 'Query';
  listHosts?: { __typename?: 'HostsWithMeta'; total?: number | null } | null;
};

export type GetSalesforceHostByIdQueryVariables = Exact<{
  input?: InputMaybe<GetSalesforceHostInput>;
}>;

export type GetSalesforceHostByIdQuery = {
  __typename?: 'Query';
  getSalesforceHost?: {
    __typename?: 'SalesforceHost';
    address1?: string | null;
    address2?: string | null;
    locality?: string | null;
    hostName?: string | null;
    postalCode?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    hid?: string | null;
    salesforceHostContacts?: {
      __typename?: 'SalesforceHostContactsWithMeta';
      edges?: Array<{
        __typename?: 'SalesforceHostContact';
        contactName?: string | null;
        phone?: string | null;
        email?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ImportHostMutationVariables = Exact<{
  hostInput?: InputMaybe<ImportHostInput>;
}>;

export type ImportHostMutation = {
  __typename?: 'Mutation';
  importHost: {
    __typename?: 'Host';
    hid?: string | null;
    altId?: string | null;
    address1?: string | null;
    address2?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    hostName?: string | null;
    postalCode?: string | null;
    status?: HostStatus | null;
  };
};

export type ListHostsQueryVariables = Exact<{
  hostsInput?: InputMaybe<ListHostsInput>;
  propertiesInput?: InputMaybe<ListPropertiesInput>;
  sitesInput?: InputMaybe<ListSitesInput>;
}>;

export type ListHostsQuery = {
  __typename?: 'Query';
  listHosts?: {
    __typename?: 'HostsWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'Host';
      hid?: string | null;
      altId?: string | null;
      hostName?: string | null;
      status?: HostStatus | null;
      properties?: {
        __typename?: 'PropertiesWithMeta';
        edges?: Array<{
          __typename?: 'Property';
          pid?: string | null;
          altId: string;
          propertyName?: string | null;
          sites?: {
            __typename?: 'SitesWithMeta';
            total?: number | null;
            edges?: Array<{
              __typename?: 'Site';
              chargers?: { __typename?: 'ChargersWithMeta'; total?: number | null } | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
    sort?: {
      __typename?: 'ListHostSortType';
      altId?: TableSortType | null;
      hid?: TableSortType | null;
      hostName?: TableSortType | null;
      status?: TableSortType | null;
    } | null;
    filter?: {
      __typename?: 'ListHostFilterType';
      status?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
    } | null;
    search?: {
      __typename?: 'ListHostSearchType';
      hid?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
  } | null;
};

export type ListSalesforceHostContactsQueryVariables = Exact<{
  input?: InputMaybe<ListSalesforceHostContactsInput>;
}>;

export type ListSalesforceHostContactsQuery = {
  __typename?: 'Query';
  listSalesforceHostContacts?: {
    __typename?: 'SalesforceHostContactsWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'SalesforceHostContact';
      contactName?: string | null;
      phone?: string | null;
      email?: string | null;
      workTitle?: string | null;
    } | null> | null;
  } | null;
};

export type ListSalesforcePropertiesQueryVariables = Exact<{
  input?: InputMaybe<ListSalesforcePropertiesInput>;
}>;

export type ListSalesforcePropertiesQuery = {
  __typename?: 'Query';
  listSalesforceProperties?: {
    __typename?: 'SalesforcePropertiesWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'SalesforceProperty';
      hid?: string | null;
      pid?: string | null;
      evcId?: string | null;
      propertyName?: string | null;
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      address4?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      postalCode?: string | null;
      country?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateHostMutationVariables = Exact<{
  hostInput?: InputMaybe<UpdateHostInput>;
}>;

export type UpdateHostMutation = {
  __typename?: 'Mutation';
  updateHost: { __typename?: 'Host'; altId?: string | null };
};

export type LoginMutationVariables = Exact<{
  loginInput: LoginInput;
}>;

export type LoginMutation = { __typename?: 'Mutation'; login?: any | null };

export type CreateUploadMutationVariables = Exact<{
  input: Scalars['Upload'];
}>;

export type CreateUploadMutation = {
  __typename?: 'Mutation';
  createUpload: {
    __typename?: 'Media';
    altId: any;
    url: string;
    fileName: string;
    mimeType: string;
    encoding?: string | null;
    createdAt?: any | null;
  };
};

export type FullChargerModelFragment = {
  __typename?: 'ChargerModel';
  altId: string;
  additionalDetails?: string | null;
  autoChargeSupported?: boolean | null;
  breakerRating?: number | null;
  communicationProtocol?: string | null;
  currentThd?: string | null;
  dimensions?: string | null;
  display?: string | null;
  efficiency?: string | null;
  firmwareVersion?: string | null;
  ingressProtection?: string | null;
  inputAcVoltage?: InputAcVoltage | null;
  interfaceVersion?: InterfaceVersion | null;
  isoIec?: boolean | null;
  manufacturer?: Manufacturer | null;
  modelName?: string | null;
  modelSku?: string | null;
  networkCommunication?: NetworkCommunication | null;
  nfcReader?: boolean | null;
  powerFactor?: number | null;
  powerOutput?: any | null;
  simultaneousChargeSupported?: boolean | null;
  smartEnergyProfileSupport?: boolean | null;
  unitType?: UnitType | null;
  weight?: string | null;
  evses?: {
    __typename?: 'ChargerModelEvsesWithMeta';
    edges?: Array<{
      __typename?: 'ChargerModelEvse';
      altId?: string | null;
      connectors?: {
        __typename?: 'ConnectorsWithMeta';
        edges?: Array<{
          __typename?: 'Connector';
          altId: string;
          connectorMaxCurrent?: number | null;
          cableCooling?: CableCooling | null;
          cableLength?: CableLength | null;
          cableManufacturer?: CableManufacturer | null;
          outputVoltageRange?: string | null;
          connectorType?: { __typename?: 'FalconConstraint'; id: string; columnText?: string | null } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CreateChargerModelMutationVariables = Exact<{
  modelInput?: InputMaybe<CreateChargerModelInput>;
}>;

export type CreateChargerModelMutation = {
  __typename?: 'Mutation';
  createChargerModel: {
    __typename?: 'ChargerModel';
    altId: string;
    additionalDetails?: string | null;
    autoChargeSupported?: boolean | null;
    breakerRating?: number | null;
    communicationProtocol?: string | null;
    currentThd?: string | null;
    dimensions?: string | null;
    display?: string | null;
    efficiency?: string | null;
    firmwareVersion?: string | null;
    ingressProtection?: string | null;
    inputAcVoltage?: InputAcVoltage | null;
    interfaceVersion?: InterfaceVersion | null;
    isoIec?: boolean | null;
    manufacturer?: Manufacturer | null;
    modelName?: string | null;
    modelSku?: string | null;
    networkCommunication?: NetworkCommunication | null;
    nfcReader?: boolean | null;
    powerFactor?: number | null;
    powerOutput?: any | null;
    simultaneousChargeSupported?: boolean | null;
    smartEnergyProfileSupport?: boolean | null;
    unitType?: UnitType | null;
    weight?: string | null;
    evses?: {
      __typename?: 'ChargerModelEvsesWithMeta';
      edges?: Array<{
        __typename?: 'ChargerModelEvse';
        altId?: string | null;
        connectors?: {
          __typename?: 'ConnectorsWithMeta';
          edges?: Array<{
            __typename?: 'Connector';
            altId: string;
            connectorMaxCurrent?: number | null;
            cableCooling?: CableCooling | null;
            cableLength?: CableLength | null;
            cableManufacturer?: CableManufacturer | null;
            outputVoltageRange?: string | null;
            connectorType?: { __typename?: 'FalconConstraint'; id: string; columnText?: string | null } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  };
};

export type GetChargerModelQueryVariables = Exact<{
  chargerModelInput?: InputMaybe<GetChargerModelInput>;
}>;

export type GetChargerModelQuery = {
  __typename?: 'Query';
  getChargerModel?: {
    __typename?: 'ChargerModel';
    altId: string;
    additionalDetails?: string | null;
    autoChargeSupported?: boolean | null;
    breakerRating?: number | null;
    communicationProtocol?: string | null;
    currentThd?: string | null;
    dimensions?: string | null;
    display?: string | null;
    efficiency?: string | null;
    firmwareVersion?: string | null;
    ingressProtection?: string | null;
    inputAcVoltage?: InputAcVoltage | null;
    interfaceVersion?: InterfaceVersion | null;
    isoIec?: boolean | null;
    manufacturer?: Manufacturer | null;
    modelName?: string | null;
    modelSku?: string | null;
    networkCommunication?: NetworkCommunication | null;
    nfcReader?: boolean | null;
    powerFactor?: number | null;
    powerOutput?: any | null;
    simultaneousChargeSupported?: boolean | null;
    smartEnergyProfileSupport?: boolean | null;
    unitType?: UnitType | null;
    weight?: string | null;
    evses?: {
      __typename?: 'ChargerModelEvsesWithMeta';
      edges?: Array<{
        __typename?: 'ChargerModelEvse';
        altId?: string | null;
        connectors?: {
          __typename?: 'ConnectorsWithMeta';
          edges?: Array<{
            __typename?: 'Connector';
            altId: string;
            connectorMaxCurrent?: number | null;
            cableCooling?: CableCooling | null;
            cableLength?: CableLength | null;
            cableManufacturer?: CableManufacturer | null;
            outputVoltageRange?: string | null;
            connectorType?: { __typename?: 'FalconConstraint'; id: string; columnText?: string | null } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetModelOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetModelOptionsQuery = {
  __typename?: 'Query';
  manufacturers?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; value: string; label?: string | null }> | null;
  } | null;
  unitTypes?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; value: string; label?: string | null }> | null;
  } | null;
  cableManufacturers?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; value: string; label?: string | null }> | null;
  } | null;
  connectorTypes?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; value: string; label?: string | null }> | null;
  } | null;
  inputAcVoltage?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; value: string; label?: string | null }> | null;
  } | null;
  cableLength?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; value: string; label?: string | null }> | null;
  } | null;
  cableCooling?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; value: string; label?: string | null }> | null;
  } | null;
  interfaceVersion?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; value: string; label?: string | null }> | null;
  } | null;
  networkCommunication?: {
    __typename?: '__Type';
    enumValues?: Array<{ __typename?: '__EnumValue'; value: string; label?: string | null }> | null;
  } | null;
};

export type ListChargerModelOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ListChargerModelOptionsQuery = {
  __typename?: 'Query';
  listChargerModelOptions?: Array<{
    __typename?: 'ChargerModel';
    altId: string;
    modelName?: string | null;
  } | null> | null;
};

export type ListChargerModelsQueryVariables = Exact<{
  input?: InputMaybe<ListChargerModelsInput>;
}>;

export type ListChargerModelsQuery = {
  __typename?: 'Query';
  listChargerModels?: {
    __typename?: 'ChargerModelsWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'ChargerModel';
      altId: string;
      breakerRating?: number | null;
      currentThd?: string | null;
      efficiency?: string | null;
      inputAcVoltage?: InputAcVoltage | null;
      manufacturer?: Manufacturer | null;
      modelName?: string | null;
      powerFactor?: number | null;
      powerOutput?: any | null;
      simultaneousChargeSupported?: boolean | null;
      unitType?: UnitType | null;
      evses?: {
        __typename?: 'ChargerModelEvsesWithMeta';
        edges?: Array<{
          __typename?: 'ChargerModelEvse';
          connectors?: {
            __typename?: 'ConnectorsWithMeta';
            edges?: Array<{ __typename?: 'Connector'; altId: string } | null> | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
    sort?: {
      __typename?: 'ListChargerModelsSortType';
      modelName?: TableSortType | null;
      manufacturer?: TableSortType | null;
      unitType?: TableSortType | null;
    } | null;
    filter?: {
      __typename?: 'ListChargerModelsFilterType';
      manufacturer?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
    } | null;
    search?: {
      __typename?: 'ListChargerModelsSearchType';
      modelName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
  } | null;
};

export type ListVehicleConnectorsQueryVariables = Exact<{
  input?: InputMaybe<ListVehicleConnectorsInput>;
}>;

export type ListVehicleConnectorsQuery = {
  __typename?: 'Query';
  listVehicleConnectors?: {
    __typename?: 'VehicleConnectorsWithMeta';
    edges?: Array<{ __typename?: 'VehicleConnector'; id?: number | null; connectorName?: string | null } | null> | null;
  } | null;
};

export type UpdateChargerModelMutationVariables = Exact<{
  modelInput?: InputMaybe<UpdateChargerModelInput>;
}>;

export type UpdateChargerModelMutation = {
  __typename?: 'Mutation';
  updateChargerModel: {
    __typename?: 'ChargerModel';
    altId: string;
    additionalDetails?: string | null;
    autoChargeSupported?: boolean | null;
    breakerRating?: number | null;
    communicationProtocol?: string | null;
    currentThd?: string | null;
    dimensions?: string | null;
    display?: string | null;
    efficiency?: string | null;
    firmwareVersion?: string | null;
    ingressProtection?: string | null;
    inputAcVoltage?: InputAcVoltage | null;
    interfaceVersion?: InterfaceVersion | null;
    isoIec?: boolean | null;
    manufacturer?: Manufacturer | null;
    modelName?: string | null;
    modelSku?: string | null;
    networkCommunication?: NetworkCommunication | null;
    nfcReader?: boolean | null;
    powerFactor?: number | null;
    powerOutput?: any | null;
    simultaneousChargeSupported?: boolean | null;
    smartEnergyProfileSupport?: boolean | null;
    unitType?: UnitType | null;
    weight?: string | null;
    evses?: {
      __typename?: 'ChargerModelEvsesWithMeta';
      edges?: Array<{
        __typename?: 'ChargerModelEvse';
        altId?: string | null;
        connectors?: {
          __typename?: 'ConnectorsWithMeta';
          edges?: Array<{
            __typename?: 'Connector';
            altId: string;
            connectorMaxCurrent?: number | null;
            cableCooling?: CableCooling | null;
            cableLength?: CableLength | null;
            cableManufacturer?: CableManufacturer | null;
            outputVoltageRange?: string | null;
            connectorType?: { __typename?: 'FalconConstraint'; id: string; columnText?: string | null } | null;
          } | null> | null;
        } | null;
      } | null> | null;
    } | null;
  };
};

export type ListNotificationGroupsQueryVariables = Exact<{
  notificationGroupsInput?: InputMaybe<ListNotificationGroupsInput>;
  notificationItemsInput?: InputMaybe<ListNotificationItemsInput>;
}>;

export type ListNotificationGroupsQuery = {
  __typename?: 'Query';
  listNotificationGroups?: {
    __typename?: 'NotificationGroupsWithMeta';
    edges?: Array<{
      __typename?: 'NotificationGroup';
      groupName?: string | null;
      sortOrder?: string | null;
      notificationItems?: {
        __typename?: 'NotificationItemsWithMeta';
        edges?: Array<{
          __typename?: 'NotificationItem';
          id?: string | null;
          notificationName?: string | null;
          notificationDesc?: string | null;
          onePrefRequired?: boolean | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListFalconConstraintsQueryVariables = Exact<{
  optionsInput?: InputMaybe<ListFalconConstraintsInput>;
}>;

export type ListFalconConstraintsQuery = {
  __typename?: 'Query';
  listFalconConstraints?: {
    __typename?: 'FalconConstraintsWithMeta';
    edges?: Array<{
      __typename?: 'FalconConstraint';
      id: string;
      columnName?: string | null;
      columnText?: string | null;
      columnValue?: string | null;
    } | null> | null;
  } | null;
};

export type ListOptionsQueryVariables = Exact<{
  optionsInput: Scalars['String'];
}>;

export type ListOptionsQuery = {
  __typename?: 'Query';
  listOptions?: {
    __typename?: '__Type';
    edges?: Array<{ __typename?: '__EnumValue'; label?: string | null; value: string }> | null;
  } | null;
};

export type ListPlanOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ListPlanOptionsQuery = {
  __typename?: 'Query';
  listCardBrandOptions?: {
    __typename?: '__Type';
    edges?: Array<{ __typename?: '__EnumValue'; label?: string | null; value: string }> | null;
  } | null;
  listIntervalUnitOptions?: {
    __typename?: '__Type';
    edges?: Array<{ __typename?: '__EnumValue'; label?: string | null; value: string }> | null;
  } | null;
  listRolloverPlanOptions?: {
    __typename?: 'PlanWithMeta';
    edges?: Array<{ __typename?: 'Plan'; label?: any | null; value?: string | null } | null> | null;
  } | null;
  listPlanStatusOptions?: {
    __typename?: '__Type';
    edges?: Array<{ __typename?: '__EnumValue'; label?: string | null; value: string }> | null;
  } | null;
  listTerminationBehaviorOptions?: {
    __typename?: '__Type';
    edges?: Array<{ __typename?: '__EnumValue'; label?: string | null; value: string }> | null;
  } | null;
};

export type ListTariffOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ListTariffOptionsQuery = {
  __typename?: 'Query';
  listConnectorOptions?: {
    __typename?: 'VehicleConnectorsWithMeta';
    edges?: Array<{ __typename?: 'VehicleConnector'; label?: string | null; value?: number | null } | null> | null;
  } | null;
  listRateTypeOptions?: {
    __typename?: '__Type';
    edges?: Array<{ __typename?: '__EnumValue'; label?: string | null; value: string }> | null;
  } | null;
};

export type FullPlanFragment = {
  __typename?: 'Plan';
  altId?: string | null;
  cardBrand?: any | null;
  cardBrandId?: number | null;
  createdAt?: any | null;
  costFactor?: number | null;
  displayToCustomer?: boolean | null;
  displayToLoggedOutUser?: boolean | null;
  displayPlanCard?: boolean | null;
  driverRank?: number | null;
  isAutoEnrolledInRewards?: boolean | null;
  enrollmentOpen?: any | null;
  financeCode?: string | null;
  intervalLength?: number | null;
  intervalUnit?: string | null;
  intervalUnitId?: number | null;
  planCode?: any | null;
  planName?: any | null;
  badge?: any | null;
  balanceTypeId?: number | null;
  ctaLabel?: any | null;
  templateId?: number | null;
  planStatusId?: number | null;
  legalese?: string | null;
  terminationBehavior?: string | null;
  terminationBehaviorId?: number | null;
  description?: any | null;
  displayName?: any | null;
  subheading?: any | null;
  duration?: number | null;
  enrollmentClosed?: any | null;
  discount?: number | null;
  requireVin?: boolean | null;
  status?: string | null;
  pageHeader?: any | null;
  elements?: {
    __typename?: 'ElementsWithMeta';
    edges?: Array<{
      __typename?: 'Element';
      day?: Day | null;
      discount?: number | null;
      endTime?: any | null;
      maxDuration?: number | null;
      startTime?: any | null;
      connectorType?: { __typename?: 'VehicleConnector'; id?: number | null } | null;
    } | null> | null;
  } | null;
  products?: {
    __typename?: 'ProductsWithMeta';
    edges?: Array<{
      __typename?: 'Product';
      productType?: string | null;
      productTypeId?: number | null;
      credit?: {
        __typename?: 'PlanCredit';
        creditAmount?: number | null;
        creditExpPolicy?: string | null;
        creditExpPolicyId?: number | null;
        creditReoccurrence?: string | null;
        creditReoccurrenceId?: number | null;
        creditOneTimeAmount?: number | null;
      } | null;
      subscription?: {
        __typename?: 'PlanSubscription';
        subscriptionFee?: number | null;
        subscriptionReoccurrence?: string | null;
        subscriptionReoccurrenceId?: number | null;
      } | null;
    } | null> | null;
  } | null;
  rolloverPlan?: { __typename?: 'Plan'; altId?: string | null } | null;
  detailSections?: Array<{
    __typename?: 'DetailSection';
    header?: any | null;
    items?: Array<any | null> | null;
  } | null> | null;
  detailSessionTimes?: Array<{
    __typename?: 'DetailSessionTime';
    duration?: number | null;
    interval?: any | null;
    startTime?: any | null;
    endTime?: any | null;
  } | null> | null;
};

export type CreatePlanMutationVariables = Exact<{
  planInput: CreatePlanInput;
}>;

export type CreatePlanMutation = {
  __typename?: 'Mutation';
  createPlan?: {
    __typename?: 'Plan';
    altId?: string | null;
    cardBrand?: any | null;
    cardBrandId?: number | null;
    createdAt?: any | null;
    costFactor?: number | null;
    displayToCustomer?: boolean | null;
    displayToLoggedOutUser?: boolean | null;
    displayPlanCard?: boolean | null;
    driverRank?: number | null;
    isAutoEnrolledInRewards?: boolean | null;
    enrollmentOpen?: any | null;
    financeCode?: string | null;
    intervalLength?: number | null;
    intervalUnit?: string | null;
    intervalUnitId?: number | null;
    planCode?: any | null;
    planName?: any | null;
    badge?: any | null;
    balanceTypeId?: number | null;
    ctaLabel?: any | null;
    templateId?: number | null;
    planStatusId?: number | null;
    legalese?: string | null;
    terminationBehavior?: string | null;
    terminationBehaviorId?: number | null;
    description?: any | null;
    displayName?: any | null;
    subheading?: any | null;
    duration?: number | null;
    enrollmentClosed?: any | null;
    discount?: number | null;
    requireVin?: boolean | null;
    status?: string | null;
    pageHeader?: any | null;
    elements?: {
      __typename?: 'ElementsWithMeta';
      edges?: Array<{
        __typename?: 'Element';
        day?: Day | null;
        discount?: number | null;
        endTime?: any | null;
        maxDuration?: number | null;
        startTime?: any | null;
        connectorType?: { __typename?: 'VehicleConnector'; id?: number | null } | null;
      } | null> | null;
    } | null;
    products?: {
      __typename?: 'ProductsWithMeta';
      edges?: Array<{
        __typename?: 'Product';
        productType?: string | null;
        productTypeId?: number | null;
        credit?: {
          __typename?: 'PlanCredit';
          creditAmount?: number | null;
          creditExpPolicy?: string | null;
          creditExpPolicyId?: number | null;
          creditReoccurrence?: string | null;
          creditReoccurrenceId?: number | null;
          creditOneTimeAmount?: number | null;
        } | null;
        subscription?: {
          __typename?: 'PlanSubscription';
          subscriptionFee?: number | null;
          subscriptionReoccurrence?: string | null;
          subscriptionReoccurrenceId?: number | null;
        } | null;
      } | null> | null;
    } | null;
    rolloverPlan?: { __typename?: 'Plan'; altId?: string | null } | null;
    detailSections?: Array<{
      __typename?: 'DetailSection';
      header?: any | null;
      items?: Array<any | null> | null;
    } | null> | null;
    detailSessionTimes?: Array<{
      __typename?: 'DetailSessionTime';
      duration?: number | null;
      interval?: any | null;
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
  } | null;
};

export type GetPlanQueryVariables = Exact<{
  planInput?: InputMaybe<GetPlanInput>;
}>;

export type GetPlanQuery = {
  __typename?: 'Query';
  getPlan?: {
    __typename?: 'Plan';
    altId?: string | null;
    cardBrand?: any | null;
    cardBrandId?: number | null;
    createdAt?: any | null;
    costFactor?: number | null;
    displayToCustomer?: boolean | null;
    displayToLoggedOutUser?: boolean | null;
    displayPlanCard?: boolean | null;
    driverRank?: number | null;
    isAutoEnrolledInRewards?: boolean | null;
    enrollmentOpen?: any | null;
    financeCode?: string | null;
    intervalLength?: number | null;
    intervalUnit?: string | null;
    intervalUnitId?: number | null;
    planCode?: any | null;
    planName?: any | null;
    badge?: any | null;
    balanceTypeId?: number | null;
    ctaLabel?: any | null;
    templateId?: number | null;
    planStatusId?: number | null;
    legalese?: string | null;
    terminationBehavior?: string | null;
    terminationBehaviorId?: number | null;
    description?: any | null;
    displayName?: any | null;
    subheading?: any | null;
    duration?: number | null;
    enrollmentClosed?: any | null;
    discount?: number | null;
    requireVin?: boolean | null;
    status?: string | null;
    pageHeader?: any | null;
    elements?: {
      __typename?: 'ElementsWithMeta';
      edges?: Array<{
        __typename?: 'Element';
        day?: Day | null;
        discount?: number | null;
        endTime?: any | null;
        maxDuration?: number | null;
        startTime?: any | null;
        connectorType?: { __typename?: 'VehicleConnector'; id?: number | null } | null;
      } | null> | null;
    } | null;
    products?: {
      __typename?: 'ProductsWithMeta';
      edges?: Array<{
        __typename?: 'Product';
        productType?: string | null;
        productTypeId?: number | null;
        credit?: {
          __typename?: 'PlanCredit';
          creditAmount?: number | null;
          creditExpPolicy?: string | null;
          creditExpPolicyId?: number | null;
          creditReoccurrence?: string | null;
          creditReoccurrenceId?: number | null;
          creditOneTimeAmount?: number | null;
        } | null;
        subscription?: {
          __typename?: 'PlanSubscription';
          subscriptionFee?: number | null;
          subscriptionReoccurrence?: string | null;
          subscriptionReoccurrenceId?: number | null;
        } | null;
      } | null> | null;
    } | null;
    rolloverPlan?: { __typename?: 'Plan'; altId?: string | null } | null;
    detailSections?: Array<{
      __typename?: 'DetailSection';
      header?: any | null;
      items?: Array<any | null> | null;
    } | null> | null;
    detailSessionTimes?: Array<{
      __typename?: 'DetailSessionTime';
      duration?: number | null;
      interval?: any | null;
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
  } | null;
};

export type ListPlanDetailsQueryVariables = Exact<{
  plansInput?: InputMaybe<ListPlansInput>;
}>;

export type ListPlanDetailsQuery = {
  __typename?: 'Query';
  listPlans?: {
    __typename?: 'PlanWithMeta';
    total?: number | null;
    page?: number | null;
    pageSize?: number | null;
    edges?: Array<{
      __typename?: 'Plan';
      altId?: string | null;
      products?: {
        __typename?: 'ProductsWithMeta';
        edges?: Array<{
          __typename?: 'Product';
          altId?: string | null;
          credit?: {
            __typename?: 'PlanCredit';
            creditAmount?: number | null;
            creditReoccurrence?: string | null;
          } | null;
          subscription?: {
            __typename?: 'PlanSubscription';
            subscriptionFee?: number | null;
            subscriptionReoccurrence?: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListPlanNamesQueryVariables = Exact<{
  plansInput?: InputMaybe<ListPlansInput>;
}>;

export type ListPlanNamesQuery = {
  __typename?: 'Query';
  listPlans?: {
    __typename?: 'PlanWithMeta';
    edges?: Array<{ __typename?: 'Plan'; altId?: string | null; planName?: any | null } | null> | null;
  } | null;
};

export type ListPlansQueryVariables = Exact<{
  input?: InputMaybe<ListPlansInput>;
}>;

export type ListPlansQuery = {
  __typename?: 'Query';
  listPlans?: {
    __typename?: 'PlanWithMeta';
    total?: number | null;
    page?: number | null;
    pageSize?: number | null;
    sort?: {
      __typename?: 'ListPlansSortType';
      planName?: TableSortInput | null;
      planDisplayName?: TableSortInput | null;
      createdAt?: TableSortInput | null;
    } | null;
    search?: {
      __typename?: 'ListPlansSearchType';
      planName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
    edges?: Array<{
      __typename?: 'Plan';
      altId?: string | null;
      createdAt?: any | null;
      planName?: any | null;
      planCode?: any | null;
      planStatus?: string | null;
      planDuration?: number | null;
      planDescription?: any | null;
      planDisplayName?: any | null;
      driverRank?: number | null;
    } | null> | null;
  } | null;
};

export type ListPlansForContractsQueryVariables = Exact<{
  plansInput?: InputMaybe<ListPlansInput>;
}>;

export type ListPlansForContractsQuery = {
  __typename?: 'Query';
  listPlans?: {
    __typename?: 'PlanWithMeta';
    total?: number | null;
    page?: number | null;
    pageSize?: number | null;
    edges?: Array<{
      __typename?: 'Plan';
      altId?: string | null;
      createdAt?: any | null;
      enrollmentClose?: any | null;
      enrollmentOpen?: any | null;
      planName?: any | null;
      planStatus?: string | null;
      intervalUnit?: string | null;
      intervalLength?: number | null;
      planDuration?: number | null;
      terminationBehavior?: string | null;
    } | null> | null;
  } | null;
};

export type UpdatePlanMutationVariables = Exact<{
  planInput: UpdatePlanInput;
}>;

export type UpdatePlanMutation = {
  __typename?: 'Mutation';
  updatePlan?: {
    __typename?: 'Plan';
    altId?: string | null;
    cardBrand?: any | null;
    cardBrandId?: number | null;
    createdAt?: any | null;
    costFactor?: number | null;
    displayToCustomer?: boolean | null;
    displayToLoggedOutUser?: boolean | null;
    displayPlanCard?: boolean | null;
    driverRank?: number | null;
    isAutoEnrolledInRewards?: boolean | null;
    enrollmentOpen?: any | null;
    financeCode?: string | null;
    intervalLength?: number | null;
    intervalUnit?: string | null;
    intervalUnitId?: number | null;
    planCode?: any | null;
    planName?: any | null;
    badge?: any | null;
    balanceTypeId?: number | null;
    ctaLabel?: any | null;
    templateId?: number | null;
    planStatusId?: number | null;
    legalese?: string | null;
    terminationBehavior?: string | null;
    terminationBehaviorId?: number | null;
    description?: any | null;
    displayName?: any | null;
    subheading?: any | null;
    duration?: number | null;
    enrollmentClosed?: any | null;
    discount?: number | null;
    requireVin?: boolean | null;
    status?: string | null;
    pageHeader?: any | null;
    elements?: {
      __typename?: 'ElementsWithMeta';
      edges?: Array<{
        __typename?: 'Element';
        day?: Day | null;
        discount?: number | null;
        endTime?: any | null;
        maxDuration?: number | null;
        startTime?: any | null;
        connectorType?: { __typename?: 'VehicleConnector'; id?: number | null } | null;
      } | null> | null;
    } | null;
    products?: {
      __typename?: 'ProductsWithMeta';
      edges?: Array<{
        __typename?: 'Product';
        productType?: string | null;
        productTypeId?: number | null;
        credit?: {
          __typename?: 'PlanCredit';
          creditAmount?: number | null;
          creditExpPolicy?: string | null;
          creditExpPolicyId?: number | null;
          creditReoccurrence?: string | null;
          creditReoccurrenceId?: number | null;
          creditOneTimeAmount?: number | null;
        } | null;
        subscription?: {
          __typename?: 'PlanSubscription';
          subscriptionFee?: number | null;
          subscriptionReoccurrence?: string | null;
          subscriptionReoccurrenceId?: number | null;
        } | null;
      } | null> | null;
    } | null;
    rolloverPlan?: { __typename?: 'Plan'; altId?: string | null } | null;
    detailSections?: Array<{
      __typename?: 'DetailSection';
      header?: any | null;
      items?: Array<any | null> | null;
    } | null> | null;
    detailSessionTimes?: Array<{
      __typename?: 'DetailSessionTime';
      duration?: number | null;
      interval?: any | null;
      startTime?: any | null;
      endTime?: any | null;
    } | null> | null;
  } | null;
};

export type UpdatePlanDriverRankMutationVariables = Exact<{
  input: Array<DriverRankInput> | DriverRankInput;
}>;

export type UpdatePlanDriverRankMutation = {
  __typename?: 'Mutation';
  updatePlanDriverRank?: { __typename?: 'DriverRankUpdateResponse'; success: boolean; message?: string | null } | null;
};

export type ImportPropertyMutationVariables = Exact<{
  propertyInput?: InputMaybe<ImportPropertyInput>;
}>;

export type ImportPropertyMutation = {
  __typename?: 'Mutation';
  importProperty: {
    __typename?: 'Property';
    address1?: string | null;
    address2?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    postalCode?: string | null;
    country?: string | null;
    status?: PropertyStatus | null;
    altId: string;
    pid?: string | null;
    propertyName?: string | null;
    host?: { __typename?: 'Host'; altId?: string | null } | null;
  };
};

export type ListPropertiesQueryVariables = Exact<{
  propertiesInput?: InputMaybe<ListPropertiesInput>;
  sitesInput?: InputMaybe<ListSitesInput>;
}>;

export type ListPropertiesQuery = {
  __typename?: 'Query';
  listProperties?: {
    __typename?: 'PropertiesWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'Property';
      address1?: string | null;
      address2?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      postalCode?: string | null;
      country?: string | null;
      status?: PropertyStatus | null;
      altId: string;
      pid?: string | null;
      propertyName?: string | null;
      host?: { __typename?: 'Host'; altId?: string | null } | null;
      sites?: {
        __typename?: 'SitesWithMeta';
        total?: number | null;
        edges?: Array<{
          __typename?: 'Site';
          chargers?: { __typename?: 'ChargersWithMeta'; total?: number | null } | null;
        } | null> | null;
      } | null;
    } | null> | null;
    sort?: {
      __typename?: 'ListPropertiesSortType';
      altId?: TableSortType | null;
      pid?: TableSortType | null;
      propertyName?: TableSortType | null;
      hostName?: TableSortType | null;
    } | null;
    filter?: {
      __typename?: 'ListPropertiesFilterType';
      status?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
    } | null;
    search?: {
      __typename?: 'ListPropertiesSearchType';
      pid?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      propertyName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      locality?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      administrativeArea?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      postalCode?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
  } | null;
};

export type UpdatePropertyMutationVariables = Exact<{
  propertyInput?: InputMaybe<UpdatePropertyInput>;
}>;

export type UpdatePropertyMutation = {
  __typename?: 'Mutation';
  updateProperty: { __typename?: 'Property'; altId: string; status?: PropertyStatus | null };
};

export type GetSessionQueryVariables = Exact<{
  sessionInput: GetSessionInput;
}>;

export type GetSessionQuery = { __typename?: 'Query'; getSession?: { __typename?: 'Session'; altId: string } | null };

export type ListSessionsV2QueryVariables = Exact<{
  input: ListSessionsV2Input;
}>;

export type ListSessionsV2Query = {
  __typename?: 'Query';
  listSessionsV2?: {
    __typename?: 'SessionsV2WithMeta';
    total?: number | null;
    edges?: Array<{
      __typename?: 'SessionV2';
      altId: string;
      vendorId: string;
      startTime?: any | null;
      endTime?: any | null;
      sessionDuration?: number | null;
      energyDelivered?: number | null;
      meterStart?: number | null;
      meterEnd?: number | null;
      lastMeterValue?: number | null;
      cost?: number | null;
      corrupted?: boolean | null;
      corruptedReasons?: Array<CorruptedReason | null> | null;
      startSource?: { __typename?: 'FalconConstraint'; columnValue?: string | null } | null;
      plan?: { __typename?: 'PlanV2'; altId?: string | null; planName?: string | null } | null;
      transaction?: {
        __typename?: 'TransactionV2';
        altId: string;
        vendorId?: string | null;
        amount?: string | null;
        driver?: {
          __typename?: 'Driver';
          altId?: string | null;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null;
      connector?: {
        __typename?: 'ConnectorV2';
        evse?: {
          __typename?: 'EvseV2';
          charger?: { __typename?: 'ChargerV2'; chargerName?: string | null } | null;
        } | null;
      } | null;
      card?: {
        __typename?: 'Card';
        internalNumber?: string | null;
        externalNumber?: string | null;
        evcId?: string | null;
      } | null;
      stopSource?: { __typename?: 'FalconConstraint'; columnValue?: string | null } | null;
    } | null> | null;
  } | null;
};

export type FullSiteFragment = {
  __typename?: 'Site';
  address1?: string | null;
  address2?: string | null;
  locality?: string | null;
  administrativeArea?: string | null;
  postalCode?: string | null;
  country?: string | null;
  displayName?: string | null;
  altId: string;
  latitude?: number | null;
  locationNotes?: string | null;
  locationTypeId?: number | null;
  longitude?: number | null;
  siteName?: string | null;
  timeZone?: string | null;
  access?: SiteAccess | null;
  accessNotes?: string | null;
  locationMarket?: SiteMarket | null;
  directions?: string | null;
  utilityCompany?: SiteUtilityCompany | null;
  type?: SiteType | null;
  sid?: string | null;
  siteAccessId?: number | null;
  demandLimitManagementEnabled?: boolean | null;
  retailDemandLimit?: number | null;
  siteStatusId?: number | null;
  meterNumber?: string | null;
  ratedVoltage?: string | null;
  maxCurrent?: string | null;
  chargers?: {
    __typename?: 'ChargersWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'Charger';
      cid?: string | null;
      altId: string;
      chargerName?: string | null;
      fieldStationStatus?: FieldStationStatus | null;
      chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
      site?: {
        __typename?: 'Site';
        altId: string;
        address1?: string | null;
        address2?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        postalCode?: string | null;
        property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
      } | null;
    } | null> | null;
    search?: {
      __typename?: 'ListChargersSearchType';
      cid?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      chargerName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      site_address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      site_locality?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      site_administrativeArea?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      site_postalCode?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      site_property_propertyName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
    sort?: {
      __typename?: 'ListChargersSortType';
      cid?: TableSortType | null;
      chargerName?: TableSortType | null;
      propertyName?: TableSortType | null;
      fieldStationStatus?: TableSortType | null;
    } | null;
  } | null;
  property?: {
    __typename?: 'SiteProperty';
    altId?: string | null;
    pid?: string | null;
    propertyName?: string | null;
    host?: { __typename?: 'Host'; altId?: string | null; hid?: string | null } | null;
  } | null;
  hoursOfOperation?: {
    __typename?: 'HoursType';
    custom?: boolean | null;
    from?: string | null;
    to?: string | null;
    monday?: {
      __typename?: 'CustomHourType';
      closed?: boolean | null;
      timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
    } | null;
    tuesday?: {
      __typename?: 'CustomHourType';
      closed?: boolean | null;
      timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
    } | null;
    wednesday?: {
      __typename?: 'CustomHourType';
      closed?: boolean | null;
      timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
    } | null;
    thursday?: {
      __typename?: 'CustomHourType';
      closed?: boolean | null;
      timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
    } | null;
    friday?: {
      __typename?: 'CustomHourType';
      closed?: boolean | null;
      timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
    } | null;
    saturday?: {
      __typename?: 'CustomHourType';
      closed?: boolean | null;
      timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
    } | null;
    sunday?: {
      __typename?: 'CustomHourType';
      closed?: boolean | null;
      timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
    } | null;
  } | null;
  siteImages?: {
    __typename?: 'SiteImagesWithMeta';
    edges?: Array<{
      __typename?: 'SiteImage';
      media?: { __typename?: 'Media'; createdAt?: any | null; altId: any; url: string } | null;
    } | null> | null;
  } | null;
};

export type CreateSiteMutationVariables = Exact<{
  siteInput?: InputMaybe<CreateSiteInput>;
}>;

export type CreateSiteMutation = { __typename?: 'Mutation'; createSite: { __typename?: 'Site'; altId: string } };

export type GetSiteQueryVariables = Exact<{
  siteInput?: InputMaybe<GetSiteInput>;
}>;

export type GetSiteQuery = {
  __typename?: 'Query';
  getSite?: {
    __typename?: 'Site';
    address1?: string | null;
    address2?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    postalCode?: string | null;
    country?: string | null;
    displayName?: string | null;
    altId: string;
    latitude?: number | null;
    locationNotes?: string | null;
    locationTypeId?: number | null;
    longitude?: number | null;
    siteName?: string | null;
    timeZone?: string | null;
    access?: SiteAccess | null;
    accessNotes?: string | null;
    locationMarket?: SiteMarket | null;
    directions?: string | null;
    utilityCompany?: SiteUtilityCompany | null;
    type?: SiteType | null;
    sid?: string | null;
    siteAccessId?: number | null;
    demandLimitManagementEnabled?: boolean | null;
    retailDemandLimit?: number | null;
    siteStatusId?: number | null;
    meterNumber?: string | null;
    ratedVoltage?: string | null;
    maxCurrent?: string | null;
    chargers?: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        cid?: string | null;
        altId: string;
        chargerName?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          country?: string | null;
          postalCode?: string | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
      search?: {
        __typename?: 'ListChargersSearchType';
        cid?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        chargerName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        site_address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        site_locality?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        site_administrativeArea?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        site_postalCode?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        site_property_propertyName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      } | null;
      sort?: {
        __typename?: 'ListChargersSortType';
        cid?: TableSortType | null;
        chargerName?: TableSortType | null;
        propertyName?: TableSortType | null;
        fieldStationStatus?: TableSortType | null;
      } | null;
    } | null;
    property?: {
      __typename?: 'SiteProperty';
      altId?: string | null;
      pid?: string | null;
      propertyName?: string | null;
      host?: { __typename?: 'Host'; altId?: string | null; hid?: string | null } | null;
    } | null;
    hoursOfOperation?: {
      __typename?: 'HoursType';
      custom?: boolean | null;
      from?: string | null;
      to?: string | null;
      monday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      tuesday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      wednesday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      thursday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      friday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      saturday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      sunday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
    } | null;
    siteImages?: {
      __typename?: 'SiteImagesWithMeta';
      edges?: Array<{
        __typename?: 'SiteImage';
        media?: { __typename?: 'Media'; createdAt?: any | null; altId: any; url: string } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetSitesTotalQueryVariables = Exact<{ [key: string]: never }>;

export type GetSitesTotalQuery = {
  __typename?: 'Query';
  listSites?: { __typename?: 'SitesWithMeta'; total?: number | null } | null;
};

export type ListMappedSitesQueryVariables = Exact<{
  sitesInput?: InputMaybe<ListSitesInput>;
}>;

export type ListMappedSitesQuery = {
  __typename?: 'Query';
  listSites?: {
    __typename?: 'SitesWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'Site';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      address4?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      postalCode?: string | null;
      country?: string | null;
      altId: string;
      latitude?: number | null;
      longitude?: number | null;
      siteName?: string | null;
      sid?: string | null;
      siteStatusId?: number | null;
      chargers?: {
        __typename?: 'ChargersWithMeta';
        total?: number | null;
        edges?: Array<{
          __typename?: 'Charger';
          altId: string;
          chargerName?: string | null;
          cid?: string | null;
          fieldStationStatus?: FieldStationStatus | null;
          chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        } | null> | null;
        search?: {
          __typename?: 'ListChargersSearchType';
          cid?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
          chargerName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
          site_address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
          site_locality?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
          site_administrativeArea?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
          site_postalCode?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
          site_property_propertyName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        } | null;
        sort?: {
          __typename?: 'ListChargersSortType';
          cid?: TableSortType | null;
          chargerName?: TableSortType | null;
          fieldStationStatus?: TableSortType | null;
          propertyName?: TableSortType | null;
          chargerModel_modelName?: TableSortType | null;
        } | null;
        filter?: {
          __typename?: 'ListChargersFilterType';
          chargerModel_altId?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
          fieldStationStatus?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
          tags?: {
            __typename?: 'TableFilterType';
            or?: Array<{ __typename?: 'TableIntFilterType'; contains?: Array<number | null> | null } | null> | null;
          } | null;
        } | null;
      } | null;
      property?: {
        __typename?: 'SiteProperty';
        altId?: string | null;
        host?: { __typename?: 'Host'; altId?: string | null } | null;
      } | null;
    } | null> | null;
    sort?: {
      __typename?: 'ListSitesSortType';
      sid?: TableSortType | null;
      siteName?: TableSortType | null;
      hostName?: TableSortType | null;
    } | null;
    filter?: {
      __typename?: 'ListSiteFilterType';
      siteStatusId?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
    } | null;
    search?: {
      __typename?: 'ListSiteSearchType';
      siteName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      sid?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      property_host_hostName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      locality?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      administrativeArea?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      postalCode?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
  } | null;
};

export type ListSiteOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ListSiteOptionsQuery = {
  __typename?: 'Query';
  listSiteOptions?: Array<{ __typename?: 'Site'; altId: string; siteName?: string | null } | null> | null;
};

export type ListSitesQueryVariables = Exact<{
  sitesInput?: InputMaybe<ListSitesInput>;
}>;

export type ListSitesQuery = {
  __typename?: 'Query';
  listSites?: {
    __typename?: 'SitesWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'Site';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      address4?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      postalCode?: string | null;
      country?: string | null;
      altId: string;
      latitude?: number | null;
      longitude?: number | null;
      siteName?: string | null;
      sid?: string | null;
      siteStatusId?: number | null;
      chargers?: { __typename?: 'ChargersWithMeta'; total?: number | null } | null;
      property?: {
        __typename?: 'SiteProperty';
        altId?: string | null;
        host?: { __typename?: 'Host'; altId?: string | null; hostName?: string | null } | null;
      } | null;
    } | null> | null;
    sort?: {
      __typename?: 'ListSitesSortType';
      sid?: TableSortType | null;
      siteName?: TableSortType | null;
      hostName?: TableSortType | null;
      siteStatusId?: TableSortType | null;
    } | null;
    filter?: {
      __typename?: 'ListSiteFilterType';
      siteStatusId?: { __typename?: 'TableStringFilterType'; in?: Array<string | null> | null } | null;
    } | null;
    search?: {
      __typename?: 'ListSiteSearchType';
      siteName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      sid?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      property_host_hostName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      locality?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      administrativeArea?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      postalCode?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
  } | null;
};

export type ListSitesForChargerQueryVariables = Exact<{
  sitesInput?: InputMaybe<ListSitesInput>;
}>;

export type ListSitesForChargerQuery = {
  __typename?: 'Query';
  listSites?: {
    __typename?: 'SitesWithMeta';
    edges?: Array<{
      __typename?: 'Site';
      address1?: string | null;
      address2?: string | null;
      address3?: string | null;
      address4?: string | null;
      administrativeArea?: string | null;
      altId: string;
      country?: string | null;
      latitude?: number | null;
      locality?: string | null;
      longitude?: number | null;
      postalCode?: string | null;
      sid?: string | null;
      siteName?: string | null;
      siteStatusId?: number | null;
      hoursOfOperation?: {
        __typename?: 'HoursType';
        custom?: boolean | null;
        from?: string | null;
        to?: string | null;
        monday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        tuesday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        wednesday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        thursday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        friday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        saturday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
        sunday?: {
          __typename?: 'CustomHourType';
          closed?: boolean | null;
          timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
        } | null;
      } | null;
      property?: {
        __typename?: 'SiteProperty';
        altId?: string | null;
        host?: { __typename?: 'Host'; altId?: string | null; hostName?: string | null } | null;
      } | null;
    } | null> | null;
  } | null;
};

export type ListWarehouseOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type ListWarehouseOptionsQuery = {
  __typename?: 'Query';
  listWarehouseOptions?: Array<{
    __typename?: 'Warehouse';
    altId?: string | null;
    warehouseName?: string | null;
    address1?: string | null;
    address2?: string | null;
    address3?: string | null;
    address4?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    postalCode?: string | null;
    country?: string | null;
  } | null> | null;
};

export type UpdateSiteMutationVariables = Exact<{
  siteInput?: InputMaybe<UpdateSiteInput>;
}>;

export type UpdateSiteMutation = {
  __typename?: 'Mutation';
  updateSite: {
    __typename?: 'Site';
    address1?: string | null;
    address2?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    postalCode?: string | null;
    country?: string | null;
    displayName?: string | null;
    altId: string;
    latitude?: number | null;
    locationNotes?: string | null;
    locationTypeId?: number | null;
    longitude?: number | null;
    siteName?: string | null;
    timeZone?: string | null;
    access?: SiteAccess | null;
    accessNotes?: string | null;
    locationMarket?: SiteMarket | null;
    directions?: string | null;
    utilityCompany?: SiteUtilityCompany | null;
    type?: SiteType | null;
    sid?: string | null;
    siteAccessId?: number | null;
    demandLimitManagementEnabled?: boolean | null;
    retailDemandLimit?: number | null;
    siteStatusId?: number | null;
    meterNumber?: string | null;
    ratedVoltage?: string | null;
    maxCurrent?: string | null;
    chargers?: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        cid?: string | null;
        altId: string;
        chargerName?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          address1?: string | null;
          address2?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          country?: string | null;
          postalCode?: string | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
      search?: {
        __typename?: 'ListChargersSearchType';
        cid?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        chargerName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        site_address1?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        site_locality?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        site_administrativeArea?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        site_postalCode?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
        site_property_propertyName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
      } | null;
      sort?: {
        __typename?: 'ListChargersSortType';
        cid?: TableSortType | null;
        chargerName?: TableSortType | null;
        propertyName?: TableSortType | null;
        fieldStationStatus?: TableSortType | null;
      } | null;
    } | null;
    property?: {
      __typename?: 'SiteProperty';
      altId?: string | null;
      pid?: string | null;
      propertyName?: string | null;
      host?: { __typename?: 'Host'; altId?: string | null; hid?: string | null } | null;
    } | null;
    hoursOfOperation?: {
      __typename?: 'HoursType';
      custom?: boolean | null;
      from?: string | null;
      to?: string | null;
      monday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      tuesday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      wednesday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      thursday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      friday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      saturday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
      sunday?: {
        __typename?: 'CustomHourType';
        closed?: boolean | null;
        timeslots?: Array<{ __typename?: 'TimeSlotType'; from?: any | null; to?: any | null } | null> | null;
      } | null;
    } | null;
    siteImages?: {
      __typename?: 'SiteImagesWithMeta';
      edges?: Array<{
        __typename?: 'SiteImage';
        media?: { __typename?: 'Media'; createdAt?: any | null; altId: any; url: string } | null;
      } | null> | null;
    } | null;
  };
};

export type CreateTagMutationVariables = Exact<{
  input?: InputMaybe<CreateTagInput>;
}>;

export type CreateTagMutation = {
  __typename?: 'Mutation';
  createTag?: {
    __typename?: 'Tag';
    altId?: string | null;
    tagName?: string | null;
    description?: string | null;
    filters?: Array<{
      __typename?: 'Filter';
      criteria?: string | null;
      exclude?: boolean | null;
      operator?: string | null;
      values?: Array<{
        __typename?: 'FilterValue';
        label?: string | null;
        valid?: boolean | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    chargers?: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        chargerName?: string | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          address4?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
      sort?: {
        __typename?: 'ListChargersSortType';
        cid?: TableSortType | null;
        chargerName?: TableSortType | null;
        fieldStationStatus?: TableSortType | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteTagMutationVariables = Exact<{
  deleteTagsInput?: InputMaybe<DeleteTagInput>;
}>;

export type DeleteTagMutation = {
  __typename?: 'Mutation';
  deleteTag?: { __typename?: 'Tag'; altId?: string | null } | null;
};

export type GetTagQueryVariables = Exact<{
  input?: InputMaybe<GetTagInput>;
  chargersInput?: InputMaybe<ListChargersInput>;
}>;

export type GetTagQuery = {
  __typename?: 'Query';
  getTag?: {
    __typename?: 'Tag';
    altId?: string | null;
    tagName?: string | null;
    description?: string | null;
    filters?: Array<{
      __typename?: 'Filter';
      criteria?: string | null;
      exclude?: boolean | null;
      operator?: string | null;
      values?: Array<{
        __typename?: 'FilterValue';
        label?: string | null;
        valid?: boolean | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    chargers?: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        chargerName?: string | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          address4?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          property?: {
            __typename?: 'SiteProperty';
            propertyName?: string | null;
            host?: { __typename?: 'Host'; hostName?: string | null } | null;
          } | null;
        } | null;
      } | null> | null;
      sort?: {
        __typename?: 'ListChargersSortType';
        cid?: TableSortType | null;
        chargerName?: TableSortType | null;
        fieldStationStatus?: TableSortType | null;
        hostName?: TableSortType | null;
        propertyName?: TableSortType | null;
      } | null;
    } | null;
  } | null;
};

export type ListTagsQueryVariables = Exact<{
  tagsInput?: InputMaybe<ListTagsInput>;
}>;

export type ListTagsQuery = {
  __typename?: 'Query';
  listTags?: {
    __typename?: 'TagsWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'Tag';
      altId?: string | null;
      tagName?: string | null;
      description?: string | null;
      chargers?: { __typename?: 'ChargersWithMeta'; total?: number | null } | null;
      filters?: Array<{
        __typename?: 'Filter';
        criteria?: string | null;
        exclude?: boolean | null;
        values?: Array<{
          __typename?: 'FilterValue';
          label?: string | null;
          valid?: boolean | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    sort?: { __typename?: 'ListTagsSortType'; tagName?: string | null } | null;
    search?: {
      __typename?: 'ListTagsSearchType';
      tagName?: { __typename?: 'TableStringFilterType'; iLike?: string | null } | null;
    } | null;
  } | null;
};

export type ListTagsOptionsQueryVariables = Exact<{
  tagsInput?: InputMaybe<ListTagsInput>;
}>;

export type ListTagsOptionsQuery = {
  __typename?: 'Query';
  listTags?: {
    __typename?: 'TagsWithMeta';
    page?: number | null;
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{ __typename?: 'Tag'; altId?: string | null; tagName?: string | null } | null> | null;
  } | null;
};

export type ListTagsTotalQueryVariables = Exact<{
  tagsInput?: InputMaybe<ListTagsInput>;
}>;

export type ListTagsTotalQuery = {
  __typename?: 'Query';
  listTags?: { __typename?: 'TagsWithMeta'; total?: number | null } | null;
};

export type UpdateTagMutationVariables = Exact<{
  input?: InputMaybe<UpdateTagInput>;
  chargersInput?: InputMaybe<ListChargersInput>;
}>;

export type UpdateTagMutation = {
  __typename?: 'Mutation';
  updateTag?: {
    __typename?: 'Tag';
    altId?: string | null;
    tagName?: string | null;
    description?: string | null;
    filters?: Array<{
      __typename?: 'Filter';
      criteria?: string | null;
      exclude?: boolean | null;
      operator?: string | null;
      values?: Array<{
        __typename?: 'FilterValue';
        label?: string | null;
        valid?: boolean | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    chargers?: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        chargerName?: string | null;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          address4?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          property?: { __typename?: 'SiteProperty'; propertyName?: string | null } | null;
        } | null;
      } | null> | null;
      sort?: {
        __typename?: 'ListChargersSortType';
        cid?: TableSortType | null;
        fieldStationStatus?: TableSortType | null;
        chargerName?: TableSortType | null;
      } | null;
    } | null;
  } | null;
};

export type ValidateTagQueryVariables = Exact<{
  input?: InputMaybe<ValidateTagInput>;
  chargersInput?: InputMaybe<ListChargersInput>;
}>;

export type ValidateTagQuery = {
  __typename?: 'Query';
  validateTag?: {
    __typename?: 'Tag';
    altId?: string | null;
    tagName?: string | null;
    description?: string | null;
    filters?: Array<{
      __typename?: 'Filter';
      criteria?: string | null;
      exclude?: boolean | null;
      operator?: string | null;
      values?: Array<{
        __typename?: 'FilterValue';
        label?: string | null;
        valid?: boolean | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    chargers?: {
      __typename?: 'ChargersWithMeta';
      page?: number | null;
      pageSize?: number | null;
      total?: number | null;
      edges?: Array<{
        __typename?: 'Charger';
        altId: string;
        cid?: string | null;
        fieldStationStatus?: FieldStationStatus | null;
        chargerName?: string | null;
        chargerModel?: { __typename?: 'ChargerModel'; altId: string; modelName?: string | null } | null;
        site?: {
          __typename?: 'Site';
          altId: string;
          address1?: string | null;
          address2?: string | null;
          address3?: string | null;
          address4?: string | null;
          locality?: string | null;
          administrativeArea?: string | null;
          postalCode?: string | null;
          country?: string | null;
          property?: {
            __typename?: 'SiteProperty';
            propertyName?: string | null;
            host?: { __typename?: 'Host'; altId?: string | null } | null;
          } | null;
        } | null;
      } | null> | null;
      sort?: {
        __typename?: 'ListChargersSortType';
        cid?: TableSortType | null;
        chargerName?: TableSortType | null;
        fieldStationStatus?: TableSortType | null;
        hostName?: TableSortType | null;
        propertyName?: TableSortType | null;
      } | null;
    } | null;
  } | null;
};

export type ListTransactionV2DetailsQueryVariables = Exact<{
  input: ListTransactionsV2Input;
}>;

export type ListTransactionV2DetailsQuery = {
  __typename?: 'Query';
  listTransactionsV2?: {
    __typename?: 'TransactionsV2WithMeta';
    total?: number | null;
    edges?: Array<{
      __typename?: 'TransactionV2';
      amount?: string | null;
      createdAt?: any | null;
      tax?: number | null;
      altId: string;
      charger?: { __typename?: 'ChargerV2'; altId: string; chargerName?: string | null; brand?: string | null } | null;
      type?: { __typename?: 'FalconConstraint'; columnValue?: string | null } | null;
      session?: {
        __typename?: 'SessionV2';
        sessionDuration?: number | null;
        energyDelivered?: number | null;
        altId: string;
        vendorId: string;
        startTime?: any | null;
        endTime?: any | null;
        meterStart?: number | null;
        meterEnd?: number | null;
        connector?: {
          __typename?: 'ConnectorV2';
          altId: string;
          connectorType?: { __typename?: 'FalconConstraint'; columnValue?: string | null } | null;
        } | null;
        plan?: {
          __typename?: 'PlanV2';
          planName?: string | null;
          chargerGroup?: {
            __typename?: 'ChargerGroupForExtend';
            altId: string;
            tariff?: { __typename?: 'TariffForExtend'; altId: string; tariffName: any } | null;
          } | null;
        } | null;
        stopSource?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
        startSource?: { __typename?: 'FalconConstraint'; columnText?: string | null } | null;
      } | null;
      items?: Array<{
        __typename?: 'TransactionItemV2';
        unitPrice?: number | null;
        amount?: number | null;
        altId: string;
        quantity?: number | null;
        productType?: {
          __typename?: 'FalconConstraint';
          columnValue?: string | null;
          columnText?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ListTransactionsV2QueryVariables = Exact<{
  input?: InputMaybe<ListTransactionsV2Input>;
}>;

export type ListTransactionsV2Query = {
  __typename?: 'Query';
  listTransactionsV2?: {
    __typename?: 'TransactionsV2WithMeta';
    total?: number | null;
    edges?: Array<{
      __typename?: 'TransactionV2';
      id: string;
      amount?: string | null;
      createdAt?: any | null;
      tax?: number | null;
      vendorId?: string | null;
      altId: string;
      type?: { __typename?: 'FalconConstraint'; columnValue?: string | null } | null;
      session?: {
        __typename?: 'SessionV2';
        accepted?: boolean | null;
        energyDelivered?: number | null;
        corrupted?: boolean | null;
        startTime?: any | null;
        endTime?: any | null;
        plan?: { __typename?: 'PlanV2'; planName?: string | null; altId?: string | null } | null;
      } | null;
      items?: Array<{ __typename?: 'TransactionItemV2'; comments?: string | null } | null> | null;
    } | null> | null;
  } | null;
};

export type AuthenticateUserMutationVariables = Exact<{
  input: AuthenticateUserInput;
}>;

export type AuthenticateUserMutation = {
  __typename?: 'Mutation';
  authenticateUser: {
    __typename?: 'AuthResponse';
    token?: string | null;
    user?: {
      __typename?: 'User';
      username?: string | null;
      userRole?: Array<UserRole | null> | null;
      id: string;
    } | null;
  };
};

export type CreateAccountVehicleMutationVariables = Exact<{
  accountVehicleInput?: InputMaybe<CreateAccountVehicleInput>;
}>;

export type CreateAccountVehicleMutation = {
  __typename?: 'Mutation';
  createAccountVehicle?: {
    __typename?: 'AccountVehicle';
    altId?: any | null;
    nickName?: any | null;
    vin?: any | null;
    macAddress?: any | null;
    deletedAt?: any | null;
    vehicle?: {
      __typename?: 'Vehicle';
      altId?: string | null;
      makeModelYear?: string | null;
      vehicleConnectors?: Array<{
        __typename?: 'VehicleConnector';
        id?: number | null;
        connectorName?: string | null;
      } | null> | null;
    } | null;
  } | null;
};

export type ListVehiclesQueryVariables = Exact<{
  vehiclesInput?: InputMaybe<ListVehiclesInput>;
}>;

export type ListVehiclesQuery = {
  __typename?: 'Query';
  listVehicles?: {
    __typename?: 'VehiclesWithMeta';
    pageSize?: number | null;
    total?: number | null;
    edges?: Array<{
      __typename?: 'Vehicle';
      altId?: string | null;
      makeModelYear?: string | null;
      vehicleConnectors?: Array<{
        __typename?: 'VehicleConnector';
        id?: number | null;
        connectorName?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** Email address custom scalar type */
  Email: any;
  /** ISO Date custom scalar type */
  ISODate: any;
  JSON: any;
  /** Phone number custom scalar type */
  Phone: any;
  /** Converts units of power from kW to Watts */
  PowerUnit: any;
  /** Sanitizes and trims String input. */
  SanitizeString: any;
  /** Float to 4 decimal places for Tariff Pricing */
  TariffPrice: any;
  /** Time custom scalar type */
  Time: any;
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: any;
  /** ID custom scalar type */
  UUID: any;
  Upload: any;
  /** Validates VIN pattern and type */
  Vin: any;
  /** Represents NULL values */
  Void: any;
  _Any: any;
  federation__FieldSet: any;
  link__Import: any;
};

export type Account = {
  __typename?: 'Account';
  accountStatus: AccountStatus;
  accountType?: Maybe<Scalars['String']>;
  accountVehicles?: Maybe<AccountVehiclesWithMeta>;
  altId: Scalars['ID'];
  balance?: Maybe<Scalars['Float']>;
  balanceType?: Maybe<FalconConstraint>;
  billingAddress?: Maybe<Address>;
  cardOrders?: Maybe<CardOrdersWithMeta>;
  cards?: Maybe<CardsWithMeta>;
  contracts?: Maybe<ContractsWithMeta>;
  driivzSyncedAt?: Maybe<Scalars['Date']>;
  drivers?: Maybe<DriversWithMeta>;
  enrolledOn?: Maybe<Scalars['Date']>;
  evcId?: Maybe<Scalars['String']>;
  evcSyncedAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  isFcms?: Maybe<Scalars['Boolean']>;
  mailingAddress?: Maybe<Address>;
  optIn?: Maybe<Scalars['Boolean']>;
  optInPromotions?: Maybe<Scalars['Boolean']>;
  partnerSource?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  vendorAccountType?: Maybe<FalconConstraint>;
  vendorId?: Maybe<Scalars['Int']>;
  vendorSyncedAt?: Maybe<Scalars['Date']>;
};

export type AccountAccountVehiclesArgs = {
  input?: InputMaybe<ListAccountVehiclesInput>;
};

export type AccountCardsArgs = {
  input?: InputMaybe<ListCardsInput>;
};

export type AccountContractsArgs = {
  input?: InputMaybe<ListContractsInput>;
};

export type AccountDriversArgs = {
  input?: InputMaybe<ListDriversInput>;
};

export type AccountOwner = {
  __typename?: 'AccountOwner';
  account?: Maybe<Account>;
  accountVehicles?: Maybe<AccountVehiclesWithMeta>;
  altId?: Maybe<Scalars['ID']>;
  cards?: Maybe<CardsWithMeta>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  driverStatus?: Maybe<FalconConstraint>;
  driverType?: Maybe<DriverType>;
  email?: Maybe<Scalars['String']>;
  enrolledOn?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  notificationPreferences?: Maybe<NotificationPreferencesWithMeta>;
  optInPromotions?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  subscriptionCopy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  vendorAccountType?: Maybe<FalconConstraint>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type AccountOwnerAccountVehiclesArgs = {
  input?: InputMaybe<ListAccountVehiclesInput>;
};

export type AccountOwnerCardsArgs = {
  input?: InputMaybe<ListCardsInput>;
};

export type AccountOwnerNotificationPreferencesArgs = {
  input?: InputMaybe<ListNotificationPreferencesInput>;
};

export type AccountSearchResult = {
  __typename?: 'AccountSearchResult';
  driivz?: Maybe<Array<Maybe<DriivzAccount>>>;
  falcon?: Maybe<Array<Maybe<Driver>>>;
  firebase?: Maybe<Array<Maybe<FirebaseAccount>>>;
};

export enum AccountStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Closed */
  Closed = 'CLOSED',
  /** Deleted */
  Deleted = 'DELETED',
  /** Registered */
  Registered = 'REGISTERED',
  /** Suspended */
  Suspended = 'SUSPENDED',
  /** Unknown */
  Unknown = 'UNKNOWN',
  /** UnSuspended */
  Unsuspended = 'UNSUSPENDED',
}

export type AccountVehicle = {
  __typename?: 'AccountVehicle';
  accountId?: Maybe<Scalars['ID']>;
  altId?: Maybe<Scalars['UUID']>;
  autochargeFlag?: Maybe<Scalars['Boolean']>;
  autochargeStatus?: Maybe<FalconConstraint>;
  card?: Maybe<Card>;
  deletedAt?: Maybe<Scalars['Date']>;
  driverFirstName?: Maybe<Scalars['String']>;
  driverId: Scalars['ID'];
  driverLastName?: Maybe<Scalars['String']>;
  macAddress?: Maybe<Scalars['SanitizeString']>;
  media?: Maybe<Media>;
  nickName?: Maybe<Scalars['SanitizeString']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['SanitizeString']>;
};

export type AccountVehicleInput = {
  accountVendorId?: InputMaybe<Scalars['ID']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  modelName?: InputMaybe<Scalars['String']>;
  vehicleYear?: InputMaybe<Scalars['Int']>;
};

export type AccountVehicleVinStatus = {
  __typename?: 'AccountVehicleVinStatus';
  vinInUse: Scalars['Boolean'];
};

export type AccountVehiclesWithMeta = {
  __typename?: 'AccountVehiclesWithMeta';
  edges?: Maybe<Array<Maybe<AccountVehicle>>>;
  total?: Maybe<Scalars['Int']>;
};

export type AccountsWithMeta = {
  __typename?: 'AccountsWithMeta';
  edges?: Maybe<Array<Maybe<Account>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<ListAccountsSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type ActivateCardForMobileInput = {
  driverId?: InputMaybe<Scalars['ID']>;
  visibleCardNumber: Scalars['String'];
};

export type ActivateCardInput = {
  comments?: InputMaybe<Scalars['String']>;
  driverId: Scalars['ID'];
  visibleCardNumber: Scalars['String'];
};

export type ActivateCardsInput = {
  accountId?: InputMaybe<Scalars['UUID']>;
  cardIds: Array<Scalars['UUID']>;
  driverId?: InputMaybe<Scalars['UUID']>;
};

export type ActiveSessionsTotals = {
  __typename?: 'ActiveSessionsTotals';
  /** Total number of active sessions for the given sites */
  sessionCount: Scalars['Int'];
  /** Total power dispensed for the active sessions for the given sites */
  totalKwh: Scalars['Float'];
};

export type ActiveSessionsTotalsInput = {
  siteIds: Array<Scalars['ID']>;
};

export enum ActivityStatus {
  AvailableToCharge = 'AVAILABLE_TO_CHARGE',
  Charging = 'CHARGING',
  Enroute = 'ENROUTE',
  UnavailableToCharge = 'UNAVAILABLE_TO_CHARGE',
  Unknown = 'UNKNOWN',
}

export enum ActivityStatusLabel {
  Charging = 'CHARGING',
  Done = 'DONE',
  Enroute = 'ENROUTE',
  Idle = 'IDLE',
  InMaintenance = 'IN_MAINTENANCE',
}

export type AdHocContract = {
  __typename?: 'AdHocContract';
  amountUsed?: Maybe<Scalars['Float']>;
  contractId?: Maybe<Scalars['String']>;
  creditBalance?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Input for adding chargers to a charger group */
export type AddChargersToChargerGroupForExtendInput = {
  /** Charger Group altId */
  altId: Scalars['ID'];
  /** List of charger altIds to add to the charger group */
  chargerIds: Array<Scalars['ID']>;
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  administrativeArea: Scalars['String'];
  altId: Scalars['ID'];
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  latitude?: Maybe<Scalars['String']>;
  locality: Scalars['String'];
  longitude?: Maybe<Scalars['String']>;
  postalCode: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AggregatedMeterValues = {
  __typename?: 'AggregatedMeterValues';
  powerMeterValuesInWatts?: Maybe<Array<Maybe<PowerMeterValue>>>;
};

export enum AlertType {
  ChargerOffline = 'CHARGER_OFFLINE',
  ConnectorDisconnected = 'CONNECTOR_DISCONNECTED',
  ConnectorFailed = 'CONNECTOR_FAILED',
  ConnectorUnavailable = 'CONNECTOR_UNAVAILABLE',
  MeterTimeout = 'METER_TIMEOUT',
  NetworkUnavailable = 'NETWORK_UNAVAILABLE',
  PeakHoursCharging = 'PEAK_HOURS_CHARGING',
  SessionComplete = 'SESSION_COMPLETE',
  SessionEndedBeforeTargetMet = 'SESSION_ENDED_BEFORE_TARGET_MET',
  SessionFailed = 'SESSION_FAILED',
  SessionStarted = 'SESSION_STARTED',
  SessionStopped = 'SESSION_STOPPED',
  SessionTerminated = 'SESSION_TERMINATED',
  SiteOffline = 'SITE_OFFLINE',
  ThrottleAttemptFailed = 'THROTTLE_ATTEMPT_FAILED',
  ThrottleFinished = 'THROTTLE_FINISHED',
  ThrottleStarted = 'THROTTLE_STARTED',
  ThrottleValidationFailed = 'THROTTLE_VALIDATION_FAILED',
}

/** Mobile Input for reassigning a card to a driver */
export type AssignCardForMobileInput = {
  /** alt-id in falcon for card that is to be reassigned */
  cardId: Scalars['ID'];
  /** alt-id in falcon for driver card is to be assigned to */
  driverId: Scalars['ID'];
};

export type AssignCardInput = {
  accountId: Scalars['ID'];
  cardId: Scalars['ID'];
  driverId: Scalars['ID'];
  nickName?: InputMaybe<Scalars['String']>;
};

export type AssignCardsInput = {
  accountId?: InputMaybe<Scalars['UUID']>;
  cardIds: Array<Scalars['UUID']>;
  driverId?: InputMaybe<Scalars['UUID']>;
  nickName?: InputMaybe<Scalars['String']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum AuthServiceType {
  ShpHsmWelcome = 'SHP_HSM_WELCOME',
  ShpPasswordReset = 'SHP_PASSWORD_RESET',
  ShpShWelcome = 'SHP_SH_WELCOME',
}

export type AuthenticateUserInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export enum BalanceType {
  /** non paying */
  NonPaying = 'NON_PAYING',
  /** payment immediately */
  PaymentImmediately = 'PAYMENT_IMMEDIATELY',
  /** post paid */
  PostPaid = 'POST_PAID',
  /** post paid immediately */
  PostPaidImmediately = 'POST_PAID_IMMEDIATELY',
  /** prepaid */
  PrePaid = 'PRE_PAID',
}

export enum CableCooling {
  /** Air Cooled */
  AirCooled = 'AIR_COOLED',
  /** Liquid Cooled */
  LiquidCooled = 'LIQUID_COOLED',
}

export enum CableLength {
  /** 18 ft */
  EighteenFeet = 'EIGHTEEN_FEET',
  /** 15 ft */
  FifteenFeet = 'FIFTEEN_FEET',
  /** 10 ft */
  TenFeet = 'TEN_FEET',
  /** 12 ft */
  TwelveFeet = 'TWELVE_FEET',
  /** 20 ft */
  TwentyFeet = 'TWENTY_FEET',
  /** 25 ft */
  TwentyFiveFeet = 'TWENTY_FIVE_FEET',
}

export enum CableManufacturer {
  /** Delphi */
  Delphi = 'DELPHI',
  /** Huber + Suhner */
  HuberSuhner = 'HUBER_SUHNER',
  /** ITT */
  Itt = 'ITT',
  /** JAE */
  Jae = 'JAE',
  /** KST */
  Kst = 'KST',
  /** Phoenix Contact */
  PhoenixContact = 'PHOENIX_CONTACT',
  /** REMA */
  Rema = 'REMA',
  /** Sumitomo (Metal Body) */
  SumitomoMetalBody = 'SUMITOMO_METAL_BODY',
  /** Sumitomo (Plastic Body) */
  SumitomoPlasticBody = 'SUMITOMO_PLASTIC_BODY',
  /** Yazaki */
  Yazaki = 'YAZAKI',
  /** Yazaki (Gen 2) */
  YazakiGen_2 = 'YAZAKI_GEN_2',
}

export type CancelAutochargeEnrollmentInput = {
  altId: Scalars['UUID'];
  driverId?: InputMaybe<Scalars['UUID']>;
};

export type CancelInviteDriverInput = {
  altId: Scalars['ID'];
};

export type CancelReservationForDriverInput = {
  accountId: Scalars['ID'];
  reservationId: Scalars['ID'];
};

export type CancelReservationInput = {
  reservationId: Scalars['ID'];
};

export type Card = {
  __typename?: 'Card';
  account?: Maybe<Account>;
  activatedAt?: Maybe<Scalars['Date']>;
  altId: Scalars['ID'];
  brand?: Maybe<Scalars['String']>;
  cardStatus?: Maybe<CardStatus>;
  cardType?: Maybe<Scalars['String']>;
  cardTypeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  driver?: Maybe<Driver>;
  evcId?: Maybe<Scalars['ID']>;
  externalNumber?: Maybe<Scalars['String']>;
  internalNumber?: Maybe<Scalars['String']>;
  nickName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  vendorId?: Maybe<Scalars['ID']>;
  vendorSyncedAt?: Maybe<Scalars['Date']>;
};

export enum CardBrand {
  /** EVgo */
  Evgo = 'EVGO',
  /** Lyft */
  Lyft = 'LYFT',
  /** Maven */
  Maven = 'MAVEN',
}

export type CardOrder = {
  __typename?: 'CardOrder';
  account?: Maybe<CardOrderAccount>;
  altId?: Maybe<Scalars['ID']>;
  driver?: Maybe<Driver>;
  fulfilledAt?: Maybe<Scalars['Date']>;
  orderStatus?: Maybe<CardOrdersStatus>;
  orderedAt?: Maybe<Scalars['Date']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type CardOrderAccount = {
  __typename?: 'CardOrderAccount';
  accountStatus?: Maybe<AccountStatus>;
  altId?: Maybe<Scalars['ID']>;
  balance?: Maybe<Scalars['Int']>;
  billingAddress?: Maybe<Address>;
  enrolledOn?: Maybe<Scalars['Date']>;
  evcId?: Maybe<Scalars['String']>;
  evcSyncedAt?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  mailingAddress?: Maybe<Address>;
  status?: Maybe<AccountStatus>;
};

export type CardOrderNotificationInput = {
  startDate: Scalars['ISODate'];
};

export enum CardOrdersStatus {
  /** Completed */
  Completed = 'COMPLETED',
  /** Ordered */
  Ordered = 'ORDERED',
  /** Sent */
  Sent = 'SENT',
}

export type CardOrdersWithMeta = {
  __typename?: 'CardOrdersWithMeta';
  edges?: Maybe<Array<Maybe<CardOrder>>>;
  filter?: Maybe<ListCardOrdersFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListCardOrdersSearchType>;
  sort?: Maybe<ListCardOrdersSortType>;
  total?: Maybe<Scalars['Int']>;
};

export enum CardStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Deactivated */
  Deactivated = 'DEACTIVATED',
  /** Deleted */
  Deleted = 'DELETED',
  /** Inactive */
  Inactive = 'INACTIVE',
  /** Lost */
  Lost = 'LOST',
  /** Reserved */
  Reserved = 'RESERVED',
  /** Stolen */
  Stolen = 'STOLEN',
  /** Suspended */
  Suspended = 'SUSPENDED',
  /** Unassigned */
  Unassigned = 'UNASSIGNED',
}

export type CardsWithMeta = {
  __typename?: 'CardsWithMeta';
  edges?: Maybe<Array<Maybe<Card>>>;
  filter?: Maybe<ListCardsFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListCardsSearchType>;
  sort?: Maybe<ListCardsSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type Charger = {
  __typename?: 'Charger';
  additionalEquipment?: Maybe<Scalars['String']>;
  additionalSerial?: Maybe<Scalars['String']>;
  altId: Scalars['ID'];
  authenticationMethodsConstraint?: Maybe<FalconConstraint>;
  authenticationMode?: Maybe<Scalars['String']>;
  authenticationModeId?: Maybe<Scalars['Int']>;
  /** @deprecated Cleanup */
  brand?: Maybe<Scalars['String']>;
  ccReaderType?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  certified?: Maybe<Scalars['Boolean']>;
  /** @deprecated Cleanup */
  chargePointIdentity?: Maybe<Scalars['String']>;
  chargerId?: Maybe<Scalars['ID']>;
  chargerIsManaged?: Maybe<Scalars['Boolean']>;
  chargerModel?: Maybe<ChargerModel>;
  chargerName?: Maybe<Scalars['String']>;
  chargerStatus?: Maybe<Scalars['String']>;
  chargerStatusId?: Maybe<Scalars['Int']>;
  cid?: Maybe<Scalars['String']>;
  coBranding?: Maybe<Scalars['String']>;
  communicationMethod?: Maybe<Scalars['String']>;
  connectionDate?: Maybe<Scalars['String']>;
  connectionUri?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  connectors?: Maybe<Array<Maybe<Connector>>>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  displayTariffSynced?: Maybe<Scalars['Boolean']>;
  energyUtilization?: Maybe<Scalars['Float']>;
  equipmentId?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  evcId?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  evcSyncedAt?: Maybe<Scalars['Date']>;
  /** @deprecated Cleanup */
  evseId?: Maybe<Scalars['String']>;
  evses?: Maybe<EvsesWithMeta>;
  excludeFromOcpi?: Maybe<Scalars['Boolean']>;
  excluded?: Maybe<Scalars['Boolean']>;
  fieldStationStatus?: Maybe<FieldStationStatus>;
  firmwareVersion?: Maybe<Scalars['SanitizeString']>;
  fundingSource?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  identityKey?: Maybe<Scalars['String']>;
  ignoreStatusNotifications?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Cleanup */
  infrastructureProviderCompanyId?: Maybe<Scalars['Int']>;
  installDate?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  ipAddress?: Maybe<Scalars['String']>;
  lastReceivedCommSignal?: Maybe<Scalars['Timestamp']>;
  lastReportedFirmwareVersion?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  legacyId?: Maybe<Scalars['Int']>;
  localLoadBalancingEnabled?: Maybe<Scalars['Boolean']>;
  localNetworkMaster?: Maybe<Scalars['Boolean']>;
  macAddress?: Maybe<Scalars['String']>;
  maintenanceComments?: Maybe<Scalars['String']>;
  maintenanceVendor?: Maybe<Scalars['String']>;
  maintenanceVendorId?: Maybe<Scalars['Int']>;
  managed?: Maybe<Scalars['Boolean']>;
  /** @deprecated Cleanup */
  maxPower?: Maybe<Scalars['Int']>;
  meterSigningSupported?: Maybe<Scalars['Boolean']>;
  /** @deprecated Cleanup */
  modelNotes?: Maybe<Scalars['String']>;
  modemSerialNumber?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  mountType?: Maybe<Scalars['String']>;
  networkConnectionType?: Maybe<Scalars['String']>;
  networkConnectionTypeId?: Maybe<Scalars['Int']>;
  nextMaintenanceDate?: Maybe<Scalars['Date']>;
  nonNetworked: Scalars['Boolean'];
  physicalLocation?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  ports?: Maybe<PortsWithMeta>;
  /** @deprecated Cleanup */
  ports2?: Maybe<PortsWithMeta>;
  productIdentityKeyId?: Maybe<Scalars['Int']>;
  /** @deprecated Cleanup */
  publish?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  serverSideLocalLoadBalancingEnabled?: Maybe<Scalars['Boolean']>;
  setTariffSynced?: Maybe<Scalars['Boolean']>;
  showInThirdPartyFilters: Scalars['Boolean'];
  simCardImsi?: Maybe<Scalars['String']>;
  simultaneousChargingEnabled: Scalars['Boolean'];
  site?: Maybe<Site>;
  smartChargingEnabled: Scalars['Boolean'];
  smiCardIccid?: Maybe<Scalars['String']>;
  tags?: Maybe<TagsWithMeta>;
  tariffSyncStatus?: Maybe<TariffSyncStatus>;
  teslaAdapter: Scalars['Boolean'];
  /** @deprecated Cleanup */
  underEaProgram?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Timestamp']>;
  usageStartDate?: Maybe<Scalars['String']>;
  validateMeterSignature: Scalars['Boolean'];
  vendorId?: Maybe<Scalars['Int']>;
  vendorSyncedAt?: Maybe<Scalars['Timestamp']>;
  warehouse?: Maybe<Warehouse>;
  warrantyExpirationDate?: Maybe<Scalars['Date']>;
  warrantyLength?: Maybe<Scalars['String']>;
  warrantyLengthId?: Maybe<Scalars['Int']>;
  warrantyStartDate?: Maybe<Scalars['Date']>;
  warrantyStatus?: Maybe<Scalars['String']>;
  warrantyStatusId?: Maybe<Scalars['Int']>;
  wirelessConnectionCarrier?: Maybe<Scalars['String']>;
  wirelessConnectionCarrierId?: Maybe<Scalars['Int']>;
};

export type ChargerPortsArgs = {
  input?: InputMaybe<ListPortsInput>;
};

export type ChargerPorts2Args = {
  input?: InputMaybe<ListPortsInput>;
};

export type ChargerAggregateMeterValues = {
  __typename?: 'ChargerAggregateMeterValues';
  chargerCapacity?: Maybe<Scalars['Int']>;
  powerMeterValuesInWatts?: Maybe<Array<Maybe<ChargerPowerMeterValue>>>;
};

export type ChargerFilterInput = {
  chargerName?: InputMaybe<TableStringFilterInput>;
  cid?: InputMaybe<TableStringFilterInput>;
};

export type ChargerFilterType = {
  __typename?: 'ChargerFilterType';
  chargerName?: Maybe<TableStringFilterType>;
  cid?: Maybe<TableStringFilterType>;
};

export type ChargerForFleet = {
  __typename?: 'ChargerForFleet';
  chargerAltId: Scalars['ID'];
  chargerModel?: Maybe<ChargerModel>;
  chargerName?: Maybe<Scalars['String']>;
  chargerSerialNumber?: Maybe<Scalars['SanitizeString']>;
  chargingVehicleSoC?: Maybe<Scalars['String']>;
  connector?: Maybe<Connector>;
  connectorIndex?: Maybe<Scalars['Int']>;
  session?: Maybe<Session>;
};

export type ChargerForMobile = {
  __typename?: 'ChargerForMobile';
  altId: Scalars['ID'];
  chargerName?: Maybe<Scalars['String']>;
  chargerStatus?: Maybe<Scalars['String']>;
  chargerType?: Maybe<Scalars['String']>;
  /** @deprecated Use simultaneousChargingEnabled instead */
  concurrentCharging?: Maybe<Scalars['Boolean']>;
  evses?: Maybe<Array<Maybe<EvseForMobile>>>;
  lastUsed?: Maybe<Scalars['String']>;
  maxConnectorPower?: Maybe<Scalars['Float']>;
  openFrom?: Maybe<Scalars['String']>;
  openTo?: Maybe<Scalars['String']>;
  reservedByDriver: Scalars['Boolean'];
  /** Indication of whether the charger has simultaneous charging enabled */
  simultaneousChargingEnabled: Scalars['Boolean'];
  site?: Maybe<ChargerForMobileSite>;
};

export type ChargerForMobileSite = {
  __typename?: 'ChargerForMobileSite';
  access?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  altId: Scalars['ID'];
  country?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type ChargerGroupForExtend = {
  __typename?: 'ChargerGroupForExtend';
  altId: Scalars['ID'];
  chargerGroupName: Scalars['String'];
  /** CHARGER_PRICE or CUSTOM_ACCESS_PLAN */
  chargerGroupType: Scalars['String'];
  /** 401 - CHARGER_PRICE, 402 - CUSTOM_ACCESS_PLAN */
  chargerGroupTypeId: Scalars['Int'];
  chargers: ChargersWithMeta;
  chargersLoaded: ChargersV2WithMeta;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  host: Host;
  planDuration?: Maybe<Scalars['Int']>;
  planId?: Maybe<Scalars['ID']>;
  planTariffTags?: Maybe<Array<Maybe<PlanTariffTag>>>;
  planTariffs: Array<Maybe<PlanTariffForExtend>>;
  status: ChargerGroupSyncStatus;
  tariff?: Maybe<TariffForExtend>;
  updatedAt: Scalars['Date'];
};

/** Charger Group sync status with driivz */
export enum ChargerGroupSyncStatus {
  Draft = 'DRAFT',
  NotSynced = 'NOT_SYNCED',
  Synced = 'SYNCED',
}

export type ChargerGroupsForExtendWithMeta = {
  __typename?: 'ChargerGroupsForExtendWithMeta';
  edges: Array<ChargerGroupForExtend>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ChargerGroupsV2Sort {
  ChargerCountAsc = 'CHARGER_COUNT_ASC',
  ChargerCountDesc = 'CHARGER_COUNT_DESC',
  CreatedOnAsc = 'CREATED_ON_ASC',
  CreatedOnDesc = 'CREATED_ON_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type ChargerImage = {
  __typename?: 'ChargerImage';
  url?: Maybe<Scalars['SanitizeString']>;
};

export enum ChargerLevel {
  Dcfc = 'DCFC',
  L2 = 'L2',
}

export type ChargerModel = {
  __typename?: 'ChargerModel';
  additionalDetails?: Maybe<Scalars['String']>;
  altId: Scalars['ID'];
  autoChargeSupported?: Maybe<Scalars['Boolean']>;
  /** @deprecated Cleanup */
  breakerRating?: Maybe<Scalars['Float']>;
  /**
   * chargerLevel is used to group chargers by how fast they can charge
   * @deprecated Changed to connector type based level of charging
   */
  chargerLevel?: Maybe<ChargerLevel>;
  chargerModelEvses?: Maybe<ChargerModelEvsesWithMeta>;
  communicationProtocol?: Maybe<Scalars['String']>;
  currentThd?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  efficiency?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  evcId?: Maybe<Scalars['ID']>;
  /** @deprecated Use chargerModelEvses */
  evses?: Maybe<ChargerModelEvsesWithMeta>;
  firmwareVersion?: Maybe<Scalars['String']>;
  ingressProtection?: Maybe<Scalars['String']>;
  inputAcVoltage?: Maybe<InputAcVoltage>;
  interfaceVersion?: Maybe<InterfaceVersion>;
  isoIec?: Maybe<Scalars['Boolean']>;
  /** @deprecated Cleanup */
  legacyId?: Maybe<Scalars['Int']>;
  /** @deprecated Use manufacturerFalconConstraint { columnValue } */
  manufacturer?: Maybe<Manufacturer>;
  manufacturerFalconConstraint?: Maybe<FalconConstraint>;
  modelName?: Maybe<Scalars['String']>;
  modelNotes?: Maybe<Scalars['String']>;
  modelSku?: Maybe<Scalars['String']>;
  mountType?: Maybe<Scalars['String']>;
  networkCommunication?: Maybe<NetworkCommunication>;
  nfcReader?: Maybe<Scalars['Boolean']>;
  /** @deprecated Cleanup */
  ports?: Maybe<ChargerModelPortsWithMeta>;
  powerFactor?: Maybe<Scalars['Float']>;
  powerOutput?: Maybe<Scalars['PowerUnit']>;
  simultaneousChargeSupported?: Maybe<Scalars['Boolean']>;
  smartEnergyProfileSupport?: Maybe<Scalars['Boolean']>;
  unitType?: Maybe<UnitType>;
  weight?: Maybe<Scalars['String']>;
};

export type ChargerModelPortsArgs = {
  input?: InputMaybe<ListPortsInput>;
};

export type ChargerModelConnector = {
  __typename?: 'ChargerModelConnector';
  altId?: Maybe<Scalars['ID']>;
  cableCooling?: Maybe<CableCooling>;
  cableLength?: Maybe<CableLength>;
  cableManufacturer?: Maybe<CableManufacturer>;
  chargerConnectorId?: Maybe<Scalars['String']>;
  connectorMaxCurrent?: Maybe<Scalars['Float']>;
  connectorPlacement?: Maybe<ConnectorPlacement>;
  connectorType?: Maybe<FalconConstraint>;
  connectorTypeId?: Maybe<Scalars['ID']>;
  /** @deprecated Cleanup */
  evcId?: Maybe<Scalars['String']>;
  evseConnectorId?: Maybe<Scalars['String']>;
  evseConnectorNumber?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  format?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  inMaintenance?: Maybe<Scalars['Boolean']>;
  /** @deprecated Cleanup */
  lastMeterUpdateTime?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  lastMeterValue?: Maybe<Scalars['Int']>;
  /** @deprecated Cleanup */
  legacyConnectorId?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  legacyId?: Maybe<Scalars['Int']>;
  maxConnectorPower?: Maybe<Scalars['Float']>;
  outputAmperes?: Maybe<Scalars['Int']>;
  outputVoltageRange?: Maybe<Scalars['String']>;
  outputVolts?: Maybe<Scalars['Int']>;
  outputWatts?: Maybe<Scalars['Int']>;
  /** @deprecated Cleanup */
  port?: Maybe<ChargerModelPort>;
  powerType?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  status?: Maybe<ConnectorStatus>;
  /** @deprecated Cleanup */
  teslaConnectorType?: Maybe<TeslaConnectorType>;
  /** @deprecated Cleanup */
  teslaInstallationDate?: Maybe<Scalars['Date']>;
  /** @deprecated Cleanup */
  teslaSerialNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  /** @deprecated Use chargerConnectorId */
  uniqueConnectorId?: Maybe<Scalars['String']>;
};

export type ChargerModelConnectorInput = {
  altId?: InputMaybe<Scalars['SanitizeString']>;
  cableCooling?: InputMaybe<CableCooling>;
  cableLength?: InputMaybe<CableLength>;
  cableManufacturer?: InputMaybe<CableManufacturer>;
  chargerConnectorId?: InputMaybe<Scalars['String']>;
  chargerModelEvseId?: InputMaybe<Scalars['SanitizeString']>;
  connectorMaxCurrent: Scalars['Float'];
  connectorPlacement?: InputMaybe<ConnectorPlacement>;
  connectorType?: InputMaybe<VehicleConnectorInput>;
  connectorTypeId?: InputMaybe<Scalars['ID']>;
  evcId?: InputMaybe<Scalars['SanitizeString']>;
  evseConnectorId?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['SanitizeString']>;
  identityKey?: InputMaybe<Scalars['Int']>;
  inMaintenance?: InputMaybe<Scalars['Boolean']>;
  lastMeterUpdateTime?: InputMaybe<Scalars['SanitizeString']>;
  lastMeterValue?: InputMaybe<Scalars['Int']>;
  legacyConnectorId?: InputMaybe<Scalars['SanitizeString']>;
  legacyId?: InputMaybe<Scalars['SanitizeString']>;
  maxConnectorPower?: InputMaybe<Scalars['Float']>;
  outputAmperes?: InputMaybe<Scalars['Int']>;
  outputVoltageRange?: InputMaybe<Scalars['SanitizeString']>;
  outputVolts?: InputMaybe<Scalars['Int']>;
  outputWatts?: InputMaybe<Scalars['Int']>;
  portId?: InputMaybe<Scalars['ID']>;
  powerType?: InputMaybe<Scalars['SanitizeString']>;
  status?: InputMaybe<ConnectorStatus>;
  teslaConnectorType?: InputMaybe<TeslaConnectorType>;
  teslaInstallationDate?: InputMaybe<Scalars['Date']>;
  teslaSerialNumber?: InputMaybe<Scalars['SanitizeString']>;
  uniqueConnectorId?: InputMaybe<Scalars['SanitizeString']>;
  vendorId?: InputMaybe<Scalars['SanitizeString']>;
  wiredPhase?: InputMaybe<Scalars['Int']>;
};

export type ChargerModelConnectorsWithMeta = {
  __typename?: 'ChargerModelConnectorsWithMeta';
  edges?: Maybe<Array<Maybe<ChargerModelConnector>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ChargerModelEvse = {
  __typename?: 'ChargerModelEvse';
  altId?: Maybe<Scalars['ID']>;
  chargerModelConnectors?: Maybe<ChargerModelConnectorsWithMeta>;
  /** @deprecated Cleanup */
  connectors?: Maybe<ConnectorsWithMeta>;
  /** @deprecated Cleanup */
  evseId?: Maybe<Scalars['String']>;
  identityKey?: Maybe<Scalars['String']>;
};

export type ChargerModelEvseInput = {
  altId?: InputMaybe<Scalars['ID']>;
  chargerModelConnectors?: InputMaybe<Array<InputMaybe<ChargerModelConnectorInput>>>;
  connectors?: InputMaybe<Array<InputMaybe<ChargerModelConnectorInput>>>;
  evseId?: InputMaybe<Scalars['String']>;
  identityKey?: InputMaybe<Scalars['String']>;
};

export type ChargerModelEvsesWithMeta = {
  __typename?: 'ChargerModelEvsesWithMeta';
  edges?: Maybe<Array<Maybe<ChargerModelEvse>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ChargerModelPort = {
  __typename?: 'ChargerModelPort';
  accessType?: Maybe<Scalars['String']>;
  altId?: Maybe<Scalars['ID']>;
  chargeBoxIdentity?: Maybe<Scalars['String']>;
  concurrentChargingSupported?: Maybe<Scalars['Boolean']>;
  connectors?: Maybe<ChargerModelConnectorsWithMeta>;
  evcId?: Maybe<Scalars['String']>;
  handicapAccessible?: Maybe<Scalars['Boolean']>;
  lastHeartbeat?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['Int']>;
  powerLevel?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
};

export type ChargerModelPortConnectorsArgs = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type ChargerModelPortsWithMeta = {
  __typename?: 'ChargerModelPortsWithMeta';
  edges?: Maybe<Array<Maybe<ChargerModelPort>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ChargerModelsWithMeta = {
  __typename?: 'ChargerModelsWithMeta';
  edges?: Maybe<Array<Maybe<ChargerModel>>>;
  filter?: Maybe<ListChargerModelsFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListChargerModelsSearchType>;
  sort?: Maybe<ListChargerModelsSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type ChargerPowerMeterValue = {
  __typename?: 'ChargerPowerMeterValue';
  chargerLimit?: Maybe<Scalars['Float']>;
  powerMeterValueInWatts?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['Date']>;
};

export type ChargerPrice = {
  __typename?: 'ChargerPrice';
  chargerType?: Maybe<Scalars['String']>;
  connectorType?: Maybe<Scalars['String']>;
  connectorsPrice?: Maybe<Array<Maybe<Connectors>>>;
  maxSessionFee?: Maybe<Scalars['String']>;
  touPricing?: Maybe<Array<Maybe<TouPrices>>>;
};

export type ChargerPriceConnectorsPriceArgs = {
  input?: InputMaybe<ConnectorPriceInput>;
};

export type ChargerPriceTouPricingArgs = {
  input?: InputMaybe<TouPricingInput>;
};

export type ChargerUtilizationChart = {
  __typename?: 'ChargerUtilizationChart';
  points: Array<Maybe<ChargerUtilizationPoint>>;
  xLabel: Scalars['String'];
};

export type ChargerUtilizationChartData = {
  __typename?: 'ChargerUtilizationChartData';
  groupedBy: ChargerUtilizationChartGroupEnum;
  totalSiteUtilization: Scalars['Float'];
  utilizationChart: Array<Maybe<ChargerUtilizationChart>>;
};

export enum ChargerUtilizationChartGroupEnum {
  Day = 'Day',
  Hour = 'Hour',
  Month = 'Month',
  Year = 'Year',
}

export type ChargerUtilizationChartInput = {
  endDate: Scalars['Date'];
  siteId: Scalars['UUID'];
  startDate: Scalars['Date'];
  timeZone: Scalars['String'];
};

export type ChargerUtilizationPoint = {
  __typename?: 'ChargerUtilizationPoint';
  name: Scalars['String'];
  utilizationPercentage: Scalars['Float'];
};

export type ChargerUtilizationReport = {
  __typename?: 'ChargerUtilizationReport';
  chargerId: Scalars['String'];
  chargerModel: Scalars['String'];
  siteUtilizationPercentage: Scalars['Float'];
  totalKwh: Scalars['Float'];
  utilizationPercentage: Scalars['Float'];
};

export type ChargerV2 = {
  __typename?: 'ChargerV2';
  additionalEquipment?: Maybe<Scalars['String']>;
  additionalSerial?: Maybe<Scalars['String']>;
  altId: Scalars['ID'];
  brand?: Maybe<Scalars['String']>;
  ccReaderType?: Maybe<Scalars['String']>;
  certified?: Maybe<Scalars['Boolean']>;
  chargePointIdentity?: Maybe<Scalars['String']>;
  chargerIsManaged?: Maybe<Scalars['Boolean']>;
  chargerModel?: Maybe<ChargerModel>;
  chargerName?: Maybe<Scalars['String']>;
  chargerStatus?: Maybe<FalconConstraint>;
  cid?: Maybe<Scalars['String']>;
  coBranding?: Maybe<Scalars['String']>;
  communicationMethod?: Maybe<Scalars['String']>;
  connectionDate?: Maybe<Scalars['Date']>;
  connectionUri?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  displayTariffSynced?: Maybe<Scalars['Boolean']>;
  dynamicIp?: Maybe<Scalars['Boolean']>;
  evses?: Maybe<Array<Maybe<EvseV2>>>;
  excludeFromOcpi?: Maybe<Scalars['Boolean']>;
  excluded?: Maybe<Scalars['Boolean']>;
  fieldStationStatus?: Maybe<FieldStationStatus>;
  firstActive?: Maybe<Scalars['Date']>;
  fundingSource?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  identityKey?: Maybe<Scalars['String']>;
  ignoreStatusNotifications?: Maybe<Scalars['Boolean']>;
  installDate?: Maybe<Scalars['Date']>;
  ipAddress?: Maybe<Scalars['String']>;
  lastReceivedCommSignal?: Maybe<Scalars['Date']>;
  lastReportedFirmwareVersion?: Maybe<Scalars['String']>;
  localLoadBalancingEnabled?: Maybe<Scalars['Boolean']>;
  localNetworkMaster?: Maybe<Scalars['Boolean']>;
  locationType?: Maybe<Scalars['String']>;
  macAddress?: Maybe<Scalars['String']>;
  maintenanceComments?: Maybe<Scalars['String']>;
  maxPower?: Maybe<Scalars['Int']>;
  meterSigningSupported?: Maybe<Scalars['Boolean']>;
  modelNotes?: Maybe<Scalars['String']>;
  modemSerialNumber?: Maybe<Scalars['String']>;
  mountType?: Maybe<Scalars['String']>;
  nextMaintenanceDate?: Maybe<Scalars['Date']>;
  nonNetworked?: Maybe<Scalars['Boolean']>;
  productIdentityKeyId?: Maybe<Scalars['Int']>;
  serialNumber?: Maybe<Scalars['String']>;
  serverSideLocalLoadBalancingEnabled?: Maybe<Scalars['Boolean']>;
  setTariffSynced?: Maybe<Scalars['Boolean']>;
  showInThirdPartyFilters?: Maybe<Scalars['Boolean']>;
  simCardIccid?: Maybe<Scalars['String']>;
  simCardImsi?: Maybe<Scalars['String']>;
  simultaneousChargingEnabled?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Site>;
  smartChargingEnabled?: Maybe<Scalars['Boolean']>;
  tariffSyncStatus?: Maybe<TariffSyncStatus>;
  teslaAdapter?: Maybe<Scalars['Boolean']>;
  underEaProgram?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  usageStartDate?: Maybe<Scalars['Date']>;
  validateMeterSignature?: Maybe<Scalars['Boolean']>;
  warrantyExpirationDate?: Maybe<Scalars['Date']>;
  warrantyStartDate?: Maybe<Scalars['Date']>;
};

export type ChargersConsumptionForExtendData = {
  __typename?: 'ChargersConsumptionForExtendData';
  chargerId: Scalars['String'];
  chargerName: Scalars['String'];
  /** Alt ID of the connector */
  connectorId: Scalars['ID'];
  /** Max power output of the connector in kW */
  connectorMaxOutput: Scalars['Float'];
  /** Status of the connector at the time of the query */
  connectorStatus?: Maybe<Scalars['String']>;
  connectorType?: Maybe<Scalars['String']>;
  evseId: Scalars['String'];
  /** Vendor Id of the last session */
  lastSessionVendorId?: Maybe<Scalars['Int']>;
  /** Flag indicating if the connector is Reservable */
  reservable?: Maybe<Scalars['Boolean']>;
  /** Total sessions in that connection for that datePreset */
  totalSessions: Scalars['Int'];
  /** Total sessions power value dispensed in kWh */
  totalSessionsPower: Scalars['Float'];
  /** Total sessions time in minutes */
  totalSessionsTime: Scalars['String'];
};

export type ChargersForFleetMeta = {
  __typename?: 'ChargersForFleetMeta';
  edges?: Maybe<Array<Maybe<ChargerForFleet>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListChargersForFleetSearchType>;
  sort?: Maybe<ListChargersForFleetSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type ChargersForMobile = {
  __typename?: 'ChargersForMobile';
  altId: Scalars['ID'];
  chargerName?: Maybe<Scalars['String']>;
  chargerStatus?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  openFrom?: Maybe<Scalars['String']>;
  openTo?: Maybe<Scalars['String']>;
  /** Indication of whether the charger has simultaneous charging enabled */
  simultaneousChargingEnabled: Scalars['Boolean'];
  site?: Maybe<ChargerForMobileSite>;
};

export type ChargersForMobileInput = {
  /** Required: A float used to calculate the users distance from the site */
  latitude: Scalars['Float'];
  /** Required: A float used to calculate the users distance from the site */
  longitude: Scalars['Float'];
  /** An array of integers used to filter network operators. */
  networkOperatorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  search?: InputMaybe<Scalars['String']>;
};

export type ChargersForMobileWithMeta = {
  __typename?: 'ChargersForMobileWithMeta';
  edges?: Maybe<Array<Maybe<ChargersForMobile>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ChargersV2Sort = {
  chargerName?: InputMaybe<TableSortType>;
  /**
   * Sort by the highest connector status value, where
   * Charging=1,
   * Available=2,
   * Preparing=3,
   * Finishing=4,
   * Paused=5,
   * Discharging=6,
   * Reserved=7,
   * Unavailable=8,
   * Faulted=9,
   * Unknown=10,
   * and New=11.
   */
  connectorStatusMax?: InputMaybe<TableSortType>;
  /**
   * Sort by the highest connector status value, where
   * New=1,
   * Unknown=2,
   * Faulted=3,
   * Unavailable=4,
   * Reserved=5,
   * Discharging=6,
   * Paused=7,
   * Finishing=8,
   * Preparing=9,
   * Available=10,
   * and Charging=11.
   */
  connectorStatusMin?: InputMaybe<TableSortType>;
  connectorStatusValue?: InputMaybe<TableSortType>;
  modelName?: InputMaybe<TableSortType>;
  siteAddress1?: InputMaybe<TableSortType>;
  siteName?: InputMaybe<TableSortType>;
};

export type ChargersV2WithMeta = {
  __typename?: 'ChargersV2WithMeta';
  edges?: Maybe<Array<Maybe<ChargerV2>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ChargersWithMeta = {
  __typename?: 'ChargersWithMeta';
  edges?: Maybe<Array<Maybe<Charger>>>;
  filter?: Maybe<ListChargersFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListChargersSearchType>;
  sort?: Maybe<ListChargersSortType>;
  total?: Maybe<Scalars['Int']>;
};

export enum ChargingLevel {
  Dcfc = 'DCFC',
  L2 = 'L2',
}

export enum ChargingProfileStatus {
  /** Accepted */
  Accepted = 'ACCEPTED',
  /** Error */
  Error = 'ERROR',
  /** Sent */
  Sent = 'SENT',
  /** Validated */
  Validated = 'VALIDATED',
}

export type CheckDriverFirebaseInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type CheckDriverPhoneNumberInput = {
  phone: Scalars['SanitizeString'];
};

export type ClientToken = {
  __typename?: 'ClientToken';
  clientToken?: Maybe<Scalars['String']>;
};

export type CompleteAutochargeEnrollmentInput = {
  connectorId: Scalars['ID'];
};

export type Connector = {
  __typename?: 'Connector';
  activePowerInWatts?: Maybe<Scalars['Float']>;
  altId: Scalars['ID'];
  cableCooling?: Maybe<CableCooling>;
  cableLength?: Maybe<CableLength>;
  cableManufacturer?: Maybe<CableManufacturer>;
  chargerConnectorId?: Maybe<Scalars['String']>;
  /** chargingLevel is used to group connectors by how fast they can charge */
  chargingLevel: ChargingLevel;
  /** @deprecated Use chargingProfileStatusConstraint */
  chargingProfileStatus?: Maybe<ChargingProfileStatus>;
  chargingProfileStatusConstraint?: Maybe<FalconConstraint>;
  connectorFormatConstraint?: Maybe<FalconConstraint>;
  connectorIndex?: Maybe<Scalars['Int']>;
  connectorMaxCurrent?: Maybe<Scalars['Float']>;
  /** @deprecated Use connectorPlacementConstraint */
  connectorPlacement?: Maybe<ConnectorPlacement>;
  connectorPlacementConstraint?: Maybe<FalconConstraint>;
  connectorStatusConstraint?: Maybe<FalconConstraint>;
  connectorType?: Maybe<FalconConstraint>;
  connectorTypeId?: Maybe<Scalars['ID']>;
  energyUtilization?: Maybe<Scalars['Float']>;
  /** @deprecated Cleanup */
  evcId?: Maybe<Scalars['String']>;
  evse?: Maybe<Evse>;
  /** @deprecated Use connectorFormatConstraint */
  format?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  identityKey?: Maybe<Scalars['Int']>;
  inMaintenance?: Maybe<Scalars['Boolean']>;
  isThrottling?: Maybe<Scalars['Boolean']>;
  /** @deprecated Cleanup */
  lastMeterUpdateTime?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  lastMeterValue?: Maybe<Scalars['Int']>;
  /** @deprecated Cleanup */
  legacyConnectorId?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  legacyId?: Maybe<Scalars['Int']>;
  maxConnectorPower?: Maybe<Scalars['Float']>;
  outputAmperes?: Maybe<Scalars['Int']>;
  outputKw?: Maybe<Scalars['Float']>;
  outputVoltageRange?: Maybe<Scalars['String']>;
  outputVolts?: Maybe<Scalars['Int']>;
  /** @deprecated Use outputKw */
  outputWatts?: Maybe<Scalars['Int']>;
  /** @deprecated Cleanup */
  port?: Maybe<Port>;
  powerLimit?: Maybe<Scalars['Float']>;
  /** @deprecated Cleanup */
  powerType?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  requestedPowerLimit?: Maybe<Scalars['Float']>;
  reservable?: Maybe<Scalars['Boolean']>;
  serialNumber?: Maybe<Scalars['String']>;
  /** @deprecated Use connectorStatusConstraint */
  status?: Maybe<ConnectorStatus>;
  teslaAdapterInMaintenance?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use teslaConnectorTypeConstraint */
  teslaConnectorType?: Maybe<TeslaConnectorType>;
  teslaConnectorTypeConstraint?: Maybe<FalconConstraint>;
  teslaInstallationDate?: Maybe<Scalars['Date']>;
  teslaSerialNumber?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  type?: Maybe<Scalars['String']>;
  /** @deprecated Use chargerConnectorId */
  uniqueConnectorId?: Maybe<Scalars['String']>;
  /** @deprecated Cleanup */
  vehicleConnectorType?: Maybe<VehicleConnector>;
  /** @deprecated Cleanup */
  vehicleConnectorTypeId?: Maybe<Scalars['ID']>;
  vendorId?: Maybe<Scalars['Int']>;
  wiredPhase?: Maybe<Scalars['Int']>;
};

export type ConnectorFilterInput = {
  connectorTypeId?: InputMaybe<TableIntFilterInput>;
  outputWatts?: InputMaybe<TableIntFilterInput>;
  status?: InputMaybe<TableStringFilterInput>;
};

export type ConnectorFilterType = {
  __typename?: 'ConnectorFilterType';
  connectorTypeId?: Maybe<TableIntFilterType>;
  outputWatts?: Maybe<TableIntFilterType>;
  status?: Maybe<TableStringFilterType>;
};

export type ConnectorForMobile = {
  __typename?: 'ConnectorForMobile';
  altId?: Maybe<Scalars['ID']>;
  connectorPlacement: ConnectorPlacement;
  connectorStatus?: Maybe<Scalars['String']>;
  connectorType?: Maybe<Scalars['String']>;
  hasTeslaAdapter?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  maxPower?: Maybe<Scalars['Float']>;
  reservable?: Maybe<Scalars['Boolean']>;
  supportsAutocharge: Scalars['Boolean'];
  vehicleConnectorTypeId?: Maybe<Scalars['Int']>;
};

export type ConnectorInput = {
  altId?: InputMaybe<Scalars['SanitizeString']>;
  cableCooling?: InputMaybe<CableCooling>;
  cableLength?: InputMaybe<CableLength>;
  cableManufacturer?: InputMaybe<CableManufacturer>;
  chargerConnectorId?: InputMaybe<Scalars['String']>;
  chargingProfileStatus?: InputMaybe<ChargingProfileStatus>;
  connectorMaxCurrent?: InputMaybe<Scalars['Float']>;
  connectorPlacement?: InputMaybe<ConnectorPlacement>;
  connectorType?: InputMaybe<VehicleConnectorInput>;
  connectorTypeId?: InputMaybe<Scalars['ID']>;
  evcId?: InputMaybe<Scalars['SanitizeString']>;
  format?: InputMaybe<Scalars['SanitizeString']>;
  identityKey?: InputMaybe<Scalars['Int']>;
  inMaintenance?: InputMaybe<Scalars['Boolean']>;
  isThrottling?: InputMaybe<Scalars['Boolean']>;
  lastMeterUpdateTime?: InputMaybe<Scalars['SanitizeString']>;
  lastMeterValue?: InputMaybe<Scalars['Int']>;
  legacyConnectorId?: InputMaybe<Scalars['SanitizeString']>;
  legacyId?: InputMaybe<Scalars['SanitizeString']>;
  maxConnectorPower?: InputMaybe<Scalars['Float']>;
  outputAmperes?: InputMaybe<Scalars['Int']>;
  outputKw?: InputMaybe<Scalars['Float']>;
  outputVoltageRange?: InputMaybe<Scalars['SanitizeString']>;
  outputVolts?: InputMaybe<Scalars['Int']>;
  outputWatts?: InputMaybe<Scalars['Int']>;
  portId?: InputMaybe<Scalars['ID']>;
  powerType?: InputMaybe<Scalars['SanitizeString']>;
  requestedPowerLimit?: InputMaybe<Scalars['Float']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ConnectorStatus>;
  teslaAdapterInMaintenance?: InputMaybe<Scalars['Boolean']>;
  teslaConnectorType?: InputMaybe<TeslaConnectorType>;
  teslaInstallationDate?: InputMaybe<Scalars['Date']>;
  teslaSerialNumber?: InputMaybe<Scalars['SanitizeString']>;
  uniqueConnectorId?: InputMaybe<Scalars['SanitizeString']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  wiredPhase?: InputMaybe<Scalars['Int']>;
};

export enum ConnectorPlacement {
  /** Bottom */
  Bottom = 'BOTTOM',
  /** Center */
  Center = 'CENTER',
  /** Left */
  Left = 'LEFT',
  /** Not specified */
  NotSpecified = 'NOT_SPECIFIED',
  /** Other */
  Other = 'OTHER',
  /** Right */
  Right = 'RIGHT',
  /** Top */
  Top = 'TOP',
}

export type ConnectorPriceInput = {
  /** Description of the type which includes DC, L2, Roaming */
  chargerType?: InputMaybe<Scalars['String']>;
  /** All of the connectors available at the charging station */
  connectors?: InputMaybe<Array<InputMaybe<ConnectorsInput>>>;
  /** This is passed to verify if a charger is DC or not in order to search for touPricing */
  isDCCharger?: InputMaybe<Scalars['String']>;
  /** Session fee at the charger */
  maxSessionFee?: InputMaybe<Scalars['String']>;
  /** Used to find Tou pricing */
  planId?: InputMaybe<Scalars['String']>;
  /** Used to find Tou Pricing */
  siteId?: InputMaybe<Scalars['String']>;
};

export enum ConnectorStatus {
  /** Available */
  Available = 'AVAILABLE',
  /** Charging */
  Charging = 'CHARGING',
  /** Discharging */
  Discharging = 'DISCHARGING',
  /** Faulted */
  Faulted = 'FAULTED',
  /** Finishing */
  Finishing = 'FINISHING',
  /** New */
  New = 'NEW',
  /** Paused */
  Paused = 'PAUSED',
  /** Preparing */
  Preparing = 'PREPARING',
  /** Reserved */
  Reserved = 'RESERVED',
  /** Unavailable */
  Unavailable = 'UNAVAILABLE',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type ConnectorV2 = {
  __typename?: 'ConnectorV2';
  altId: Scalars['ID'];
  /** chargingLevel is used to group connectors by how fast they can charge */
  chargingLevel: ChargingLevel;
  /** Position of the connector on the charger, from left to right */
  connectorIndex?: Maybe<Scalars['Int']>;
  connectorPlacement?: Maybe<FalconConstraint>;
  connectorStatus?: Maybe<FalconConstraint>;
  connectorType?: Maybe<FalconConstraint>;
  evse?: Maybe<EvseV2>;
  identityKey?: Maybe<Scalars['Int']>;
  inMaintenance?: Maybe<Scalars['Boolean']>;
  lastMeterValue?: Maybe<Scalars['Int']>;
  maxConnectorPower?: Maybe<Scalars['Float']>;
  outputAmperes?: Maybe<Scalars['Float']>;
  outputWatts?: Maybe<Scalars['Float']>;
  reservable?: Maybe<Scalars['Boolean']>;
  status?: Maybe<FalconConstraint>;
  teslaAdapterInMaintenance?: Maybe<Scalars['Boolean']>;
  teslaConnectorType?: Maybe<FalconConstraint>;
};

export type Connectors = {
  __typename?: 'Connectors';
  /** A string represents the Roaming relationship */
  altText?: Maybe<Scalars['String']>;
  /** A string that represents the fee related to being idle */
  idleFee?: Maybe<Scalars['String']>;
  /** A string that represents the kilowatt hour fee */
  kwhFee?: Maybe<Scalars['String']>;
  /** A string that represents the fee per minute */
  minuteFee?: Maybe<Scalars['String']>;
  /** A string that represents the session Fee related to the connector type */
  sessionFee?: Maybe<Scalars['String']>;
  /** An integer that maps to connectors IDs */
  vehicleConnectorId?: Maybe<Scalars['Int']>;
};

export type ConnectorsInput = {
  /** A string represents the Roaming relationship */
  altText?: InputMaybe<Scalars['String']>;
  /** A string that describes the connector type */
  connectorType?: InputMaybe<Scalars['String']>;
  /** A string that represents the fee related to being idle */
  idleFee?: InputMaybe<Scalars['String']>;
  /** A string that represents the kilowatt hour fee */
  kwhFee?: InputMaybe<Scalars['String']>;
  /** A string that represents the fee per minute */
  minuteFee?: InputMaybe<Scalars['String']>;
  /** A string that represents the session Fee related to the connector type */
  sessionFee?: InputMaybe<Scalars['String']>;
};

export type ConnectorsWithMeta = {
  __typename?: 'ConnectorsWithMeta';
  edges?: Maybe<Array<Maybe<Connector>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type Contact = {
  __typename?: 'Contact';
  altId?: Maybe<Scalars['ID']>;
  contactName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  workTitle?: Maybe<Scalars['String']>;
};

export type ContactsWithMeta = {
  __typename?: 'ContactsWithMeta';
  edges?: Maybe<Array<Maybe<Contact>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type Contract = {
  __typename?: 'Contract';
  account?: Maybe<Account>;
  altId?: Maybe<Scalars['ID']>;
  closureDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  plan?: Maybe<Plan>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<FalconConstraint>;
  vendorId?: Maybe<Scalars['ID']>;
  vendorSyncedAt?: Maybe<Scalars['Date']>;
};

export type ContractExpirationInput = {
  monthsFromExp: Scalars['Int'];
};

export type ContractsWithMeta = {
  __typename?: 'ContractsWithMeta';
  edges?: Maybe<Array<Maybe<Contract>>>;
  filter?: Maybe<ListContractsFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListContractsSearchType>;
  sort?: Maybe<ListContractsSortType>;
  total?: Maybe<Scalars['Int']>;
};

export enum CorruptedReason {
  CorruptedByWrongPowerPerHourTransactions = 'CORRUPTED_BY_WRONG_POWER_PER_HOUR_TRANSACTIONS',
  Unknown = 'UNKNOWN',
}

export type Coupon = {
  __typename?: 'Coupon';
  adUrl?: Maybe<Scalars['String']>;
  imgUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CreateAccountInput = {
  accountStatus?: InputMaybe<AccountStatus>;
  billingAddress?: InputMaybe<CreateAddressInput>;
  enrolledOn?: InputMaybe<Scalars['Date']>;
  evcId?: InputMaybe<Scalars['SanitizeString']>;
  isFcms?: InputMaybe<Scalars['Boolean']>;
  mailingAddress?: InputMaybe<CreateAddressInput>;
  vendorId?: InputMaybe<Scalars['Int']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']>;
};

export type CreateAccountOwnerFromDriivzInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  accountStatus?: InputMaybe<AccountStatus>;
  balance?: InputMaybe<Scalars['Float']>;
  balanceType?: InputMaybe<Scalars['ID']>;
  billingAddress: CreateAddressInput;
  card?: InputMaybe<CreateCardInput>;
  driverStatusId?: InputMaybe<Scalars['Int']>;
  driverType?: InputMaybe<DriverType>;
  email: Scalars['SanitizeString'];
  enrolledOn?: InputMaybe<Scalars['Date']>;
  firstName: Scalars['SanitizeString'];
  importedDriivzAccount?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['SanitizeString'];
  legacyId?: InputMaybe<Scalars['SanitizeString']>;
  legacyPhone?: InputMaybe<Scalars['SanitizeString']>;
  mailingAddress?: InputMaybe<CreateAddressInput>;
  password?: InputMaybe<Scalars['SanitizeString']>;
  phone?: InputMaybe<Scalars['SanitizeString']>;
  vendorAccountType?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']>;
};

export type CreateAccountOwnerInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  accountStatus?: InputMaybe<AccountStatus>;
  balanceType?: InputMaybe<Scalars['ID']>;
  billingAddress: CreateAddressInput;
  card?: InputMaybe<CreateCardInput>;
  driverStatusId?: InputMaybe<Scalars['Int']>;
  email: Scalars['SanitizeString'];
  enrolledOn?: InputMaybe<Scalars['Date']>;
  firstName: Scalars['SanitizeString'];
  importedDriivzAccount?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['SanitizeString'];
  legacyId?: InputMaybe<Scalars['SanitizeString']>;
  legacyPhone?: InputMaybe<Scalars['SanitizeString']>;
  mailingAddress: CreateAddressInput;
  password?: InputMaybe<Scalars['SanitizeString']>;
  phone?: InputMaybe<Scalars['SanitizeString']>;
  vendorAccountType?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']>;
};

export type CreateAccountOwnerWithoutPhoneInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  accountStatus?: InputMaybe<AccountStatus>;
  billingAddress?: InputMaybe<CreateAddressInput>;
  card?: InputMaybe<CreateCardInput>;
  driverStatusId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  enrolledOn?: InputMaybe<Scalars['Date']>;
  firstName: Scalars['String'];
  importedDriivzAccount?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  legacyId?: InputMaybe<Scalars['String']>;
  mailingAddress?: InputMaybe<CreateAddressInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type CreateAccountVehicleInput = {
  accountId: Scalars['UUID'];
  driverId: Scalars['UUID'];
  macAddress?: InputMaybe<Scalars['SanitizeString']>;
  media?: InputMaybe<GetMediaInput>;
  nickName?: InputMaybe<Scalars['SanitizeString']>;
  vehicleId: Scalars['UUID'];
  vin?: InputMaybe<Scalars['SanitizeString']>;
};

export type CreateAdHocContractInput = {
  accountId: Scalars['ID'];
  amount: Scalars['Int'];
};

export type CreateAddressInput = {
  address1?: InputMaybe<Scalars['SanitizeString']>;
  address2?: InputMaybe<Scalars['SanitizeString']>;
  address3?: InputMaybe<Scalars['SanitizeString']>;
  address4?: InputMaybe<Scalars['SanitizeString']>;
  /** administrativeArea must be the 2-character state-code for US addresses (CA, OR, AZ), or the full name of the province for Canadian addresses (Quebec, Ontario, British Columbia) */
  administrativeArea?: InputMaybe<Scalars['SanitizeString']>;
  country?: InputMaybe<Scalars['SanitizeString']>;
  locality?: InputMaybe<Scalars['SanitizeString']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']>;
};

export type CreateCardInput = {
  brand: Scalars['String'];
  cardTypeId?: InputMaybe<Scalars['Int']>;
  cardVendorId?: InputMaybe<Scalars['ID']>;
  externalNumber: Scalars['String'];
  internalNumber: Scalars['String'];
  nickName?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type CreateCardOrderInput = {
  contractId?: InputMaybe<Scalars['ID']>;
  driverId: Scalars['ID'];
};

/** Input for creating a charger group plan */
export type CreateChargerGroupPlanForExtendInput = {
  chargerGroupName: Scalars['SanitizeString'];
  description: Scalars['SanitizeString'];
  /** The amount of time in months that the plan will be valid */
  planDuration: Scalars['Int'];
};

export type CreateChargerInput = {
  additionalEquipment?: InputMaybe<Scalars['SanitizeString']>;
  additionalSerial?: InputMaybe<Scalars['SanitizeString']>;
  altId?: InputMaybe<Scalars['ID']>;
  authenticationMethodsConstraint?: InputMaybe<Scalars['Int']>;
  authenticationModeId?: InputMaybe<Scalars['Int']>;
  brand?: InputMaybe<Scalars['SanitizeString']>;
  chargePointIdentity?: InputMaybe<Scalars['SanitizeString']>;
  chargerIsManaged?: InputMaybe<Scalars['Boolean']>;
  chargerModelId: Scalars['ID'];
  chargerName: Scalars['SanitizeString'];
  chargerStatusId?: InputMaybe<Scalars['Int']>;
  cid: Scalars['SanitizeString'];
  coBranding?: InputMaybe<Scalars['SanitizeString']>;
  communicationMethod?: InputMaybe<Scalars['SanitizeString']>;
  connectionDate?: InputMaybe<Scalars['SanitizeString']>;
  connectionUri?: InputMaybe<Scalars['SanitizeString']>;
  description?: InputMaybe<Scalars['SanitizeString']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  equipmentId?: InputMaybe<Scalars['SanitizeString']>;
  evcId?: InputMaybe<Scalars['SanitizeString']>;
  evseId?: InputMaybe<Scalars['SanitizeString']>;
  evses?: InputMaybe<Array<InputMaybe<EvseInput>>>;
  excludeFromOcpi?: InputMaybe<Scalars['Boolean']>;
  excluded?: InputMaybe<Scalars['Boolean']>;
  fieldStationStatus: FieldStationStatus;
  fundingSource?: InputMaybe<Scalars['SanitizeString']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  identityKey?: InputMaybe<Scalars['SanitizeString']>;
  ignoreStatusNotifications?: InputMaybe<Scalars['Boolean']>;
  infrastructureProviderCompanyId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['SanitizeString']>;
  legacyId?: InputMaybe<Scalars['SanitizeString']>;
  localLoadBalancingEnabled?: InputMaybe<Scalars['Boolean']>;
  locationId: Scalars['ID'];
  locationType: Scalars['SanitizeString'];
  macAddress?: InputMaybe<Scalars['SanitizeString']>;
  maintenanceComments?: InputMaybe<Scalars['String']>;
  maintenanceVendorId?: InputMaybe<Scalars['Int']>;
  maxPower?: InputMaybe<Scalars['Int']>;
  meterSigningSupported?: InputMaybe<Scalars['Boolean']>;
  modemSerialNumber?: InputMaybe<Scalars['String']>;
  networkConnectionTypeId?: InputMaybe<Scalars['Int']>;
  nextMaintenanceDate?: InputMaybe<Scalars['Date']>;
  nonNetworked?: InputMaybe<Scalars['Boolean']>;
  ports?: InputMaybe<Array<InputMaybe<PortInput>>>;
  productIdentityKeyId?: InputMaybe<Scalars['Int']>;
  serialNumber: Scalars['SanitizeString'];
  serverSideLocalLoadBalancingEnabled?: InputMaybe<Scalars['Boolean']>;
  showInThirdPartyFilters?: InputMaybe<Scalars['Boolean']>;
  simultaneousChargingEnabled?: InputMaybe<Scalars['Boolean']>;
  smartChargingEnabled?: InputMaybe<Scalars['Boolean']>;
  usageStartDate?: InputMaybe<Scalars['SanitizeString']>;
  validateMeterSignature?: InputMaybe<Scalars['Boolean']>;
  vendorId?: InputMaybe<Scalars['Int']>;
  warehouseLocation?: InputMaybe<Scalars['SanitizeString']>;
  warrantyExpirationDate?: InputMaybe<Scalars['Date']>;
  warrantyLengthId?: InputMaybe<Scalars['Int']>;
  warrantyStartDate?: InputMaybe<Scalars['Date']>;
  warrantyStatusId?: InputMaybe<Scalars['Int']>;
  wirelessConnectionCarrierId?: InputMaybe<Scalars['Int']>;
};

export type CreateChargerModelInput = {
  additionalDetails?: InputMaybe<Scalars['SanitizeString']>;
  autoChargeSupported?: InputMaybe<Scalars['Boolean']>;
  breakerRating?: InputMaybe<Scalars['Float']>;
  chargerModelEvses?: InputMaybe<Array<InputMaybe<ChargerModelEvseInput>>>;
  communicationProtocol?: InputMaybe<Scalars['SanitizeString']>;
  currentThd?: InputMaybe<Scalars['SanitizeString']>;
  dimensions?: InputMaybe<Scalars['SanitizeString']>;
  display?: InputMaybe<Scalars['SanitizeString']>;
  efficiency?: InputMaybe<Scalars['SanitizeString']>;
  evses?: InputMaybe<Array<InputMaybe<ChargerModelEvseInput>>>;
  firmwareVersion?: InputMaybe<Scalars['SanitizeString']>;
  ingressProtection?: InputMaybe<Scalars['SanitizeString']>;
  inputAcVoltage?: InputMaybe<InputAcVoltage>;
  interfaceVersion?: InputMaybe<InterfaceVersion>;
  isoIec?: InputMaybe<Scalars['Boolean']>;
  legacyId?: InputMaybe<Scalars['SanitizeString']>;
  manufacturer?: InputMaybe<Manufacturer>;
  modelName?: InputMaybe<Scalars['SanitizeString']>;
  modelNotes?: InputMaybe<Scalars['String']>;
  modelSku?: InputMaybe<Scalars['SanitizeString']>;
  mountType?: InputMaybe<Scalars['String']>;
  networkCommunication?: InputMaybe<NetworkCommunication>;
  nfcReader?: InputMaybe<Scalars['Boolean']>;
  ports?: InputMaybe<Array<InputMaybe<PortInput>>>;
  powerFactor?: InputMaybe<Scalars['Float']>;
  powerOutput: Scalars['PowerUnit'];
  simultaneousChargeSupported?: InputMaybe<Scalars['Boolean']>;
  smartEnergyProfileSupport?: InputMaybe<Scalars['Boolean']>;
  unitType?: InputMaybe<UnitType>;
  vendorId?: InputMaybe<Scalars['SanitizeString']>;
  weight?: InputMaybe<Scalars['SanitizeString']>;
};

/** Input for creating a charger group */
export type CreateChargerPriceGroupForExtendInput = {
  chargerGroupName: Scalars['SanitizeString'];
  description?: InputMaybe<Scalars['SanitizeString']>;
};

export type CreateContactInput = {
  contactName?: InputMaybe<Scalars['SanitizeString']>;
  email?: InputMaybe<Scalars['SanitizeString']>;
  hostId: Scalars['ID'];
  phone?: InputMaybe<Scalars['SanitizeString']>;
  workTitle?: InputMaybe<Scalars['SanitizeString']>;
};

export type CreateContractInput = {
  accountId: Scalars['ID'];
  closureDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  planId: Scalars['ID'];
  startDate: Scalars['Date'];
  statusId: Scalars['ID'];
};

export type CreateCreditForUserInput = {
  /** The amount of credit to grant to the user */
  amount: Scalars['Float'];
  /** The falcon ID of the credit source for which the credit should be created. */
  creditSourceId: Scalars['Int'];
  /** The desired expiration date of the credit (only applies to new credits where the creditSourceId != 0) */
  expiryDate?: InputMaybe<Scalars['Date']>;
  /** The account number from the Driivz account (a.k.a. the falcon.accounts.vendor_id) */
  vendorId: Scalars['Int'];
};

export type CreateCreditSourceInput = {
  /** The name of the credit source to be saved into the falcon database. */
  name: Scalars['String'];
};

export type CreateCustomPricingForExtendInput = {
  displayDescription1?: InputMaybe<Scalars['SanitizeString']>;
  displayDescription2?: InputMaybe<Scalars['SanitizeString']>;
  displayName?: InputMaybe<Scalars['SanitizeString']>;
  displayRank?: InputMaybe<Scalars['Int']>;
  pricing: CreatePricingForExtendInput;
  /** Schedule the pricing will apply during */
  tariffSchedules: Array<CreateTariffScheduleForExtendInput>;
};

export type CreateDriverInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  accountVendorId?: InputMaybe<Scalars['ID']>;
  billingAddress?: InputMaybe<CreateAddressInput>;
  driverStatusId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  enrolledOn?: InputMaybe<Scalars['Date']>;
  firstName: Scalars['String'];
  invited?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  legacyPhone?: InputMaybe<Scalars['SanitizeString']>;
  mailingAddress?: InputMaybe<CreateAddressInput>;
  phone?: InputMaybe<Scalars['Phone']>;
  tokenAndroid?: InputMaybe<Scalars['String']>;
  tokenIos?: InputMaybe<Scalars['String']>;
  vendorDriverStatus?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']>;
};

export type CreateExtendHostInput = {
  /** Host ID in the Hosts table of the Extend Host */
  hostId: Scalars['ID'];
  prefix: Scalars['String'];
};

export type CreateExtendUserInput = {
  /** ID stored in firebase for the user */
  firebaseId: Scalars['String'];
  /** IDs of the hosts which are being added to the user */
  hostIds: Array<Scalars['ID']>;
  /** Unique username string */
  username: Scalars['String'];
};

export type CreateFilterInput = {
  criteria?: InputMaybe<Scalars['SanitizeString']>;
  exclude?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<Scalars['SanitizeString']>;
  values?: InputMaybe<Array<InputMaybe<CreateFilterValueInput>>>;
};

export type CreateFilterValueInput = {
  label?: InputMaybe<Scalars['SanitizeString']>;
  valid?: InputMaybe<Scalars['Boolean']>;
  value: Scalars['SanitizeString'];
};

export type CreateFleetUserInput = {
  accountId: Scalars['ID'];
  firebaseId: Scalars['String'];
  username: Scalars['String'];
};

export type CreateHostInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  address3?: InputMaybe<Scalars['String']>;
  address4?: InputMaybe<Scalars['String']>;
  administrativeArea?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<InputMaybe<CreateContactInput>>>;
  country?: InputMaybe<Scalars['String']>;
  hid?: InputMaybe<Scalars['String']>;
  hostName?: InputMaybe<Scalars['String']>;
  locality?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Array<InputMaybe<CreatePropertyInput>>>;
  status?: InputMaybe<HostStatus>;
};

export type CreateLogoUploadUrlInput = {
  /** Indicates whether the uploadUrl will be created for a SITE or a HOST */
  bucket: FileUploadBucketEnum;
  /** The name of the file that you intend to upload */
  filename: Scalars['String'];
  /** An optional path under which to store the filename inside the s3 bucket. */
  path?: InputMaybe<Scalars['String']>;
};

export type CreatePaymentCardForMobileInput = {
  cardNumber: Scalars['String'];
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  nameOnCard: Scalars['String'];
  nonce: Scalars['String'];
  optIn?: InputMaybe<Scalars['Boolean']>;
  paymentMethodType: Scalars['String'];
};

export type CreatePlanInput = {
  badge?: InputMaybe<Scalars['SanitizeString']>;
  /** Id of the balance type from falcon_constraints to use for this plan. */
  balanceTypeId?: InputMaybe<Scalars['Int']>;
  cardBrandId?: InputMaybe<Scalars['Int']>;
  /** Value modifier that is an additional component from a base cost to give a new unit cost.  */
  costFactor?: InputMaybe<Scalars['Float']>;
  ctaLabel?: InputMaybe<Scalars['SanitizeString']>;
  defaultAccountPlan?: InputMaybe<Scalars['Boolean']>;
  detailHeading?: InputMaybe<Scalars['SanitizeString']>;
  detailSections?: InputMaybe<Array<InputMaybe<DetailSectionInput>>>;
  detailSessionTimes?: InputMaybe<Array<InputMaybe<DetailSessionTimeInput>>>;
  displayPlanCard?: InputMaybe<Scalars['Boolean']>;
  displayToCustomer?: InputMaybe<Scalars['Boolean']>;
  driverRank?: InputMaybe<Scalars['Int']>;
  elements?: InputMaybe<Array<InputMaybe<ElementInput>>>;
  enrollmentClose?: InputMaybe<Scalars['Date']>;
  enrollmentOpen?: InputMaybe<Scalars['Date']>;
  financeCode?: InputMaybe<Scalars['SanitizeString']>;
  intervalLength: Scalars['Int'];
  intervalUnitId: Scalars['Int'];
  isAutoEnrolledInRewards?: InputMaybe<Scalars['Boolean']>;
  legalese?: InputMaybe<Scalars['String']>;
  planCode: Scalars['SanitizeString'];
  planDescription: Scalars['SanitizeString'];
  planDiscount?: InputMaybe<Scalars['Float']>;
  planDisplayName?: InputMaybe<Scalars['SanitizeString']>;
  planDuration?: InputMaybe<Scalars['Int']>;
  planName: Scalars['SanitizeString'];
  planStatusId?: InputMaybe<Scalars['Int']>;
  planSubheading?: InputMaybe<Scalars['SanitizeString']>;
  products?: InputMaybe<Array<InputMaybe<ProductInput>>>;
  rolloverPlanId?: InputMaybe<Scalars['ID']>;
  templateId?: InputMaybe<Scalars['Int']>;
  terminationBehaviorId?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  vinRequiredFlag?: InputMaybe<Scalars['Boolean']>;
};

export type CreatePlanTariffForExtendInput = {
  /** Charger group altIds tariff will apply to.  Only groups with a type of CHARGER_GROUP are permitted */
  chargerGroupIds: Array<Scalars['ID']>;
  /** Time of use pricing for the Tariff */
  customPricing?: InputMaybe<Array<CreateCustomPricingForExtendInput>>;
};

export type CreatePlanTariffsForExtendInput = {
  /** ID for custom plan.  Only groups with a type of CUSTOM_ACCESS_PLAN are permitted */
  chargerGroupId: Scalars['ID'];
  planTariffs: Array<CreatePlanTariffForExtendInput>;
};

export type CreatePricingForExtendInput = {
  perKwh?: InputMaybe<Scalars['TariffPrice']>;
  perMinute?: InputMaybe<Scalars['TariffPrice']>;
  perSession?: InputMaybe<Scalars['TariffPrice']>;
};

export type CreatePropertyInput = {
  address1?: InputMaybe<Scalars['SanitizeString']>;
  address2?: InputMaybe<Scalars['SanitizeString']>;
  address3?: InputMaybe<Scalars['SanitizeString']>;
  address4?: InputMaybe<Scalars['SanitizeString']>;
  administrativeArea?: InputMaybe<Scalars['SanitizeString']>;
  country?: InputMaybe<Scalars['SanitizeString']>;
  hostId?: InputMaybe<Scalars['ID']>;
  legacyId?: InputMaybe<Scalars['ID']>;
  locality?: InputMaybe<Scalars['SanitizeString']>;
  pid?: InputMaybe<Scalars['SanitizeString']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']>;
  propertyName?: InputMaybe<Scalars['SanitizeString']>;
  status?: InputMaybe<PropertyStatus>;
  vendorId?: InputMaybe<Scalars['SanitizeString']>;
};

export type CreateReservationForDriverInput = {
  accountId: Scalars['ID'];
  connectorId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type CreateReservationInput = {
  connectorId?: InputMaybe<Scalars['ID']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type CreateSessionInput = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  accountVendorId: Scalars['ID'];
  cardVendorId?: InputMaybe<Scalars['ID']>;
  connectorVendorId: Scalars['ID'];
  corrupted?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['Date']>;
  isSessionEnd?: InputMaybe<Scalars['Boolean']>;
  meterEnd?: InputMaybe<Scalars['Int']>;
  meterStart?: InputMaybe<Scalars['Int']>;
  planVendorId?: InputMaybe<Scalars['ID']>;
  startSource: Scalars['String'];
  startTime: Scalars['Date'];
  stopSource?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type CreateSiteImageInput = {
  media?: InputMaybe<GetMediaInput>;
  mediaOrder?: InputMaybe<Scalars['Int']>;
};

export type CreateSiteInput = {
  access?: InputMaybe<SiteAccess>;
  accessNotes?: InputMaybe<Scalars['SanitizeString']>;
  address1: Scalars['SanitizeString'];
  address2?: InputMaybe<Scalars['SanitizeString']>;
  address3?: InputMaybe<Scalars['SanitizeString']>;
  address4?: InputMaybe<Scalars['SanitizeString']>;
  administrativeArea: Scalars['SanitizeString'];
  country?: InputMaybe<Scalars['SanitizeString']>;
  demandLimitManagementEnabled?: InputMaybe<Scalars['Boolean']>;
  description: Scalars['SanitizeString'];
  directions?: InputMaybe<Scalars['SanitizeString']>;
  displayName?: InputMaybe<Scalars['SanitizeString']>;
  evcId?: InputMaybe<Scalars['SanitizeString']>;
  hostId?: InputMaybe<Scalars['SanitizeString']>;
  hoursOfOperation?: InputMaybe<HoursInput>;
  latitude: Scalars['Float'];
  legacyId?: InputMaybe<Scalars['ID']>;
  locality: Scalars['SanitizeString'];
  locationMarket?: InputMaybe<SiteMarket>;
  locationNotes?: InputMaybe<Scalars['SanitizeString']>;
  locationTypeId?: InputMaybe<Scalars['Int']>;
  longitude: Scalars['Float'];
  maxCurrent?: InputMaybe<Scalars['SanitizeString']>;
  meterNumber?: InputMaybe<Scalars['SanitizeString']>;
  postalCode: Scalars['SanitizeString'];
  property: CreatePropertyInput;
  propertyId?: InputMaybe<Scalars['SanitizeString']>;
  ratedVoltage?: InputMaybe<Scalars['SanitizeString']>;
  retailDemandLimit?: InputMaybe<Scalars['Float']>;
  sid: Scalars['SanitizeString'];
  siteAccessId?: InputMaybe<Scalars['Int']>;
  siteImages?: InputMaybe<Array<InputMaybe<CreateSiteImageInput>>>;
  siteName: Scalars['SanitizeString'];
  siteStatusId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<SiteType>;
  utilityCompany?: InputMaybe<SiteUtilityCompany>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type CreateTagInput = {
  description?: InputMaybe<Scalars['SanitizeString']>;
  filters?: InputMaybe<Array<InputMaybe<CreateFilterInput>>>;
  tagName?: InputMaybe<Scalars['SanitizeString']>;
};

export type CreateTariffConnectorTypeInput = {
  connectorTypeId: Scalars['ID'];
  tariffId: Scalars['ID'];
};

export type CreateTariffForExtendInput = {
  /** list of chargerGroupIds that will be associated with the Tariff */
  chargerGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Time of use pricing for the Tariff */
  customPricing?: InputMaybe<Array<InputMaybe<CreateCustomPricingForExtendInput>>>;
  defaultPricing?: InputMaybe<DefaultPricingForExtendInput>;
  /** display name for the Tariff for HMI display */
  displayName?: InputMaybe<Scalars['SanitizeString']>;
  /** name for the Tariff */
  name?: InputMaybe<Scalars['SanitizeString']>;
  reservationTerm?: InputMaybe<TariffItemReservationTermForExtendInput>;
};

export type CreateTariffScheduleForExtendInput = {
  /** Days schedule applies to unabbreviated */
  daysOfWeek: Array<Day>;
  /** End time for schedule as HH:MM */
  endTime: Scalars['String'];
  /** Start time for schedule as HH:MM */
  startTime: Scalars['String'];
};

export type CreateUploadsInput = {
  files: Array<InputMaybe<Scalars['Upload']>>;
  path?: InputMaybe<Scalars['String']>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  userRole?: InputMaybe<UserRole>;
  username: Scalars['String'];
};

export type CreateVehicleInput = {
  connectorRefArray: Array<Scalars['Int']>;
  makeRefId: Scalars['Int'];
  modelRefId: Scalars['Int'];
  modelYear: Scalars['Int'];
};

export type CreateVehicleMakeInput = {
  manufacturer: Scalars['String'];
  vendorValue?: InputMaybe<Scalars['String']>;
  vpicId?: InputMaybe<Scalars['Int']>;
  vpicValue?: InputMaybe<Scalars['String']>;
};

export type CreateVehicleModelInput = {
  makeRefId: Scalars['Int'];
  modelName: Scalars['String'];
  vendorValue?: InputMaybe<Scalars['String']>;
  vpicId?: InputMaybe<Scalars['Int']>;
  vpicValue?: InputMaybe<Scalars['String']>;
};

export type Credit = {
  __typename?: 'Credit';
  allocatedCredit?: Maybe<Scalars['Float']>;
  expiresOn?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  planName?: Maybe<Scalars['String']>;
  remainingCredit?: Maybe<Scalars['Float']>;
  usedCredit?: Maybe<Scalars['Float']>;
};

export type CreditBucket = {
  __typename?: 'CreditBucket';
  /** The ID of the falcon.credit_bucket that was created. */
  id?: Maybe<Scalars['ID']>;
};

export enum CreditExpPolicy {
  /** Carry Over */
  CarryOver = 'CARRY_OVER',
  /** Expires */
  Expires = 'EXPIRES',
}

export type CreditSource = {
  __typename?: 'CreditSource';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type CreditsWithMeta = {
  __typename?: 'CreditsWithMeta';
  edges?: Maybe<Array<Maybe<Credit>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CurrentUserResponse = {
  __typename?: 'CurrentUserResponse';
  driver?: Maybe<Driver>;
  user?: Maybe<User>;
};

export type CustomHourInput = {
  closed?: InputMaybe<Scalars['Boolean']>;
  timeslots?: InputMaybe<Array<InputMaybe<TimeSlotInput>>>;
};

export type CustomHourType = {
  __typename?: 'CustomHourType';
  closed?: Maybe<Scalars['Boolean']>;
  timeslots?: Maybe<Array<Maybe<TimeSlotType>>>;
};

export enum Day {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DeactivateCardsInput = {
  accountId?: InputMaybe<Scalars['UUID']>;
  cardIds: Array<Scalars['UUID']>;
  driverId?: InputMaybe<Scalars['UUID']>;
};

/** Input for deactivating a charger group plan */
export type DeactivateChargerGroupPlanForExtendInput = {
  /** Charger group plan altId */
  altId: Scalars['ID'];
};

export type DefaultPricingForExtendInput = {
  displayDescription1?: InputMaybe<Scalars['SanitizeString']>;
  displayDescription2?: InputMaybe<Scalars['SanitizeString']>;
  displayName?: InputMaybe<Scalars['SanitizeString']>;
  displayRank?: InputMaybe<Scalars['Int']>;
  pricing?: InputMaybe<CreatePricingForExtendInput>;
};

export type DeleteAccountVehicleInput = {
  altId: Scalars['UUID'];
  driverId?: InputMaybe<Scalars['UUID']>;
};

/** Input for deleting a charger group */
export type DeleteChargerGroupForExtendInput = {
  /** Charger group altId */
  altId: Scalars['ID'];
};

export type DeleteChargerInput = {
  altId: Scalars['ID'];
};

/** Input for deleting a charger price group */
export type DeleteChargerPriceGroupForExtendInput = {
  /** Charger group altId */
  altId: Scalars['ID'];
};

export type DeleteDriverInput = {
  altId: Scalars['ID'];
};

export type DeleteExtendHostInput = {
  /** Unique ID of the Extend Host */
  altId: Scalars['ID'];
};

export type DeleteExtendUserHostsInput = {
  /** Unique UUID of the user */
  altId: Scalars['ID'];
  /** IDs of the hosts which are being removed from the user */
  hostIds: Array<Scalars['ID']>;
};

export type DeleteHostInput = {
  altId: Scalars['ID'];
};

export type DeletePaymentMethodForMobileInput = {
  altId: Scalars['ID'];
};

export type DeletePaymentMethodInput = {
  vendorId: Scalars['ID'];
};

export type DeleteTagInput = {
  altId: Scalars['ID'];
};

export type DeleteTariffForExtendInput = {
  altId: Scalars['ID'];
};

export type DeleteVehicleIdCardInput = {
  /** Alt ID of the card */
  altId: Scalars['ID'];
};

export type DeleteVehicleInput = {
  id: Scalars['ID'];
};

export type DeleteVehicleMakeInput = {
  id: Scalars['ID'];
};

export type DeleteVehicleModelInput = {
  id: Scalars['ID'];
};

export type DetailSection = {
  __typename?: 'DetailSection';
  header?: Maybe<Scalars['SanitizeString']>;
  items?: Maybe<Array<Maybe<Scalars['SanitizeString']>>>;
};

export type DetailSectionInput = {
  header?: InputMaybe<Scalars['SanitizeString']>;
  items?: InputMaybe<Array<InputMaybe<Scalars['SanitizeString']>>>;
};

export type DetailSessionTime = {
  __typename?: 'DetailSessionTime';
  duration?: Maybe<Scalars['Int']>;
  endTime?: Maybe<Scalars['Time']>;
  interval?: Maybe<Scalars['SanitizeString']>;
  startTime?: Maybe<Scalars['Time']>;
};

export type DetailSessionTimeInput = {
  duration?: InputMaybe<Scalars['Int']>;
  endTime?: InputMaybe<Scalars['Time']>;
  interval?: InputMaybe<Scalars['SanitizeString']>;
  startTime?: InputMaybe<Scalars['Time']>;
};

export type DisassociateAccountVehicleInput = {
  altId: Scalars['UUID'];
};

export type DriivzAccount = {
  __typename?: 'DriivzAccount';
  accountStatus?: Maybe<Scalars['String']>;
  accountVendorId?: Maybe<Scalars['Int']>;
  balanceType?: Maybe<Scalars['String']>;
  billingAddress1?: Maybe<Scalars['String']>;
  billingAddress2?: Maybe<Scalars['String']>;
  billingCountry?: Maybe<Scalars['String']>;
  billingLocality?: Maybe<Scalars['String']>;
  billingPostalCode?: Maybe<Scalars['String']>;
  billingRegion?: Maybe<Scalars['String']>;
  cardExternalNumber?: Maybe<Scalars['String']>;
  cardInternalNumber?: Maybe<Scalars['String']>;
  cardVendorId?: Maybe<Scalars['Int']>;
  driverType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shippingAddress1?: Maybe<Scalars['String']>;
  shippingAddress2?: Maybe<Scalars['String']>;
  shippingCountry?: Maybe<Scalars['String']>;
  shippingLocality?: Maybe<Scalars['String']>;
  shippingPostalCode?: Maybe<Scalars['String']>;
  vendorAccountType?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type DriivzEntityInput = {
  activation_date?: InputMaybe<Scalars['String']>;
  billing_plan_id?: InputMaybe<Scalars['ID']>;
  contract_id?: InputMaybe<Scalars['ID']>;
  end_date?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  paying_entity_customer_id?: InputMaybe<Scalars['Int']>;
  stopped_on?: InputMaybe<Scalars['String']>;
};

export type DriivzTagInput = {
  vendorId: Scalars['ID'];
};

export type Driver = {
  __typename?: 'Driver';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['ID']>;
  accountVehicles?: Maybe<AccountVehiclesWithMeta>;
  altId?: Maybe<Scalars['ID']>;
  cards?: Maybe<CardsWithMeta>;
  /** contactId is used to track the contact with the emarsys sdk */
  contactId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  driverStatus?: Maybe<FalconConstraint>;
  driverType?: Maybe<DriverType>;
  email?: Maybe<Scalars['String']>;
  firebaseId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  hasPendingAutochargeEnrollment: Scalars['Boolean'];
  hasValidPaymentMethod?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  inGoodStanding?: Maybe<InGoodStanding>;
  invited?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  legacyPhone?: Maybe<Scalars['String']>;
  metrics?: Maybe<DriverMetrics>;
  notificationPreferences?: Maybe<NotificationPreferencesWithMeta>;
  /** notification preferences for operator portal */
  notificationPreferencesForOp?: Maybe<NotificationPreferencesWithMeta>;
  optInPromotions?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Phone']>;
  tokenAndroid?: Maybe<Scalars['String']>;
  tokenIos?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  vendorId?: Maybe<Scalars['ID']>;
  vendorSyncedAt?: Maybe<Scalars['Date']>;
};

export type DriverAccountVehiclesArgs = {
  input?: InputMaybe<ListAccountVehiclesInput>;
};

export type DriverCardsArgs = {
  input?: InputMaybe<ListCardsInput>;
};

export type DriverNotificationPreferencesArgs = {
  input?: InputMaybe<ListNotificationPreferencesInput>;
};

export type DriverNotificationPreferencesForOpArgs = {
  input?: InputMaybe<ListNotificationPreferencesInput>;
};

export type DriverMetrics = {
  __typename?: 'DriverMetrics';
  CO2Offset?: Maybe<Scalars['Float']>;
  DCFCSessions?: Maybe<Scalars['Int']>;
  EVMilesPowered?: Maybe<Scalars['Float']>;
  GHGAvoided?: Maybe<Scalars['Float']>;
  L2Sessions?: Maybe<Scalars['Int']>;
  favoriteCharger?: Maybe<Scalars['String']>;
  kWh?: Maybe<Scalars['Float']>;
  totalSessions?: Maybe<Scalars['Int']>;
};

export type DriverRankInput = {
  altId?: InputMaybe<Scalars['ID']>;
  driverRank?: InputMaybe<Scalars['Int']>;
};

export type DriverRankUpdateResponse = {
  __typename?: 'DriverRankUpdateResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum DriverType {
  Member = 'MEMBER',
  Owner = 'OWNER',
}

export type DriversWithMeta = {
  __typename?: 'DriversWithMeta';
  edges?: Maybe<Array<Maybe<Driver>>>;
  filter?: Maybe<ListDriversFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListDriversSearchType>;
  sort?: Maybe<ListDriversSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type ElectricVehicle = {
  __typename?: 'ElectricVehicle';
  altId?: Maybe<Scalars['ID']>;
  make?: Maybe<VehicleMake>;
  model?: Maybe<VehicleModel>;
  year?: Maybe<Scalars['String']>;
};

export type ElectricVehiclesWithMeta = {
  __typename?: 'ElectricVehiclesWithMeta';
  edges?: Maybe<Array<Maybe<ElectricVehicle>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type Element = {
  __typename?: 'Element';
  altId?: Maybe<Scalars['ID']>;
  connectorType?: Maybe<VehicleConnector>;
  day?: Maybe<Day>;
  discount?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Time']>;
  maxDuration?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['Time']>;
};

export type ElementInput = {
  altId?: InputMaybe<Scalars['ID']>;
  connectorTypeId: Scalars['ID'];
  day: Day;
  discount: Scalars['Float'];
  endTime: Scalars['Time'];
  maxDuration?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['Time'];
};

export type ElementsWithMeta = {
  __typename?: 'ElementsWithMeta';
  edges?: Maybe<Array<Maybe<Element>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export enum EmailProvider {
  Emarsys = 'EMARSYS',
  Mandrill = 'MANDRILL',
}

export type EmailRecipient = {
  emailAddress: Scalars['SanitizeString'];
  mergeVars?: InputMaybe<Scalars['JSON']>;
};

export type EnrollDriverInput = {
  action: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  oobCode?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type EnrollExistingUserInput = {
  endAccountId: Scalars['ID'];
  startAccountId: Scalars['ID'];
};

export type EnrollUserInRewardsInput = {
  /** The account vendorId */
  accountNumber: Scalars['ID'];
  /** The driver email address */
  email: Scalars['String'];
  /** The drivers first name */
  firstName?: InputMaybe<Scalars['String']>;
  /** A boolean indicating whether or not the driver opts in to rewards */
  optIn: Scalars['Boolean'];
};

export enum EvcStatus {
  ChangeAvailability = 'CHANGE_AVAILABILITY',
  ChangeConfiguration = 'CHANGE_CONFIGURATION',
  DataTransfer = 'DATA_TRANSFER',
  GetConfiguration = 'GET_CONFIGURATION',
  GetDiagnostic = 'GET_DIAGNOSTIC',
  ResetStation = 'RESET_STATION',
  StartCharge = 'START_CHARGE',
  StopCharge = 'STOP_CHARGE',
  UnlockConnector = 'UNLOCK_CONNECTOR',
  UpdateFirmware = 'UPDATE_FIRMWARE',
}

export enum EvcStatusInput {
  ChangeAvailability = 'CHANGE_AVAILABILITY',
  ChangeConfiguration = 'CHANGE_CONFIGURATION',
  DataTransfer = 'DATA_TRANSFER',
  GetConfiguration = 'GET_CONFIGURATION',
  GetDiagnostic = 'GET_DIAGNOSTIC',
  ResetStation = 'RESET_STATION',
  StartCharge = 'START_CHARGE',
  StopCharge = 'STOP_CHARGE',
  UnlockConnector = 'UNLOCK_CONNECTOR',
  UpdateFirmware = 'UPDATE_FIRMWARE',
}

export type EvgoAccessQrCode = {
  __typename?: 'EvgoAccessQRCode';
  credential?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type EvgoSitesForMobileInput = {
  /** An integer used to find sites within a radius. Units in miles. */
  distance: Scalars['Int'];
  /** If this flag is set to true and connectorTypeIds has CHADEMO site supports Tesla charging */
  hasTeslaAdapter?: InputMaybe<Scalars['Boolean']>;
  /** An float for latitude of the driver or POI. */
  latitude: Scalars['Float'];
  /** An float for longitude of the driver or POI. */
  longitude: Scalars['Float'];
  /** An integer for the min desired output in kW filter */
  outputKwMax?: InputMaybe<Scalars['Int']>;
  /** An integer for the min desired output in kW filter */
  outputKwMin?: InputMaybe<Scalars['Int']>;
  /** A boolean flag which applies a true/false filter to the RESERVABLE property of the results returned. */
  reservable?: InputMaybe<Scalars['Boolean']>;
  /** A string used to search against the name, displayName, adddress1, city, zipCode, and usaStateName. */
  search?: InputMaybe<Scalars['String']>;
  /** An array of integers used to filter vehicle connectors. */
  vehicleConnectorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type Evse = {
  __typename?: 'Evse';
  altId?: Maybe<Scalars['ID']>;
  charger?: Maybe<Charger>;
  connectors?: Maybe<ConnectorsWithMeta>;
  evseId?: Maybe<Scalars['String']>;
};

export type EvseForMobile = {
  __typename?: 'EvseForMobile';
  altId?: Maybe<Scalars['ID']>;
  connectors?: Maybe<Array<Maybe<ConnectorForMobile>>>;
};

export type EvseInput = {
  altId?: InputMaybe<Scalars['ID']>;
  connectors: Array<InputMaybe<ConnectorInput>>;
  evseId: Scalars['String'];
};

export type EvseV2 = {
  __typename?: 'EvseV2';
  altId?: Maybe<Scalars['ID']>;
  charger?: Maybe<ChargerV2>;
  connectors?: Maybe<Array<Maybe<ConnectorV2>>>;
};

export type EvsesWithMeta = {
  __typename?: 'EvsesWithMeta';
  edges?: Maybe<Array<Maybe<Evse>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ExtendCalculatedHostPlan = {
  __typename?: 'ExtendCalculatedHostPlan';
  planId: Scalars['Int'];
};

export type ExtendHmiDisplayLabels = {
  __typename?: 'ExtendHmiDisplayLabels';
  altId: Scalars['ID'];
  column1Description1?: Maybe<Scalars['SanitizeString']>;
  column1Description2?: Maybe<Scalars['SanitizeString']>;
  column1Title?: Maybe<Scalars['SanitizeString']>;
  column2Description1?: Maybe<Scalars['SanitizeString']>;
  column2Description2?: Maybe<Scalars['SanitizeString']>;
  column2Title?: Maybe<Scalars['SanitizeString']>;
  column3Description1?: Maybe<Scalars['SanitizeString']>;
  column3Description2?: Maybe<Scalars['SanitizeString']>;
  column3Title?: Maybe<Scalars['SanitizeString']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  /** Host that the labels will apply to */
  hostId: Scalars['ID'];
  row1Description1?: Maybe<Scalars['SanitizeString']>;
  row1Description2?: Maybe<Scalars['SanitizeString']>;
  row1Description3?: Maybe<Scalars['SanitizeString']>;
  row1Title?: Maybe<Scalars['SanitizeString']>;
  row2Description1?: Maybe<Scalars['SanitizeString']>;
  row2Description2?: Maybe<Scalars['SanitizeString']>;
  row2Description3?: Maybe<Scalars['SanitizeString']>;
  row2Title?: Maybe<Scalars['SanitizeString']>;
  row3Description1?: Maybe<Scalars['SanitizeString']>;
  row3Description2?: Maybe<Scalars['SanitizeString']>;
  row3Description3?: Maybe<Scalars['SanitizeString']>;
  row3Title?: Maybe<Scalars['SanitizeString']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ExtendHost = {
  __typename?: 'ExtendHost';
  /** UUID of the Extend Host */
  altId: Scalars['ID'];
  createdAt?: Maybe<Scalars['Date']>;
  /** Host associated to the Extend Host */
  host: Host;
  prefix?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  /** Driivz host folder ID - used to create charger groups */
  vendorFolderId: Scalars['Int'];
};

export type ExtendHostsWithMeta = {
  __typename?: 'ExtendHostsWithMeta';
  edges?: Maybe<Array<ExtendHost>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ExtendUser = {
  __typename?: 'ExtendUser';
  /** Unique UUID of the user */
  altId: Scalars['ID'];
  /** Timestamp when the user was created */
  createdAt?: Maybe<Scalars['Date']>;
  /** Timestamp when the user was deleted */
  deletedAt?: Maybe<Scalars['Date']>;
  /** ID stored in firebase for the user */
  firebaseId: Scalars['String'];
  /** Hosts the user is associated to */
  hosts: HostsWithMeta;
  /** Unique ID of the user */
  id?: Maybe<Scalars['ID']>;
  /** Timestamp when the user was last updated */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Unique username string */
  username: Scalars['String'];
};

export type ExtendUsersWithMeta = {
  __typename?: 'ExtendUsersWithMeta';
  edges?: Maybe<Array<Maybe<ExtendUser>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type FalconConstraint = {
  __typename?: 'FalconConstraint';
  columnName?: Maybe<Scalars['String']>;
  columnText?: Maybe<Scalars['String']>;
  columnValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  tableName?: Maybe<Scalars['String']>;
};

export type FalconConstraintsWithMeta = {
  __typename?: 'FalconConstraintsWithMeta';
  edges?: Maybe<Array<Maybe<FalconConstraint>>>;
  filter?: Maybe<ListFalconConstraintsFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<ListFalconConstraintsSortType>;
  total?: Maybe<Scalars['Int']>;
};

export enum FieldStationStatus {
  /** Connected */
  Connected = 'CONNECTED',
  /** In Stock */
  InStock = 'IN_STOCK',
  /** Ordered */
  Ordered = 'ORDERED',
  /** Provisioned */
  Provisioned = 'PROVISIONED',
  /** Ready for Installation */
  ReadyForInstallation = 'READY_FOR_INSTALLATION',
  /** Site Testing */
  SiteTesting = 'SITE_TESTING',
  /** Warehouse Testing */
  WarehouseTesting = 'WAREHOUSE_TESTING',
}

export enum FileUploadBucketEnum {
  /** Indicates that this is a MOBILE Logo for an entire Host */
  MobileHostLogoBucket = 'MOBILE_HOST_LOGO_BUCKET',
  /** Inidcates that this is a MOBILE Logo for a specific Site */
  MobileSiteLogoBucket = 'MOBILE_SITE_LOGO_BUCKET',
}

export type Filter = {
  __typename?: 'Filter';
  criteria?: Maybe<Scalars['String']>;
  exclude?: Maybe<Scalars['Boolean']>;
  operator?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<FilterValue>>>;
};

export type FilterValue = {
  __typename?: 'FilterValue';
  label?: Maybe<Scalars['String']>;
  valid?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type FirebaseAccount = {
  __typename?: 'FirebaseAccount';
  customClaims?: Maybe<FirebaseAccountCustomClaims>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<FirebaseAccountMetaData>;
  phoneNumber?: Maybe<Scalars['String']>;
  tokensValidAfterTime?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

export type FirebaseAccountCustomClaims = {
  __typename?: 'FirebaseAccountCustomClaims';
  altId?: Maybe<Scalars['String']>;
  driverType?: Maybe<Scalars['String']>;
  roles?: Maybe<UserRoles>;
  vendorSynced?: Maybe<Scalars['Boolean']>;
};

export type FirebaseAccountMetaData = {
  __typename?: 'FirebaseAccountMetaData';
  creationTime?: Maybe<Scalars['String']>;
  lastSignInTime?: Maybe<Scalars['String']>;
};

export type FirebaseUserForExtend = {
  __typename?: 'FirebaseUserForExtend';
  firebaseId: Scalars['String'];
  username: Scalars['String'];
};

export type FleetSiteCharger = {
  __typename?: 'FleetSiteCharger';
  accountAltId: Scalars['ID'];
  charger?: Maybe<Charger>;
  chargerAltId: Scalars['ID'];
};

export type FleetVehicle = {
  __typename?: 'FleetVehicle';
  activityStatus?: Maybe<ActivityStatus>;
  activityStatusLabel?: Maybe<ActivityStatusLabel>;
  altId: Scalars['ID'];
  card: Card;
  currentSession?: Maybe<Session>;
  sessions?: Maybe<SessionsWithMeta>;
};

export type FleetVehicleSessionsArgs = {
  input?: InputMaybe<ListSessionsInput>;
};

export type GeographicalRegion = {
  __typename?: 'GeographicalRegion';
  abbreviation?: Maybe<Scalars['String']>;
  altId?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type GeographicalRegionsWithMeta = {
  __typename?: 'GeographicalRegionsWithMeta';
  edges?: Maybe<Array<Maybe<GeographicalRegion>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type GetAccountInput = {
  altId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type GetAccountVehicleByVinInput = {
  vin: Scalars['SanitizeString'];
};

export type GetAccountVehicleInput = {
  altId: Scalars['UUID'];
  paranoid?: InputMaybe<Scalars['Boolean']>;
};

export type GetAccountVehicleVinStatusInput = {
  vin: Scalars['SanitizeString'];
};

export type GetActiveSessionInput = {
  connectorId?: InputMaybe<Scalars['ID']>;
};

export type GetAdHocContractInput = {
  accountId: Scalars['ID'];
};

export type GetAggregatedPowerMeterValuesForFleetInput = {
  aggregateIntervalMinutes: Scalars['Int'];
  from: Scalars['Date'];
  siteAltId: Scalars['ID'];
  until: Scalars['Date'];
};

export type GetCardInput = {
  altId?: InputMaybe<Scalars['UUID']>;
  externalNumber?: InputMaybe<Scalars['String']>;
};

export type GetChargerAggregatedPowerMeterValuesForFleetInput = {
  aggregateIntervalMinutes: Scalars['Int'];
  chargerAltId: Scalars['ID'];
  from: Scalars['Date'];
  siteAltId: Scalars['ID'];
  until: Scalars['Date'];
};

export type GetChargerGroupForExtendInput = {
  altId: Scalars['ID'];
  showDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type GetChargerInput = {
  altId: Scalars['ID'];
};

export type GetChargerModelInput = {
  altId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['SanitizeString']>;
};

export type GetChargerPricingForMobileInput = {
  /** Driivz charger Id */
  chargerId: Scalars['ID'];
};

export type GetConnectorInput = {
  altId: Scalars['ID'];
};

export type GetContractInput = {
  altId: Scalars['ID'];
};

export type GetCouponInput = {
  adType: Scalars['String'];
  stationId?: InputMaybe<Scalars['Int']>;
};

export type GetDriverInput = {
  altId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  paranoid?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type GetEvgoAccessQrCodeInput = {
  accountVendorId: Scalars['Int'];
  siteVendorId: Scalars['Int'];
};

export type GetExtendUserInput = {
  /** Unique UUID of the user */
  altId?: InputMaybe<Scalars['ID']>;
  /** Firebase ID of the user */
  firebaseId?: InputMaybe<Scalars['String']>;
};

export type GetFirebaseUserForExtendInput = {
  email: Scalars['String'];
};

export type GetHostInput = {
  altId?: InputMaybe<Scalars['ID']>;
  hid?: InputMaybe<Scalars['SanitizeString']>;
  vendorId?: InputMaybe<Scalars['SanitizeString']>;
};

/** Input type for GetInvoiceForMobile */
export type GetInvoiceInput = {
  /** A number indicating the Driivz ID of the invoice */
  invoiceId: Scalars['Int'];
};

export type GetMediaInput = {
  altId: Scalars['UUID'];
};

export type GetPlanForMobileInput = {
  /** altId used to retrieve plans */
  altId: Scalars['ID'];
  /** user's current latitude value in float */
  lat?: InputMaybe<Scalars['Float']>;
  /** user's current longitude value in float */
  long?: InputMaybe<Scalars['Float']>;
};

export type GetPlanInput = {
  altId?: InputMaybe<Scalars['ID']>;
  planCode?: InputMaybe<Scalars['SanitizeString']>;
};

export type GetPortInput = {
  altId: Scalars['ID'];
};

export type GetPropertyInput = {
  pid: Scalars['SanitizeString'];
};

export type GetRewardsUserInput = {
  trackLogin?: InputMaybe<Scalars['Boolean']>;
};

export type GetSalesforceHostInput = {
  hid?: InputMaybe<Scalars['SanitizeString']>;
};

export type GetSalesforcePropertyInput = {
  hid: Scalars['SanitizeString'];
  pid: Scalars['SanitizeString'];
};

export type GetSessionForFleetInput = {
  altId: Scalars['ID'];
};

export type GetSessionInput = {
  sessionId: Scalars['ID'];
};

export type GetSessionSummaryByPlanInput = {
  startDate: Scalars['Date'];
};

export type GetSessionValidationForMobileInput = {
  /** The connectorId of the charger for which a session creation attempt was made. */
  connectorId?: InputMaybe<Scalars['ID']>;
};

export type GetSessionsConsumptionForExtendInput = {
  aggregateBy: SessionAggregation;
  /** Filter the timeseries data by charger groups, if not send will return for all charger groups */
  chargerGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter the timeseries data by chargers, if not send will return for all chargers */
  chargerIds?: InputMaybe<Array<Scalars['ID']>>;
  datePreset?: InputMaybe<SessionDatePreset>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** Filter the timeseries data by plans, if not send will return for all plans */
  planIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter the timeseries data by chargers in these sites, if not send will return for all sites */
  siteIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** Filter the timeseries data by transaction type, if not send will return only for the default eXtend+ types */
  transactionTypeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetSessionsConsumptionForExtendResponse = {
  __typename?: 'GetSessionsConsumptionForExtendResponse';
  timeSeries?: Maybe<Array<Maybe<SessionsConsumptionForExtendData>>>;
};

export type GetSiteForExtendInput = {
  /** altId of the site */
  altId: Scalars['ID'];
};

export type GetSiteImageInput = {
  altId: Scalars['ID'];
};

export type GetSiteInput = {
  altId: Scalars['ID'];
};

export type GetStatementCurrentInput = {
  /** A string parameter indicating the currency type to be shown on the returned statement */
  currency: Scalars['String'];
};

export type GetStatementInput = {
  /** An Id parameter required to find statements in Driivz */
  invoiceId: Scalars['ID'];
};

export type GetTagInput = {
  altId: Scalars['ID'];
  description?: InputMaybe<Scalars['SanitizeString']>;
  filters?: InputMaybe<Array<InputMaybe<UpdateFilterInput>>>;
  tagName?: InputMaybe<Scalars['SanitizeString']>;
};

export type GetTariffForExtendInput = {
  altId: Scalars['ID'];
};

export type GetTariffLogsInput = {
  chargerIdentityKeys?: InputMaybe<Array<Scalars['SanitizeString']>>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** Default and maximum limit is 50 */
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<TariffLogsSort>>>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export type GetTransactionInput = {
  transactionId: Scalars['ID'];
};

export type GetVehicleByVinInput = {
  vin: Scalars['SanitizeString'];
};

export type GetWarehouseInput = {
  altId?: InputMaybe<Scalars['ID']>;
  warehouseName?: InputMaybe<Scalars['SanitizeString']>;
};

export type Host = {
  __typename?: 'Host';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  altId?: Maybe<Scalars['ID']>;
  contacts?: Maybe<ContactsWithMeta>;
  country?: Maybe<Scalars['String']>;
  /** Flag to indicate if host is using Custom Plans */
  customPlansEnabled?: Maybe<Scalars['Boolean']>;
  defaultBillingTariffCode?: Maybe<Scalars['String']>;
  defaultHostServicePlanCode?: Maybe<Scalars['String']>;
  extendUsers?: Maybe<ExtendUsersWithMeta>;
  hid?: Maybe<Scalars['String']>;
  hostName?: Maybe<Scalars['String']>;
  hostStatus?: Maybe<FalconConstraint>;
  id?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['ID']>;
  locality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  properties?: Maybe<PropertiesWithMeta>;
  /** @deprecated Use hostStatus */
  status?: Maybe<HostStatus>;
  timezone?: Maybe<Timezone>;
  vendorId?: Maybe<Scalars['String']>;
  vendorSyncedAt?: Maybe<Scalars['Date']>;
};

export type HostContactsArgs = {
  input?: InputMaybe<ListContactsInput>;
};

export type HostExtendUsersArgs = {
  input?: InputMaybe<ListExtendUsersInput>;
};

export type HostPropertiesArgs = {
  input?: InputMaybe<ListPropertiesInput>;
};

export type HostFilterInput = {
  hid?: InputMaybe<TableStringFilterInput>;
  hostName?: InputMaybe<TableStringFilterInput>;
};

export type HostFilterType = {
  __typename?: 'HostFilterType';
  hid?: Maybe<TableStringFilterType>;
  hostName?: Maybe<TableStringFilterType>;
};

export enum HostStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Closed */
  Closed = 'CLOSED',
  /** Closed Customer Request */
  ClosedCustomerRequest = 'CLOSED_CUSTOMER_REQUEST',
  /** Closed Delinquency */
  ClosedDelinquency = 'CLOSED_DELINQUENCY',
  /** Deleted */
  Deleted = 'DELETED',
  /** Invited */
  Invited = 'INVITED',
  /** In 3DS Validation */
  In_3DsValidation = 'IN_3DS_VALIDATION',
  /** Pre-registered */
  PreRegistered = 'PRE_REGISTERED',
  /** Registered */
  Registered = 'REGISTERED',
  /** Suspended */
  Suspended = 'SUSPENDED',
  /** Suspended Delinquency */
  SuspendedDelinquency = 'SUSPENDED_DELINQUENCY',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type HostsWithMeta = {
  __typename?: 'HostsWithMeta';
  edges?: Maybe<Array<Maybe<Host>>>;
  filter?: Maybe<ListHostFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListHostSearchType>;
  sort?: Maybe<ListHostSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type HoursInput = {
  custom?: InputMaybe<Scalars['Boolean']>;
  friday?: InputMaybe<CustomHourInput>;
  from?: InputMaybe<Scalars['SanitizeString']>;
  monday?: InputMaybe<CustomHourInput>;
  saturday?: InputMaybe<CustomHourInput>;
  sunday?: InputMaybe<CustomHourInput>;
  thursday?: InputMaybe<CustomHourInput>;
  to?: InputMaybe<Scalars['SanitizeString']>;
  tuesday?: InputMaybe<CustomHourInput>;
  wednesday?: InputMaybe<CustomHourInput>;
};

export type HoursType = {
  __typename?: 'HoursType';
  custom?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<CustomHourType>;
  from?: Maybe<Scalars['String']>;
  monday?: Maybe<CustomHourType>;
  saturday?: Maybe<CustomHourType>;
  sunday?: Maybe<CustomHourType>;
  thursday?: Maybe<CustomHourType>;
  to?: Maybe<Scalars['String']>;
  tuesday?: Maybe<CustomHourType>;
  wednesday?: Maybe<CustomHourType>;
};

export type ImportAccountOwnerInput = {
  accountOwners: Array<CreateAccountOwnerFromDriivzInput>;
};

export type ImportAccountVehicleInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  driverId?: InputMaybe<Scalars['ID']>;
  vehicleId?: InputMaybe<Scalars['ID']>;
};

export type ImportAccountVehiclesInput = {
  accountVehicles?: InputMaybe<Array<InputMaybe<ImportAccountVehicleInput>>>;
};

export type ImportCardsInput = {
  cards?: InputMaybe<Array<InputMaybe<UpsertCardInput>>>;
};

export type ImportContractItemInput = {
  accountId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['Date']>;
  planId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
  vendorId: Scalars['ID'];
  vendorStatus?: InputMaybe<Scalars['String']>;
};

export type ImportContractsInput = {
  contracts: Array<ImportContractItemInput>;
};

export type ImportHostInput = {
  address1?: InputMaybe<Scalars['SanitizeString']>;
  address2?: InputMaybe<Scalars['SanitizeString']>;
  address3?: InputMaybe<Scalars['SanitizeString']>;
  address4?: InputMaybe<Scalars['SanitizeString']>;
  administrativeArea?: InputMaybe<Scalars['SanitizeString']>;
  country?: InputMaybe<Scalars['SanitizeString']>;
  hid: Scalars['SanitizeString'];
  hostName: Scalars['SanitizeString'];
  legacyId?: InputMaybe<Scalars['ID']>;
  locality?: InputMaybe<Scalars['SanitizeString']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']>;
  status?: InputMaybe<HostStatus>;
};

export type ImportPaymentMethodsInput = {
  paymentMethods: Array<UpsertPaymentMethodInput>;
};

export type ImportPropertyInput = {
  address1?: InputMaybe<Scalars['SanitizeString']>;
  address2?: InputMaybe<Scalars['SanitizeString']>;
  address3?: InputMaybe<Scalars['SanitizeString']>;
  address4?: InputMaybe<Scalars['SanitizeString']>;
  administrativeArea?: InputMaybe<Scalars['SanitizeString']>;
  country?: InputMaybe<Scalars['SanitizeString']>;
  hostId: Scalars['ID'];
  legacyId?: InputMaybe<Scalars['ID']>;
  locality?: InputMaybe<Scalars['SanitizeString']>;
  pid: Scalars['SanitizeString'];
  postalCode?: InputMaybe<Scalars['SanitizeString']>;
  propertyName: Scalars['SanitizeString'];
  status?: InputMaybe<PropertyStatus>;
  vendorId?: InputMaybe<Scalars['SanitizeString']>;
};

export type ImportVehiclesInput = {
  vehicleModels?: InputMaybe<Array<InputMaybe<VehicleInput>>>;
};

export type InGoodStanding = {
  __typename?: 'InGoodStanding';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

export enum InputAcVoltage {
  /** 120V Single Phase */
  SinglePhase_120V = 'SINGLE_PHASE_120V',
  /** 208/240 Single Phase */
  SinglePhase_208_240 = 'SINGLE_PHASE_208_240',
  /** 208V/120V Three Phase 3 Wire */
  ThreePhase_3Wire_208V_120V = 'THREE_PHASE_3_WIRE_208V_120V',
  /** 480V/277V Three Phase 3 Wire */
  ThreePhase_3Wire_480V_277V = 'THREE_PHASE_3_WIRE_480V_277V',
  /** 480V/277V Three Phase 4 Wire */
  ThreePhase_4Wire_480V_277V = 'THREE_PHASE_4_WIRE_480V_277V',
}

export enum InterfaceVersion {
  /** 2 Button */
  Button_2 = 'BUTTON_2',
  /** 3 Button */
  Button_3 = 'BUTTON_3',
  /** No buttons */
  NoButton = 'NO_BUTTON',
}

export enum IntervalUnit {
  /** Day */
  Day = 'DAY',
  /** Month */
  Month = 'MONTH',
  /** Week */
  Week = 'WEEK',
  /** Year */
  Year = 'YEAR',
}

export type InviteDriverInput = {
  altId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['Email']>;
};

/** An invoice of billed transactions */
export type Invoice = {
  __typename?: 'Invoice';
  /** A string identifying where the authorization for this invoice was initiated */
  authorization?: Maybe<Scalars['String']>;
  /** The amount of tax billed on the invoice */
  billingTax?: Maybe<Scalars['Float']>;
  /** Charger details for the charger used in the invoice transactions */
  charger?: Maybe<Charger>;
  /** A confirmation number for the paid invoice */
  confirmationNumber?: Maybe<Scalars['String']>;
  /** The date on which the current invoice was created */
  createdOn?: Maybe<Scalars['Date']>;
  /** The amount of credits applied to the invoice */
  creditApplied?: Maybe<Scalars['Float']>;
  /** The credit card number which was used for payment */
  creditCardNumber?: Maybe<Scalars['String']>;
  /** The type of the credit card used for payment */
  creditCardType?: Maybe<Scalars['String']>;
  /** The name of the driver associated with the invoice */
  driverName?: Maybe<Scalars['String']>;
  /** Session end time */
  endTime?: Maybe<Scalars['Date']>;
  /** The amount of energy delivered in kWh */
  energyDeliveredKw?: Maybe<Scalars['Float']>;
  /** A string defining which invoice category applies to the current invoice */
  invoiceCategory?: Maybe<Scalars['String']>;
  /** A number indicating the Driivz ID of the invoice */
  invoiceId?: Maybe<Scalars['Int']>;
  /** A number indicating the invoiceNumber of the current invoice */
  invoiceNumber?: Maybe<Scalars['Int']>;
  /** Transaction line item details */
  lineItems?: Maybe<Array<Maybe<InvoiceLineItem>>>;
  /** Network operator details describing the hostName of the applicable network operator */
  networkOperator?: Maybe<NetworkOperator>;
  /** The date on which the current invoice was paid */
  paidOn?: Maybe<Scalars['Date']>;
  /** Max power level */
  powerLevel?: Maybe<Scalars['Float']>;
  /** The duration of the session billed on the current invoice Format: (HH:MM:SS.MS) */
  sessionDuration?: Maybe<Scalars['Time']>;
  /** An id for identifying the charge session related to this invoice */
  sessionId?: Maybe<Scalars['String']>;
  /** Site details for the location where the invoice transactions took place */
  site?: Maybe<Site>;
  /** Session start time */
  startTime?: Maybe<Scalars['Date']>;
  /** Session stop reason */
  stopReason?: Maybe<Scalars['String']>;
  /** The sum total of the lineItem.amounts */
  subtotal?: Maybe<Scalars['Float']>;
  /** The total amount paid by the customer */
  totalPaid?: Maybe<Scalars['Float']>;
};

/** Transaction line item details */
export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  /** The total cost of this transaction */
  amount?: Maybe<Scalars['Float']>;
  /** Comments on the transaction */
  comments?: Maybe<Scalars['String']>;
  /** The type of transaction */
  productType?: Maybe<Scalars['String']>;
  /** How many units were billed under this transaction */
  quantity?: Maybe<Scalars['Float']>;
  /** The cost of each unit under this transaction */
  unitPrice?: Maybe<Scalars['String']>;
};

/** return type for ListInvoicesForMobile */
export type InvoicesWithMeta = {
  __typename?: 'InvoicesWithMeta';
  edges?: Maybe<Array<Maybe<Invoice>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type LambdaEvent = {
  __typename?: 'LambdaEvent';
  success?: Maybe<Scalars['Boolean']>;
};

export type LambdaEventInput = {
  after?: InputMaybe<DriivzEntityInput>;
  before?: InputMaybe<DriivzEntityInput>;
  operation?: InputMaybe<Scalars['String']>;
};

export type ListAccountVehiclesFilterInput = {
  accountId?: InputMaybe<TableIntFilterInput>;
};

export type ListAccountVehiclesInput = {
  filter?: InputMaybe<ListAccountVehiclesFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  paranoid?: InputMaybe<Scalars['Boolean']>;
};

export type ListAccountsFilterInput = {
  accountStatus?: InputMaybe<TableStringFilterInput>;
  evcId?: InputMaybe<TableStringFilterInput>;
};

export type ListAccountsInput = {
  filter?: InputMaybe<ListAccountsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ListAccountsSortInput>;
};

export type ListAccountsSortInput = {
  accountStatus?: InputMaybe<TableSortInput>;
  firstName?: InputMaybe<TableSortInput>;
};

export type ListAccountsSortType = {
  __typename?: 'ListAccountsSortType';
  accountStatus?: Maybe<TableSortType>;
  evcId?: Maybe<TableSortType>;
  firstName?: Maybe<TableSortType>;
};

export type ListCardOrdersFilterInput = {
  account_altId?: InputMaybe<TableStringFilterInput>;
  brand?: InputMaybe<TableStringFilterInput>;
  driver_altId?: InputMaybe<TableStringFilterInput>;
  orderStatus?: InputMaybe<TableStringFilterInput>;
};

export type ListCardOrdersFilterType = {
  __typename?: 'ListCardOrdersFilterType';
  account_altId?: Maybe<TableStringFilterType>;
  brand?: Maybe<TableStringFilterType>;
  driver_altId?: Maybe<TableStringFilterType>;
  orderStatus?: Maybe<TableStringFilterType>;
};

export type ListCardOrdersForMobileInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListCardOrdersInput = {
  filter?: InputMaybe<ListCardOrdersFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListCardOrdersSearchInput>;
  sort?: InputMaybe<ListCardOrdersSortInput>;
};

export type ListCardOrdersSearchInput = {
  accountId?: InputMaybe<TableIntFilterInput>;
  account_billingAddress_address1?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_administrativeArea?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_locality?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_postalCode?: InputMaybe<TableStringFilterInput>;
  driver_firstName?: InputMaybe<TableStringFilterInput>;
  driver_lastName?: InputMaybe<TableStringFilterInput>;
};

export type ListCardOrdersSearchType = {
  __typename?: 'ListCardOrdersSearchType';
  accountId?: Maybe<TableIntFilterType>;
  account_billingAddress_address1?: Maybe<TableStringFilterType>;
  account_billingAddress_administrativeArea?: Maybe<TableStringFilterType>;
  account_billingAddress_locality?: Maybe<TableStringFilterType>;
  account_billingAddress_postalCode?: Maybe<TableStringFilterType>;
  driver_firstName?: Maybe<TableStringFilterType>;
  driver_lastName?: Maybe<TableStringFilterType>;
};

export type ListCardOrdersSortInput = {
  driver_firstName?: InputMaybe<TableSortInput>;
  driver_lastName?: InputMaybe<TableSortInput>;
};

export type ListCardOrdersSortType = {
  __typename?: 'ListCardOrdersSortType';
  driver_firstName?: Maybe<TableSortType>;
  driver_lastName?: Maybe<TableSortType>;
};

export type ListCardsFilterInput = {
  account_altId?: InputMaybe<TableStringFilterInput>;
  brand?: InputMaybe<TableStringFilterInput>;
  cardStatus?: InputMaybe<TableStringFilterInput>;
  cardTypeId?: InputMaybe<TableIntFilterInput>;
  driver_altId?: InputMaybe<TableStringFilterInput>;
};

export type ListCardsFilterType = {
  __typename?: 'ListCardsFilterType';
  account_altId?: Maybe<TableStringFilterType>;
  brand?: Maybe<TableStringFilterType>;
  cardStatus?: Maybe<TableStringFilterType>;
  cardTypeId?: Maybe<TableStringFilterType>;
  driver_altId?: Maybe<TableStringFilterType>;
};

export type ListCardsInput = {
  filter?: InputMaybe<ListCardsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListCardsSearchInput>;
  sort?: InputMaybe<ListCardsSortInput>;
};

export type ListCardsSearchInput = {
  accountId?: InputMaybe<TableIntFilterInput>;
  driver_firstName?: InputMaybe<TableStringFilterInput>;
  driver_lastName?: InputMaybe<TableStringFilterInput>;
  evcId?: InputMaybe<TableStringFilterInput>;
  externalNumber?: InputMaybe<TableStringFilterInput>;
  internalNumber?: InputMaybe<TableStringFilterInput>;
};

export type ListCardsSearchType = {
  __typename?: 'ListCardsSearchType';
  accountId?: Maybe<TableIntFilterType>;
  driver_firstName?: Maybe<TableStringFilterType>;
  driver_lastName?: Maybe<TableStringFilterType>;
  evcId?: Maybe<TableStringFilterType>;
  externalNumber?: Maybe<TableStringFilterType>;
  internalNumber?: Maybe<TableStringFilterType>;
};

export type ListCardsSortInput = {
  driver_email?: InputMaybe<TableSortInput>;
  driver_firstName?: InputMaybe<TableSortInput>;
  driver_lastName?: InputMaybe<TableSortInput>;
  driver_phone?: InputMaybe<TableSortInput>;
  externalNumber?: InputMaybe<TableSortInput>;
  internalNumber?: InputMaybe<TableSortInput>;
  nickName?: InputMaybe<TableSortInput>;
};

export type ListCardsSortType = {
  __typename?: 'ListCardsSortType';
  driver_email?: Maybe<TableSortType>;
  driver_firstName?: Maybe<TableSortType>;
  driver_lastName?: Maybe<TableSortType>;
  driver_phone?: Maybe<TableSortType>;
  externalNumber?: Maybe<TableSortType>;
  internalNumber?: Maybe<TableSortType>;
  nickName?: Maybe<TableSortType>;
};

export type ListChargerGroupsFilterForExtendInput = {
  /** Name of the charger group to filter on */
  chargerGroupName?: InputMaybe<TableStringFilterInput>;
  /** Group type to filter on.  401 - CHARGER_PRICE, 402 - CUSTOM_ACCESS_PLAN */
  chargerGroupTypeId?: InputMaybe<TableIntFilterInput>;
  /** Description of the charger group to filter on */
  description?: InputMaybe<TableStringFilterInput>;
};

export type ListChargerGroupsForExtendInput = {
  filter?: InputMaybe<ListChargerGroupsFilterForExtendInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListChargerGroupsFilterForExtendInput>;
  showDeleted?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<Array<InputMaybe<ChargerGroupsV2Sort>>>;
};

export type ListChargerModelsFilterInput = {
  manufacturer?: InputMaybe<TableStringFilterInput>;
};

export type ListChargerModelsFilterType = {
  __typename?: 'ListChargerModelsFilterType';
  manufacturer?: Maybe<TableStringFilterType>;
};

export type ListChargerModelsInput = {
  filter?: InputMaybe<ListChargerModelsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListChargerModelsSearchInput>;
  sort?: InputMaybe<ListChargerModelsSortInput>;
};

export type ListChargerModelsSearchInput = {
  modelName?: InputMaybe<TableStringFilterInput>;
};

export type ListChargerModelsSearchType = {
  __typename?: 'ListChargerModelsSearchType';
  modelName?: Maybe<TableStringFilterType>;
};

export type ListChargerModelsSortInput = {
  altId?: InputMaybe<TableSortInput>;
  manufacturer?: InputMaybe<TableSortInput>;
  modelName?: InputMaybe<TableSortInput>;
  unitType?: InputMaybe<TableSortInput>;
};

export type ListChargerModelsSortType = {
  __typename?: 'ListChargerModelsSortType';
  manufacturer?: Maybe<TableSortType>;
  modelName?: Maybe<TableSortType>;
  unitType?: Maybe<TableSortType>;
};

export type ListChargerUtilization = {
  __typename?: 'ListChargerUtilization';
  edges?: Maybe<Array<Maybe<ChargerUtilizationReport>>>;
  endDate: Scalars['Date'];
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  timeZone: Scalars['String'];
  total?: Maybe<Scalars['Int']>;
};

export type ListChargerUtilizationInput = {
  endDate: Scalars['Date'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  siteId: Scalars['UUID'];
  startDate: Scalars['Date'];
  timeZone: Scalars['String'];
};

/** Filter the consumption data in that datePreset, for all chargers in these site altIds, return all chargers if siteIds not sent */
export type ListChargersConsumptionForExtendInput = {
  chargerIds?: InputMaybe<Array<Scalars['ID']>>;
  datePreset: SessionDatePreset;
  siteIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ListChargersFilterInput = {
  and?: InputMaybe<ChargerFilterInput>;
  chargerModel_altId?: InputMaybe<TableStringFilterInput>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  evses_connectors_connectorStatusId?: InputMaybe<TableIntFilterInput>;
  evses_connectors_connectorTypeId?: InputMaybe<TableIntFilterInput>;
  fieldStationStatus?: InputMaybe<TableStringFilterInput>;
  isReservable?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<ChargerFilterInput>;
  port?: InputMaybe<ListPortsRootFilterInput>;
  radius?: InputMaybe<ListChargersRadiusInput>;
  site_postalCode?: InputMaybe<TableStringFilterInput>;
  site_siteName?: InputMaybe<TableStringFilterInput>;
  tagId?: InputMaybe<TableStringFilterInput>;
  tags?: InputMaybe<TableFilterInput>;
};

export type ListChargersFilterType = {
  __typename?: 'ListChargersFilterType';
  chargerModel_altId?: Maybe<TableStringFilterType>;
  fieldStationStatus?: Maybe<TableStringFilterType>;
  site_postalCode?: Maybe<TableStringFilterType>;
  site_siteName?: Maybe<TableStringFilterType>;
  tags?: Maybe<TableFilterType>;
};

export type ListChargersForFleetInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListChargersForFleetSearchInput>;
  siteId: Scalars['ID'];
  sort?: InputMaybe<ListChargersForFleetSortInput>;
};

export type ListChargersForFleetSearchInput = {
  chargers_chargerName?: InputMaybe<TableStringFilterInput>;
  evses_connectors_connectorTypeConstraint_columnValue?: InputMaybe<TableStringFilterInput>;
};

export type ListChargersForFleetSearchType = {
  __typename?: 'ListChargersForFleetSearchType';
  chargers_chargerName?: Maybe<TableStringFilterType>;
  evses_connectors_connectorTypeConstraint_columnValue?: Maybe<TableStringFilterType>;
};

export type ListChargersForFleetSortInput = {
  chargerName?: InputMaybe<TableSortInput>;
};

export type ListChargersForFleetSortType = {
  __typename?: 'ListChargersForFleetSortType';
  chargerName?: Maybe<TableSortType>;
};

export type ListChargersForMobileInput = {
  connectorTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  distance: Scalars['Int'];
  /** If this flag is set to true and connectorTypeIds has CHADEMO station supports Tesla charging */
  hasTeslaAdapter?: InputMaybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  limit?: InputMaybe<Scalars['Int']>;
  longitude: Scalars['Float'];
  search: Scalars['String'];
  vehicleConnectorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ListChargersInput = {
  filter?: InputMaybe<ListChargersFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListChargersSearchInput>;
  sort?: InputMaybe<ListChargersSortInput>;
  total?: InputMaybe<Scalars['Int']>;
};

export type ListChargersRadiusInput = {
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  radius?: InputMaybe<Scalars['Int']>;
};

export type ListChargersRootFilterInput = {
  and?: InputMaybe<ChargerFilterInput>;
  or?: InputMaybe<ChargerFilterInput>;
  port?: InputMaybe<ListPortsRootFilterInput>;
};

export type ListChargersRootFilterType = {
  __typename?: 'ListChargersRootFilterType';
  and?: Maybe<ChargerFilterType>;
  or?: Maybe<ChargerFilterType>;
  port?: Maybe<ListPortsRootFilterType>;
};

export type ListChargersSearchInput = {
  chargerModel_modelName?: InputMaybe<TableStringFilterInput>;
  chargerName?: InputMaybe<TableStringFilterInput>;
  cid?: InputMaybe<TableStringFilterInput>;
  evcId?: InputMaybe<TableStringFilterInput>;
  site_address1?: InputMaybe<TableStringFilterInput>;
  site_administrativeArea?: InputMaybe<TableStringFilterInput>;
  site_locality?: InputMaybe<TableStringFilterInput>;
  site_postalCode?: InputMaybe<TableStringFilterInput>;
  site_property_propertyName?: InputMaybe<TableStringFilterInput>;
  site_siteName?: InputMaybe<TableStringFilterInput>;
};

export type ListChargersSearchType = {
  __typename?: 'ListChargersSearchType';
  chargerModel_modelName?: Maybe<TableStringFilterType>;
  chargerName?: Maybe<TableStringFilterType>;
  cid?: Maybe<TableStringFilterType>;
  site_address1?: Maybe<TableStringFilterType>;
  site_administrativeArea?: Maybe<TableStringFilterType>;
  site_locality?: Maybe<TableStringFilterType>;
  site_postalCode?: Maybe<TableStringFilterType>;
  site_property_propertyName?: Maybe<TableStringFilterType>;
  site_siteName?: Maybe<TableStringFilterType>;
};

export type ListChargersSortForExtendInput = {
  chargerModel_modelName?: InputMaybe<TableSortInput>;
  chargerName?: InputMaybe<TableSortInput>;
  charging?: InputMaybe<TableSortInput>;
  site_address1?: InputMaybe<TableSortInput>;
  site_siteName?: InputMaybe<TableSortInput>;
};

export type ListChargersSortInput = {
  chargerModel_modelName?: InputMaybe<TableSortInput>;
  chargerName?: InputMaybe<TableSortInput>;
  cid?: InputMaybe<TableSortInput>;
  fieldStationStatus?: InputMaybe<TableSortInput>;
  hostName?: InputMaybe<TableSortInput>;
  propertyName?: InputMaybe<TableSortInput>;
};

export type ListChargersSortType = {
  __typename?: 'ListChargersSortType';
  chargerModel_modelName?: Maybe<TableSortType>;
  chargerName?: Maybe<TableSortType>;
  cid?: Maybe<TableSortType>;
  fieldStationStatus?: Maybe<TableSortType>;
  hostName?: Maybe<TableSortType>;
  propertyName?: Maybe<TableSortType>;
};

export type ListChargersV2FilterInput = {
  chargerGroupName?: InputMaybe<TableStringFilterInput>;
  chargerName?: InputMaybe<TableStringFilterInput>;
  connectorIsReservable?: InputMaybe<TableBooleanFilterInput>;
  connectorStatusId?: InputMaybe<TableIntFilterInput>;
  connectorStatusMax?: InputMaybe<TableStringFilterInput>;
  connectorStatusMin?: InputMaybe<TableStringFilterInput>;
  connectorStatusValue?: InputMaybe<TableStringFilterInput>;
  modelName?: InputMaybe<TableStringFilterInput>;
  siteAddress1?: InputMaybe<TableStringFilterInput>;
  siteAdministrativeArea?: InputMaybe<TableStringFilterInput>;
  siteLocality?: InputMaybe<TableStringFilterInput>;
  siteName?: InputMaybe<TableStringFilterInput>;
  sitePostalCode?: InputMaybe<TableStringFilterInput>;
  vendorId?: InputMaybe<TableStringFilterInput>;
};

export type ListChargersV2Input = {
  filter?: InputMaybe<ListChargersV2FilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListChargersV2FilterInput>;
  sort?: InputMaybe<ChargersV2Sort>;
  total?: InputMaybe<Scalars['Int']>;
};

export type ListConnectorsFilterInput = {
  and?: InputMaybe<ConnectorFilterInput>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<ConnectorFilterInput>;
};

export type ListConnectorsInput = {
  filter?: InputMaybe<ListConnectorsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ListConnectorsSortInput>;
  total?: InputMaybe<Scalars['Int']>;
};

export type ListConnectorsRootFilterInput = {
  and?: InputMaybe<ConnectorFilterInput>;
  or?: InputMaybe<ConnectorFilterInput>;
};

export type ListConnectorsRootFilterType = {
  __typename?: 'ListConnectorsRootFilterType';
  and?: Maybe<ConnectorFilterType>;
  or?: Maybe<ConnectorFilterType>;
};

export type ListConnectorsSortInput = {
  altId?: InputMaybe<TableSortInput>;
};

export type ListContactsFilterInput = {
  contactName?: InputMaybe<TableStringFilterInput>;
};

export type ListContactsInput = {
  filter?: InputMaybe<ListContactsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ListContactsSortInput>;
};

export type ListContactsSortInput = {
  contactName?: InputMaybe<TableSortInput>;
};

export type ListContractsFilterInput = {
  accountId?: InputMaybe<TableStringFilterInput>;
  planId?: InputMaybe<TableStringFilterInput>;
  statusId?: InputMaybe<TableIntFilterInput>;
};

export type ListContractsFilterType = {
  __typename?: 'ListContractsFilterType';
  accountId?: Maybe<TableStringFilterType>;
  planId?: Maybe<TableStringFilterType>;
  statusId?: Maybe<TableIntFilterType>;
};

export type ListContractsInput = {
  filter?: InputMaybe<ListContractsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListContractsSearchInput>;
  sort?: InputMaybe<ListContractsSortInput>;
};

export type ListContractsSearchInput = {
  accountId?: InputMaybe<TableStringFilterInput>;
  planId?: InputMaybe<TableStringFilterInput>;
  statusId?: InputMaybe<TableStringFilterInput>;
};

export type ListContractsSearchType = {
  __typename?: 'ListContractsSearchType';
  accountId?: Maybe<TableStringFilterType>;
  planId?: Maybe<TableStringFilterType>;
  statusId?: Maybe<TableIntFilterType>;
};

export type ListContractsSortInput = {
  statusId?: InputMaybe<TableSortInput>;
};

export type ListContractsSortType = {
  __typename?: 'ListContractsSortType';
  statusId?: Maybe<TableSortType>;
};

export type ListCreditsInput = {
  accountId: Scalars['ID'];
};

export type ListDriversFilterInput = {
  account_accountStatus?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_administrativeArea?: InputMaybe<TableStringFilterInput>;
  driverStatusId?: InputMaybe<TableStringFilterInput>;
};

export type ListDriversFilterType = {
  __typename?: 'ListDriversFilterType';
  account_accountStatus?: Maybe<TableStringFilterType>;
  account_billingAddress_administrativeArea?: Maybe<TableStringFilterType>;
  driverStatusId?: Maybe<TableStringFilterType>;
};

export type ListDriversInput = {
  filter?: InputMaybe<ListDriversFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  paranoid?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<ListDriversSearchInput>;
  searchAndFilter?: InputMaybe<Scalars['Boolean']>;
  sort?: InputMaybe<ListDriversSortInput>;
};

export type ListDriversSearchInput = {
  accountVehicles_vin?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_address1?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_address2?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_administrativeArea?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_locality?: InputMaybe<TableStringFilterInput>;
  account_billingAddress_postalCode?: InputMaybe<TableStringFilterInput>;
  account_id?: InputMaybe<TableIntFilterInput>;
  email?: InputMaybe<TableStringFilterInput>;
  firstName?: InputMaybe<TableStringFilterInput>;
  lastName?: InputMaybe<TableStringFilterInput>;
  phone?: InputMaybe<TableStringFilterInput>;
};

export type ListDriversSearchType = {
  __typename?: 'ListDriversSearchType';
  account_billingAddress_address1?: Maybe<TableStringFilterType>;
  account_billingAddress_address2?: Maybe<TableStringFilterType>;
  account_billingAddress_administrativeArea?: Maybe<TableStringFilterType>;
  account_billingAddress_locality?: Maybe<TableStringFilterType>;
  account_billingAddress_postalCode?: Maybe<TableStringFilterType>;
  account_id?: Maybe<TableIntFilterType>;
  email?: Maybe<TableStringFilterType>;
  firstName?: Maybe<TableStringFilterType>;
  lastName?: Maybe<TableStringFilterType>;
  phone?: Maybe<TableStringFilterType>;
};

export type ListDriversSortInput = {
  account_accountStatus?: InputMaybe<TableSortInput>;
  account_balance?: InputMaybe<TableSortInput>;
  account_billingAddress_address1?: InputMaybe<TableSortInput>;
  account_id?: InputMaybe<TableSortInput>;
  driverStatusId?: InputMaybe<TableSortInput>;
  email?: InputMaybe<TableSortInput>;
  firstName?: InputMaybe<TableSortInput>;
  lastName?: InputMaybe<TableSortInput>;
};

export type ListDriversSortType = {
  __typename?: 'ListDriversSortType';
  account_accountStatus?: Maybe<TableSortType>;
  account_balance?: Maybe<TableSortType>;
  account_billingAddress_address1?: Maybe<TableSortType>;
  account_id?: Maybe<TableSortType>;
  driverStatusId?: Maybe<TableSortType>;
  email?: Maybe<TableSortType>;
  firstName?: Maybe<TableSortType>;
  lastName?: Maybe<TableSortType>;
};

export type ListElectricVehiclesInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListElectricVehiclesSearchInput>;
  sort?: InputMaybe<ListElectricVehiclesSortInput>;
};

export type ListElectricVehiclesSearchInput = {
  vehicleMake_manufacturer?: InputMaybe<TableStringFilterInput>;
  vehicleModelYear_ModelYear?: InputMaybe<TableStringFilterInput>;
  vehicleModel_modelName?: InputMaybe<TableStringFilterInput>;
};

export type ListElectricVehiclesSortInput = {
  make_manufacturer?: InputMaybe<TableSortInput>;
  vehicleMake_manufacturer?: InputMaybe<TableSortInput>;
  vehicleModelYear_ModelYear?: InputMaybe<TableSortInput>;
  vehicleModel_modelName?: InputMaybe<TableSortInput>;
};

export type ListElementsInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListExtendHostsInput = {
  /** Page you would like to request */
  page?: InputMaybe<Scalars['Int']>;
  /** Size of page you would like to request */
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListExtendUsersInput = {
  /** Page you would like to request */
  page?: InputMaybe<Scalars['Int']>;
  /** Size of page you would like to request */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** Total number of results returned */
  total?: InputMaybe<Scalars['Int']>;
};

export type ListFalconConstraintsFilterInput = {
  columnName: TableStringFilterInput;
  columnText?: InputMaybe<TableStringFilterInput>;
  columnValue?: InputMaybe<TableStringFilterInput>;
  tableName: TableStringFilterInput;
};

export type ListFalconConstraintsFilterType = {
  __typename?: 'ListFalconConstraintsFilterType';
  columnName?: Maybe<TableStringFilterType>;
  columnText?: Maybe<TableStringFilterType>;
  columnValue?: Maybe<TableStringFilterType>;
  tableName?: Maybe<TableStringFilterType>;
};

export type ListFalconConstraintsInput = {
  filter?: InputMaybe<ListFalconConstraintsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ListFalconConstraintsSortInput>;
};

export type ListFalconConstraintsSortInput = {
  columnText?: InputMaybe<TableSortInput>;
  id?: InputMaybe<TableSortInput>;
  orderBy?: InputMaybe<TableSortInput>;
};

export type ListFalconConstraintsSortType = {
  __typename?: 'ListFalconConstraintsSortType';
  columnText?: Maybe<TableSortType>;
  id?: Maybe<TableSortType>;
  orderBy?: Maybe<TableSortType>;
};

export type ListGeographicalRegionsFilterInput = {
  abbreviation?: InputMaybe<TableStringFilterInput>;
  country?: InputMaybe<TableStringFilterInput>;
  name?: InputMaybe<TableStringFilterInput>;
};

export type ListGeographicalRegionsInput = {
  filter?: InputMaybe<ListGeographicalRegionsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ListGeographicalRegionsSortInput>;
};

export type ListGeographicalRegionsSortInput = {
  abbreviation?: InputMaybe<TableSortInput>;
  name?: InputMaybe<TableSortInput>;
};

export type ListHostFilterInput = {
  hostStatusId?: InputMaybe<TableIntFilterInput>;
  status?: InputMaybe<TableStringFilterInput>;
};

export type ListHostFilterType = {
  __typename?: 'ListHostFilterType';
  status?: Maybe<TableStringFilterType>;
};

export type ListHostSearchInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  hid?: InputMaybe<TableStringFilterInput>;
  hostName?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
};

export type ListHostSearchType = {
  __typename?: 'ListHostSearchType';
  address1?: Maybe<TableStringFilterType>;
  administrativeArea?: Maybe<TableStringFilterType>;
  hid?: Maybe<TableStringFilterType>;
  hostName?: Maybe<TableStringFilterType>;
  locality?: Maybe<TableStringFilterType>;
  postalCode?: Maybe<TableStringFilterType>;
};

export type ListHostSortInput = {
  altId?: InputMaybe<TableSortInput>;
  hid?: InputMaybe<TableSortInput>;
  hostName?: InputMaybe<TableSortInput>;
  status?: InputMaybe<TableSortInput>;
};

export type ListHostSortType = {
  __typename?: 'ListHostSortType';
  altId?: Maybe<TableSortType>;
  hid?: Maybe<TableSortType>;
  hostName?: Maybe<TableSortType>;
  status?: Maybe<TableSortType>;
};

export type ListHostsInput = {
  filter?: InputMaybe<ListHostFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListHostSearchInput>;
  sort?: InputMaybe<ListHostSortInput>;
};

export type ListHostsRootFilterInput = {
  and?: InputMaybe<HostFilterInput>;
  or?: InputMaybe<HostFilterInput>;
};

export type ListHostsRootFilterType = {
  __typename?: 'ListHostsRootFilterType';
  and?: Maybe<HostFilterType>;
  or?: Maybe<HostFilterType>;
};

/** Input type for ListInvoicesForMobile */
export type ListInvoicesInput = {
  /** The cardID to further limit results to invoices associated with a specific card */
  cardId?: InputMaybe<Scalars['ID']>;
  /** The end of the date range in which to look for invoices to list; If NULL, uses server's current Date as default */
  endDate?: InputMaybe<Scalars['Date']>;
  /** The page number of results to request; page 0 is the first page */
  page?: InputMaybe<Scalars['Int']>;
  /** The number of results to include per page. Default is 9999 */
  pageSize?: InputMaybe<Scalars['Int']>;
  /** The beginning of the date range in which to look for invoices to list */
  startDate: Scalars['Date'];
};

export type ListNotificationGroupsFilterInput = {
  groupName?: InputMaybe<TableStringFilterInput>;
};

export type ListNotificationGroupsFilterType = {
  __typename?: 'ListNotificationGroupsFilterType';
  groupName?: Maybe<TableStringFilterType>;
};

export type ListNotificationGroupsInput = {
  filter?: InputMaybe<ListNotificationGroupsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListNotificationGroupsSearchInput>;
  sort?: InputMaybe<ListNotificationGroupsSortInput>;
};

export type ListNotificationGroupsSearchInput = {
  groupName?: InputMaybe<TableStringFilterInput>;
};

export type ListNotificationGroupsSearchType = {
  __typename?: 'ListNotificationGroupsSearchType';
  groupName?: Maybe<TableStringFilterType>;
};

export type ListNotificationGroupsSortInput = {
  groupName?: InputMaybe<TableSortInput>;
  sortOrder?: InputMaybe<TableSortInput>;
};

export type ListNotificationGroupsSortType = {
  __typename?: 'ListNotificationGroupsSortType';
  groupName?: Maybe<TableSortType>;
  sortOrder?: Maybe<TableSortType>;
};

export type ListNotificationItemsFilterInput = {
  driverLevel?: InputMaybe<TableStringFilterInput>;
};

export type ListNotificationItemsFilterType = {
  __typename?: 'ListNotificationItemsFilterType';
  driverLevel?: Maybe<TableStringFilterType>;
};

export type ListNotificationItemsInput = {
  filter?: InputMaybe<ListNotificationItemsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListNotificationItemsSearchInput>;
  sort?: InputMaybe<ListNotificationItemsSortInput>;
};

export type ListNotificationItemsSearchInput = {
  driverLevel?: InputMaybe<TableStringFilterInput>;
};

export type ListNotificationItemsSearchType = {
  __typename?: 'ListNotificationItemsSearchType';
  driverLevel?: Maybe<TableStringFilterType>;
};

export type ListNotificationItemsSortInput = {
  groupName?: InputMaybe<TableSortInput>;
  sortOrder?: InputMaybe<TableSortInput>;
};

export type ListNotificationItemsSortType = {
  __typename?: 'ListNotificationItemsSortType';
  groupName?: Maybe<TableSortType>;
  sortOrder?: Maybe<TableSortType>;
};

export type ListNotificationPreferencesFilterInput = {
  emailOn?: InputMaybe<TableBooleanFilterInput>;
  pushOn?: InputMaybe<TableBooleanFilterInput>;
  smsOn?: InputMaybe<TableBooleanFilterInput>;
};

export type ListNotificationPreferencesFilterType = {
  __typename?: 'ListNotificationPreferencesFilterType';
  emailOn?: Maybe<TableBooleanFilterType>;
  pushOn?: Maybe<TableBooleanFilterType>;
  smsOn?: Maybe<TableBooleanFilterType>;
};

export type ListNotificationPreferencesInput = {
  filter?: InputMaybe<ListNotificationPreferencesFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListNotificationPreferencesSearchInput>;
  sort?: InputMaybe<ListNotificationPreferencesSortInput>;
};

export type ListNotificationPreferencesSearchInput = {
  emailOn?: InputMaybe<TableBooleanFilterInput>;
  pushOn?: InputMaybe<TableBooleanFilterInput>;
  smsOn?: InputMaybe<TableBooleanFilterInput>;
};

export type ListNotificationPreferencesSearchType = {
  __typename?: 'ListNotificationPreferencesSearchType';
  emailOn?: Maybe<TableBooleanFilterType>;
  pushOn?: Maybe<TableBooleanFilterType>;
  smsOn?: Maybe<TableBooleanFilterType>;
};

export type ListNotificationPreferencesSortInput = {
  createdAt?: InputMaybe<TableSortInput>;
  updatedAt?: InputMaybe<TableSortInput>;
};

export type ListNotificationPreferencesSortType = {
  __typename?: 'ListNotificationPreferencesSortType';
  createdAt?: Maybe<TableSortType>;
  updatedAt?: Maybe<TableSortType>;
};

export type ListPaymentMethodsForMobileInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListPlansFilterInput = {
  /** Array of plan altIds used to filter a list of plans */
  altId?: InputMaybe<TableStringFilterInput>;
  /** Boolean flag used to filter plans that display their card to users */
  displayPlanCard?: InputMaybe<TableBooleanFilterInput>;
  /** Boolean flag used to filter plans that are displayed to customers */
  displayToCustomer?: InputMaybe<TableBooleanFilterInput>;
  /** Boolean flag used to filter plans that are displayed to logged out users */
  displayToLoggedOutCustomer?: InputMaybe<TableBooleanFilterInput>;
  /** Value used to order plans displayed to mobile users */
  driverRank?: InputMaybe<TableIntFilterInput>;
  planStatusId?: InputMaybe<TableIntFilterInput>;
  tags?: InputMaybe<TableStringFilterInput>;
};

export type ListPlansInput = {
  filter?: InputMaybe<ListPlansFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListPlansSearchInput>;
  sort?: InputMaybe<ListPlansSortInput>;
};

export type ListPlansSearchInput = {
  planName?: InputMaybe<TableStringFilterInput>;
};

export type ListPlansSearchType = {
  __typename?: 'ListPlansSearchType';
  planName?: Maybe<TableStringFilterType>;
};

export type ListPlansSortInput = {
  createdAt?: InputMaybe<TableSortInput>;
  driverRank?: InputMaybe<TableSortInput>;
  planDisplayName?: InputMaybe<TableSortInput>;
  planName?: InputMaybe<TableSortInput>;
};

export type ListPlansSortType = {
  __typename?: 'ListPlansSortType';
  createdAt?: Maybe<TableSortInput>;
  planDescription?: Maybe<TableSortInput>;
  planDisplayName?: Maybe<TableSortInput>;
  planName?: Maybe<TableSortInput>;
};

export type ListPortsFilterInput = {
  and?: InputMaybe<PortFilterInput>;
  connector?: InputMaybe<ListConnectorsRootFilterInput>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<PortFilterInput>;
};

export type ListPortsInput = {
  filter?: InputMaybe<ListPortsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListPortsSearchInput>;
  sort?: InputMaybe<ListPortsSortInput>;
  total?: InputMaybe<Scalars['Int']>;
};

export type ListPortsRootFilterInput = {
  and?: InputMaybe<PortFilterInput>;
  connector?: InputMaybe<ListConnectorsRootFilterInput>;
  or?: InputMaybe<PortFilterInput>;
};

export type ListPortsRootFilterType = {
  __typename?: 'ListPortsRootFilterType';
  and?: Maybe<PortFilterType>;
  connector?: Maybe<ListConnectorsRootFilterType>;
  or?: Maybe<PortFilterType>;
};

export type ListPortsSearchInput = {
  evcId?: InputMaybe<TableStringFilterInput>;
};

export type ListPortsSortInput = {
  chargeBoxIdentity?: InputMaybe<TableSortInput>;
};

export type ListProductsInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListProductsSearchInput>;
  sort?: InputMaybe<ListProductsSortInput>;
};

export type ListProductsSearchInput = {
  name?: InputMaybe<TableStringFilterInput>;
};

export type ListProductsSearchType = {
  __typename?: 'ListProductsSearchType';
  name?: Maybe<TableStringFilterType>;
};

export type ListProductsSortInput = {
  createdAt?: InputMaybe<TableStringFilterInput>;
  description?: InputMaybe<TableStringFilterInput>;
  name?: InputMaybe<TableStringFilterInput>;
};

export type ListProductsSortType = {
  __typename?: 'ListProductsSortType';
  createdAt?: Maybe<TableSortInput>;
  description?: Maybe<TableSortInput>;
  name?: Maybe<TableSortInput>;
};

export type ListPropertiesFilterInput = {
  host_hid?: InputMaybe<TableStringFilterInput>;
  propertyName?: InputMaybe<TableStringFilterInput>;
  status?: InputMaybe<TableStringFilterInput>;
};

export type ListPropertiesFilterType = {
  __typename?: 'ListPropertiesFilterType';
  status?: Maybe<TableStringFilterType>;
};

export type ListPropertiesInput = {
  filter?: InputMaybe<ListPropertiesFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListPropertiesSearchInput>;
  sort?: InputMaybe<ListPropertiesSortInput>;
  total?: InputMaybe<Scalars['Int']>;
};

export type ListPropertiesRootFilterInput = {
  and?: InputMaybe<PropertyFilterInput>;
  host?: InputMaybe<ListHostsRootFilterInput>;
  or?: InputMaybe<PropertyFilterInput>;
};

export type ListPropertiesRootFilterType = {
  __typename?: 'ListPropertiesRootFilterType';
  and?: Maybe<PropertyFilterType>;
  host?: Maybe<ListHostsRootFilterType>;
  or?: Maybe<PropertyFilterType>;
};

export type ListPropertiesSearchInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  pid?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
  propertyName?: InputMaybe<TableStringFilterInput>;
};

export type ListPropertiesSearchType = {
  __typename?: 'ListPropertiesSearchType';
  address1?: Maybe<TableStringFilterType>;
  administrativeArea?: Maybe<TableStringFilterType>;
  locality?: Maybe<TableStringFilterType>;
  pid?: Maybe<TableStringFilterType>;
  postalCode?: Maybe<TableStringFilterType>;
  propertyName?: Maybe<TableStringFilterType>;
};

export type ListPropertiesSortInput = {
  altId?: InputMaybe<TableSortInput>;
  host?: InputMaybe<ListHostSortInput>;
  hostName?: InputMaybe<TableSortInput>;
  pid?: InputMaybe<TableSortInput>;
  propertyName?: InputMaybe<TableSortInput>;
  status?: InputMaybe<TableSortInput>;
};

export type ListPropertiesSortType = {
  __typename?: 'ListPropertiesSortType';
  altId?: Maybe<TableSortType>;
  hostName?: Maybe<TableSortType>;
  pid?: Maybe<TableSortType>;
  propertyName?: Maybe<TableSortType>;
  status?: Maybe<TableSortType>;
};

export type ListReservationTermsInput = {
  endDate?: InputMaybe<Scalars['Date']>;
  siteId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
};

export type ListReservationsByDriverInput = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type ListReservationsInput = {
  /** ID of the account to look up reservations for */
  accountId?: InputMaybe<Scalars['ID']>;
  /** ID of the card to filter reservations for; Required for listReservationsByCard */
  cardId?: InputMaybe<Scalars['ID']>;
  /** ID of the charger to look up reservations for */
  chargerId?: InputMaybe<Scalars['ID']>;
  /** ID of the connector to look up reservations for; Required for listAvailableSlots query */
  connectorId?: InputMaybe<Scalars['ID']>;
  /** The end range of reservations to look up */
  endDate: Scalars['Date'];
  /** The beginning range of reservations to look up */
  startDate: Scalars['Date'];
};

export type ListSalesforceHostContactsFilterInput = {
  contactName?: InputMaybe<TableStringFilterInput>;
  hid?: InputMaybe<TableStringFilterInput>;
};

export type ListSalesforceHostContactsInput = {
  filter?: InputMaybe<ListSalesforceHostContactsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ListSalesforceHostContactsSortInput>;
};

export type ListSalesforceHostContactsSortInput = {
  contactName?: InputMaybe<TableSortInput>;
};

export type ListSalesforceHostsFilterInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  hostName?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
  status?: InputMaybe<TableStringFilterInput>;
};

export type ListSalesforceHostsInput = {
  filter?: InputMaybe<ListSalesforceHostsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListSalesforceHostsSearchInput>;
  sort?: InputMaybe<ListSalesforceHostsSortInput>;
};

export type ListSalesforceHostsSearchInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  address2?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  hid?: InputMaybe<TableStringFilterInput>;
  hostName?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
};

export type ListSalesforceHostsSortInput = {
  hid?: InputMaybe<TableSortInput>;
};

export type ListSalesforceHostsType = {
  __typename?: 'ListSalesforceHostsType';
  hostName?: Maybe<TableStringFilterType>;
};

export type ListSalesforcePropertiesFilterInput = {
  hid?: InputMaybe<TableStringFilterInput>;
  propertyName?: InputMaybe<TableStringFilterInput>;
};

export type ListSalesforcePropertiesFilterType = {
  __typename?: 'ListSalesforcePropertiesFilterType';
  propertyName?: Maybe<TableStringFilterType>;
};

export type ListSalesforcePropertiesInput = {
  filter?: InputMaybe<ListSalesforcePropertiesFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListSalesforcePropertiesSearchInput>;
  sort?: InputMaybe<ListSalesforcePropertiesSortInput>;
  total?: InputMaybe<Scalars['Int']>;
};

export type ListSalesforcePropertiesSearchInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  pid?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
  propertyName?: InputMaybe<TableStringFilterInput>;
};

export type ListSalesforcePropertiesSearchType = {
  __typename?: 'ListSalesforcePropertiesSearchType';
  address1?: Maybe<TableStringFilterType>;
  administrativeArea?: Maybe<TableStringFilterType>;
  locality?: Maybe<TableStringFilterType>;
  pid?: Maybe<TableStringFilterType>;
  postalCode?: Maybe<TableStringFilterType>;
  propertyName?: Maybe<TableStringFilterType>;
};

export type ListSalesforcePropertiesSortInput = {
  hostName?: InputMaybe<TableSortInput>;
  pid?: InputMaybe<TableSortInput>;
  propertyName?: InputMaybe<TableSortInput>;
};

export type ListSalesforcePropertiesSortType = {
  __typename?: 'ListSalesforcePropertiesSortType';
  hostName?: Maybe<TableSortType>;
  pid?: Maybe<TableSortType>;
  propertyName?: Maybe<TableSortType>;
};

export type ListSessionsFilterInput = {
  card_altId?: InputMaybe<TableStringFilterInput>;
  connector_evse_charger_altId?: InputMaybe<TableStringFilterInput>;
  connector_evse_charger_locationId?: InputMaybe<TableStringFilterInput>;
  endTime?: InputMaybe<TableTimestampRangeFilterInput>;
  sessionId?: InputMaybe<TableStringFilterInput>;
  sessionStartOn?: InputMaybe<TableTimestampRangeFilterInput>;
  sessionStoppedOn?: InputMaybe<TableTimestampRangeFilterInput>;
  startSource?: InputMaybe<TableStringFilterInput>;
  startTime?: InputMaybe<TableTimestampRangeFilterInput>;
};

export type ListSessionsFilterType = {
  __typename?: 'ListSessionsFilterType';
  card_altId?: Maybe<TableStringFilterType>;
  connector_evse_charger_locationId?: Maybe<TableStringFilterType>;
  sessionId?: Maybe<TableStringFilterType>;
  sessionStartOn?: Maybe<TableTimestampRangeFilterType>;
  sessionStoppedOn?: Maybe<TableTimestampRangeFilterType>;
  startSource?: Maybe<TableStringFilterType>;
};

export type ListSessionsInput = {
  filter?: InputMaybe<ListSessionsFilterInput>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  search?: InputMaybe<ListSessionsSearchInput>;
  sort?: InputMaybe<ListSessionsSortInput>;
};

export type ListSessionsSearchInput = {
  card_externalNumber?: InputMaybe<TableStringFilterInput>;
  chargerId?: InputMaybe<TableStringFilterInput>;
  sessionId?: InputMaybe<TableStringFilterInput>;
};

export type ListSessionsSearchType = {
  __typename?: 'ListSessionsSearchType';
  chargerId?: Maybe<TableStringFilterType>;
  sessionId?: Maybe<TableStringFilterType>;
};

export type ListSessionsSortInput = {
  sessionId?: InputMaybe<TableSortInput>;
};

export type ListSessionsV2FilterInput = {
  accountId?: InputMaybe<TableStringFilterInput>;
  altId?: InputMaybe<TableStringFilterInput>;
  chargerId?: InputMaybe<TableStringFilterInput>;
  chargerName?: InputMaybe<TableStringFilterInput>;
  corrupted?: InputMaybe<TableBooleanFilterInput>;
  driverId?: InputMaybe<TableStringFilterInput>;
  endTime?: InputMaybe<TableDateFilterInput>;
  endTime2?: InputMaybe<TableDateFilterInput>;
  hostName?: InputMaybe<TableStringFilterInput>;
  planId?: InputMaybe<TableStringFilterInput>;
  planName?: InputMaybe<TableStringFilterInput>;
  siteId?: InputMaybe<TableStringFilterInput>;
  siteName?: InputMaybe<TableStringFilterInput>;
  startTime?: InputMaybe<TableDateFilterInput>;
  startTime2?: InputMaybe<TableDateFilterInput>;
  transactionTypeId?: InputMaybe<TableStringFilterInput>;
  vendorIdStartsWith?: InputMaybe<Scalars['String']>;
};

export type ListSessionsV2Input = {
  filter?: InputMaybe<ListSessionsV2FilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListSessionsV2FilterInput>;
  sort?: InputMaybe<Array<InputMaybe<SessionsV2Sort>>>;
  total?: InputMaybe<Scalars['Int']>;
};

export type ListSiteFilterType = {
  __typename?: 'ListSiteFilterType';
  and?: Maybe<SiteFilterType>;
  charger?: Maybe<ListChargersRootFilterType>;
  distance?: Maybe<Scalars['Float']>;
  distinct?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  neLat?: Maybe<Scalars['Float']>;
  neLng?: Maybe<Scalars['Float']>;
  or?: Maybe<SiteFilterType>;
  property?: Maybe<ListPropertiesRootFilterType>;
  siteStatusId?: Maybe<TableStringFilterType>;
  swLat?: Maybe<Scalars['Float']>;
  swLng?: Maybe<Scalars['Float']>;
};

export type ListSiteImagesInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  siteId: Scalars['ID'];
};

export type ListSiteSearchType = {
  __typename?: 'ListSiteSearchType';
  address1?: Maybe<TableStringFilterType>;
  administrativeArea?: Maybe<TableStringFilterType>;
  locality?: Maybe<TableStringFilterType>;
  postalCode?: Maybe<TableStringFilterType>;
  property_host_hostName?: Maybe<TableStringFilterType>;
  sid?: Maybe<TableStringFilterType>;
  siteName?: Maybe<TableStringFilterType>;
};

export type ListSitesConsumptionForExtendData = {
  __typename?: 'ListSitesConsumptionForExtendData';
  /** Address of that site */
  address?: Maybe<Scalars['String']>;
  /** State of that site */
  administrativeArea?: Maybe<Scalars['String']>;
  /** Average kWh dispensed */
  averageSessionPower: Scalars['Float'];
  /** Average session time in HH:MM:SS format */
  averageSessionTime?: Maybe<Scalars['String']>;
  siteId: Scalars['String'];
  siteLatitude?: Maybe<Scalars['Float']>;
  siteLongitude?: Maybe<Scalars['Float']>;
  siteName: Scalars['String'];
  siteStatus: Scalars['String'];
  /** Total chargers in that site, in that time interval */
  totalChargers: Scalars['Int'];
  /** Total sessions in that site, in that time interval */
  totalSessions: Scalars['Int'];
  /** Total cost value of all the sessions in $, in that site, in that time interval */
  totalSessionsCost: Scalars['Float'];
  /** Total power value dispensed in all the sessions in kWh, in that site, in that time interval */
  totalSessionsPower: Scalars['Float'];
  /** Total session time to second precision */
  totalSessionsTime?: Maybe<Scalars['String']>;
};

/** Filter the session consumption data in that datePreset, for all sessions in these site altIds */
export type ListSitesConsumptionForExtendFilterInput = {
  /** Filter the timeseries data by charger groups, if not send will return for all charger groups */
  chargerGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  datePreset?: InputMaybe<SessionDatePreset>;
  endDate?: InputMaybe<Scalars['Date']>;
  /** Filter the timeseries data by plans, if not send will return for all plans */
  planIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter the sessions in these sites, if not send will return for all sites */
  siteIds?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['Date']>;
  /** Filter the timeseries data by transaction type, if not send will return only for the default eXtend+ types */
  transactionTypeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ListSitesConsumptionForExtendInput = {
  filter: ListSitesConsumptionForExtendFilterInput;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListSitesConsumptionForExtendWithMeta = {
  __typename?: 'ListSitesConsumptionForExtendWithMeta';
  edges?: Maybe<Array<ListSitesConsumptionForExtendData>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type ListSitesFilterInput = {
  and?: InputMaybe<SiteFilterInput>;
  charger?: InputMaybe<ListChargersRootFilterInput>;
  distance?: InputMaybe<Scalars['Float']>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  neLat?: InputMaybe<Scalars['Float']>;
  neLng?: InputMaybe<Scalars['Float']>;
  or?: InputMaybe<SiteFilterInput>;
  property?: InputMaybe<ListPropertiesRootFilterInput>;
  siteStatusId?: InputMaybe<TableIntFilterInput>;
  swLat?: InputMaybe<Scalars['Float']>;
  swLng?: InputMaybe<Scalars['Float']>;
};

export type ListSitesForMobileInput = {
  /** An array of integers used to filter by connector types. */
  connectorTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** An integer used to find sites within a radius. Units in miles. */
  distance: Scalars['Int'];
  /** If this flag is set to true and connectorTypeIds has CHADEMO site supports Tesla charging */
  hasTeslaAdapter?: InputMaybe<Scalars['Boolean']>;
  /** An float for latitude of the driver or POI. */
  latitude: Scalars['Float'];
  /** An float for longitude of the driver or POI. */
  longitude: Scalars['Float'];
  /** An array of integers used to filter network operators. */
  networkOperatorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** An integer for the min desired output in kW filter */
  outputKwMax?: InputMaybe<Scalars['Int']>;
  /** An integer for the min desired output in kW filter */
  outputKwMin?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED - no longer used in listSitesForMobile */
  outputWattsMax?: InputMaybe<Scalars['Int']>;
  /** DEPRECATED - no longer used in listSitesForMobile */
  outputWattsMin?: InputMaybe<Scalars['Int']>;
  /** A boolean flag which applies a true/false filter to the RESERVABLE property of the results returned. */
  reservable?: InputMaybe<Scalars['Boolean']>;
  /** A string used to search against the name, displayName, adddress1, city, zipCode, and usaStateName. */
  search?: InputMaybe<Scalars['String']>;
  /** An array of integers used to filter vehicle connectors. */
  vehicleConnectorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type ListSitesInput = {
  filter?: InputMaybe<ListSitesFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListSitesSearchInput>;
  sort?: InputMaybe<ListSitesSortInput>;
};

export type ListSitesSearchInput = {
  address1?: InputMaybe<TableStringFilterInput>;
  administrativeArea?: InputMaybe<TableStringFilterInput>;
  displayName?: InputMaybe<TableStringFilterInput>;
  locality?: InputMaybe<TableStringFilterInput>;
  postalCode?: InputMaybe<TableStringFilterInput>;
  property_host_hostName?: InputMaybe<TableStringFilterInput>;
  sid?: InputMaybe<TableStringFilterInput>;
  siteName?: InputMaybe<TableStringFilterInput>;
};

export type ListSitesSortInput = {
  distance?: InputMaybe<TableSortInput>;
  property?: InputMaybe<ListPropertiesSortInput>;
  sid?: InputMaybe<TableSortInput>;
  siteName?: InputMaybe<TableSortInput>;
  siteStatusId?: InputMaybe<TableSortInput>;
};

export type ListSitesSortType = {
  __typename?: 'ListSitesSortType';
  hostName?: Maybe<TableSortType>;
  sid?: Maybe<TableSortType>;
  siteName?: Maybe<TableSortType>;
  siteStatusId?: Maybe<TableSortType>;
};

export type ListStatementsInput = {
  /** The end date in ISO format */
  endDate?: InputMaybe<Scalars['Date']>;
  /** The start date in ISO format */
  startDate?: InputMaybe<Scalars['Date']>;
};

export type ListTagsFilterInput = {
  description?: InputMaybe<TableStringFilterInput>;
  tagName?: InputMaybe<TableStringFilterInput>;
};

export type ListTagsInput = {
  filter?: InputMaybe<ListTagsFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListTagsSearchInput>;
  sort?: InputMaybe<ListTagsSortInput>;
};

export type ListTagsSearchInput = {
  tagName?: InputMaybe<TableStringFilterInput>;
};

export type ListTagsSearchType = {
  __typename?: 'ListTagsSearchType';
  tagName?: Maybe<TableStringFilterType>;
};

export type ListTagsSortInput = {
  tagName?: InputMaybe<TableSortInput>;
};

export type ListTagsSortType = {
  __typename?: 'ListTagsSortType';
  tagName?: Maybe<Scalars['String']>;
};

export type ListTariffConnectorTypesInput = {
  tariffId: Scalars['ID'];
};

export type ListTariffsForExtendInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<TariffsSort>>>;
};

export type ListTariffsInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListTariffsSearchInput>;
  sort?: InputMaybe<ListTariffsSortInput>;
};

export type ListTariffsSearchInput = {
  tariffDescription?: InputMaybe<TableStringFilterInput>;
  tariffName?: InputMaybe<TableStringFilterInput>;
};

export type ListTariffsSearchType = {
  __typename?: 'ListTariffsSearchType';
  tariffDescription?: Maybe<TableStringFilterType>;
  tariffName?: Maybe<TableStringFilterType>;
};

export type ListTariffsSortInput = {
  altId?: InputMaybe<TableSortInput>;
  createdAt?: InputMaybe<TableSortInput>;
  tariffDescription?: InputMaybe<TableSortInput>;
  tariffName?: InputMaybe<TableSortInput>;
  tariffRank?: InputMaybe<TableSortInput>;
};

export type ListTariffsSortType = {
  __typename?: 'ListTariffsSortType';
  altId?: Maybe<TableSortType>;
  createdAt?: Maybe<TableSortType>;
  tariffDescription?: Maybe<TableSortType>;
  tariffName?: Maybe<TableSortType>;
  tariffRank?: Maybe<TableSortType>;
};

export type ListTransactionsForMobileInput = {
  cardId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
};

export type ListTransactionsInput = {
  accountId: Scalars['ID'];
  cardId?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate: Scalars['Date'];
};

export type ListTransactionsV2FilterInput = {
  altId?: InputMaybe<TableStringFilterInput>;
  chargerGroupId?: InputMaybe<TableStringFilterInput>;
  chargerId?: InputMaybe<TableStringFilterInput>;
  chargerName?: InputMaybe<TableStringFilterInput>;
  createdAt?: InputMaybe<TableDateFilterInput>;
  createdAt2?: InputMaybe<TableDateFilterInput>;
  driverId?: InputMaybe<TableIntFilterInput>;
  endTime?: InputMaybe<TableDateFilterInput>;
  endTime2?: InputMaybe<TableDateFilterInput>;
  endTimeLocal?: InputMaybe<TableDateFilterInput>;
  planId?: InputMaybe<TableStringFilterInput>;
  planName?: InputMaybe<TableStringFilterInput>;
  siteId?: InputMaybe<TableStringFilterInput>;
  siteName?: InputMaybe<TableStringFilterInput>;
  startTime?: InputMaybe<TableDateFilterInput>;
  startTime2?: InputMaybe<TableDateFilterInput>;
  startTimeLocal?: InputMaybe<TableDateFilterInput>;
  transactionTypeId?: InputMaybe<TableStringFilterInput>;
  vendorId?: InputMaybe<TableIntFilterInput>;
};

export type ListTransactionsV2Input = {
  filter?: InputMaybe<ListTransactionsV2FilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListTransactionsV2FilterInput>;
  sort?: InputMaybe<Array<InputMaybe<TransactionsV2Sort>>>;
  total?: InputMaybe<Scalars['Int']>;
};

export type ListUnassociatedChargersForFleetInput = {
  siteId: Scalars['ID'];
};

export type ListUploadsFilterInput = {
  url?: InputMaybe<TableStringFilterInput>;
};

export type ListUploadsInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<ListUploadsFilterInput>;
};

export type ListVehicleConnectorsInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListVehiclesInput = {
  fuzzySearch?: InputMaybe<Scalars['SanitizeString']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type ListWarehousesFilterInput = {
  warehouseName?: InputMaybe<TableStringFilterInput>;
};

export type ListWarehousesInput = {
  filter?: InputMaybe<ListWarehousesFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ListWarehousesSortInput>;
};

export type ListWarehousesSortInput = {
  warehouseName?: InputMaybe<TableSortInput>;
};

export type ListWarehousesSortType = {
  __typename?: 'ListWarehousesSortType';
  warehouseName?: Maybe<TableSortInput>;
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LogoUploadUrl = {
  __typename?: 'LogoUploadUrl';
  filename: Scalars['String'];
  hiddenFormFieldsJSON: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export enum MaintenanceVendor {
  /** BTC */
  Btc = 'BTC',
  /** MaxGen */
  Maxgen = 'MAXGEN',
}

export type MandrillResponse = {
  __typename?: 'MandrillResponse';
  mandrillId: Scalars['ID'];
  recipientEmail: Scalars['String'];
  status: Scalars['String'];
};

export enum Manufacturer {
  /** ABB */
  Abb = 'ABB',
  /** AV */
  Av = 'AV',
  Bosch = 'BOSCH',
  /** BTC */
  Btc = 'BTC',
  /** Delta */
  Delta = 'DELTA',
  Efacec = 'EFACEC',
  /** LiteOn */
  Liteon = 'LITEON',
  /** Nissan */
  Nissan = 'NISSAN',
  Semaconnect = 'SEMACONNECT',
  /** Signet */
  Signet = 'SIGNET',
  /** SPX */
  Spx = 'SPX',
}

export type Media = {
  __typename?: 'Media';
  altId: Scalars['UUID'];
  createdAt?: Maybe<Scalars['Date']>;
  encoding?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  mimeType: Scalars['String'];
  url: Scalars['String'];
};

export type MobileTransaction = {
  __typename?: 'MobileTransaction';
  authorization?: Maybe<Scalars['String']>;
  billingTax?: Maybe<Scalars['Float']>;
  charger?: Maybe<Charger>;
  createdAt?: Maybe<Scalars['Date']>;
  driverName?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Date']>;
  energyDeliveredKw?: Maybe<Scalars['Float']>;
  lineItems?: Maybe<Array<Maybe<MobileTransactionLineItem>>>;
  networkOperator?: Maybe<NetworkOperator>;
  powerLevel?: Maybe<Scalars['Float']>;
  sessionDuration?: Maybe<Scalars['Time']>;
  sessionId?: Maybe<Scalars['String']>;
  site?: Maybe<Site>;
  startTime?: Maybe<Scalars['Date']>;
  stopReason?: Maybe<Scalars['String']>;
  subtotal?: Maybe<Scalars['Float']>;
  transactionCategory?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['ID']>;
};

export type MobileTransactionLineItem = {
  __typename?: 'MobileTransactionLineItem';
  amount?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['String']>;
};

export type MobileTransactionsWithMeta = {
  __typename?: 'MobileTransactionsWithMeta';
  edges?: Maybe<Array<Maybe<MobileTransaction>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCard?: Maybe<Scalars['Void']>;
  activateCardForMobile?: Maybe<Scalars['Void']>;
  activateCards?: Maybe<Array<Maybe<Card>>>;
  /** Mutation for adding chargers to a charger group */
  addChargersToChargerGroupForExtend?: Maybe<ChargerGroupForExtend>;
  assignCard?: Maybe<Card>;
  assignCardForMobile?: Maybe<Card>;
  assignCards?: Maybe<Array<Maybe<Card>>>;
  authenticateUser: AuthResponse;
  cancelAutochargeEnrollment: AccountVehicle;
  cancelInviteDriver: Driver;
  /** cancel a reservation for the current authenticated driver */
  cancelReservation?: Maybe<Reservation>;
  /** cancel a reservation for a specified driver */
  cancelReservationForDriver?: Maybe<Reservation>;
  checkDriverFirebase?: Maybe<Scalars['Void']>;
  checkDriverPhoneNumber: PhoneNumberCheck;
  completeAutochargeEnrollment?: Maybe<Scalars['Void']>;
  createAccount?: Maybe<Account>;
  createAccountOwner: Driver;
  createAccountOwnerWithoutPhone: Driver;
  createAccountVehicle?: Maybe<AccountVehicle>;
  createAdHocContract?: Maybe<AdHocContract>;
  createCard?: Maybe<Card>;
  createCardOrder?: Maybe<Scalars['Void']>;
  createCharger: Charger;
  /** Mutation for creating a charger group plan */
  createChargerGroupPlanForExtend?: Maybe<ChargerGroupForExtend>;
  createChargerModel: ChargerModel;
  /** Mutation for creating a charger group */
  createChargerPriceGroupForExtend?: Maybe<ChargerGroupForExtend>;
  createClientToken: ClientToken;
  createContact?: Maybe<Contact>;
  createContract?: Maybe<Contract>;
  createCreditForUser?: Maybe<CreditBucket>;
  createCreditSource?: Maybe<CreditSource>;
  /** This is to create tag and tags_chargers when syncing tag from Driivz through kafka lambda */
  createDriivzTag?: Maybe<Tag>;
  createDriver: Driver;
  createDriverForMobile: Driver;
  /** Flag a plan as requiring custom calculated transactions by connector pricing */
  createExtendCalculatedHostPlan?: Maybe<Success>;
  createExtendHost: ExtendHost;
  createExtendUser?: Maybe<ExtendUser>;
  createFleetUser: User;
  createHost: Host;
  /** Create an s3 pre-signed upload url which will accept png images */
  createLogoUploadUrl: LogoUploadUrl;
  createPaymentCardForMobile?: Maybe<Scalars['Void']>;
  createPlan?: Maybe<Plan>;
  createPlanTariff?: Maybe<Plan>;
  /** Mutation to create a custom plan tariff */
  createPlanTariffsForExtend: ChargerGroupForExtend;
  createProperty: Property;
  /** create a reservation for the current authenticated driver */
  createReservation?: Maybe<Reservation>;
  /** create a reservation for a specified driver */
  createReservationForDriver?: Maybe<Reservation>;
  createSession?: Maybe<Session>;
  createSite: Site;
  createTag?: Maybe<Tag>;
  createTariffConnectorType?: Maybe<TariffConnectorTypes>;
  /** Mutation to create a tariff */
  createTariffForExtend: TariffForExtend;
  createUpload: Media;
  createUploads: Array<Maybe<Media>>;
  createUser: User;
  createVehicle?: Maybe<VehicleV2>;
  createVehicleMake?: Maybe<VehicleMakeV2>;
  createVehicleModel?: Maybe<VehicleModelV2>;
  deactivateCards?: Maybe<Array<Maybe<Card>>>;
  /** Mutation for deactivating a charger group plan */
  deactivateChargerGroupPlanForExtend?: Maybe<ChargerGroupForExtend>;
  deleteAccountVehicle?: Maybe<Success>;
  deleteCharger: Charger;
  /** Mutation for deleting a charger group */
  deleteChargerGroupForExtend?: Maybe<ChargerGroupForExtend>;
  /** Mutation for deleting a charger price group */
  deleteChargerPriceGroupForExtend?: Maybe<ChargerGroupForExtend>;
  /** This is to delete tag and tags_chargers when syncing tag from Driivz through kafka lambda */
  deleteDriivzTag?: Maybe<Scalars['Void']>;
  deleteDriver: Driver;
  /** Remove a plan as requiring custom calculated transactions by connector pricing */
  deleteExtendCalculatedHostPlan?: Maybe<Success>;
  deleteExtendHost?: Maybe<Success>;
  deleteExtendUserHosts?: Maybe<ExtendUser>;
  deleteHost: Host;
  deletePaymentMethod?: Maybe<Scalars['Void']>;
  deletePaymentMethodForMobile?: Maybe<Scalars['Void']>;
  deletePlanTariff?: Maybe<Scalars['Void']>;
  deleteTag?: Maybe<Tag>;
  /** Mutation to delete a tariff */
  deleteTariffForExtend?: Maybe<Scalars['Void']>;
  deleteVehicle?: Maybe<VehicleV2>;
  deleteVehicleIdCard?: Maybe<Success>;
  deleteVehicleMake?: Maybe<VehicleMakeV2>;
  deleteVehicleModel?: Maybe<VehicleModelV2>;
  disassociateAccountVehicle?: Maybe<Success>;
  enrollDriver: Success;
  enrollExistingUsers?: Maybe<Array<Maybe<Reward>>>;
  enrollNewUsers?: Maybe<Array<Maybe<Reward>>>;
  enrollThirdPartyWallet?: Maybe<Scalars['Void']>;
  enrollUserInRewards?: Maybe<Scalars['Void']>;
  handleAccountEvent?: Maybe<Scalars['Boolean']>;
  handleAccountStatusEvent?: Maybe<Scalars['Boolean']>;
  handleCardEvent?: Maybe<Scalars['Boolean']>;
  handleContractEvent?: Maybe<Scalars['Boolean']>;
  handlePaymentMethodEvent?: Maybe<Scalars['Boolean']>;
  handleSessionEvent?: Maybe<Scalars['Boolean']>;
  handleSessionMeterEvent?: Maybe<Scalars['Boolean']>;
  importAccountDrivers: Scalars['Int'];
  importAccountOwner: Scalars['Int'];
  importAccountVehicles?: Maybe<Scalars['Int']>;
  importCards?: Maybe<Scalars['Int']>;
  importContracts?: Maybe<Scalars['Int']>;
  importHost: Host;
  importPaymentMethods?: Maybe<Scalars['Int']>;
  importProperty: Property;
  importVehicles?: Maybe<Array<Maybe<ElectricVehicle>>>;
  inviteDriver: MandrillResponse;
  login?: Maybe<Scalars['Void']>;
  /** @deprecated Cleanup - use optInPromotions instead! */
  optIn?: Maybe<OptIn>;
  optInPromotions?: Maybe<Scalars['Void']>;
  reassignCard?: Maybe<Card>;
  refreshTags?: Maybe<Array<Maybe<Charger>>>;
  registerAccountOwner: Driver;
  /** Mutation for removing chargers from a charger group */
  removeChargersFromChargerGroupForExtend?: Maybe<ChargerGroupForExtend>;
  removeDriver?: Maybe<Scalars['Void']>;
  replaceCard?: Maybe<Card>;
  reserveCards?: Maybe<Array<Maybe<Card>>>;
  resetChargerForExtend?: Maybe<Scalars['Void']>;
  resetDriverPassword: MandrillResponse;
  /** Remove a phone number from all databases, freeing the number to be used again */
  reusePhoneNumber?: Maybe<Scalars['Void']>;
  root?: Maybe<Scalars['SanitizeString']>;
  sendCardOrderNotifications?: Maybe<Scalars['Void']>;
  sendContractNotifications?: Maybe<Scalars['Void']>;
  sendDisplayTariff: Array<SendTariffResponse>;
  sendEmailMessage?: Maybe<Success>;
  sendEndSessionTariff: Array<SendTariffResponse>;
  sendPasswordResetEmail?: Maybe<Success>;
  sendPaymentMethodNotifications?: Maybe<SendPaymentMethodNotificationsResponse>;
  sendRewardsConfirmationEmail?: Maybe<SendRewardsConfirmationEmailReturn>;
  sendSessionTariff: Array<SendTariffResponse>;
  sendSetTariff: Array<SendTariffResponse>;
  sendTransactionSms?: Maybe<Scalars['Void']>;
  sessionStartRequest?: Maybe<SessionStartResponse>;
  setChargingProfile: SetChargingProfileStatusResponse;
  /** Mutation to set demand limit for retail demand management */
  setDemandLimit: Site;
  setPrimaryCardForMobile?: Maybe<Scalars['Void']>;
  startAutochargeEnrollment: AccountVehicle;
  startCharge?: Maybe<Scalars['Void']>;
  startChargeForFleet?: Maybe<Scalars['Void']>;
  startChargeForMobile?: Maybe<Scalars['Void']>;
  stopCharge?: Maybe<Scalars['Void']>;
  stopChargeForMobile?: Maybe<Scalars['Void']>;
  suspendCards?: Maybe<Array<Maybe<Card>>>;
  syncAssignCard?: Maybe<Card>;
  syncChargerModelConnector?: Maybe<ChargerModelConnector>;
  syncChargerStatus: Charger;
  syncConnector?: Maybe<Connector>;
  syncContract?: Maybe<Contract>;
  syncCreateAccountOwner: Driver;
  syncCreateAdHocContract?: Maybe<AdHocContract>;
  syncCreateCardOrder?: Maybe<CardOrder>;
  syncCreateCharger: Charger;
  syncCreateChargerModel: ChargerModel;
  syncCreateContract?: Maybe<Contract>;
  syncCreateDriver?: Maybe<Driver>;
  syncCreateHost: Host;
  syncCreateProperty: Property;
  syncCreateSite: Site;
  syncPasswordReset: Scalars['String'];
  syncPlan?: Maybe<Plan>;
  syncSite: Site;
  syncUpdateAccount: Account;
  syncUpdateAccountOwner: Driver;
  syncUpdateCharger: Charger;
  syncUpdateDriver?: Maybe<Driver>;
  syncUser?: Maybe<Scalars['Void']>;
  /** Mutation to toggle demand limit for retail demand management */
  toggleDemandLimit: Site;
  unassignCards?: Maybe<Array<Maybe<Card>>>;
  unenrollThirdPartyWallet?: Maybe<Scalars['Void']>;
  updateAccount?: Maybe<Account>;
  updateAccountOwner: Driver;
  updateAccountVehicle?: Maybe<AccountVehicle>;
  updateAdHocContract?: Maybe<AdHocContract>;
  updateCard?: Maybe<Card>;
  updateCardOrders?: Maybe<Array<Maybe<CardOrder>>>;
  updateCardStatus?: Maybe<Card>;
  updateCharger: Charger;
  /** Mutation for updating a charger group plan */
  updateChargerGroupPlanForExtend?: Maybe<ChargerGroupForExtend>;
  updateChargerModel: ChargerModel;
  updateChargerModelConnector?: Maybe<ChargerModelConnector>;
  /** Mutation for updating a charger group and assing chargers to it */
  updateChargerPriceGroupForExtend?: Maybe<ChargerGroupForExtend>;
  updateConnector?: Maybe<Connector>;
  updateConnectorPowerLimit?: Maybe<Connector>;
  /** Mutation to allow updating a connectors ability to be reserved */
  updateConnectorReservable?: Maybe<ConnectorV2>;
  updateContract?: Maybe<Contract>;
  /** This is to update tag and tags_chargers when syncing tag from Driivz through kafka lambda */
  updateDriivzTag?: Maybe<Tag>;
  updateDriver: Driver;
  updateExtendUserHosts?: Maybe<ExtendUser>;
  updateFleetUser: User;
  updateHost: Host;
  updatePassword?: Maybe<Scalars['Void']>;
  updatePlan?: Maybe<Plan>;
  updatePlanDriverRank?: Maybe<DriverRankUpdateResponse>;
  updatePlanTariff?: Maybe<Plan>;
  /** Mutation to update a custom plan tariff */
  updatePlanTariffsForExtend: ChargerGroupForExtend;
  updateProperty: Property;
  updateRewardsUser?: Maybe<Array<Maybe<RewardsUser>>>;
  updateSession?: Maybe<Session>;
  updateSite: Site;
  /** Mutation to update a site */
  updateSiteForExtend: Site;
  updateSiteReex: Site;
  updateTag?: Maybe<Tag>;
  /** Mutation to update a tariff */
  updateTariffForExtend: TariffForExtend;
  updateUser: User;
  updateVehicle?: Maybe<VehicleV2>;
  updateVehicleMake?: Maybe<VehicleMakeV2>;
  updateVehicleModel?: Maybe<VehicleModelV2>;
  upsertCard?: Maybe<Card>;
  upsertCardOrder?: Maybe<CardOrder>;
  upsertExtendHmiDisplayLabels: ExtendHmiDisplayLabels;
  upsertPaymentMethod?: Maybe<Scalars['Void']>;
  upsertSessionMeter?: Maybe<SessionMeter>;
  validateAddress: Address;
  verifyDriverEmail: MandrillResponse;
};

export type MutationActivateCardArgs = {
  input: ActivateCardInput;
};

export type MutationActivateCardForMobileArgs = {
  input: ActivateCardForMobileInput;
};

export type MutationActivateCardsArgs = {
  input: ActivateCardsInput;
};

export type MutationAddChargersToChargerGroupForExtendArgs = {
  input: AddChargersToChargerGroupForExtendInput;
};

export type MutationAssignCardArgs = {
  input?: InputMaybe<AssignCardInput>;
};

export type MutationAssignCardForMobileArgs = {
  input?: InputMaybe<AssignCardForMobileInput>;
};

export type MutationAssignCardsArgs = {
  input: AssignCardsInput;
};

export type MutationAuthenticateUserArgs = {
  input: AuthenticateUserInput;
};

export type MutationCancelAutochargeEnrollmentArgs = {
  input: CancelAutochargeEnrollmentInput;
};

export type MutationCancelInviteDriverArgs = {
  input?: InputMaybe<CancelInviteDriverInput>;
};

export type MutationCancelReservationArgs = {
  input: CancelReservationInput;
};

export type MutationCancelReservationForDriverArgs = {
  input: CancelReservationForDriverInput;
};

export type MutationCheckDriverFirebaseArgs = {
  input: CheckDriverFirebaseInput;
};

export type MutationCheckDriverPhoneNumberArgs = {
  input: CheckDriverPhoneNumberInput;
};

export type MutationCompleteAutochargeEnrollmentArgs = {
  input: CompleteAutochargeEnrollmentInput;
};

export type MutationCreateAccountArgs = {
  input?: InputMaybe<CreateAccountInput>;
};

export type MutationCreateAccountOwnerArgs = {
  input?: InputMaybe<CreateAccountOwnerInput>;
};

export type MutationCreateAccountOwnerWithoutPhoneArgs = {
  input?: InputMaybe<CreateAccountOwnerWithoutPhoneInput>;
};

export type MutationCreateAccountVehicleArgs = {
  input?: InputMaybe<CreateAccountVehicleInput>;
};

export type MutationCreateAdHocContractArgs = {
  input: CreateAdHocContractInput;
};

export type MutationCreateCardArgs = {
  input: CreateCardInput;
};

export type MutationCreateCardOrderArgs = {
  input: CreateCardOrderInput;
};

export type MutationCreateChargerArgs = {
  input?: InputMaybe<CreateChargerInput>;
};

export type MutationCreateChargerGroupPlanForExtendArgs = {
  input: CreateChargerGroupPlanForExtendInput;
};

export type MutationCreateChargerModelArgs = {
  input?: InputMaybe<CreateChargerModelInput>;
};

export type MutationCreateChargerPriceGroupForExtendArgs = {
  input: CreateChargerPriceGroupForExtendInput;
};

export type MutationCreateContactArgs = {
  input?: InputMaybe<CreateContactInput>;
};

export type MutationCreateContractArgs = {
  input: CreateContractInput;
};

export type MutationCreateCreditForUserArgs = {
  input: CreateCreditForUserInput;
};

export type MutationCreateCreditSourceArgs = {
  input: CreateCreditSourceInput;
};

export type MutationCreateDriivzTagArgs = {
  input?: InputMaybe<DriivzTagInput>;
};

export type MutationCreateDriverArgs = {
  input?: InputMaybe<CreateDriverInput>;
};

export type MutationCreateDriverForMobileArgs = {
  input?: InputMaybe<CreateDriverInput>;
};

export type MutationCreateExtendCalculatedHostPlanArgs = {
  planId: Scalars['Int'];
};

export type MutationCreateExtendHostArgs = {
  input: CreateExtendHostInput;
};

export type MutationCreateExtendUserArgs = {
  input?: InputMaybe<CreateExtendUserInput>;
};

export type MutationCreateFleetUserArgs = {
  input: CreateFleetUserInput;
};

export type MutationCreateHostArgs = {
  input?: InputMaybe<CreateHostInput>;
};

export type MutationCreateLogoUploadUrlArgs = {
  input?: InputMaybe<CreateLogoUploadUrlInput>;
};

export type MutationCreatePaymentCardForMobileArgs = {
  input: CreatePaymentCardForMobileInput;
};

export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};

export type MutationCreatePlanTariffArgs = {
  input: PlanTariffInput;
};

export type MutationCreatePlanTariffsForExtendArgs = {
  input: CreatePlanTariffsForExtendInput;
};

export type MutationCreatePropertyArgs = {
  input?: InputMaybe<CreatePropertyInput>;
};

export type MutationCreateReservationArgs = {
  input: CreateReservationInput;
};

export type MutationCreateReservationForDriverArgs = {
  input: CreateReservationForDriverInput;
};

export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};

export type MutationCreateSiteArgs = {
  input?: InputMaybe<CreateSiteInput>;
};

export type MutationCreateTagArgs = {
  input?: InputMaybe<CreateTagInput>;
};

export type MutationCreateTariffConnectorTypeArgs = {
  input: CreateTariffConnectorTypeInput;
};

export type MutationCreateTariffForExtendArgs = {
  input: CreateTariffForExtendInput;
};

export type MutationCreateUploadArgs = {
  input: Scalars['Upload'];
};

export type MutationCreateUploadsArgs = {
  input?: InputMaybe<CreateUploadsInput>;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateVehicleArgs = {
  input: CreateVehicleInput;
};

export type MutationCreateVehicleMakeArgs = {
  input: CreateVehicleMakeInput;
};

export type MutationCreateVehicleModelArgs = {
  input: CreateVehicleModelInput;
};

export type MutationDeactivateCardsArgs = {
  input: DeactivateCardsInput;
};

export type MutationDeactivateChargerGroupPlanForExtendArgs = {
  input: DeactivateChargerGroupPlanForExtendInput;
};

export type MutationDeleteAccountVehicleArgs = {
  input?: InputMaybe<DeleteAccountVehicleInput>;
};

export type MutationDeleteChargerArgs = {
  input?: InputMaybe<DeleteChargerInput>;
};

export type MutationDeleteChargerGroupForExtendArgs = {
  input: DeleteChargerGroupForExtendInput;
};

export type MutationDeleteChargerPriceGroupForExtendArgs = {
  input: DeleteChargerPriceGroupForExtendInput;
};

export type MutationDeleteDriivzTagArgs = {
  input?: InputMaybe<DriivzTagInput>;
};

export type MutationDeleteDriverArgs = {
  input?: InputMaybe<DeleteDriverInput>;
};

export type MutationDeleteExtendCalculatedHostPlanArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteExtendHostArgs = {
  input: DeleteExtendHostInput;
};

export type MutationDeleteExtendUserHostsArgs = {
  input?: InputMaybe<DeleteExtendUserHostsInput>;
};

export type MutationDeleteHostArgs = {
  input?: InputMaybe<DeleteHostInput>;
};

export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};

export type MutationDeletePaymentMethodForMobileArgs = {
  input: DeletePaymentMethodForMobileInput;
};

export type MutationDeletePlanTariffArgs = {
  input: PlanTariffInput;
};

export type MutationDeleteTagArgs = {
  input?: InputMaybe<DeleteTagInput>;
};

export type MutationDeleteTariffForExtendArgs = {
  input: DeleteTariffForExtendInput;
};

export type MutationDeleteVehicleArgs = {
  input: DeleteVehicleInput;
};

export type MutationDeleteVehicleIdCardArgs = {
  input: DeleteVehicleIdCardInput;
};

export type MutationDeleteVehicleMakeArgs = {
  input: DeleteVehicleMakeInput;
};

export type MutationDeleteVehicleModelArgs = {
  input: DeleteVehicleModelInput;
};

export type MutationDisassociateAccountVehicleArgs = {
  input?: InputMaybe<DisassociateAccountVehicleInput>;
};

export type MutationEnrollDriverArgs = {
  input: EnrollDriverInput;
};

export type MutationEnrollExistingUsersArgs = {
  input: EnrollExistingUserInput;
};

export type MutationEnrollThirdPartyWalletArgs = {
  input: ThirdPartyWalletInput;
};

export type MutationEnrollUserInRewardsArgs = {
  input: EnrollUserInRewardsInput;
};

export type MutationHandleAccountEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandleAccountStatusEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandleCardEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandleContractEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandlePaymentMethodEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandleSessionEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationHandleSessionMeterEventArgs = {
  input?: InputMaybe<LambdaEventInput>;
};

export type MutationImportAccountDriversArgs = {
  input?: InputMaybe<ImportAccountOwnerInput>;
};

export type MutationImportAccountOwnerArgs = {
  input?: InputMaybe<ImportAccountOwnerInput>;
};

export type MutationImportAccountVehiclesArgs = {
  input: ImportAccountVehiclesInput;
};

export type MutationImportCardsArgs = {
  input: ImportCardsInput;
};

export type MutationImportContractsArgs = {
  input: ImportContractsInput;
};

export type MutationImportHostArgs = {
  input?: InputMaybe<ImportHostInput>;
};

export type MutationImportPaymentMethodsArgs = {
  input: ImportPaymentMethodsInput;
};

export type MutationImportPropertyArgs = {
  input?: InputMaybe<ImportPropertyInput>;
};

export type MutationImportVehiclesArgs = {
  input: ImportVehiclesInput;
};

export type MutationInviteDriverArgs = {
  input?: InputMaybe<InviteDriverInput>;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationOptInArgs = {
  input?: InputMaybe<OptInInput>;
};

export type MutationOptInPromotionsArgs = {
  input?: InputMaybe<OptInPromotionsInput>;
};

export type MutationReassignCardArgs = {
  input: ReassignCardInput;
};

export type MutationRefreshTagsArgs = {
  input?: InputMaybe<RefreshTagsInput>;
};

export type MutationRegisterAccountOwnerArgs = {
  input?: InputMaybe<RegisterAccountOwnerInput>;
};

export type MutationRemoveChargersFromChargerGroupForExtendArgs = {
  input: RemoveChargersFromChargerGroupForExtendInput;
};

export type MutationRemoveDriverArgs = {
  input?: InputMaybe<RemoveDriverInput>;
};

export type MutationReplaceCardArgs = {
  input: ReplaceCardInput;
};

export type MutationReserveCardsArgs = {
  input: ReserveCardsInput;
};

export type MutationResetChargerForExtendArgs = {
  input: ResetChargerForExtendInput;
};

export type MutationResetDriverPasswordArgs = {
  input?: InputMaybe<ResetDriverPasswordInput>;
};

export type MutationReusePhoneNumberArgs = {
  input: ReusePhoneNumberInput;
};

export type MutationSendCardOrderNotificationsArgs = {
  input: CardOrderNotificationInput;
};

export type MutationSendContractNotificationsArgs = {
  input: ContractExpirationInput;
};

export type MutationSendDisplayTariffArgs = {
  input: Array<SendTariffInput>;
};

export type MutationSendEmailMessageArgs = {
  input?: InputMaybe<SendEmailMessageInput>;
};

export type MutationSendEndSessionTariffArgs = {
  input: Array<SendTariffInput>;
};

export type MutationSendPasswordResetEmailArgs = {
  input?: InputMaybe<SendPasswordResetInput>;
};

export type MutationSendPaymentMethodNotificationsArgs = {
  input: PaymentMethodExpirationInput;
};

export type MutationSendRewardsConfirmationEmailArgs = {
  input: SendRewardsConfirmationEmailInput;
};

export type MutationSendSessionTariffArgs = {
  input: Array<SendTariffInput>;
};

export type MutationSendSetTariffArgs = {
  input: Array<SendTariffInput>;
};

export type MutationSendTransactionSmsArgs = {
  input: SendTransactionSmsInput;
};

export type MutationSessionStartRequestArgs = {
  input: SessionStartInput;
};

export type MutationSetChargingProfileArgs = {
  input?: InputMaybe<SetChargingProfileInput>;
};

export type MutationSetDemandLimitArgs = {
  input: SetDemandLimitInput;
};

export type MutationSetPrimaryCardForMobileArgs = {
  input: SetPrimaryCardForMobileInput;
};

export type MutationStartAutochargeEnrollmentArgs = {
  input: StartAutochargeEnrollmentInput;
};

export type MutationStartChargeArgs = {
  input?: InputMaybe<StartChargeInput>;
};

export type MutationStartChargeForFleetArgs = {
  input?: InputMaybe<StartChargeFleetInput>;
};

export type MutationStartChargeForMobileArgs = {
  input?: InputMaybe<StartChargeForMobileInput>;
};

export type MutationStopChargeArgs = {
  input: StopChargeInput;
};

export type MutationStopChargeForMobileArgs = {
  input: StopChargeForMobileInput;
};

export type MutationSuspendCardsArgs = {
  input: SuspendCardsInput;
};

export type MutationSyncAssignCardArgs = {
  input?: InputMaybe<SyncAssignCardInput>;
};

export type MutationSyncChargerModelConnectorArgs = {
  input?: InputMaybe<SyncConnectorInput>;
};

export type MutationSyncChargerStatusArgs = {
  input?: InputMaybe<SyncChargerStatusInput>;
};

export type MutationSyncConnectorArgs = {
  input?: InputMaybe<SyncConnectorInput>;
};

export type MutationSyncContractArgs = {
  input: SyncContractInput;
};

export type MutationSyncCreateAccountOwnerArgs = {
  input?: InputMaybe<SyncCreateAccountOwnerInput>;
};

export type MutationSyncCreateAdHocContractArgs = {
  input: SyncCreateAdHocContractInput;
};

export type MutationSyncCreateCardOrderArgs = {
  input: SyncCreateCardOrderInput;
};

export type MutationSyncCreateChargerArgs = {
  input?: InputMaybe<SyncCreateChargerInput>;
};

export type MutationSyncCreateChargerModelArgs = {
  input?: InputMaybe<SyncCreateChargerModelInput>;
};

export type MutationSyncCreateContractArgs = {
  input: SyncCreateContractInput;
};

export type MutationSyncCreateDriverArgs = {
  input: SyncDriverInput;
};

export type MutationSyncCreateHostArgs = {
  input?: InputMaybe<SyncCreateHostInput>;
};

export type MutationSyncCreatePropertyArgs = {
  input?: InputMaybe<SyncCreatePropertyInput>;
};

export type MutationSyncCreateSiteArgs = {
  input?: InputMaybe<SyncCreateSiteInput>;
};

export type MutationSyncPasswordResetArgs = {
  input: SyncPasswordResetInput;
};

export type MutationSyncPlanArgs = {
  input: SyncPlanInput;
};

export type MutationSyncSiteArgs = {
  input?: InputMaybe<SyncSiteInput>;
};

export type MutationSyncUpdateAccountArgs = {
  input?: InputMaybe<SyncUpdateAccountInput>;
};

export type MutationSyncUpdateAccountOwnerArgs = {
  input?: InputMaybe<SyncUpdateAccountOwnerInput>;
};

export type MutationSyncUpdateChargerArgs = {
  input?: InputMaybe<SyncUpdateChargerInput>;
};

export type MutationSyncUpdateDriverArgs = {
  input: SyncDriverInput;
};

export type MutationSyncUserArgs = {
  email: Scalars['String'];
  setPhoneNumber?: InputMaybe<Scalars['String']>;
};

export type MutationToggleDemandLimitArgs = {
  input: ToggleDemandLimitInput;
};

export type MutationUnassignCardsArgs = {
  input: UnassignCardsInput;
};

export type MutationUnenrollThirdPartyWalletArgs = {
  input: ThirdPartyWalletInput;
};

export type MutationUpdateAccountArgs = {
  input?: InputMaybe<UpdateAccountInput>;
};

export type MutationUpdateAccountOwnerArgs = {
  input?: InputMaybe<UpdateAccountOwnerInput>;
};

export type MutationUpdateAccountVehicleArgs = {
  input?: InputMaybe<UpdateAccountVehicleInput>;
};

export type MutationUpdateAdHocContractArgs = {
  input: UpdateAdHocContractInput;
};

export type MutationUpdateCardArgs = {
  input: UpdateCardInput;
};

export type MutationUpdateCardOrdersArgs = {
  input: UpdateCardOrdersInput;
};

export type MutationUpdateCardStatusArgs = {
  input?: InputMaybe<UpdateCardStatusInput>;
};

export type MutationUpdateChargerArgs = {
  input?: InputMaybe<UpdateChargerInput>;
};

export type MutationUpdateChargerGroupPlanForExtendArgs = {
  input: UpdateChargerGroupPlanForExtendInput;
};

export type MutationUpdateChargerModelArgs = {
  input?: InputMaybe<UpdateChargerModelInput>;
};

export type MutationUpdateChargerModelConnectorArgs = {
  input?: InputMaybe<UpdateConnectorInput>;
};

export type MutationUpdateChargerPriceGroupForExtendArgs = {
  input: UpdateChargerPriceGroupForExtendInput;
};

export type MutationUpdateConnectorArgs = {
  input?: InputMaybe<UpdateConnectorInput>;
};

export type MutationUpdateConnectorPowerLimitArgs = {
  input?: InputMaybe<UpdateConnectorPowerLimitInput>;
};

export type MutationUpdateConnectorReservableArgs = {
  input?: InputMaybe<UpdateConnectorReservableInput>;
};

export type MutationUpdateContractArgs = {
  input: UpdateContractInput;
};

export type MutationUpdateDriivzTagArgs = {
  input?: InputMaybe<DriivzTagInput>;
};

export type MutationUpdateDriverArgs = {
  input?: InputMaybe<UpdateDriverInput>;
};

export type MutationUpdateExtendUserHostsArgs = {
  input?: InputMaybe<UpdateExtendUserHostsInput>;
};

export type MutationUpdateFleetUserArgs = {
  input: UpdateFleetUserInput;
};

export type MutationUpdateHostArgs = {
  input?: InputMaybe<UpdateHostInput>;
};

export type MutationUpdatePasswordArgs = {
  input?: InputMaybe<UpdatePasswordInput>;
};

export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};

export type MutationUpdatePlanDriverRankArgs = {
  input: Array<DriverRankInput>;
};

export type MutationUpdatePlanTariffArgs = {
  input: PlanTariffInput;
};

export type MutationUpdatePlanTariffsForExtendArgs = {
  input: UpdatePlanTariffsForExtendInput;
};

export type MutationUpdatePropertyArgs = {
  input?: InputMaybe<UpdatePropertyInput>;
};

export type MutationUpdateRewardsUserArgs = {
  input: UpdateRewardsUserInput;
};

export type MutationUpdateSessionArgs = {
  input: UpdateSessionInput;
};

export type MutationUpdateSiteArgs = {
  input?: InputMaybe<UpdateSiteInput>;
};

export type MutationUpdateSiteForExtendArgs = {
  input?: InputMaybe<UpdateSiteForExtendInput>;
};

export type MutationUpdateSiteReexArgs = {
  input: UpdateSiteReexInput;
};

export type MutationUpdateTagArgs = {
  input?: InputMaybe<UpdateTagInput>;
};

export type MutationUpdateTariffForExtendArgs = {
  input: UpdateTariffForExtendInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateVehicleArgs = {
  input: UpdateVehicleInput;
};

export type MutationUpdateVehicleMakeArgs = {
  input: UpdateVehicleMakeInput;
};

export type MutationUpdateVehicleModelArgs = {
  input: UpdateVehicleModelInput;
};

export type MutationUpsertCardArgs = {
  input: UpsertCardInput;
};

export type MutationUpsertCardOrderArgs = {
  input: UpsertCardOrderInput;
};

export type MutationUpsertExtendHmiDisplayLabelsArgs = {
  input: UpsertExtendHmiDisplayLabelsInput;
};

export type MutationUpsertPaymentMethodArgs = {
  input: UpsertPaymentMethodInput;
};

export type MutationUpsertSessionMeterArgs = {
  input: UpsertSessionMeterInput;
};

export type MutationValidateAddressArgs = {
  input?: InputMaybe<ValidateAddressInput>;
};

export type MutationVerifyDriverEmailArgs = {
  input: VerifyDriverEmailInput;
};

export enum NetworkCommunication {
  /** Ethernet */
  Ethernet = 'ETHERNET',
  /** 4G LTE */
  FourGLte = 'FOUR_G_LTE',
  /** NB-IoT */
  NbIot = 'NB_IOT',
  /** Non-Networked */
  NonNetworked = 'NON_NETWORKED',
  /** 3G */
  ThreeG = 'THREE_G',
  /** WiFi */
  Wifi = 'WIFI',
  /** ZigBee */
  Zigbee = 'ZIGBEE',
}

export type NetworkOperator = {
  __typename?: 'NetworkOperator';
  hostId?: Maybe<Scalars['Int']>;
  hostName?: Maybe<Scalars['String']>;
};

/** An object containing the id and name of the network */
export type NetworkOperatorType = {
  __typename?: 'NetworkOperatorType';
  networkOperatorId?: Maybe<Scalars['Int']>;
  networkOperatorName?: Maybe<Scalars['String']>;
};

export type NetworkOperatorWithMeta = {
  __typename?: 'NetworkOperatorWithMeta';
  edges?: Maybe<Array<Maybe<NetworkOperator>>>;
  total?: Maybe<Scalars['Int']>;
};

export type NotificationGroup = {
  __typename?: 'NotificationGroup';
  groupName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  notificationItems?: Maybe<NotificationItemsWithMeta>;
  sortOrder?: Maybe<Scalars['String']>;
};

export type NotificationGroupNotificationItemsArgs = {
  input?: InputMaybe<ListNotificationItemsInput>;
};

export type NotificationGroupsWithMeta = {
  __typename?: 'NotificationGroupsWithMeta';
  edges?: Maybe<Array<Maybe<NotificationGroup>>>;
  filter?: Maybe<ListNotificationGroupsFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListNotificationGroupsSearchType>;
  sort?: Maybe<ListNotificationGroupsSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type NotificationItem = {
  __typename?: 'NotificationItem';
  driverLevel?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  notificationDesc?: Maybe<Scalars['String']>;
  notificationGroup?: Maybe<NotificationGroup>;
  notificationName?: Maybe<Scalars['String']>;
  onePrefRequired?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Scalars['Int']>;
};

export type NotificationItemsWithMeta = {
  __typename?: 'NotificationItemsWithMeta';
  edges?: Maybe<Array<Maybe<NotificationItem>>>;
  filter?: Maybe<ListNotificationItemsFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListNotificationItemsSearchType>;
  sort?: Maybe<ListNotificationItemsSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  createdAt?: Maybe<Scalars['Date']>;
  driverId?: Maybe<Scalars['ID']>;
  emailOn?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  notificationItemId?: Maybe<Scalars['ID']>;
  pushOn?: Maybe<Scalars['Boolean']>;
  smsOn?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type NotificationPreferencesWithMeta = {
  __typename?: 'NotificationPreferencesWithMeta';
  edges?: Maybe<Array<Maybe<NotificationPreference>>>;
  filter?: Maybe<ListNotificationPreferencesFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListNotificationPreferencesSearchType>;
  sort?: Maybe<ListNotificationPreferencesSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type OptIn = {
  __typename?: 'OptIn';
  /** @deprecated Cleanup */
  insert_id?: Maybe<Scalars['Int']>;
};

export type OptInInput = {
  email?: InputMaybe<Scalars['String']>;
  mobile_phone?: InputMaybe<Scalars['String']>;
  promotion_box?: InputMaybe<Scalars['Int']>;
  promotion_copy?: InputMaybe<Scalars['String']>;
};

export type OptInPromotions = {
  __typename?: 'OptInPromotions';
  insertId?: Maybe<Scalars['Int']>;
};

export type OptInPromotionsInput = {
  /** The email address for the user opting to receive promotional communications */
  email?: InputMaybe<Scalars['String']>;
  /** The phone number for the user opting to receive promotional communications */
  mobilePhone?: InputMaybe<Scalars['String']>;
  /** A number indicating whether the user opts in or out (1 = opt-in and 0 = opt-out) */
  promotionBox?: InputMaybe<Scalars['Int']>;
  /** A string describing the subscription terms for the promotional communications */
  promotionCopy?: InputMaybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  account?: Maybe<Account>;
  altId?: Maybe<Scalars['ID']>;
  cardNumber?: Maybe<Scalars['String']>;
  expired?: Maybe<Scalars['Boolean']>;
  expiryMonth?: Maybe<Scalars['Int']>;
  expiryYear?: Maybe<Scalars['Int']>;
  nameOnCard?: Maybe<Scalars['String']>;
  paymentMethodType?: Maybe<FalconConstraint>;
  primary?: Maybe<Scalars['Boolean']>;
};

export type PaymentMethodExpirationInput = {
  monthsFromExp: Scalars['Int'];
};

export type PaymentMethodsWithMeta = {
  __typename?: 'PaymentMethodsWithMeta';
  edges?: Maybe<Array<Maybe<PaymentMethod>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type PdfStatement = {
  __typename?: 'PdfStatement';
  /** @deprecated Use statement instead. */
  base64statement?: Maybe<Scalars['String']>;
  /** A buffer string encoded to base64 (e.g. 'JVBERi0xLjQKJeLjz9M...') */
  statement?: Maybe<Scalars['String']>;
  /** @deprecated Use statement instead. */
  url?: Maybe<Scalars['String']>;
};

export type PhoneNumberCheck = {
  __typename?: 'PhoneNumberCheck';
  associatedEmail?: Maybe<Scalars['String']>;
  phoneNumberAvailable?: Maybe<Scalars['Boolean']>;
};

export type Plan = {
  __typename?: 'Plan';
  allowRoaming?: Maybe<Scalars['Boolean']>;
  altId?: Maybe<Scalars['ID']>;
  badge?: Maybe<Scalars['SanitizeString']>;
  balanceType?: Maybe<BalanceType>;
  /** Id of the balance type from falcon_constraints to use for this plan. */
  balanceTypeId?: Maybe<Scalars['Int']>;
  cardBrand?: Maybe<Scalars['SanitizeString']>;
  cardBrandId?: Maybe<Scalars['Int']>;
  /** @deprecated This is not a one to one relationship.  Use contracts. */
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  costFactor?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['Date']>;
  ctaLabel?: Maybe<Scalars['SanitizeString']>;
  defaultAccountPlan?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['Date']>;
  detailHeading?: Maybe<Scalars['SanitizeString']>;
  detailSections?: Maybe<Array<Maybe<DetailSection>>>;
  detailSessionTimes?: Maybe<Array<Maybe<DetailSessionTime>>>;
  displayPlanCard?: Maybe<Scalars['Boolean']>;
  displayToCustomer?: Maybe<Scalars['Boolean']>;
  displayToLoggedOutUser?: Maybe<Scalars['Boolean']>;
  driverRank?: Maybe<Scalars['Int']>;
  elements?: Maybe<ElementsWithMeta>;
  enrollmentClose?: Maybe<Scalars['Date']>;
  enrollmentOpen?: Maybe<Scalars['Date']>;
  financeCode?: Maybe<Scalars['String']>;
  intervalLength?: Maybe<Scalars['Int']>;
  intervalUnit?: Maybe<Scalars['String']>;
  intervalUnitId?: Maybe<Scalars['Int']>;
  isAutoEnrolledInRewards?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  legalese?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  planCode?: Maybe<Scalars['SanitizeString']>;
  planDescription?: Maybe<Scalars['SanitizeString']>;
  planDiscount?: Maybe<Scalars['Float']>;
  planDisplayName?: Maybe<Scalars['SanitizeString']>;
  planDuration?: Maybe<Scalars['Int']>;
  planName?: Maybe<Scalars['SanitizeString']>;
  planStatus?: Maybe<Scalars['String']>;
  planStatusId?: Maybe<Scalars['Int']>;
  planSubheading?: Maybe<Scalars['SanitizeString']>;
  products?: Maybe<ProductsWithMeta>;
  recurringCost?: Maybe<Scalars['Float']>;
  rolloverPlan?: Maybe<Plan>;
  sortOrder?: Maybe<Scalars['Int']>;
  tariffs?: Maybe<TariffWithMeta>;
  template?: Maybe<Scalars['SanitizeString']>;
  templateId?: Maybe<Scalars['Int']>;
  terminationBehavior?: Maybe<Scalars['String']>;
  terminationBehaviorId?: Maybe<Scalars['Int']>;
  touRegionWithPricing?: Maybe<Array<Maybe<TouRegionWithPricing>>>;
  touRegionWithRangePricing?: Maybe<TouRegionWithRangePricing>;
  vendorId?: Maybe<Scalars['ID']>;
  vinRequiredFlag?: Maybe<Scalars['Boolean']>;
};

export type PlanContractsArgs = {
  altId?: InputMaybe<Scalars['ID']>;
};

export type PlanElementsArgs = {
  input?: InputMaybe<ListElementsInput>;
};

export type PlanProductsArgs = {
  input?: InputMaybe<ListProductsInput>;
};

export type PlanTariffsArgs = {
  input?: InputMaybe<ListTariffsInput>;
};

export type PlanTouRegionWithPricingArgs = {
  input?: InputMaybe<TouRegionWithPricingInput>;
};

export type PlanTouRegionWithRangePricingArgs = {
  input?: InputMaybe<TouRegionWithRangePricingInput>;
};

export type PlanCredit = {
  __typename?: 'PlanCredit';
  altId?: Maybe<Scalars['ID']>;
  creditAmount?: Maybe<Scalars['Float']>;
  creditExpPolicy?: Maybe<Scalars['String']>;
  creditExpPolicyId?: Maybe<Scalars['Int']>;
  creditReoccurrence?: Maybe<Scalars['String']>;
  creditReoccurrenceId?: Maybe<Scalars['Int']>;
  oneTimeCreditAmount?: Maybe<Scalars['Float']>;
};

export type PlanCreditInput = {
  altId?: InputMaybe<Scalars['ID']>;
  creditAmount?: InputMaybe<Scalars['Float']>;
  creditExpPolicyId?: InputMaybe<Scalars['Int']>;
  creditReoccurrenceId?: InputMaybe<Scalars['Int']>;
  oneTimeCreditAmount?: InputMaybe<Scalars['Float']>;
};

export type PlanEnrollmentsForExtend = {
  __typename?: 'PlanEnrollmentsForExtend';
  planName: Scalars['String'];
  /** Total number of contracts assigned to plan */
  totalEnrollments: Scalars['Int'];
};

export enum PlanStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE',
}

export type PlanSubscription = {
  __typename?: 'PlanSubscription';
  altId?: Maybe<Scalars['ID']>;
  subscriptionFee?: Maybe<Scalars['Float']>;
  subscriptionReoccurrence?: Maybe<Scalars['String']>;
  subscriptionReoccurrenceId?: Maybe<Scalars['Int']>;
};

export type PlanSubscriptionInput = {
  altId?: InputMaybe<Scalars['ID']>;
  subscriptionFee: Scalars['Float'];
  subscriptionReoccurrence?: InputMaybe<Scalars['String']>;
  subscriptionReoccurrenceId?: InputMaybe<Scalars['Int']>;
};

export type PlanTariffForExtend = {
  __typename?: 'PlanTariffForExtend';
  altId: Scalars['ID'];
  chargerGroups: Array<ChargerGroupForExtend>;
  customPricing?: Maybe<Array<TariffCustomPricingForExtend>>;
  tariffDescription?: Maybe<Scalars['SanitizeString']>;
  tariffName: Scalars['SanitizeString'];
  tariffRank?: Maybe<Scalars['Int']>;
};

export type PlanTariffInput = {
  vendorId: Scalars['ID'];
};

export type PlanTariffTag = {
  __typename?: 'PlanTariffTag';
  plan?: Maybe<Plan>;
  planChargerGroup?: Maybe<ChargerGroupForExtend>;
};

export type PlanV2 = {
  __typename?: 'PlanV2';
  altId?: Maybe<Scalars['ID']>;
  chargerGroup?: Maybe<ChargerGroupForExtend>;
  planCode?: Maybe<Scalars['String']>;
  planDescription?: Maybe<Scalars['String']>;
  planDisplayName?: Maybe<Scalars['String']>;
  planName?: Maybe<Scalars['String']>;
  planStatus?: Maybe<FalconConstraint>;
  planSubheading?: Maybe<Scalars['String']>;
};

export type PlanWithMeta = {
  __typename?: 'PlanWithMeta';
  edges?: Maybe<Array<Maybe<Plan>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListPlansSearchType>;
  sort?: Maybe<ListPlansSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type Port = {
  __typename?: 'Port';
  accessType?: Maybe<Scalars['String']>;
  altId?: Maybe<Scalars['ID']>;
  chargeBoxIdentity?: Maybe<Scalars['String']>;
  concurrentChargingSupported?: Maybe<Scalars['Boolean']>;
  connectors?: Maybe<ConnectorsWithMeta>;
  connectors2?: Maybe<ConnectorsWithMeta>;
  evcId?: Maybe<Scalars['String']>;
  handicapAccessible?: Maybe<Scalars['Boolean']>;
  lastHeartbeat?: Maybe<Scalars['String']>;
  legacyId?: Maybe<Scalars['Int']>;
  powerLevel?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['String']>;
};

export type PortConnectorsArgs = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type PortConnectors2Args = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type PortFilterInput = {
  powerLevel?: InputMaybe<TableStringFilterInput>;
};

export type PortFilterType = {
  __typename?: 'PortFilterType';
  powerLevel?: Maybe<TableStringFilterType>;
};

export type PortInput = {
  accessType?: InputMaybe<Scalars['SanitizeString']>;
  altId?: InputMaybe<Scalars['ID']>;
  chargeBoxIdentity?: InputMaybe<Scalars['SanitizeString']>;
  concurrentChargingSupported?: InputMaybe<Scalars['Boolean']>;
  connectors: Array<InputMaybe<ConnectorInput>>;
  evcId?: InputMaybe<Scalars['SanitizeString']>;
  handicapAccessible?: InputMaybe<Scalars['Boolean']>;
  lastHeartbeat?: InputMaybe<Scalars['SanitizeString']>;
  legacyId?: InputMaybe<Scalars['SanitizeString']>;
  phone?: InputMaybe<Scalars['SanitizeString']>;
  powerLevel?: InputMaybe<Scalars['SanitizeString']>;
  qrCode?: InputMaybe<Scalars['SanitizeString']>;
};

export type PortsWithMeta = {
  __typename?: 'PortsWithMeta';
  edges?: Maybe<Array<Maybe<Port>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export enum PowerLevel {
  /** Level 1 */
  Level_1 = 'LEVEL_1',
  /** Level 2 */
  Level_2 = 'LEVEL_2',
  /** Level 3 */
  Level_3 = 'LEVEL_3',
  /** Unknown */
  Unknown = 'UNKNOWN',
}

export type PowerMeterValue = {
  __typename?: 'PowerMeterValue';
  powerMeterValueInWatts?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['Date']>;
};

export type Price = {
  __typename?: 'Price';
  billingPlanCode?: Maybe<Scalars['String']>;
  billingPlanDescription?: Maybe<Scalars['String']>;
  billingPlanId?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fixed?: Maybe<Scalars['Float']>;
  initialFreeTime?: Maybe<Scalars['Int']>;
  kwh?: Maybe<Scalars['Float']>;
  perHour?: Maybe<Scalars['Float']>;
  perMinute?: Maybe<Scalars['Float']>;
  plugInPerHour?: Maybe<Scalars['Float']>;
  plugInPerMinute?: Maybe<Scalars['Float']>;
  reservation?: Maybe<Scalars['Float']>;
  transaction?: Maybe<Scalars['Float']>;
};

export type PricingForExtend = {
  __typename?: 'PricingForExtend';
  altId: Scalars['ID'];
  perKwh?: Maybe<Scalars['TariffPrice']>;
  perMinute?: Maybe<Scalars['TariffPrice']>;
  perSession?: Maybe<Scalars['TariffPrice']>;
};

export type Product = {
  __typename?: 'Product';
  altId?: Maybe<Scalars['ID']>;
  credit?: Maybe<PlanCredit>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
  productTypeId?: Maybe<Scalars['Int']>;
  subscription?: Maybe<PlanSubscription>;
};

export type ProductInput = {
  altId?: InputMaybe<Scalars['ID']>;
  credit?: InputMaybe<PlanCreditInput>;
  description?: InputMaybe<Scalars['SanitizeString']>;
  name?: InputMaybe<Scalars['SanitizeString']>;
  productTypeId?: InputMaybe<Scalars['Int']>;
  subscription?: InputMaybe<PlanSubscriptionInput>;
};

export type ProductsWithMeta = {
  __typename?: 'ProductsWithMeta';
  edges?: Maybe<Array<Maybe<Product>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListProductsSearchType>;
  sort?: Maybe<ListProductsSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type PromotionForMobile = {
  __typename?: 'PromotionForMobile';
  code: Scalars['String'];
  plan?: Maybe<Plan>;
  planCode?: Maybe<Scalars['String']>;
};

export type PropertiesWithMeta = {
  __typename?: 'PropertiesWithMeta';
  edges?: Maybe<Array<Maybe<Property>>>;
  filter?: Maybe<ListPropertiesFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListPropertiesSearchType>;
  sort?: Maybe<ListPropertiesSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type Property = {
  __typename?: 'Property';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  altId: Scalars['ID'];
  country?: Maybe<Scalars['String']>;
  host?: Maybe<Host>;
  latitude?: Maybe<Scalars['Float']>;
  legacyId?: Maybe<Scalars['ID']>;
  locality?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  pid?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  propertyName?: Maybe<Scalars['String']>;
  propertyStatus?: Maybe<FalconConstraint>;
  sites?: Maybe<SitesWithMeta>;
  /** @deprecated Use propertyStatus */
  status?: Maybe<PropertyStatus>;
  timezone?: Maybe<Timezone>;
  vendorId?: Maybe<Scalars['String']>;
};

export type PropertySitesArgs = {
  input?: InputMaybe<ListSitesInput>;
};

export type PropertyFilterInput = {
  pid?: InputMaybe<TableStringFilterInput>;
  propertyName?: InputMaybe<TableStringFilterInput>;
};

export type PropertyFilterType = {
  __typename?: 'PropertyFilterType';
  pid?: Maybe<TableStringFilterType>;
  propertyName?: Maybe<TableStringFilterType>;
};

export enum PropertyStatus {
  /** Contract Expired */
  ContractExpired = 'CONTRACT_EXPIRED',
  /** Contract Terminated */
  ContractTerminated = 'CONTRACT_TERMINATED',
  /** Decommissioned */
  Decommissioned = 'DECOMMISSIONED',
  /** Denetworked */
  Denetworked = 'DENETWORKED',
  /** Planned */
  Planned = 'PLANNED',
  Provisioned = 'PROVISIONED',
  /** Ready For Provisioning */
  ReadyForProvisioning = 'READY_FOR_PROVISIONING',
  /** Scheduled For Decommissioning */
  ScheduledForDecommissioning = 'SCHEDULED_FOR_DECOMMISSIONING',
}

export type Publish = {
  type: Scalars['String'];
  typeName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  chargersForMobile?: Maybe<ChargersForMobileWithMeta>;
  getAccount?: Maybe<Account>;
  getAccountVehicle?: Maybe<AccountVehicle>;
  getAccountVehicleByVin?: Maybe<AccountVehicle>;
  getAccountVehicleVinStatus: AccountVehicleVinStatus;
  getActiveSession?: Maybe<Session>;
  /** Query to return summary data for active sessions at the given sites */
  getActiveSessionsTotals?: Maybe<ActiveSessionsTotals>;
  getAdHocContract?: Maybe<AdHocContract>;
  getAggregatedPowerMeterValuesForFleet?: Maybe<AggregatedMeterValues>;
  getCard?: Maybe<Card>;
  getCharger?: Maybe<Charger>;
  getChargerAggregatedPowerMeterValuesForFleet?: Maybe<ChargerAggregateMeterValues>;
  /** Query to get a Charger Group by altId */
  getChargerGroupForExtend?: Maybe<ChargerGroupForExtend>;
  getChargerModel?: Maybe<ChargerModel>;
  getChargerModelConnector?: Maybe<ChargerModelConnector>;
  getChargerPricingForMobile?: Maybe<ChargerPrice>;
  getChargerUtilizationChartData?: Maybe<ChargerUtilizationChartData>;
  /** @deprecated use getCharger and request energyUtilization field */
  getChargerWithEnergyUtilization?: Maybe<Charger>;
  getConnector?: Maybe<Connector>;
  getContract?: Maybe<Contract>;
  getCoupon?: Maybe<Coupon>;
  getCurrentDriver?: Maybe<Driver>;
  getCurrentUser: CurrentUserResponse;
  getDriver?: Maybe<Driver>;
  getEvgoAccessQRCode?: Maybe<EvgoAccessQrCode>;
  getEvgoSitesForMobile?: Maybe<SitesForMobileWithMeta>;
  /** Get calculated plans for current extend host */
  getExtendCalculatedHostPlans: Array<ExtendCalculatedHostPlan>;
  getExtendUser?: Maybe<ExtendUser>;
  getFirebaseUserForExtend: FirebaseUserForExtend;
  getFleetUser: User;
  getHost?: Maybe<Host>;
  /** Get a single invoice for the current user based on a given invoiceId */
  getInvoiceForMobile?: Maybe<Invoice>;
  getPlan?: Maybe<Plan>;
  getPlanEnrollmentsForExtend: Array<PlanEnrollmentsForExtend>;
  getPlanForMobile?: Maybe<Plan>;
  getPort?: Maybe<Port>;
  getProperty?: Maybe<Property>;
  getRewardsTotalForMobile?: Maybe<Rewards>;
  getRewardsUser?: Maybe<RewardsUser>;
  getRoamingSitesForMobile?: Maybe<SitesForMobileWithMeta>;
  getSalesforceHost?: Maybe<SalesforceHost>;
  getSalesforceProperty?: Maybe<SalesforceProperty>;
  getSession?: Maybe<Session>;
  getSessionForFleet?: Maybe<Session>;
  getSessionSummaryByPlan?: Maybe<Array<SessionSummaryByPlan>>;
  getSessionValidationForMobile?: Maybe<SessionValidation>;
  /** Query to return a timestamp between the date interval or date preset sent, summing the consumption and income of chargers */
  getSessionsConsumptionForExtend?: Maybe<GetSessionsConsumptionForExtendResponse>;
  getSite?: Maybe<Site>;
  /** Query to get a site by altId */
  getSiteForExtend?: Maybe<Site>;
  getSiteForMobile?: Maybe<Site>;
  /** Query to get a list of sites */
  getSitesForExtend?: Maybe<Array<Maybe<Site>>>;
  getStatement?: Maybe<PdfStatement>;
  getStatementCurrent?: Maybe<PdfStatement>;
  getTag?: Maybe<Tag>;
  /** Gets a single TariffForExtend type */
  getTariffForExtend: TariffForExtend;
  getTariffLogs: Array<TariffLog>;
  getTransaction?: Maybe<Transaction>;
  getTransactionForMobile?: Maybe<MobileTransaction>;
  getVehicleByVin?: Maybe<Vehicle>;
  getWarehouse?: Maybe<Warehouse>;
  listAccountVehicles?: Maybe<AccountVehiclesWithMeta>;
  listAccounts?: Maybe<AccountsWithMeta>;
  listAvailableSlots?: Maybe<Array<Maybe<TimeSlot>>>;
  listCardOrders?: Maybe<CardOrdersWithMeta>;
  listCardOrdersForMobile?: Maybe<CardOrdersWithMeta>;
  listCards?: Maybe<CardsWithMeta>;
  listCardsForMobile?: Maybe<CardsWithMeta>;
  /** Query to list charger groups */
  listChargerGroupsForExtend?: Maybe<ChargerGroupsForExtendWithMeta>;
  listChargerModelConnectors?: Maybe<ChargerModelConnectorsWithMeta>;
  listChargerModelOptions?: Maybe<Array<Maybe<ChargerModel>>>;
  listChargerModelPorts?: Maybe<ChargerModelPortsWithMeta>;
  listChargerModels?: Maybe<ChargerModelsWithMeta>;
  listChargerUtilization?: Maybe<ListChargerUtilization>;
  listChargers?: Maybe<ChargersWithMeta>;
  /** Query to return a list of chargers, by sites and their consumption data by date preset */
  listChargersConsumptionForExtend?: Maybe<Array<ChargersConsumptionForExtendData>>;
  listChargersForFleet?: Maybe<ChargersForFleetMeta>;
  listChargersForMobile?: Maybe<ChargersWithMeta>;
  /** Dynamic list chargers query */
  listChargersV2?: Maybe<ChargersV2WithMeta>;
  listConnectorAssociations?: Maybe<Array<Maybe<ConnectorAssociation>>>;
  listConnectors?: Maybe<ConnectorsWithMeta>;
  listConnectors2?: Maybe<ConnectorsWithMeta>;
  listContacts?: Maybe<ContactsWithMeta>;
  listContracts?: Maybe<ContractsWithMeta>;
  listCredits?: Maybe<CreditsWithMeta>;
  listDrivers?: Maybe<DriversWithMeta>;
  listElectricVehicles?: Maybe<ElectricVehiclesWithMeta>;
  listExtendHosts?: Maybe<ExtendHostsWithMeta>;
  listExtendUsers?: Maybe<ExtendUsersWithMeta>;
  listFalconConstraints?: Maybe<FalconConstraintsWithMeta>;
  listGeographicalRegions?: Maybe<GeographicalRegionsWithMeta>;
  listHosts?: Maybe<HostsWithMeta>;
  listHostsForExtend?: Maybe<HostsWithMeta>;
  /** Get a list of invoices for the current user within a given date range, with the optional ability to limit results by cardId */
  listInvoicesForMobile?: Maybe<InvoicesWithMeta>;
  /** A query that fetches a list of partner network operators to populate filters on client */
  listNetworkOperators?: Maybe<NetworkOperatorWithMeta>;
  listNotificationGroups?: Maybe<NotificationGroupsWithMeta>;
  listPaymentMethodsForMobile?: Maybe<PaymentMethodsWithMeta>;
  listPlans?: Maybe<PlanWithMeta>;
  listPlansForMobile?: Maybe<PlanWithMeta>;
  listPorts?: Maybe<PortsWithMeta>;
  listPorts2?: Maybe<PortsWithMeta>;
  listProperties?: Maybe<PropertiesWithMeta>;
  listReservationTerms?: Maybe<ReservationTermsWithMeta>;
  listReservations?: Maybe<ReservationsWithMeta>;
  /** list reservations for the current authenticated driver */
  listReservationsByDriver?: Maybe<ReservationsWithMeta>;
  listSalesforceHostContacts?: Maybe<SalesforceHostContactsWithMeta>;
  listSalesforceHosts?: Maybe<SalesforceHostsWithMeta>;
  listSalesforceProperties?: Maybe<SalesforcePropertiesWithMeta>;
  listSessions?: Maybe<SessionsWithMeta>;
  listSessionsV2?: Maybe<SessionsV2WithMeta>;
  listSiteImages?: Maybe<SiteImagesWithMeta>;
  listSiteOptions?: Maybe<Array<Maybe<Site>>>;
  listSites?: Maybe<SitesWithMeta>;
  listSitesConsumptionForExtend: ListSitesConsumptionForExtendWithMeta;
  listSitesForMobile?: Maybe<SitesWithMeta>;
  listStatements?: Maybe<StatementsWithMeta>;
  listTags?: Maybe<TagsWithMeta>;
  listTariffConnectorTypes?: Maybe<TariffConnectorTypesWithMeta>;
  /** Query to fetch all tariffs for a partner admins host(s) */
  listTariffsForExtend: TariffsForExtendWithMeta;
  listTouRegions?: Maybe<Array<Maybe<TouRegion>>>;
  listTransactions?: Maybe<TransactionsWithMeta>;
  listTransactionsForMobile?: Maybe<MobileTransactionsWithMeta>;
  listTransactionsV2?: Maybe<TransactionsV2WithMeta>;
  listUnassociatedChargersForFleet?: Maybe<UnassociatedChargersForFleetWithMeta>;
  listUploads?: Maybe<UploadsWithMeta>;
  listVehicleConnectors?: Maybe<VehicleConnectorsWithMeta>;
  listVehicleMakes?: Maybe<Array<Maybe<VehicleMakeV2>>>;
  listVehicleModels?: Maybe<Array<Maybe<VehicleModelV2>>>;
  listVehicles?: Maybe<VehiclesWithMeta>;
  listVehiclesV2?: Maybe<Array<Maybe<VehicleV2>>>;
  listWarehouseOptions?: Maybe<Array<Maybe<Warehouse>>>;
  listWarehouses?: Maybe<WarehousesWithMeta>;
  root?: Maybe<Scalars['SanitizeString']>;
  searchFirebaseAndDriivzAndFalconAccounts?: Maybe<AccountSearchResult>;
  siteForMobile?: Maybe<SiteForMobile>;
  /** @deprecated No longer supported. */
  sitesForMobile?: Maybe<SitesForMobileWithMeta>;
  sitesStatusForMobile?: Maybe<Array<SitesStatusForMobile>>;
  validateTag?: Maybe<Tag>;
};

export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};

export type QueryChargersForMobileArgs = {
  input?: InputMaybe<ChargersForMobileInput>;
};

export type QueryGetAccountArgs = {
  input?: InputMaybe<GetAccountInput>;
};

export type QueryGetAccountVehicleArgs = {
  input?: InputMaybe<GetAccountVehicleInput>;
};

export type QueryGetAccountVehicleByVinArgs = {
  input: GetAccountVehicleByVinInput;
};

export type QueryGetAccountVehicleVinStatusArgs = {
  input: GetAccountVehicleVinStatusInput;
};

export type QueryGetActiveSessionArgs = {
  input: GetActiveSessionInput;
};

export type QueryGetActiveSessionsTotalsArgs = {
  input: ActiveSessionsTotalsInput;
};

export type QueryGetAdHocContractArgs = {
  input: GetAdHocContractInput;
};

export type QueryGetAggregatedPowerMeterValuesForFleetArgs = {
  input: GetAggregatedPowerMeterValuesForFleetInput;
};

export type QueryGetCardArgs = {
  input?: InputMaybe<GetCardInput>;
};

export type QueryGetChargerArgs = {
  input?: InputMaybe<GetChargerInput>;
};

export type QueryGetChargerAggregatedPowerMeterValuesForFleetArgs = {
  input?: InputMaybe<GetChargerAggregatedPowerMeterValuesForFleetInput>;
};

export type QueryGetChargerGroupForExtendArgs = {
  input: GetChargerGroupForExtendInput;
};

export type QueryGetChargerModelArgs = {
  input?: InputMaybe<GetChargerModelInput>;
};

export type QueryGetChargerModelConnectorArgs = {
  input?: InputMaybe<GetConnectorInput>;
};

export type QueryGetChargerPricingForMobileArgs = {
  input?: InputMaybe<GetChargerPricingForMobileInput>;
};

export type QueryGetChargerUtilizationChartDataArgs = {
  input: ChargerUtilizationChartInput;
};

export type QueryGetChargerWithEnergyUtilizationArgs = {
  input?: InputMaybe<GetChargerInput>;
};

export type QueryGetConnectorArgs = {
  input?: InputMaybe<GetConnectorInput>;
};

export type QueryGetContractArgs = {
  input: GetContractInput;
};

export type QueryGetCouponArgs = {
  input?: InputMaybe<GetCouponInput>;
};

export type QueryGetDriverArgs = {
  input?: InputMaybe<GetDriverInput>;
};

export type QueryGetEvgoAccessQrCodeArgs = {
  input?: InputMaybe<GetEvgoAccessQrCodeInput>;
};

export type QueryGetEvgoSitesForMobileArgs = {
  input: EvgoSitesForMobileInput;
};

export type QueryGetExtendUserArgs = {
  input?: InputMaybe<GetExtendUserInput>;
};

export type QueryGetFirebaseUserForExtendArgs = {
  input: GetFirebaseUserForExtendInput;
};

export type QueryGetHostArgs = {
  input?: InputMaybe<GetHostInput>;
};

export type QueryGetInvoiceForMobileArgs = {
  input?: InputMaybe<GetInvoiceInput>;
};

export type QueryGetPlanArgs = {
  input?: InputMaybe<GetPlanInput>;
};

export type QueryGetPlanForMobileArgs = {
  input?: InputMaybe<GetPlanForMobileInput>;
};

export type QueryGetPortArgs = {
  input?: InputMaybe<GetPortInput>;
};

export type QueryGetPropertyArgs = {
  input?: InputMaybe<GetPropertyInput>;
};

export type QueryGetRewardsUserArgs = {
  input: GetRewardsUserInput;
};

export type QueryGetRoamingSitesForMobileArgs = {
  input: RoamingSitesForMobileInput;
};

export type QueryGetSalesforceHostArgs = {
  input?: InputMaybe<GetSalesforceHostInput>;
};

export type QueryGetSalesforcePropertyArgs = {
  input?: InputMaybe<GetSalesforcePropertyInput>;
};

export type QueryGetSessionArgs = {
  input: GetSessionInput;
};

export type QueryGetSessionForFleetArgs = {
  input: GetSessionForFleetInput;
};

export type QueryGetSessionSummaryByPlanArgs = {
  input?: InputMaybe<GetSessionSummaryByPlanInput>;
};

export type QueryGetSessionValidationForMobileArgs = {
  input?: InputMaybe<GetSessionValidationForMobileInput>;
};

export type QueryGetSessionsConsumptionForExtendArgs = {
  input: GetSessionsConsumptionForExtendInput;
};

export type QueryGetSiteArgs = {
  input?: InputMaybe<GetSiteInput>;
};

export type QueryGetSiteForExtendArgs = {
  input?: InputMaybe<GetSiteForExtendInput>;
};

export type QueryGetSiteForMobileArgs = {
  input?: InputMaybe<GetSiteInput>;
};

export type QueryGetStatementArgs = {
  input: GetStatementInput;
};

export type QueryGetStatementCurrentArgs = {
  input?: InputMaybe<GetStatementCurrentInput>;
};

export type QueryGetTagArgs = {
  input?: InputMaybe<GetTagInput>;
};

export type QueryGetTariffForExtendArgs = {
  input: GetTariffForExtendInput;
};

export type QueryGetTariffLogsArgs = {
  input: GetTariffLogsInput;
};

export type QueryGetTransactionArgs = {
  input: GetTransactionInput;
};

export type QueryGetTransactionForMobileArgs = {
  input: GetTransactionInput;
};

export type QueryGetVehicleByVinArgs = {
  input: GetVehicleByVinInput;
};

export type QueryGetWarehouseArgs = {
  input?: InputMaybe<GetWarehouseInput>;
};

export type QueryListAccountVehiclesArgs = {
  input?: InputMaybe<ListAccountVehiclesInput>;
};

export type QueryListAccountsArgs = {
  input?: InputMaybe<ListAccountsInput>;
};

export type QueryListAvailableSlotsArgs = {
  input?: InputMaybe<ListReservationsInput>;
};

export type QueryListCardOrdersArgs = {
  input?: InputMaybe<ListCardOrdersInput>;
};

export type QueryListCardOrdersForMobileArgs = {
  input?: InputMaybe<ListCardOrdersForMobileInput>;
};

export type QueryListCardsArgs = {
  input?: InputMaybe<ListCardsInput>;
};

export type QueryListCardsForMobileArgs = {
  input?: InputMaybe<ListCardsInput>;
};

export type QueryListChargerGroupsForExtendArgs = {
  input?: InputMaybe<ListChargerGroupsForExtendInput>;
};

export type QueryListChargerModelConnectorsArgs = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type QueryListChargerModelPortsArgs = {
  input?: InputMaybe<ListPortsInput>;
};

export type QueryListChargerModelsArgs = {
  input?: InputMaybe<ListChargerModelsInput>;
};

export type QueryListChargerUtilizationArgs = {
  input?: InputMaybe<ListChargerUtilizationInput>;
};

export type QueryListChargersArgs = {
  input?: InputMaybe<ListChargersInput>;
};

export type QueryListChargersConsumptionForExtendArgs = {
  input: ListChargersConsumptionForExtendInput;
};

export type QueryListChargersForFleetArgs = {
  input?: InputMaybe<ListChargersForFleetInput>;
};

export type QueryListChargersForMobileArgs = {
  input?: InputMaybe<ListChargersForMobileInput>;
};

export type QueryListChargersV2Args = {
  input?: InputMaybe<ListChargersV2Input>;
};

export type QueryListConnectorsArgs = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type QueryListConnectors2Args = {
  input?: InputMaybe<ListConnectorsInput>;
};

export type QueryListContactsArgs = {
  input?: InputMaybe<ListContactsInput>;
};

export type QueryListContractsArgs = {
  input?: InputMaybe<ListContractsInput>;
};

export type QueryListCreditsArgs = {
  input?: InputMaybe<ListCreditsInput>;
};

export type QueryListDriversArgs = {
  input?: InputMaybe<ListDriversInput>;
};

export type QueryListElectricVehiclesArgs = {
  input?: InputMaybe<ListElectricVehiclesInput>;
};

export type QueryListExtendHostsArgs = {
  input?: InputMaybe<ListExtendHostsInput>;
};

export type QueryListExtendUsersArgs = {
  input?: InputMaybe<ListExtendUsersInput>;
};

export type QueryListFalconConstraintsArgs = {
  input?: InputMaybe<ListFalconConstraintsInput>;
};

export type QueryListGeographicalRegionsArgs = {
  input?: InputMaybe<ListGeographicalRegionsInput>;
};

export type QueryListHostsArgs = {
  input?: InputMaybe<ListHostsInput>;
};

export type QueryListHostsForExtendArgs = {
  input?: InputMaybe<ListHostsInput>;
};

export type QueryListInvoicesForMobileArgs = {
  input?: InputMaybe<ListInvoicesInput>;
};

export type QueryListNotificationGroupsArgs = {
  input?: InputMaybe<ListNotificationGroupsInput>;
};

export type QueryListPaymentMethodsForMobileArgs = {
  input?: InputMaybe<ListPaymentMethodsForMobileInput>;
};

export type QueryListPlansArgs = {
  input?: InputMaybe<ListPlansInput>;
};

export type QueryListPlansForMobileArgs = {
  input?: InputMaybe<ListPlansInput>;
};

export type QueryListPortsArgs = {
  input?: InputMaybe<ListPortsInput>;
};

export type QueryListPorts2Args = {
  input?: InputMaybe<ListPortsInput>;
};

export type QueryListPropertiesArgs = {
  input?: InputMaybe<ListPropertiesInput>;
};

export type QueryListReservationTermsArgs = {
  input?: InputMaybe<ListReservationTermsInput>;
};

export type QueryListReservationsArgs = {
  input?: InputMaybe<ListReservationsInput>;
};

export type QueryListReservationsByDriverArgs = {
  input: ListReservationsByDriverInput;
};

export type QueryListSalesforceHostContactsArgs = {
  input?: InputMaybe<ListSalesforceHostContactsInput>;
};

export type QueryListSalesforceHostsArgs = {
  input?: InputMaybe<ListSalesforceHostsInput>;
};

export type QueryListSalesforcePropertiesArgs = {
  input?: InputMaybe<ListSalesforcePropertiesInput>;
};

export type QueryListSessionsArgs = {
  input?: InputMaybe<ListSessionsInput>;
};

export type QueryListSessionsV2Args = {
  input?: InputMaybe<ListSessionsV2Input>;
};

export type QueryListSiteImagesArgs = {
  input?: InputMaybe<ListSiteImagesInput>;
};

export type QueryListSitesArgs = {
  input?: InputMaybe<ListSitesInput>;
};

export type QueryListSitesConsumptionForExtendArgs = {
  input: ListSitesConsumptionForExtendInput;
};

export type QueryListSitesForMobileArgs = {
  input: ListSitesForMobileInput;
};

export type QueryListStatementsArgs = {
  input?: InputMaybe<ListStatementsInput>;
};

export type QueryListTagsArgs = {
  input?: InputMaybe<ListTagsInput>;
};

export type QueryListTariffConnectorTypesArgs = {
  input?: InputMaybe<ListTariffConnectorTypesInput>;
};

export type QueryListTariffsForExtendArgs = {
  input: ListTariffsForExtendInput;
};

export type QueryListTransactionsArgs = {
  input?: InputMaybe<ListTransactionsInput>;
};

export type QueryListTransactionsForMobileArgs = {
  input?: InputMaybe<ListTransactionsForMobileInput>;
};

export type QueryListTransactionsV2Args = {
  input?: InputMaybe<ListTransactionsV2Input>;
};

export type QueryListUnassociatedChargersForFleetArgs = {
  input?: InputMaybe<ListUnassociatedChargersForFleetInput>;
};

export type QueryListUploadsArgs = {
  input?: InputMaybe<ListUploadsInput>;
};

export type QueryListVehicleConnectorsArgs = {
  input?: InputMaybe<ListVehicleConnectorsInput>;
};

export type QueryListVehiclesArgs = {
  input?: InputMaybe<ListVehiclesInput>;
};

export type QueryListWarehousesArgs = {
  input?: InputMaybe<ListWarehousesInput>;
};

export type QuerySearchFirebaseAndDriivzAndFalconAccountsArgs = {
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type QuerySiteForMobileArgs = {
  input: SiteForMobileInput;
};

export type QuerySitesForMobileArgs = {
  input: SitesForMobileInput;
};

export type QuerySitesStatusForMobileArgs = {
  input: SitesStatusForMobileInput;
};

export type QueryValidateTagArgs = {
  input?: InputMaybe<ValidateTagInput>;
};

export type RawReqJsonData = {
  __typename?: 'RawReqJsonData';
  data: Scalars['String'];
  messageId: Scalars['String'];
  vendorId: Scalars['String'];
};

export type RawRequestJson = {
  __typename?: 'RawRequestJson';
  data: RawReqJsonData;
  id: Scalars['String'];
};

export type RawResponseJson = {
  __typename?: 'RawResponseJson';
  /** Nullable in the case of no response from the charger */
  response?: Maybe<Scalars['String']>;
};

export type ReassignCardInput = {
  altId: Scalars['UUID'];
  driverId: Scalars['UUID'];
};

/** EVgo Renew Data */
export type Reex = {
  __typename?: 'Reex';
  date: Scalars['Date'];
  outcome: ReexOutcome;
};

/** EVgo Renew Outcome */
export enum ReexOutcome {
  /** Remove */
  Remove = 'REMOVE',
  /** Replace */
  Replace = 'REPLACE',
}

export type RefreshTagsInput = {
  chargers: Array<InputMaybe<Scalars['ID']>>;
};

export type RegisterAccountOwnerInput = {
  accountStatus?: InputMaybe<AccountStatus>;
  billingAddress: CreateAddressInput;
  email: Scalars['SanitizeString'];
  enrolledOn?: InputMaybe<Scalars['Date']>;
  firebaseId: Scalars['SanitizeString'];
  firstName: Scalars['SanitizeString'];
  lastName: Scalars['SanitizeString'];
  mailingAddress: CreateAddressInput;
  optInPromotions?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  phone: Scalars['SanitizeString'];
  subscriptionCopy?: InputMaybe<Scalars['String']>;
  tokenAndroid?: InputMaybe<Scalars['SanitizeString']>;
  tokenIos?: InputMaybe<Scalars['SanitizeString']>;
};

/** Input for removing chargers from a charger group */
export type RemoveChargersFromChargerGroupForExtendInput = {
  altId: Scalars['ID'];
  /** List of charger altIds to remove to the charger group */
  chargerIds: Array<Scalars['ID']>;
};

export type RemoveDriverInput = {
  altId: Scalars['ID'];
};

export enum Reoccurrence {
  /** One Time */
  OneTime = 'ONE_TIME',
  /** Reoccurring */
  Reoccurring = 'REOCCURRING',
}

export type ReplaceCardInput = {
  accountId: Scalars['UUID'];
  altId: Scalars['UUID'];
  driverId: Scalars['UUID'];
};

export type Reservation = {
  __typename?: 'Reservation';
  address?: Maybe<Address>;
  chargerName?: Maybe<Scalars['String']>;
  connectorId?: Maybe<Scalars['ID']>;
  driverId?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['Date']>;
  reservationId?: Maybe<Scalars['ID']>;
  siteId?: Maybe<Scalars['ID']>;
  siteName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
};

export type ReservationTerm = {
  __typename?: 'ReservationTerm';
  cancellationFee?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  noShowFee?: Maybe<Scalars['Float']>;
  reservationFee?: Maybe<Scalars['Float']>;
  reservationType?: Maybe<Scalars['String']>;
};

export type ReservationTermsWithMeta = {
  __typename?: 'ReservationTermsWithMeta';
  edges?: Maybe<Array<Maybe<ReservationTerm>>>;
};

export type ReservationsWithMeta = {
  __typename?: 'ReservationsWithMeta';
  edges?: Maybe<Array<Maybe<Reservation>>>;
};

export type ReserveCardsInput = {
  cardIds: Array<Scalars['UUID']>;
};

export type ResetChargerForExtendInput = {
  chargerId: Scalars['ID'];
};

export type ResetDriverPasswordInput = {
  altId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['Email']>;
};

export type ReusePhoneNumberInput = {
  /** Phone number to be removed from all databases */
  phone: Scalars['SanitizeString'];
};

export type Reward = {
  __typename?: 'Reward';
  accountNumber?: Maybe<Scalars['ID']>;
  error?: Maybe<Scalars['String']>;
  rewardsGroup?: Maybe<Scalars['Int']>;
};

export type Rewards = {
  __typename?: 'Rewards';
  total?: Maybe<Scalars['Float']>;
};

export type RewardsUser = {
  __typename?: 'RewardsUser';
  accountNumber?: Maybe<Scalars['ID']>;
  pointsBalance?: Maybe<Scalars['Float']>;
};

export enum RfidSystemSupported {
  /** ISO/IEC 14443 Type A/B */
  IsoIec_14443TypeAB = 'ISO_IEC_14443_TYPE_A_B',
  /** NFC Reader */
  NfcReader = 'NFC_READER',
}

export type RoamingSitesForMobileInput = {
  /** An integer used to find sites within a radius. Units in miles. */
  distance: Scalars['Int'];
  /** If this flag is set to true and connectorTypeIds has CHADEMO site supports Tesla charging */
  hasTeslaAdapter?: InputMaybe<Scalars['Boolean']>;
  /** An float for latitude of the driver or POI. */
  latitude: Scalars['Float'];
  /** An float for longitude of the driver or POI. */
  longitude: Scalars['Float'];
  /** An array of integers used to filter network operators. */
  networkOperatorIds: Array<Scalars['Int']>;
  /** An integer for the min desired output in kW filter */
  outputKwMax?: InputMaybe<Scalars['Int']>;
  /** An integer for the min desired output in kW filter */
  outputKwMin?: InputMaybe<Scalars['Int']>;
  /** A boolean flag which applies a true/false filter to the RESERVABLE property of the results returned. */
  reservable?: InputMaybe<Scalars['Boolean']>;
  /** A string used to search against the name, displayName, adddress1, city, zipCode, and usaStateName. */
  search?: InputMaybe<Scalars['String']>;
  /** An array of integers used to filter vehicle connectors. */
  vehicleConnectorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type SalesforceHost = {
  __typename?: 'SalesforceHost';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  hid?: Maybe<Scalars['String']>;
  hostName?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  salesforceHostContacts?: Maybe<SalesforceHostContactsWithMeta>;
  salesforceProperties?: Maybe<SalesforcePropertiesWithMeta>;
};

export type SalesforceHostSalesforceHostContactsArgs = {
  input?: InputMaybe<ListSalesforceHostContactsInput>;
};

export type SalesforceHostSalesforcePropertiesArgs = {
  input?: InputMaybe<ListSalesforcePropertiesInput>;
};

export type SalesforceHostContact = {
  __typename?: 'SalesforceHostContact';
  contactName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  hid?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  workTitle?: Maybe<Scalars['String']>;
};

export type SalesforceHostContactsWithMeta = {
  __typename?: 'SalesforceHostContactsWithMeta';
  edges?: Maybe<Array<Maybe<SalesforceHostContact>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type SalesforceHostsWithMeta = {
  __typename?: 'SalesforceHostsWithMeta';
  edges?: Maybe<Array<Maybe<SalesforceHost>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListSalesforceHostsType>;
  total?: Maybe<Scalars['Int']>;
};

export type SalesforcePropertiesWithMeta = {
  __typename?: 'SalesforcePropertiesWithMeta';
  edges?: Maybe<Array<Maybe<SalesforceProperty>>>;
  filter?: Maybe<ListSalesforcePropertiesFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListSalesforcePropertiesSearchType>;
  sort?: Maybe<ListSalesforcePropertiesSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type SalesforceProperty = {
  __typename?: 'SalesforceProperty';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  evcId?: Maybe<Scalars['String']>;
  hid?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  propertyName?: Maybe<Scalars['String']>;
  salesforceHost?: Maybe<SalesforceHost>;
};

export type SalesforcePropertySalesforceHostArgs = {
  input?: InputMaybe<GetSalesforceHostInput>;
};

export enum SalesforcePropertyStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Inactive */
  Inactive = 'INACTIVE',
}

export type SendEmailMessageInput = {
  /** This value is optional, but should be set to true if you need to add the recipients to Emarsys contact database. */
  createEmarsysContact?: InputMaybe<Scalars['Boolean']>;
  fromAddress?: InputMaybe<Scalars['SanitizeString']>;
  fromName?: InputMaybe<Scalars['SanitizeString']>;
  /** Unique identifier that references the provider template id. It can be Emarsy's event trigger id or Mandrill's template name for example */
  messageId: Scalars['SanitizeString'];
  provider?: InputMaybe<EmailProvider>;
  recipients: Array<EmailRecipient>;
  replyToAddress?: InputMaybe<Scalars['SanitizeString']>;
  subject?: InputMaybe<Scalars['SanitizeString']>;
};

export type SendPasswordResetInput = {
  /** Identifies which environment the email is in reference to. This mitigates the issue with a single Emarsys environment */
  baseUrl?: InputMaybe<Scalars['SanitizeString']>;
  emailAddress: Scalars['SanitizeString'];
  recipientName?: InputMaybe<Scalars['SanitizeString']>;
  /** Identify in what service you're trying to reset your password. This could be used to send a custom email template if necessary. */
  service: AuthServiceType;
};

export type SendPaymentMethodNotificationsResponse = {
  __typename?: 'SendPaymentMethodNotificationsResponse';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SendRewardsConfirmationEmailInput = {
  accountNumber?: InputMaybe<Scalars['Int']>;
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
};

export type SendRewardsConfirmationEmailReturn = {
  __typename?: 'SendRewardsConfirmationEmailReturn';
  mandrillId?: Maybe<Scalars['String']>;
  recipientEmail?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SendTariffInput = {
  addressObj: TariffAddressObj;
  identityKey: Scalars['String'];
  message: Scalars['JSON'];
};

export type SendTariffResponse = {
  __typename?: 'SendTariffResponse';
  /** accepted, true when charger accepted, false when rejected and null on no response. */
  accepted?: Maybe<Scalars['Boolean']>;
  identityKey: Scalars['String'];
};

export type SendTransactionSmsInput = {
  message?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['ID'];
};

export type Session = {
  __typename?: 'Session';
  /** Indication of whether the session was accepted to start or not */
  accepted?: Maybe<Scalars['Boolean']>;
  /** Account used for the session */
  account?: Maybe<Account>;
  /** Unique identifier for the session */
  altId: Scalars['ID'];
  /** Battery level of the vehicle after a session has completed */
  batteryLevel?: Maybe<Scalars['Float']>;
  /** Card used to start the session */
  card?: Maybe<Card>;
  /** Number of the RFID card used to start a session */
  cardNumber?: Maybe<Scalars['String']>;
  /** Charger used during the session */
  charger?: Maybe<SessionCharger>;
  /** Connector used for the session */
  connector?: Maybe<Connector>;
  connectorFleet?: Maybe<Connector>;
  /** id field on the connector used during the session */
  connectorId?: Maybe<Scalars['ID']>;
  /** Indication of whether the session was corrupted or not */
  corrupted?: Maybe<Scalars['Boolean']>;
  /** Reasons for session corruption */
  corruptedReasons?: Maybe<Array<Maybe<CorruptedReason>>>;
  /** Monetary information for the session */
  cost?: Maybe<SessionCost>;
  /** Time the session took to complete */
  duration?: Maybe<Scalars['String']>;
  /** Time the session ended */
  endTime?: Maybe<Scalars['Date']>;
  /** Total kWh delivered during the session */
  energyDelivered?: Maybe<Scalars['Float']>;
  /** Indication of whether there are extenuating fees for the session or not */
  idleFees?: Maybe<Scalars['Boolean']>;
  /** Database ID of the invoice created from the session */
  invoiceId?: Maybe<Scalars['ID']>;
  /** Number for the invoice as it appears on a statement */
  invoiceNumber?: Maybe<Scalars['ID']>;
  /** kWh the vehicle ended with */
  meterEnd?: Maybe<Scalars['Int']>;
  /** kWh the vehicle began with */
  meterStart?: Maybe<Scalars['Int']>;
  /** Plan used for the session */
  plan?: Maybe<Plan>;
  /** kWh readings throughout the session */
  readings?: Maybe<Array<Maybe<SessionReading>>>;
  /** Total time of the session */
  sessionDuration?: Maybe<Scalars['Int']>;
  /** Identification number of the session for session_meters */
  sessionId?: Maybe<Scalars['ID']>;
  /** Indication of whether another session is currently active on the same charger */
  simultaneousChargingActive: Scalars['Boolean'];
  /** Indication of whether the charger has simultaneous charging enabled */
  simultaneousChargingEnabled: Scalars['Boolean'];
  /** Source from which the session was started such as App or RFID */
  startSource?: Maybe<FalconConstraint>;
  /** Time the session began */
  startTime?: Maybe<Scalars['Date']>;
  /** Source from which the session was stopped such as App or RFID */
  stopSource?: Maybe<FalconConstraint>;
  /** Tariff on the charger used for the session */
  tariff?: Maybe<Tariff>;
  /** Time zone in which the session occurred */
  timeZone?: Maybe<Scalars['String']>;
  /** ID relative to the session in Driivz */
  vendorId?: Maybe<Scalars['ID']>;
};

export enum SessionAggregation {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Week = 'week',
}

export type SessionCharger = {
  __typename?: 'SessionCharger';
  chargerId?: Maybe<Scalars['ID']>;
  chargerName?: Maybe<Scalars['String']>;
  maxPower?: Maybe<Scalars['Float']>;
};

export type SessionCost = {
  __typename?: 'SessionCost';
  billedTime?: Maybe<Scalars['Float']>;
  energyCost?: Maybe<Scalars['Float']>;
  estimated?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
};

export enum SessionDatePreset {
  /** Get last 7 days of data */
  Last_7d = 'last_7d',
  /** Get last 12 months of data */
  Last_12m = 'last_12m',
  /** Get last 30 days of data */
  Last_30d = 'last_30d',
  /** Get today's data */
  Today = 'today',
  /** Get yestarday's data */
  Yesterday = 'yesterday',
}

export type SessionMeter = {
  __typename?: 'SessionMeter';
  altId?: Maybe<Scalars['ID']>;
  connector?: Maybe<Connector>;
  eventDate?: Maybe<Scalars['Date']>;
  meterand?: Maybe<FalconConstraint>;
  session?: Maybe<Session>;
  value?: Maybe<Scalars['Float']>;
};

export type SessionMeterValue = {
  __typename?: 'SessionMeterValue';
  timestamp: Scalars['Date'];
  unitOfMeasure: SessionMeterValueUnitOfMeasurment;
  value: Scalars['Float'];
};

export enum SessionMeterValueUnitOfMeasurment {
  Kwh = 'KWH',
  Wh = 'WH',
}

export type SessionReading = {
  __typename?: 'SessionReading';
  kWhValue?: Maybe<Scalars['Float']>;
  powerValue?: Maybe<Scalars['Float']>;
  socValue?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type SessionSummaryByPlan = {
  __typename?: 'SessionSummaryByPlan';
  planName: Scalars['String'];
  /** Dollar value for transactions against plan */
  powerCostValue: Scalars['Float'];
};

export type SessionV2 = {
  __typename?: 'SessionV2';
  /** Indication of whether the session was accepted to start or not */
  accepted?: Maybe<Scalars['Boolean']>;
  /** Identification number of the session for session_meters */
  altId: Scalars['ID'];
  /** The card used to start the session.  Only available for superAdmin users. */
  card?: Maybe<Card>;
  /** Notes left by an operator */
  comments?: Maybe<Scalars['String']>;
  connector?: Maybe<ConnectorV2>;
  /** Indication of whether the session was corrupted or not */
  corrupted?: Maybe<Scalars['Boolean']>;
  corruptedReasons?: Maybe<Array<Maybe<CorruptedReason>>>;
  cost?: Maybe<Scalars['Float']>;
  /** End time of the session in UTC */
  endTime?: Maybe<Scalars['Date']>;
  /** End time of the session in the site timezone */
  endTimeLocal?: Maybe<Scalars['Date']>;
  /** Total kWh delivered during the session */
  energyDelivered?: Maybe<Scalars['Float']>;
  /** last recorded kWh for the session */
  lastMeterValue?: Maybe<Scalars['Int']>;
  /** kWh the vehicle ended with */
  meterEnd?: Maybe<Scalars['Int']>;
  /** kWh the vehicle began with */
  meterStart?: Maybe<Scalars['Int']>;
  /** Meter values recorded during session.  Default unit is Wh (WH enum). */
  meterValues: Array<SessionMeterValue>;
  /** Plan used for the session */
  plan?: Maybe<PlanV2>;
  /** Duration of session in minutes */
  sessionDuration?: Maybe<Scalars['Int']>;
  soc_notification_sent?: Maybe<Scalars['Boolean']>;
  startSource?: Maybe<FalconConstraint>;
  /** Start time of the session in UTC */
  startTime?: Maybe<Scalars['Date']>;
  /** Start time of the session in the site timezone */
  startTimeLocal?: Maybe<Scalars['Date']>;
  stopSource?: Maybe<FalconConstraint>;
  transaction?: Maybe<TransactionV2>;
  vendorId: Scalars['ID'];
};

export type SessionV2MeterValuesArgs = {
  units?: InputMaybe<SessionMeterValueUnitOfMeasurment>;
};

/** An object containing information about the validity of a session */
export type SessionValidation = {
  __typename?: 'SessionValidation';
  /** The ID of the current authorizationStatus of the session */
  authorizationStatusId?: Maybe<Scalars['ID']>;
  /** A message string describing the error for the found session */
  message?: Maybe<Scalars['String']>;
  /** A boolean value that is true when a recent session is found for the input connectorId */
  sessionFound?: Maybe<Scalars['Boolean']>;
};

export type SessionsConsumptionForExtendData = {
  __typename?: 'SessionsConsumptionForExtendData';
  powerCostValue: Scalars['Float'];
  powerValue: Scalars['Float'];
  timestamp?: Maybe<Scalars['Date']>;
  totalSessions: Scalars['Int'];
};

export enum SessionsV2Sort {
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KwhAsc = 'KWH_ASC',
  KwhDesc = 'KWH_DESC',
  SiteNameAsc = 'SITE_NAME_ASC',
  SiteNameDesc = 'SITE_NAME_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
}

export type SessionsV2WithMeta = {
  __typename?: 'SessionsV2WithMeta';
  edges?: Maybe<Array<Maybe<SessionV2>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type SessionsWithMeta = {
  __typename?: 'SessionsWithMeta';
  edges?: Maybe<Array<Maybe<Session>>>;
  filter?: Maybe<ListSessionsFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListSessionsSearchType>;
  total?: Maybe<Scalars['Int']>;
};

export enum SetChargeProfileResponseStatus {
  /** Error */
  Error = 'ERROR',
  /** Validated */
  Validated = 'VALIDATED',
}

export type SetChargingProfileInput = {
  /**
   * Optional property to use when setting the charging profile on chargers
   * that allow each session to use the full charging profile when
   * simultaneous charging is in use.  Defaults to false.
   */
  applySimultaneousChargingWorkaround?: InputMaybe<Scalars['Boolean']>;
  connectorId: Scalars['ID'];
  limit: Scalars['Float'];
  reasonDetail: Scalars['String'];
  reasonType: SetChargingProfileReason;
  shouldPublishSuccessAlert?: InputMaybe<Scalars['Boolean']>;
};

export enum SetChargingProfileReason {
  ChargerDisabled = 'CHARGER_DISABLED',
  ChargerRebootReceived = 'CHARGER_REBOOT_RECEIVED',
  /** Set throttle to prevent overloading circuit or tripping breaker */
  ManageCircuit = 'MANAGE_CIRCUIT',
  /** Set throttle to prevent excessive demand charges */
  ManageDemand = 'MANAGE_DEMAND',
  /** Set throttle to avoid charging during peak hours when possible */
  ManageTou = 'MANAGE_TOU',
  /**
   * Set throttle to avoid to minimal power level needed to start session so that
   * power can be allocated maximally to active sessions
   */
  SetPreSessionThrottle = 'SET_PRE_SESSION_THROTTLE',
  /** Stop existing throttle on the charger */
  StopThrottle = 'STOP_THROTTLE',
}

export type SetChargingProfileStatusResponse = {
  __typename?: 'SetChargingProfileStatusResponse';
  SetChargeProfileResponseStatus?: Maybe<SetChargeProfileResponseStatus>;
};

export type SetDemandLimitInput = {
  altId: Scalars['UUID'];
  retailDemandLimit: Scalars['Float'];
};

export type SetPrimaryCardForMobileInput = {
  altId: Scalars['ID'];
};

export type Site = {
  __typename?: 'Site';
  /** @deprecated Use siteAccess */
  access?: Maybe<SiteAccess>;
  accessNotes?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  altId: Scalars['ID'];
  availableChargers?: Maybe<Scalars['Int']>;
  chargerUtilizationPercentage?: Maybe<Scalars['Float']>;
  chargers?: Maybe<ChargersWithMeta>;
  chargersInUse?: Maybe<Scalars['Int']>;
  chargersOffline?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  demandLimitManagementEnabled?: Maybe<Scalars['Boolean']>;
  directions?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  evcId?: Maybe<Scalars['String']>;
  evcSyncedAt?: Maybe<Scalars['Date']>;
  evseCount?: Maybe<Scalars['Int']>;
  hoursOfOperation?: Maybe<HoursType>;
  isEvgoAccess?: Maybe<Scalars['Boolean']>;
  isEvgoAccessAvailable?: Maybe<Scalars['Boolean']>;
  kWhDelivered?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  legacyId?: Maybe<Scalars['ID']>;
  locality?: Maybe<Scalars['String']>;
  locationMarket?: Maybe<SiteMarket>;
  locationNotes?: Maybe<Scalars['String']>;
  locationType?: Maybe<FalconConstraint>;
  locationTypeId?: Maybe<Scalars['Int']>;
  longitude?: Maybe<Scalars['Float']>;
  maxCurrent?: Maybe<Scalars['String']>;
  meterNumber?: Maybe<Scalars['String']>;
  networkOperator?: Maybe<NetworkOperatorType>;
  postalCode?: Maybe<Scalars['String']>;
  property?: Maybe<SiteProperty>;
  ratedVoltage?: Maybe<Scalars['String']>;
  reexDate?: Maybe<Scalars['Date']>;
  reexOutcome?: Maybe<ReexOutcome>;
  retailDemandLimit?: Maybe<Scalars['Float']>;
  setupType?: Maybe<FalconConstraint>;
  sid?: Maybe<Scalars['String']>;
  siteAccess?: Maybe<FalconConstraint>;
  siteAccessId?: Maybe<Scalars['Int']>;
  siteImages?: Maybe<SiteImagesWithMeta>;
  siteName?: Maybe<Scalars['String']>;
  /** @deprecated Use siteStatusConstraint */
  siteStatus?: Maybe<Scalars['String']>;
  siteStatusConstraint?: Maybe<FalconConstraint>;
  siteStatusId?: Maybe<Scalars['Int']>;
  /** @deprecated Use locationType */
  siteType?: Maybe<FalconConstraint>;
  timeZone?: Maybe<Scalars['String']>;
  /** @deprecated Use locationType */
  type?: Maybe<SiteType>;
  utilityCompany?: Maybe<SiteUtilityCompany>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type SiteKWhDeliveredArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type SiteSiteImagesArgs = {
  input?: InputMaybe<ListSiteImagesInput>;
};

export enum SiteAccess {
  /** Dedicated */
  Dedicated = 'DEDICATED',
  /** Private */
  Private = 'PRIVATE',
  /** Public */
  Public = 'PUBLIC',
  /** Taxi Only */
  TaxiOnly = 'TAXI_ONLY',
  /** Use Site */
  UseSite = 'USE_SITE',
}

export type SiteFilterInput = {
  centerLat?: InputMaybe<Scalars['Float']>;
  centerLng?: InputMaybe<Scalars['Float']>;
  distance?: InputMaybe<Scalars['Float']>;
  neLat?: InputMaybe<Scalars['Float']>;
  neLng?: InputMaybe<Scalars['Float']>;
  openSites?: InputMaybe<Scalars['Boolean']>;
  sid?: InputMaybe<TableStringFilterInput>;
  siteName?: InputMaybe<TableStringFilterInput>;
  siteStatusId?: InputMaybe<TableIntFilterInput>;
  swLat?: InputMaybe<Scalars['Float']>;
  swLng?: InputMaybe<Scalars['Float']>;
};

export type SiteFilterType = {
  __typename?: 'SiteFilterType';
  centerLat?: Maybe<Scalars['Float']>;
  centerLng?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  neLat?: Maybe<Scalars['Float']>;
  neLng?: Maybe<Scalars['Float']>;
  openSites?: Maybe<Scalars['Boolean']>;
  sid?: Maybe<TableStringFilterType>;
  siteName?: Maybe<TableStringFilterType>;
  siteStatusId?: Maybe<TableStringFilterType>;
  swLat?: Maybe<Scalars['Float']>;
  swLng?: Maybe<Scalars['Float']>;
};

export type SiteForMobile = {
  __typename?: 'SiteForMobile';
  access?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  altId: Scalars['ID'];
  availableChargers?: Maybe<Scalars['Boolean']>;
  chargerStatus?: Maybe<Scalars['String']>;
  chargers?: Maybe<Array<Maybe<ChargerForMobile>>>;
  country?: Maybe<Scalars['String']>;
  directions?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  isEvgoAccess?: Maybe<Scalars['Boolean']>;
  isEvgoAccessAvailable?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  locationNotes?: Maybe<Scalars['String']>;
  logoURL?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  maxConnectorPower?: Maybe<Scalars['Float']>;
  networkName?: Maybe<Scalars['String']>;
  openFrom?: Maybe<Scalars['String']>;
  openTo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  reex?: Maybe<Reex>;
  timeZone?: Maybe<Scalars['String']>;
};

export type SiteForMobileInput = {
  /** Required: A string used as a unique identifier for the site */
  altId: Scalars['ID'];
  /** Required: A float for latitude of the driver or POI. */
  latitude: Scalars['Float'];
  /** Required: A float for longitude of the driver or POI. */
  longitude: Scalars['Float'];
};

export type SiteForMobileSummary = {
  __typename?: 'SiteForMobileSummary';
  available?: Maybe<Scalars['Int']>;
  busy?: Maybe<Scalars['Int']>;
  unavailable?: Maybe<Scalars['Int']>;
  unknown?: Maybe<Scalars['Int']>;
};

export type SiteImage = {
  __typename?: 'SiteImage';
  altId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  media?: Maybe<Media>;
  site?: Maybe<Site>;
};

export type SiteImagesWithMeta = {
  __typename?: 'SiteImagesWithMeta';
  edges?: Maybe<Array<Maybe<SiteImage>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export enum SiteMarket {
  /** Location Market 1 */
  LocationMarket_1 = 'LOCATION_MARKET_1',
  /** Location Market 2 */
  LocationMarket_2 = 'LOCATION_MARKET_2',
  /** Location Market 3 */
  LocationMarket_3 = 'LOCATION_MARKET_3',
  /** Location Market 4 */
  LocationMarket_4 = 'LOCATION_MARKET_4',
  /** Location Market 5 */
  LocationMarket_5 = 'LOCATION_MARKET_5',
}

export type SiteProperty = {
  __typename?: 'SiteProperty';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  altId?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['String']>;
  host?: Maybe<Host>;
  locality?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  propertyName?: Maybe<Scalars['String']>;
  status?: Maybe<PropertyStatus>;
  vendorId?: Maybe<Scalars['String']>;
};

export enum SiteType {
  /** Commercial */
  Commercial = 'COMMERCIAL',
  /** DSO */
  Dso = 'DSO',
  /** Gas Station */
  GasStation = 'GAS_STATION',
  /** Government */
  Government = 'GOVERNMENT',
  /** Highway */
  Highway = 'HIGHWAY',
  /** Home */
  Home = 'HOME',
  /** Hotel */
  Hotel = 'HOTEL',
  /** Mall */
  Mall = 'MALL',
  /** Parking Lot */
  ParkingLot = 'PARKING_LOT',
  /** Public Parking */
  PublicParking = 'PUBLIC_PARKING',
  /** Work */
  Work = 'WORK',
}

export enum SiteUtilityCompany {
  /** Host Power */
  HostPower = 'HOST_POWER',
  /** Utility Company 1 */
  UtilityCompany_1 = 'UTILITY_COMPANY_1',
  /** Utility Company 2 */
  UtilityCompany_2 = 'UTILITY_COMPANY_2',
  /** Utility Company 3 */
  UtilityCompany_3 = 'UTILITY_COMPANY_3',
  /** Utility Company 4 */
  UtilityCompany_4 = 'UTILITY_COMPANY_4',
}

export type SitesForMobile = {
  __typename?: 'SitesForMobile';
  access?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  altId: Scalars['ID'];
  country?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  locality?: Maybe<Scalars['String']>;
  logoURL?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  maxPower?: Maybe<Array<Maybe<SitesForMobilePowerStatus>>>;
  networkName?: Maybe<Scalars['String']>;
  openFrom?: Maybe<Scalars['String']>;
  openTo?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  reex?: Maybe<Reex>;
  reservable?: Maybe<Scalars['Boolean']>;
  siteName?: Maybe<Scalars['String']>;
  siteStatus?: Maybe<Scalars['String']>;
  siteSummary?: Maybe<SiteForMobileSummary>;
  timeZone?: Maybe<Scalars['String']>;
};

export type SitesForMobileInput = {
  /** An integer used to find sites within a radius. Units in miles. */
  distance: Scalars['Int'];
  /** If this flag is set to true and connectorTypeIds has CHADEMO site supports Tesla charging */
  hasTeslaAdapter?: InputMaybe<Scalars['Boolean']>;
  /** An float for latitude of the driver or POI. */
  latitude: Scalars['Float'];
  /** An float for longitude of the driver or POI. */
  longitude: Scalars['Float'];
  /** An array of integers used to filter network operators. */
  networkOperatorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** An integer for the min desired output in kW filter */
  outputKwMax?: InputMaybe<Scalars['Int']>;
  /** An integer for the min desired output in kW filter */
  outputKwMin?: InputMaybe<Scalars['Int']>;
  /** A boolean flag which applies a true/false filter to the RESERVABLE property of the results returned. */
  reservable?: InputMaybe<Scalars['Boolean']>;
  /** A string used to search against the name, displayName, adddress1, city, zipCode, and usaStateName. */
  search?: InputMaybe<Scalars['String']>;
  /** An array of integers used to filter vehicle connectors. */
  vehicleConnectorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export type SitesForMobilePowerStatus = {
  __typename?: 'SitesForMobilePowerStatus';
  power?: Maybe<Scalars['Float']>;
  /** Indication of whether the charger has simultaneous charging enabled */
  simultaneousChargingEnabled: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
};

export type SitesForMobileWithMeta = {
  __typename?: 'SitesForMobileWithMeta';
  edges?: Maybe<Array<Maybe<SitesForMobile>>>;
  total?: Maybe<Scalars['Int']>;
};

export type SitesStatusForMobile = {
  __typename?: 'SitesStatusForMobile';
  access: Scalars['String'];
  reservable: Scalars['Boolean'];
  siteId: Scalars['ID'];
  status: Scalars['String'];
  summary: SiteForMobileSummary;
};

export type SitesStatusForMobileInput = {
  afterIsoDateTime: Scalars['String'];
  siteIds: Array<Scalars['ID']>;
};

export type SitesWithMeta = {
  __typename?: 'SitesWithMeta';
  edges?: Maybe<Array<Maybe<Site>>>;
  filter?: Maybe<ListSiteFilterType>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListSiteSearchType>;
  sort?: Maybe<ListSitesSortType>;
  total?: Maybe<Scalars['Int']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StartAutochargeEnrollmentInput = {
  altId: Scalars['UUID'];
};

export type StartChargeFleetInput = {
  cardId: Scalars['Int'];
  cardNumber: Scalars['String'];
  connectorId: Scalars['ID'];
};

export type StartChargeForMobileInput = {
  connectorId: Scalars['ID'];
};

export type StartChargeInput = {
  connectorId: Scalars['ID'];
  driverId: Scalars['ID'];
};

export type Statement = {
  __typename?: 'Statement';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ID']>;
  invoiceId?: Maybe<Scalars['ID']>;
  invoiceNumber?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type StatementsWithMeta = {
  __typename?: 'StatementsWithMeta';
  edges?: Maybe<Array<Maybe<Statement>>>;
  total?: Maybe<Scalars['Int']>;
};

export type StopChargeForMobileInput = {
  connectorId: Scalars['ID'];
};

export type StopChargeInput = {
  connectorId: Scalars['ID'];
};

export type Success = {
  __typename?: 'Success';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SuspendCardsInput = {
  accountId?: InputMaybe<Scalars['UUID']>;
  cardIds: Array<Scalars['UUID']>;
  driverId?: InputMaybe<Scalars['UUID']>;
};

export type SyncAccountInput = {
  altId: Scalars['ID'];
  evcId: Scalars['String'];
  evcSyncedAt?: InputMaybe<Scalars['Date']>;
  publish: Publish;
  vendorSyncedAt: Scalars['Date'];
};

export type SyncAssignCardInput = {
  altId: Scalars['ID'];
};

export type SyncChargerStatusInput = {
  chargerVendorId: Scalars['ID'];
  connectorStatus: Scalars['String'];
  connectorVendorId: Scalars['ID'];
  vendorSyncedAt: Scalars['Timestamp'];
};

export type SyncConnectorInput = {
  evcId: Scalars['ID'];
  status: ConnectorStatus;
};

export type SyncContractInput = {
  accountVendorId: Scalars['ID'];
  balanceType?: InputMaybe<Scalars['ID']>;
  endDate?: InputMaybe<Scalars['Date']>;
  planVendorId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Date']>;
  vendorId: Scalars['ID'];
  vendorStatus?: InputMaybe<Scalars['String']>;
};

export type SyncCreateAccountOwnerInput = {
  altId: Scalars['ID'];
};

export type SyncCreateAdHocContractInput = {
  accountId: Scalars['ID'];
  amount: Scalars['Int'];
};

export type SyncCreateCardOrderInput = {
  altId: Scalars['ID'];
  contractId?: InputMaybe<Scalars['String']>;
};

export type SyncCreateChargerInput = {
  altId: Scalars['ID'];
};

export type SyncCreateChargerModelInput = {
  altId: Scalars['ID'];
};

export type SyncCreateContractInput = {
  altId: Scalars['ID'];
};

export type SyncCreateHostInput = {
  altId: Scalars['ID'];
};

export type SyncCreatePropertyInput = {
  altId: Scalars['ID'];
};

export type SyncCreateSiteInput = {
  altId: Scalars['ID'];
};

export type SyncDriverInput = {
  altId: Scalars['ID'];
};

export type SyncPasswordResetInput = {
  id: Scalars['String'];
};

export type SyncPlanInput = {
  vendorId: Scalars['ID'];
};

export type SyncSiteInput = {
  altId: Scalars['ID'];
  evcId: Scalars['SanitizeString'];
  evcSyncedAt: Scalars['Date'];
  publish: Publish;
};

export type SyncUpdateAccountInput = {
  altId: Scalars['ID'];
};

export type SyncUpdateAccountOwnerInput = {
  altId: Scalars['ID'];
};

export type SyncUpdateChargerInput = {
  altId: Scalars['ID'];
};

export type TableBooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type TableBooleanFilterType = {
  __typename?: 'TableBooleanFilterType';
  eq?: Maybe<Scalars['Boolean']>;
  ne?: Maybe<Scalars['Boolean']>;
};

export type TableDateFilterInput = {
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
};

export type TableDateRangeFilterInput = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type TableDateRangeFilterType = {
  __typename?: 'TableDateRangeFilterType';
  from?: Maybe<Scalars['Date']>;
  to?: Maybe<Scalars['Date']>;
};

export type TableFilterInput = {
  or?: InputMaybe<Array<InputMaybe<TableIntFilterInput>>>;
};

export type TableFilterType = {
  __typename?: 'TableFilterType';
  or?: Maybe<Array<Maybe<TableIntFilterType>>>;
};

export type TableFloatFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  like?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  notLike?: InputMaybe<Scalars['Float']>;
};

export type TableIntFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contains?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  like?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  notLike?: InputMaybe<Scalars['Int']>;
};

export type TableIntFilterType = {
  __typename?: 'TableIntFilterType';
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
  contains?: Maybe<Array<Maybe<Scalars['Int']>>>;
  eq?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  like?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  ne?: Maybe<Scalars['Int']>;
  notLike?: Maybe<Scalars['Int']>;
};

export enum TableSortInput {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum TableSortType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type TableStringFilterInput = {
  beginsWith?: InputMaybe<Scalars['SanitizeString']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['SanitizeString']>>>;
  contains?: InputMaybe<Scalars['SanitizeString']>;
  endsWith?: InputMaybe<Scalars['SanitizeString']>;
  eq?: InputMaybe<Scalars['SanitizeString']>;
  ge?: InputMaybe<Scalars['SanitizeString']>;
  gt?: InputMaybe<Scalars['SanitizeString']>;
  iLike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['SanitizeString']>>>;
  le?: InputMaybe<Scalars['SanitizeString']>;
  lt?: InputMaybe<Scalars['SanitizeString']>;
  ne?: InputMaybe<Scalars['SanitizeString']>;
  notContains?: InputMaybe<Scalars['SanitizeString']>;
};

export type TableStringFilterType = {
  __typename?: 'TableStringFilterType';
  beginsWith?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  contains?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  iLike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ne?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
};

export type TableTimestampRangeFilterInput = {
  from?: InputMaybe<Scalars['Timestamp']>;
  gte?: InputMaybe<Scalars['Timestamp']>;
  to?: InputMaybe<Scalars['Timestamp']>;
};

export type TableTimestampRangeFilterType = {
  __typename?: 'TableTimestampRangeFilterType';
  from?: Maybe<Scalars['Timestamp']>;
  to?: Maybe<Scalars['Timestamp']>;
};

export type Tag = {
  __typename?: 'Tag';
  altId?: Maybe<Scalars['ID']>;
  chargerGroup: ChargerGroupForExtend;
  chargers?: Maybe<ChargersWithMeta>;
  description?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Maybe<Filter>>>;
  planTariffs?: Maybe<TariffWithMeta>;
  tagName?: Maybe<Scalars['String']>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type TagChargersArgs = {
  input?: InputMaybe<ListChargersInput>;
};

export type TagsWithMeta = {
  __typename?: 'TagsWithMeta';
  edges?: Maybe<Array<Maybe<Tag>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListTagsSearchType>;
  sort?: Maybe<ListTagsSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type Tariff = {
  __typename?: 'Tariff';
  altId: Scalars['ID'];
  connectorTypes?: Maybe<TariffConnectorTypesWithMeta>;
  createdAt?: Maybe<Scalars['Date']>;
  energyFee?: Maybe<Scalars['Float']>;
  /** @deprecated Use maxSessionDurationInMinutes instead */
  maxSessionDuration?: Maybe<Scalars['Int']>;
  maxSessionDurationInMinutes?: Maybe<Scalars['Float']>;
  perMinuteFee?: Maybe<Scalars['Float']>;
  planTags?: Maybe<TagsWithMeta>;
  plans?: Maybe<PlanWithMeta>;
  sessionFee?: Maybe<Scalars['Float']>;
  tags?: Maybe<TagsWithMeta>;
  tariffDescription?: Maybe<Scalars['SanitizeString']>;
  tariffItems?: Maybe<Array<Maybe<TariffItem>>>;
  tariffName: Scalars['SanitizeString'];
  tariffRank?: Maybe<Scalars['Int']>;
};

export type TariffAddressObj = {
  address1?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  stateAbbr: Scalars['String'];
  zipCode: Scalars['String'];
};

export type TariffConnectorTypes = {
  __typename?: 'TariffConnectorTypes';
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  tariff?: Maybe<Tariff>;
};

export type TariffConnectorTypesWithMeta = {
  __typename?: 'TariffConnectorTypesWithMeta';
  edges?: Maybe<Array<Maybe<TariffConnectorTypes>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TariffCustomPricesForExtend = {
  __typename?: 'TariffCustomPricesForExtend';
  perKwh?: Maybe<Scalars['TariffPrice']>;
  perMinute?: Maybe<Scalars['TariffPrice']>;
  perSession?: Maybe<Scalars['TariffPrice']>;
};

export type TariffCustomPricingForExtend = {
  __typename?: 'TariffCustomPricingForExtend';
  altId: Scalars['ID'];
  displayDescription1?: Maybe<Scalars['SanitizeString']>;
  displayDescription2?: Maybe<Scalars['SanitizeString']>;
  displayName?: Maybe<Scalars['SanitizeString']>;
  displayRank?: Maybe<Scalars['Int']>;
  pricing: TariffCustomPricesForExtend;
  /** Schedule the pricing will apply during */
  tariffSchedules: Array<TariffScheduleForExtend>;
};

export type TariffForExtend = {
  __typename?: 'TariffForExtend';
  /** Unique UUID of the Tariff */
  altId: Scalars['ID'];
  chargerGroups?: Maybe<Array<Maybe<ChargerGroupForExtend>>>;
  /** Time of use pricing for the Tariff */
  customPricing?: Maybe<Array<TariffCustomPricingForExtend>>;
  /** Default pricing for the Tariff */
  defaultPricing?: Maybe<TariffPricingForExtend>;
  /** Reservation pricing for the Tariff */
  reservationTerm?: Maybe<TariffItemReservationTermForExtend>;
  status?: Maybe<TariffForExtendSyncStatus>;
  tariffDescription?: Maybe<Scalars['SanitizeString']>;
  tariffDisplayName?: Maybe<Scalars['SanitizeString']>;
  tariffName: Scalars['SanitizeString'];
  tariffRank?: Maybe<Scalars['Int']>;
};

export enum TariffForExtendSyncStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  NotSynced = 'NOT_SYNCED',
}

export type TariffItem = {
  __typename?: 'TariffItem';
  altId: Scalars['ID'];
  perKwh: Scalars['TariffPrice'];
  perMinute: Scalars['TariffPrice'];
  perSession: Scalars['TariffPrice'];
  reservationTerms: Array<TariffItemReservationTerm>;
  tariffItemName: Scalars['String'];
  tariffSchedule?: Maybe<TariffSchedule>;
  tariffSchedules: Array<TariffScheduleOp>;
  vendorId?: Maybe<Scalars['ID']>;
};

export type TariffItemReservationTerm = {
  __typename?: 'TariffItemReservationTerm';
  altId?: Maybe<Scalars['ID']>;
  fee?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  noShowFee?: Maybe<Scalars['Float']>;
};

export type TariffItemReservationTermForExtend = {
  __typename?: 'TariffItemReservationTermForExtend';
  /** Fee for making a reservation */
  fee?: Maybe<Scalars['Float']>;
  /** Length of allowed reservation in minutes */
  length?: Maybe<Scalars['Float']>;
  /** Fee for missing a reservation */
  noShowFee?: Maybe<Scalars['Float']>;
};

export type TariffItemReservationTermForExtendInput = {
  /** Fee for making a reservation */
  fee?: InputMaybe<Scalars['TariffPrice']>;
  /** Fee for missing a reservation */
  noShowFee?: InputMaybe<Scalars['TariffPrice']>;
};

export type TariffLog = {
  __typename?: 'TariffLog';
  accepted: Scalars['Boolean'];
  chargerIdentityKey: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  messageType: TariffLogMessageType;
  rawRequestData: RawRequestJson;
  rawResponseData?: Maybe<RawResponseJson>;
};

export enum TariffLogMessageType {
  DisplayTariff = 'DisplayTariff',
  EndSessionTariff = 'EndSessionTariff',
  SessionTariff = 'SessionTariff',
  SetTariff = 'SetTariff',
}

export type TariffLogsSort = {
  columnName: Scalars['SanitizeString'];
  direction: SortDirection;
};

export type TariffPricingForExtend = {
  __typename?: 'TariffPricingForExtend';
  displayDescription1?: Maybe<Scalars['SanitizeString']>;
  displayDescription2?: Maybe<Scalars['SanitizeString']>;
  displayName?: Maybe<Scalars['SanitizeString']>;
  displayRank?: Maybe<Scalars['Int']>;
  pricing?: Maybe<PricingForExtend>;
};

export type TariffSchedule = {
  __typename?: 'TariffSchedule';
  scheduleName?: Maybe<Scalars['String']>;
};

export type TariffScheduleForExtend = {
  __typename?: 'TariffScheduleForExtend';
  /** Days schedule applies to */
  daysOfWeek: Array<Day>;
  /** End time for schedule as HH:MM */
  endTime: Scalars['String'];
  /** Start time for schedule as HH:MM */
  startTime: Scalars['String'];
};

export type TariffScheduleOp = {
  __typename?: 'TariffScheduleOP';
  altId: Scalars['ID'];
  dayOfWeek: DayOfWeek;
  endTime: Scalars['String'];
  startTime: Scalars['String'];
  tariffItem?: Maybe<TariffItem>;
};

export enum TariffSyncStatus {
  NotSynced = 'NOT_SYNCED',
  Synced = 'SYNCED',
}

export type TariffWithMeta = {
  __typename?: 'TariffWithMeta';
  edges?: Maybe<Array<Maybe<Tariff>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<ListTariffsSearchType>;
  sort?: Maybe<ListTariffsSortType>;
  total?: Maybe<Scalars['Int']>;
};

export type TariffsForExtendWithMeta = {
  __typename?: 'TariffsForExtendWithMeta';
  edges?: Maybe<Array<TariffForExtend>>;
  total?: Maybe<Scalars['Int']>;
};

export enum TariffsSort {
  TariffDescriptionAsc = 'TARIFF_DESCRIPTION_ASC',
  TariffDescriptionDesc = 'TARIFF_DESCRIPTION_DESC',
  TariffNameAsc = 'TARIFF_NAME_ASC',
  TariffNameDesc = 'TARIFF_NAME_DESC',
  TariffRankAsc = 'TARIFF_RANK_ASC',
  TariffRankDesc = 'TARIFF_RANK_DESC',
}

export enum TerminationBehavior {
  /** Expires */
  Expires = 'EXPIRES',
  /** Reoccurs */
  Reoccurs = 'REOCCURS',
  /** Rollover */
  Rollover = 'ROLLOVER',
}

export enum TeslaConnectorType {
  /** Adapter */
  Adapter = 'ADAPTER',
  /** Connector */
  Connector = 'CONNECTOR',
  /** None */
  None = 'NONE',
}

export type ThirdPartyWalletInput = {
  /**  account's altId  */
  altId: Scalars['ID'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  startTime?: Maybe<Scalars['Date']>;
};

export type TimeSlotInput = {
  from?: InputMaybe<Scalars['SanitizeString']>;
  to?: InputMaybe<Scalars['SanitizeString']>;
};

export type TimeSlotType = {
  __typename?: 'TimeSlotType';
  from?: Maybe<Scalars['SanitizeString']>;
  to?: Maybe<Scalars['SanitizeString']>;
};

export type Timezone = {
  __typename?: 'Timezone';
  id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['String']>;
};

export type ToggleDemandLimitInput = {
  altId: Scalars['UUID'];
  demandLimitManagementEnabled: Scalars['Boolean'];
};

export type TouPrices = {
  __typename?: 'TouPrices';
  /** The name of the pricing schedule */
  name?: Maybe<Scalars['String']>;
  /** A number that represents the order of schedule */
  order?: Maybe<Scalars['Int']>;
  /** A string that represents the price in cents */
  price?: Maybe<Scalars['String']>;
  /** A string that represents the pricing per unit */
  priceUnit?: Maybe<Scalars['String']>;
  /** A an array of strings that represents the scheduled time of the price */
  schedule?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TouPricing = {
  __typename?: 'TouPricing';
  /** An int indicating the sort order for which the TouPricingByAggregate object should appear in */
  order?: Maybe<Scalars['Int']>;
  /** A float representing the price per price unit */
  price?: Maybe<Scalars['Float']>;
  /** A string indicating the price tier for time of use */
  priceTier?: Maybe<Scalars['String']>;
  /** An array of strings with time ranges */
  priceTierSchedule?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A string with units used for charging (will most likely be /kWh) */
  priceUnit?: Maybe<Scalars['String']>;
  /** A string indicating the number of reservations */
  reservations?: Maybe<Scalars['String']>;
  /** A float representing the credit amount for the subscription */
  subscriptionCredit?: Maybe<Scalars['Float']>;
  /** A float representing the fee for the subscription */
  subscriptionFee?: Maybe<Scalars['Float']>;
  /** A float representing the fee per transaction */
  transactionFee?: Maybe<Scalars['Float']>;
};

export type TouPricingByRange = {
  __typename?: 'TouPricingByRange';
  /** A float representing the max price per price unit */
  maxPrice?: Maybe<Scalars['Float']>;
  /** A float representing the min price per price unit */
  minPrice?: Maybe<Scalars['Float']>;
  /** An int indicating the sort order for which the TouPricingByAggregate object should appear in */
  order?: Maybe<Scalars['Int']>;
  /** A string indicating the price tier for time of use */
  priceTier?: Maybe<Scalars['String']>;
  /** An array of strings with time ranges */
  priceTierSchedule?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A string with units used for charging (will most likely be /kWh) */
  priceUnit?: Maybe<Scalars['String']>;
  /** A string indicating the number of reservations */
  reservations?: Maybe<Scalars['String']>;
  /** A float representing the credit amount for the subscription */
  subscriptionCredit?: Maybe<Scalars['Float']>;
  /** A float representing the fee for the subscription */
  subscriptionFee?: Maybe<Scalars['Float']>;
  /** A float representing the fee per transaction */
  transactionFee?: Maybe<Scalars['Float']>;
};

export type TouPricingInput = {
  planId?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['String']>;
};

export type TouRegion = {
  __typename?: 'TouRegion';
  /** An int representing the region id */
  id?: Maybe<Scalars['Int']>;
  /** A string representing the region name */
  name?: Maybe<Scalars['String']>;
  /** A string representing the region pricing tier */
  tier?: Maybe<Scalars['String']>;
};

export type TouRegionWithPricing = {
  __typename?: 'TouRegionWithPricing';
  /** A boolean used to flag the LA region */
  defaultValue?: Maybe<Scalars['Boolean']>;
  /** A float for the latitude coordinate representing the center of the region */
  latitude?: Maybe<Scalars['Float']>;
  /** A float for the longitude coordinate representing the center of the region */
  longitude?: Maybe<Scalars['Float']>;
  /** A boolean used to flag the region as the nearest to the POI ? */
  nearest?: Maybe<Scalars['Boolean']>;
  /** An int representing the id of the region */
  regionId?: Maybe<Scalars['Int']>;
  /** A string representing the name of the region */
  regionName?: Maybe<Scalars['String']>;
  /** A string representing the tier level for the region */
  tier?: Maybe<Scalars['String']>;
  /** An array of TouPricingByRegion objects nested under the region */
  touPricing?: Maybe<Array<Maybe<TouPricing>>>;
};

export type TouRegionWithPricingInput = {
  /** Required: The ID of the Plan */
  planId?: InputMaybe<Scalars['Int']>;
  /** Optional: The ID of the Region */
  regionId?: InputMaybe<Scalars['Int']>;
};

export type TouRegionWithRangePricing = {
  __typename?: 'TouRegionWithRangePricing';
  /** A boolean used to flag the LA region */
  defaultValue?: Maybe<Scalars['Boolean']>;
  /** A float for the latitude coordinate representing the center of the region */
  latitude?: Maybe<Scalars['Float']>;
  /** A float for the longitude coordinate representing the center of the region */
  longitude?: Maybe<Scalars['Float']>;
  /** A boolean used to flag the region as the nearest to the POI ? */
  nearest?: Maybe<Scalars['Boolean']>;
  /** An int representing the id of the region */
  regionId?: Maybe<Scalars['Int']>;
  /** A string representing the name of the region */
  regionName?: Maybe<Scalars['String']>;
  /** An array of TouPricingByAggregate objects nested under the region */
  touPricingByRange?: Maybe<Array<Maybe<TouPricingByRange>>>;
};

export type TouRegionWithRangePricingInput = {
  /** Required: The ID of the Plan */
  planId?: InputMaybe<Scalars['Int']>;
  /** Optional: The ID of the Region */
  regionId?: InputMaybe<Scalars['Int']>;
  /** Optional: The name of the Region */
  regionName?: InputMaybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  altId: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  authorizationType?: Maybe<Scalars['String']>;
  billingPlanProduct?: Maybe<Scalars['String']>;
  billingProductCategoryType?: Maybe<Scalars['String']>;
  billingTransactionStatus?: Maybe<Scalars['String']>;
  ccLast4Digits?: Maybe<Scalars['String']>;
  charger?: Maybe<Charger>;
  chargerId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  currency?: Maybe<Scalars['String']>;
  debit?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use transactionItems */
  items?: Maybe<Array<Maybe<TransactionItem>>>;
  maxPower?: Maybe<Scalars['String']>;
  paymentTxId?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  powerLoss?: Maybe<Scalars['Boolean']>;
  sessionEnd?: Maybe<Scalars['Date']>;
  sessionId?: Maybe<Scalars['ID']>;
  sessionStart?: Maybe<Scalars['Date']>;
  settlement?: Maybe<Scalars['String']>;
  settlementTime?: Maybe<Scalars['Date']>;
  siteId?: Maybe<Scalars['Int']>;
  siteName?: Maybe<Scalars['String']>;
  tax?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['String']>;
  transactionItems?: Maybe<Array<Maybe<TransactionItem>>>;
  vendorId?: Maybe<Scalars['ID']>;
  vendorNetworkId?: Maybe<Scalars['String']>;
};

export type TransactionItem = {
  __typename?: 'TransactionItem';
  altId?: Maybe<Scalars['ID']>;
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  price?: Maybe<Scalars['Float']>;
  productQuantity?: Maybe<Scalars['Float']>;
  productType?: Maybe<Scalars['String']>;
  productTypeId?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['ID']>;
  unitPrice?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['String']>;
};

export type TransactionItemV2 = {
  __typename?: 'TransactionItemV2';
  altId: Scalars['ID'];
  amount?: Maybe<Scalars['Float']>;
  /** Transaction amount supplemented with calculated costs for transactions falling under plans paid for by EVgo */
  calculatedAmount?: Maybe<Scalars['Float']>;
  /** Transaction amount unit price for transactions falling under plans paid for by EVgo */
  calculatedUnitPrice?: Maybe<Scalars['Float']>;
  comments?: Maybe<Scalars['String']>;
  productType?: Maybe<FalconConstraint>;
  quantity?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  transaction?: Maybe<TransactionV2>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export type TransactionItemsWithMeta = {
  __typename?: 'TransactionItemsWithMeta';
  edges?: Maybe<Array<Maybe<TransactionItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TransactionV2 = {
  __typename?: 'TransactionV2';
  altId: Scalars['ID'];
  amount?: Maybe<Scalars['String']>;
  billingPlanProduct?: Maybe<Scalars['String']>;
  billingProductCategoryType?: Maybe<Scalars['String']>;
  billingTransactionStatus?: Maybe<Scalars['String']>;
  /** Transaction amount supplemented with calculated costs for transactions falling under plans paid for by EVgo */
  calculatedAmount?: Maybe<Scalars['Float']>;
  /** The corresponding tax amount for the calculated amount */
  calculatedTaxAmount?: Maybe<Scalars['Float']>;
  charger?: Maybe<ChargerV2>;
  createdAt?: Maybe<Scalars['Date']>;
  debit?: Maybe<Scalars['Boolean']>;
  /** The driver who initiated the transaction. Only available for superAdmin users. */
  driver?: Maybe<Driver>;
  id: Scalars['ID'];
  /** Indicates the transaction amounts have been supplemented with calculated costs */
  isCalculated?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<TransactionItemV2>>>;
  session?: Maybe<SessionV2>;
  tax?: Maybe<Scalars['Float']>;
  type?: Maybe<FalconConstraint>;
  vendorId?: Maybe<Scalars['ID']>;
};

export enum TransactionsV2Sort {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ChargerNameAsc = 'CHARGER_NAME_ASC',
  ChargerNameDesc = 'CHARGER_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  EndDateLocalAsc = 'END_DATE_LOCAL_ASC',
  EndDateLocalDesc = 'END_DATE_LOCAL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KwhAsc = 'KWH_ASC',
  KwhDesc = 'KWH_DESC',
  PlanNameAsc = 'PLAN_NAME_ASC',
  PlanNameDesc = 'PLAN_NAME_DESC',
  SiteNameAsc = 'SITE_NAME_ASC',
  SiteNameDesc = 'SITE_NAME_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StartDateLocalAsc = 'START_DATE_LOCAL_ASC',
  StartDateLocalDesc = 'START_DATE_LOCAL_DESC',
  TaxAsc = 'TAX_ASC',
  TaxDesc = 'TAX_DESC',
  TaxRateAsc = 'TAX_RATE_ASC',
  TaxRateDesc = 'TAX_RATE_DESC',
  VendorIdAsc = 'VENDOR_ID_ASC',
  VendorIdDesc = 'VENDOR_ID_DESC',
}

export type TransactionsV2WithMeta = {
  __typename?: 'TransactionsV2WithMeta';
  edges?: Maybe<Array<Maybe<TransactionV2>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type TransactionsWithMeta = {
  __typename?: 'TransactionsWithMeta';
  edges?: Maybe<Array<Maybe<Transaction>>>;
  total?: Maybe<Scalars['Int']>;
};

export type UnassignCardsInput = {
  cardIds: Array<Scalars['UUID']>;
};

export type UnassociatedChargerForFleet = {
  __typename?: 'UnassociatedChargerForFleet';
  altId: Scalars['ID'];
  chargerModel?: Maybe<ChargerModel>;
  chargerName?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['SanitizeString']>;
};

export type UnassociatedChargersForFleetWithMeta = {
  __typename?: 'UnassociatedChargersForFleetWithMeta';
  edges?: Maybe<Array<Maybe<UnassociatedChargerForFleet>>>;
};

export enum UnitType {
  /** All in One */
  AllInOne = 'ALL_IN_ONE',
  /** Cabinet */
  Cabinet = 'CABINET',
  /** Dispenser */
  Dispenser = 'DISPENSER',
}

export type UpdateAccountInput = {
  accountStatus?: InputMaybe<AccountStatus>;
  altId: Scalars['ID'];
  billingAddress?: InputMaybe<UpdateAddressInput>;
  enrolledOn?: InputMaybe<Scalars['Date']>;
  evcId?: InputMaybe<Scalars['SanitizeString']>;
  isFcms?: InputMaybe<Scalars['Boolean']>;
  mailingAddress?: InputMaybe<UpdateAddressInput>;
  vendorId?: InputMaybe<Scalars['Int']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']>;
};

export type UpdateAccountOwnerInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  accountStatus?: InputMaybe<AccountStatus>;
  balanceType?: InputMaybe<Scalars['ID']>;
  billingAddress?: InputMaybe<UpdateAddressInput>;
  driverId?: InputMaybe<Scalars['ID']>;
  driverStatusId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  enrolledOn?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyPhone?: InputMaybe<Scalars['SanitizeString']>;
  mailingAddress?: InputMaybe<UpdateAddressInput>;
  notificationPreferences?: InputMaybe<Array<InputMaybe<UpdateNotificationPreferencesInput>>>;
  phone?: InputMaybe<Scalars['String']>;
  vendorAccountType?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type UpdateAccountVehicleInput = {
  accountId: Scalars['UUID'];
  altId: Scalars['UUID'];
  autochargeFlag?: InputMaybe<Scalars['Boolean']>;
  driverId?: InputMaybe<Scalars['UUID']>;
  macAddress?: InputMaybe<Scalars['SanitizeString']>;
  media?: InputMaybe<GetMediaInput>;
  nickName?: InputMaybe<Scalars['SanitizeString']>;
  vehicleId: Scalars['UUID'];
  vin?: InputMaybe<Scalars['SanitizeString']>;
};

export type UpdateAdHocContractInput = {
  accountId: Scalars['ID'];
  amount: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
};

export type UpdateAddressInput = {
  address1: Scalars['SanitizeString'];
  address2?: InputMaybe<Scalars['SanitizeString']>;
  address3?: InputMaybe<Scalars['SanitizeString']>;
  address4?: InputMaybe<Scalars['SanitizeString']>;
  /** administrativeArea must be the 2-character state-code for US addresses (CA, OR, AZ), or the full name of the province for Canadian addresses (Quebec, Ontario, British Columbia) */
  administrativeArea?: InputMaybe<Scalars['SanitizeString']>;
  altId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<Scalars['SanitizeString']>;
  locality?: InputMaybe<Scalars['SanitizeString']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']>;
};

export type UpdateCardInput = {
  altId: Scalars['UUID'];
  brand?: InputMaybe<Scalars['String']>;
  cardStatus?: InputMaybe<Scalars['String']>;
  driverId?: InputMaybe<Scalars['UUID']>;
  nickName?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']>;
};

export type UpdateCardOrdersInput = {
  cardOrderIds: Array<Scalars['ID']>;
};

export type UpdateCardStatusInput = {
  cardAltId: Scalars['String'];
  cardStatus: CardStatus;
  comments?: InputMaybe<Scalars['String']>;
};

/** Input for updating a charger group plan */
export type UpdateChargerGroupPlanForExtendInput = {
  altId: Scalars['ID'];
  chargerGroupName: Scalars['SanitizeString'];
  description: Scalars['SanitizeString'];
  /** The amount of time in months that the plan will be valid */
  planDuration: Scalars['Int'];
};

export type UpdateChargerInput = {
  additionalEquipment?: InputMaybe<Scalars['SanitizeString']>;
  additionalSerial?: InputMaybe<Scalars['SanitizeString']>;
  altId?: InputMaybe<Scalars['ID']>;
  authenticationMethodsConstraint?: InputMaybe<Scalars['Int']>;
  authenticationModeId?: InputMaybe<Scalars['Int']>;
  brand?: InputMaybe<Scalars['SanitizeString']>;
  ccReaderType?: InputMaybe<Scalars['SanitizeString']>;
  certified?: InputMaybe<Scalars['Boolean']>;
  chargePointIdentity?: InputMaybe<Scalars['SanitizeString']>;
  chargerGroups?: InputMaybe<Array<InputMaybe<Scalars['SanitizeString']>>>;
  chargerHost?: InputMaybe<Scalars['SanitizeString']>;
  chargerIsManaged?: InputMaybe<Scalars['Boolean']>;
  chargerModelId?: InputMaybe<Scalars['ID']>;
  chargerName?: InputMaybe<Scalars['SanitizeString']>;
  chargerStatusId?: InputMaybe<Scalars['Int']>;
  cid?: InputMaybe<Scalars['SanitizeString']>;
  coBranding?: InputMaybe<Scalars['SanitizeString']>;
  communicationMethod?: InputMaybe<Scalars['SanitizeString']>;
  connectionDate?: InputMaybe<Scalars['SanitizeString']>;
  connectionUri?: InputMaybe<Scalars['SanitizeString']>;
  connectorTypes?: InputMaybe<Array<InputMaybe<Scalars['SanitizeString']>>>;
  description?: InputMaybe<Scalars['SanitizeString']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  displayTariffSynced?: InputMaybe<Scalars['Boolean']>;
  equipmentId?: InputMaybe<Scalars['SanitizeString']>;
  evcId?: InputMaybe<Scalars['ID']>;
  evseId?: InputMaybe<Scalars['SanitizeString']>;
  evses?: InputMaybe<Array<InputMaybe<EvseInput>>>;
  excludeFromOcpi?: InputMaybe<Scalars['Boolean']>;
  excluded?: InputMaybe<Scalars['Boolean']>;
  fieldStationStatus?: InputMaybe<FieldStationStatus>;
  firmWareVersion?: InputMaybe<Scalars['SanitizeString']>;
  firstActive?: InputMaybe<Scalars['SanitizeString']>;
  fundingSource?: InputMaybe<Scalars['SanitizeString']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  identityKey?: InputMaybe<Scalars['SanitizeString']>;
  ignoreStatusNotifications?: InputMaybe<Scalars['Boolean']>;
  infrastructureProviderCompanyId?: InputMaybe<Scalars['Int']>;
  installDate?: InputMaybe<Scalars['SanitizeString']>;
  ipAddress?: InputMaybe<Scalars['SanitizeString']>;
  lastReportedFirmwareVersion?: InputMaybe<Scalars['SanitizeString']>;
  localLoadBalancingEnabled?: InputMaybe<Scalars['Boolean']>;
  localNetworkMaster?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['ID']>;
  locationType?: InputMaybe<Scalars['SanitizeString']>;
  macAddress?: InputMaybe<Scalars['SanitizeString']>;
  maintenanceComments?: InputMaybe<Scalars['String']>;
  maintenanceVendorId?: InputMaybe<Scalars['Int']>;
  maxPower?: InputMaybe<Scalars['Int']>;
  meterSigningSupported?: InputMaybe<Scalars['Boolean']>;
  modelNotes?: InputMaybe<Scalars['SanitizeString']>;
  modelNumber?: InputMaybe<Scalars['SanitizeString']>;
  modemSerialNumber?: InputMaybe<Scalars['String']>;
  mountType?: InputMaybe<Scalars['SanitizeString']>;
  networkConnectionTypeId?: InputMaybe<Scalars['Int']>;
  nextMaintenanceDate?: InputMaybe<Scalars['Date']>;
  nonNetworked?: InputMaybe<Scalars['Boolean']>;
  ports?: InputMaybe<Array<InputMaybe<PortInput>>>;
  productIdentityKeyId?: InputMaybe<Scalars['Int']>;
  serialNumber?: InputMaybe<Scalars['SanitizeString']>;
  serverSideLocalLoadBalancingEnabled?: InputMaybe<Scalars['Boolean']>;
  setTariffSynced?: InputMaybe<Scalars['Boolean']>;
  showInThirdPartyFilters?: InputMaybe<Scalars['Boolean']>;
  simCardIccid?: InputMaybe<Scalars['SanitizeString']>;
  simCardImsi?: InputMaybe<Scalars['SanitizeString']>;
  simultaneousChargingEnabled?: InputMaybe<Scalars['Boolean']>;
  smartChargingEnabled?: InputMaybe<Scalars['Boolean']>;
  teslaAdapter?: InputMaybe<Scalars['Boolean']>;
  underEaProgram?: InputMaybe<Scalars['Boolean']>;
  updateVendorId?: InputMaybe<Scalars['Int']>;
  usageStartDate?: InputMaybe<Scalars['SanitizeString']>;
  validateMeterSignature?: InputMaybe<Scalars['Boolean']>;
  vendorId?: InputMaybe<Scalars['Int']>;
  warehouseLocation?: InputMaybe<Scalars['SanitizeString']>;
  warrantyExpirationDate?: InputMaybe<Scalars['Date']>;
  warrantyLengthId?: InputMaybe<Scalars['Int']>;
  warrantyStartDate?: InputMaybe<Scalars['Date']>;
  warrantyStatusId?: InputMaybe<Scalars['Int']>;
  wirelessConnectionCarrierId?: InputMaybe<Scalars['Int']>;
};

export type UpdateChargerModelInput = {
  additionalDetails?: InputMaybe<Scalars['SanitizeString']>;
  altId: Scalars['ID'];
  autoChargeSupported?: InputMaybe<Scalars['Boolean']>;
  breakerRating?: InputMaybe<Scalars['Float']>;
  chargerModelEvses?: InputMaybe<Array<InputMaybe<ChargerModelEvseInput>>>;
  communicationProtocol?: InputMaybe<Scalars['String']>;
  currentThd?: InputMaybe<Scalars['SanitizeString']>;
  dimensions?: InputMaybe<Scalars['SanitizeString']>;
  display?: InputMaybe<Scalars['SanitizeString']>;
  efficiency?: InputMaybe<Scalars['SanitizeString']>;
  evses?: InputMaybe<Array<InputMaybe<ChargerModelEvseInput>>>;
  firmwareVersion?: InputMaybe<Scalars['SanitizeString']>;
  ingressProtection?: InputMaybe<Scalars['SanitizeString']>;
  inputAcVoltage?: InputMaybe<InputAcVoltage>;
  interfaceVersion?: InputMaybe<InterfaceVersion>;
  isoIec?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Manufacturer>;
  modelName?: InputMaybe<Scalars['SanitizeString']>;
  modelNotes?: InputMaybe<Scalars['String']>;
  modelSku?: InputMaybe<Scalars['SanitizeString']>;
  mountType?: InputMaybe<Scalars['String']>;
  networkCommunication?: InputMaybe<NetworkCommunication>;
  nfcReader?: InputMaybe<Scalars['Boolean']>;
  ports?: InputMaybe<Array<InputMaybe<PortInput>>>;
  powerFactor?: InputMaybe<Scalars['Float']>;
  powerOutput?: InputMaybe<Scalars['PowerUnit']>;
  simultaneousChargeSupported?: InputMaybe<Scalars['Boolean']>;
  smartEnergyProfileSupport?: InputMaybe<Scalars['Boolean']>;
  unitType?: InputMaybe<UnitType>;
  weight?: InputMaybe<Scalars['SanitizeString']>;
};

/** Input for updating a charger group */
export type UpdateChargerPriceGroupForExtendInput = {
  altId: Scalars['ID'];
  chargerGroupName?: InputMaybe<Scalars['SanitizeString']>;
  description?: InputMaybe<Scalars['SanitizeString']>;
};

export type UpdateConnectorInput = {
  altId: Scalars['ID'];
  chargingProfileStatus?: InputMaybe<ChargingProfileStatus>;
  connectorPlacement?: InputMaybe<ConnectorPlacement>;
  isThrottling?: InputMaybe<Scalars['Boolean']>;
  requestedPowerLimit?: InputMaybe<Scalars['Float']>;
  teslaConnectorType?: InputMaybe<TeslaConnectorType>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type UpdateConnectorPowerLimitInput = {
  altId: Scalars['ID'];
  powerLimit: Scalars['Float'];
  reasonString?: InputMaybe<Scalars['String']>;
};

export type UpdateConnectorReservableInput = {
  /** ID of the connector to update */
  altId: Scalars['ID'];
  /** Allows the connector to be reserved */
  reservable: Scalars['Boolean'];
};

export type UpdateContractInput = {
  altId: Scalars['ID'];
  closureDate?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  statusId?: InputMaybe<Scalars['Int']>;
};

export type UpdateCustomPricingForExtendInput = {
  altId?: InputMaybe<Scalars['ID']>;
  displayDescription1?: InputMaybe<Scalars['SanitizeString']>;
  displayDescription2?: InputMaybe<Scalars['SanitizeString']>;
  displayName?: InputMaybe<Scalars['SanitizeString']>;
  displayRank?: InputMaybe<Scalars['Int']>;
  pricing: UpdatePricingForExtendInput;
  /** Schedule the pricing will apply during */
  tariffSchedules: Array<CreateTariffScheduleForExtendInput>;
};

export type UpdateDriverInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  altId?: InputMaybe<Scalars['ID']>;
  driverStatusId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  enrolledOn?: InputMaybe<Scalars['Date']>;
  firebaseId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  invited?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  legacyPhone?: InputMaybe<Scalars['SanitizeString']>;
  notificationPreferences?: InputMaybe<Array<InputMaybe<UpdateNotificationPreferencesInput>>>;
  phone?: InputMaybe<Scalars['Phone']>;
  tokenAndroid?: InputMaybe<Scalars['String']>;
  tokenIos?: InputMaybe<Scalars['String']>;
  vendorDriverStatus?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type UpdateExtendUserHostsInput = {
  /** Unique UUID of the user */
  altId: Scalars['ID'];
  /** ID stored in firebase for the user */
  firebaseId?: InputMaybe<Scalars['String']>;
  /** IDs of the hosts which are being added/updated on the user */
  hostIds: Array<Scalars['ID']>;
  /** Unique username string */
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateFilterInput = {
  criteria?: InputMaybe<Scalars['SanitizeString']>;
  exclude?: InputMaybe<Scalars['Boolean']>;
  operator?: InputMaybe<Scalars['SanitizeString']>;
  values?: InputMaybe<Array<InputMaybe<UpdateFilterValueInput>>>;
};

export type UpdateFilterValueInput = {
  label?: InputMaybe<Scalars['SanitizeString']>;
  valid?: InputMaybe<Scalars['Boolean']>;
  value: Scalars['SanitizeString'];
};

export type UpdateFleetUserInput = {
  accountId: Scalars['ID'];
  firebaseId: Scalars['String'];
};

export type UpdateHostInput = {
  address1?: InputMaybe<Scalars['SanitizeString']>;
  address2?: InputMaybe<Scalars['SanitizeString']>;
  address3?: InputMaybe<Scalars['SanitizeString']>;
  address4?: InputMaybe<Scalars['SanitizeString']>;
  administrativeArea?: InputMaybe<Scalars['SanitizeString']>;
  altId?: InputMaybe<Scalars['ID']>;
  country?: InputMaybe<Scalars['SanitizeString']>;
  hid?: InputMaybe<Scalars['SanitizeString']>;
  hostName?: InputMaybe<Scalars['SanitizeString']>;
  locality?: InputMaybe<Scalars['SanitizeString']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']>;
  status?: InputMaybe<HostStatus>;
  vendorId?: InputMaybe<Scalars['SanitizeString']>;
};

export type UpdateNotificationPreferencesInput = {
  driverId?: InputMaybe<Scalars['ID']>;
  emailOn?: InputMaybe<Scalars['Boolean']>;
  notificationItemId?: InputMaybe<Scalars['ID']>;
  pushOn?: InputMaybe<Scalars['Boolean']>;
  smsOn?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePasswordInput = {
  password: Scalars['String'];
};

export type UpdatePlanInput = {
  altId: Scalars['ID'];
  badge?: InputMaybe<Scalars['SanitizeString']>;
  /** Id of the balance type from falcon_constraints to use for this plan. */
  balanceTypeId?: InputMaybe<Scalars['Int']>;
  cardBrandId?: InputMaybe<Scalars['Int']>;
  costFactor?: InputMaybe<Scalars['Float']>;
  ctaLabel?: InputMaybe<Scalars['SanitizeString']>;
  defaultAccountPlan?: InputMaybe<Scalars['Boolean']>;
  detailHeading?: InputMaybe<Scalars['SanitizeString']>;
  detailSections?: InputMaybe<Array<InputMaybe<DetailSectionInput>>>;
  detailSessionTimes?: InputMaybe<Array<InputMaybe<DetailSessionTimeInput>>>;
  displayPlanCard?: InputMaybe<Scalars['Boolean']>;
  displayToCustomer?: InputMaybe<Scalars['Boolean']>;
  displayToLoggedOutUser?: InputMaybe<Scalars['Boolean']>;
  driverRank?: InputMaybe<Scalars['Int']>;
  elements?: InputMaybe<Array<InputMaybe<ElementInput>>>;
  enrollmentClose?: InputMaybe<Scalars['Date']>;
  enrollmentOpen?: InputMaybe<Scalars['Date']>;
  financeCode?: InputMaybe<Scalars['SanitizeString']>;
  intervalLength?: InputMaybe<Scalars['Int']>;
  intervalUnitId?: InputMaybe<Scalars['Int']>;
  isAutoEnrolledInRewards?: InputMaybe<Scalars['Boolean']>;
  legalese?: InputMaybe<Scalars['String']>;
  planCode?: InputMaybe<Scalars['SanitizeString']>;
  planDescription?: InputMaybe<Scalars['SanitizeString']>;
  planDiscount?: InputMaybe<Scalars['Float']>;
  planDisplayName?: InputMaybe<Scalars['SanitizeString']>;
  planDuration?: InputMaybe<Scalars['Int']>;
  planName?: InputMaybe<Scalars['SanitizeString']>;
  planStatusId?: InputMaybe<Scalars['Int']>;
  planSubheading?: InputMaybe<Scalars['SanitizeString']>;
  products?: InputMaybe<Array<InputMaybe<ProductInput>>>;
  rolloverPlanId?: InputMaybe<Scalars['ID']>;
  templateId?: InputMaybe<Scalars['Int']>;
  terminationBehaviorId?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  vinRequiredFlag?: InputMaybe<Scalars['Boolean']>;
};

export type UpdatePlanTariffForExtendInput = {
  altId?: InputMaybe<Scalars['ID']>;
  /** Charger group altIds tariff will apply to.  Only groups with a type of CHARGER_GROUP are permitted */
  chargerGroupIds: Array<Scalars['ID']>;
  /** Time of use pricing for the Tariff */
  customPricing?: InputMaybe<Array<UpdateCustomPricingForExtendInput>>;
};

export type UpdatePlanTariffsForExtendInput = {
  /** ID for custom plan.  Only groups with a type of CUSTOM_ACCESS_PLAN are permitted */
  chargerGroupId: Scalars['ID'];
  planTariffs: Array<UpdatePlanTariffForExtendInput>;
};

export type UpdatePricingForExtendInput = {
  perKwh?: InputMaybe<Scalars['TariffPrice']>;
  perMinute?: InputMaybe<Scalars['TariffPrice']>;
  perSession?: InputMaybe<Scalars['TariffPrice']>;
};

export type UpdatePropertyInput = {
  altId?: InputMaybe<Scalars['ID']>;
  host?: InputMaybe<UpdateHostInput>;
  pid: Scalars['SanitizeString'];
  status?: InputMaybe<Scalars['SanitizeString']>;
  vendorId?: InputMaybe<Scalars['SanitizeString']>;
};

export type UpdateRewardsUserInput = {
  accountNumber: Scalars['ID'];
  firstLogin?: InputMaybe<Scalars['Boolean']>;
  swellEmail?: InputMaybe<Scalars['String']>;
  welcomeEmailSent?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSessionInput = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  accountVendorId?: InputMaybe<Scalars['ID']>;
  cardVendorId?: InputMaybe<Scalars['ID']>;
  connectorVendorId?: InputMaybe<Scalars['ID']>;
  corrupted?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['Date']>;
  isSessionEnd?: InputMaybe<Scalars['Boolean']>;
  meterEnd?: InputMaybe<Scalars['Int']>;
  meterStart?: InputMaybe<Scalars['Int']>;
  planVendorId?: InputMaybe<Scalars['ID']>;
  startSource?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['Date']>;
  stopSource?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['ID'];
};

export type UpdateSiteForExtendInput = {
  /** Notes regarding access to each stall on the site */
  accessNotes?: InputMaybe<Scalars['SanitizeString']>;
  /** Unique UUID for the site */
  altId: Scalars['ID'];
  /** Description of the site */
  description?: InputMaybe<Scalars['SanitizeString']>;
  /** Directions to the site */
  directions?: InputMaybe<Scalars['SanitizeString']>;
  /** Name of the site that should be displayed when rendered elsewhere */
  displayName?: InputMaybe<Scalars['SanitizeString']>;
  /** Notes regarding the site location */
  locationNotes?: InputMaybe<Scalars['SanitizeString']>;
  /** Name of the site */
  siteName?: InputMaybe<Scalars['SanitizeString']>;
};

export type UpdateSiteImageInput = {
  altId?: InputMaybe<Scalars['ID']>;
  media?: InputMaybe<GetMediaInput>;
  mediaOrder?: InputMaybe<Scalars['Int']>;
};

export type UpdateSiteInput = {
  access?: InputMaybe<SiteAccess>;
  accessNotes?: InputMaybe<Scalars['SanitizeString']>;
  address1: Scalars['SanitizeString'];
  address2?: InputMaybe<Scalars['SanitizeString']>;
  address3?: InputMaybe<Scalars['SanitizeString']>;
  address4?: InputMaybe<Scalars['SanitizeString']>;
  administrativeArea: Scalars['SanitizeString'];
  altId: Scalars['ID'];
  country?: InputMaybe<Scalars['SanitizeString']>;
  demandLimitManagementEnabled?: InputMaybe<Scalars['Boolean']>;
  description: Scalars['SanitizeString'];
  directions?: InputMaybe<Scalars['SanitizeString']>;
  displayName: Scalars['SanitizeString'];
  evcId?: InputMaybe<Scalars['SanitizeString']>;
  hid?: InputMaybe<Scalars['SanitizeString']>;
  hostId?: InputMaybe<Scalars['SanitizeString']>;
  hoursOfOperation?: InputMaybe<HoursInput>;
  latitude: Scalars['Float'];
  locality: Scalars['SanitizeString'];
  locationMarket?: InputMaybe<SiteMarket>;
  locationNotes?: InputMaybe<Scalars['SanitizeString']>;
  locationTypeId?: InputMaybe<Scalars['Int']>;
  longitude: Scalars['Float'];
  maxCurrent?: InputMaybe<Scalars['SanitizeString']>;
  meterNumber?: InputMaybe<Scalars['SanitizeString']>;
  postalCode: Scalars['SanitizeString'];
  property: UpdatePropertyInput;
  ratedVoltage?: InputMaybe<Scalars['SanitizeString']>;
  retailDemandLimit?: InputMaybe<Scalars['Float']>;
  sid: Scalars['SanitizeString'];
  siteAccessId?: InputMaybe<Scalars['Int']>;
  siteImages?: InputMaybe<Array<InputMaybe<UpdateSiteImageInput>>>;
  siteName: Scalars['SanitizeString'];
  siteStatusId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<SiteType>;
  utilityCompany?: InputMaybe<SiteUtilityCompany>;
};

export type UpdateSiteReexInput = {
  reexDate: Scalars['Date'];
  reexOutcome: ReexOutcome;
  sid: Scalars['String'];
};

export type UpdateTagInput = {
  altId: Scalars['ID'];
  description?: InputMaybe<Scalars['SanitizeString']>;
  filters?: InputMaybe<Array<InputMaybe<UpdateFilterInput>>>;
  tagName?: InputMaybe<Scalars['SanitizeString']>;
};

export type UpdateTariffForExtendInput = {
  /** altId of the Tariff to update */
  altId?: InputMaybe<Scalars['ID']>;
  /** list of chargerGroupIds that will be associated with the Tariff */
  chargerGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Time of use pricing for the Tariff */
  customPricing?: InputMaybe<Array<UpdateCustomPricingForExtendInput>>;
  defaultPricing?: InputMaybe<DefaultPricingForExtendInput>;
  /** display name for the Tariff for HMI display */
  displayName?: InputMaybe<Scalars['SanitizeString']>;
  /** name for the Tariff */
  name?: InputMaybe<Scalars['SanitizeString']>;
  reservationTerm?: InputMaybe<TariffItemReservationTermForExtendInput>;
};

export type UpdateUserInput = {
  altId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  evcId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<UserRole>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpdateVehicleInput = {
  connectorRefArray?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['ID'];
  makeRefId?: InputMaybe<Scalars['Int']>;
  modelRefId?: InputMaybe<Scalars['Int']>;
  modelYear?: InputMaybe<Scalars['Int']>;
};

export type UpdateVehicleMakeInput = {
  id: Scalars['ID'];
  manufacturer?: InputMaybe<Scalars['String']>;
  vendorValue?: InputMaybe<Scalars['String']>;
  vpicId?: InputMaybe<Scalars['Int']>;
  vpicValue?: InputMaybe<Scalars['String']>;
};

export type UpdateVehicleModelInput = {
  id: Scalars['ID'];
  makeRefId?: InputMaybe<Scalars['Int']>;
  modelName?: InputMaybe<Scalars['String']>;
  vendorValue?: InputMaybe<Scalars['String']>;
  vpicId?: InputMaybe<Scalars['Int']>;
  vpicValue?: InputMaybe<Scalars['String']>;
};

export type UploadsWithMeta = {
  __typename?: 'UploadsWithMeta';
  edges?: Maybe<Array<Maybe<Media>>>;
  total?: Maybe<Scalars['Int']>;
};

export type UpsertCardInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  altId?: InputMaybe<Scalars['UUID']>;
  brand?: InputMaybe<Scalars['String']>;
  cardStatus?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  driverId?: InputMaybe<Scalars['ID']>;
  externalNumber?: InputMaybe<Scalars['String']>;
  internalNumber?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  vendorCardBrand?: InputMaybe<Scalars['String']>;
  vendorCardStatus?: InputMaybe<Scalars['String']>;
  vendorCardType?: InputMaybe<Scalars['String']>;
  vendorDriverId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
  vendorSyncedAt?: InputMaybe<Scalars['Date']>;
};

export type UpsertCardOrderInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  altId?: InputMaybe<Scalars['UUID']>;
  cardOrderStatusTimestamp?: InputMaybe<Scalars['Date']>;
  cardStatus?: InputMaybe<Scalars['String']>;
  /** Sent from lambda to help determine if the event included a card status change */
  cardStatusChanged?: InputMaybe<Scalars['Boolean']>;
  driverId?: InputMaybe<Scalars['ID']>;
  vendorCardId?: InputMaybe<Scalars['ID']>;
  vendorCardStatus?: InputMaybe<Scalars['String']>;
  vendorDriverId?: InputMaybe<Scalars['ID']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type UpsertExtendHmiDisplayLabelsInput = {
  column1Description1?: InputMaybe<Scalars['SanitizeString']>;
  column1Description2?: InputMaybe<Scalars['SanitizeString']>;
  column1Title?: InputMaybe<Scalars['SanitizeString']>;
  column2Description1?: InputMaybe<Scalars['SanitizeString']>;
  column2Description2?: InputMaybe<Scalars['SanitizeString']>;
  column2Title?: InputMaybe<Scalars['SanitizeString']>;
  column3Description1?: InputMaybe<Scalars['SanitizeString']>;
  column3Description2?: InputMaybe<Scalars['SanitizeString']>;
  column3Title?: InputMaybe<Scalars['SanitizeString']>;
  row1Description1?: InputMaybe<Scalars['SanitizeString']>;
  row1Description2?: InputMaybe<Scalars['SanitizeString']>;
  row1Description3?: InputMaybe<Scalars['SanitizeString']>;
  row1Title?: InputMaybe<Scalars['SanitizeString']>;
  row2Description1?: InputMaybe<Scalars['SanitizeString']>;
  row2Description2?: InputMaybe<Scalars['SanitizeString']>;
  row2Description3?: InputMaybe<Scalars['SanitizeString']>;
  row2Title?: InputMaybe<Scalars['SanitizeString']>;
  row3Description1?: InputMaybe<Scalars['SanitizeString']>;
  row3Description2?: InputMaybe<Scalars['SanitizeString']>;
  row3Description3?: InputMaybe<Scalars['SanitizeString']>;
  row3Title?: InputMaybe<Scalars['SanitizeString']>;
};

export type UpsertPaymentMethodInput = {
  accountVendorId: Scalars['ID'];
  braintreeCustomerId: Scalars['String'];
  cardNumber: Scalars['String'];
  expired?: InputMaybe<Scalars['Boolean']>;
  expiryMonth: Scalars['Int'];
  expiryYear: Scalars['Int'];
  nameOnCard: Scalars['String'];
  primary?: InputMaybe<Scalars['Boolean']>;
  token: Scalars['String'];
  vendorId: Scalars['ID'];
  vendorPaymentMethodType: Scalars['String'];
};

export type UpsertSessionMeterInput = {
  connectorVendorId: Scalars['ID'];
  eventDate: Scalars['Date'];
  sessionVendorId: Scalars['ID'];
  value: Scalars['Float'];
  vendorMeterand: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  accountId?: Maybe<Scalars['ID']>;
  altId: Scalars['ID'];
  firebaseId?: Maybe<Scalars['String']>;
  userRole?: Maybe<Array<Maybe<UserRole>>>;
  username?: Maybe<Scalars['String']>;
};

export enum UserRole {
  Driver = 'DRIVER',
  Operator = 'OPERATOR',
}

export type UserRoles = {
  __typename?: 'UserRoles';
  falcon?: Maybe<Array<Maybe<Scalars['String']>>>;
  fcm?: Maybe<Array<Maybe<Scalars['String']>>>;
  operatorPortal?: Maybe<Array<Maybe<Scalars['String']>>>;
  portals?: Maybe<Array<Maybe<Scalars['String']>>>;
  receiptPortal?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ValidateAddressInput = {
  address1: Scalars['SanitizeString'];
  address2?: InputMaybe<Scalars['SanitizeString']>;
  address3?: InputMaybe<Scalars['SanitizeString']>;
  address4?: InputMaybe<Scalars['SanitizeString']>;
  /** administrativeArea must be the 2-character state-code for US addresses (CA, OR, AZ), or the full name of the province for Canadian addresses (Quebec, Ontario, British Columbia) */
  administrativeArea?: InputMaybe<Scalars['SanitizeString']>;
  country?: InputMaybe<Scalars['SanitizeString']>;
  latitude?: InputMaybe<Scalars['SanitizeString']>;
  locality?: InputMaybe<Scalars['SanitizeString']>;
  longitude?: InputMaybe<Scalars['SanitizeString']>;
  postalCode?: InputMaybe<Scalars['SanitizeString']>;
};

export type ValidateTagInput = {
  altId: Scalars['ID'];
  description?: InputMaybe<Scalars['SanitizeString']>;
  filters?: InputMaybe<Array<InputMaybe<UpdateFilterInput>>>;
  tagName?: InputMaybe<Scalars['SanitizeString']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  altId?: Maybe<Scalars['ID']>;
  autochargeStatus: FalconConstraint;
  id?: Maybe<Scalars['ID']>;
  makeModelYear?: Maybe<Scalars['String']>;
  vehicleConnectors?: Maybe<Array<Maybe<VehicleConnector>>>;
};

export type VehicleConnector = {
  __typename?: 'VehicleConnector';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type VehicleConnectorInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type VehicleConnectorsWithMeta = {
  __typename?: 'VehicleConnectorsWithMeta';
  edges?: Maybe<Array<Maybe<VehicleConnector>>>;
  total?: Maybe<Scalars['Int']>;
};

export type VehicleInput = {
  modelName?: InputMaybe<Scalars['String']>;
  vehicleMake?: InputMaybe<VehicleMakeInput>;
  vehicleYears?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

/** DMS */
export type VehicleMake = {
  __typename?: 'VehicleMake';
  altId?: Maybe<Scalars['ID']>;
  manufacturer?: Maybe<Scalars['String']>;
};

export type VehicleMakeInput = {
  makeName?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type VehicleMakeV2 = {
  __typename?: 'VehicleMakeV2';
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  manufacturer: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  vendorValue?: Maybe<Scalars['String']>;
  vpicId?: Maybe<Scalars['Int']>;
  vpicValue?: Maybe<Scalars['String']>;
};

/** DMS */
export type VehicleModel = {
  __typename?: 'VehicleModel';
  /** altId refers to manufacturer_id in DMS */
  altId?: Maybe<Scalars['ID']>;
  modelName?: Maybe<Scalars['String']>;
  /**
   * vehicleMake will never be returned, based
   * on DB query and Service layer logic
   */
  vehicleMake?: Maybe<VehicleMake>;
};

export type VehicleModelV2 = {
  __typename?: 'VehicleModelV2';
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  makeRefId: Scalars['Int'];
  modelName: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  vendorValue?: Maybe<Scalars['String']>;
  vpicId?: Maybe<Scalars['Int']>;
  vpicValue?: Maybe<Scalars['String']>;
};

export type VehicleV2 = {
  __typename?: 'VehicleV2';
  connectorRefArray: Array<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  makeRefId: Scalars['Int'];
  modelRefId: Scalars['Int'];
  modelYear: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type VehiclesWithMeta = {
  __typename?: 'VehiclesWithMeta';
  edges?: Maybe<Array<Maybe<Vehicle>>>;
  pageSize?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type VerifyDriverEmailInput = {
  altId: Scalars['String'];
};

export type Warehouse = {
  __typename?: 'Warehouse';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  administrativeArea?: Maybe<Scalars['String']>;
  altId?: Maybe<Scalars['ID']>;
  chargers?: Maybe<ChargersWithMeta>;
  country?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  warehouseName?: Maybe<Scalars['String']>;
};

export type WarehouseChargersArgs = {
  input?: InputMaybe<ListChargersInput>;
};

export type WarehousesWithMeta = {
  __typename?: 'WarehousesWithMeta';
  edges?: Maybe<Array<Maybe<Warehouse>>>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<ListWarehousesSortType>;
  total?: Maybe<Scalars['Int']>;
};

export enum WarrantyLength {
  /** 5 yrs */
  FiveYear = 'FIVE_YEAR',
  /** None */
  None = 'NONE',
  /** 1 Yr */
  OneYear = 'ONE_YEAR',
  /** 3 yrs */
  ThreeYear = 'THREE_YEAR',
}

export enum WarrantyStatus {
  /** Active */
  Active = 'ACTIVE',
  /** Extended */
  Extended = 'EXTENDED',
  /** Inactive */
  Inactive = 'INACTIVE',
}

export type _Entity =
  | Account
  | Card
  | Charger
  | ChargerModel
  | Connector
  | ExtendUser
  | FalconConstraint
  | FleetSiteCharger
  | FleetVehicle
  | PromotionForMobile
  | Property
  | Session
  | Site
  | User
  | Vehicle;

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']>;
};

export type ConnectorAssociation = {
  __typename?: 'connectorAssociation';
  connectorId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  referenceValue?: Maybe<Scalars['String']>;
  vehicleConnectorId?: Maybe<Scalars['Int']>;
};

export enum Link__Purpose {
  /** `EXECUTION` features provide metadata necessary for operation execution. */
  Execution = 'EXECUTION',
  /** `SECURITY` features provide metadata necessary to securely resolve fields. */
  Security = 'SECURITY',
}

export type SessionStartInput = {
  actionResult: Scalars['String'];
  eventDate: Scalars['String'];
  eventType: Scalars['String'];
  id: Scalars['ID'];
  stationId: Scalars['ID'];
  stationSocketId: Scalars['ID'];
};

export type SessionStartResponse = {
  __typename?: 'sessionStartResponse';
  actionResult?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  stationId: Scalars['ID'];
  stationSocketId: Scalars['ID'];
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type';
  kind: __TypeKind;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  specifiedByURL?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL',
}

export const FullChargerFragmentDoc = gql`
  fragment FullCharger on Charger {
    altId
    additionalEquipment
    additionalSerial
    ccReaderType
    certified
    chargePointIdentity
    chargerIsManaged
    chargerModel {
      altId
      modelName
    }
    chargerName
    chargerStatusId
    cid
    communicationMethod
    connectionUri
    coBranding
    description
    disabled
    equipmentId
    evcSyncedAt
    evses {
      edges {
        altId
        evseId
        connectors {
          edges {
            altId
            uniqueConnectorId
            identityKey
            inMaintenance
            connectorType {
              columnText
            }
          }
        }
      }
    }
    excluded
    fieldStationStatus
    fundingSource
    hidden
    identityKey
    ignoreStatusNotifications
    installDate
    ipAddress
    lastReceivedCommSignal
    lastReportedFirmwareVersion
    localLoadBalancingEnabled
    localNetworkMaster
    macAddress
    maintenanceComments
    maintenanceVendorId
    meterSigningSupported
    modelNotes
    mountType
    nextMaintenanceDate
    nonNetworked
    evseId
    connectionDate
    usageStartDate
    maxPower
    excludeFromOcpi
    authenticationModeId
    networkConnectionTypeId
    wirelessConnectionCarrierId
    serialNumber
    showInThirdPartyFilters
    simultaneousChargingEnabled
    site {
      access
      address1
      address2
      administrativeArea
      altId
      hoursOfOperation {
        custom
        from
        to
        monday {
          closed
          timeslots {
            from
            to
          }
        }
        tuesday {
          closed
          timeslots {
            from
            to
          }
        }
        wednesday {
          closed
          timeslots {
            from
            to
          }
        }
        thursday {
          closed
          timeslots {
            from
            to
          }
        }
        friday {
          closed
          timeslots {
            from
            to
          }
        }
        saturday {
          closed
          timeslots {
            from
            to
          }
        }
        sunday {
          closed
          timeslots {
            from
            to
          }
        }
      }
      latitude
      locality
      locationMarket
      longitude
      postalCode
      siteName
      sid
      timeZone
      type
    }
    smartChargingEnabled
    teslaAdapter
    validateMeterSignature
    tags {
      total
      edges {
        altId
        tagName
      }
    }
    warehouse {
      altId
      warehouseName
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
    }
    warrantyLengthId
    warrantyStatusId
    warrantyStartDate
    warrantyExpirationDate
  }
`;
export const ChargersWithMetaFragmentDoc = gql`
  fragment ChargersWithMeta on ChargersWithMeta {
    edges {
      altId
      chargerName
      chargerStatus
      cid
      fieldStationStatus
      displayTariffSynced
      chargerModel {
        altId
        modelName
      }
      evses {
        edges {
          connectors {
            edges {
              chargingLevel
              connectorStatusConstraint {
                columnValue
                columnText
              }
              reservable
            }
          }
        }
      }
      setTariffSynced
      site {
        altId
        siteName
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
        latitude
        longitude
        property {
          propertyName
        }
      }
      tariffSyncStatus
    }
    page
    pageSize
    total
  }
`;
export const PlanTariffTagFragmentDoc = gql`
  fragment PlanTariffTag on PlanTariffTag {
    planChargerGroup {
      altId
      chargerGroupName
      status
      deletedAt
    }
  }
`;
export const ChargerGroupForExtendFragmentDoc = gql`
  fragment ChargerGroupForExtend on ChargerGroupForExtend {
    altId
    chargerGroupName
    chargerGroupType
    chargerGroupTypeId
    description
    createdAt
    deletedAt
    planDuration
    planId
    status
  }
`;
export const TariffScheduleForExtendFragmentDoc = gql`
  fragment TariffScheduleForExtend on TariffScheduleForExtend {
    daysOfWeek
    endTime
    startTime
  }
`;
export const TariffCustomPricingForExtendFragmentDoc = gql`
  fragment TariffCustomPricingForExtend on TariffCustomPricingForExtend {
    altId
    displayRank
    displayName
    displayDescription1
    displayDescription2
    pricing {
      perKwh
      perMinute
      perSession
    }
    tariffSchedules {
      ...TariffScheduleForExtend
    }
  }
  ${TariffScheduleForExtendFragmentDoc}
`;
export const PlanTariffForExtendFragmentDoc = gql`
  fragment PlanTariffForExtend on PlanTariffForExtend {
    altId
    tariffName
    tariffDescription
    chargerGroups {
      ...ChargerGroupForExtend
    }
    customPricing {
      ...TariffCustomPricingForExtend
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffCustomPricingForExtendFragmentDoc}
`;
export const TariffPricingForExtendFragmentDoc = gql`
  fragment TariffPricingForExtend on TariffPricingForExtend {
    displayRank
    displayName
    displayDescription1
    displayDescription2
    pricing {
      perKwh
      perMinute
      perSession
    }
  }
`;
export const TariffItemReservationTermForExtendFragmentDoc = gql`
  fragment TariffItemReservationTermForExtend on TariffItemReservationTermForExtend {
    fee
    noShowFee
  }
`;
export const TariffForExtendFragmentDoc = gql`
  fragment TariffForExtend on TariffForExtend {
    altId
    tariffDescription
    tariffDisplayName
    tariffName
    tariffRank
    status
    chargerGroups {
      ...ChargerGroupForExtend
      chargers: chargersLoaded {
        total
      }
    }
    defaultPricing {
      ...TariffPricingForExtend
    }
    customPricing {
      ...TariffCustomPricingForExtend
    }
    reservationTerm {
      ...TariffItemReservationTermForExtend
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffPricingForExtendFragmentDoc}
  ${TariffCustomPricingForExtendFragmentDoc}
  ${TariffItemReservationTermForExtendFragmentDoc}
`;
export const FullChargerModelFragmentDoc = gql`
  fragment FullChargerModel on ChargerModel {
    altId
    additionalDetails
    autoChargeSupported
    breakerRating
    communicationProtocol
    currentThd
    dimensions
    display
    efficiency
    firmwareVersion
    ingressProtection
    inputAcVoltage
    interfaceVersion
    isoIec
    manufacturer
    modelName
    modelSku
    networkCommunication
    nfcReader
    powerFactor
    powerOutput
    simultaneousChargeSupported
    smartEnergyProfileSupport
    unitType
    weight
    evses {
      edges {
        altId
        connectors {
          edges {
            altId
            connectorMaxCurrent
            connectorType {
              id
              columnText
            }
            cableCooling
            cableLength
            cableManufacturer
            outputVoltageRange
          }
        }
      }
    }
  }
`;
export const FullPlanFragmentDoc = gql`
  fragment FullPlan on Plan {
    altId
    cardBrand
    cardBrandId
    createdAt
    costFactor
    description: planDescription
    displayName: planDisplayName
    subheading: planSubheading
    duration: planDuration
    displayToCustomer
    displayToLoggedOutUser
    displayPlanCard
    driverRank
    isAutoEnrolledInRewards
    elements(input: { pageSize: 9999 }) {
      edges {
        day
        discount
        endTime
        maxDuration
        startTime
        connectorType {
          id
        }
      }
    }
    enrollmentClosed: enrollmentClose
    enrollmentOpen
    financeCode
    intervalLength
    intervalUnit
    intervalUnitId
    planCode
    planName
    badge
    balanceTypeId
    ctaLabel
    discount: planDiscount
    templateId
    products(input: { pageSize: 9999 }) {
      edges {
        productType
        productTypeId
        credit {
          creditAmount
          creditExpPolicy
          creditExpPolicyId
          creditReoccurrence
          creditReoccurrenceId
          creditOneTimeAmount: oneTimeCreditAmount
        }
        subscription {
          subscriptionFee
          subscriptionReoccurrence
          subscriptionReoccurrenceId
        }
      }
    }
    requireVin: vinRequiredFlag
    rolloverPlan {
      altId
    }
    status: planStatus
    planStatusId
    pageHeader: detailHeading
    detailSections {
      header
      items
    }
    detailSessionTimes {
      duration
      interval
      startTime
      endTime
    }
    legalese
    terminationBehavior
    terminationBehaviorId
  }
`;
export const FullSiteFragmentDoc = gql`
  fragment FullSite on Site {
    address1
    address2
    locality
    administrativeArea
    postalCode
    country
    chargers {
      edges {
        cid
        altId
        chargerName
        fieldStationStatus
        chargerModel {
          altId
          modelName
        }
        site {
          altId
          address1
          address2
          locality
          administrativeArea
          country
          postalCode
          property {
            propertyName
          }
        }
      }
      page
      pageSize
      total
      search {
        cid {
          iLike
        }
        chargerName {
          iLike
        }
        site_address1 {
          iLike
        }
        site_locality {
          iLike
        }
        site_administrativeArea {
          iLike
        }
        site_postalCode {
          iLike
        }
        site_property_propertyName {
          iLike
        }
      }
      sort {
        cid
        chargerName
        propertyName
        fieldStationStatus
      }
    }
    displayName
    altId
    latitude
    locationNotes
    locationTypeId
    longitude
    siteName
    timeZone
    property {
      altId
      pid
      propertyName
      host {
        altId
        hid
      }
    }
    hoursOfOperation {
      custom
      from
      to
      monday {
        closed
        timeslots {
          from
          to
        }
      }
      tuesday {
        closed
        timeslots {
          from
          to
        }
      }
      wednesday {
        closed
        timeslots {
          from
          to
        }
      }
      thursday {
        closed
        timeslots {
          from
          to
        }
      }
      friday {
        closed
        timeslots {
          from
          to
        }
      }
      saturday {
        closed
        timeslots {
          from
          to
        }
      }
      sunday {
        closed
        timeslots {
          from
          to
        }
      }
    }
    access
    accessNotes
    locationMarket
    directions
    utilityCompany
    type
    sid
    siteAccessId
    siteImages {
      edges {
        media {
          createdAt
          altId
          url
        }
      }
    }
    demandLimitManagementEnabled
    retailDemandLimit
    siteStatusId
    meterNumber
    ratedVoltage
    maxCurrent
  }
`;
export const CancelAutochargeEnrollmentDocument = gql`
  mutation cancelAutochargeEnrollment($input: CancelAutochargeEnrollmentInput!) {
    cancelAutochargeEnrollment(input: $input) {
      driverId
    }
  }
`;
export type CancelAutochargeEnrollmentMutationFn = Apollo.MutationFunction<
  CancelAutochargeEnrollmentMutation,
  CancelAutochargeEnrollmentMutationVariables
>;

/**
 * __useCancelAutochargeEnrollmentMutation__
 *
 * To run a mutation, you first call `useCancelAutochargeEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAutochargeEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAutochargeEnrollmentMutation, { data, loading, error }] = useCancelAutochargeEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAutochargeEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAutochargeEnrollmentMutation,
    CancelAutochargeEnrollmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelAutochargeEnrollmentMutation, CancelAutochargeEnrollmentMutationVariables>(
    CancelAutochargeEnrollmentDocument,
    options,
  );
}
export type CancelAutochargeEnrollmentMutationHookResult = ReturnType<typeof useCancelAutochargeEnrollmentMutation>;
export type CancelAutochargeEnrollmentMutationResult = Apollo.MutationResult<CancelAutochargeEnrollmentMutation>;
export type CancelAutochargeEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAutochargeEnrollmentMutation,
  CancelAutochargeEnrollmentMutationVariables
>;
export const CreateAccountOwnerDocument = gql`
  mutation createAccountOwner($input: CreateAccountOwnerInput) {
    createAccountOwner(input: $input) {
      altId
      account {
        altId
      }
    }
  }
`;
export type CreateAccountOwnerMutationFn = Apollo.MutationFunction<
  CreateAccountOwnerMutation,
  CreateAccountOwnerMutationVariables
>;

/**
 * __useCreateAccountOwnerMutation__
 *
 * To run a mutation, you first call `useCreateAccountOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountOwnerMutation, { data, loading, error }] = useCreateAccountOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountOwnerMutation, CreateAccountOwnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAccountOwnerMutation, CreateAccountOwnerMutationVariables>(
    CreateAccountOwnerDocument,
    options,
  );
}
export type CreateAccountOwnerMutationHookResult = ReturnType<typeof useCreateAccountOwnerMutation>;
export type CreateAccountOwnerMutationResult = Apollo.MutationResult<CreateAccountOwnerMutation>;
export type CreateAccountOwnerMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountOwnerMutation,
  CreateAccountOwnerMutationVariables
>;
export const DeleteAccountVehicleDocument = gql`
  mutation deleteAccountVehicle($input: DeleteAccountVehicleInput!) {
    deleteAccountVehicle(input: $input) {
      success
    }
  }
`;
export type DeleteAccountVehicleMutationFn = Apollo.MutationFunction<
  DeleteAccountVehicleMutation,
  DeleteAccountVehicleMutationVariables
>;

/**
 * __useDeleteAccountVehicleMutation__
 *
 * To run a mutation, you first call `useDeleteAccountVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountVehicleMutation, { data, loading, error }] = useDeleteAccountVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountVehicleMutation, DeleteAccountVehicleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountVehicleMutation, DeleteAccountVehicleMutationVariables>(
    DeleteAccountVehicleDocument,
    options,
  );
}
export type DeleteAccountVehicleMutationHookResult = ReturnType<typeof useDeleteAccountVehicleMutation>;
export type DeleteAccountVehicleMutationResult = Apollo.MutationResult<DeleteAccountVehicleMutation>;
export type DeleteAccountVehicleMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountVehicleMutation,
  DeleteAccountVehicleMutationVariables
>;
export const DisassociateAccountVehicleDocument = gql`
  mutation disassociateAccountVehicle($input: DisassociateAccountVehicleInput!) {
    disassociateAccountVehicle(input: $input) {
      success
    }
  }
`;
export type DisassociateAccountVehicleMutationFn = Apollo.MutationFunction<
  DisassociateAccountVehicleMutation,
  DisassociateAccountVehicleMutationVariables
>;

/**
 * __useDisassociateAccountVehicleMutation__
 *
 * To run a mutation, you first call `useDisassociateAccountVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisassociateAccountVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disassociateAccountVehicleMutation, { data, loading, error }] = useDisassociateAccountVehicleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisassociateAccountVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisassociateAccountVehicleMutation,
    DisassociateAccountVehicleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DisassociateAccountVehicleMutation, DisassociateAccountVehicleMutationVariables>(
    DisassociateAccountVehicleDocument,
    options,
  );
}
export type DisassociateAccountVehicleMutationHookResult = ReturnType<typeof useDisassociateAccountVehicleMutation>;
export type DisassociateAccountVehicleMutationResult = Apollo.MutationResult<DisassociateAccountVehicleMutation>;
export type DisassociateAccountVehicleMutationOptions = Apollo.BaseMutationOptions<
  DisassociateAccountVehicleMutation,
  DisassociateAccountVehicleMutationVariables
>;
export const GetAccountDocument = gql`
  query getAccount(
    $accountInput: GetAccountInput
    $accountVehiclesInput: ListAccountVehiclesInput
    $cardsInput: ListCardsInput
    $driversInput: ListDriversInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    getAccount(input: $accountInput) {
      altId
      id
      enrolledOn
      accountStatus
      accountType
      vendorId
      updatedAt
      drivers(input: $driversInput) {
        total
        edges {
          accountId
          id
          altId
          accountId
          firstName
          lastName
          email
          phone
          deletedAt
          driverType
          driverStatus {
            id
            columnText
          }
          firebaseId
          createdAt
          accountVehicles(input: $accountVehiclesInput) {
            total
            edges {
              altId
              nickName
              vin
              macAddress
              autochargeStatus {
                id
                columnText
                columnValue
              }
              deletedAt
              vehicle {
                altId
                makeModelYear
                vehicleConnectors {
                  id
                  connectorName: name
                }
              }
            }
          }
          notificationPreferencesForOp(input: $preferencesInput) {
            edges {
              driverId
              notificationItemId
              emailOn
              smsOn
              pushOn
            }
          }
        }
      }
      cards(input: $cardsInput) {
        total
        edges {
          altId
          brand
          cardStatus
          cardTypeId
          externalNumber
          internalNumber
          updatedAt
          createdAt
          driver {
            altId
            firstName
            lastName
          }
          account {
            accountStatus
          }
        }
      }
      cardOrders {
        total
        edges {
          orderStatus
          orderedAt
          fulfilledAt
          driver {
            email
          }
        }
      }
      contracts(input: { pageSize: 999 }) {
        total
        edges {
          altId
          plan {
            altId
            planName
            terminationBehavior
          }
          startDate
          endDate
          closureDate
          status {
            id
            columnText
          }
        }
      }
      mailingAddress {
        altId
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      billingAddress {
        altId
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
    }
  }
`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      accountInput: // value for 'accountInput'
 *      accountVehiclesInput: // value for 'accountVehiclesInput'
 *      cardsInput: // value for 'cardsInput'
 *      driversInput: // value for 'driversInput'
 *      preferencesInput: // value for 'preferencesInput'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
}
export function useGetAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const SearchFirebaseAndDriivzAndFalconAccountsDocument = gql`
  query SearchFirebaseAndDriivzAndFalconAccounts($email: String, $phoneNumber: String) {
    searchFirebaseAndDriivzAndFalconAccounts(email: $email, phoneNumber: $phoneNumber) {
      driivz {
        firstName
        lastName
        billingAddress1
        billingAddress2
        billingRegion
        billingLocality
        email
        phone
      }
      falcon {
        firstName
        lastName
        accountId
        email
        phone
        altId
        driverType
        account {
          altId
          billingAddress {
            address1
            address2
            city
            postalCode
            locality
          }
        }
      }
      firebase {
        email
        phoneNumber
      }
    }
  }
`;

/**
 * __useSearchFirebaseAndDriivzAndFalconAccountsQuery__
 *
 * To run a query within a React component, call `useSearchFirebaseAndDriivzAndFalconAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFirebaseAndDriivzAndFalconAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFirebaseAndDriivzAndFalconAccountsQuery({
 *   variables: {
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useSearchFirebaseAndDriivzAndFalconAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchFirebaseAndDriivzAndFalconAccountsQuery,
    SearchFirebaseAndDriivzAndFalconAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchFirebaseAndDriivzAndFalconAccountsQuery,
    SearchFirebaseAndDriivzAndFalconAccountsQueryVariables
  >(SearchFirebaseAndDriivzAndFalconAccountsDocument, options);
}
export function useSearchFirebaseAndDriivzAndFalconAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchFirebaseAndDriivzAndFalconAccountsQuery,
    SearchFirebaseAndDriivzAndFalconAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchFirebaseAndDriivzAndFalconAccountsQuery,
    SearchFirebaseAndDriivzAndFalconAccountsQueryVariables
  >(SearchFirebaseAndDriivzAndFalconAccountsDocument, options);
}
export type SearchFirebaseAndDriivzAndFalconAccountsQueryHookResult = ReturnType<
  typeof useSearchFirebaseAndDriivzAndFalconAccountsQuery
>;
export type SearchFirebaseAndDriivzAndFalconAccountsLazyQueryHookResult = ReturnType<
  typeof useSearchFirebaseAndDriivzAndFalconAccountsLazyQuery
>;
export type SearchFirebaseAndDriivzAndFalconAccountsQueryResult = Apollo.QueryResult<
  SearchFirebaseAndDriivzAndFalconAccountsQuery,
  SearchFirebaseAndDriivzAndFalconAccountsQueryVariables
>;
export const SyncUserDocument = gql`
  mutation syncUser($email: String!, $setPhoneNumber: String) {
    syncUser(email: $email, setPhoneNumber: $setPhoneNumber)
  }
`;
export type SyncUserMutationFn = Apollo.MutationFunction<SyncUserMutation, SyncUserMutationVariables>;

/**
 * __useSyncUserMutation__
 *
 * To run a mutation, you first call `useSyncUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUserMutation, { data, loading, error }] = useSyncUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      setPhoneNumber: // value for 'setPhoneNumber'
 *   },
 * });
 */
export function useSyncUserMutation(
  baseOptions?: Apollo.MutationHookOptions<SyncUserMutation, SyncUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SyncUserMutation, SyncUserMutationVariables>(SyncUserDocument, options);
}
export type SyncUserMutationHookResult = ReturnType<typeof useSyncUserMutation>;
export type SyncUserMutationResult = Apollo.MutationResult<SyncUserMutation>;
export type SyncUserMutationOptions = Apollo.BaseMutationOptions<SyncUserMutation, SyncUserMutationVariables>;
export const UpdateAccountDocument = gql`
  mutation updateAccount($input: UpdateAccountInput) {
    account: updateAccount(input: $input) {
      altId
      evcId
      mailingAddress {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      billingAddress {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
      }
      enrolledOn
    }
  }
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const UpdateAccountOwnerDocument = gql`
  mutation updateAccountOwner(
    $accountInput: UpdateAccountOwnerInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    updateAccountOwner(input: $accountInput) {
      altId
      firstName
      lastName
      phone
      email
      driverType
      driverStatus {
        id
        columnText
      }
      account {
        altId
        enrolledOn
        vendorId
        vendorAccountType {
          id
          columnText
        }
        optInPromotions
        mailingAddress {
          altId
          address1
          address2
          locality
          administrativeArea
          postalCode
          country
        }
        billingAddress {
          altId
          address1
          address2
          locality
          administrativeArea
          postalCode
          country
        }
      }
      createdAt
      updatedAt
      deletedAt
      notificationPreferences(input: $preferencesInput) {
        edges {
          driverId
          notificationItemId
          emailOn
          smsOn
          pushOn
        }
      }
    }
  }
`;
export type UpdateAccountOwnerMutationFn = Apollo.MutationFunction<
  UpdateAccountOwnerMutation,
  UpdateAccountOwnerMutationVariables
>;

/**
 * __useUpdateAccountOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateAccountOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountOwnerMutation, { data, loading, error }] = useUpdateAccountOwnerMutation({
 *   variables: {
 *      accountInput: // value for 'accountInput'
 *      preferencesInput: // value for 'preferencesInput'
 *   },
 * });
 */
export function useUpdateAccountOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountOwnerMutation, UpdateAccountOwnerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountOwnerMutation, UpdateAccountOwnerMutationVariables>(
    UpdateAccountOwnerDocument,
    options,
  );
}
export type UpdateAccountOwnerMutationHookResult = ReturnType<typeof useUpdateAccountOwnerMutation>;
export type UpdateAccountOwnerMutationResult = Apollo.MutationResult<UpdateAccountOwnerMutation>;
export type UpdateAccountOwnerMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountOwnerMutation,
  UpdateAccountOwnerMutationVariables
>;
export const UpdateAccountVehicleDocument = gql`
  mutation updateAccountVehicle($accountVehicleInput: UpdateAccountVehicleInput) {
    updateAccountVehicle(input: $accountVehicleInput) {
      altId
      vehicle {
        altId
        makeModelYear
      }
      nickName
      vin
    }
  }
`;
export type UpdateAccountVehicleMutationFn = Apollo.MutationFunction<
  UpdateAccountVehicleMutation,
  UpdateAccountVehicleMutationVariables
>;

/**
 * __useUpdateAccountVehicleMutation__
 *
 * To run a mutation, you first call `useUpdateAccountVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountVehicleMutation, { data, loading, error }] = useUpdateAccountVehicleMutation({
 *   variables: {
 *      accountVehicleInput: // value for 'accountVehicleInput'
 *   },
 * });
 */
export function useUpdateAccountVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountVehicleMutation, UpdateAccountVehicleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAccountVehicleMutation, UpdateAccountVehicleMutationVariables>(
    UpdateAccountVehicleDocument,
    options,
  );
}
export type UpdateAccountVehicleMutationHookResult = ReturnType<typeof useUpdateAccountVehicleMutation>;
export type UpdateAccountVehicleMutationResult = Apollo.MutationResult<UpdateAccountVehicleMutation>;
export type UpdateAccountVehicleMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountVehicleMutation,
  UpdateAccountVehicleMutationVariables
>;
export const ValidateAddressDocument = gql`
  mutation validateAddress($addressInput: ValidateAddressInput) {
    validateAddress(input: $addressInput) {
      address1
      address2
      address3
      address4
      locality
      administrativeArea
      postalCode
      country
      latitude
      longitude
    }
  }
`;
export type ValidateAddressMutationFn = Apollo.MutationFunction<
  ValidateAddressMutation,
  ValidateAddressMutationVariables
>;

/**
 * __useValidateAddressMutation__
 *
 * To run a mutation, you first call `useValidateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAddressMutation, { data, loading, error }] = useValidateAddressMutation({
 *   variables: {
 *      addressInput: // value for 'addressInput'
 *   },
 * });
 */
export function useValidateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<ValidateAddressMutation, ValidateAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateAddressMutation, ValidateAddressMutationVariables>(
    ValidateAddressDocument,
    options,
  );
}
export type ValidateAddressMutationHookResult = ReturnType<typeof useValidateAddressMutation>;
export type ValidateAddressMutationResult = Apollo.MutationResult<ValidateAddressMutation>;
export type ValidateAddressMutationOptions = Apollo.BaseMutationOptions<
  ValidateAddressMutation,
  ValidateAddressMutationVariables
>;
export const ActivateCardsDocument = gql`
  mutation activateCards($input: ActivateCardsInput!) {
    activateCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type ActivateCardsMutationFn = Apollo.MutationFunction<ActivateCardsMutation, ActivateCardsMutationVariables>;

/**
 * __useActivateCardsMutation__
 *
 * To run a mutation, you first call `useActivateCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCardsMutation, { data, loading, error }] = useActivateCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateCardsMutation, ActivateCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateCardsMutation, ActivateCardsMutationVariables>(ActivateCardsDocument, options);
}
export type ActivateCardsMutationHookResult = ReturnType<typeof useActivateCardsMutation>;
export type ActivateCardsMutationResult = Apollo.MutationResult<ActivateCardsMutation>;
export type ActivateCardsMutationOptions = Apollo.BaseMutationOptions<
  ActivateCardsMutation,
  ActivateCardsMutationVariables
>;
export const AssignCardsDocument = gql`
  mutation assignCards($input: AssignCardsInput!) {
    assignCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type AssignCardsMutationFn = Apollo.MutationFunction<AssignCardsMutation, AssignCardsMutationVariables>;

/**
 * __useAssignCardsMutation__
 *
 * To run a mutation, you first call `useAssignCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCardsMutation, { data, loading, error }] = useAssignCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignCardsMutation, AssignCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignCardsMutation, AssignCardsMutationVariables>(AssignCardsDocument, options);
}
export type AssignCardsMutationHookResult = ReturnType<typeof useAssignCardsMutation>;
export type AssignCardsMutationResult = Apollo.MutationResult<AssignCardsMutation>;
export type AssignCardsMutationOptions = Apollo.BaseMutationOptions<AssignCardsMutation, AssignCardsMutationVariables>;
export const CreateCardDocument = gql`
  mutation createCard($input: CreateCardInput!) {
    createCard(input: $input) {
      altId
      cardStatus
      activatedAt
    }
  }
`;
export type CreateCardMutationFn = Apollo.MutationFunction<CreateCardMutation, CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCardMutation, CreateCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCardMutation, CreateCardMutationVariables>(CreateCardDocument, options);
}
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<CreateCardMutation, CreateCardMutationVariables>;
export const DeactivateCardsDocument = gql`
  mutation deactivateCards($input: DeactivateCardsInput!) {
    deactivateCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type DeactivateCardsMutationFn = Apollo.MutationFunction<
  DeactivateCardsMutation,
  DeactivateCardsMutationVariables
>;

/**
 * __useDeactivateCardsMutation__
 *
 * To run a mutation, you first call `useDeactivateCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCardsMutation, { data, loading, error }] = useDeactivateCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateCardsMutation, DeactivateCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeactivateCardsMutation, DeactivateCardsMutationVariables>(
    DeactivateCardsDocument,
    options,
  );
}
export type DeactivateCardsMutationHookResult = ReturnType<typeof useDeactivateCardsMutation>;
export type DeactivateCardsMutationResult = Apollo.MutationResult<DeactivateCardsMutation>;
export type DeactivateCardsMutationOptions = Apollo.BaseMutationOptions<
  DeactivateCardsMutation,
  DeactivateCardsMutationVariables
>;
export const DeleteVehicleIdCardDocument = gql`
  mutation deleteVehicleIdCard($input: DeleteVehicleIdCardInput!) {
    deleteVehicleIdCard(input: $input) {
      success
    }
  }
`;
export type DeleteVehicleIdCardMutationFn = Apollo.MutationFunction<
  DeleteVehicleIdCardMutation,
  DeleteVehicleIdCardMutationVariables
>;

/**
 * __useDeleteVehicleIdCardMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleIdCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleIdCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleIdCardMutation, { data, loading, error }] = useDeleteVehicleIdCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVehicleIdCardMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVehicleIdCardMutation, DeleteVehicleIdCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVehicleIdCardMutation, DeleteVehicleIdCardMutationVariables>(
    DeleteVehicleIdCardDocument,
    options,
  );
}
export type DeleteVehicleIdCardMutationHookResult = ReturnType<typeof useDeleteVehicleIdCardMutation>;
export type DeleteVehicleIdCardMutationResult = Apollo.MutationResult<DeleteVehicleIdCardMutation>;
export type DeleteVehicleIdCardMutationOptions = Apollo.BaseMutationOptions<
  DeleteVehicleIdCardMutation,
  DeleteVehicleIdCardMutationVariables
>;
export const ListCardOrdersDocument = gql`
  query listCardOrders($input: ListCardOrdersInput) {
    listCardOrders(input: $input) {
      total
      page
      pageSize
      filter {
        orderStatus {
          in
        }
      }
      search {
        driver_firstName {
          iLike
        }
        driver_lastName {
          iLike
        }
        account_billingAddress_address1 {
          iLike
        }
        account_billingAddress_locality {
          iLike
        }
        account_billingAddress_administrativeArea {
          iLike
        }
        account_billingAddress_postalCode {
          iLike
        }
        accountId {
          eq
        }
      }
      edges {
        altId
        orderStatus
        orderedAt
        fulfilledAt
        driver {
          firstName
          lastName
        }
        account {
          altId
          id
          billingAddress {
            address1
            address2
            locality
            administrativeArea
            postalCode
          }
        }
      }
    }
  }
`;

/**
 * __useListCardOrdersQuery__
 *
 * To run a query within a React component, call `useListCardOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCardOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCardOrdersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListCardOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCardOrdersQuery, ListCardOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListCardOrdersQuery, ListCardOrdersQueryVariables>(ListCardOrdersDocument, options);
}
export function useListCardOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCardOrdersQuery, ListCardOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListCardOrdersQuery, ListCardOrdersQueryVariables>(ListCardOrdersDocument, options);
}
export type ListCardOrdersQueryHookResult = ReturnType<typeof useListCardOrdersQuery>;
export type ListCardOrdersLazyQueryHookResult = ReturnType<typeof useListCardOrdersLazyQuery>;
export type ListCardOrdersQueryResult = Apollo.QueryResult<ListCardOrdersQuery, ListCardOrdersQueryVariables>;
export const ListCardsDocument = gql`
  query listCards($input: ListCardsInput) {
    listCards(input: $input) {
      total
      page
      pageSize
      filter {
        cardStatus {
          in
        }
        brand {
          in
        }
      }
      search {
        externalNumber {
          iLike
        }
        internalNumber {
          iLike
        }
        driver_firstName {
          iLike
        }
        driver_lastName {
          iLike
        }
        accountId {
          eq
        }
      }
      edges {
        altId
        internalNumber
        externalNumber
        brand
        cardStatus
        cardTypeId
        account {
          id
          altId
        }
        driver {
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useListCardsQuery__
 *
 * To run a query within a React component, call `useListCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCardsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListCardsQuery(baseOptions?: Apollo.QueryHookOptions<ListCardsQuery, ListCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListCardsQuery, ListCardsQueryVariables>(ListCardsDocument, options);
}
export function useListCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCardsQuery, ListCardsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListCardsQuery, ListCardsQueryVariables>(ListCardsDocument, options);
}
export type ListCardsQueryHookResult = ReturnType<typeof useListCardsQuery>;
export type ListCardsLazyQueryHookResult = ReturnType<typeof useListCardsLazyQuery>;
export type ListCardsQueryResult = Apollo.QueryResult<ListCardsQuery, ListCardsQueryVariables>;
export const ReassignCardDocument = gql`
  mutation ReassignCard($input: ReassignCardInput!) {
    reassignCard(input: $input) {
      altId
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type ReassignCardMutationFn = Apollo.MutationFunction<ReassignCardMutation, ReassignCardMutationVariables>;

/**
 * __useReassignCardMutation__
 *
 * To run a mutation, you first call `useReassignCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReassignCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reassignCardMutation, { data, loading, error }] = useReassignCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReassignCardMutation(
  baseOptions?: Apollo.MutationHookOptions<ReassignCardMutation, ReassignCardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReassignCardMutation, ReassignCardMutationVariables>(ReassignCardDocument, options);
}
export type ReassignCardMutationHookResult = ReturnType<typeof useReassignCardMutation>;
export type ReassignCardMutationResult = Apollo.MutationResult<ReassignCardMutation>;
export type ReassignCardMutationOptions = Apollo.BaseMutationOptions<
  ReassignCardMutation,
  ReassignCardMutationVariables
>;
export const ReserveCardsDocument = gql`
  mutation reserveCards($input: ReserveCardsInput!) {
    reserveCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type ReserveCardsMutationFn = Apollo.MutationFunction<ReserveCardsMutation, ReserveCardsMutationVariables>;

/**
 * __useReserveCardsMutation__
 *
 * To run a mutation, you first call `useReserveCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveCardsMutation, { data, loading, error }] = useReserveCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReserveCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<ReserveCardsMutation, ReserveCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReserveCardsMutation, ReserveCardsMutationVariables>(ReserveCardsDocument, options);
}
export type ReserveCardsMutationHookResult = ReturnType<typeof useReserveCardsMutation>;
export type ReserveCardsMutationResult = Apollo.MutationResult<ReserveCardsMutation>;
export type ReserveCardsMutationOptions = Apollo.BaseMutationOptions<
  ReserveCardsMutation,
  ReserveCardsMutationVariables
>;
export const SuspendCardsDocument = gql`
  mutation suspendCards($input: SuspendCardsInput!) {
    suspendCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type SuspendCardsMutationFn = Apollo.MutationFunction<SuspendCardsMutation, SuspendCardsMutationVariables>;

/**
 * __useSuspendCardsMutation__
 *
 * To run a mutation, you first call `useSuspendCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendCardsMutation, { data, loading, error }] = useSuspendCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuspendCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<SuspendCardsMutation, SuspendCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SuspendCardsMutation, SuspendCardsMutationVariables>(SuspendCardsDocument, options);
}
export type SuspendCardsMutationHookResult = ReturnType<typeof useSuspendCardsMutation>;
export type SuspendCardsMutationResult = Apollo.MutationResult<SuspendCardsMutation>;
export type SuspendCardsMutationOptions = Apollo.BaseMutationOptions<
  SuspendCardsMutation,
  SuspendCardsMutationVariables
>;
export const UnassignCardsDocument = gql`
  mutation unassignCards($input: UnassignCardsInput!) {
    unassignCards(input: $input) {
      altId
      externalNumber
      cardStatus
      driver {
        altId
      }
    }
  }
`;
export type UnassignCardsMutationFn = Apollo.MutationFunction<UnassignCardsMutation, UnassignCardsMutationVariables>;

/**
 * __useUnassignCardsMutation__
 *
 * To run a mutation, you first call `useUnassignCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCardsMutation, { data, loading, error }] = useUnassignCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<UnassignCardsMutation, UnassignCardsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnassignCardsMutation, UnassignCardsMutationVariables>(UnassignCardsDocument, options);
}
export type UnassignCardsMutationHookResult = ReturnType<typeof useUnassignCardsMutation>;
export type UnassignCardsMutationResult = Apollo.MutationResult<UnassignCardsMutation>;
export type UnassignCardsMutationOptions = Apollo.BaseMutationOptions<
  UnassignCardsMutation,
  UnassignCardsMutationVariables
>;
export const UpdateCardOrdersDocument = gql`
  mutation updateCardOrders($input: UpdateCardOrdersInput!) {
    updateCardOrders(input: $input) {
      altId
      orderStatus
    }
  }
`;
export type UpdateCardOrdersMutationFn = Apollo.MutationFunction<
  UpdateCardOrdersMutation,
  UpdateCardOrdersMutationVariables
>;

/**
 * __useUpdateCardOrdersMutation__
 *
 * To run a mutation, you first call `useUpdateCardOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardOrdersMutation, { data, loading, error }] = useUpdateCardOrdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCardOrdersMutation, UpdateCardOrdersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCardOrdersMutation, UpdateCardOrdersMutationVariables>(
    UpdateCardOrdersDocument,
    options,
  );
}
export type UpdateCardOrdersMutationHookResult = ReturnType<typeof useUpdateCardOrdersMutation>;
export type UpdateCardOrdersMutationResult = Apollo.MutationResult<UpdateCardOrdersMutation>;
export type UpdateCardOrdersMutationOptions = Apollo.BaseMutationOptions<
  UpdateCardOrdersMutation,
  UpdateCardOrdersMutationVariables
>;
export const CreateChargerDocument = gql`
  mutation createCharger($chargerInput: CreateChargerInput) {
    createCharger(input: $chargerInput) {
      ...FullCharger
    }
  }
  ${FullChargerFragmentDoc}
`;
export type CreateChargerMutationFn = Apollo.MutationFunction<CreateChargerMutation, CreateChargerMutationVariables>;

/**
 * __useCreateChargerMutation__
 *
 * To run a mutation, you first call `useCreateChargerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargerMutation, { data, loading, error }] = useCreateChargerMutation({
 *   variables: {
 *      chargerInput: // value for 'chargerInput'
 *   },
 * });
 */
export function useCreateChargerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChargerMutation, CreateChargerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChargerMutation, CreateChargerMutationVariables>(CreateChargerDocument, options);
}
export type CreateChargerMutationHookResult = ReturnType<typeof useCreateChargerMutation>;
export type CreateChargerMutationResult = Apollo.MutationResult<CreateChargerMutation>;
export type CreateChargerMutationOptions = Apollo.BaseMutationOptions<
  CreateChargerMutation,
  CreateChargerMutationVariables
>;
export const GetChargerDocument = gql`
  query getCharger($chargerInput: GetChargerInput) {
    getCharger(input: $chargerInput) {
      ...FullCharger
    }
  }
  ${FullChargerFragmentDoc}
`;

/**
 * __useGetChargerQuery__
 *
 * To run a query within a React component, call `useGetChargerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerQuery({
 *   variables: {
 *      chargerInput: // value for 'chargerInput'
 *   },
 * });
 */
export function useGetChargerQuery(baseOptions?: Apollo.QueryHookOptions<GetChargerQuery, GetChargerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChargerQuery, GetChargerQueryVariables>(GetChargerDocument, options);
}
export function useGetChargerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargerQuery, GetChargerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChargerQuery, GetChargerQueryVariables>(GetChargerDocument, options);
}
export type GetChargerQueryHookResult = ReturnType<typeof useGetChargerQuery>;
export type GetChargerLazyQueryHookResult = ReturnType<typeof useGetChargerLazyQuery>;
export type GetChargerQueryResult = Apollo.QueryResult<GetChargerQuery, GetChargerQueryVariables>;
export const ListChargersDocument = gql`
  query listChargers($chargersInput: ListChargersInput) {
    listChargers(input: $chargersInput) {
      edges {
        altId
        chargerName
        cid
        fieldStationStatus
        chargerModel {
          altId
          modelName
        }
        site {
          altId
          address1
          address2
          locality
          administrativeArea
          postalCode
          latitude
          longitude
          property {
            propertyName
          }
        }
        warehouse {
          altId
          warehouseName
          address1
          address2
          locality
          administrativeArea
          postalCode
        }
      }
      page
      pageSize
      total
      search {
        cid {
          iLike
        }
        chargerName {
          iLike
        }
        site_address1 {
          iLike
        }
        site_locality {
          iLike
        }
        site_administrativeArea {
          iLike
        }
        site_postalCode {
          iLike
        }
        site_property_propertyName {
          iLike
        }
      }
      sort {
        cid
        chargerName
        fieldStationStatus
        propertyName
        chargerModel_modelName
      }
      filter {
        chargerModel_altId {
          in
        }
        fieldStationStatus {
          in
        }
        tags {
          or {
            contains
          }
        }
      }
    }
  }
`;

/**
 * __useListChargersQuery__
 *
 * To run a query within a React component, call `useListChargersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargersQuery({
 *   variables: {
 *      chargersInput: // value for 'chargersInput'
 *   },
 * });
 */
export function useListChargersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListChargersQuery, ListChargersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChargersQuery, ListChargersQueryVariables>(ListChargersDocument, options);
}
export function useListChargersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListChargersQuery, ListChargersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChargersQuery, ListChargersQueryVariables>(ListChargersDocument, options);
}
export type ListChargersQueryHookResult = ReturnType<typeof useListChargersQuery>;
export type ListChargersLazyQueryHookResult = ReturnType<typeof useListChargersLazyQuery>;
export type ListChargersQueryResult = Apollo.QueryResult<ListChargersQuery, ListChargersQueryVariables>;
export const UpdateConnectorReservableDocument = gql`
  mutation UpdateConnectorReservable($input: UpdateConnectorReservableInput!) {
    updateConnectorReservable(input: $input) {
      altId
      reservable
    }
  }
`;
export type UpdateConnectorReservableMutationFn = Apollo.MutationFunction<
  UpdateConnectorReservableMutation,
  UpdateConnectorReservableMutationVariables
>;

/**
 * __useUpdateConnectorReservableMutation__
 *
 * To run a mutation, you first call `useUpdateConnectorReservableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectorReservableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectorReservableMutation, { data, loading, error }] = useUpdateConnectorReservableMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConnectorReservableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConnectorReservableMutation,
    UpdateConnectorReservableMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateConnectorReservableMutation, UpdateConnectorReservableMutationVariables>(
    UpdateConnectorReservableDocument,
    options,
  );
}
export type UpdateConnectorReservableMutationHookResult = ReturnType<typeof useUpdateConnectorReservableMutation>;
export type UpdateConnectorReservableMutationResult = Apollo.MutationResult<UpdateConnectorReservableMutation>;
export type UpdateConnectorReservableMutationOptions = Apollo.BaseMutationOptions<
  UpdateConnectorReservableMutation,
  UpdateConnectorReservableMutationVariables
>;
export const StartChargeDocument = gql`
  mutation startCharge($input: StartChargeInput!) {
    startCharge(input: $input)
  }
`;
export type StartChargeMutationFn = Apollo.MutationFunction<StartChargeMutation, StartChargeMutationVariables>;

/**
 * __useStartChargeMutation__
 *
 * To run a mutation, you first call `useStartChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startChargeMutation, { data, loading, error }] = useStartChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<StartChargeMutation, StartChargeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartChargeMutation, StartChargeMutationVariables>(StartChargeDocument, options);
}
export type StartChargeMutationHookResult = ReturnType<typeof useStartChargeMutation>;
export type StartChargeMutationResult = Apollo.MutationResult<StartChargeMutation>;
export type StartChargeMutationOptions = Apollo.BaseMutationOptions<StartChargeMutation, StartChargeMutationVariables>;
export const StopChargeDocument = gql`
  mutation stopCharge($input: StopChargeInput!) {
    stopCharge(input: $input)
  }
`;
export type StopChargeMutationFn = Apollo.MutationFunction<StopChargeMutation, StopChargeMutationVariables>;

/**
 * __useStopChargeMutation__
 *
 * To run a mutation, you first call `useStopChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopChargeMutation, { data, loading, error }] = useStopChargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStopChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<StopChargeMutation, StopChargeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StopChargeMutation, StopChargeMutationVariables>(StopChargeDocument, options);
}
export type StopChargeMutationHookResult = ReturnType<typeof useStopChargeMutation>;
export type StopChargeMutationResult = Apollo.MutationResult<StopChargeMutation>;
export type StopChargeMutationOptions = Apollo.BaseMutationOptions<StopChargeMutation, StopChargeMutationVariables>;
export const UpdateChargerDocument = gql`
  mutation UpdateCharger($chargerInput: UpdateChargerInput) {
    updateCharger(input: $chargerInput) {
      ...FullCharger
    }
  }
  ${FullChargerFragmentDoc}
`;
export type UpdateChargerMutationFn = Apollo.MutationFunction<UpdateChargerMutation, UpdateChargerMutationVariables>;

/**
 * __useUpdateChargerMutation__
 *
 * To run a mutation, you first call `useUpdateChargerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargerMutation, { data, loading, error }] = useUpdateChargerMutation({
 *   variables: {
 *      chargerInput: // value for 'chargerInput'
 *   },
 * });
 */
export function useUpdateChargerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateChargerMutation, UpdateChargerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateChargerMutation, UpdateChargerMutationVariables>(UpdateChargerDocument, options);
}
export type UpdateChargerMutationHookResult = ReturnType<typeof useUpdateChargerMutation>;
export type UpdateChargerMutationResult = Apollo.MutationResult<UpdateChargerMutation>;
export type UpdateChargerMutationOptions = Apollo.BaseMutationOptions<
  UpdateChargerMutation,
  UpdateChargerMutationVariables
>;
export const CreateContactDocument = gql`
  mutation createContact($contactInput: CreateContactInput) {
    createContact(input: $contactInput) {
      altId
      contactName
      workTitle
      email
      phone
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const CreateContractDocument = gql`
  mutation createContract($contractInput: CreateContractInput!) {
    createContract(input: $contractInput) {
      altId
      account {
        altId
      }
      plan {
        altId
      }
      startDate
      endDate
      closureDate
      status {
        columnText
      }
    }
  }
`;
export type CreateContractMutationFn = Apollo.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      contractInput: // value for 'contractInput'
 *   },
 * });
 */
export function useCreateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, options);
}
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = Apollo.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = Apollo.BaseMutationOptions<
  CreateContractMutation,
  CreateContractMutationVariables
>;
export const GetContractDocument = gql`
  query getContract($input: GetContractInput!) {
    getContract(input: $input) {
      altId
      account {
        altId
      }
      plan {
        altId
      }
      startDate
      endDate
      closureDate
      status {
        columnText
      }
    }
  }
`;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetContractQuery(baseOptions: Apollo.QueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, options);
}
export function useGetContractLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, options);
}
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = Apollo.QueryResult<GetContractQuery, GetContractQueryVariables>;
export const ListContractsDocument = gql`
  query ListContracts($input: ListContractsInput) {
    listContracts(input: $input) {
      total
      page
      pageSize
      filter {
        accountId {
          iLike
        }
      }
      sort {
        statusId
      }
      search {
        accountId {
          iLike
        }
      }
      edges {
        altId
        account {
          altId
        }
        plan {
          altId
        }
        startDate
        endDate
        closureDate
        status {
          columnText
        }
      }
    }
  }
`;

/**
 * __useListContractsQuery__
 *
 * To run a query within a React component, call `useListContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListContractsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListContractsQuery, ListContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListContractsQuery, ListContractsQueryVariables>(ListContractsDocument, options);
}
export function useListContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListContractsQuery, ListContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListContractsQuery, ListContractsQueryVariables>(ListContractsDocument, options);
}
export type ListContractsQueryHookResult = ReturnType<typeof useListContractsQuery>;
export type ListContractsLazyQueryHookResult = ReturnType<typeof useListContractsLazyQuery>;
export type ListContractsQueryResult = Apollo.QueryResult<ListContractsQuery, ListContractsQueryVariables>;
export const UpdateContractDocument = gql`
  mutation updateContract($contractInput: UpdateContractInput!) {
    updateContract(input: $contractInput) {
      altId
      account {
        altId
      }
      plan {
        altId
      }
      startDate
      endDate
      closureDate
      status {
        columnText
      }
    }
  }
`;
export type UpdateContractMutationFn = Apollo.MutationFunction<UpdateContractMutation, UpdateContractMutationVariables>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      contractInput: // value for 'contractInput'
 *   },
 * });
 */
export function useUpdateContractMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(UpdateContractDocument, options);
}
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = Apollo.MutationResult<UpdateContractMutation>;
export type UpdateContractMutationOptions = Apollo.BaseMutationOptions<
  UpdateContractMutation,
  UpdateContractMutationVariables
>;
export const CreateDriverDocument = gql`
  mutation createDriver($input: CreateDriverInput) {
    createDriver(input: $input) {
      firstName
      lastName
      driverType
      phone
      email
    }
  }
`;
export type CreateDriverMutationFn = Apollo.MutationFunction<CreateDriverMutation, CreateDriverMutationVariables>;

/**
 * __useCreateDriverMutation__
 *
 * To run a mutation, you first call `useCreateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverMutation, { data, loading, error }] = useCreateDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDriverMutation, CreateDriverMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDriverMutation, CreateDriverMutationVariables>(CreateDriverDocument, options);
}
export type CreateDriverMutationHookResult = ReturnType<typeof useCreateDriverMutation>;
export type CreateDriverMutationResult = Apollo.MutationResult<CreateDriverMutation>;
export type CreateDriverMutationOptions = Apollo.BaseMutationOptions<
  CreateDriverMutation,
  CreateDriverMutationVariables
>;
export const DeleteDriverDocument = gql`
  mutation deleteDriver($input: DeleteDriverInput) {
    deleteDriver(input: $input) {
      altId
    }
  }
`;
export type DeleteDriverMutationFn = Apollo.MutationFunction<DeleteDriverMutation, DeleteDriverMutationVariables>;

/**
 * __useDeleteDriverMutation__
 *
 * To run a mutation, you first call `useDeleteDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverMutation, { data, loading, error }] = useDeleteDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDriverMutation, DeleteDriverMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDriverMutation, DeleteDriverMutationVariables>(DeleteDriverDocument, options);
}
export type DeleteDriverMutationHookResult = ReturnType<typeof useDeleteDriverMutation>;
export type DeleteDriverMutationResult = Apollo.MutationResult<DeleteDriverMutation>;
export type DeleteDriverMutationOptions = Apollo.BaseMutationOptions<
  DeleteDriverMutation,
  DeleteDriverMutationVariables
>;
export const GetDriverDocument = gql`
  query getDriver(
    $input: GetDriverInput
    $cardsInput: ListCardsInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    getDriver(input: $input) {
      id
      altId
      firstName
      lastName
      phone
      email
      createdAt
      updatedAt
      deletedAt
      driverStatus {
        id
        columnText
      }
      cards(input: $cardsInput) {
        edges {
          altId
          brand
          internalNumber
          externalNumber
          cardStatus
          driver {
            altId
            firstName
            lastName
          }
        }
        total
      }
      account {
        id
        altId
        accountStatus
        accountType
        contracts {
          total
          edges {
            altId
            plan {
              altId
              planName
            }
            startDate
            endDate
            closureDate
            status {
              columnText
            }
          }
        }
        mailingAddress {
          address1
          address2
          locality
          administrativeArea
          postalCode
        }
      }
      accountVehicles {
        edges {
          altId
          nickName
          vin
          vehicle {
            makeModelYear
          }
        }
      }
      notificationPreferences(input: $preferencesInput) {
        edges {
          driverId
          notificationItemId
          emailOn
          smsOn
          pushOn
        }
      }
    }
  }
`;

/**
 * __useGetDriverQuery__
 *
 * To run a query within a React component, call `useGetDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverQuery({
 *   variables: {
 *      input: // value for 'input'
 *      cardsInput: // value for 'cardsInput'
 *      preferencesInput: // value for 'preferencesInput'
 *   },
 * });
 */
export function useGetDriverQuery(baseOptions?: Apollo.QueryHookOptions<GetDriverQuery, GetDriverQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDriverQuery, GetDriverQueryVariables>(GetDriverDocument, options);
}
export function useGetDriverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDriverQuery, GetDriverQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDriverQuery, GetDriverQueryVariables>(GetDriverDocument, options);
}
export type GetDriverQueryHookResult = ReturnType<typeof useGetDriverQuery>;
export type GetDriverLazyQueryHookResult = ReturnType<typeof useGetDriverLazyQuery>;
export type GetDriverQueryResult = Apollo.QueryResult<GetDriverQuery, GetDriverQueryVariables>;
export const ListDriversDocument = gql`
  query listDrivers($driversInput: ListDriversInput) {
    listDrivers(input: $driversInput) {
      edges {
        altId
        email
        firstName
        lastName
        driverType
        driverStatus {
          id
          columnValue
        }
        account {
          altId
          id
          accountStatus
          billingAddress {
            address1
            address2
            locality
            administrativeArea
            postalCode
            country
          }
        }
      }
      page
      pageSize
      filter {
        driverStatusId {
          in
        }
        account_billingAddress_administrativeArea {
          in
        }
        account_accountStatus {
          in
        }
      }
      search {
        account_id {
          eq
        }
        account_billingAddress_address1 {
          iLike
        }
        email {
          iLike
        }
        firstName {
          iLike
        }
        lastName {
          iLike
        }
      }
      sort {
        account_id
        account_balance
        account_billingAddress_address1
        account_accountStatus
        driverStatusId
        email
        firstName
        lastName
      }
      total
    }
  }
`;

/**
 * __useListDriversQuery__
 *
 * To run a query within a React component, call `useListDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDriversQuery({
 *   variables: {
 *      driversInput: // value for 'driversInput'
 *   },
 * });
 */
export function useListDriversQuery(
  baseOptions?: Apollo.QueryHookOptions<ListDriversQuery, ListDriversQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListDriversQuery, ListDriversQueryVariables>(ListDriversDocument, options);
}
export function useListDriversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListDriversQuery, ListDriversQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListDriversQuery, ListDriversQueryVariables>(ListDriversDocument, options);
}
export type ListDriversQueryHookResult = ReturnType<typeof useListDriversQuery>;
export type ListDriversLazyQueryHookResult = ReturnType<typeof useListDriversLazyQuery>;
export type ListDriversQueryResult = Apollo.QueryResult<ListDriversQuery, ListDriversQueryVariables>;
export const ResetDriverPasswordDocument = gql`
  mutation resetDriverPassword($driverInput: ResetDriverPasswordInput) {
    resetDriverPassword(input: $driverInput) {
      mandrillId
      recipientEmail
      status
    }
  }
`;
export type ResetDriverPasswordMutationFn = Apollo.MutationFunction<
  ResetDriverPasswordMutation,
  ResetDriverPasswordMutationVariables
>;

/**
 * __useResetDriverPasswordMutation__
 *
 * To run a mutation, you first call `useResetDriverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDriverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDriverPasswordMutation, { data, loading, error }] = useResetDriverPasswordMutation({
 *   variables: {
 *      driverInput: // value for 'driverInput'
 *   },
 * });
 */
export function useResetDriverPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetDriverPasswordMutation, ResetDriverPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetDriverPasswordMutation, ResetDriverPasswordMutationVariables>(
    ResetDriverPasswordDocument,
    options,
  );
}
export type ResetDriverPasswordMutationHookResult = ReturnType<typeof useResetDriverPasswordMutation>;
export type ResetDriverPasswordMutationResult = Apollo.MutationResult<ResetDriverPasswordMutation>;
export type ResetDriverPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetDriverPasswordMutation,
  ResetDriverPasswordMutationVariables
>;
export const UpdateDriverDocument = gql`
  mutation updateDriver(
    $driverInput: UpdateDriverInput
    $cardsInput: ListCardsInput
    $preferencesInput: ListNotificationPreferencesInput
  ) {
    updateDriver(input: $driverInput) {
      id
      altId
      firstName
      lastName
      phone
      email
      cards(input: $cardsInput) {
        edges {
          altId
          brand
          internalNumber
          externalNumber
          cardStatus
          driver {
            altId
            firstName
            lastName
          }
        }
        total
      }
      account {
        id
        altId
        mailingAddress {
          address1
          address2
          locality
          administrativeArea
          postalCode
        }
        accountStatus
      }
      accountVehicles {
        edges {
          altId
          nickName
          vin
          vehicle {
            makeModelYear
          }
        }
      }
      notificationPreferences(input: $preferencesInput) {
        edges {
          driverId
          notificationItemId
          emailOn
          smsOn
          pushOn
        }
      }
    }
  }
`;
export type UpdateDriverMutationFn = Apollo.MutationFunction<UpdateDriverMutation, UpdateDriverMutationVariables>;

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      driverInput: // value for 'driverInput'
 *      cardsInput: // value for 'cardsInput'
 *      preferencesInput: // value for 'preferencesInput'
 *   },
 * });
 */
export function useUpdateDriverMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDriverMutation, UpdateDriverMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDriverMutation, UpdateDriverMutationVariables>(UpdateDriverDocument, options);
}
export type UpdateDriverMutationHookResult = ReturnType<typeof useUpdateDriverMutation>;
export type UpdateDriverMutationResult = Apollo.MutationResult<UpdateDriverMutation>;
export type UpdateDriverMutationOptions = Apollo.BaseMutationOptions<
  UpdateDriverMutation,
  UpdateDriverMutationVariables
>;
export const AddChargersToChargerGroupForExtendDocument = gql`
  mutation addChargersToChargerGroupForExtend($input: AddChargersToChargerGroupForExtendInput!) {
    addChargersToChargerGroupForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type AddChargersToChargerGroupForExtendMutationFn = Apollo.MutationFunction<
  AddChargersToChargerGroupForExtendMutation,
  AddChargersToChargerGroupForExtendMutationVariables
>;

/**
 * __useAddChargersToChargerGroupForExtendMutation__
 *
 * To run a mutation, you first call `useAddChargersToChargerGroupForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChargersToChargerGroupForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChargersToChargerGroupForExtendMutation, { data, loading, error }] = useAddChargersToChargerGroupForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddChargersToChargerGroupForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddChargersToChargerGroupForExtendMutation,
    AddChargersToChargerGroupForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddChargersToChargerGroupForExtendMutation,
    AddChargersToChargerGroupForExtendMutationVariables
  >(AddChargersToChargerGroupForExtendDocument, options);
}
export type AddChargersToChargerGroupForExtendMutationHookResult = ReturnType<
  typeof useAddChargersToChargerGroupForExtendMutation
>;
export type AddChargersToChargerGroupForExtendMutationResult =
  Apollo.MutationResult<AddChargersToChargerGroupForExtendMutation>;
export type AddChargersToChargerGroupForExtendMutationOptions = Apollo.BaseMutationOptions<
  AddChargersToChargerGroupForExtendMutation,
  AddChargersToChargerGroupForExtendMutationVariables
>;
export const CreateChargerPriceGroupForExtendDocument = gql`
  mutation createChargerPriceGroupForExtend($input: CreateChargerPriceGroupForExtendInput!) {
    createChargerPriceGroupForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type CreateChargerPriceGroupForExtendMutationFn = Apollo.MutationFunction<
  CreateChargerPriceGroupForExtendMutation,
  CreateChargerPriceGroupForExtendMutationVariables
>;

/**
 * __useCreateChargerPriceGroupForExtendMutation__
 *
 * To run a mutation, you first call `useCreateChargerPriceGroupForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargerPriceGroupForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargerPriceGroupForExtendMutation, { data, loading, error }] = useCreateChargerPriceGroupForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChargerPriceGroupForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChargerPriceGroupForExtendMutation,
    CreateChargerPriceGroupForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateChargerPriceGroupForExtendMutation,
    CreateChargerPriceGroupForExtendMutationVariables
  >(CreateChargerPriceGroupForExtendDocument, options);
}
export type CreateChargerPriceGroupForExtendMutationHookResult = ReturnType<
  typeof useCreateChargerPriceGroupForExtendMutation
>;
export type CreateChargerPriceGroupForExtendMutationResult =
  Apollo.MutationResult<CreateChargerPriceGroupForExtendMutation>;
export type CreateChargerPriceGroupForExtendMutationOptions = Apollo.BaseMutationOptions<
  CreateChargerPriceGroupForExtendMutation,
  CreateChargerPriceGroupForExtendMutationVariables
>;
export const CreateChargerGroupPlanForExtendDocument = gql`
  mutation createChargerGroupPlanForExtend($input: CreateChargerGroupPlanForExtendInput!) {
    createChargerGroupPlanForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type CreateChargerGroupPlanForExtendMutationFn = Apollo.MutationFunction<
  CreateChargerGroupPlanForExtendMutation,
  CreateChargerGroupPlanForExtendMutationVariables
>;

/**
 * __useCreateChargerGroupPlanForExtendMutation__
 *
 * To run a mutation, you first call `useCreateChargerGroupPlanForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargerGroupPlanForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargerGroupPlanForExtendMutation, { data, loading, error }] = useCreateChargerGroupPlanForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChargerGroupPlanForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChargerGroupPlanForExtendMutation,
    CreateChargerGroupPlanForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChargerGroupPlanForExtendMutation, CreateChargerGroupPlanForExtendMutationVariables>(
    CreateChargerGroupPlanForExtendDocument,
    options,
  );
}
export type CreateChargerGroupPlanForExtendMutationHookResult = ReturnType<
  typeof useCreateChargerGroupPlanForExtendMutation
>;
export type CreateChargerGroupPlanForExtendMutationResult =
  Apollo.MutationResult<CreateChargerGroupPlanForExtendMutation>;
export type CreateChargerGroupPlanForExtendMutationOptions = Apollo.BaseMutationOptions<
  CreateChargerGroupPlanForExtendMutation,
  CreateChargerGroupPlanForExtendMutationVariables
>;
export const CreateExtendHostDocument = gql`
  mutation createExtendHost($input: CreateExtendHostInput!) {
    createExtendHost(input: $input) {
      altId
      createdAt
      host {
        altId
        hostName
        hid
        status
        vendorId
      }
    }
  }
`;
export type CreateExtendHostMutationFn = Apollo.MutationFunction<
  CreateExtendHostMutation,
  CreateExtendHostMutationVariables
>;

/**
 * __useCreateExtendHostMutation__
 *
 * To run a mutation, you first call `useCreateExtendHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExtendHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExtendHostMutation, { data, loading, error }] = useCreateExtendHostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExtendHostMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateExtendHostMutation, CreateExtendHostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateExtendHostMutation, CreateExtendHostMutationVariables>(
    CreateExtendHostDocument,
    options,
  );
}
export type CreateExtendHostMutationHookResult = ReturnType<typeof useCreateExtendHostMutation>;
export type CreateExtendHostMutationResult = Apollo.MutationResult<CreateExtendHostMutation>;
export type CreateExtendHostMutationOptions = Apollo.BaseMutationOptions<
  CreateExtendHostMutation,
  CreateExtendHostMutationVariables
>;
export const CreateExtendUserDocument = gql`
  mutation createExtendUser($extenduserInput: CreateExtendUserInput) {
    createExtendUser(input: $extenduserInput) {
      altId
      hosts {
        edges {
          hostName
          hid
        }
      }
    }
  }
`;
export type CreateExtendUserMutationFn = Apollo.MutationFunction<
  CreateExtendUserMutation,
  CreateExtendUserMutationVariables
>;

/**
 * __useCreateExtendUserMutation__
 *
 * To run a mutation, you first call `useCreateExtendUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExtendUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExtendUserMutation, { data, loading, error }] = useCreateExtendUserMutation({
 *   variables: {
 *      extenduserInput: // value for 'extenduserInput'
 *   },
 * });
 */
export function useCreateExtendUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateExtendUserMutation, CreateExtendUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateExtendUserMutation, CreateExtendUserMutationVariables>(
    CreateExtendUserDocument,
    options,
  );
}
export type CreateExtendUserMutationHookResult = ReturnType<typeof useCreateExtendUserMutation>;
export type CreateExtendUserMutationResult = Apollo.MutationResult<CreateExtendUserMutation>;
export type CreateExtendUserMutationOptions = Apollo.BaseMutationOptions<
  CreateExtendUserMutation,
  CreateExtendUserMutationVariables
>;
export const CreatePlanTariffsForExtendDocument = gql`
  mutation createPlanTariffsForExtend($input: CreatePlanTariffsForExtendInput!) {
    createPlanTariffsForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type CreatePlanTariffsForExtendMutationFn = Apollo.MutationFunction<
  CreatePlanTariffsForExtendMutation,
  CreatePlanTariffsForExtendMutationVariables
>;

/**
 * __useCreatePlanTariffsForExtendMutation__
 *
 * To run a mutation, you first call `useCreatePlanTariffsForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanTariffsForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanTariffsForExtendMutation, { data, loading, error }] = useCreatePlanTariffsForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlanTariffsForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePlanTariffsForExtendMutation,
    CreatePlanTariffsForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePlanTariffsForExtendMutation, CreatePlanTariffsForExtendMutationVariables>(
    CreatePlanTariffsForExtendDocument,
    options,
  );
}
export type CreatePlanTariffsForExtendMutationHookResult = ReturnType<typeof useCreatePlanTariffsForExtendMutation>;
export type CreatePlanTariffsForExtendMutationResult = Apollo.MutationResult<CreatePlanTariffsForExtendMutation>;
export type CreatePlanTariffsForExtendMutationOptions = Apollo.BaseMutationOptions<
  CreatePlanTariffsForExtendMutation,
  CreatePlanTariffsForExtendMutationVariables
>;
export const CreateTariffForExtendDocument = gql`
  mutation createTariffForExtend($input: CreateTariffForExtendInput!) {
    createTariffForExtend(input: $input) {
      ...TariffForExtend
    }
  }
  ${TariffForExtendFragmentDoc}
`;
export type CreateTariffForExtendMutationFn = Apollo.MutationFunction<
  CreateTariffForExtendMutation,
  CreateTariffForExtendMutationVariables
>;

/**
 * __useCreateTariffForExtendMutation__
 *
 * To run a mutation, you first call `useCreateTariffForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTariffForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTariffForExtendMutation, { data, loading, error }] = useCreateTariffForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTariffForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTariffForExtendMutation, CreateTariffForExtendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTariffForExtendMutation, CreateTariffForExtendMutationVariables>(
    CreateTariffForExtendDocument,
    options,
  );
}
export type CreateTariffForExtendMutationHookResult = ReturnType<typeof useCreateTariffForExtendMutation>;
export type CreateTariffForExtendMutationResult = Apollo.MutationResult<CreateTariffForExtendMutation>;
export type CreateTariffForExtendMutationOptions = Apollo.BaseMutationOptions<
  CreateTariffForExtendMutation,
  CreateTariffForExtendMutationVariables
>;
export const DeactivateChargerGroupPlanForExtendDocument = gql`
  mutation deactivateChargerGroupPlanForExtend($input: DeactivateChargerGroupPlanForExtendInput!) {
    deactivateChargerGroupPlanForExtend(input: $input) {
      altId
    }
  }
`;
export type DeactivateChargerGroupPlanForExtendMutationFn = Apollo.MutationFunction<
  DeactivateChargerGroupPlanForExtendMutation,
  DeactivateChargerGroupPlanForExtendMutationVariables
>;

/**
 * __useDeactivateChargerGroupPlanForExtendMutation__
 *
 * To run a mutation, you first call `useDeactivateChargerGroupPlanForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateChargerGroupPlanForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateChargerGroupPlanForExtendMutation, { data, loading, error }] = useDeactivateChargerGroupPlanForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeactivateChargerGroupPlanForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivateChargerGroupPlanForExtendMutation,
    DeactivateChargerGroupPlanForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivateChargerGroupPlanForExtendMutation,
    DeactivateChargerGroupPlanForExtendMutationVariables
  >(DeactivateChargerGroupPlanForExtendDocument, options);
}
export type DeactivateChargerGroupPlanForExtendMutationHookResult = ReturnType<
  typeof useDeactivateChargerGroupPlanForExtendMutation
>;
export type DeactivateChargerGroupPlanForExtendMutationResult =
  Apollo.MutationResult<DeactivateChargerGroupPlanForExtendMutation>;
export type DeactivateChargerGroupPlanForExtendMutationOptions = Apollo.BaseMutationOptions<
  DeactivateChargerGroupPlanForExtendMutation,
  DeactivateChargerGroupPlanForExtendMutationVariables
>;
export const DeleteChargerGroupForExtendDocument = gql`
  mutation deleteChargerGroupForExtend($input: DeleteChargerGroupForExtendInput!) {
    deleteChargerGroupForExtend(input: $input) {
      altId
      status
    }
  }
`;
export type DeleteChargerGroupForExtendMutationFn = Apollo.MutationFunction<
  DeleteChargerGroupForExtendMutation,
  DeleteChargerGroupForExtendMutationVariables
>;

/**
 * __useDeleteChargerGroupForExtendMutation__
 *
 * To run a mutation, you first call `useDeleteChargerGroupForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChargerGroupForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChargerGroupForExtendMutation, { data, loading, error }] = useDeleteChargerGroupForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteChargerGroupForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChargerGroupForExtendMutation,
    DeleteChargerGroupForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteChargerGroupForExtendMutation, DeleteChargerGroupForExtendMutationVariables>(
    DeleteChargerGroupForExtendDocument,
    options,
  );
}
export type DeleteChargerGroupForExtendMutationHookResult = ReturnType<typeof useDeleteChargerGroupForExtendMutation>;
export type DeleteChargerGroupForExtendMutationResult = Apollo.MutationResult<DeleteChargerGroupForExtendMutation>;
export type DeleteChargerGroupForExtendMutationOptions = Apollo.BaseMutationOptions<
  DeleteChargerGroupForExtendMutation,
  DeleteChargerGroupForExtendMutationVariables
>;
export const DeleteExtendHostDocument = gql`
  mutation deleteExtendHost($input: DeleteExtendHostInput!) {
    deleteExtendHost(input: $input) {
      success
    }
  }
`;
export type DeleteExtendHostMutationFn = Apollo.MutationFunction<
  DeleteExtendHostMutation,
  DeleteExtendHostMutationVariables
>;

/**
 * __useDeleteExtendHostMutation__
 *
 * To run a mutation, you first call `useDeleteExtendHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExtendHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExtendHostMutation, { data, loading, error }] = useDeleteExtendHostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteExtendHostMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteExtendHostMutation, DeleteExtendHostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExtendHostMutation, DeleteExtendHostMutationVariables>(
    DeleteExtendHostDocument,
    options,
  );
}
export type DeleteExtendHostMutationHookResult = ReturnType<typeof useDeleteExtendHostMutation>;
export type DeleteExtendHostMutationResult = Apollo.MutationResult<DeleteExtendHostMutation>;
export type DeleteExtendHostMutationOptions = Apollo.BaseMutationOptions<
  DeleteExtendHostMutation,
  DeleteExtendHostMutationVariables
>;
export const DeleteExtendUserHostsDocument = gql`
  mutation deleteExtendUserHosts($deleteExtendUserHostsInput: DeleteExtendUserHostsInput) {
    deleteExtendUserHosts(input: $deleteExtendUserHostsInput) {
      altId
    }
  }
`;
export type DeleteExtendUserHostsMutationFn = Apollo.MutationFunction<
  DeleteExtendUserHostsMutation,
  DeleteExtendUserHostsMutationVariables
>;

/**
 * __useDeleteExtendUserHostsMutation__
 *
 * To run a mutation, you first call `useDeleteExtendUserHostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExtendUserHostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExtendUserHostsMutation, { data, loading, error }] = useDeleteExtendUserHostsMutation({
 *   variables: {
 *      deleteExtendUserHostsInput: // value for 'deleteExtendUserHostsInput'
 *   },
 * });
 */
export function useDeleteExtendUserHostsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteExtendUserHostsMutation, DeleteExtendUserHostsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExtendUserHostsMutation, DeleteExtendUserHostsMutationVariables>(
    DeleteExtendUserHostsDocument,
    options,
  );
}
export type DeleteExtendUserHostsMutationHookResult = ReturnType<typeof useDeleteExtendUserHostsMutation>;
export type DeleteExtendUserHostsMutationResult = Apollo.MutationResult<DeleteExtendUserHostsMutation>;
export type DeleteExtendUserHostsMutationOptions = Apollo.BaseMutationOptions<
  DeleteExtendUserHostsMutation,
  DeleteExtendUserHostsMutationVariables
>;
export const DeleteTariffForExtendDocument = gql`
  mutation deleteTariffForExtend($input: DeleteTariffForExtendInput!) {
    deleteTariffForExtend(input: $input)
  }
`;
export type DeleteTariffForExtendMutationFn = Apollo.MutationFunction<
  DeleteTariffForExtendMutation,
  DeleteTariffForExtendMutationVariables
>;

/**
 * __useDeleteTariffForExtendMutation__
 *
 * To run a mutation, you first call `useDeleteTariffForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTariffForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTariffForExtendMutation, { data, loading, error }] = useDeleteTariffForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTariffForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTariffForExtendMutation, DeleteTariffForExtendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTariffForExtendMutation, DeleteTariffForExtendMutationVariables>(
    DeleteTariffForExtendDocument,
    options,
  );
}
export type DeleteTariffForExtendMutationHookResult = ReturnType<typeof useDeleteTariffForExtendMutation>;
export type DeleteTariffForExtendMutationResult = Apollo.MutationResult<DeleteTariffForExtendMutation>;
export type DeleteTariffForExtendMutationOptions = Apollo.BaseMutationOptions<
  DeleteTariffForExtendMutation,
  DeleteTariffForExtendMutationVariables
>;
export const GetActiveSessionsTotalsDocument = gql`
  query getActiveSessionsTotals($input: ActiveSessionsTotalsInput!) {
    getActiveSessionsTotals(input: $input) {
      totalKwh
    }
  }
`;

/**
 * __useGetActiveSessionsTotalsQuery__
 *
 * To run a query within a React component, call `useGetActiveSessionsTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSessionsTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSessionsTotalsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActiveSessionsTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<GetActiveSessionsTotalsQuery, GetActiveSessionsTotalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveSessionsTotalsQuery, GetActiveSessionsTotalsQueryVariables>(
    GetActiveSessionsTotalsDocument,
    options,
  );
}
export function useGetActiveSessionsTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActiveSessionsTotalsQuery, GetActiveSessionsTotalsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActiveSessionsTotalsQuery, GetActiveSessionsTotalsQueryVariables>(
    GetActiveSessionsTotalsDocument,
    options,
  );
}
export type GetActiveSessionsTotalsQueryHookResult = ReturnType<typeof useGetActiveSessionsTotalsQuery>;
export type GetActiveSessionsTotalsLazyQueryHookResult = ReturnType<typeof useGetActiveSessionsTotalsLazyQuery>;
export type GetActiveSessionsTotalsQueryResult = Apollo.QueryResult<
  GetActiveSessionsTotalsQuery,
  GetActiveSessionsTotalsQueryVariables
>;
export const GetChargerExtendDocument = gql`
  query getChargerExtend($chargerInput: GetChargerInput) {
    getCharger(input: $chargerInput) {
      altId
      chargerName
      serialNumber
      chargerId
      setTariffSynced
      displayTariffSynced
      tariffSyncStatus
      chargerModel {
        modelName
        firmwareVersion
        breakerRating
        simultaneousChargeSupported
      }
      site {
        altId
        siteName
      }
      evses {
        edges {
          connectors {
            edges {
              altId
              connectorType {
                columnText
              }
              cableLength
              connectorMaxCurrent
              maxConnectorPower
              energyUtilization
              connectorStatusConstraint {
                columnValue
                columnText
              }
              outputVoltageRange
              reservable
            }
          }
          evseId
        }
      }
    }
  }
`;

/**
 * __useGetChargerExtendQuery__
 *
 * To run a query within a React component, call `useGetChargerExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerExtendQuery({
 *   variables: {
 *      chargerInput: // value for 'chargerInput'
 *   },
 * });
 */
export function useGetChargerExtendQuery(
  baseOptions?: Apollo.QueryHookOptions<GetChargerExtendQuery, GetChargerExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChargerExtendQuery, GetChargerExtendQueryVariables>(GetChargerExtendDocument, options);
}
export function useGetChargerExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargerExtendQuery, GetChargerExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChargerExtendQuery, GetChargerExtendQueryVariables>(GetChargerExtendDocument, options);
}
export type GetChargerExtendQueryHookResult = ReturnType<typeof useGetChargerExtendQuery>;
export type GetChargerExtendLazyQueryHookResult = ReturnType<typeof useGetChargerExtendLazyQuery>;
export type GetChargerExtendQueryResult = Apollo.QueryResult<GetChargerExtendQuery, GetChargerExtendQueryVariables>;
export const GetChargerGroupForExtendDocument = gql`
  query getChargerGroupForExtend($input: GetChargerGroupForExtendInput!) {
    getChargerGroupForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;

/**
 * __useGetChargerGroupForExtendQuery__
 *
 * To run a query within a React component, call `useGetChargerGroupForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerGroupForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerGroupForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChargerGroupForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<GetChargerGroupForExtendQuery, GetChargerGroupForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChargerGroupForExtendQuery, GetChargerGroupForExtendQueryVariables>(
    GetChargerGroupForExtendDocument,
    options,
  );
}
export function useGetChargerGroupForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargerGroupForExtendQuery, GetChargerGroupForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChargerGroupForExtendQuery, GetChargerGroupForExtendQueryVariables>(
    GetChargerGroupForExtendDocument,
    options,
  );
}
export type GetChargerGroupForExtendQueryHookResult = ReturnType<typeof useGetChargerGroupForExtendQuery>;
export type GetChargerGroupForExtendLazyQueryHookResult = ReturnType<typeof useGetChargerGroupForExtendLazyQuery>;
export type GetChargerGroupForExtendQueryResult = Apollo.QueryResult<
  GetChargerGroupForExtendQuery,
  GetChargerGroupForExtendQueryVariables
>;
export const GetChargerGroupPlansForExtendDocument = gql`
  query getChargerGroupPlansForExtend($input: GetChargerGroupForExtendInput!) {
    getChargerGroupForExtend(input: $input) {
      planTariffTags {
        planChargerGroup {
          ...ChargerGroupForExtend
        }
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
`;

/**
 * __useGetChargerGroupPlansForExtendQuery__
 *
 * To run a query within a React component, call `useGetChargerGroupPlansForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerGroupPlansForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerGroupPlansForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChargerGroupPlansForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<GetChargerGroupPlansForExtendQuery, GetChargerGroupPlansForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChargerGroupPlansForExtendQuery, GetChargerGroupPlansForExtendQueryVariables>(
    GetChargerGroupPlansForExtendDocument,
    options,
  );
}
export function useGetChargerGroupPlansForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChargerGroupPlansForExtendQuery,
    GetChargerGroupPlansForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChargerGroupPlansForExtendQuery, GetChargerGroupPlansForExtendQueryVariables>(
    GetChargerGroupPlansForExtendDocument,
    options,
  );
}
export type GetChargerGroupPlansForExtendQueryHookResult = ReturnType<typeof useGetChargerGroupPlansForExtendQuery>;
export type GetChargerGroupPlansForExtendLazyQueryHookResult = ReturnType<
  typeof useGetChargerGroupPlansForExtendLazyQuery
>;
export type GetChargerGroupPlansForExtendQueryResult = Apollo.QueryResult<
  GetChargerGroupPlansForExtendQuery,
  GetChargerGroupPlansForExtendQueryVariables
>;
export const GetChargerGroupsForChargerPriceDocument = gql`
  query getChargerGroupsForChargerPrice($input: GetTariffForExtendInput!) {
    getTariffForExtend(input: $input) {
      chargerGroups {
        altId
        chargerGroupName
      }
    }
  }
`;

/**
 * __useGetChargerGroupsForChargerPriceQuery__
 *
 * To run a query within a React component, call `useGetChargerGroupsForChargerPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerGroupsForChargerPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerGroupsForChargerPriceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChargerGroupsForChargerPriceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChargerGroupsForChargerPriceQuery,
    GetChargerGroupsForChargerPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChargerGroupsForChargerPriceQuery, GetChargerGroupsForChargerPriceQueryVariables>(
    GetChargerGroupsForChargerPriceDocument,
    options,
  );
}
export function useGetChargerGroupsForChargerPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChargerGroupsForChargerPriceQuery,
    GetChargerGroupsForChargerPriceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChargerGroupsForChargerPriceQuery, GetChargerGroupsForChargerPriceQueryVariables>(
    GetChargerGroupsForChargerPriceDocument,
    options,
  );
}
export type GetChargerGroupsForChargerPriceQueryHookResult = ReturnType<typeof useGetChargerGroupsForChargerPriceQuery>;
export type GetChargerGroupsForChargerPriceLazyQueryHookResult = ReturnType<
  typeof useGetChargerGroupsForChargerPriceLazyQuery
>;
export type GetChargerGroupsForChargerPriceQueryResult = Apollo.QueryResult<
  GetChargerGroupsForChargerPriceQuery,
  GetChargerGroupsForChargerPriceQueryVariables
>;
export const GetExtendCalculatedHostPlansDocument = gql`
  query getExtendCalculatedHostPlans {
    getExtendCalculatedHostPlans {
      planId
    }
  }
`;

/**
 * __useGetExtendCalculatedHostPlansQuery__
 *
 * To run a query within a React component, call `useGetExtendCalculatedHostPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtendCalculatedHostPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtendCalculatedHostPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtendCalculatedHostPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExtendCalculatedHostPlansQuery, GetExtendCalculatedHostPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExtendCalculatedHostPlansQuery, GetExtendCalculatedHostPlansQueryVariables>(
    GetExtendCalculatedHostPlansDocument,
    options,
  );
}
export function useGetExtendCalculatedHostPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExtendCalculatedHostPlansQuery,
    GetExtendCalculatedHostPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExtendCalculatedHostPlansQuery, GetExtendCalculatedHostPlansQueryVariables>(
    GetExtendCalculatedHostPlansDocument,
    options,
  );
}
export type GetExtendCalculatedHostPlansQueryHookResult = ReturnType<typeof useGetExtendCalculatedHostPlansQuery>;
export type GetExtendCalculatedHostPlansLazyQueryHookResult = ReturnType<
  typeof useGetExtendCalculatedHostPlansLazyQuery
>;
export type GetExtendCalculatedHostPlansQueryResult = Apollo.QueryResult<
  GetExtendCalculatedHostPlansQuery,
  GetExtendCalculatedHostPlansQueryVariables
>;
export const GetExtendUserDocument = gql`
  query getExtendUser($extenduserInput: GetExtendUserInput) {
    getExtendUser(input: $extenduserInput) {
      altId
      hosts {
        edges {
          altId
          hostName
          customPlansEnabled
        }
      }
    }
  }
`;

/**
 * __useGetExtendUserQuery__
 *
 * To run a query within a React component, call `useGetExtendUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtendUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtendUserQuery({
 *   variables: {
 *      extenduserInput: // value for 'extenduserInput'
 *   },
 * });
 */
export function useGetExtendUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExtendUserQuery, GetExtendUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExtendUserQuery, GetExtendUserQueryVariables>(GetExtendUserDocument, options);
}
export function useGetExtendUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExtendUserQuery, GetExtendUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExtendUserQuery, GetExtendUserQueryVariables>(GetExtendUserDocument, options);
}
export type GetExtendUserQueryHookResult = ReturnType<typeof useGetExtendUserQuery>;
export type GetExtendUserLazyQueryHookResult = ReturnType<typeof useGetExtendUserLazyQuery>;
export type GetExtendUserQueryResult = Apollo.QueryResult<GetExtendUserQuery, GetExtendUserQueryVariables>;
export const GetFirebaseUserForExtendDocument = gql`
  query getFirebaseUserForExtend($input: GetFirebaseUserForExtendInput!) {
    getFirebaseUserForExtend(input: $input) {
      username
      firebaseId
    }
  }
`;

/**
 * __useGetFirebaseUserForExtendQuery__
 *
 * To run a query within a React component, call `useGetFirebaseUserForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirebaseUserForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirebaseUserForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFirebaseUserForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<GetFirebaseUserForExtendQuery, GetFirebaseUserForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFirebaseUserForExtendQuery, GetFirebaseUserForExtendQueryVariables>(
    GetFirebaseUserForExtendDocument,
    options,
  );
}
export function useGetFirebaseUserForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFirebaseUserForExtendQuery, GetFirebaseUserForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFirebaseUserForExtendQuery, GetFirebaseUserForExtendQueryVariables>(
    GetFirebaseUserForExtendDocument,
    options,
  );
}
export type GetFirebaseUserForExtendQueryHookResult = ReturnType<typeof useGetFirebaseUserForExtendQuery>;
export type GetFirebaseUserForExtendLazyQueryHookResult = ReturnType<typeof useGetFirebaseUserForExtendLazyQuery>;
export type GetFirebaseUserForExtendQueryResult = Apollo.QueryResult<
  GetFirebaseUserForExtendQuery,
  GetFirebaseUserForExtendQueryVariables
>;
export const GetPlanEnrollmentsForExtendDocument = gql`
  query getPlanEnrollmentsForExtend {
    getPlanEnrollmentsForExtend {
      planName
      totalEnrollments
    }
  }
`;

/**
 * __useGetPlanEnrollmentsForExtendQuery__
 *
 * To run a query within a React component, call `useGetPlanEnrollmentsForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanEnrollmentsForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanEnrollmentsForExtendQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanEnrollmentsForExtendQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPlanEnrollmentsForExtendQuery, GetPlanEnrollmentsForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlanEnrollmentsForExtendQuery, GetPlanEnrollmentsForExtendQueryVariables>(
    GetPlanEnrollmentsForExtendDocument,
    options,
  );
}
export function useGetPlanEnrollmentsForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlanEnrollmentsForExtendQuery,
    GetPlanEnrollmentsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlanEnrollmentsForExtendQuery, GetPlanEnrollmentsForExtendQueryVariables>(
    GetPlanEnrollmentsForExtendDocument,
    options,
  );
}
export type GetPlanEnrollmentsForExtendQueryHookResult = ReturnType<typeof useGetPlanEnrollmentsForExtendQuery>;
export type GetPlanEnrollmentsForExtendLazyQueryHookResult = ReturnType<typeof useGetPlanEnrollmentsForExtendLazyQuery>;
export type GetPlanEnrollmentsForExtendQueryResult = Apollo.QueryResult<
  GetPlanEnrollmentsForExtendQuery,
  GetPlanEnrollmentsForExtendQueryVariables
>;
export const GetCustomPlanBreakdownDocument = gql`
  query getCustomPlanBreakdown($input: GetSessionSummaryByPlanInput) {
    getSessionSummaryByPlan(input: $input) {
      planName
      powerCostValue
    }
  }
`;

/**
 * __useGetCustomPlanBreakdownQuery__
 *
 * To run a query within a React component, call `useGetCustomPlanBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomPlanBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomPlanBreakdownQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCustomPlanBreakdownQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomPlanBreakdownQuery, GetCustomPlanBreakdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomPlanBreakdownQuery, GetCustomPlanBreakdownQueryVariables>(
    GetCustomPlanBreakdownDocument,
    options,
  );
}
export function useGetCustomPlanBreakdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomPlanBreakdownQuery, GetCustomPlanBreakdownQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomPlanBreakdownQuery, GetCustomPlanBreakdownQueryVariables>(
    GetCustomPlanBreakdownDocument,
    options,
  );
}
export type GetCustomPlanBreakdownQueryHookResult = ReturnType<typeof useGetCustomPlanBreakdownQuery>;
export type GetCustomPlanBreakdownLazyQueryHookResult = ReturnType<typeof useGetCustomPlanBreakdownLazyQuery>;
export type GetCustomPlanBreakdownQueryResult = Apollo.QueryResult<
  GetCustomPlanBreakdownQuery,
  GetCustomPlanBreakdownQueryVariables
>;
export const GetSessionsConsumptionForExtendDocument = gql`
  query GetSessionsConsumptionForExtend($input: GetSessionsConsumptionForExtendInput!) {
    getSessionsConsumptionForExtend(input: $input) {
      timeSeries {
        totalSessions
        powerCostValue
        powerValue
        timestamp
      }
    }
  }
`;

/**
 * __useGetSessionsConsumptionForExtendQuery__
 *
 * To run a query within a React component, call `useGetSessionsConsumptionForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsConsumptionForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsConsumptionForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSessionsConsumptionForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSessionsConsumptionForExtendQuery,
    GetSessionsConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSessionsConsumptionForExtendQuery, GetSessionsConsumptionForExtendQueryVariables>(
    GetSessionsConsumptionForExtendDocument,
    options,
  );
}
export function useGetSessionsConsumptionForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSessionsConsumptionForExtendQuery,
    GetSessionsConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSessionsConsumptionForExtendQuery, GetSessionsConsumptionForExtendQueryVariables>(
    GetSessionsConsumptionForExtendDocument,
    options,
  );
}
export type GetSessionsConsumptionForExtendQueryHookResult = ReturnType<typeof useGetSessionsConsumptionForExtendQuery>;
export type GetSessionsConsumptionForExtendLazyQueryHookResult = ReturnType<
  typeof useGetSessionsConsumptionForExtendLazyQuery
>;
export type GetSessionsConsumptionForExtendQueryResult = Apollo.QueryResult<
  GetSessionsConsumptionForExtendQuery,
  GetSessionsConsumptionForExtendQueryVariables
>;
export const GetSitesEvseCountDocument = gql`
  query GetSitesEvseCount {
    getSitesForExtend {
      evseCount
    }
  }
`;

/**
 * __useGetSitesEvseCountQuery__
 *
 * To run a query within a React component, call `useGetSitesEvseCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesEvseCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesEvseCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesEvseCountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSitesEvseCountQuery, GetSitesEvseCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSitesEvseCountQuery, GetSitesEvseCountQueryVariables>(GetSitesEvseCountDocument, options);
}
export function useGetSitesEvseCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSitesEvseCountQuery, GetSitesEvseCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSitesEvseCountQuery, GetSitesEvseCountQueryVariables>(
    GetSitesEvseCountDocument,
    options,
  );
}
export type GetSitesEvseCountQueryHookResult = ReturnType<typeof useGetSitesEvseCountQuery>;
export type GetSitesEvseCountLazyQueryHookResult = ReturnType<typeof useGetSitesEvseCountLazyQuery>;
export type GetSitesEvseCountQueryResult = Apollo.QueryResult<GetSitesEvseCountQuery, GetSitesEvseCountQueryVariables>;
export const GetSiteForExtendDocument = gql`
  query getSiteForExtend($siteInput: GetSiteForExtendInput) {
    getSiteForExtend(input: $siteInput) {
      altId
      displayName
      directions
      address1
      address2
      locality
      administrativeArea
      postalCode
    }
  }
`;

/**
 * __useGetSiteForExtendQuery__
 *
 * To run a query within a React component, call `useGetSiteForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteForExtendQuery({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useGetSiteForExtendQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSiteForExtendQuery, GetSiteForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSiteForExtendQuery, GetSiteForExtendQueryVariables>(GetSiteForExtendDocument, options);
}
export function useGetSiteForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSiteForExtendQuery, GetSiteForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSiteForExtendQuery, GetSiteForExtendQueryVariables>(GetSiteForExtendDocument, options);
}
export type GetSiteForExtendQueryHookResult = ReturnType<typeof useGetSiteForExtendQuery>;
export type GetSiteForExtendLazyQueryHookResult = ReturnType<typeof useGetSiteForExtendLazyQuery>;
export type GetSiteForExtendQueryResult = Apollo.QueryResult<GetSiteForExtendQuery, GetSiteForExtendQueryVariables>;
export const GetSitesForExtendDocument = gql`
  query GetSitesForExtend {
    getSitesForExtend {
      altId
      siteName
      siteStatusId
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
      evseCount
      chargers {
        edges {
          altId
          chargerName
        }
        total
      }
    }
  }
`;

/**
 * __useGetSitesForExtendQuery__
 *
 * To run a query within a React component, call `useGetSitesForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesForExtendQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesForExtendQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSitesForExtendQuery, GetSitesForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSitesForExtendQuery, GetSitesForExtendQueryVariables>(GetSitesForExtendDocument, options);
}
export function useGetSitesForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSitesForExtendQuery, GetSitesForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSitesForExtendQuery, GetSitesForExtendQueryVariables>(
    GetSitesForExtendDocument,
    options,
  );
}
export type GetSitesForExtendQueryHookResult = ReturnType<typeof useGetSitesForExtendQuery>;
export type GetSitesForExtendLazyQueryHookResult = ReturnType<typeof useGetSitesForExtendLazyQuery>;
export type GetSitesForExtendQueryResult = Apollo.QueryResult<GetSitesForExtendQuery, GetSitesForExtendQueryVariables>;
export const GetSitesShallowForExtendDocument = gql`
  query GetSitesShallowForExtend {
    getSitesForExtend {
      siteName
      postalCode
    }
  }
`;

/**
 * __useGetSitesShallowForExtendQuery__
 *
 * To run a query within a React component, call `useGetSitesShallowForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesShallowForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesShallowForExtendQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesShallowForExtendQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSitesShallowForExtendQuery, GetSitesShallowForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSitesShallowForExtendQuery, GetSitesShallowForExtendQueryVariables>(
    GetSitesShallowForExtendDocument,
    options,
  );
}
export function useGetSitesShallowForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSitesShallowForExtendQuery, GetSitesShallowForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSitesShallowForExtendQuery, GetSitesShallowForExtendQueryVariables>(
    GetSitesShallowForExtendDocument,
    options,
  );
}
export type GetSitesShallowForExtendQueryHookResult = ReturnType<typeof useGetSitesShallowForExtendQuery>;
export type GetSitesShallowForExtendLazyQueryHookResult = ReturnType<typeof useGetSitesShallowForExtendLazyQuery>;
export type GetSitesShallowForExtendQueryResult = Apollo.QueryResult<
  GetSitesShallowForExtendQuery,
  GetSitesShallowForExtendQueryVariables
>;
export const GetTariffForExtendDocument = gql`
  query getTariffForExtend($input: GetTariffForExtendInput!) {
    getTariffForExtend(input: $input) {
      ...TariffForExtend
    }
  }
  ${TariffForExtendFragmentDoc}
`;

/**
 * __useGetTariffForExtendQuery__
 *
 * To run a query within a React component, call `useGetTariffForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTariffForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<GetTariffForExtendQuery, GetTariffForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTariffForExtendQuery, GetTariffForExtendQueryVariables>(
    GetTariffForExtendDocument,
    options,
  );
}
export function useGetTariffForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTariffForExtendQuery, GetTariffForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTariffForExtendQuery, GetTariffForExtendQueryVariables>(
    GetTariffForExtendDocument,
    options,
  );
}
export type GetTariffForExtendQueryHookResult = ReturnType<typeof useGetTariffForExtendQuery>;
export type GetTariffForExtendLazyQueryHookResult = ReturnType<typeof useGetTariffForExtendLazyQuery>;
export type GetTariffForExtendQueryResult = Apollo.QueryResult<
  GetTariffForExtendQuery,
  GetTariffForExtendQueryVariables
>;
export const ListChargerGroupsForExtendDocument = gql`
  query listChargerGroupsForExtend($input: ListChargerGroupsForExtendInput!) {
    listChargerGroupsForExtend(input: $input) {
      edges {
        ...ChargerGroupForExtend
        chargers: chargersLoaded {
          edges {
            altId
          }
          total
        }
      }
      total
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
`;

/**
 * __useListChargerGroupsForExtendQuery__
 *
 * To run a query within a React component, call `useListChargerGroupsForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerGroupsForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerGroupsForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerGroupsForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<ListChargerGroupsForExtendQuery, ListChargerGroupsForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChargerGroupsForExtendQuery, ListChargerGroupsForExtendQueryVariables>(
    ListChargerGroupsForExtendDocument,
    options,
  );
}
export function useListChargerGroupsForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListChargerGroupsForExtendQuery, ListChargerGroupsForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChargerGroupsForExtendQuery, ListChargerGroupsForExtendQueryVariables>(
    ListChargerGroupsForExtendDocument,
    options,
  );
}
export type ListChargerGroupsForExtendQueryHookResult = ReturnType<typeof useListChargerGroupsForExtendQuery>;
export type ListChargerGroupsForExtendLazyQueryHookResult = ReturnType<typeof useListChargerGroupsForExtendLazyQuery>;
export type ListChargerGroupsForExtendQueryResult = Apollo.QueryResult<
  ListChargerGroupsForExtendQuery,
  ListChargerGroupsForExtendQueryVariables
>;
export const ListChargerGroupsForChargerPricesDocument = gql`
  query listChargerGroupsForChargerPrices($input: ListChargerGroupsForExtendInput!) {
    listChargerGroupsForExtend(input: $input) {
      edges {
        ...ChargerGroupForExtend
        tariff {
          altId
        }
        chargers: chargersLoaded {
          edges {
            altId
          }
          total
        }
      }
      total
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
`;

/**
 * __useListChargerGroupsForChargerPricesQuery__
 *
 * To run a query within a React component, call `useListChargerGroupsForChargerPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerGroupsForChargerPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerGroupsForChargerPricesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerGroupsForChargerPricesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListChargerGroupsForChargerPricesQuery,
    ListChargerGroupsForChargerPricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChargerGroupsForChargerPricesQuery, ListChargerGroupsForChargerPricesQueryVariables>(
    ListChargerGroupsForChargerPricesDocument,
    options,
  );
}
export function useListChargerGroupsForChargerPricesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChargerGroupsForChargerPricesQuery,
    ListChargerGroupsForChargerPricesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChargerGroupsForChargerPricesQuery, ListChargerGroupsForChargerPricesQueryVariables>(
    ListChargerGroupsForChargerPricesDocument,
    options,
  );
}
export type ListChargerGroupsForChargerPricesQueryHookResult = ReturnType<
  typeof useListChargerGroupsForChargerPricesQuery
>;
export type ListChargerGroupsForChargerPricesLazyQueryHookResult = ReturnType<
  typeof useListChargerGroupsForChargerPricesLazyQuery
>;
export type ListChargerGroupsForChargerPricesQueryResult = Apollo.QueryResult<
  ListChargerGroupsForChargerPricesQuery,
  ListChargerGroupsForChargerPricesQueryVariables
>;
export const ListChargerGroupsPlanTariffTagsForExtendDocument = gql`
  query listChargerGroupsPlanTariffTagsForExtend($input: ListChargerGroupsForExtendInput!) {
    listChargerGroupsForExtend(input: $input) {
      edges {
        altId
        chargerGroupName
        planTariffTags {
          ...PlanTariffTag
        }
      }
    }
  }
  ${PlanTariffTagFragmentDoc}
`;

/**
 * __useListChargerGroupsPlanTariffTagsForExtendQuery__
 *
 * To run a query within a React component, call `useListChargerGroupsPlanTariffTagsForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerGroupsPlanTariffTagsForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerGroupsPlanTariffTagsForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerGroupsPlanTariffTagsForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListChargerGroupsPlanTariffTagsForExtendQuery,
    ListChargerGroupsPlanTariffTagsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListChargerGroupsPlanTariffTagsForExtendQuery,
    ListChargerGroupsPlanTariffTagsForExtendQueryVariables
  >(ListChargerGroupsPlanTariffTagsForExtendDocument, options);
}
export function useListChargerGroupsPlanTariffTagsForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChargerGroupsPlanTariffTagsForExtendQuery,
    ListChargerGroupsPlanTariffTagsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListChargerGroupsPlanTariffTagsForExtendQuery,
    ListChargerGroupsPlanTariffTagsForExtendQueryVariables
  >(ListChargerGroupsPlanTariffTagsForExtendDocument, options);
}
export type ListChargerGroupsPlanTariffTagsForExtendQueryHookResult = ReturnType<
  typeof useListChargerGroupsPlanTariffTagsForExtendQuery
>;
export type ListChargerGroupsPlanTariffTagsForExtendLazyQueryHookResult = ReturnType<
  typeof useListChargerGroupsPlanTariffTagsForExtendLazyQuery
>;
export type ListChargerGroupsPlanTariffTagsForExtendQueryResult = Apollo.QueryResult<
  ListChargerGroupsPlanTariffTagsForExtendQuery,
  ListChargerGroupsPlanTariffTagsForExtendQueryVariables
>;
export const ListChargerGroupsTariffsForExtendDocument = gql`
  query listChargerGroupsTariffsForExtend($input: ListChargerGroupsForExtendInput!) {
    listChargerGroupsForExtend(input: $input) {
      edges {
        altId
        tariff {
          ...TariffForExtend
        }
      }
    }
  }
  ${TariffForExtendFragmentDoc}
`;

/**
 * __useListChargerGroupsTariffsForExtendQuery__
 *
 * To run a query within a React component, call `useListChargerGroupsTariffsForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerGroupsTariffsForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerGroupsTariffsForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerGroupsTariffsForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListChargerGroupsTariffsForExtendQuery,
    ListChargerGroupsTariffsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChargerGroupsTariffsForExtendQuery, ListChargerGroupsTariffsForExtendQueryVariables>(
    ListChargerGroupsTariffsForExtendDocument,
    options,
  );
}
export function useListChargerGroupsTariffsForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChargerGroupsTariffsForExtendQuery,
    ListChargerGroupsTariffsForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChargerGroupsTariffsForExtendQuery, ListChargerGroupsTariffsForExtendQueryVariables>(
    ListChargerGroupsTariffsForExtendDocument,
    options,
  );
}
export type ListChargerGroupsTariffsForExtendQueryHookResult = ReturnType<
  typeof useListChargerGroupsTariffsForExtendQuery
>;
export type ListChargerGroupsTariffsForExtendLazyQueryHookResult = ReturnType<
  typeof useListChargerGroupsTariffsForExtendLazyQuery
>;
export type ListChargerGroupsTariffsForExtendQueryResult = Apollo.QueryResult<
  ListChargerGroupsTariffsForExtendQuery,
  ListChargerGroupsTariffsForExtendQueryVariables
>;
export const ListChargersConsumptionForExtendDocument = gql`
  query listChargersConsumptionForExtend($input: ListChargersConsumptionForExtendInput!) {
    listChargersConsumptionForExtend(input: $input) {
      chargerId
      chargerName
      connectorMaxOutput
      connectorStatus
      connectorType
      connectorId
      evseId
      totalSessions
      totalSessionsPower
      totalSessionsTime
      lastSessionVendorId
      reservable
    }
  }
`;

/**
 * __useListChargersConsumptionForExtendQuery__
 *
 * To run a query within a React component, call `useListChargersConsumptionForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargersConsumptionForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargersConsumptionForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargersConsumptionForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListChargersConsumptionForExtendQuery,
    ListChargersConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChargersConsumptionForExtendQuery, ListChargersConsumptionForExtendQueryVariables>(
    ListChargersConsumptionForExtendDocument,
    options,
  );
}
export function useListChargersConsumptionForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChargersConsumptionForExtendQuery,
    ListChargersConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChargersConsumptionForExtendQuery, ListChargersConsumptionForExtendQueryVariables>(
    ListChargersConsumptionForExtendDocument,
    options,
  );
}
export type ListChargersConsumptionForExtendQueryHookResult = ReturnType<
  typeof useListChargersConsumptionForExtendQuery
>;
export type ListChargersConsumptionForExtendLazyQueryHookResult = ReturnType<
  typeof useListChargersConsumptionForExtendLazyQuery
>;
export type ListChargersConsumptionForExtendQueryResult = Apollo.QueryResult<
  ListChargersConsumptionForExtendQuery,
  ListChargersConsumptionForExtendQueryVariables
>;
export const ListChargersConsumptionOnlyStatusForExtendDocument = gql`
  query listChargersConsumptionOnlyStatusForExtend($input: ListChargersConsumptionForExtendInput!) {
    listChargersConsumptionForExtend(input: $input) {
      connectorStatus
    }
  }
`;

/**
 * __useListChargersConsumptionOnlyStatusForExtendQuery__
 *
 * To run a query within a React component, call `useListChargersConsumptionOnlyStatusForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargersConsumptionOnlyStatusForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargersConsumptionOnlyStatusForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargersConsumptionOnlyStatusForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListChargersConsumptionOnlyStatusForExtendQuery,
    ListChargersConsumptionOnlyStatusForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListChargersConsumptionOnlyStatusForExtendQuery,
    ListChargersConsumptionOnlyStatusForExtendQueryVariables
  >(ListChargersConsumptionOnlyStatusForExtendDocument, options);
}
export function useListChargersConsumptionOnlyStatusForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChargersConsumptionOnlyStatusForExtendQuery,
    ListChargersConsumptionOnlyStatusForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListChargersConsumptionOnlyStatusForExtendQuery,
    ListChargersConsumptionOnlyStatusForExtendQueryVariables
  >(ListChargersConsumptionOnlyStatusForExtendDocument, options);
}
export type ListChargersConsumptionOnlyStatusForExtendQueryHookResult = ReturnType<
  typeof useListChargersConsumptionOnlyStatusForExtendQuery
>;
export type ListChargersConsumptionOnlyStatusForExtendLazyQueryHookResult = ReturnType<
  typeof useListChargersConsumptionOnlyStatusForExtendLazyQuery
>;
export type ListChargersConsumptionOnlyStatusForExtendQueryResult = Apollo.QueryResult<
  ListChargersConsumptionOnlyStatusForExtendQuery,
  ListChargersConsumptionOnlyStatusForExtendQueryVariables
>;
export const ListChargersV2Document = gql`
  query listChargersV2($input: ListChargersV2Input) {
    listChargersV2(input: $input) {
      total
      edges {
        altId
        setTariffSynced
        displayTariffSynced
        tariffSyncStatus
        chargerStatus {
          columnText
        }
        evses {
          connectors {
            reservable
            chargingLevel
            connectorStatus {
              columnText
            }
          }
        }
        chargerName
        chargerModel {
          modelName
        }
        site {
          address1
          address2
          postalCode
          administrativeArea
          siteName
          locality
          country
        }
      }
    }
  }
`;

/**
 * __useListChargersV2Query__
 *
 * To run a query within a React component, call `useListChargersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useListChargersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargersV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargersV2Query(
  baseOptions?: Apollo.QueryHookOptions<ListChargersV2Query, ListChargersV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChargersV2Query, ListChargersV2QueryVariables>(ListChargersV2Document, options);
}
export function useListChargersV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListChargersV2Query, ListChargersV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChargersV2Query, ListChargersV2QueryVariables>(ListChargersV2Document, options);
}
export type ListChargersV2QueryHookResult = ReturnType<typeof useListChargersV2Query>;
export type ListChargersV2LazyQueryHookResult = ReturnType<typeof useListChargersV2LazyQuery>;
export type ListChargersV2QueryResult = Apollo.QueryResult<ListChargersV2Query, ListChargersV2QueryVariables>;
export const ListChargersV2ShallowDocument = gql`
  query listChargersV2Shallow($input: ListChargersV2Input) {
    listChargersV2(input: $input) {
      total
    }
  }
`;

/**
 * __useListChargersV2ShallowQuery__
 *
 * To run a query within a React component, call `useListChargersV2ShallowQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargersV2ShallowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargersV2ShallowQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargersV2ShallowQuery(
  baseOptions?: Apollo.QueryHookOptions<ListChargersV2ShallowQuery, ListChargersV2ShallowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChargersV2ShallowQuery, ListChargersV2ShallowQueryVariables>(
    ListChargersV2ShallowDocument,
    options,
  );
}
export function useListChargersV2ShallowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListChargersV2ShallowQuery, ListChargersV2ShallowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChargersV2ShallowQuery, ListChargersV2ShallowQueryVariables>(
    ListChargersV2ShallowDocument,
    options,
  );
}
export type ListChargersV2ShallowQueryHookResult = ReturnType<typeof useListChargersV2ShallowQuery>;
export type ListChargersV2ShallowLazyQueryHookResult = ReturnType<typeof useListChargersV2ShallowLazyQuery>;
export type ListChargersV2ShallowQueryResult = Apollo.QueryResult<
  ListChargersV2ShallowQuery,
  ListChargersV2ShallowQueryVariables
>;
export const ListExtendHostsDocument = gql`
  query listExtendHosts($input: ListExtendHostsInput) {
    listExtendHosts(input: $input) {
      total
      edges {
        altId
        createdAt
        prefix
        host {
          altId
          hostName
          hid
          status
          vendorId
        }
      }
    }
  }
`;

/**
 * __useListExtendHostsQuery__
 *
 * To run a query within a React component, call `useListExtendHostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExtendHostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExtendHostsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListExtendHostsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListExtendHostsQuery, ListExtendHostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListExtendHostsQuery, ListExtendHostsQueryVariables>(ListExtendHostsDocument, options);
}
export function useListExtendHostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListExtendHostsQuery, ListExtendHostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListExtendHostsQuery, ListExtendHostsQueryVariables>(ListExtendHostsDocument, options);
}
export type ListExtendHostsQueryHookResult = ReturnType<typeof useListExtendHostsQuery>;
export type ListExtendHostsLazyQueryHookResult = ReturnType<typeof useListExtendHostsLazyQuery>;
export type ListExtendHostsQueryResult = Apollo.QueryResult<ListExtendHostsQuery, ListExtendHostsQueryVariables>;
export const ListExtendUsersDocument = gql`
  query listExtendUsers($input: ListExtendUsersInput) {
    listExtendUsers(input: $input) {
      total
      edges {
        altId
        username
        firebaseId
        createdAt
        hosts {
          total
          edges {
            altId
            hostName
          }
        }
      }
    }
  }
`;

/**
 * __useListExtendUsersQuery__
 *
 * To run a query within a React component, call `useListExtendUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExtendUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExtendUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListExtendUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListExtendUsersQuery, ListExtendUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListExtendUsersQuery, ListExtendUsersQueryVariables>(ListExtendUsersDocument, options);
}
export function useListExtendUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListExtendUsersQuery, ListExtendUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListExtendUsersQuery, ListExtendUsersQueryVariables>(ListExtendUsersDocument, options);
}
export type ListExtendUsersQueryHookResult = ReturnType<typeof useListExtendUsersQuery>;
export type ListExtendUsersLazyQueryHookResult = ReturnType<typeof useListExtendUsersLazyQuery>;
export type ListExtendUsersQueryResult = Apollo.QueryResult<ListExtendUsersQuery, ListExtendUsersQueryVariables>;
export const ListSessionForDashboardLiveSessionsDocument = gql`
  query listSessionForDashboardLiveSessions($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        vendorId
        meterStart
        lastMeterValue
        connector {
          evse {
            charger {
              altId
              site {
                sid
                siteName
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListSessionForDashboardLiveSessionsQuery__
 *
 * To run a query within a React component, call `useListSessionForDashboardLiveSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSessionForDashboardLiveSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSessionForDashboardLiveSessionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSessionForDashboardLiveSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListSessionForDashboardLiveSessionsQuery,
    ListSessionForDashboardLiveSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSessionForDashboardLiveSessionsQuery, ListSessionForDashboardLiveSessionsQueryVariables>(
    ListSessionForDashboardLiveSessionsDocument,
    options,
  );
}
export function useListSessionForDashboardLiveSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListSessionForDashboardLiveSessionsQuery,
    ListSessionForDashboardLiveSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListSessionForDashboardLiveSessionsQuery,
    ListSessionForDashboardLiveSessionsQueryVariables
  >(ListSessionForDashboardLiveSessionsDocument, options);
}
export type ListSessionForDashboardLiveSessionsQueryHookResult = ReturnType<
  typeof useListSessionForDashboardLiveSessionsQuery
>;
export type ListSessionForDashboardLiveSessionsLazyQueryHookResult = ReturnType<
  typeof useListSessionForDashboardLiveSessionsLazyQuery
>;
export type ListSessionForDashboardLiveSessionsQueryResult = Apollo.QueryResult<
  ListSessionForDashboardLiveSessionsQuery,
  ListSessionForDashboardLiveSessionsQueryVariables
>;
export const ListSessionsForMapDocument = gql`
  query listSessionsForMap($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        connector {
          evse {
            charger {
              site {
                latitude
                longitude
                administrativeArea
                siteName
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListSessionsForMapQuery__
 *
 * To run a query within a React component, call `useListSessionsForMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSessionsForMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSessionsForMapQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSessionsForMapQuery(
  baseOptions: Apollo.QueryHookOptions<ListSessionsForMapQuery, ListSessionsForMapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSessionsForMapQuery, ListSessionsForMapQueryVariables>(
    ListSessionsForMapDocument,
    options,
  );
}
export function useListSessionsForMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSessionsForMapQuery, ListSessionsForMapQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSessionsForMapQuery, ListSessionsForMapQueryVariables>(
    ListSessionsForMapDocument,
    options,
  );
}
export type ListSessionsForMapQueryHookResult = ReturnType<typeof useListSessionsForMapQuery>;
export type ListSessionsForMapLazyQueryHookResult = ReturnType<typeof useListSessionsForMapLazyQuery>;
export type ListSessionsForMapQueryResult = Apollo.QueryResult<
  ListSessionsForMapQuery,
  ListSessionsForMapQueryVariables
>;
export const ListSessionsV2ExtendDocument = gql`
  query listSessionsV2Extend($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        altId
        vendorId
        connector {
          connectorType {
            columnText
          }
          evse {
            charger {
              chargerName
              site {
                siteName
                timeZone
              }
            }
          }
        }
        plan {
          planName
          chargerGroup {
            chargerGroupName
            altId
          }
        }
        stopSource {
          columnText
        }
        startSource {
          columnText
        }
        startTime
        endTime
        meterStart
        lastMeterValue
        transaction {
          altId
          tax
          type {
            columnText
          }
        }
        cost
      }
    }
  }
`;

/**
 * __useListSessionsV2ExtendQuery__
 *
 * To run a query within a React component, call `useListSessionsV2ExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSessionsV2ExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSessionsV2ExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSessionsV2ExtendQuery(
  baseOptions: Apollo.QueryHookOptions<ListSessionsV2ExtendQuery, ListSessionsV2ExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSessionsV2ExtendQuery, ListSessionsV2ExtendQueryVariables>(
    ListSessionsV2ExtendDocument,
    options,
  );
}
export function useListSessionsV2ExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSessionsV2ExtendQuery, ListSessionsV2ExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSessionsV2ExtendQuery, ListSessionsV2ExtendQueryVariables>(
    ListSessionsV2ExtendDocument,
    options,
  );
}
export type ListSessionsV2ExtendQueryHookResult = ReturnType<typeof useListSessionsV2ExtendQuery>;
export type ListSessionsV2ExtendLazyQueryHookResult = ReturnType<typeof useListSessionsV2ExtendLazyQuery>;
export type ListSessionsV2ExtendQueryResult = Apollo.QueryResult<
  ListSessionsV2ExtendQuery,
  ListSessionsV2ExtendQueryVariables
>;
export const ListSitesConsumptionForExtendDocument = gql`
  query listSitesConsumptionForExtend($input: ListSitesConsumptionForExtendInput!) {
    listSitesConsumptionForExtend(input: $input) {
      edges {
        address
        siteId
        siteName
        siteStatus
        administrativeArea
        siteLatitude
        siteLongitude
        totalChargers
        totalSessions
        totalSessionsCost
        totalSessionsPower
        averageSessionPower
        totalSessionsTime
        averageSessionTime
      }
      page
      pageSize
    }
  }
`;

/**
 * __useListSitesConsumptionForExtendQuery__
 *
 * To run a query within a React component, call `useListSitesConsumptionForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSitesConsumptionForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSitesConsumptionForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSitesConsumptionForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<ListSitesConsumptionForExtendQuery, ListSitesConsumptionForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSitesConsumptionForExtendQuery, ListSitesConsumptionForExtendQueryVariables>(
    ListSitesConsumptionForExtendDocument,
    options,
  );
}
export function useListSitesConsumptionForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListSitesConsumptionForExtendQuery,
    ListSitesConsumptionForExtendQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSitesConsumptionForExtendQuery, ListSitesConsumptionForExtendQueryVariables>(
    ListSitesConsumptionForExtendDocument,
    options,
  );
}
export type ListSitesConsumptionForExtendQueryHookResult = ReturnType<typeof useListSitesConsumptionForExtendQuery>;
export type ListSitesConsumptionForExtendLazyQueryHookResult = ReturnType<
  typeof useListSitesConsumptionForExtendLazyQuery
>;
export type ListSitesConsumptionForExtendQueryResult = Apollo.QueryResult<
  ListSitesConsumptionForExtendQuery,
  ListSitesConsumptionForExtendQueryVariables
>;
export const ListTariffsForExtendDocument = gql`
  query listTariffsForExtend($input: ListTariffsForExtendInput!) {
    listTariffsForExtend(input: $input) {
      edges {
        ...TariffForExtend
      }
      total
    }
  }
  ${TariffForExtendFragmentDoc}
`;

/**
 * __useListTariffsForExtendQuery__
 *
 * To run a query within a React component, call `useListTariffsForExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTariffsForExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTariffsForExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTariffsForExtendQuery(
  baseOptions: Apollo.QueryHookOptions<ListTariffsForExtendQuery, ListTariffsForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTariffsForExtendQuery, ListTariffsForExtendQueryVariables>(
    ListTariffsForExtendDocument,
    options,
  );
}
export function useListTariffsForExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTariffsForExtendQuery, ListTariffsForExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTariffsForExtendQuery, ListTariffsForExtendQueryVariables>(
    ListTariffsForExtendDocument,
    options,
  );
}
export type ListTariffsForExtendQueryHookResult = ReturnType<typeof useListTariffsForExtendQuery>;
export type ListTariffsForExtendLazyQueryHookResult = ReturnType<typeof useListTariffsForExtendLazyQuery>;
export type ListTariffsForExtendQueryResult = Apollo.QueryResult<
  ListTariffsForExtendQuery,
  ListTariffsForExtendQueryVariables
>;
export const ListTransactionsV2ExtendDocument = gql`
  query listTransactionsV2Extend($input: ListTransactionsV2Input!) {
    listTransactionsV2(input: $input) {
      page
      pageSize
      total
      edges {
        vendorId
        items {
          altId
          quantity
          unitPrice
          productType {
            columnText
          }
          taxRate
          calculatedAmount
          calculatedUnitPrice
        }
        session {
          altId
          vendorId
          connector {
            connectorType {
              columnText
            }
            evse {
              charger {
                chargerName
                site {
                  siteName
                  timeZone
                }
              }
            }
          }
          plan {
            planName
            chargerGroup {
              altId
              chargerGroupName
            }
          }
          stopSource {
            columnText
          }
          startSource {
            columnText
          }
          startTime
          endTime
          startTimeLocal
          endTimeLocal
          meterStart
          meterEnd
          cost
        }
        altId
        tax
        amount
        calculatedAmount
        calculatedTaxAmount
        isCalculated
        type {
          columnText
        }
      }
    }
  }
`;

/**
 * __useListTransactionsV2ExtendQuery__
 *
 * To run a query within a React component, call `useListTransactionsV2ExtendQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionsV2ExtendQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionsV2ExtendQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTransactionsV2ExtendQuery(
  baseOptions: Apollo.QueryHookOptions<ListTransactionsV2ExtendQuery, ListTransactionsV2ExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTransactionsV2ExtendQuery, ListTransactionsV2ExtendQueryVariables>(
    ListTransactionsV2ExtendDocument,
    options,
  );
}
export function useListTransactionsV2ExtendLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTransactionsV2ExtendQuery, ListTransactionsV2ExtendQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTransactionsV2ExtendQuery, ListTransactionsV2ExtendQueryVariables>(
    ListTransactionsV2ExtendDocument,
    options,
  );
}
export type ListTransactionsV2ExtendQueryHookResult = ReturnType<typeof useListTransactionsV2ExtendQuery>;
export type ListTransactionsV2ExtendLazyQueryHookResult = ReturnType<typeof useListTransactionsV2ExtendLazyQuery>;
export type ListTransactionsV2ExtendQueryResult = Apollo.QueryResult<
  ListTransactionsV2ExtendQuery,
  ListTransactionsV2ExtendQueryVariables
>;
export const RemoveChargersFromChargerGroupForExtendDocument = gql`
  mutation removeChargersFromChargerGroupForExtend($input: RemoveChargersFromChargerGroupForExtendInput!) {
    removeChargersFromChargerGroupForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type RemoveChargersFromChargerGroupForExtendMutationFn = Apollo.MutationFunction<
  RemoveChargersFromChargerGroupForExtendMutation,
  RemoveChargersFromChargerGroupForExtendMutationVariables
>;

/**
 * __useRemoveChargersFromChargerGroupForExtendMutation__
 *
 * To run a mutation, you first call `useRemoveChargersFromChargerGroupForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChargersFromChargerGroupForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChargersFromChargerGroupForExtendMutation, { data, loading, error }] = useRemoveChargersFromChargerGroupForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveChargersFromChargerGroupForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveChargersFromChargerGroupForExtendMutation,
    RemoveChargersFromChargerGroupForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveChargersFromChargerGroupForExtendMutation,
    RemoveChargersFromChargerGroupForExtendMutationVariables
  >(RemoveChargersFromChargerGroupForExtendDocument, options);
}
export type RemoveChargersFromChargerGroupForExtendMutationHookResult = ReturnType<
  typeof useRemoveChargersFromChargerGroupForExtendMutation
>;
export type RemoveChargersFromChargerGroupForExtendMutationResult =
  Apollo.MutationResult<RemoveChargersFromChargerGroupForExtendMutation>;
export type RemoveChargersFromChargerGroupForExtendMutationOptions = Apollo.BaseMutationOptions<
  RemoveChargersFromChargerGroupForExtendMutation,
  RemoveChargersFromChargerGroupForExtendMutationVariables
>;
export const ResetChargerForExtendDocument = gql`
  mutation resetChargerForExtend($chargerInput: ResetChargerForExtendInput!) {
    resetChargerForExtend(input: $chargerInput)
  }
`;
export type ResetChargerForExtendMutationFn = Apollo.MutationFunction<
  ResetChargerForExtendMutation,
  ResetChargerForExtendMutationVariables
>;

/**
 * __useResetChargerForExtendMutation__
 *
 * To run a mutation, you first call `useResetChargerForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetChargerForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetChargerForExtendMutation, { data, loading, error }] = useResetChargerForExtendMutation({
 *   variables: {
 *      chargerInput: // value for 'chargerInput'
 *   },
 * });
 */
export function useResetChargerForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetChargerForExtendMutation, ResetChargerForExtendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetChargerForExtendMutation, ResetChargerForExtendMutationVariables>(
    ResetChargerForExtendDocument,
    options,
  );
}
export type ResetChargerForExtendMutationHookResult = ReturnType<typeof useResetChargerForExtendMutation>;
export type ResetChargerForExtendMutationResult = Apollo.MutationResult<ResetChargerForExtendMutation>;
export type ResetChargerForExtendMutationOptions = Apollo.BaseMutationOptions<
  ResetChargerForExtendMutation,
  ResetChargerForExtendMutationVariables
>;
export const UpdateChargerPriceGroupForExtendDocument = gql`
  mutation updateChargerPriceGroupForExtend($input: UpdateChargerPriceGroupForExtendInput!) {
    updateChargerPriceGroupForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type UpdateChargerPriceGroupForExtendMutationFn = Apollo.MutationFunction<
  UpdateChargerPriceGroupForExtendMutation,
  UpdateChargerPriceGroupForExtendMutationVariables
>;

/**
 * __useUpdateChargerPriceGroupForExtendMutation__
 *
 * To run a mutation, you first call `useUpdateChargerPriceGroupForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargerPriceGroupForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargerPriceGroupForExtendMutation, { data, loading, error }] = useUpdateChargerPriceGroupForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChargerPriceGroupForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChargerPriceGroupForExtendMutation,
    UpdateChargerPriceGroupForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChargerPriceGroupForExtendMutation,
    UpdateChargerPriceGroupForExtendMutationVariables
  >(UpdateChargerPriceGroupForExtendDocument, options);
}
export type UpdateChargerPriceGroupForExtendMutationHookResult = ReturnType<
  typeof useUpdateChargerPriceGroupForExtendMutation
>;
export type UpdateChargerPriceGroupForExtendMutationResult =
  Apollo.MutationResult<UpdateChargerPriceGroupForExtendMutation>;
export type UpdateChargerPriceGroupForExtendMutationOptions = Apollo.BaseMutationOptions<
  UpdateChargerPriceGroupForExtendMutation,
  UpdateChargerPriceGroupForExtendMutationVariables
>;
export const UpdateChargerGroupPlanForExtendDocument = gql`
  mutation updateChargerGroupPlanForExtend($input: UpdateChargerGroupPlanForExtendInput!) {
    updateChargerGroupPlanForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type UpdateChargerGroupPlanForExtendMutationFn = Apollo.MutationFunction<
  UpdateChargerGroupPlanForExtendMutation,
  UpdateChargerGroupPlanForExtendMutationVariables
>;

/**
 * __useUpdateChargerGroupPlanForExtendMutation__
 *
 * To run a mutation, you first call `useUpdateChargerGroupPlanForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargerGroupPlanForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargerGroupPlanForExtendMutation, { data, loading, error }] = useUpdateChargerGroupPlanForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChargerGroupPlanForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChargerGroupPlanForExtendMutation,
    UpdateChargerGroupPlanForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateChargerGroupPlanForExtendMutation, UpdateChargerGroupPlanForExtendMutationVariables>(
    UpdateChargerGroupPlanForExtendDocument,
    options,
  );
}
export type UpdateChargerGroupPlanForExtendMutationHookResult = ReturnType<
  typeof useUpdateChargerGroupPlanForExtendMutation
>;
export type UpdateChargerGroupPlanForExtendMutationResult =
  Apollo.MutationResult<UpdateChargerGroupPlanForExtendMutation>;
export type UpdateChargerGroupPlanForExtendMutationOptions = Apollo.BaseMutationOptions<
  UpdateChargerGroupPlanForExtendMutation,
  UpdateChargerGroupPlanForExtendMutationVariables
>;
export const UpdateExtendUserHostsDocument = gql`
  mutation updateExtendUserHosts($extenduserInput: UpdateExtendUserHostsInput) {
    updateExtendUserHosts(input: $extenduserInput) {
      altId
      hosts {
        edges {
          hostName
          hid
        }
      }
    }
  }
`;
export type UpdateExtendUserHostsMutationFn = Apollo.MutationFunction<
  UpdateExtendUserHostsMutation,
  UpdateExtendUserHostsMutationVariables
>;

/**
 * __useUpdateExtendUserHostsMutation__
 *
 * To run a mutation, you first call `useUpdateExtendUserHostsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExtendUserHostsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExtendUserHostsMutation, { data, loading, error }] = useUpdateExtendUserHostsMutation({
 *   variables: {
 *      extenduserInput: // value for 'extenduserInput'
 *   },
 * });
 */
export function useUpdateExtendUserHostsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateExtendUserHostsMutation, UpdateExtendUserHostsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExtendUserHostsMutation, UpdateExtendUserHostsMutationVariables>(
    UpdateExtendUserHostsDocument,
    options,
  );
}
export type UpdateExtendUserHostsMutationHookResult = ReturnType<typeof useUpdateExtendUserHostsMutation>;
export type UpdateExtendUserHostsMutationResult = Apollo.MutationResult<UpdateExtendUserHostsMutation>;
export type UpdateExtendUserHostsMutationOptions = Apollo.BaseMutationOptions<
  UpdateExtendUserHostsMutation,
  UpdateExtendUserHostsMutationVariables
>;
export const UpdatePlanTariffsForExtendDocument = gql`
  mutation updatePlanTariffsForExtend($input: UpdatePlanTariffsForExtendInput!) {
    updatePlanTariffsForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtendFragmentDoc}
  ${TariffForExtendFragmentDoc}
  ${PlanTariffForExtendFragmentDoc}
  ${ChargersWithMetaFragmentDoc}
`;
export type UpdatePlanTariffsForExtendMutationFn = Apollo.MutationFunction<
  UpdatePlanTariffsForExtendMutation,
  UpdatePlanTariffsForExtendMutationVariables
>;

/**
 * __useUpdatePlanTariffsForExtendMutation__
 *
 * To run a mutation, you first call `useUpdatePlanTariffsForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanTariffsForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanTariffsForExtendMutation, { data, loading, error }] = useUpdatePlanTariffsForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanTariffsForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePlanTariffsForExtendMutation,
    UpdatePlanTariffsForExtendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlanTariffsForExtendMutation, UpdatePlanTariffsForExtendMutationVariables>(
    UpdatePlanTariffsForExtendDocument,
    options,
  );
}
export type UpdatePlanTariffsForExtendMutationHookResult = ReturnType<typeof useUpdatePlanTariffsForExtendMutation>;
export type UpdatePlanTariffsForExtendMutationResult = Apollo.MutationResult<UpdatePlanTariffsForExtendMutation>;
export type UpdatePlanTariffsForExtendMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanTariffsForExtendMutation,
  UpdatePlanTariffsForExtendMutationVariables
>;
export const UpdateSiteForExtendDocument = gql`
  mutation updateSiteForExtend($siteInput: UpdateSiteForExtendInput) {
    updateSiteForExtend(input: $siteInput) {
      altId
      directions
    }
  }
`;
export type UpdateSiteForExtendMutationFn = Apollo.MutationFunction<
  UpdateSiteForExtendMutation,
  UpdateSiteForExtendMutationVariables
>;

/**
 * __useUpdateSiteForExtendMutation__
 *
 * To run a mutation, you first call `useUpdateSiteForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteForExtendMutation, { data, loading, error }] = useUpdateSiteForExtendMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useUpdateSiteForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSiteForExtendMutation, UpdateSiteForExtendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSiteForExtendMutation, UpdateSiteForExtendMutationVariables>(
    UpdateSiteForExtendDocument,
    options,
  );
}
export type UpdateSiteForExtendMutationHookResult = ReturnType<typeof useUpdateSiteForExtendMutation>;
export type UpdateSiteForExtendMutationResult = Apollo.MutationResult<UpdateSiteForExtendMutation>;
export type UpdateSiteForExtendMutationOptions = Apollo.BaseMutationOptions<
  UpdateSiteForExtendMutation,
  UpdateSiteForExtendMutationVariables
>;
export const UpdateTariffForExtendDocument = gql`
  mutation updateTariffForExtend($input: UpdateTariffForExtendInput!) {
    updateTariffForExtend(input: $input) {
      ...TariffForExtend
    }
  }
  ${TariffForExtendFragmentDoc}
`;
export type UpdateTariffForExtendMutationFn = Apollo.MutationFunction<
  UpdateTariffForExtendMutation,
  UpdateTariffForExtendMutationVariables
>;

/**
 * __useUpdateTariffForExtendMutation__
 *
 * To run a mutation, you first call `useUpdateTariffForExtendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTariffForExtendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTariffForExtendMutation, { data, loading, error }] = useUpdateTariffForExtendMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTariffForExtendMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTariffForExtendMutation, UpdateTariffForExtendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTariffForExtendMutation, UpdateTariffForExtendMutationVariables>(
    UpdateTariffForExtendDocument,
    options,
  );
}
export type UpdateTariffForExtendMutationHookResult = ReturnType<typeof useUpdateTariffForExtendMutation>;
export type UpdateTariffForExtendMutationResult = Apollo.MutationResult<UpdateTariffForExtendMutation>;
export type UpdateTariffForExtendMutationOptions = Apollo.BaseMutationOptions<
  UpdateTariffForExtendMutation,
  UpdateTariffForExtendMutationVariables
>;
export const ListGeographicalRegionsDocument = gql`
  query listGeographicalRegions($usaInput: ListGeographicalRegionsInput!, $canadaInput: ListGeographicalRegionsInput!) {
    listGeographicalRegionsUsa: listGeographicalRegions(input: $usaInput) {
      total
      edges {
        value: abbreviation
        label: name
      }
    }
    listGeographicalRegionsCanada: listGeographicalRegions(input: $canadaInput) {
      total
      edges {
        value: abbreviation
        label: name
      }
    }
  }
`;

/**
 * __useListGeographicalRegionsQuery__
 *
 * To run a query within a React component, call `useListGeographicalRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGeographicalRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGeographicalRegionsQuery({
 *   variables: {
 *      usaInput: // value for 'usaInput'
 *      canadaInput: // value for 'canadaInput'
 *   },
 * });
 */
export function useListGeographicalRegionsQuery(
  baseOptions: Apollo.QueryHookOptions<ListGeographicalRegionsQuery, ListGeographicalRegionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListGeographicalRegionsQuery, ListGeographicalRegionsQueryVariables>(
    ListGeographicalRegionsDocument,
    options,
  );
}
export function useListGeographicalRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListGeographicalRegionsQuery, ListGeographicalRegionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListGeographicalRegionsQuery, ListGeographicalRegionsQueryVariables>(
    ListGeographicalRegionsDocument,
    options,
  );
}
export type ListGeographicalRegionsQueryHookResult = ReturnType<typeof useListGeographicalRegionsQuery>;
export type ListGeographicalRegionsLazyQueryHookResult = ReturnType<typeof useListGeographicalRegionsLazyQuery>;
export type ListGeographicalRegionsQueryResult = Apollo.QueryResult<
  ListGeographicalRegionsQuery,
  ListGeographicalRegionsQueryVariables
>;
export const CreateHostDocument = gql`
  mutation createHost($input: CreateHostInput) {
    createHost(input: $input) {
      hid
      altId
      address1
      locality
      administrativeArea
      hostName
      postalCode
      status
    }
  }
`;
export type CreateHostMutationFn = Apollo.MutationFunction<CreateHostMutation, CreateHostMutationVariables>;

/**
 * __useCreateHostMutation__
 *
 * To run a mutation, you first call `useCreateHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHostMutation, { data, loading, error }] = useCreateHostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHostMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateHostMutation, CreateHostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateHostMutation, CreateHostMutationVariables>(CreateHostDocument, options);
}
export type CreateHostMutationHookResult = ReturnType<typeof useCreateHostMutation>;
export type CreateHostMutationResult = Apollo.MutationResult<CreateHostMutation>;
export type CreateHostMutationOptions = Apollo.BaseMutationOptions<CreateHostMutation, CreateHostMutationVariables>;
export const GetHostByIdDocument = gql`
  query getHostById(
    $hostInput: GetHostInput
    $propertiesInput: ListPropertiesInput
    $sitesInput: ListSitesInput
    $contactsInput: ListContactsInput
  ) {
    getHost(input: $hostInput) {
      hid
      altId
      hostName
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
      contacts(input: $contactsInput) {
        edges {
          altId
          contactName
          workTitle
          email
          phone
        }
      }
      properties(input: $propertiesInput) {
        edges {
          address1
          address2
          locality
          administrativeArea
          postalCode
          country
          altId
          propertyName
          pid
          status
          sites(input: $sitesInput) {
            edges {
              chargers {
                total
              }
              altId
              siteName
              address1
              address2
              locality
              administrativeArea
              postalCode
              country
              sid
              displayName
              latitude
              longitude
              access
              siteStatusId
              type
            }
            total
          }
        }
        page
        pageSize
        sort {
          altId
          pid
          propertyName
          hostName
          status
        }
        total
        search {
          pid {
            iLike
          }
          propertyName {
            iLike
          }
          address1 {
            iLike
          }
          locality {
            iLike
          }
          administrativeArea {
            iLike
          }
          postalCode {
            iLike
          }
        }
      }
      status
    }
  }
`;

/**
 * __useGetHostByIdQuery__
 *
 * To run a query within a React component, call `useGetHostByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostByIdQuery({
 *   variables: {
 *      hostInput: // value for 'hostInput'
 *      propertiesInput: // value for 'propertiesInput'
 *      sitesInput: // value for 'sitesInput'
 *      contactsInput: // value for 'contactsInput'
 *   },
 * });
 */
export function useGetHostByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetHostByIdQuery, GetHostByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHostByIdQuery, GetHostByIdQueryVariables>(GetHostByIdDocument, options);
}
export function useGetHostByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHostByIdQuery, GetHostByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHostByIdQuery, GetHostByIdQueryVariables>(GetHostByIdDocument, options);
}
export type GetHostByIdQueryHookResult = ReturnType<typeof useGetHostByIdQuery>;
export type GetHostByIdLazyQueryHookResult = ReturnType<typeof useGetHostByIdLazyQuery>;
export type GetHostByIdQueryResult = Apollo.QueryResult<GetHostByIdQuery, GetHostByIdQueryVariables>;
export const GetHostByNameDocument = gql`
  query getHostByName($input: GetHostInput) {
    getHost(input: $input) {
      altId
    }
  }
`;

/**
 * __useGetHostByNameQuery__
 *
 * To run a query within a React component, call `useGetHostByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostByNameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHostByNameQuery(
  baseOptions?: Apollo.QueryHookOptions<GetHostByNameQuery, GetHostByNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHostByNameQuery, GetHostByNameQueryVariables>(GetHostByNameDocument, options);
}
export function useGetHostByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHostByNameQuery, GetHostByNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHostByNameQuery, GetHostByNameQueryVariables>(GetHostByNameDocument, options);
}
export type GetHostByNameQueryHookResult = ReturnType<typeof useGetHostByNameQuery>;
export type GetHostByNameLazyQueryHookResult = ReturnType<typeof useGetHostByNameLazyQuery>;
export type GetHostByNameQueryResult = Apollo.QueryResult<GetHostByNameQuery, GetHostByNameQueryVariables>;
export const GetHostsTotalDocument = gql`
  query getHostsTotal($input: ListHostsInput) {
    listHosts(input: $input) {
      total
    }
  }
`;

/**
 * __useGetHostsTotalQuery__
 *
 * To run a query within a React component, call `useGetHostsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHostsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHostsTotalQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHostsTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<GetHostsTotalQuery, GetHostsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHostsTotalQuery, GetHostsTotalQueryVariables>(GetHostsTotalDocument, options);
}
export function useGetHostsTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHostsTotalQuery, GetHostsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHostsTotalQuery, GetHostsTotalQueryVariables>(GetHostsTotalDocument, options);
}
export type GetHostsTotalQueryHookResult = ReturnType<typeof useGetHostsTotalQuery>;
export type GetHostsTotalLazyQueryHookResult = ReturnType<typeof useGetHostsTotalLazyQuery>;
export type GetHostsTotalQueryResult = Apollo.QueryResult<GetHostsTotalQuery, GetHostsTotalQueryVariables>;
export const GetSalesforceHostByIdDocument = gql`
  query getSalesforceHostById($input: GetSalesforceHostInput) {
    getSalesforceHost(input: $input) {
      address1
      address2
      locality
      hostName
      postalCode
      administrativeArea
      country
      hid
      hostName
      salesforceHostContacts {
        edges {
          contactName
          phone
          email
        }
      }
    }
  }
`;

/**
 * __useGetSalesforceHostByIdQuery__
 *
 * To run a query within a React component, call `useGetSalesforceHostByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesforceHostByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesforceHostByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSalesforceHostByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSalesforceHostByIdQuery, GetSalesforceHostByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSalesforceHostByIdQuery, GetSalesforceHostByIdQueryVariables>(
    GetSalesforceHostByIdDocument,
    options,
  );
}
export function useGetSalesforceHostByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSalesforceHostByIdQuery, GetSalesforceHostByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSalesforceHostByIdQuery, GetSalesforceHostByIdQueryVariables>(
    GetSalesforceHostByIdDocument,
    options,
  );
}
export type GetSalesforceHostByIdQueryHookResult = ReturnType<typeof useGetSalesforceHostByIdQuery>;
export type GetSalesforceHostByIdLazyQueryHookResult = ReturnType<typeof useGetSalesforceHostByIdLazyQuery>;
export type GetSalesforceHostByIdQueryResult = Apollo.QueryResult<
  GetSalesforceHostByIdQuery,
  GetSalesforceHostByIdQueryVariables
>;
export const ImportHostDocument = gql`
  mutation importHost($hostInput: ImportHostInput) {
    importHost(input: $hostInput) {
      hid
      altId
      address1
      address2
      locality
      administrativeArea
      hostName
      postalCode
      status
    }
  }
`;
export type ImportHostMutationFn = Apollo.MutationFunction<ImportHostMutation, ImportHostMutationVariables>;

/**
 * __useImportHostMutation__
 *
 * To run a mutation, you first call `useImportHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importHostMutation, { data, loading, error }] = useImportHostMutation({
 *   variables: {
 *      hostInput: // value for 'hostInput'
 *   },
 * });
 */
export function useImportHostMutation(
  baseOptions?: Apollo.MutationHookOptions<ImportHostMutation, ImportHostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportHostMutation, ImportHostMutationVariables>(ImportHostDocument, options);
}
export type ImportHostMutationHookResult = ReturnType<typeof useImportHostMutation>;
export type ImportHostMutationResult = Apollo.MutationResult<ImportHostMutation>;
export type ImportHostMutationOptions = Apollo.BaseMutationOptions<ImportHostMutation, ImportHostMutationVariables>;
export const ListHostsDocument = gql`
  query listHosts($hostsInput: ListHostsInput, $propertiesInput: ListPropertiesInput, $sitesInput: ListSitesInput) {
    listHosts(input: $hostsInput) {
      page
      pageSize
      total
      edges {
        hid
        altId
        hostName
        status
        properties(input: $propertiesInput) {
          edges {
            pid
            altId
            propertyName
            sites(input: $sitesInput) {
              edges {
                chargers {
                  total
                }
              }
              total
            }
          }
        }
      }
      sort {
        altId
        hid
        hostName
        status
      }
      filter {
        status {
          in
        }
      }
      search {
        hid {
          iLike
        }
      }
    }
  }
`;

/**
 * __useListHostsQuery__
 *
 * To run a query within a React component, call `useListHostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHostsQuery({
 *   variables: {
 *      hostsInput: // value for 'hostsInput'
 *      propertiesInput: // value for 'propertiesInput'
 *      sitesInput: // value for 'sitesInput'
 *   },
 * });
 */
export function useListHostsQuery(baseOptions?: Apollo.QueryHookOptions<ListHostsQuery, ListHostsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListHostsQuery, ListHostsQueryVariables>(ListHostsDocument, options);
}
export function useListHostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListHostsQuery, ListHostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListHostsQuery, ListHostsQueryVariables>(ListHostsDocument, options);
}
export type ListHostsQueryHookResult = ReturnType<typeof useListHostsQuery>;
export type ListHostsLazyQueryHookResult = ReturnType<typeof useListHostsLazyQuery>;
export type ListHostsQueryResult = Apollo.QueryResult<ListHostsQuery, ListHostsQueryVariables>;
export const ListSalesforceHostContactsDocument = gql`
  query listSalesforceHostContacts($input: ListSalesforceHostContactsInput) {
    listSalesforceHostContacts(input: $input) {
      page
      pageSize
      total
      edges {
        contactName
        phone
        email
        workTitle
      }
    }
  }
`;

/**
 * __useListSalesforceHostContactsQuery__
 *
 * To run a query within a React component, call `useListSalesforceHostContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSalesforceHostContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSalesforceHostContactsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSalesforceHostContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListSalesforceHostContactsQuery, ListSalesforceHostContactsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSalesforceHostContactsQuery, ListSalesforceHostContactsQueryVariables>(
    ListSalesforceHostContactsDocument,
    options,
  );
}
export function useListSalesforceHostContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSalesforceHostContactsQuery, ListSalesforceHostContactsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSalesforceHostContactsQuery, ListSalesforceHostContactsQueryVariables>(
    ListSalesforceHostContactsDocument,
    options,
  );
}
export type ListSalesforceHostContactsQueryHookResult = ReturnType<typeof useListSalesforceHostContactsQuery>;
export type ListSalesforceHostContactsLazyQueryHookResult = ReturnType<typeof useListSalesforceHostContactsLazyQuery>;
export type ListSalesforceHostContactsQueryResult = Apollo.QueryResult<
  ListSalesforceHostContactsQuery,
  ListSalesforceHostContactsQueryVariables
>;
export const ListSalesforcePropertiesDocument = gql`
  query listSalesforceProperties($input: ListSalesforcePropertiesInput) {
    listSalesforceProperties(input: $input) {
      edges {
        hid
        pid
        evcId
        propertyName
        address1
        address2
        address3
        address4
        locality
        administrativeArea
        postalCode
        country
      }
      page
      pageSize
      total
    }
  }
`;

/**
 * __useListSalesforcePropertiesQuery__
 *
 * To run a query within a React component, call `useListSalesforcePropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSalesforcePropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSalesforcePropertiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSalesforcePropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListSalesforcePropertiesQuery, ListSalesforcePropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSalesforcePropertiesQuery, ListSalesforcePropertiesQueryVariables>(
    ListSalesforcePropertiesDocument,
    options,
  );
}
export function useListSalesforcePropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSalesforcePropertiesQuery, ListSalesforcePropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSalesforcePropertiesQuery, ListSalesforcePropertiesQueryVariables>(
    ListSalesforcePropertiesDocument,
    options,
  );
}
export type ListSalesforcePropertiesQueryHookResult = ReturnType<typeof useListSalesforcePropertiesQuery>;
export type ListSalesforcePropertiesLazyQueryHookResult = ReturnType<typeof useListSalesforcePropertiesLazyQuery>;
export type ListSalesforcePropertiesQueryResult = Apollo.QueryResult<
  ListSalesforcePropertiesQuery,
  ListSalesforcePropertiesQueryVariables
>;
export const UpdateHostDocument = gql`
  mutation updateHost($hostInput: UpdateHostInput) {
    updateHost(input: $hostInput) {
      altId
    }
  }
`;
export type UpdateHostMutationFn = Apollo.MutationFunction<UpdateHostMutation, UpdateHostMutationVariables>;

/**
 * __useUpdateHostMutation__
 *
 * To run a mutation, you first call `useUpdateHostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHostMutation, { data, loading, error }] = useUpdateHostMutation({
 *   variables: {
 *      hostInput: // value for 'hostInput'
 *   },
 * });
 */
export function useUpdateHostMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateHostMutation, UpdateHostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateHostMutation, UpdateHostMutationVariables>(UpdateHostDocument, options);
}
export type UpdateHostMutationHookResult = ReturnType<typeof useUpdateHostMutation>;
export type UpdateHostMutationResult = Apollo.MutationResult<UpdateHostMutation>;
export type UpdateHostMutationOptions = Apollo.BaseMutationOptions<UpdateHostMutation, UpdateHostMutationVariables>;
export const LoginDocument = gql`
  mutation login($loginInput: LoginInput!) {
    login(input: $loginInput) @client
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginInput: // value for 'loginInput'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const CreateUploadDocument = gql`
  mutation createUpload($input: Upload!) {
    createUpload(input: $input) {
      altId
      url
      fileName
      mimeType
      encoding
      createdAt
    }
  }
`;
export type CreateUploadMutationFn = Apollo.MutationFunction<CreateUploadMutation, CreateUploadMutationVariables>;

/**
 * __useCreateUploadMutation__
 *
 * To run a mutation, you first call `useCreateUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadMutation, { data, loading, error }] = useCreateUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUploadMutation, CreateUploadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUploadMutation, CreateUploadMutationVariables>(CreateUploadDocument, options);
}
export type CreateUploadMutationHookResult = ReturnType<typeof useCreateUploadMutation>;
export type CreateUploadMutationResult = Apollo.MutationResult<CreateUploadMutation>;
export type CreateUploadMutationOptions = Apollo.BaseMutationOptions<
  CreateUploadMutation,
  CreateUploadMutationVariables
>;
export const CreateChargerModelDocument = gql`
  mutation createChargerModel($modelInput: CreateChargerModelInput) {
    createChargerModel(input: $modelInput) {
      ...FullChargerModel
    }
  }
  ${FullChargerModelFragmentDoc}
`;
export type CreateChargerModelMutationFn = Apollo.MutationFunction<
  CreateChargerModelMutation,
  CreateChargerModelMutationVariables
>;

/**
 * __useCreateChargerModelMutation__
 *
 * To run a mutation, you first call `useCreateChargerModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChargerModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChargerModelMutation, { data, loading, error }] = useCreateChargerModelMutation({
 *   variables: {
 *      modelInput: // value for 'modelInput'
 *   },
 * });
 */
export function useCreateChargerModelMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChargerModelMutation, CreateChargerModelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChargerModelMutation, CreateChargerModelMutationVariables>(
    CreateChargerModelDocument,
    options,
  );
}
export type CreateChargerModelMutationHookResult = ReturnType<typeof useCreateChargerModelMutation>;
export type CreateChargerModelMutationResult = Apollo.MutationResult<CreateChargerModelMutation>;
export type CreateChargerModelMutationOptions = Apollo.BaseMutationOptions<
  CreateChargerModelMutation,
  CreateChargerModelMutationVariables
>;
export const GetChargerModelDocument = gql`
  query getChargerModel($chargerModelInput: GetChargerModelInput) {
    getChargerModel(input: $chargerModelInput) {
      ...FullChargerModel
    }
  }
  ${FullChargerModelFragmentDoc}
`;

/**
 * __useGetChargerModelQuery__
 *
 * To run a query within a React component, call `useGetChargerModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargerModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargerModelQuery({
 *   variables: {
 *      chargerModelInput: // value for 'chargerModelInput'
 *   },
 * });
 */
export function useGetChargerModelQuery(
  baseOptions?: Apollo.QueryHookOptions<GetChargerModelQuery, GetChargerModelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChargerModelQuery, GetChargerModelQueryVariables>(GetChargerModelDocument, options);
}
export function useGetChargerModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargerModelQuery, GetChargerModelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChargerModelQuery, GetChargerModelQueryVariables>(GetChargerModelDocument, options);
}
export type GetChargerModelQueryHookResult = ReturnType<typeof useGetChargerModelQuery>;
export type GetChargerModelLazyQueryHookResult = ReturnType<typeof useGetChargerModelLazyQuery>;
export type GetChargerModelQueryResult = Apollo.QueryResult<GetChargerModelQuery, GetChargerModelQueryVariables>;
export const GetModelOptionsDocument = gql`
  query getModelOptions {
    manufacturers: __type(name: "Manufacturer") {
      enumValues {
        value: name
        label: description
      }
    }
    unitTypes: __type(name: "UnitType") {
      enumValues {
        value: name
        label: description
      }
    }
    cableManufacturers: __type(name: "CableManufacturer") {
      enumValues {
        value: name
        label: description
      }
    }
    connectorTypes: __type(name: "ConnectorType") {
      enumValues {
        value: name
        label: description
      }
    }
    inputAcVoltage: __type(name: "InputAcVoltage") {
      enumValues {
        value: name
        label: description
      }
    }
    cableLength: __type(name: "CableLength") {
      enumValues {
        value: name
        label: description
      }
    }
    cableCooling: __type(name: "CableCooling") {
      enumValues {
        value: name
        label: description
      }
    }
    interfaceVersion: __type(name: "InterfaceVersion") {
      enumValues {
        value: name
        label: description
      }
    }
    networkCommunication: __type(name: "NetworkCommunication") {
      enumValues {
        value: name
        label: description
      }
    }
  }
`;

/**
 * __useGetModelOptionsQuery__
 *
 * To run a query within a React component, call `useGetModelOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetModelOptionsQuery, GetModelOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetModelOptionsQuery, GetModelOptionsQueryVariables>(GetModelOptionsDocument, options);
}
export function useGetModelOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetModelOptionsQuery, GetModelOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetModelOptionsQuery, GetModelOptionsQueryVariables>(GetModelOptionsDocument, options);
}
export type GetModelOptionsQueryHookResult = ReturnType<typeof useGetModelOptionsQuery>;
export type GetModelOptionsLazyQueryHookResult = ReturnType<typeof useGetModelOptionsLazyQuery>;
export type GetModelOptionsQueryResult = Apollo.QueryResult<GetModelOptionsQuery, GetModelOptionsQueryVariables>;
export const ListChargerModelOptionsDocument = gql`
  query listChargerModelOptions {
    listChargerModelOptions {
      altId
      modelName
    }
  }
`;

/**
 * __useListChargerModelOptionsQuery__
 *
 * To run a query within a React component, call `useListChargerModelOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerModelOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerModelOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListChargerModelOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListChargerModelOptionsQuery, ListChargerModelOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChargerModelOptionsQuery, ListChargerModelOptionsQueryVariables>(
    ListChargerModelOptionsDocument,
    options,
  );
}
export function useListChargerModelOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListChargerModelOptionsQuery, ListChargerModelOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChargerModelOptionsQuery, ListChargerModelOptionsQueryVariables>(
    ListChargerModelOptionsDocument,
    options,
  );
}
export type ListChargerModelOptionsQueryHookResult = ReturnType<typeof useListChargerModelOptionsQuery>;
export type ListChargerModelOptionsLazyQueryHookResult = ReturnType<typeof useListChargerModelOptionsLazyQuery>;
export type ListChargerModelOptionsQueryResult = Apollo.QueryResult<
  ListChargerModelOptionsQuery,
  ListChargerModelOptionsQueryVariables
>;
export const ListChargerModelsDocument = gql`
  query listChargerModels($input: ListChargerModelsInput) {
    listChargerModels(input: $input) {
      edges {
        altId
        breakerRating
        currentThd
        efficiency
        inputAcVoltage
        manufacturer
        modelName
        powerFactor
        powerOutput
        simultaneousChargeSupported
        unitType
        evses {
          edges {
            connectors {
              edges {
                altId
              }
            }
          }
        }
      }
      page
      pageSize
      total
      sort {
        modelName
        manufacturer
        unitType
      }
      filter {
        manufacturer {
          in
        }
      }
      search {
        modelName {
          iLike
        }
      }
    }
  }
`;

/**
 * __useListChargerModelsQuery__
 *
 * To run a query within a React component, call `useListChargerModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChargerModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChargerModelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListChargerModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListChargerModelsQuery, ListChargerModelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListChargerModelsQuery, ListChargerModelsQueryVariables>(ListChargerModelsDocument, options);
}
export function useListChargerModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListChargerModelsQuery, ListChargerModelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListChargerModelsQuery, ListChargerModelsQueryVariables>(
    ListChargerModelsDocument,
    options,
  );
}
export type ListChargerModelsQueryHookResult = ReturnType<typeof useListChargerModelsQuery>;
export type ListChargerModelsLazyQueryHookResult = ReturnType<typeof useListChargerModelsLazyQuery>;
export type ListChargerModelsQueryResult = Apollo.QueryResult<ListChargerModelsQuery, ListChargerModelsQueryVariables>;
export const ListVehicleConnectorsDocument = gql`
  query listVehicleConnectors($input: ListVehicleConnectorsInput) {
    listVehicleConnectors(input: $input) {
      edges {
        id
        connectorName: name
      }
    }
  }
`;

/**
 * __useListVehicleConnectorsQuery__
 *
 * To run a query within a React component, call `useListVehicleConnectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVehicleConnectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVehicleConnectorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListVehicleConnectorsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListVehicleConnectorsQuery, ListVehicleConnectorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListVehicleConnectorsQuery, ListVehicleConnectorsQueryVariables>(
    ListVehicleConnectorsDocument,
    options,
  );
}
export function useListVehicleConnectorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListVehicleConnectorsQuery, ListVehicleConnectorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListVehicleConnectorsQuery, ListVehicleConnectorsQueryVariables>(
    ListVehicleConnectorsDocument,
    options,
  );
}
export type ListVehicleConnectorsQueryHookResult = ReturnType<typeof useListVehicleConnectorsQuery>;
export type ListVehicleConnectorsLazyQueryHookResult = ReturnType<typeof useListVehicleConnectorsLazyQuery>;
export type ListVehicleConnectorsQueryResult = Apollo.QueryResult<
  ListVehicleConnectorsQuery,
  ListVehicleConnectorsQueryVariables
>;
export const UpdateChargerModelDocument = gql`
  mutation UpdateChargerModel($modelInput: UpdateChargerModelInput) {
    updateChargerModel(input: $modelInput) {
      ...FullChargerModel
    }
  }
  ${FullChargerModelFragmentDoc}
`;
export type UpdateChargerModelMutationFn = Apollo.MutationFunction<
  UpdateChargerModelMutation,
  UpdateChargerModelMutationVariables
>;

/**
 * __useUpdateChargerModelMutation__
 *
 * To run a mutation, you first call `useUpdateChargerModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargerModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargerModelMutation, { data, loading, error }] = useUpdateChargerModelMutation({
 *   variables: {
 *      modelInput: // value for 'modelInput'
 *   },
 * });
 */
export function useUpdateChargerModelMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateChargerModelMutation, UpdateChargerModelMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateChargerModelMutation, UpdateChargerModelMutationVariables>(
    UpdateChargerModelDocument,
    options,
  );
}
export type UpdateChargerModelMutationHookResult = ReturnType<typeof useUpdateChargerModelMutation>;
export type UpdateChargerModelMutationResult = Apollo.MutationResult<UpdateChargerModelMutation>;
export type UpdateChargerModelMutationOptions = Apollo.BaseMutationOptions<
  UpdateChargerModelMutation,
  UpdateChargerModelMutationVariables
>;
export const ListNotificationGroupsDocument = gql`
  query listNotificationGroups(
    $notificationGroupsInput: ListNotificationGroupsInput
    $notificationItemsInput: ListNotificationItemsInput
  ) {
    listNotificationGroups(input: $notificationGroupsInput) {
      edges {
        groupName
        sortOrder
        notificationItems(input: $notificationItemsInput) {
          edges {
            id
            notificationName
            notificationDesc
            onePrefRequired
          }
        }
      }
    }
  }
`;

/**
 * __useListNotificationGroupsQuery__
 *
 * To run a query within a React component, call `useListNotificationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNotificationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNotificationGroupsQuery({
 *   variables: {
 *      notificationGroupsInput: // value for 'notificationGroupsInput'
 *      notificationItemsInput: // value for 'notificationItemsInput'
 *   },
 * });
 */
export function useListNotificationGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListNotificationGroupsQuery, ListNotificationGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListNotificationGroupsQuery, ListNotificationGroupsQueryVariables>(
    ListNotificationGroupsDocument,
    options,
  );
}
export function useListNotificationGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListNotificationGroupsQuery, ListNotificationGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListNotificationGroupsQuery, ListNotificationGroupsQueryVariables>(
    ListNotificationGroupsDocument,
    options,
  );
}
export type ListNotificationGroupsQueryHookResult = ReturnType<typeof useListNotificationGroupsQuery>;
export type ListNotificationGroupsLazyQueryHookResult = ReturnType<typeof useListNotificationGroupsLazyQuery>;
export type ListNotificationGroupsQueryResult = Apollo.QueryResult<
  ListNotificationGroupsQuery,
  ListNotificationGroupsQueryVariables
>;
export const ListFalconConstraintsDocument = gql`
  query listFalconConstraints($optionsInput: ListFalconConstraintsInput) {
    listFalconConstraints(input: $optionsInput) {
      edges {
        id
        columnName
        columnText
        columnValue
      }
    }
  }
`;

/**
 * __useListFalconConstraintsQuery__
 *
 * To run a query within a React component, call `useListFalconConstraintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFalconConstraintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFalconConstraintsQuery({
 *   variables: {
 *      optionsInput: // value for 'optionsInput'
 *   },
 * });
 */
export function useListFalconConstraintsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListFalconConstraintsQuery, ListFalconConstraintsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListFalconConstraintsQuery, ListFalconConstraintsQueryVariables>(
    ListFalconConstraintsDocument,
    options,
  );
}
export function useListFalconConstraintsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListFalconConstraintsQuery, ListFalconConstraintsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListFalconConstraintsQuery, ListFalconConstraintsQueryVariables>(
    ListFalconConstraintsDocument,
    options,
  );
}
export type ListFalconConstraintsQueryHookResult = ReturnType<typeof useListFalconConstraintsQuery>;
export type ListFalconConstraintsLazyQueryHookResult = ReturnType<typeof useListFalconConstraintsLazyQuery>;
export type ListFalconConstraintsQueryResult = Apollo.QueryResult<
  ListFalconConstraintsQuery,
  ListFalconConstraintsQueryVariables
>;
export const ListOptionsDocument = gql`
  query listOptions($optionsInput: String!) {
    listOptions: __type(name: $optionsInput) {
      edges: enumValues {
        label: description
        value: name
      }
    }
  }
`;

/**
 * __useListOptionsQuery__
 *
 * To run a query within a React component, call `useListOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOptionsQuery({
 *   variables: {
 *      optionsInput: // value for 'optionsInput'
 *   },
 * });
 */
export function useListOptionsQuery(baseOptions: Apollo.QueryHookOptions<ListOptionsQuery, ListOptionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListOptionsQuery, ListOptionsQueryVariables>(ListOptionsDocument, options);
}
export function useListOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListOptionsQuery, ListOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListOptionsQuery, ListOptionsQueryVariables>(ListOptionsDocument, options);
}
export type ListOptionsQueryHookResult = ReturnType<typeof useListOptionsQuery>;
export type ListOptionsLazyQueryHookResult = ReturnType<typeof useListOptionsLazyQuery>;
export type ListOptionsQueryResult = Apollo.QueryResult<ListOptionsQuery, ListOptionsQueryVariables>;
export const ListPlanOptionsDocument = gql`
  query listPlanOptions {
    listCardBrandOptions: __type(name: "CardBrand") {
      edges: enumValues {
        label: description
        value: name
      }
    }
    listIntervalUnitOptions: __type(name: "IntervalUnit") {
      edges: enumValues {
        label: description
        value: name
      }
    }
    listRolloverPlanOptions: listPlans(input: { pageSize: 9999 }) {
      edges {
        label: planName
        value: altId
      }
    }
    listPlanStatusOptions: __type(name: "PlanStatus") {
      edges: enumValues {
        label: description
        value: name
      }
    }
    listTerminationBehaviorOptions: __type(name: "TerminationBehavior") {
      edges: enumValues {
        label: description
        value: name
      }
    }
  }
`;

/**
 * __useListPlanOptionsQuery__
 *
 * To run a query within a React component, call `useListPlanOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlanOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlanOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPlanOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListPlanOptionsQuery, ListPlanOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPlanOptionsQuery, ListPlanOptionsQueryVariables>(ListPlanOptionsDocument, options);
}
export function useListPlanOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPlanOptionsQuery, ListPlanOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPlanOptionsQuery, ListPlanOptionsQueryVariables>(ListPlanOptionsDocument, options);
}
export type ListPlanOptionsQueryHookResult = ReturnType<typeof useListPlanOptionsQuery>;
export type ListPlanOptionsLazyQueryHookResult = ReturnType<typeof useListPlanOptionsLazyQuery>;
export type ListPlanOptionsQueryResult = Apollo.QueryResult<ListPlanOptionsQuery, ListPlanOptionsQueryVariables>;
export const ListTariffOptionsDocument = gql`
  query listTariffOptions {
    listConnectorOptions: listVehicleConnectors(input: { pageSize: 9999 }) {
      edges {
        label: name
        value: id
      }
    }
    listRateTypeOptions: __type(name: "TariffRateType") {
      edges: enumValues {
        label: description
        value: name
      }
    }
  }
`;

/**
 * __useListTariffOptionsQuery__
 *
 * To run a query within a React component, call `useListTariffOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTariffOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTariffOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListTariffOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTariffOptionsQuery, ListTariffOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTariffOptionsQuery, ListTariffOptionsQueryVariables>(ListTariffOptionsDocument, options);
}
export function useListTariffOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTariffOptionsQuery, ListTariffOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTariffOptionsQuery, ListTariffOptionsQueryVariables>(
    ListTariffOptionsDocument,
    options,
  );
}
export type ListTariffOptionsQueryHookResult = ReturnType<typeof useListTariffOptionsQuery>;
export type ListTariffOptionsLazyQueryHookResult = ReturnType<typeof useListTariffOptionsLazyQuery>;
export type ListTariffOptionsQueryResult = Apollo.QueryResult<ListTariffOptionsQuery, ListTariffOptionsQueryVariables>;
export const CreatePlanDocument = gql`
  mutation createPlan($planInput: CreatePlanInput!) {
    createPlan(input: $planInput) {
      ...FullPlan
    }
  }
  ${FullPlanFragmentDoc}
`;
export type CreatePlanMutationFn = Apollo.MutationFunction<CreatePlanMutation, CreatePlanMutationVariables>;

/**
 * __useCreatePlanMutation__
 *
 * To run a mutation, you first call `useCreatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlanMutation, { data, loading, error }] = useCreatePlanMutation({
 *   variables: {
 *      planInput: // value for 'planInput'
 *   },
 * });
 */
export function useCreatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePlanMutation, CreatePlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePlanMutation, CreatePlanMutationVariables>(CreatePlanDocument, options);
}
export type CreatePlanMutationHookResult = ReturnType<typeof useCreatePlanMutation>;
export type CreatePlanMutationResult = Apollo.MutationResult<CreatePlanMutation>;
export type CreatePlanMutationOptions = Apollo.BaseMutationOptions<CreatePlanMutation, CreatePlanMutationVariables>;
export const GetPlanDocument = gql`
  query getPlan($planInput: GetPlanInput) {
    getPlan(input: $planInput) {
      ...FullPlan
    }
  }
  ${FullPlanFragmentDoc}
`;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      planInput: // value for 'planInput'
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions?: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
}
export function useGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
}
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<GetPlanQuery, GetPlanQueryVariables>;
export const ListPlanDetailsDocument = gql`
  query listPlanDetails($plansInput: ListPlansInput) {
    listPlans(input: $plansInput) {
      total
      page
      pageSize
      edges {
        altId
        products(input: { pageSize: 9999 }) {
          edges {
            altId
            credit {
              creditAmount
              creditReoccurrence
            }
            subscription {
              subscriptionFee
              subscriptionReoccurrence
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListPlanDetailsQuery__
 *
 * To run a query within a React component, call `useListPlanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlanDetailsQuery({
 *   variables: {
 *      plansInput: // value for 'plansInput'
 *   },
 * });
 */
export function useListPlanDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListPlanDetailsQuery, ListPlanDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPlanDetailsQuery, ListPlanDetailsQueryVariables>(ListPlanDetailsDocument, options);
}
export function useListPlanDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPlanDetailsQuery, ListPlanDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPlanDetailsQuery, ListPlanDetailsQueryVariables>(ListPlanDetailsDocument, options);
}
export type ListPlanDetailsQueryHookResult = ReturnType<typeof useListPlanDetailsQuery>;
export type ListPlanDetailsLazyQueryHookResult = ReturnType<typeof useListPlanDetailsLazyQuery>;
export type ListPlanDetailsQueryResult = Apollo.QueryResult<ListPlanDetailsQuery, ListPlanDetailsQueryVariables>;
export const ListPlanNamesDocument = gql`
  query listPlanNames($plansInput: ListPlansInput) {
    listPlans(input: $plansInput) {
      edges {
        altId
        planName
      }
    }
  }
`;

/**
 * __useListPlanNamesQuery__
 *
 * To run a query within a React component, call `useListPlanNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlanNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlanNamesQuery({
 *   variables: {
 *      plansInput: // value for 'plansInput'
 *   },
 * });
 */
export function useListPlanNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListPlanNamesQuery, ListPlanNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPlanNamesQuery, ListPlanNamesQueryVariables>(ListPlanNamesDocument, options);
}
export function useListPlanNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPlanNamesQuery, ListPlanNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPlanNamesQuery, ListPlanNamesQueryVariables>(ListPlanNamesDocument, options);
}
export type ListPlanNamesQueryHookResult = ReturnType<typeof useListPlanNamesQuery>;
export type ListPlanNamesLazyQueryHookResult = ReturnType<typeof useListPlanNamesLazyQuery>;
export type ListPlanNamesQueryResult = Apollo.QueryResult<ListPlanNamesQuery, ListPlanNamesQueryVariables>;
export const ListPlansDocument = gql`
  query listPlans($input: ListPlansInput) {
    listPlans(input: $input) {
      total
      page
      pageSize
      sort {
        planName
        planDisplayName
        createdAt
      }
      search {
        planName {
          iLike
        }
      }
      edges {
        altId
        createdAt
        planName
        planCode
        planStatus
        planDuration
        planDescription
        planDisplayName
        driverRank
      }
    }
  }
`;

/**
 * __useListPlansQuery__
 *
 * To run a query within a React component, call `useListPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlansQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListPlansQuery(baseOptions?: Apollo.QueryHookOptions<ListPlansQuery, ListPlansQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPlansQuery, ListPlansQueryVariables>(ListPlansDocument, options);
}
export function useListPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPlansQuery, ListPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPlansQuery, ListPlansQueryVariables>(ListPlansDocument, options);
}
export type ListPlansQueryHookResult = ReturnType<typeof useListPlansQuery>;
export type ListPlansLazyQueryHookResult = ReturnType<typeof useListPlansLazyQuery>;
export type ListPlansQueryResult = Apollo.QueryResult<ListPlansQuery, ListPlansQueryVariables>;
export const ListPlansForContractsDocument = gql`
  query listPlansForContracts($plansInput: ListPlansInput) {
    listPlans(input: $plansInput) {
      total
      page
      pageSize
      edges {
        altId
        createdAt
        enrollmentClose
        enrollmentOpen
        planName
        planStatus
        intervalUnit
        intervalLength
        planDuration
        terminationBehavior
      }
    }
  }
`;

/**
 * __useListPlansForContractsQuery__
 *
 * To run a query within a React component, call `useListPlansForContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlansForContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlansForContractsQuery({
 *   variables: {
 *      plansInput: // value for 'plansInput'
 *   },
 * });
 */
export function useListPlansForContractsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListPlansForContractsQuery, ListPlansForContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPlansForContractsQuery, ListPlansForContractsQueryVariables>(
    ListPlansForContractsDocument,
    options,
  );
}
export function useListPlansForContractsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPlansForContractsQuery, ListPlansForContractsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPlansForContractsQuery, ListPlansForContractsQueryVariables>(
    ListPlansForContractsDocument,
    options,
  );
}
export type ListPlansForContractsQueryHookResult = ReturnType<typeof useListPlansForContractsQuery>;
export type ListPlansForContractsLazyQueryHookResult = ReturnType<typeof useListPlansForContractsLazyQuery>;
export type ListPlansForContractsQueryResult = Apollo.QueryResult<
  ListPlansForContractsQuery,
  ListPlansForContractsQueryVariables
>;
export const UpdatePlanDocument = gql`
  mutation updatePlan($planInput: UpdatePlanInput!) {
    updatePlan(input: $planInput) {
      ...FullPlan
    }
  }
  ${FullPlanFragmentDoc}
`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      planInput: // value for 'planInput'
 *   },
 * });
 */
export function useUpdatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
}
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const UpdatePlanDriverRankDocument = gql`
  mutation UpdatePlanDriverRank($input: [DriverRankInput!]!) {
    updatePlanDriverRank(input: $input) {
      success
      message
    }
  }
`;
export type UpdatePlanDriverRankMutationFn = Apollo.MutationFunction<
  UpdatePlanDriverRankMutation,
  UpdatePlanDriverRankMutationVariables
>;

/**
 * __useUpdatePlanDriverRankMutation__
 *
 * To run a mutation, you first call `useUpdatePlanDriverRankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanDriverRankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanDriverRankMutation, { data, loading, error }] = useUpdatePlanDriverRankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanDriverRankMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePlanDriverRankMutation, UpdatePlanDriverRankMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlanDriverRankMutation, UpdatePlanDriverRankMutationVariables>(
    UpdatePlanDriverRankDocument,
    options,
  );
}
export type UpdatePlanDriverRankMutationHookResult = ReturnType<typeof useUpdatePlanDriverRankMutation>;
export type UpdatePlanDriverRankMutationResult = Apollo.MutationResult<UpdatePlanDriverRankMutation>;
export type UpdatePlanDriverRankMutationOptions = Apollo.BaseMutationOptions<
  UpdatePlanDriverRankMutation,
  UpdatePlanDriverRankMutationVariables
>;
export const ImportPropertyDocument = gql`
  mutation importProperty($propertyInput: ImportPropertyInput) {
    importProperty(input: $propertyInput) {
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
      status
      host {
        altId
      }
      altId
      pid
      postalCode
      propertyName
    }
  }
`;
export type ImportPropertyMutationFn = Apollo.MutationFunction<ImportPropertyMutation, ImportPropertyMutationVariables>;

/**
 * __useImportPropertyMutation__
 *
 * To run a mutation, you first call `useImportPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPropertyMutation, { data, loading, error }] = useImportPropertyMutation({
 *   variables: {
 *      propertyInput: // value for 'propertyInput'
 *   },
 * });
 */
export function useImportPropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<ImportPropertyMutation, ImportPropertyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportPropertyMutation, ImportPropertyMutationVariables>(ImportPropertyDocument, options);
}
export type ImportPropertyMutationHookResult = ReturnType<typeof useImportPropertyMutation>;
export type ImportPropertyMutationResult = Apollo.MutationResult<ImportPropertyMutation>;
export type ImportPropertyMutationOptions = Apollo.BaseMutationOptions<
  ImportPropertyMutation,
  ImportPropertyMutationVariables
>;
export const ListPropertiesDocument = gql`
  query listProperties($propertiesInput: ListPropertiesInput, $sitesInput: ListSitesInput) {
    listProperties(input: $propertiesInput) {
      edges {
        address1
        address2
        locality
        administrativeArea
        postalCode
        country
        status
        host {
          altId
        }
        altId
        pid
        postalCode
        propertyName
        sites(input: $sitesInput) {
          edges {
            chargers {
              total
            }
          }
          total
        }
      }
      page
      pageSize
      total
      sort {
        altId
        pid
        propertyName
        hostName
      }
      filter {
        status {
          in
        }
      }
      search {
        pid {
          iLike
        }
        propertyName {
          iLike
        }
        address1 {
          iLike
        }
        locality {
          iLike
        }
        administrativeArea {
          iLike
        }
        postalCode {
          iLike
        }
      }
    }
  }
`;

/**
 * __useListPropertiesQuery__
 *
 * To run a query within a React component, call `useListPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPropertiesQuery({
 *   variables: {
 *      propertiesInput: // value for 'propertiesInput'
 *      sitesInput: // value for 'sitesInput'
 *   },
 * });
 */
export function useListPropertiesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListPropertiesQuery, ListPropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListPropertiesQuery, ListPropertiesQueryVariables>(ListPropertiesDocument, options);
}
export function useListPropertiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListPropertiesQuery, ListPropertiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListPropertiesQuery, ListPropertiesQueryVariables>(ListPropertiesDocument, options);
}
export type ListPropertiesQueryHookResult = ReturnType<typeof useListPropertiesQuery>;
export type ListPropertiesLazyQueryHookResult = ReturnType<typeof useListPropertiesLazyQuery>;
export type ListPropertiesQueryResult = Apollo.QueryResult<ListPropertiesQuery, ListPropertiesQueryVariables>;
export const UpdatePropertyDocument = gql`
  mutation updateProperty($propertyInput: UpdatePropertyInput) {
    updateProperty(input: $propertyInput) {
      altId
      status
    }
  }
`;
export type UpdatePropertyMutationFn = Apollo.MutationFunction<UpdatePropertyMutation, UpdatePropertyMutationVariables>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      propertyInput: // value for 'propertyInput'
 *   },
 * });
 */
export function useUpdatePropertyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePropertyMutation, UpdatePropertyMutationVariables>(UpdatePropertyDocument, options);
}
export type UpdatePropertyMutationHookResult = ReturnType<typeof useUpdatePropertyMutation>;
export type UpdatePropertyMutationResult = Apollo.MutationResult<UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;
export const GetSessionDocument = gql`
  query getSession($sessionInput: GetSessionInput!) {
    getSession(input: $sessionInput) {
      altId
    }
  }
`;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *      sessionInput: // value for 'sessionInput'
 *   },
 * });
 */
export function useGetSessionQuery(baseOptions: Apollo.QueryHookOptions<GetSessionQuery, GetSessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
}
export function useGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSessionQuery, GetSessionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
}
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<typeof useGetSessionLazyQuery>;
export type GetSessionQueryResult = Apollo.QueryResult<GetSessionQuery, GetSessionQueryVariables>;
export const ListSessionsV2Document = gql`
  query ListSessionsV2($input: ListSessionsV2Input!) {
    listSessionsV2(input: $input) {
      total
      edges {
        altId
        vendorId
        startSource {
          columnValue
        }
        startTime
        endTime
        sessionDuration
        energyDelivered
        plan {
          altId
          planName
        }
        transaction {
          altId
          vendorId
          amount
          driver {
            altId
            firstName
            lastName
          }
        }
        meterStart
        meterEnd
        lastMeterValue
        connector {
          evse {
            charger {
              chargerName
            }
          }
        }
        cost
        corrupted
        corruptedReasons
        card {
          internalNumber
          externalNumber
          evcId
        }
        stopSource {
          columnValue
        }
      }
    }
  }
`;

/**
 * __useListSessionsV2Query__
 *
 * To run a query within a React component, call `useListSessionsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useListSessionsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSessionsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListSessionsV2Query(
  baseOptions: Apollo.QueryHookOptions<ListSessionsV2Query, ListSessionsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSessionsV2Query, ListSessionsV2QueryVariables>(ListSessionsV2Document, options);
}
export function useListSessionsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSessionsV2Query, ListSessionsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSessionsV2Query, ListSessionsV2QueryVariables>(ListSessionsV2Document, options);
}
export type ListSessionsV2QueryHookResult = ReturnType<typeof useListSessionsV2Query>;
export type ListSessionsV2LazyQueryHookResult = ReturnType<typeof useListSessionsV2LazyQuery>;
export type ListSessionsV2QueryResult = Apollo.QueryResult<ListSessionsV2Query, ListSessionsV2QueryVariables>;
export const CreateSiteDocument = gql`
  mutation createSite($siteInput: CreateSiteInput) {
    createSite(input: $siteInput) {
      altId
    }
  }
`;
export type CreateSiteMutationFn = Apollo.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useCreateSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
}
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const GetSiteDocument = gql`
  query getSite($siteInput: GetSiteInput) {
    getSite(input: $siteInput) {
      ...FullSite
    }
  }
  ${FullSiteFragmentDoc}
`;

/**
 * __useGetSiteQuery__
 *
 * To run a query within a React component, call `useGetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteQuery({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useGetSiteQuery(baseOptions?: Apollo.QueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, options);
}
export function useGetSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, options);
}
export type GetSiteQueryHookResult = ReturnType<typeof useGetSiteQuery>;
export type GetSiteLazyQueryHookResult = ReturnType<typeof useGetSiteLazyQuery>;
export type GetSiteQueryResult = Apollo.QueryResult<GetSiteQuery, GetSiteQueryVariables>;
export const GetSitesTotalDocument = gql`
  query getSitesTotal {
    listSites {
      total
    }
  }
`;

/**
 * __useGetSitesTotalQuery__
 *
 * To run a query within a React component, call `useGetSitesTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesTotalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSitesTotalQuery, GetSitesTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSitesTotalQuery, GetSitesTotalQueryVariables>(GetSitesTotalDocument, options);
}
export function useGetSitesTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSitesTotalQuery, GetSitesTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSitesTotalQuery, GetSitesTotalQueryVariables>(GetSitesTotalDocument, options);
}
export type GetSitesTotalQueryHookResult = ReturnType<typeof useGetSitesTotalQuery>;
export type GetSitesTotalLazyQueryHookResult = ReturnType<typeof useGetSitesTotalLazyQuery>;
export type GetSitesTotalQueryResult = Apollo.QueryResult<GetSitesTotalQuery, GetSitesTotalQueryVariables>;
export const ListMappedSitesDocument = gql`
  query listMappedSites($sitesInput: ListSitesInput) {
    listSites(input: $sitesInput) {
      edges {
        address1
        address2
        address3
        address4
        locality
        administrativeArea
        postalCode
        country
        chargers {
          edges {
            altId
            chargerName
            cid
            fieldStationStatus
            chargerModel {
              altId
              modelName
            }
          }
          total
          search {
            cid {
              iLike
            }
            chargerName {
              iLike
            }
            site_address1 {
              iLike
            }
            site_locality {
              iLike
            }
            site_administrativeArea {
              iLike
            }
            site_postalCode {
              iLike
            }
            site_property_propertyName {
              iLike
            }
          }
          sort {
            cid
            chargerName
            fieldStationStatus
            propertyName
            chargerModel_modelName
          }
          filter {
            chargerModel_altId {
              in
            }
            fieldStationStatus {
              in
            }
            tags {
              or {
                contains
              }
            }
          }
        }
        altId
        latitude
        longitude
        siteName
        property {
          altId
          host {
            altId
          }
        }
        sid
        siteStatusId
      }
      page
      pageSize
      sort {
        sid
        siteName
        hostName
      }
      filter {
        siteStatusId {
          in
        }
      }
      search {
        siteName {
          iLike
        }
        sid {
          iLike
        }
        property_host_hostName {
          iLike
        }
        address1 {
          iLike
        }
        locality {
          iLike
        }
        administrativeArea {
          iLike
        }
        postalCode {
          iLike
        }
      }
      total
    }
  }
`;

/**
 * __useListMappedSitesQuery__
 *
 * To run a query within a React component, call `useListMappedSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMappedSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMappedSitesQuery({
 *   variables: {
 *      sitesInput: // value for 'sitesInput'
 *   },
 * });
 */
export function useListMappedSitesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListMappedSitesQuery, ListMappedSitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMappedSitesQuery, ListMappedSitesQueryVariables>(ListMappedSitesDocument, options);
}
export function useListMappedSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMappedSitesQuery, ListMappedSitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMappedSitesQuery, ListMappedSitesQueryVariables>(ListMappedSitesDocument, options);
}
export type ListMappedSitesQueryHookResult = ReturnType<typeof useListMappedSitesQuery>;
export type ListMappedSitesLazyQueryHookResult = ReturnType<typeof useListMappedSitesLazyQuery>;
export type ListMappedSitesQueryResult = Apollo.QueryResult<ListMappedSitesQuery, ListMappedSitesQueryVariables>;
export const ListSiteOptionsDocument = gql`
  query listSiteOptions {
    listSiteOptions {
      altId
      siteName
    }
  }
`;

/**
 * __useListSiteOptionsQuery__
 *
 * To run a query within a React component, call `useListSiteOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSiteOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSiteOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSiteOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListSiteOptionsQuery, ListSiteOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSiteOptionsQuery, ListSiteOptionsQueryVariables>(ListSiteOptionsDocument, options);
}
export function useListSiteOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSiteOptionsQuery, ListSiteOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSiteOptionsQuery, ListSiteOptionsQueryVariables>(ListSiteOptionsDocument, options);
}
export type ListSiteOptionsQueryHookResult = ReturnType<typeof useListSiteOptionsQuery>;
export type ListSiteOptionsLazyQueryHookResult = ReturnType<typeof useListSiteOptionsLazyQuery>;
export type ListSiteOptionsQueryResult = Apollo.QueryResult<ListSiteOptionsQuery, ListSiteOptionsQueryVariables>;
export const ListSitesDocument = gql`
  query listSites($sitesInput: ListSitesInput) {
    listSites(input: $sitesInput) {
      edges {
        address1
        address2
        address3
        address4
        locality
        administrativeArea
        postalCode
        country
        chargers {
          total
        }
        altId
        latitude
        longitude
        siteName
        property {
          altId
          host {
            altId
            hostName
          }
        }
        sid
        siteStatusId
      }
      page
      pageSize
      sort {
        sid
        siteName
        hostName
        siteStatusId
      }
      filter {
        siteStatusId {
          in
        }
      }
      search {
        siteName {
          iLike
        }
        sid {
          iLike
        }
        property_host_hostName {
          iLike
        }
        address1 {
          iLike
        }
        locality {
          iLike
        }
        administrativeArea {
          iLike
        }
        postalCode {
          iLike
        }
      }
      total
    }
  }
`;

/**
 * __useListSitesQuery__
 *
 * To run a query within a React component, call `useListSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSitesQuery({
 *   variables: {
 *      sitesInput: // value for 'sitesInput'
 *   },
 * });
 */
export function useListSitesQuery(baseOptions?: Apollo.QueryHookOptions<ListSitesQuery, ListSitesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSitesQuery, ListSitesQueryVariables>(ListSitesDocument, options);
}
export function useListSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSitesQuery, ListSitesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSitesQuery, ListSitesQueryVariables>(ListSitesDocument, options);
}
export type ListSitesQueryHookResult = ReturnType<typeof useListSitesQuery>;
export type ListSitesLazyQueryHookResult = ReturnType<typeof useListSitesLazyQuery>;
export type ListSitesQueryResult = Apollo.QueryResult<ListSitesQuery, ListSitesQueryVariables>;
export const ListSitesForChargerDocument = gql`
  query listSitesForCharger($sitesInput: ListSitesInput) {
    listSites(input: $sitesInput) {
      edges {
        address1
        address2
        address3
        address4
        administrativeArea
        altId
        country
        hoursOfOperation {
          custom
          from
          to
          monday {
            closed
            timeslots {
              from
              to
            }
          }
          tuesday {
            closed
            timeslots {
              from
              to
            }
          }
          wednesday {
            closed
            timeslots {
              from
              to
            }
          }
          thursday {
            closed
            timeslots {
              from
              to
            }
          }
          friday {
            closed
            timeslots {
              from
              to
            }
          }
          saturday {
            closed
            timeslots {
              from
              to
            }
          }
          sunday {
            closed
            timeslots {
              from
              to
            }
          }
        }
        latitude
        locality
        longitude
        postalCode
        property {
          altId
          host {
            altId
            hostName
          }
        }
        sid
        siteName
        siteStatusId
      }
    }
  }
`;

/**
 * __useListSitesForChargerQuery__
 *
 * To run a query within a React component, call `useListSitesForChargerQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSitesForChargerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSitesForChargerQuery({
 *   variables: {
 *      sitesInput: // value for 'sitesInput'
 *   },
 * });
 */
export function useListSitesForChargerQuery(
  baseOptions?: Apollo.QueryHookOptions<ListSitesForChargerQuery, ListSitesForChargerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSitesForChargerQuery, ListSitesForChargerQueryVariables>(
    ListSitesForChargerDocument,
    options,
  );
}
export function useListSitesForChargerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListSitesForChargerQuery, ListSitesForChargerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListSitesForChargerQuery, ListSitesForChargerQueryVariables>(
    ListSitesForChargerDocument,
    options,
  );
}
export type ListSitesForChargerQueryHookResult = ReturnType<typeof useListSitesForChargerQuery>;
export type ListSitesForChargerLazyQueryHookResult = ReturnType<typeof useListSitesForChargerLazyQuery>;
export type ListSitesForChargerQueryResult = Apollo.QueryResult<
  ListSitesForChargerQuery,
  ListSitesForChargerQueryVariables
>;
export const ListWarehouseOptionsDocument = gql`
  query listWarehouseOptions {
    listWarehouseOptions {
      altId
      warehouseName
      address1
      address2
      address3
      address4
      locality
      administrativeArea
      postalCode
      country
    }
  }
`;

/**
 * __useListWarehouseOptionsQuery__
 *
 * To run a query within a React component, call `useListWarehouseOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWarehouseOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWarehouseOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWarehouseOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListWarehouseOptionsQuery, ListWarehouseOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListWarehouseOptionsQuery, ListWarehouseOptionsQueryVariables>(
    ListWarehouseOptionsDocument,
    options,
  );
}
export function useListWarehouseOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListWarehouseOptionsQuery, ListWarehouseOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListWarehouseOptionsQuery, ListWarehouseOptionsQueryVariables>(
    ListWarehouseOptionsDocument,
    options,
  );
}
export type ListWarehouseOptionsQueryHookResult = ReturnType<typeof useListWarehouseOptionsQuery>;
export type ListWarehouseOptionsLazyQueryHookResult = ReturnType<typeof useListWarehouseOptionsLazyQuery>;
export type ListWarehouseOptionsQueryResult = Apollo.QueryResult<
  ListWarehouseOptionsQuery,
  ListWarehouseOptionsQueryVariables
>;
export const UpdateSiteDocument = gql`
  mutation UpdateSite($siteInput: UpdateSiteInput) {
    updateSite(input: $siteInput) {
      ...FullSite
    }
  }
  ${FullSiteFragmentDoc}
`;
export type UpdateSiteMutationFn = Apollo.MutationFunction<UpdateSiteMutation, UpdateSiteMutationVariables>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useUpdateSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSiteMutation, UpdateSiteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument, options);
}
export type UpdateSiteMutationHookResult = ReturnType<typeof useUpdateSiteMutation>;
export type UpdateSiteMutationResult = Apollo.MutationResult<UpdateSiteMutation>;
export type UpdateSiteMutationOptions = Apollo.BaseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>;
export const CreateTagDocument = gql`
  mutation createTag($input: CreateTagInput) {
    createTag(input: $input) {
      altId
      tagName
      description
      filters {
        criteria
        exclude
        operator
        values {
          label
          valid
          value
        }
      }
      chargers {
        edges {
          chargerModel {
            altId
            modelName
          }
          cid
          fieldStationStatus
          chargerName
          site {
            altId
            address1
            address2
            address3
            address4
            locality
            administrativeArea
            postalCode
            country
            property {
              propertyName
            }
          }
        }
        page
        pageSize
        sort {
          cid
          chargerName
          fieldStationStatus
        }
        total
      }
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const DeleteTagDocument = gql`
  mutation deleteTag($deleteTagsInput: DeleteTagInput) {
    deleteTag(input: $deleteTagsInput) {
      altId
    }
  }
`;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      deleteTagsInput: // value for 'deleteTagsInput'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
}
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const GetTagDocument = gql`
  query getTag($input: GetTagInput, $chargersInput: ListChargersInput) {
    getTag(input: $input) {
      altId
      tagName
      description
      filters {
        criteria
        exclude
        operator
        values {
          label
          valid
          value
        }
      }
      chargers(input: $chargersInput) {
        edges {
          altId
          chargerModel {
            altId
            modelName
          }
          cid
          fieldStationStatus
          chargerName
          site {
            altId
            address1
            address2
            address3
            address4
            locality
            administrativeArea
            postalCode
            country
            property {
              propertyName
              host {
                hostName
              }
            }
          }
        }
        page
        pageSize
        sort {
          cid
          chargerName
          fieldStationStatus
          hostName
          propertyName
        }
        total
      }
    }
  }
`;

/**
 * __useGetTagQuery__
 *
 * To run a query within a React component, call `useGetTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagQuery({
 *   variables: {
 *      input: // value for 'input'
 *      chargersInput: // value for 'chargersInput'
 *   },
 * });
 */
export function useGetTagQuery(baseOptions?: Apollo.QueryHookOptions<GetTagQuery, GetTagQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, options);
}
export function useGetTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTagQuery, GetTagQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagQuery, GetTagQueryVariables>(GetTagDocument, options);
}
export type GetTagQueryHookResult = ReturnType<typeof useGetTagQuery>;
export type GetTagLazyQueryHookResult = ReturnType<typeof useGetTagLazyQuery>;
export type GetTagQueryResult = Apollo.QueryResult<GetTagQuery, GetTagQueryVariables>;
export const ListTagsDocument = gql`
  query listTags($tagsInput: ListTagsInput) {
    listTags(input: $tagsInput) {
      edges {
        altId
        tagName
        description
        chargers {
          total
        }
        filters {
          criteria
          exclude
          values {
            label
            valid
            value
          }
        }
      }
      page
      pageSize
      total
      sort {
        tagName
      }
      search {
        tagName {
          iLike
        }
      }
    }
  }
`;

/**
 * __useListTagsQuery__
 *
 * To run a query within a React component, call `useListTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagsQuery({
 *   variables: {
 *      tagsInput: // value for 'tagsInput'
 *   },
 * });
 */
export function useListTagsQuery(baseOptions?: Apollo.QueryHookOptions<ListTagsQuery, ListTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTagsQuery, ListTagsQueryVariables>(ListTagsDocument, options);
}
export function useListTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTagsQuery, ListTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTagsQuery, ListTagsQueryVariables>(ListTagsDocument, options);
}
export type ListTagsQueryHookResult = ReturnType<typeof useListTagsQuery>;
export type ListTagsLazyQueryHookResult = ReturnType<typeof useListTagsLazyQuery>;
export type ListTagsQueryResult = Apollo.QueryResult<ListTagsQuery, ListTagsQueryVariables>;
export const ListTagsOptionsDocument = gql`
  query listTagsOptions($tagsInput: ListTagsInput) {
    listTags(input: $tagsInput) {
      edges {
        altId
        tagName
      }
      page
      pageSize
      total
    }
  }
`;

/**
 * __useListTagsOptionsQuery__
 *
 * To run a query within a React component, call `useListTagsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagsOptionsQuery({
 *   variables: {
 *      tagsInput: // value for 'tagsInput'
 *   },
 * });
 */
export function useListTagsOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTagsOptionsQuery, ListTagsOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTagsOptionsQuery, ListTagsOptionsQueryVariables>(ListTagsOptionsDocument, options);
}
export function useListTagsOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTagsOptionsQuery, ListTagsOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTagsOptionsQuery, ListTagsOptionsQueryVariables>(ListTagsOptionsDocument, options);
}
export type ListTagsOptionsQueryHookResult = ReturnType<typeof useListTagsOptionsQuery>;
export type ListTagsOptionsLazyQueryHookResult = ReturnType<typeof useListTagsOptionsLazyQuery>;
export type ListTagsOptionsQueryResult = Apollo.QueryResult<ListTagsOptionsQuery, ListTagsOptionsQueryVariables>;
export const ListTagsTotalDocument = gql`
  query listTagsTotal($tagsInput: ListTagsInput) {
    listTags(input: $tagsInput) {
      total
    }
  }
`;

/**
 * __useListTagsTotalQuery__
 *
 * To run a query within a React component, call `useListTagsTotalQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagsTotalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagsTotalQuery({
 *   variables: {
 *      tagsInput: // value for 'tagsInput'
 *   },
 * });
 */
export function useListTagsTotalQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTagsTotalQuery, ListTagsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTagsTotalQuery, ListTagsTotalQueryVariables>(ListTagsTotalDocument, options);
}
export function useListTagsTotalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTagsTotalQuery, ListTagsTotalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTagsTotalQuery, ListTagsTotalQueryVariables>(ListTagsTotalDocument, options);
}
export type ListTagsTotalQueryHookResult = ReturnType<typeof useListTagsTotalQuery>;
export type ListTagsTotalLazyQueryHookResult = ReturnType<typeof useListTagsTotalLazyQuery>;
export type ListTagsTotalQueryResult = Apollo.QueryResult<ListTagsTotalQuery, ListTagsTotalQueryVariables>;
export const UpdateTagDocument = gql`
  mutation updateTag($input: UpdateTagInput, $chargersInput: ListChargersInput) {
    updateTag(input: $input) {
      altId
      tagName
      description
      filters {
        criteria
        exclude
        operator
        values {
          label
          valid
          value
        }
      }
      chargers(input: $chargersInput) {
        edges {
          altId
          chargerName
          chargerModel {
            altId
            modelName
          }
          cid
          fieldStationStatus
          site {
            altId
            address1
            address2
            address3
            address4
            locality
            administrativeArea
            postalCode
            country
            property {
              propertyName
            }
          }
        }
        page
        pageSize
        sort {
          cid
          fieldStationStatus
          chargerName
        }
        total
      }
    }
  }
`;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *      chargersInput: // value for 'chargersInput'
 *   },
 * });
 */
export function useUpdateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
}
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const ValidateTagDocument = gql`
  query validateTag($input: ValidateTagInput, $chargersInput: ListChargersInput) {
    validateTag(input: $input) {
      altId
      tagName
      description
      filters {
        criteria
        exclude
        operator
        values {
          label
          valid
          value
        }
      }
      chargers(input: $chargersInput) {
        edges {
          altId
          chargerModel {
            altId
            modelName
          }
          cid
          fieldStationStatus
          chargerName
          site {
            altId
            address1
            address2
            address3
            address4
            locality
            administrativeArea
            postalCode
            country
            property {
              propertyName
              host {
                altId
              }
            }
          }
        }
        page
        pageSize
        sort {
          cid
          chargerName
          fieldStationStatus
          hostName
          propertyName
        }
        total
      }
    }
  }
`;

/**
 * __useValidateTagQuery__
 *
 * To run a query within a React component, call `useValidateTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTagQuery({
 *   variables: {
 *      input: // value for 'input'
 *      chargersInput: // value for 'chargersInput'
 *   },
 * });
 */
export function useValidateTagQuery(
  baseOptions?: Apollo.QueryHookOptions<ValidateTagQuery, ValidateTagQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidateTagQuery, ValidateTagQueryVariables>(ValidateTagDocument, options);
}
export function useValidateTagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidateTagQuery, ValidateTagQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidateTagQuery, ValidateTagQueryVariables>(ValidateTagDocument, options);
}
export type ValidateTagQueryHookResult = ReturnType<typeof useValidateTagQuery>;
export type ValidateTagLazyQueryHookResult = ReturnType<typeof useValidateTagLazyQuery>;
export type ValidateTagQueryResult = Apollo.QueryResult<ValidateTagQuery, ValidateTagQueryVariables>;
export const ListTransactionV2DetailsDocument = gql`
  query listTransactionV2Details($input: ListTransactionsV2Input!) {
    listTransactionsV2(input: $input) {
      total
      edges {
        amount
        createdAt
        tax
        charger {
          altId
          chargerName
          brand
        }
        type {
          columnValue
        }
        session {
          sessionDuration
          energyDelivered
          altId
          vendorId
          connector {
            altId
            connectorType {
              columnValue
            }
          }
          plan {
            planName
            chargerGroup {
              altId
            }
            chargerGroup {
              tariff {
                altId
                tariffName
              }
            }
          }
          stopSource {
            columnText
          }
          startSource {
            columnText
          }
          startTime
          endTime
          meterStart
          meterEnd
        }
        altId
        items {
          unitPrice
          amount
          altId
          productType {
            columnValue
            columnText
          }
          quantity
        }
      }
    }
  }
`;

/**
 * __useListTransactionV2DetailsQuery__
 *
 * To run a query within a React component, call `useListTransactionV2DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionV2DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionV2DetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTransactionV2DetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ListTransactionV2DetailsQuery, ListTransactionV2DetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTransactionV2DetailsQuery, ListTransactionV2DetailsQueryVariables>(
    ListTransactionV2DetailsDocument,
    options,
  );
}
export function useListTransactionV2DetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTransactionV2DetailsQuery, ListTransactionV2DetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTransactionV2DetailsQuery, ListTransactionV2DetailsQueryVariables>(
    ListTransactionV2DetailsDocument,
    options,
  );
}
export type ListTransactionV2DetailsQueryHookResult = ReturnType<typeof useListTransactionV2DetailsQuery>;
export type ListTransactionV2DetailsLazyQueryHookResult = ReturnType<typeof useListTransactionV2DetailsLazyQuery>;
export type ListTransactionV2DetailsQueryResult = Apollo.QueryResult<
  ListTransactionV2DetailsQuery,
  ListTransactionV2DetailsQueryVariables
>;
export const ListTransactionsV2Document = gql`
  query listTransactionsV2($input: ListTransactionsV2Input) {
    listTransactionsV2(input: $input) {
      total
      edges {
        id
        amount
        createdAt
        tax
        vendorId
        type {
          columnValue
        }
        session {
          accepted
          energyDelivered
          corrupted
          startTime
          endTime
          plan {
            planName
            altId
          }
        }
        items {
          comments
        }
        altId
      }
    }
  }
`;

/**
 * __useListTransactionsV2Query__
 *
 * To run a query within a React component, call `useListTransactionsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useListTransactionsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTransactionsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListTransactionsV2Query(
  baseOptions?: Apollo.QueryHookOptions<ListTransactionsV2Query, ListTransactionsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTransactionsV2Query, ListTransactionsV2QueryVariables>(
    ListTransactionsV2Document,
    options,
  );
}
export function useListTransactionsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTransactionsV2Query, ListTransactionsV2QueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTransactionsV2Query, ListTransactionsV2QueryVariables>(
    ListTransactionsV2Document,
    options,
  );
}
export type ListTransactionsV2QueryHookResult = ReturnType<typeof useListTransactionsV2Query>;
export type ListTransactionsV2LazyQueryHookResult = ReturnType<typeof useListTransactionsV2LazyQuery>;
export type ListTransactionsV2QueryResult = Apollo.QueryResult<
  ListTransactionsV2Query,
  ListTransactionsV2QueryVariables
>;
export const AuthenticateUserDocument = gql`
  mutation authenticateUser($input: AuthenticateUserInput!) {
    authenticateUser(input: $input) {
      token
      user {
        id: altId
        username
        userRole
      }
    }
  }
`;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<
  AuthenticateUserMutation,
  AuthenticateUserMutationVariables
>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuthenticateUserMutation, AuthenticateUserMutationVariables>(
    AuthenticateUserDocument,
    options,
  );
}
export type AuthenticateUserMutationHookResult = ReturnType<typeof useAuthenticateUserMutation>;
export type AuthenticateUserMutationResult = Apollo.MutationResult<AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateUserMutation,
  AuthenticateUserMutationVariables
>;
export const CreateAccountVehicleDocument = gql`
  mutation createAccountVehicle($accountVehicleInput: CreateAccountVehicleInput) {
    createAccountVehicle(input: $accountVehicleInput) {
      altId
      vehicle {
        altId
        makeModelYear
        vehicleConnectors {
          id
          connectorName: name
        }
      }
      nickName
      vin
      macAddress
      deletedAt
    }
  }
`;
export type CreateAccountVehicleMutationFn = Apollo.MutationFunction<
  CreateAccountVehicleMutation,
  CreateAccountVehicleMutationVariables
>;

/**
 * __useCreateAccountVehicleMutation__
 *
 * To run a mutation, you first call `useCreateAccountVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountVehicleMutation, { data, loading, error }] = useCreateAccountVehicleMutation({
 *   variables: {
 *      accountVehicleInput: // value for 'accountVehicleInput'
 *   },
 * });
 */
export function useCreateAccountVehicleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountVehicleMutation, CreateAccountVehicleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAccountVehicleMutation, CreateAccountVehicleMutationVariables>(
    CreateAccountVehicleDocument,
    options,
  );
}
export type CreateAccountVehicleMutationHookResult = ReturnType<typeof useCreateAccountVehicleMutation>;
export type CreateAccountVehicleMutationResult = Apollo.MutationResult<CreateAccountVehicleMutation>;
export type CreateAccountVehicleMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountVehicleMutation,
  CreateAccountVehicleMutationVariables
>;
export const ListVehiclesDocument = gql`
  query listVehicles($vehiclesInput: ListVehiclesInput) {
    listVehicles(input: $vehiclesInput) {
      edges {
        altId
        makeModelYear
        vehicleConnectors {
          id
          connectorName: name
        }
      }
      pageSize
      total
    }
  }
`;

/**
 * __useListVehiclesQuery__
 *
 * To run a query within a React component, call `useListVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVehiclesQuery({
 *   variables: {
 *      vehiclesInput: // value for 'vehiclesInput'
 *   },
 * });
 */
export function useListVehiclesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListVehiclesQuery, ListVehiclesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListVehiclesQuery, ListVehiclesQueryVariables>(ListVehiclesDocument, options);
}
export function useListVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListVehiclesQuery, ListVehiclesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListVehiclesQuery, ListVehiclesQueryVariables>(ListVehiclesDocument, options);
}
export type ListVehiclesQueryHookResult = ReturnType<typeof useListVehiclesQuery>;
export type ListVehiclesLazyQueryHookResult = ReturnType<typeof useListVehiclesLazyQuery>;
export type ListVehiclesQueryResult = Apollo.QueryResult<ListVehiclesQuery, ListVehiclesQueryVariables>;
