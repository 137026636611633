import { useMemo } from 'react';
import { TransactionV2, useGetExtendCalculatedHostPlansQuery, Maybe } from 'src/@types';
import {
  formatTransactionForExport,
  FormattedTransactionForExport,
} from 'src/components/views/ExtendPlusView/shared/TransactionTable';

type Props = {
  transactions: Maybe<TransactionV2>[];
};

export function useFormattedTransactionsForExport({ transactions }: Props) {
  const { data: calculatedPlans } = useGetExtendCalculatedHostPlansQuery({ variables: {} });

  const rows = useMemo<FormattedTransactionForExport[]>(
    () =>
      (transactions || []).map((edge) => {
        const row = formatTransactionForExport(edge);
        const hasCalculatedPlans = calculatedPlans?.getExtendCalculatedHostPlans.length;
        if (!hasCalculatedPlans) delete row['eXtend Calculated Transaction'];
        return row;
      }),
    [transactions, calculatedPlans],
  );

  return rows;
}
