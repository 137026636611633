import { ListChargerGroupsForExtendQuery, ListChargerGroupsPlanTariffTagsForExtendQuery } from 'src/@types';

export const getPlanTariffTagsForChargerGroup = (
  chargerGroupsWithPlanTariffTags: NonNullable<
    ListChargerGroupsPlanTariffTagsForExtendQuery['listChargerGroupsForExtend']
  >['edges'],
  chargerGroup: NonNullable<NonNullable<ListChargerGroupsForExtendQuery['listChargerGroupsForExtend']>['edges']>['0'],
) => {
  return chargerGroupsWithPlanTariffTags
    ?.filter((group) => group.altId === chargerGroup.altId && group?.planTariffTags?.length)
    ?.map((group) => group?.planTariffTags)[0];
};
