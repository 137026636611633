import { Tab, Box, Button } from '@material-ui/core';
import { useState, useCallback } from 'react';
import { GetChargerGroupForExtendQuery, useDeleteChargerGroupForExtendMutation } from 'src/@types';
import { StyledTabs } from 'src/components/shared/StyledTabs';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { ChargerGroupChargerView } from '../Chargers';
import { CustomPlansListView } from '../CustomPlans';
import { ChargerGroupPricesView } from '../Prices';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useConfirmationDialog, useSnackbar } from 'src/lib/hooks';
import { useNavigate } from 'react-router-dom';

enum ChargerGroupTab {
  Chargers = 'chargers',
  Prices = 'prices',
  CustomPlans = 'custom-plans',
}

type Props = {
  testId: string;
  data: GetChargerGroupForExtendQuery['getChargerGroupForExtend'];
};

export function ChargerGroupTabs({ testId = '', data }: Props) {
  const { track } = useAnalytics();
  const [tab, setTab] = useState(ChargerGroupTab.Chargers);
  const confirmationDialog = useConfirmationDialog();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [deleteChargerGroup] = useDeleteChargerGroupForExtendMutation({
    onCompleted: () => {
      navigate(`/extend-plus/charger-groups`);
    },
    onError: (err: { message: string }) => {
      snackbar.error(`Failed to delete charger group. Error: ${err?.message}`);
    },
  });

  const handleOnDeleteChargerGroup = useCallback(() => {
    confirmationDialog.open({
      title: `Delete Charger Group`,
      body: `Once deleted, all chargers assigned to this charger group will need to be re-assigned to another charger group`,
      callback: () => {
        deleteChargerGroup({ variables: { input: { altId: data?.altId as string } } });
      },
    });
  }, [data?.altId, confirmationDialog, deleteChargerGroup]);

  return (
    <>
      <StyledTabs tab={tab} handleChangeTab={(e, newTab) => setTab(newTab as ChargerGroupTab)}>
        <Tab
          label="Chargers"
          data-testid={`${testId}-chargers-tab`}
          value={ChargerGroupTab.Chargers}
          onClick={() => track(events.chargerGroupDetailsPage.CLICKED_CHARGER_GROUP_CHARGERS_TAB)}
        ></Tab>
        <Tab
          label="Prices"
          data-testid={`${testId}-prices-tab`}
          value={ChargerGroupTab.Prices}
          onClick={() => track(events.chargerGroupDetailsPage.CLICKED_CHARGER_GROUP_PRICES_TAB)}
        ></Tab>
        <Tab
          label="Custom Plans"
          data-testid={`${testId}-custom-plans-tab`}
          value={ChargerGroupTab.CustomPlans}
          onClick={() => track(events.chargerGroupDetailsPage.CLICKED_CHARGER_GROUP_CUSTOM_PLANS_TAB)}
        ></Tab>
      </StyledTabs>

      {tab === ChargerGroupTab.Chargers && <ChargerGroupChargerView />}
      {tab === ChargerGroupTab.Prices && <ChargerGroupPricesView data={data} />}
      {tab === ChargerGroupTab.CustomPlans && <CustomPlansListView altId={data?.altId || ''} />}

      <Box marginTop={3}>
        <Button
          data-testid="delete-chargers-group-btn"
          variant="outlined"
          startIcon={<DeleteIcon />}
          color="secondary"
          onClick={() => {
            handleOnDeleteChargerGroup();
            track(events.chargerGroupChargersList.DELETE_CHARGE_GROUP);
          }}
        >
          DELETE CHARGER GROUP
        </Button>
      </Box>
    </>
  );
}
