import { Box } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateChargerGroupPlanForExtendMutation } from 'src/@types';
import { Card } from 'src/components/shared/Card';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { Title } from 'src/components/shared/Title';
import { useSnackbar } from 'src/lib/hooks';
import { CustomPlanPriceForm, FormValues } from './CustomPlanPriceForm';
import { validationSchema } from './CustomPlanPriceForm/validations';
import { CustomPlanPriceStepper, Step } from './CustomPlanPriceStepper';

export const CustomPlansCreateView: React.FC = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const initialValues: FormValues = {
    chargerGroupName: '',
    description: '',
    hasDuration: false,
    planDuration: 0,
  };

  const [createCustomPlan] = useCreateChargerGroupPlanForExtendMutation();

  const handleSubmit = (formValues: FormValues, { resetForm, setValues }: FormikHelpers<FormValues>) => {
    const { hasDuration, ...values } = formValues;
    if (!hasDuration) values.planDuration = 0;

    createCustomPlan({
      variables: {
        input: {
          ...values,
        },
      },
      onError: (error) => {
        const message = error?.message.includes('plan with same name already exists')
          ? 'Sorry, this custom plan name already exists. Please provide a unique name.'
          : 'Sorry, we were unable to create your custom plan';

        snackbar.error(message);
        resetForm();
        setValues(formValues);
      },
      onCompleted: ({ createChargerGroupPlanForExtend: customPlan }) => {
        snackbar.success('Your Custom Plan has been saved.');
        navigate(`/extend-plus/custom-plans/${customPlan?.altId}/pricing?new=true`);
      },
    });
  };

  return (
    <PageContent
      hasFloatingPageActions
      pageHeader={
        <PageHeader
          className="page-header"
          childrenLeft={
            <Box className="page-header-children">
              <Title>Custom Plan Details</Title>
            </Box>
          }
        />
      }
    >
      <CustomPlanPriceStepper currentStep={Step.Details} />

      <Card>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <CustomPlanPriceForm isNew={true} />
        </Formik>
      </Card>
    </PageContent>
  );
};
