import { theme } from '@evgo/react-material-components';
import { Box, Button, CircularProgress, IconButton } from '@material-ui/core';
import { Close as CloseIcon, FileCopyOutlined as CopyIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { FieldArray, Form, useFormikContext } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ChargerGroupForExtend,
  CreateCustomPricingForExtendInput,
  CreatePricingForExtendInput,
  useListChargerGroupsForExtendQuery,
} from 'src/@types';
import { CustomPricingInput } from 'src/components/shared/CustomPricingInput';
import { FormAlert } from 'src/components/shared/FormAlert';
import { MultiSelectWithChipsInput } from 'src/components/shared/MultiSelectWithChipsInput';
import { PageActions } from 'src/components/shared/PageActions';
import config from 'src/config';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import styled from 'styled-components';
import { EditSection } from '../Details/EditSection';

export type Props = {
  id?: string;
  readonly?: boolean;
  disabled?: boolean;
};

export const fieldName = 'planTariffs';

const StyledButton = styled(Button)`
  margin-top: ${theme.spacing(2)}px !important;
`;

const StyledDiv = styled.div`
  .has-error {
    border: 1px solid rgb(237, 108, 2);
  }

  .loading {
    color: ${theme.palette.common.white};
  }

  .MuiAlert-filledWarning {
    background-color: rgb(237, 108, 2);
  }
`;

export type CreateCustomPricingForExtendInputForm = Omit<CreateCustomPricingForExtendInput, 'pricing'> & {
  pricing: {
    perKwh: CreatePricingForExtendInput['perKwh'] | boolean;
    perMinute: CreatePricingForExtendInput['perMinute'] | boolean;
    perSession: CreatePricingForExtendInput['perSession'] | boolean;
  };
};

export type TariffCustomPricingFormValues = {
  [fieldName]?: {
    altId?: string;
    chargerGroupIds: string[];
    chargerGroups?: ChargerGroupForExtend[];
    customPricing: CreateCustomPricingForExtendInputForm[];
  }[];
};

export const newPriceSchedule = (): CreateCustomPricingForExtendInputForm => ({
  pricing: {
    perKwh: false,
    perSession: false,
    perMinute: false,
  },
  tariffSchedules: [
    {
      startTime: '',
      endTime: '',
      daysOfWeek: [],
    },
  ],
});

export const newPricingGroup = () => ({
  chargerGroupIds: [],
  customPricing: [newPriceSchedule()],
});

export function PricingForm({ id = '', readonly = false, disabled = false }: Props) {
  const { track } = useAnalytics();

  const { altId } = useParams<{ altId: string }>();
  const [queryParams] = useSearchParams();
  const isNew = Boolean(queryParams.get('new'));
  const navigate = useNavigate();
  const params = useParams();
  const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [scrolledToAnchor, setScrolledToAnchor] = useState(false);

  const { values, errors, touched, isSubmitting, submitCount, isValid } =
    useFormikContext<TariffCustomPricingFormValues>();

  const { data: chargerGroupsData, loading: chargerGroupsLoading } = useListChargerGroupsForExtendQuery({
    variables: {
      input: {
        pageSize: config.maxPageSize,
        filter: {
          chargerGroupTypeId: {
            eq: 401,
          },
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (readonly || scrolledToAnchor) return;

    const anchorIndex = Number(queryParams.get('anchor'));

    if (!isNaN(anchorIndex) && chargerGroupsData?.listChargerGroupsForExtend?.edges[anchorIndex]) {
      itemRefs.current[anchorIndex]?.scrollIntoView();

      setScrolledToAnchor(true);
    }
  }, [chargerGroupsData?.listChargerGroupsForExtend?.edges, queryParams, scrolledToAnchor, readonly]);

  const getDisabledOptions = useCallback(
    (index: number) => {
      const selectedOptions: string[] = [];

      values[fieldName]?.forEach(({ chargerGroupIds }, i) => {
        if (index != i && chargerGroupIds.length) {
          selectedOptions.push(...chargerGroupIds);
        }
      });

      return selectedOptions;
    },
    [values],
  );

  const getChargerGroupOptions = useCallback(() => {
    const chargerGroups = chargerGroupsData?.listChargerGroupsForExtend?.edges || [];
    const chargerGroupOptions = chargerGroups.map((chargerGroup) => ({
      value: chargerGroup.altId,
      label: chargerGroup.chargerGroupName,
      secondaryValue: `${chargerGroup.chargers?.total || 0}`,
    }));
    return chargerGroupOptions;
  }, [chargerGroupsData]);

  const clonePrice = useCallback(
    (index: number) => {
      const currentPrice = values[fieldName]?.[index];
      return { ...currentPrice, chargerGroupIds: [], chargerGroups: [], altId: null };
    },
    [values],
  );

  const hasGapError = (index: number) => {
    // @ts-ignore - gaps does not exist on TariffCustomPricingFormValues, it's a custom error prop.
    return errors?.[fieldName]?.[index]?.gaps && touched?.[fieldName]?.[index]?.customPricing;
  };

  return (
    <Form id={`${id}-form`}>
      <FieldArray name={fieldName} data-testid={`${id}-prices`}>
        {({ remove, push }) => (
          <StyledDiv>
            {values[fieldName]?.map((_, index) => (
              <div key={index} ref={(element) => (itemRefs.current[index] = element)}>
                <Box mb={4} position="relative" className={clsx(hasGapError(index) && 'has-error')}>
                  <EditSection
                    title={`Pricing ${index + 1}`}
                    subtitle={
                      !readonly
                        ? 'Select charger group(s) and define a price and schedule across a 24/7 period.'
                        : undefined
                    }
                    route={`/extend-plus/custom-plans/${params.altId}/pricing?anchor=${index}`}
                    disabled={!readonly || disabled}
                  >
                    <MultiSelectWithChipsInput
                      label="Select Charger Groups"
                      name={`${fieldName}.${index}.chargerGroupIds`}
                      loading={chargerGroupsLoading}
                      options={getChargerGroupOptions()}
                      selectedOptions={getDisabledOptions(index)}
                      readonly={readonly}
                    />

                    {!readonly && hasGapError(index) && (
                      <Alert variant="filled" severity="warning">
                        Ensure these charger groups have a price across 24 hour period for each day of the week
                      </Alert>
                    )}

                    <FieldArray name={`${fieldName}.${index}.customPricing`} data-testid={`${id}-custom-prices`}>
                      {({ remove: removePrice, push: pushPrice }) => (
                        <Box>
                          {values[fieldName]?.[index].customPricing?.map((customPlanPrice, zIndex) => (
                            <Box key={zIndex} position="relative">
                              <CustomPricingInput
                                name={`${fieldName}.${index}.customPricing.${zIndex}`}
                                scheduleTooltip={
                                  <div>
                                    Ensure you have a price defined for all time periods.
                                    <Box mt={1} />
                                    For example, if you want one price for the full 24/7 period, input 12:00AM - 12:00AM
                                    for S, M, T, W, T, F, S.
                                  </div>
                                }
                                index={zIndex}
                                readonly={readonly}
                              />
                              {!readonly && (
                                <IconButton
                                  onClick={() => removePrice(zIndex)}
                                  aria-label="remove custom price"
                                  data-testid={`${id}-remove-custom-prices-${zIndex}`}
                                  style={{ position: 'absolute', top: 0, right: 0 }}
                                  disabled={values[fieldName]?.[index].customPricing?.length === 1}
                                >
                                  <CloseIcon fontSize="inherit" />
                                </IconButton>
                              )}
                            </Box>
                          ))}

                          {!readonly && (
                            <StyledButton
                              variant="outlined"
                              color="secondary"
                              onClick={() => pushPrice(newPriceSchedule())}
                              data-testid={`${id}-add-price-schedule-button`}
                            >
                              + Add another price schedule for this group
                            </StyledButton>
                          )}
                        </Box>
                      )}
                    </FieldArray>
                    {!readonly && (
                      <IconButton
                        onClick={() => {
                          const clonedPrice = clonePrice(index);
                          push(clonedPrice);
                          track(events.customPlanDetails.DUPLICATED_CUSTOM_PLAN_PRICE_SCHEDULE_GROUP);
                        }}
                        aria-label="clone custom price"
                        data-testid={`${id}-clone-custom-prices-${index}`}
                        style={{ position: 'absolute', top: 24, right: 48 }}
                      >
                        <CopyIcon fontSize="inherit" />
                      </IconButton>
                    )}
                    {!readonly && (
                      <IconButton
                        onClick={() => {
                          remove(index);
                          track(events.customPlanDetails.REMOVED_CUSTOM_PLAN_PRICE_SCHEDULE_GROUP);
                        }}
                        aria-label="remove custom price"
                        data-testid={`${id}-remove-custom-prices-${index}`}
                        style={{ position: 'absolute', top: 24, right: 0 }}
                        disabled={values[fieldName]?.length === 1}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </EditSection>
                </Box>
              </div>
            ))}
            {!readonly && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => push(newPricingGroup())}
                data-testid={`${id}-add-pricing-group-button`}
              >
                Add new pricing group
              </Button>
            )}
          </StyledDiv>
        )}
      </FieldArray>

      {!readonly && (
        <PageActions>
          {submitCount > 0 && !isValid && <FormAlert />}
          <Button
            disabled={isSubmitting}
            data-testid={`${id}-cancel-button`}
            color="secondary"
            size="large"
            onClick={() => navigate(`/extend-plus/custom-plans/${altId}/${isNew ? 'edit?new=true' : 'details'}`)}
          >
            Back
          </Button>
          <Button
            disabled={isSubmitting}
            data-testid={`${id}-save-button`}
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
            onClick={() => track(events.customPlanDetails.SAVED_CUSTOM_PLAN_PRICE_SCHEDULE)}
          >
            {isSubmitting ? (
              <CircularProgress size={theme.spacing(2.5)} className="loading" />
            ) : isNew ? (
              'Next'
            ) : (
              'Save'
            )}
          </Button>
        </PageActions>
      )}
    </Form>
  );
}
