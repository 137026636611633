import numeral from 'numeral';
import styled from 'styled-components';
import { ResponsiveHeatMap, ComputedCell, HeatMapDatum } from '@nivo/heatmap';
import { CircularProgress, Box } from '@material-ui/core';
import { Card, CardHeader } from 'src/components/shared/Card';
import { theme } from '@evgo/react-material-components';
import { extendPlusTheme } from 'src/theme/extendPlusTheme';
import { ChartContainer } from './styles';
import { useMemo } from 'react';
import { useGetSessionsConsumptionForExtend } from 'src/lib/hooks/useGetSessionsConsumptionForExtend';
import { getChartData } from './helpers';
import { dateRange } from '../helpers';
import { SessionAggregation } from 'src/@types';

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

export const CustomCell = ({
  cell,
  borderWidth,
  divergeValue,
}: {
  cell: ComputedCell<HeatMapDatum>;
  borderWidth: number;
  divergeValue: number;
}) => {
  if (cell.value === null) return null;

  return (
    <g
      transform={`translate(${cell.x}, ${cell.y})`}
      style={{
        opacity: '1',
      }}
    >
      <rect
        transform={`translate(-${cell.width / 2},-${cell.height / 2})`}
        width={cell.width}
        height={cell.height}
        fill={cell.color}
        fillOpacity={cell.opacity}
        stroke={cell.borderColor}
        strokeWidth={borderWidth}
        rx="0"
        ry="0"
      ></rect>
      <text
        dominantBaseline="central"
        textAnchor="middle"
        style={{
          fontSize: '11px',
          fill: cell.value < divergeValue ? extendPlusTheme.palette.text.primary : extendPlusTheme.palette.common.white,
        }}
      >
        {cell.formattedValue}
      </text>
    </g>
  );
};

const testid = (value: string) => ({ 'data-testid': `energy-distribution-by-hour-${value}` });

export const DistributionByWeekDayHour = ({ timePeriod }: { timePeriod: number }) => {
  const legendSuffix = 'kWh';
  const input = {
    aggregateBy: SessionAggregation.Hour,
    ...dateRange(timePeriod),
  };

  const { data, loading } = useGetSessionsConsumptionForExtend({ input });

  const chartData = useMemo(() => getChartData(data), [data]);

  const values = chartData.flatMap((day) => {
    return day.data.map((val) => Math.round(val.y));
  });

  const powerRangeMin = values.length ? Math.min(...values) : 0;
  const powerRangeMax = values.length ? Math.max(...values) : 100;

  return (
    <StyledCard {...testid('card')}>
      <CardHeader
        title="Average Energy Distribution"
        subtitle={
          <Box display="flex" flexDirection="column">
            Distribution of total average energy consumed across all sites. Data is displayed according to the charger's
            timezone.
          </Box>
        }
      />
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <ChartContainer height={250} {...testid('chart')}>
          <ResponsiveHeatMap
            data={chartData}
            margin={{ top: 50, left: 35 }}
            valueFormat=">-.2s"
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -90,
              legend: '',
              legendOffset: 46,
            }}
            colors={{
              type: 'diverging',
              colors: [
                extendPlusTheme.palette.common.white,
                extendPlusTheme.palette.sequential[300],
                extendPlusTheme.palette.sequential[700],
              ],
              divergeAt: 0.5,
              minValue: powerRangeMin,
              maxValue: powerRangeMax,
            }}
            cellComponent={(cell) => CustomCell({ ...cell, divergeValue: (powerRangeMax - powerRangeMin) / 2 })}
            emptyColor="#fff"
            borderWidth={1}
            borderColor={theme.palette.grey[200]}
            legends={[]}
          />
          <div {...testid('chart-legend')}>
            <span>
              {numeral(powerRangeMin).format('0,0.[0]')} {legendSuffix}
              <div className="blue1" />
              <div className="blue2" />
              <div className="blue3" />
              <div className="blue4" />
              <div className="blue5" />
              <div className="blue6" />
              <div className="blue7" />
              <div className="blue8" />
              {numeral(powerRangeMax).format('0,0.[0]')} {legendSuffix}
            </span>
          </div>
        </ChartContainer>
      )}
    </StyledCard>
  );
};
