import { TextInfo, theme } from '@evgo/react-material-components';
import { Box, Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { DataContext } from 'src/components/shared/DataContext';
import { useDataContext } from 'src/components/shared/DataContext/useDataContext';
import { PageActions } from 'src/components/shared/PageActions';
import { events } from 'src/lib/utils/analytics-events';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import styled from 'styled-components';
import { MultiSelectWithChipsInput } from 'src/components/shared/MultiSelectWithChipsInput';
import { useCallback } from 'react';
import config from 'src/config';
import { useListChargerGroupsForChargerPricesQuery } from 'src/@types';
import { Card, CardHeader } from 'src/components/shared/Card';
import { FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS, useFlag } from 'src/config/featureFlag';

export type FormValues = {
  name: string;
  displayName: string;
  chargerGroupIds: string[];
};

export type ChargerPriceFormProps = {
  isNew: boolean;
  altId?: string;
};

const StyledForm = styled(Form)`
  .half {
    width: 100%;
    max-width: 500px;
  }

  .fields {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)}px;
  }

  .loading {
    color: ${theme.palette.common.white};
  }
`;

export const fieldName = 'chargerGroupIds';

export const ChargerPriceForm = ({ isNew, altId }: ChargerPriceFormProps) => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const className = 'ChargerPriceForm';
  const id = _.kebabCase(className);
  const [dataRef, setData] = useDataContext();
  const { values, touched, errors, handleBlur, handleChange, isSubmitting } = useFormikContext<FormValues>();
  const enableHMIFields = useFlag(FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS);

  const { data: chargerGroupsData, loading: chargerGroupsLoading } = useListChargerGroupsForChargerPricesQuery({
    variables: {
      input: {
        pageSize: config.maxPageSize,
        filter: {
          chargerGroupTypeId: {
            eq: 401,
          },
        },
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const getChargerGroupOptions = useCallback(() => {
    const allowedChargerGroups =
      chargerGroupsData?.listChargerGroupsForExtend?.edges.filter((chargerGroup) => {
        return !Boolean(chargerGroup.tariff) || chargerGroup.tariff?.altId === altId;
      }) || [];
    const chargerGroupOptions = allowedChargerGroups.map((chargerGroup) => ({
      value: chargerGroup.altId,
      label: chargerGroup.chargerGroupName,
      secondaryValue: `${chargerGroup.chargers?.total || 0}`,
    }));
    return chargerGroupOptions;
  }, [chargerGroupsData, altId]);

  const getDisabledOptions = useCallback(() => {
    const groupOptions = getChargerGroupOptions();
    const selectedOptions = values[fieldName]?.filter((groupId) => {
      groupOptions.map((option) => option.value).includes(groupId);
    });

    return selectedOptions;
  }, [values, getChargerGroupOptions]);

  return (
    <DataContext context={id}>
      <StyledForm ref={dataRef}>
        <Card mb={4}>
          <CardHeader title="Pricing Name" />
          <Box className="fields">
            <TextInfo
              className={clsx(className, 'half')}
              disabled={isSubmitting}
              error={errors.name && touched.name}
              helpertext={touched.name && errors.name}
              id={`${id}-pricing-name-input`}
              label="Pricing Name *"
              labelProps={{ className }}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              {...setData('pricing-name-input')}
            />
            {enableHMIFields && (
              <TextInfo
                className={clsx(className, 'half')}
                disabled={isSubmitting}
                error={errors.displayName && touched.displayName}
                helpertext={touched.displayName && errors.displayName}
                id={`${id}-pricing-display-name-input`}
                label="Display Name"
                labelProps={{ className }}
                name="displayName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.displayName}
                {...setData('display-name-input')}
              />
            )}
          </Box>
        </Card>
        <Card>
          <CardHeader title="Charger Group" subtitle="Select charger groups to which this price applies." />
          <Box className="fields">
            <MultiSelectWithChipsInput
              label="Select Charger Groups"
              name={`${fieldName}`}
              loading={chargerGroupsLoading}
              options={getChargerGroupOptions()}
              selectedOptions={getDisabledOptions()}
            />
          </Box>
        </Card>
        <PageActions>
          <Button
            className={className}
            color="secondary"
            disabled={isSubmitting}
            id={`${id}-cancel-button`}
            {...setData('cancel-button')}
            size="large"
            onClick={() => {
              navigate(`/extend-plus/charger-prices/${isNew ? '' : `${altId}/details`}`);
              track(events.chargerPriceDetailsPage.CANCELED_CHARGER_PRICE_DETAILS);
            }}
          >
            Back
          </Button>

          <Button
            className={className}
            color="secondary"
            disabled={isSubmitting}
            id={`${id}-save-button`}
            size="large"
            type="submit"
            variant="contained"
            {...setData('save-button')}
            onClick={() => track(events.chargerPriceDetailsPage.SAVED_CHARGER_PRICE_DETAILS)}
          >
            {isSubmitting ? (
              <CircularProgress size={theme.spacing(2.5)} className="loading" />
            ) : isNew ? (
              'Next'
            ) : (
              'Save'
            )}
          </Button>
        </PageActions>
      </StyledForm>
    </DataContext>
  );
};
