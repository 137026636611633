import { Box } from '@material-ui/core';
import { TextInfo } from '@evgo/react-material-components';
import { FormikTouched, FormikErrors, useFormikContext } from 'formik';
import { FormValues } from '../../../utils';
import { DisplayRank } from 'src/@types/shared';
import { Dropdown } from '@evgo/react-material-components';
import { TariffCustomPricingForExtend } from 'src/@types';
import { Fragment } from 'react';

type Props = {
  name: 'customPricing' | 'defaultPricing';
  index?: number;
  id: string;
  rankOptions?: Array<{ label: string; value: number | null }>;
  readonly?: boolean;
};

export function HmiDisplayInputs(props: Props) {
  const { name, id, index = 0, readonly, rankOptions } = props;
  const { values, handleBlur, handleChange, isSubmitting, errors, touched } = useFormikContext<FormValues>();

  const getFormikInputProps = (
    field: 'displayName' | 'displayDescription1' | 'displayDescription2' | 'displayRank',
  ) => {
    const customPricingErrors = errors?.customPricing as FormikErrors<TariffCustomPricingForExtend>[];
    const customPricingTouched = touched?.customPricing as FormikTouched<TariffCustomPricingForExtend>[];
    const customPricingValues = values?.customPricing as TariffCustomPricingForExtend[];
    const defaultPricingValues = values?.defaultPricing;
    const defaultPricingErrors = errors?.defaultPricing;
    const defaultPricingTouched = touched?.defaultPricing;
    const formikProps: Record<string, unknown> = {
      onBlur: handleBlur,
      onChange: handleChange,
      name: name === 'customPricing' ? `${name}.[${index}]${field}` : `${name}.${field}`,
      value: name === 'customPricing' ? customPricingValues[index]?.[field] : defaultPricingValues?.[field],
      error:
        name === 'customPricing'
          ? customPricingErrors?.[index]?.[field] && customPricingTouched?.[index]?.[field]
          : defaultPricingErrors?.[field] && defaultPricingTouched?.[field],
      helpertext:
        name === 'customPricing'
          ? customPricingTouched?.[index]?.[field] && customPricingErrors?.[index]?.[field]
          : defaultPricingTouched?.[field] && defaultPricingErrors?.[field],
    };

    return formikProps;
  };

  const displayRank = (getFormikInputProps('displayRank')?.value as number) || DisplayRank.NotDisplayed;
  const options =
    displayRank !== DisplayRank.NotDisplayed
      ? [{ label: (displayRank as number).toString(), value: displayRank }, ...(rankOptions || [])]
      : rankOptions;
  return (
    <Fragment>
      {readonly ? (
        <Box pl={2}>
          {getFormikInputProps('displayName')?.value && <p>Name: {getFormikInputProps('displayName')?.value}</p>}
          {getFormikInputProps('displayDescription1')?.value && (
            <p>Description 1: {getFormikInputProps('displayDescription1')?.value}</p>
          )}
          {getFormikInputProps('displayDescription2')?.value && (
            <p>Description 2: {getFormikInputProps('displayDescription2')?.value}</p>
          )}
          <p>Rank: {displayRank === DisplayRank.NotDisplayed ? 'Not Displayed' : displayRank.toString()}</p>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" pl={1}>
          <Box pb={1.8}>
            <TextInfo
              type="text"
              disabled={isSubmitting || readonly}
              label="Name"
              data-testid={`${id}-${index}-display-name`}
              {...getFormikInputProps('displayName')}
            />
          </Box>
          <Box pb={1.8}>
            <TextInfo
              type="text"
              disabled={isSubmitting || readonly}
              label="Description 1"
              data-testid={`${id}-${index}-display-description1`}
              {...getFormikInputProps('displayDescription1')}
            />
          </Box>
          <Box pb={1.8}>
            <TextInfo
              type="text"
              disabled={isSubmitting || readonly}
              label="Description 2"
              data-testid={`${id}-${index}-display-description2`}
              {...getFormikInputProps('displayDescription2')}
            />
          </Box>
          <Box pb={1.8}>
            <Dropdown
              label="Rank"
              disabled={isSubmitting || readonly}
              data-testid={`${id}-${index}-display-rank`}
              options={options || []}
              {...getFormikInputProps('displayRank')}
            />
          </Box>
        </Box>
      )}
    </Fragment>
  );
}
