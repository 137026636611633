import _ from 'lodash';
import clsx from 'clsx';
import React, { useState, useRef } from 'react';
import { useDebounceFn } from 'ahooks';
import { InputLabel, Select, Checkbox, ListItemText, Typography, CircularProgress } from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, Search as SearchIcon } from '@material-ui/icons';
import { StyledFormControl, StyledSearchMenuItem, StyledMenuItem, StyledBox } from './styles';
import { Options } from 'src/lib/helpers';

export type MultiSelectSearchFilterProps = {
  id?: string;
  label: string;
  className?: string;
  options: Array<Options>;
  debounce?: number;
  onChange: (value: Array<string>) => void;
  loading?: boolean;
  value: Array<string>;
};

export const MultiSelectSearchFilter: React.FC<MultiSelectSearchFilterProps> = (props) => {
  const {
    label,
    id = _.kebabCase(label),
    className = 'MultiSelectSearch',
    options,
    debounce = 200,
    onChange,
    value: selected,
    loading = false,
  } = props;

  const [typed, setTyped] = useState<string>('');
  const inputRef = useRef<null | HTMLInputElement>(null);
  const { run: debouncedOnChange } = useDebounceFn(onChange, { wait: debounce });

  const selectedLabels = (): string => {
    if (!selected.length) {
      return 'All';
    }
    return selected.map((item) => options.find((option) => option.value === item)?.label).join(', ');
  };

  return (
    <StyledFormControl className={className}>
      <InputLabel className={clsx(className, 'label')}>{label}</InputLabel>
      <Select
        className={clsx(className, 'select')}
        displayEmpty
        multiple
        onChange={(e) => {
          const values: string[] = e.target.value as string[];
          debouncedOnChange(values);
        }}
        renderValue={selectedLabels}
        value={selected}
        data-testid={`${id}-select`}
        MenuProps={{
          variant: 'menu',
          getContentAnchorEl: null,
        }}
      >
        <StyledSearchMenuItem value={''} disabled disableRipple>
          <SearchIcon />
          <input
            data-testid="options-search"
            className={clsx(className, 'search')}
            onClickCapture={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onKeyDown={(e) => e.stopPropagation()}
            autoFocus
            type="text"
            value={typed}
            placeholder="Search"
            onChange={(e) => {
              setTyped(e.target.value);
              setTimeout(() => {
                inputRef?.current?.focus();
              }, 100);
            }}
            ref={inputRef}
          ></input>
        </StyledSearchMenuItem>
        {options
          .filter((option) => option?.label?.toLocaleLowerCase().includes(typed.toLocaleLowerCase()))
          .map((option, i) => {
            return (
              <StyledMenuItem
                key={option?.value}
                className={clsx(className, 'option')}
                value={option?.value}
                disableRipple
              >
                <Checkbox
                  checked={selected.includes(option?.value)}
                  checkedIcon={<CheckBox fontSize="small" />}
                  disableRipple
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  inputProps={{ id: `${id}-${i}-option-checkbox` }}
                  className={clsx(className, 'option-checkbox')}
                  data-testid="option-checkbox"
                />
                <ListItemText data-testid="option-label" primary={option?.label} style={{ fontSize: 'small' }} />
              </StyledMenuItem>
            );
          })}
      </Select>

      {loading && (
        <StyledBox>
          <CircularProgress size={20} />
        </StyledBox>
      )}

      {!_.isEmpty(selected) && (
        <Typography
          className={clsx(className, 'clear-options')}
          variant="caption"
          color="secondary"
          onClick={() => {
            debouncedOnChange([]);
          }}
        >
          Clear
        </Typography>
      )}
    </StyledFormControl>
  );
};
