import { Day, TariffCustomPricingForExtend } from 'src/@types';
import { convertTimeToNumber } from '../../ChargerPrices/Pricing/utils';

type Timeframe = { startTime: number; endTime: number };

export function groupByDayOfWeek(values: TariffCustomPricingForExtend[]): Record<Day, Timeframe[]> {
  const map = {
    [Day.Monday]: [] as Timeframe[],
    [Day.Tuesday]: [] as Timeframe[],
    [Day.Wednesday]: [] as Timeframe[],
    [Day.Thursday]: [] as Timeframe[],
    [Day.Friday]: [] as Timeframe[],
    [Day.Saturday]: [] as Timeframe[],
    [Day.Sunday]: [] as Timeframe[],
  };

  values.forEach(({ tariffSchedules }) => {
    if (!tariffSchedules?.length) {
      return;
    }

    tariffSchedules.forEach((tariffSchedule) => {
      const { startTime = '', endTime = '', daysOfWeek = [] } = tariffSchedule || {};

      daysOfWeek.forEach((dayOfWeek) => {
        if (!map[dayOfWeek]) {
          map[dayOfWeek] = [];
        }
        map[dayOfWeek].push({
          startTime: convertTimeToNumber(startTime),
          endTime: convertTimeToNumber(endTime),
        });
      });
    });
  });

  return map;
}

function hasGaps(timeframes: Timeframe[]) {
  if (!timeframes.length) return true;
  timeframes.sort((a, b) => (a.startTime > b.startTime ? 1 : -1));
  return timeframes.some(({ startTime, endTime }, i) => {
    if (i === 0 && startTime > 0) return true;
    if (i === timeframes.length - 1 && endTime !== 0) return true;
    const previousTimeframe = timeframes[i - 1];
    if (previousTimeframe) {
      const endTimeIsForNextDay = previousTimeframe.endTime === 0;
      const previousEndTime = endTimeIsForNextDay ? 24 : previousTimeframe.endTime;
      if (previousEndTime !== startTime) return true;
    }
  });
}

export function isFullyScheduledWeek(values: TariffCustomPricingForExtend[]): boolean {
  const timeframesByDayOfWeek = groupByDayOfWeek(values);

  return !Object.values(timeframesByDayOfWeek).some((timeframesForDay) => {
    return hasGaps(timeframesForDay);
  });
}
