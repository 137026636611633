import { Box } from '@material-ui/core';
import { useMemo, useReducer, useState } from 'react';
import { useListChargerGroupsForExtendQuery } from 'src/@types';
import { Card } from 'src/components/shared/Card';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { Title } from 'src/components/shared/Title';
import { UtilizationBarChartCardWithQuery } from 'src/components/shared/UtilizationBarChartCardWithQuery';
import config from 'src/config';
import { useGetSitesForUser } from 'src/lib/hooks';
import { initialState, reducer } from './filterHelpers';
import { transactionTypes } from '../shared/TransactionFilter';
import { ReportFilter } from './ReportFilter';
import { TransactionsList } from './TransactionsList';
import { useUTCDateRangeValue, useUtilizationBarChartAggregateBy } from './utils';
import { SiteMetrics } from './SiteMetrics';

export const ReportView = () => {
  const [loadingSites, setLoadingSites] = useState(true);
  const [state, dispatch] = useReducer(reducer, initialState);
  const dateRange = useUTCDateRangeValue(state.filter.dateRange);
  const aggregateBy = useUtilizationBarChartAggregateBy(state.filter.dateRange);

  useGetSitesForUser({
    onCompleted: (sites) => {
      dispatch({ type: 'setInitialSites', sites });
      setLoadingSites(false);
    },
  });

  const { loading: loadingChargerGroups } = useListChargerGroupsForExtendQuery({
    variables: {
      input: {
        pageSize: config.maxPageSize,
      },
    },
    onCompleted(data) {
      const chargerGroups = data?.listChargerGroupsForExtend?.edges;
      const chargerGroupPrices = chargerGroups?.filter(({ chargerGroupTypeId }) => chargerGroupTypeId === 401);
      dispatch({ type: 'setInitialChargerGroups', chargerGroups: chargerGroupPrices || [] });
      const chargerGroupPlans = chargerGroups?.filter(
        ({ chargerGroupTypeId, planId }) => chargerGroupTypeId === 402 && planId,
      );
      dispatch({
        type: 'setInitialPlans',
        plans: chargerGroupPlans || [],
      });
    },
  });

  const transactionTypeIds = useMemo(
    () =>
      state.filter.transactionTypes.length ? state.filter.transactionTypes : transactionTypes.map((type) => type.id),

    [state.filter.transactionTypes],
  );

  return (
    <PageContent
      loading={loadingSites || loadingChargerGroups}
      pageHeader={<PageHeader childrenLeft={<Title>Consumption Reports</Title>} />}
    >
      <Box>
        <ReportFilter state={state} dispatch={dispatch} />
      </Box>

      <Card>
        <UtilizationBarChartCardWithQuery
          title="Utilization Graph"
          aggregateBy={aggregateBy}
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          siteIds={state.filter?.siteIds}
          chargerGroupIds={state.filter?.chargerGroupIds}
          planIds={state.filter?.planIds}
          transactionTypeIds={transactionTypeIds}
        />
      </Card>
      <TransactionsList
        siteIds={state.filter?.siteIds}
        chargerGroupIds={state.filter?.chargerGroupIds}
        planIds={state.filter?.planIds}
        transactionTypeIds={transactionTypeIds}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
      />
      <SiteMetrics
        siteIds={state.filter?.siteIds}
        chargerGroupIds={state.filter?.chargerGroupIds}
        planIds={state.filter?.planIds}
        transactionTypeIds={transactionTypeIds}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
      />
    </PageContent>
  );
};
