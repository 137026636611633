import { useUpdateEffect } from 'ahooks';
import React, { useState } from 'react';
import {
  useGetChargerGroupForExtendQuery,
  useListChargersV2Query,
  AddChargersToChargerGroupForExtendMutationFn,
} from 'src/@types';
import { StyledModal } from 'src/components/shared/StyledModal';
import config from 'src/config';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import styled from 'styled-components';
import { ChargerSelectionFilter } from './ChargerSelectionFilter';
import { ChargerSelectionList } from './ChargerSelectionList';
import { Filters } from '../filterHelpers';

export type Props = {
  open: boolean;
  onClose: () => void;
  chargerGroupAltId: string | undefined;
  addChargers: AddChargersToChargerGroupForExtendMutationFn;
};

const CustomModal = styled(StyledModal)`
  > div {
    min-width: 75%;
  }
`;

const defaultFilters = {
  search: '',
  zips: [],
  chargerLevels: [],
  states: [],
};

export const ChargerSelectionModal: React.FC<Props> = ({ open, onClose, chargerGroupAltId, addChargers }: Props) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [filters, setFilters] = useState<Filters>(defaultFilters);
  const { track } = useAnalytics();

  const getInput = {
    altId: chargerGroupAltId || '',
  };

  useUpdateEffect(() => {
    if (!open) {
      setSelected([]);
      setFilters(defaultFilters);
    }
  }, [open, setFilters, setSelected]);

  const { data: chargerGroupData } = useGetChargerGroupForExtendQuery({
    variables: {
      input: getInput,
    },
    fetchPolicy: 'cache-and-network',
  });

  const unassignedChargersInput = {
    pageSize: config.maxPageSize,
    filter: {
      chargerGroupName: { eq: null },
    },
  };
  const { data, loading, error } = useListChargersV2Query({
    variables: {
      input: unassignedChargersInput,
    },
    fetchPolicy: 'cache-and-network',
  });

  const chargers = data?.listChargersV2?.edges || [];

  const handleAddChargersToChargerGroupForExtend = () => {
    const existingChargersInGroup =
      chargerGroupData?.getChargerGroupForExtend?.chargers?.edges?.map((charger) => charger?.altId || '') || [];
    addChargers({
      variables: {
        input: { ...getInput, chargerIds: [...existingChargersInGroup, ...selected] },
      },
    });
    onClose();
    track(events.chargerSelectionModal.SAVED_CHARGER_LIST);
  };

  const handleClose = () => {
    onClose();
    track(events.chargerSelectionModal.CLOSED_CHARGER_SELECTION_MODAL);
  };

  return (
    <CustomModal
      data-testid="charger-selection-modal"
      id="charger-selection-modal"
      open={open}
      title="List of chargers to add"
      subtitle={`Filter and select the available chargers you want to apply this price to. If you don't see the charger you are looking for, that means the charger is already assigned to an existing price group.`}
      onClick={handleAddChargersToChargerGroupForExtend}
      onClose={handleClose}
      secondaryButtonClick={handleClose}
      secondaryButtonText="Cancel"
      primaryButtonText="Add Chargers"
    >
      <ChargerSelectionFilter data={chargers} filters={filters} onChange={setFilters} />
      <ChargerSelectionList
        data={chargers}
        loading={loading}
        error={error}
        onSelectChange={setSelected}
        selected={selected}
        filters={filters}
      />
    </CustomModal>
  );
};
