import { gql } from '@apollo/client';
import { ChargerGroupForExtend, ChargersWithMeta, PlanTariffForExtend, TariffForExtend } from './fragments';

export const updatePlanTariffsForExtend = gql`
  mutation updatePlanTariffsForExtend($input: UpdatePlanTariffsForExtendInput!) {
    updatePlanTariffsForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtend}
  ${TariffForExtend}
  ${PlanTariffForExtend}
  ${ChargersWithMeta}
`;
