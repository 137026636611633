import { useQuery } from '@apollo/client';
import { useState, useMemo } from 'react';
import { GetSessionsConsumptionForExtendInput, Query } from 'src/@types';
import { getSessionsConsumptionForExtend } from 'src/apollo/queries/extendPlus';

import { getDateRange, createChartData, getCaptionText } from './helpers';
import { UtilizationChartTab, UtilizationBarChartCard } from '../UtilizationBarChartCard';

export type UtilizationBarChartCardWithQueryProps = GetSessionsConsumptionForExtendInput & {
  title: string;
  siteName?: string;
  chargerName?: string;
};

export function UtilizationBarChartCardWithQuery(props: UtilizationBarChartCardWithQueryProps) {
  const { title, siteName, chargerName, ...input } = props;
  const [tab, setTab] = useState<UtilizationChartTab>(UtilizationChartTab.kWh);
  const caption = useMemo(() => getCaptionText(input.aggregateBy), [input.aggregateBy]);

  input.siteIds = input.siteIds?.length ? input.siteIds : undefined;
  input.chargerIds = input.chargerIds?.length ? input.chargerIds : undefined;
  input.chargerGroupIds = input.chargerGroupIds?.length ? input.chargerGroupIds : undefined;
  input.planIds = input.planIds?.length ? input.planIds : undefined;
  input.transactionTypeIds = input.transactionTypeIds?.length ? input.transactionTypeIds : undefined;

  const { data, loading } = useQuery<Query>(getSessionsConsumptionForExtend, {
    variables: { input },
    fetchPolicy: 'network-only',
  });

  const { chartData, dateRange } = useMemo(() => {
    const base = data?.getSessionsConsumptionForExtend;
    return {
      dateRange: getDateRange(base),
      chartData: createChartData(tab, input.aggregateBy, base),
    };
  }, [data?.getSessionsConsumptionForExtend, input.aggregateBy, tab]);

  return (
    <UtilizationBarChartCard
      id={`${tab}-graph`}
      title={title}
      caption={caption}
      loading={loading}
      siteName={siteName}
      chargerName={chargerName}
      dateRange={dateRange}
      data={chartData}
      selectedTab={tab}
      onTabChanged={setTab}
    />
  );
}
