import { Box, Grid, ThemeProvider, Typography } from '@material-ui/core';
import { ChargerGroupForExtend, ChargerGroupSyncStatus as ChargerGroupSyncStatusType } from 'src/@types';
import ChargerGroupSyncStatus from 'src/components/shared/ChargerGroupSyncStatus';
import { extendPlusTheme } from 'src/theme/extendPlusTheme';
import { ChargerGroupFactoryForExtend } from 'src/__fixtures__/chargerGroup';
import ExampleViewer from './ExampleViewer';

const ChargerGroup: ChargerGroupForExtend = {
  ...ChargerGroupFactoryForExtend(),
  status: ChargerGroupSyncStatusType.Draft,
};

const ChargerGroupNotSynced: ChargerGroupForExtend = {
  ...ChargerGroup,
  status: ChargerGroupSyncStatusType.NotSynced,
};

const ChargerGroupSynced: ChargerGroupForExtend = {
  ...ChargerGroup,
  status: ChargerGroupSyncStatusType.Synced,
};

const ChargerGroupPlan: ChargerGroupForExtend = {
  ...ChargerGroup,
  chargerGroupType: 'CUSTOM_ACCESS_PLAN',
};

const ChargerGroupPlanNotSynced: ChargerGroupForExtend = {
  ...ChargerGroupNotSynced,
  chargerGroupType: 'CUSTOM_ACCESS_PLAN',
};

const ChargerGroupPlanSynced: ChargerGroupForExtend = {
  ...ChargerGroupSynced,
  chargerGroupType: 'CUSTOM_ACCESS_PLAN',
};

export default function ChargerGroupSyncStatusExample() {
  return (
    <ExampleViewer title="Charger Group Sync Status Chip">
      <ThemeProvider theme={extendPlusTheme}>
        <Box padding="10px">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box mb="30px" mt="20px">
                <Typography variant="subtitle2">Charger Prices</Typography>
              </Box>
              <Box display="flex" mb="30px" flexDirection="row" alignItems="center">
                <Box mr="10px">
                  <ChargerGroupSyncStatus data={ChargerGroup} />
                </Box>
                <Box mr="10px">
                  <ChargerGroupSyncStatus data={ChargerGroupNotSynced} />
                </Box>
                <Box mr="10px">
                  <ChargerGroupSyncStatus data={ChargerGroupSynced} />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mb="30px">
                <Typography variant="subtitle2">Custom Plans</Typography>
              </Box>
              <Box display="flex" mb="30px" flexDirection="row" alignItems="center">
                <Box mr="10px">
                  <ChargerGroupSyncStatus data={ChargerGroupPlan} />
                </Box>
                <Box mr="10px">
                  <ChargerGroupSyncStatus data={ChargerGroupPlanNotSynced} />
                </Box>
                <Box mr="10px">
                  <ChargerGroupSyncStatus data={ChargerGroupPlanSynced} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </ExampleViewer>
  );
}
