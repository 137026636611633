import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useListChargersV2ShallowQuery } from 'src/@types';
import { Card } from 'src/components/shared/Card';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { SubTitle } from 'src/components/shared/SubTitle';
import { Title } from 'src/components/shared/Title';
import { events } from 'src/lib/utils/analytics-events';
import { ChargerPriceGroupsList } from './ChargerPricesList';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import config from 'src/config';

export const ChargerPricesListView: React.FC = () => {
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const id = 'charger-prices';

  const unassignedChargersInput = {
    pageSize: config.maxPageSize,
    filter: {
      chargerGroupName: { eq: null },
    },
  };
  const { data } = useListChargersV2ShallowQuery({
    variables: {
      input: unassignedChargersInput,
    },
    fetchPolicy: 'cache-and-network',
  });

  const totalCount = data?.listChargersV2?.total || 0;

  return (
    <PageContent
      pageHeader={
        <PageHeader
          childrenLeft={
            <>
              <Title>Charger Prices</Title>
              <SubTitle color="textPrimary">List of charger prices applied to your charger groups</SubTitle>
            </>
          }
        />
      }
    >
      <article>
        <Card className="table-container">
          <Box pb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button
                onClick={() => {
                  navigate('/extend-plus/charger-prices/create?new=true');
                  track(events.chargerPricesList.CLICKED_ADD_CHARGER_PRICES);
                }}
                variant="contained"
                color="secondary"
                size="large"
                data-testid={`${id}-add-prices-button`}
              >
                Add Prices
              </Button>
            </Box>

            <SubTitle>You have {totalCount} chargers without prices</SubTitle>
          </Box>
          <ChargerPriceGroupsList />
        </Card>
      </article>
    </PageContent>
  );
};
