/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export type Options = {
  value: string;
  label: string;
};

export const getValueFromObject = (object: any, key: string): unknown => {
  let value;
  Object.keys(object).some((k) => {
    if (k === key) {
      value = object[k];
      return true;
    }
    if (object[k] && typeof object[k] === 'object') {
      value = getValueFromObject(object[k], key);
      return value !== undefined;
    }
  });
  return value;
};

export const getUniqueOptions = (array: Array<any>, params: { value: string; label: string }): Array<Options> => {
  const keyArray = array.map((item) => {
    return [
      getValueFromObject(item, params.value),
      { value: getValueFromObject(item, params.value), label: getValueFromObject(item, params.label) },
    ];
  });
  const uniqueMapping = new Map(keyArray as any);

  return [...(uniqueMapping as any).values()];
};
