import { Box, Button, IconButton, Switch, Typography, Tab } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import TodayIcon from '@material-ui/icons/Today';
import { FieldArray, useFormikContext } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TariffCustomPricingForExtend, TariffPricingForExtend } from 'src/@types';
import { Card, CardHeader } from 'src/components/shared/Card';
import { CustomPricingInput } from 'src/components/shared/CustomPricingInput';
import { PricingTypeInput } from 'src/components/shared/CustomPricingInput/PricingTypeInput';
import { StyledTabs } from 'src/components/shared/StyledTabs';
import styled from 'styled-components';
import { ChargerPricingWeekView } from '../ChargerPricingWeekView';
import { useConfirmationDialog } from 'src/lib/hooks';
import { theme } from '@evgo/react-material-components';
import { useLocation } from 'react-router-dom';
import { HmiDisplayInputs } from './HmiDisplayInputs';
import { FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS, useFlag } from 'src/config/featureFlag';
import { DisplayRank } from 'src/@types/shared';

const StyledHeading = styled(Typography)`
  border-bottom: 1px solid ${theme.palette.grey['300']};
`;

const StyledContent = styled.div`
  border-left: 1px solid ${theme.palette.grey['300']};
  padding: ${theme.spacing(1.5)}px ${theme.spacing(5)}px ${theme.spacing(1.5)}px ${theme.spacing(1.5)}px;
  max-width: 200px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${theme.spacing(3)}px;
  padding-bottom: ${theme.spacing(5)}px;
`;

const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)}px;
`;

enum CustomPriceTab {
  CustomPrice = 'custom-price-details',
  CalendarView = 'calendar-view',
}

type Props = {
  id?: string;
  tariffName?: string;
};

export type FormValues = {
  chargerGroupAltId: string;
  customPricing?: Array<TariffCustomPricingForExtend>;
  defaultPricing?: TariffPricingForExtend;
};

const CalendarViewLabel = () => (
  <CalendarContainer>
    <span>Calendar View</span>
    <TodayIcon />
  </CalendarContainer>
);

const newCustomPrice = () => ({
  pricing: {
    perKwh: false,
    perSession: false,
    perMinute: false,
  },
  tariffSchedules: [
    {
      startTime: '',
      endTime: '',
      daysOfWeek: [],
    },
  ],
});

export function PriceForm({ id = '', tariffName }: Props) {
  const fieldName = 'customPricing';
  const [customPriceTab, setCustomPriceTab] = useState(CustomPriceTab.CustomPrice);

  const { values, setFieldValue } = useFormikContext<FormValues>();
  const customPricingExists = Boolean(values?.customPricing?.length);
  const confirmationDialog = useConfirmationDialog();
  const customPricingRef = useRef({ scrollIntoView: () => {} } as HTMLAnchorElement);
  const location = useLocation();

  const executeScroll = () => customPricingRef.current.scrollIntoView();

  useEffect(() => {
    if (location.hash === '#custom-pricing') window.requestAnimationFrame(() => executeScroll());
  }, [location.hash]);

  const onCustomPriceDeletionAttempt = () => {
    confirmationDialog.open({
      title: 'Remove time of use prices?',
      body: 'Are you sure you want to remove this pricing block? Any data that you have entered will be lost.',
      buttonConfirmText: 'CONFIRM',
      callback: () => {
        setFieldValue('customPricing', []);
      },
    });
  };

  const initialRankOptions: Array<{ label: string; value: number | null }> = [
    DisplayRank.NotDisplayed,
    DisplayRank.One,
    DisplayRank.Two,
    DisplayRank.Three,
  ].map((x) => ({
    label: x === DisplayRank.NotDisplayed ? 'Not Displayed' : x.toString(),
    value: x,
  }));

  const foundRanks = useMemo(() => {
    const ranks = values?.customPricing?.filter((price) => !!price.displayRank).map((price) => price.displayRank) || [];
    if (values?.defaultPricing?.displayRank) ranks.push(values.defaultPricing.displayRank);
    return ranks;
  }, [values?.customPricing, values?.defaultPricing]);

  const availableRankOptions: Array<{ label: string; value: number | null }> = useMemo(() => {
    return initialRankOptions.filter(
      (option: { label: string; value: number | null }) =>
        !foundRanks.includes(option.value) || option.value === DisplayRank.NotDisplayed,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundRanks]);

  const enableHmi = useFlag(FLAG_OP_EXTEND_HMI_DISPLAY_FIELDS);

  return (
    <>
      <Card mb={4} style={{ marginTop: `${theme.spacing(4)}px` }}>
        <CardHeader title="What is the default price for these chargers?" subtitle="You can select one or more types" />
        <Box display="flex">
          <Box>
            <StyledHeading variant="h6">Price</StyledHeading>
            <PricingTypeInput name="defaultPricing.pricing" id={`${id}-default-price-input`} />
          </Box>
          {enableHmi && (
            <Box>
              <StyledHeading variant="h6">
                <Box pl={2.5}>HMI Display</Box>
              </StyledHeading>
              <StyledContent>
                <HmiDisplayInputs name="defaultPricing" id="default-pricing" rankOptions={availableRankOptions} />
              </StyledContent>
            </Box>
          )}
        </Box>
      </Card>
      <article ref={customPricingRef} id="custom-pricing">
        <Card>
          <CardHeader
            title="Would you like to define different prices for specific time frames?"
            subtitle="Time of use pricing allows you to define a specific price for different time frames"
          />
          <SwitchContainer>
            <Switch
              color="secondary"
              name="timeSpecificPrices"
              checked={customPricingExists}
              value={customPricingExists}
              data-testid="time-specific-prices-switch"
              onChange={() => {
                if (!customPricingExists) {
                  setFieldValue('customPricing', [newCustomPrice()]);
                } else {
                  onCustomPriceDeletionAttempt();
                }
              }}
            />
            <span>Yes</span>
          </SwitchContainer>

          {customPricingExists && (
            <>
              <StyledTabs
                tab={customPriceTab}
                handleChangeTab={(e, newTab) => setCustomPriceTab(newTab as CustomPriceTab)}
              >
                <Tab
                  data-testid={`${id}-custom-price-details-tab`}
                  label="Custom Price Details"
                  value={CustomPriceTab.CustomPrice}
                />
                <Tab
                  data-testid={`${id}-custom-price-calendar-view-tab`}
                  label={<CalendarViewLabel />}
                  value={CustomPriceTab.CalendarView}
                />
              </StyledTabs>

              {customPriceTab === CustomPriceTab.CustomPrice && (
                <a>
                  <FieldArray name={fieldName} data-testid={`${id}-custom-prices`}>
                    {({ remove, push }) => (
                      <div>
                        {values[fieldName]?.map((customPrice, index) => (
                          <Box key={index} position="relative">
                            <CustomPricingInput
                              showHmiDisplay={true}
                              title={`Time of use price ${index + 1}`}
                              name={`${fieldName}.${index}`}
                              index={index}
                              rankOptions={availableRankOptions}
                              scheduleTooltip={`Times are defined in the charger's timezone`}
                            />
                            <IconButton
                              onClick={() => remove(index)}
                              aria-label="remove custom price"
                              data-testid={`${id}-remove-custom-prices-${index}`}
                              style={{ position: 'absolute', top: 0, right: 0 }}
                              disabled={values.customPricing?.length == 1}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        ))}
                        <Button
                          className="secondary"
                          onClick={() => push(newCustomPrice())}
                          data-testid={`${id}-add-custom-price-button`}
                        >
                          Add Price
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </a>
              )}

              {customPriceTab === CustomPriceTab.CalendarView && (
                <ChargerPricingWeekView tariffName={tariffName} values={values} />
              )}
            </>
          )}
        </Card>
      </article>
    </>
  );
}
