import { ApolloError } from '@apollo/client';
import {
  useGetChargerGroupForExtendQuery,
  useUpdateChargerPriceGroupForExtendMutation,
  useCreateChargerPriceGroupForExtendMutation,
} from 'src/@types';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { Card } from 'src/components/shared/Card';
import { PageContent } from 'src/components/shared/PageContent';
import { useSnackbar } from 'src/lib/hooks';
import { ChargerGroupForm, FormValues } from '../shared/ChargerGroupForm';
import { validationSchema } from '../shared/ChargerGroupForm/validations';
import { formatErrorMessage } from '../utils/formatErrorMessage';

export const ChargerGroupDescriptionView: React.FC = () => {
  const snackbar = useSnackbar();
  const { altId } = useParams<{ altId: string }>();
  const navigate = useNavigate();
  const isNew = !Boolean(altId);

  const [initialValues, setInitialValues] = useState<FormValues>({
    chargerGroupName: '',
    description: '',
  });

  const variables = {
    input: { altId: altId || '' },
  };
  const { loading } = useGetChargerGroupForExtendQuery({
    variables,
    skip: !altId,
    fetchPolicy: 'cache-and-network',
    onError: (error) => snackbar.error(error?.message || 'Something went wrong.'),
    onCompleted: ({ getChargerGroupForExtend }) => {
      if (getChargerGroupForExtend) {
        setInitialValues({
          chargerGroupName: getChargerGroupForExtend.chargerGroupName,
          description: getChargerGroupForExtend.description,
        });
      } else {
        snackbar.error('Charger Group not found.');
      }
    },
  });

  const [updateChargerGroup] = useUpdateChargerPriceGroupForExtendMutation();

  const [createChargerGroup] = useCreateChargerPriceGroupForExtendMutation();

  const handleSubmit = (formValues: FormValues, { resetForm, setValues }: FormikHelpers<FormValues>) => {
    const handleError = (error: ApolloError, values: React.SetStateAction<FormValues>) => {
      snackbar.error(formatErrorMessage({ message: error?.message, altId }));
      resetForm();
      setValues(values);
    };
    const handleSuccess = (chargerGroupAltId?: string) => {
      snackbar.success('Your Charger Group has been saved.');
      navigate(`/extend-plus/charger-groups/${chargerGroupAltId}/details`);
    };
    if (isNew) {
      createChargerGroup({
        variables: {
          input: formValues,
        },
        onError: (error) => {
          handleError(error, formValues);
        },
        onCompleted: ({ createChargerPriceGroupForExtend: chargerGroup }) => {
          handleSuccess(chargerGroup?.altId);
        },
      });
    } else {
      updateChargerGroup({
        variables: {
          input: { altId: altId || '', ...formValues },
        },
        onError: (error) => {
          handleError(error, formValues);
        },
        onCompleted: ({ updateChargerPriceGroupForExtend: chargerGroup }) => {
          handleSuccess(chargerGroup?.altId);
        },
      });
    }
  };

  return (
    <PageContent
      loading={loading}
      hasFloatingPageActions
      breadcrumbs={
        <Breadcrumbs>
          <BreadcrumbLink data-testid={'charger-groups-list-link'} to="/extend-plus/charger-groups">
            Charger Groups
          </BreadcrumbLink>
          {!isNew && (
            <BreadcrumbLink
              data-testid={'charger-group-details-link'}
              to={`/extend-plus/charger-groups/${altId}/details`}
            >
              {initialValues?.chargerGroupName}
            </BreadcrumbLink>
          )}
          <BreadcrumbText>{isNew ? 'Create' : 'Edit'} Charger Group</BreadcrumbText>
        </Breadcrumbs>
      }
    >
      <Card>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <ChargerGroupForm isNew={isNew} altId={altId} />
        </Formik>
      </Card>
    </PageContent>
  );
};
