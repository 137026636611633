import { ChargerGroupForExtend, ChargerGroupSyncStatus, Day, TariffForExtendSyncStatus } from 'src/@types';
import { ChargerFactory } from './charger';

export const ChargerGroupFactory = () => {
  return {
    total: 6,
    edges: [
      {
        altId: '4d088d84-6fdd-410a-a9f5-ce9c544b4ed9',
        tagName: 'California',
        chargerGroupName: 'California',
        description: '',
        chargers: {
          total: 8,
          edges: [],
        },
        host: {
          altId: '9aacdbcb-6a6b-410a-a9f5-9630053e2168',
        },
      },
      {
        altId: '5a30d64d-6a6b-4a3b-9476-9630053e2168',
        tagName: 'Hell1383s',
        chargerGroupName: 'Hell1383s',
        description: 'Hi!',
        chargers: {
          total: 8,
          edges: [],
        },
        host: {
          altId: '9aacdbcb-6a6b-410a-a9f5-9630053e2168',
        },
      },
      {
        altId: '8c4f7bcb-f4e3-4e78-bc4b-6e11ac47f707',
        tagName: 'Hello$77',
        chargerGroupName: 'Hello$77',
        description: 'Hi!',
        chargers: {
          total: 8,
          edges: [],
        },
        host: {
          altId: '9aacdbcb-6a6b-410a-a9f5-9630053e2168',
        },
      },
      {
        altId: '1786557f-c35f-4bbf-86d8-8666ae1004df',
        tagName: 'Philadelphia',
        chargerGroupName: 'Philadelphia',
        description: 'testing',
        chargers: {
          total: 8,
          edges: [],
        },
        host: {
          altId: '9aacdbcb-6a6b-410a-a9f5-9630053e2168',
        },
      },
      {
        altId: 'ce0e49b9-69ff-4312-93a1-6e4ee0166150',
        tagName: 'Radical',
        chargerGroupName: 'Radical',
        description: 'really cool tag',
        chargers: {
          total: 8,
          edges: [],
        },
        host: {
          altId: '9aacdbcb-6a6b-410a-a9f5-9630053e2168',
        },
      },
      {
        altId: 'f7798352-99b7-414a-b483-0f3be8959bf1',
        tagName: 'test sg',
        chargerGroupName: 'test sg',
        description: 'testing console errors',
        chargers: {
          total: 8,
          edges: [],
        },
        host: {
          altId: '9aacdbcb-6a6b-410a-a9f5-9630053e2168',
        },
      },
    ],
    sort: {
      tagName: 'ASC',
    },
  };
};

export const ChargerGroupWithoutPlanFactoryForExtend = (overrides = {}): ChargerGroupForExtend => ({
  altId: '9aacdbcb-6a6b-410a-a9f5-9630053e2168',
  chargerGroupName: 'Test No Plan',
  description: 'Test Description',
  chargerGroupType: 'Custom Plan',
  chargerGroupTypeId: 402,
  chargers: {
    total: 0,
  },
  host: {
    altId: '9aacdbcb-6a6b-410a-a9f5-9630053e2168',
  },
  planTariffs: [],
  createdAt: '2022-02-02',
  updatedAt: '2022-02-02',
  deletedAt: null,
  status: ChargerGroupSyncStatus.Draft,
  ...overrides,
  chargersLoaded: {
    total: 0,
  },
});

export const ChargerGroupForExtendPlanTariffTagFactory = (): ChargerGroupForExtend['planTariffTags'] => [
  {
    planChargerGroup: {
      altId: 'cad5652c-721a-4c7c-b4d1-633ffcf51869',
      chargerGroupName: 'TH Plan 9005',
      chargers: {},
      chargersLoaded: {},
      host: {},
      planTariffs: [],
      updatedAt: '2023-01-18T23:26:36.775Z',
      chargerGroupType: 'CUSTOM_ACCESS_PLAN',
      chargerGroupTypeId: 0,
      createdAt: '2023-01-18T23:26:36.775Z',
      deletedAt: 'ul',
      description: 'asdfab',
      planDuration: 0,
      planId: '1185',
      status: ChargerGroupSyncStatus.Synced,
      __typename: 'ChargerGroupForExtend',
    },
  },
];

export const DefaultPricingFactory = (overrides = {}) => {
  return {
    displayName: 'Early Bird',
    displayDescription1: 'gets it',
    displayDescription2: '6AM - 7AM',
    displayRank: 1,
    pricing: { altId: '321', perKwh: 0, perMinute: 0, perSession: 0 },
    ...overrides,
  };
};

export const ChargerGroupForExtendTariffFactory = (): ChargerGroupForExtend['tariff'] => ({
  altId: '123',
  tariffDescription: 'Test Tariff',
  tariffName: 'Tariff 1',
  tariffDisplayName: 'Tariff 1 Display',
  tariffRank: null,
  status: TariffForExtendSyncStatus.Active,
  chargerGroups: [{ ...ChargerGroupWithoutPlanFactoryForExtend() }],
  customPricing: [],
  defaultPricing: DefaultPricingFactory(),
  reservationTerm: {
    fee: 0,
    noShowFee: 0,
  },
});

export const ChargerGroupFactoryForExtend = (): ChargerGroupForExtend => ({
  ...ChargerGroupWithoutPlanFactoryForExtend(),
  chargers: {
    total: 1,
    edges: [ChargerFactory()],
  },
  planTariffs: [
    {
      tariffName: 'Tariff 1',
      altId: '9aacdbcb-6a6b-410a-a9f5-9630053e2168',
      chargerGroups: [ChargerGroupWithoutPlanFactoryForExtend()],
      customPricing: [
        {
          altId: '123',
          pricing: {
            perKwh: 0.38,
            perSession: 0,
            perMinute: 0,
          },
          tariffSchedules: [
            {
              startTime: '10:00',
              endTime: '12:00',
              daysOfWeek: [Day.Monday, Day.Thursday],
            },
            {
              startTime: '11:00',
              endTime: '13:00',
              daysOfWeek: [Day.Saturday],
            },
            {
              startTime: '14:00',
              endTime: '18:00',
              daysOfWeek: [Day.Sunday],
            },
          ],
        },
      ],
    },
    {
      tariffName: 'Tariff 1',
      altId: '9aacdbcb-6a6b-410a-a9f5-9630053e2162',
      chargerGroups: [ChargerGroupWithoutPlanFactoryForExtend()],
      customPricing: [
        {
          altId: '123',
          pricing: {
            perKwh: 0.38,
            perSession: 0,
            perMinute: 0,
          },
          tariffSchedules: [
            {
              startTime: '10:00',
              endTime: '12:00',
              daysOfWeek: [Day.Monday, Day.Thursday],
            },
          ],
        },
        {
          altId: '123',
          pricing: {
            perKwh: 0.38,
            perSession: 0,
            perMinute: 0,
          },
          tariffSchedules: [
            {
              startTime: '10:00',
              endTime: '12:00',
              daysOfWeek: [Day.Monday, Day.Thursday],
            },
          ],
        },
      ],
    },
  ],
});
