import { useLazyQuery, useQuery } from '@apollo/client';
import { theme } from '@evgo/react-material-components';
import { Box, Button, colors, Divider, Tab, Typography } from '@material-ui/core';
import {
  ArrowForward,
  Check,
  EvStation,
  OfflineBolt,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
} from '@material-ui/icons';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Query } from 'src/@types';
import { listChargerGroupsForExtend as LIST_CHARGER_GROUPS_QUERY } from 'src/apollo/queries/extendPlus';
import { listHosts as LIST_HOSTS } from 'src/apollo/queries/hosts';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { Card, CardHeader } from 'src/components/shared/Card';
import { Connector, Status } from 'src/components/shared/Connector';
import { CustomPricingInput } from 'src/components/shared/CustomPricingInput';
import { validationSchema } from 'src/components/shared/CustomPricingInput/validation';
import { DateRangeField, DateRangeValue } from 'src/components/shared/DateRangeField';
import { MultiSelectFilter } from 'src/components/shared/MultiSelectFilter';
import { MultiSelectSearchFilter } from 'src/components/shared/MultiSelectSearchFilter';
import { MultiSelectWithChipsInput } from 'src/components/shared/MultiSelectWithChipsInput';
import { PageActions } from 'src/components/shared/PageActions';
import { PageContent } from 'src/components/shared/PageContent';
import { SearchFilterDropdown } from 'src/components/shared/SearchFilterDropdown';
import { Stepper } from 'src/components/shared/Stepper';
import { SubTitle } from 'src/components/shared/SubTitle';
import { Column, Direction, MuiDirection, Table, TableEmpty } from 'src/components/shared/Table';
import { Tooltip } from 'src/components/shared/Tooltip';
import config from 'src/config';
import { useSnackbar } from 'src/lib/hooks';
import { TableComponentFactory, TableData } from 'src/__fixtures__/tableComponent';
import { WeekViewComponentFactory } from 'src/__fixtures__/weekViewComponent';
import { KebabMenu, MenuItem } from '../../shared/KebabMenu';
import { MetricCard } from '../../shared/MetricCard';
import { PageHeader } from '../../shared/PageHeader';
import { PropertyDetail } from '../../shared/PropertyDetail';
import { SearchField } from '../../shared/SearchField';
import { StyledModal } from '../../shared/StyledModal';
import { StyledTabs } from '../../shared/StyledTabs';
import { Title } from '../../shared/Title';
import { BarChart } from '../../shared/UtilizationBarGraph';
import { WeekView } from '../../shared/WeekView';
import ChargerGroupSyncStatusExample from './ChargerGroupSyncStatusExample';
import ExampleViewer from './ExampleViewer';
import ExtendPlusTypography from './ExtendPlusTypography';
import { Styled } from './styles';
import { ThemingColors } from './ThemingColors';

const pageHeaderChildrenLeft = (
  <div className="page-header-children">
    <Typography variant="h6">Mountain View</Typography>
    <PropertyDetail
      title={'Site Address'}
      content={'1835 W Olympic Blvd Los Angeles, CA 90064'}
      contentMaxWidth={190}
    />
    <Box mt={4} mb={2}>
      <Button className="page-header-left-button" variant="outlined">
        See Directions
      </Button>
    </Box>
  </div>
);

const pageHeaderChildrenRight = (
  <div className="page-header-children">
    <Button variant="outlined">Set Pricing</Button>
  </div>
);

const tableData = TableComponentFactory();
const weekViewData = WeekViewComponentFactory();

const TableButton: React.FC<{ data: TableData }> = ({ data }) => {
  return (
    <Button variant="outlined">
      <Typography variant="subtitle1">{data.tagName}</Typography>
    </Button>
  );
};

const tableColumns: Column[] = [
  { key: 'tagName', label: 'Name', sortable: true, width: '25%' },
  { key: 'description', label: 'Description', sortable: true, width: '25%' },
  { key: 'chargers.total', label: '# of Chargers', sortable: true, numeric: true, width: '25%' },
  { key: 'action', label: '', sortable: false, width: '25%', component: TableButton },
];

const barChartData = [
  { label: 'Mon 03/21', value: 275 },
  { label: 'Tue 03/22', value: 180 },
  { label: 'Wed 03/23', value: 230 },
  { label: 'Thu 03/24', value: 220 },
  { label: 'Fri 03/25', value: 320 },
  { label: 'Sat 03/26', value: 290 },
  { label: 'Sun 03/27', value: 275 },
];

export const StyleGuideView: React.FC = () => {
  const [tab, setTab] = React.useState('tab1');
  const [tablePage, setTablePage] = React.useState(0);
  const [tablePageSize, setTablePageSize] = React.useState(5);
  const [tableSortBy, setTableSortBy] = React.useState<string>('tagName');
  const [tableSortDirection, setTableSortDirection] = React.useState<Direction>(Direction.Asc);
  const [tableSelected, setTableSelected] = React.useState<string[]>([]);
  const [searchFilterHostId, setSearchFilterHostId] = React.useState<string>('');
  const [searchFilterHostOptions, setSearchFilterHostOptions] = React.useState<{ value: string; label: string }[]>([]);
  const [searchFilterSearchString, setSearchFilterSearchString] = React.useState<string>('');
  const snackbar = useSnackbar();

  const [listHostsLazyQuery, { data: listHostsLazyQueryData }] = useLazyQuery<Query>(LIST_HOSTS, {});
  const { data: chargerGroupsData, loading: chargerGroupsLoading } = useQuery<Query>(LIST_CHARGER_GROUPS_QUERY, {
    variables: {
      input: {
        pageSize: config.maxPageSize,
        filter: {
          chargerGroupTypeId: {
            eq: 401,
          },
        },
      },
    },
  });

  const chargerGroups = chargerGroupsData?.listChargerGroupsForExtend?.edges || [];
  const chargerGroupOptions = chargerGroups.map((group) => ({
    value: group.altId,
    label: group.chargerGroupName,
    secondaryValue: `${group.chargers?.total || 0}`,
  }));

  useEffect(() => {
    if (listHostsLazyQueryData?.listHosts?.edges && searchFilterSearchString) {
      const newHostOptions = listHostsLazyQueryData?.listHosts?.edges.map((host) => ({
        label: host?.hostName || '',
        value: host?.altId || '',
      }));
      setSearchFilterHostOptions(newHostOptions);
    }
    if (!searchFilterSearchString && searchFilterHostOptions.length) {
      setSearchFilterHostOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listHostsLazyQueryData, searchFilterSearchString]);

  const handleChangeTab = (event: React.ChangeEvent<unknown>, newTab: string) => {
    setTab(newTab);
  };
  const [modalOpen, setModalOpen] = useState(false);

  const [dateRange, setDateRange] = useState<DateRangeValue>();

  const [multiSelectSearchFilterValue, setMultiSelectSearchFilterValue] = useState(['CLOSED', 'ACTIVE']);

  return (
    <Styled className="style-guide-view-component">
      <h1>Component Style Guide</h1>
      <ExtendPlusTypography />
      <ExampleViewer title="Theming colors">
        <ThemingColors />
      </ExampleViewer>
      <ExampleViewer title="PropertyDetail">
        <Box mb={2}>
          <Typography>Includes copy to clipboard button:</Typography>
        </Box>
        <PropertyDetail
          title={'Site Address'}
          content={'1835 W Olympic Blvd Los Angeles, CA 90064'}
          contentMaxWidth={190}
        />
        <Box mt={4} mb={2}>
          <Typography>Does not includes copy to clipboard button:</Typography>
        </Box>
        <PropertyDetail
          title={'Site Address'}
          content={'1835 W Olympic Blvd Los Angeles, CA 90064'}
          contentMaxWidth={190}
          allowCopyToClipboard={false}
        />
      </ExampleViewer>
      <ExampleViewer title="PageContent">
        <div className="page-content-component">
          <PageContent
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbLink data-testid="home-link" to="/styleguide">
                  First Breadcrumb
                </BreadcrumbLink>
                <BreadcrumbLink data-testid="home-link" to="/styleguide">
                  Second Breadcrumb
                </BreadcrumbLink>
                <BreadcrumbText>Final Breadcrumb</BreadcrumbText>
              </Breadcrumbs>
            }
            pageHeader={
              <PageHeader
                childrenLeft={
                  <>
                    <Title>Page title</Title>
                    <SubTitle>Page subtitle</SubTitle>
                  </>
                }
              />
            }
          >
            <Card>
              <div>Page content</div>
            </Card>
          </PageContent>
        </div>
      </ExampleViewer>
      <ExampleViewer title="PageHeader">
        <div className="page-header-wrapper">
          <Box mb={2}>
            <Typography>Children on Left:</Typography>
          </Box>
          <PageHeader childrenLeft={pageHeaderChildrenLeft} />
          <Box mt={4} mb={2}>
            <Typography>Children on Right:</Typography>
          </Box>
          <PageHeader childrenRight={pageHeaderChildrenRight} />
          <Box mt={4} mb={2}>
            <Typography>Children on Left and Right:</Typography>
          </Box>
          <PageHeader childrenLeft={pageHeaderChildrenLeft} childrenRight={pageHeaderChildrenRight} />
        </div>
      </ExampleViewer>
      <ExampleViewer title="StyledTabs">
        <Box mb={2}>
          <Typography>Styled Tabs:</Typography>
        </Box>
        <StyledTabs tab={tab} handleChangeTab={handleChangeTab}>
          <Tab label="Tab 1" value="tab1"></Tab>
          <Tab label="Tab 2" value="tab2"></Tab>
          <Tab label="Tab 3" value="tab3"></Tab>
        </StyledTabs>
      </ExampleViewer>
      <ExampleViewer title="SearchField">
        <SearchField />
      </ExampleViewer>
      <ExampleViewer title="StyledModal">
        <Box mb={2}>
          <Typography>Styled Modal with subtitle and secondary button:</Typography>
        </Box>
        <Button color="secondary" onClick={() => setModalOpen(true)}>
          Open Modal
        </Button>
        <StyledModal
          open={modalOpen}
          title={'Styled Modal'}
          subtitle={'with a subtitle'}
          onClick={() => setModalOpen(false)}
          onClose={() => setModalOpen(false)}
          secondaryButtonClick={() => setModalOpen(false)}
          secondaryButtonText="Closeeee!"
        >
          And some content
        </StyledModal>
      </ExampleViewer>
      <ExampleViewer title="Card">
        <Card>This is a card component</Card>

        <Box mb={2} />

        <Card py={5} px={5}>
          This is a card component with custom padding
        </Card>

        <Box mb={2} />

        <Card>
          <CardHeader title="Charger Utilization" />
          This is a card with a title.
        </Card>

        <Box mb={2} />

        <Card>
          <CardHeader title="Charger Utilization" subtitle="Mountain View" />
          This is a card with a title and subtitle.
        </Card>
      </ExampleViewer>
      <ExampleViewer title="MetricCard">
        <MetricCard
          icon={
            <div style={{ color: colors.green[600] }}>
              <TrendingUp />
            </div>
          }
          primaryText="89.12"
          secondaryText="kWh"
          tertiaryText="35% kWh increase"
          tertiaryFeedbackText=" in the past 30 days versus the previous 30 days"
          label="Monthly Utilization"
        />
        <MetricCard
          icon={<TrendingDown />}
          theming={{ iconColor: colors.red[600] }}
          primaryText="$89.12"
          tertiaryText="-$18 decrease"
          tertiaryFeedbackText=" in the past 30 days versus the previous 30 days"
          label="Monthly Income"
        />
        <MetricCard
          icon={<TrendingFlat />}
          primaryText="$45.21"
          label="Monthly Income"
          tertiaryText="No change"
          tertiaryFeedbackText=" in the past 30 days versus the previous 30 days"
        />
        <MetricCard icon={<OfflineBolt />} primaryText="4" secondaryText="out of 12" label="chargers in use" />
        <MetricCard icon={<EvStation />} primaryText="12" secondaryText="out of 14" label="chargers online" />
        <MetricCard
          primaryText="0"
          secondaryText="Unassigned Chargers"
          label={
            <>
              <Check />
              <span>ALL CHARGERS HAVE A PRICE</span>
            </>
          }
          theming={{ primaryTextColor: theme.palette.text.primary, labelTextColor: colors.green[600] }}
        />
        <MetricCard
          primaryText="3"
          secondaryText="Unassigned Chargers"
          label={
            <>
              <span>ASSIGN A PRICE</span>
              <ArrowForward />
            </>
          }
          theming={{ primaryTextColor: colors.red[600], labelTextColor: theme.palette.text.primary }}
        />
        <MetricCard primaryText="Primary Text Only" label="Example without icon" />
      </ExampleViewer>

      <ExampleViewer title="Title & Subtitle">
        <Title>This is a title component</Title>
        <SubTitle>This is a subtitle component</SubTitle>
      </ExampleViewer>
      <ExampleViewer title="Utilization Bar Graph">
        <BarChart
          captionText={'Total consumption by day'}
          data={barChartData}
          color={'#3F657C'}
          height={400}
          className={'util-bar'}
          id={'util-bar-1'}
          data-testid={'util-bar-test-id'}
        />
      </ExampleViewer>
      <ExampleViewer title="KebabMenu">
        <KebabMenu>
          {({ close }) => (
            <>
              <MenuItem onClick={close}>Profile</MenuItem>
              <MenuItem onClick={close}>My account</MenuItem>
              <MenuItem onClick={close}>Logout</MenuItem>
            </>
          )}
        </KebabMenu>
      </ExampleViewer>
      <ExampleViewer title="Connector">
        <Connector
          status={Status.Charging}
          utilizationMeasure="89%"
          connectorType="CCS"
          evseId="1234567890"
          cableLength="20 ft."
          connectorMaxCurrent="120 A"
          connectorVoltageRange="200-500 V"
        />
      </ExampleViewer>
      <ExampleViewer title="Snackbar">
        <Button
          onClick={() => {
            snackbar.success('This is a success snackbar.');
          }}
        >
          Open Success Snackbar
        </Button>
        <Button
          onClick={() => {
            snackbar.error('This is an error snackbar.');
          }}
        >
          Open Error Snackbar
        </Button>
        <Button
          onClick={() => {
            snackbar.warning('This is a warning snackbar.');
          }}
        >
          Open Warning Snackbar
        </Button>
        <Button
          onClick={() => {
            snackbar.info('This is an info snackbar.');
          }}
        >
          Open Info Snackbar
        </Button>
        <Button
          onClick={() => {
            snackbar.system('This is a system snackbar.', { buttonText: 'Custom Text' });
          }}
        >
          Open System Snackbar
        </Button>
      </ExampleViewer>

      <ExampleViewer title="Stepper">
        <Stepper steps={['Step 1', 'Step 2', 'Step 3']} />
      </ExampleViewer>

      <ExampleViewer title="EmptyTable">
        <Typography variant="h6">Default message</Typography>

        <table>
          <TableEmpty />
        </table>

        <Typography variant="h6">Custom message</Typography>

        <table>
          <TableEmpty>Not Found!</TableEmpty>
        </table>

        <Typography variant="h6">Custom component</Typography>

        <table>
          <TableEmpty>
            <>
              <TrendingDown />
              <Box display="inline" px={2}>
                Message
              </Box>
            </>
          </TableEmpty>
        </table>
      </ExampleViewer>

      <ExampleViewer title="Breadcrumbs">
        <Breadcrumbs>
          <BreadcrumbLink data-testid="home-link" to="/styleguide">
            First Breadcrumb
          </BreadcrumbLink>
          <BreadcrumbLink data-testid="home-link" to="/styleguide">
            Second Breadcrumb
          </BreadcrumbLink>
          <BreadcrumbText>Final Breadcrumb</BreadcrumbText>
        </Breadcrumbs>
      </ExampleViewer>

      <ExampleViewer title="MultiSelectFilter">
        <MultiSelectFilter
          label="Filter Controlled"
          options={[
            { label: 'Active', value: 'ACTIVE' },
            { label: 'Closed', value: 'CLOSED' },
            { label: 'Registered', value: 'REGISTERED' },
            { label: 'Suspended', value: 'SUSPENDED' },
          ]}
          onChange={(selected) => alert('You selected: ' + JSON.stringify(selected))}
          initialValue={['CLOSED', 'ACTIVE']}
          debounce={500}
        />
      </ExampleViewer>

      <ExampleViewer title="MultiSelectSearchFilter">
        <MultiSelectSearchFilter
          label="Filter Search Controlled"
          options={[
            { label: 'Active', value: 'ACTIVE' },
            { label: 'Closed', value: 'CLOSED' },
            { label: 'Registered', value: 'REGISTERED' },
            { label: 'Suspended', value: 'SUSPENDED' },
          ]}
          onChange={(value) => setMultiSelectSearchFilterValue(value)}
          value={multiSelectSearchFilterValue}
          debounce={500}
        />
      </ExampleViewer>

      <ExampleViewer title="Table">
        <Typography variant="h6">Table with FrontEnd Sorting and Custom Component</Typography>
        <Table
          data={tableData}
          loading={false}
          error={undefined}
          columns={tableColumns}
          sorting={{ sortBy: 'tagName', sortDirection: Direction.Asc }}
        />
        <Typography variant="h6">Table with Checkboxes, Backend sorting and pagination</Typography>
        <Table
          data={_.orderBy(
            tableData,
            [
              (row) =>
                tableColumns.find((column) => column.key === tableSortBy)?.numeric
                  ? _.get(row, tableSortBy)
                  : _.get(row, tableSortBy).toString().toLowerCase(),
            ],
            MuiDirection[tableSortDirection],
          ).slice(tablePage * tablePageSize, tablePage * tablePageSize + tablePageSize)}
          loading={false}
          error={undefined}
          columns={tableColumns}
          sorting={{
            sortBy: tableSortBy,
            sortDirection: tableSortDirection,
            onSortByChange: setTableSortBy,
            onSortDirectionChange: setTableSortDirection,
          }}
          pagination={{
            pageSize: tablePageSize,
            page: tablePage,
            total: tableData.length,
            rowsPerPageOptions: [5, 10, 20],
            onPageChange: setTablePage,
            onPageSizeChange: setTablePageSize,
          }}
          selectRows={{
            id: 'altId',
            onSelectChange: setTableSelected,
            values: tableSelected,
          }}
        />
      </ExampleViewer>

      <ExampleViewer title="ExpandableTableRows">
        <Typography variant="h6">Expandable Table Rows</Typography>

        <Table data={tableData} loading={false} error={undefined} columns={tableColumns}>
          <Box display="inline" px={2}>
            <Card py={5} px={5}>
              A card inside a table row
            </Card>
          </Box>
        </Table>
      </ExampleViewer>

      <ExampleViewer title="PageActions">
        <PageActions sticky={false} message="Custom message">
          <Button color="secondary">Cancel</Button>
          <Button variant="contained" color="secondary">
            Save
          </Button>
        </PageActions>
      </ExampleViewer>

      <ExampleViewer title="Tooltip">
        <Tooltip content={'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'} />
      </ExampleViewer>

      <ExampleViewer title="DateRangeField">
        <DateRangeField placeholder="Select date range" value={dateRange} onChange={setDateRange} />
      </ExampleViewer>

      <ExampleViewer title="Form Inputs">
        <Formik
          initialValues={{
            customPricing: { tariffSchedules: [{ daysOfWeek: [], startTime: '', endTime: '' }] },
          }}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          {({ values }) => (
            <Form>
              <Typography paragraph>CustomPricingInput</Typography>
              <CustomPricingInput name="customPricing" />

              <Box my={3}>
                <Divider />
              </Box>

              <Typography paragraph>MultiSelectWithChipsInput</Typography>
              <MultiSelectWithChipsInput
                label="Select Charger Groups"
                name="chargerPriceIds"
                loading={chargerGroupsLoading}
                options={chargerGroupOptions}
              />

              <Box my={3}>
                <Divider />
              </Box>

              <Typography paragraph>values: {JSON.stringify(values)}</Typography>
            </Form>
          )}
        </Formik>
      </ExampleViewer>

      <ExampleViewer title="Week View">
        <WeekView
          title="Calendar view of prices"
          subTitle="This is a 24hs/7day week view of your charger prices. To adjust these prices, go to the Custom Price Details tab."
          data={weekViewData}
          loading={false}
        />
      </ExampleViewer>

      <ExampleViewer title="Search Filter Dropdown">
        <SearchFilterDropdown
          label="Select an existing host*"
          value={searchFilterHostId as string}
          options={searchFilterHostOptions}
          onChange={(hostId) => setSearchFilterHostId(hostId)}
          onSearchChange={(hostName) => {
            setSearchFilterSearchString(hostName);
            if (hostName) {
              listHostsLazyQuery({
                variables: {
                  hostsInput: {
                    page: 0,
                    pageSize: config.maxPageSize,
                    search: {
                      hostName: { iLike: `%${hostName}%` },
                    },
                  },
                },
              });
            }
          }}
          debounce={500}
        />
      </ExampleViewer>
      <ChargerGroupSyncStatusExample />
    </Styled>
  );
};
