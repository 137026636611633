import { gql } from '@apollo/client';
import { ChargerGroupForExtend, ChargersWithMeta, PlanTariffForExtend, TariffForExtend } from './fragments';

export const getChargerGroupForExtend = gql`
  query getChargerGroupForExtend($input: GetChargerGroupForExtendInput!) {
    getChargerGroupForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtend}
  ${TariffForExtend}
  ${PlanTariffForExtend}
  ${ChargersWithMeta}
`;
