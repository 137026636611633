import {
  Assessment,
  Dashboard as DashboardIcon,
  EvStation,
  BubbleChart,
  HelpOutline as HelpOutlineIcon,
  Layers,
  MonetizationOn,
} from '@material-ui/icons';

import { DrawerContent } from './DrawerContent';

import { startsWithOneOf } from './helpers';
import { events } from 'src/lib/utils/analytics-events';
import { NavigationItem } from './types';

type PartnerAdminNavItemProps = {
  handleDrawerToggle?: () => void;
};

export function PartnerAdminNavItems({ handleDrawerToggle }: PartnerAdminNavItemProps) {
  const children: NavigationItem[] = [
    {
      name: 'Dashboard',
      to: '/extend-plus/dashboard',
      icon: <DashboardIcon />,
      analyticsEventName: events.primaryNav.CLICKED_DASHBOARD,
    },
    {
      name: 'Sites',
      to: '/extend-plus/sites',
      icon: <Layers />,
      isActive: (path: string) => startsWithOneOf(path, ['/extend-plus/sites']),
      analyticsEventName: events.primaryNav.CLICKED_SITES,
    },
    {
      name: 'Chargers',
      to: '/extend-plus/chargers',
      icon: <EvStation />,
      isActive: (path: string) => startsWithOneOf(path, ['/extend-plus/chargers']),
      analyticsEventName: events.primaryNav.CLICKED_CHARGERS,
    },
    {
      name: 'Charger Groups',
      to: '/extend-plus/charger-groups',
      icon: <BubbleChart />,
      isActive: (path: string) => startsWithOneOf(path, ['/extend-plus/charger-groups']),
      analyticsEventName: events.primaryNav.CLICKED_CHARGER_GROUPS,
    },
    {
      name: 'Pricing',
      icon: <MonetizationOn />,
      subItems: [
        {
          name: 'Charger Prices',
          to: `/extend-plus/charger-prices`,
          analyticsEventName: events.primaryNav.CLICKED_CHARGER_PRICES,
        },
        {
          name: 'Custom Plan Prices',
          to: '/extend-plus/custom-plans',
          analyticsEventName: events.primaryNav.CLICKED_CUSTOM_PLAN_PRICES,
        },
      ],
    },
    {
      name: 'Reports',
      to: '/extend-plus/reports',
      icon: <Assessment />,
      analyticsEventName: events.primaryNav.CLICKED_REPORTS,
    },
    {
      name: 'Help & Support',
      to: '/extend-plus/help',
      icon: <HelpOutlineIcon />,
      analyticsEventName: events.primaryNav.CLICKED_HELP_SUPPORT,
      isolated: true,
    },
  ];

  return <DrawerContent children={children} handleDrawerToggle={handleDrawerToggle} />;
}
