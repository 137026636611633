import { Box, Button } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TableLink } from 'src/components/shared/TableLink';
import { TariffCustomPricingForExtend } from 'src/@types';
import {
  transformTariffCustomPricingForExtend,
  transformTariffDefaultPricingForExtend,
} from 'src/lib/helpers/transformTariffPricingForExtend';
import { GetChargerGroupForExtendQuery } from 'src/@types';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { DefaultPriceSection } from 'src/components/views/ExtendPlusView/ChargerPrices/Details/DefaultPriceSection';
import { DescriptionSection } from './DescriptionSection';
import { TimeSpecificPriceSection } from './TimeSpecificPriceSection';
import { transformReservationCustomPricingForExtend } from 'src/lib/helpers/transformReservationPricingForExtend';
import { ReservationPriceSection } from './ReservationPriceSection';
import { Title } from 'src/components/shared/Title';
import { PageHeader } from 'src/components/shared/PageHeader';
import { events } from 'src/lib/utils/analytics-events';
import { Card } from 'src/components/shared/Card';

type Props = {
  data: GetChargerGroupForExtendQuery['getChargerGroupForExtend'];
};

export const ChargerGroupPricesView: React.FC<Props> = ({ data }) => {
  const id = 'charger-group-prices-view';
  const navigate = useNavigate();
  const { track } = useAnalytics();

  return (
    <>
      <PageHeader
        childrenLeft={
          <Box mt={4}>
            <Title>Prices</Title>
          </Box>
        }
        childrenRight={
          data?.tariff?.altId && (
            <Box mt={4}>
              <Button
                data-testid={`${id}-edit-btn"`}
                color="secondary"
                variant="outlined"
                onClick={() => {
                  track(events.chargerGroupPricesTab.CLICKED_EDIT_PRICES_BUTTON);
                  navigate(`/extend-plus/charger-prices/${data?.tariff?.altId}/pricing`);
                }}
              >
                Edit
              </Button>
            </Box>
          )
        }
      />
      {data?.tariff ? (
        <Box mt={4}>
          <DescriptionSection description={data?.tariff?.tariffDescription} />
          <DefaultPriceSection
            pricing={transformTariffDefaultPricingForExtend(data.tariff?.defaultPricing)}
            showEditButton={false}
          />
          <TimeSpecificPriceSection
            customPricing={data.tariff?.customPricing?.map((customPricing: TariffCustomPricingForExtend) =>
              transformTariffCustomPricingForExtend(customPricing),
            )}
          />
          <ReservationPriceSection
            pricing={
              data.tariff?.reservationTerm
                ? transformReservationCustomPricingForExtend(data.tariff?.reservationTerm)
                : undefined
            }
          />
        </Box>
      ) : (
        <Box mt={4}>
          <Card>
            <p>No prices available</p>
            <br />
            <TableLink
              to={'/extend-plus/help/how-do-i-create-prices-for-my-chargers'}
              onClick={() => track(events.chargerPricesList.CLICKED_CHARGER_PRICE_LINK)}
            >
              Learn more
            </TableLink>{' '}
            or create charger prices <TableLink to={'/extend-plus/charger-prices'}>here</TableLink>
          </Card>
        </Box>
      )}
    </>
  );
};
