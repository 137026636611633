import { Box, CircularProgress } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useUpdateChargerGroupPlanForExtendMutation, useGetChargerGroupForExtendQuery } from 'src/@types';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { Card } from 'src/components/shared/Card';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { Title } from 'src/components/shared/Title';
import { useSnackbar } from 'src/lib/hooks';
import { CustomPlanPriceForm, FormValues } from './CustomPlanPriceForm';
import { validationSchema } from './CustomPlanPriceForm/validations';
import { CustomPlanPriceStepper, Step } from './CustomPlanPriceStepper';

export const CustomPlansEditView: React.FC = () => {
  const id = 'custom-plan-price-details-plan-description';
  const snackbar = useSnackbar();
  const { altId = '' } = useParams<{ altId: string }>();

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const isNew = Boolean(queryParams.get('new'));

  const [initialValues, setInitialValues] = useState<FormValues>({
    chargerGroupName: '',
    description: '',
    hasDuration: false,
    planDuration: 0,
  });

  const { loading } = useGetChargerGroupForExtendQuery({
    variables: { input: { altId, showDeleted: true } },
    fetchPolicy: 'cache-and-network',
    onError: (error) => snackbar.error(error?.message || 'Something went wrong.'),
    onCompleted: (data) => {
      if (data.getChargerGroupForExtend) {
        setInitialValues({
          chargerGroupName: data.getChargerGroupForExtend.chargerGroupName,
          description: data.getChargerGroupForExtend.description || '',
          hasDuration: Boolean(data.getChargerGroupForExtend.planDuration),
          planDuration: data.getChargerGroupForExtend.planDuration || 0,
        });
      } else {
        snackbar.error('Charger pricing not found.');
      }
    },
  });

  const [updateCustomPlan] = useUpdateChargerGroupPlanForExtendMutation();

  const handleSubmit = (formValues: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    const { hasDuration, ...values } = formValues;
    if (!hasDuration) values.planDuration = 0;

    updateCustomPlan({
      variables: {
        input: {
          altId,
          ...values,
        },
      },
      onError: (error) => {
        const message = error?.message.includes('plan with same name already exists')
          ? 'Sorry, this custom plan name already exists. Please provide a unique name.'
          : 'Sorry, we were unable to update your custom plan';

        snackbar.error(message);
        resetForm({ values: formValues });
      },
      onCompleted: () => {
        snackbar.success('Your Custom Plan has been saved.');
        navigate(`/extend-plus/custom-plans/${isNew ? `${altId}/pricing?new=true` : `${altId}/details`}`);
      },
    });
  };

  return (
    <PageContent
      hasFloatingPageActions
      breadcrumbs={
        !isNew && (
          <Breadcrumbs>
            <BreadcrumbLink data-testid={`${id}-custom-plans-list-link`} to="/extend-plus/custom-plans">
              Custom Plan Prices
            </BreadcrumbLink>
            {initialValues.chargerGroupName && (
              <Breadcrumbs>
                <BreadcrumbLink
                  data-testid={`${id}-custom-plan-group-details-link`}
                  to={`/extend-plus/custom-plans/${altId}/details`}
                >
                  {initialValues.chargerGroupName}
                </BreadcrumbLink>
                <BreadcrumbText data-testid={`${id}-plan-details-text-breadcrumb`}>
                  Plan Price Description
                </BreadcrumbText>
              </Breadcrumbs>
            )}
          </Breadcrumbs>
        )
      }
      pageHeader={
        <PageHeader
          className="page-header"
          childrenLeft={
            <Box className="page-header-children">
              <Title>Custom Plan Details</Title>
            </Box>
          }
        />
      }
    >
      {isNew && <CustomPlanPriceStepper currentStep={Step.Details} />}

      <Card>
        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <CustomPlanPriceForm isNew={isNew} altId={altId} />
          </Formik>
        )}
      </Card>
    </PageContent>
  );
};
