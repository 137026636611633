import { theme } from '@evgo/react-material-components';
import { Box, Typography } from '@material-ui/core';
import { ReportProblem as IconReportProblem } from '@material-ui/icons';
import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { GetChargerGroupForExtendQuery } from 'src/@types';
import transformPlanTariffForExtend from 'src/lib/helpers/transformPlanTariffForExtend';
import { events } from 'src/lib/utils/analytics-events';
import { fieldName, PricingForm, TariffCustomPricingFormValues } from '../../Pricing/PricingForm';
import { EditSection } from '../EditSection';
import { Styled } from './styles';

export interface Props {
  planTariffs?: NonNullable<GetChargerGroupForExtendQuery['getChargerGroupForExtend']>['planTariffs'];
  disabled?: boolean;
}

export const PricingBreakdownSection: React.FC<Props> = (props) => {
  const params = useParams();

  const initialPlanTariffs: TariffCustomPricingFormValues = {
    [fieldName]: transformPlanTariffForExtend(props.planTariffs || []),
  };

  return (
    <Styled>
      {Boolean(initialPlanTariffs[fieldName]?.length) ? (
        <Formik initialValues={initialPlanTariffs} onSubmit={() => {}}>
          <PricingForm id="custom-plan-pricing" readonly disabled={props.disabled} />
        </Formik>
      ) : (
        <EditSection
          disabled={props.disabled}
          title="Pricing 1"
          route={`/extend-plus/custom-plans/${params.altId}/pricing`}
          trackEvent={events.customPlanSummary.CLICKED_EDIT_PRICING_GROUP_BUTTON}
        >
          <Box
            display="flex"
            width="fit-content"
            flexDirection="row"
            alignItems="center"
            bgcolor={theme.palette.grey['200']}
            padding="8px"
          >
            <Box mr="10px">
              <IconReportProblem />
            </Box>
            <Typography>No prices defined. Please add pricing to this plan.</Typography>
          </Box>
        </EditSection>
      )}
    </Styled>
  );
};
