import { Button, CircularProgress } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import {
  ChargerGroupForExtend,
  useGetChargerGroupsForChargerPriceLazyQuery,
  useDeleteTariffForExtendMutation,
} from 'src/@types';
import { useConfirmationDialog, useSnackbar } from 'src/lib/hooks';

type Props = {
  altId: ChargerGroupForExtend['altId'];
};

export const DeleteChargerPriceButton: React.FC<Props> = ({ altId }) => {
  const navigate = useNavigate();
  const confirmationDialog = useConfirmationDialog();
  const snackBar = useSnackbar();
  const [getChargerGroupsForChargerPrice, { data, loading }] = useGetChargerGroupsForChargerPriceLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [deleteChargerPriceGroupForExtend] = useDeleteTariffForExtendMutation({
    onCompleted: () => {
      if (data?.getTariffForExtend?.chargerGroups?.length) {
        snackBar.success('Your price has been deleted and all charger groups have been unassigned.');
      } else {
        snackBar.success('Your price has been deleted.');
      }
      navigate('/extend-plus/charger-prices', { replace: true });
    },
    onError: () => {
      snackBar.error('Failed to delete Charger Price');
    },
  });

  const openConFirmationDialog = () => {
    getChargerGroupsForChargerPrice({
      variables: {
        input: { altId },
      },
    }).then((res) => {
      const { chargerGroups } = res?.data?.getTariffForExtend || { chargerGroups: [] };
      confirmationDialog.open({
        title: 'Delete Charger Price?',
        body2: () => {
          return loading ? (
            <CircularProgress size={20} className="loading" />
          ) : chargerGroups?.length ? (
            <>
              <p>Looks like this price has the following associated charger group(s):</p>
              {chargerGroups.map((group) => (
                <li>
                  <Link
                    style={{ color: '#0073e4' }}
                    to={`/extend-plus/charger-groups/${group?.altId}/details`}
                    onClick={() => confirmationDialog.close()}
                  >
                    {group?.chargerGroupName}
                  </Link>
                </li>
              ))}
              <br />
              <p>
                Once deleted, all charger groups assigned to this price will need to be re-assigned to another charger
                price.
              </p>
            </>
          ) : (
            <p>Are you sure you want to delete this charger price?</p>
          );
        },
        buttonConfirmText: 'Delete',
        callback: () => {
          deleteChargerPriceGroupForExtend({ variables: { input: { altId } } });
        },
      });
    });
  };

  return (
    <Button
      data-testid="delete-charger-price-btn"
      color="secondary"
      variant="outlined"
      startIcon={<Delete />}
      onClick={openConFirmationDialog}
    >
      Delete Prices
    </Button>
  );
};
