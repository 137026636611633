import { theme } from '@evgo/react-material-components';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  ChargerV2,
  useGetChargerGroupForExtendQuery,
  useAddChargersToChargerGroupForExtendMutation,
  useRemoveChargersFromChargerGroupForExtendMutation,
} from 'src/@types';
import { getChargerGroupForExtend as GET_CHARGER_GROUP_QUERY } from 'src/apollo/queries/extendPlus';
import { Card } from 'src/components/shared/Card';
import { Column, Table } from 'src/components/shared/Table';
import { useSnackbar } from 'src/lib/hooks';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';
import { ChargerSelectionModal } from './ChargerSelectionModal';
import { RemoveChargerButton } from './RemoveChargerButton';
import { Check } from '@material-ui/icons';
import { SearchField } from 'src/components/shared/SearchField';
import { Filters, runFilters } from './filterHelpers';
import { TableLink } from 'src/components/shared/TableLink';
import ChargerV2SyncStatus from 'src/components/shared/ChargerSyncStatus';

type Params = {
  altId: string;
};

const defaultFilters = {
  search: '',
};

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(4)}px;
`;

const TableActions = styled.div`
  margin: ${theme.spacing(3)}px 0px;
`;

type ColumnDataProps = {
  data: ChargerV2;
};

const ChargerName: React.FC<ColumnDataProps> = ({ data }) => {
  const to = `/extend-plus/chargers/${data?.altId}/profile`;
  return <TableLink to={to}>{data.chargerName}</TableLink>;
};

const columns: Column[] = [
  { key: 'chargerName', label: 'Charger Name', sortable: true, width: '20%', component: ChargerName },
  { key: 'chargerModel.modelName', label: 'Model', sortable: true, width: '20%' },
  { key: 'chargerStatus', label: 'Connector Status', sortable: true, width: '15%' },
  { key: 'site.siteName', label: 'Site', sortable: true, width: '15%' },
  {
    key: 'reservable',
    label: 'Reservable',
    component: ({ data: { reservable } }) => (reservable ? <Check /> : null),
    sortable: true,
    width: '10%',
  },
  {
    key: 'chargerSyncStatus',
    label: 'Charger Status',
    sortable: false,
    width: '15%',
    component: ChargerV2SyncStatus,
  },
  { key: 'remove', label: '', sortable: false, width: '10%', align: 'right', component: RemoveChargerButton },
];

export const ChargerGroupChargerView: React.FC = () => {
  const className = 'ChargerListView';
  const { altId } = useParams<Params>();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();

  const [filters, setFilters] = useState<Partial<Filters>>(defaultFilters);
  const [openModal, setOpenModal] = useState(false);

  const { data, loading, error } = useGetChargerGroupForExtendQuery({
    variables: {
      input: { altId: altId || '' },
    },
    fetchPolicy: 'cache-and-network',
  });

  const chargers = data?.getChargerGroupForExtend?.chargers?.edges || [];

  const id = 'charger-group-details-charger-list';

  const [removeCharger, { loading: removeLoading }] = useRemoveChargersFromChargerGroupForExtendMutation({
    onCompleted: () => {
      snackbar.system('Charger has been removed from your list', { buttonText: 'CLOSE' });
    },
    onError: (err: { message: string }) => {
      if (err?.message.includes('404')) {
        snackbar.error(
          'Failed to remove charger from charger group because the charger group does not exist on Driivz.',
        );
        return;
      }
      snackbar.error('Failed to remove charger from charger group');
    },
    refetchQueries: [
      {
        query: GET_CHARGER_GROUP_QUERY,
        variables: {
          input: { altId },
        },
      },
    ],
  });

  const [addChargersToChargerGroupForExtend, { loading: updateLoading }] =
    useAddChargersToChargerGroupForExtendMutation({
      onCompleted: () => {
        snackbar.system('Chargers have been added to your list', { buttonText: 'CLOSE' });
      },
      onError: (err: { message: string }) => {
        if (err?.message.includes('404')) {
          snackbar.error(
            'Failed to add charger(s) to charger group because the charger group does not exist on Driivz.',
          );
          return;
        }
        snackbar.error('Failed to add charger(s) to charger group.');
      },
      refetchQueries: () => [
        {
          query: GET_CHARGER_GROUP_QUERY,
          variables: {
            input: { altId },
          },
        },
      ],
    });

  if (updateLoading || removeLoading) {
    return (
      <Box m="auto">
        <CircularProgress data-testid="loading-indicator" />
      </Box>
    );
  }

  return (
    <StyledCard>
      <Typography paragraph>
        # of chargers in list:{' '}
        {loading ? <CircularProgress size={theme.spacing(2)} /> : data?.getChargerGroupForExtend?.chargers?.total}
      </Typography>

      <TableActions>
        <SearchField
          initialValue={filters.search}
          onChange={(value) => {
            setFilters({ ...filters, search: value });
            track(events.chargerGroupChargersList.SEARCHED_CHARGER_LIST);
          }}
        />
        <Button
          data-testid="add-chargers-btn"
          variant="contained"
          color="secondary"
          style={{ marginLeft: `${theme.spacing(3)}px` }}
          onClick={() => {
            setOpenModal(true);
            track(events.chargerGroupChargersList.CLICKED_ADD_CHARGERS_BUTTON);
          }}
        >
          Add Chargers
        </Button>
      </TableActions>

      {openModal && (
        <ChargerSelectionModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          chargerGroupAltId={altId}
          addChargers={addChargersToChargerGroupForExtend}
        />
      )}
      <Table
        className={className}
        data={runFilters(filters, (chargers as ChargerV2[]) || [])}
        loading={loading}
        error={error}
        columns={columns}
        parentData={{ altId }}
        componentMutation={removeCharger}
        noDataMessage="You don't have any chargers added to this group"
        data-testid={id}
      />
    </StyledCard>
  );
};
