import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
// import AccountsView from '../../views/AccountsView';
import { AccountView } from 'src/components/views/AccountView';
import { AccountCreate } from 'src/components/views/AccountView/AccountCreate';
import { LoginView } from 'src/components/views/auth/LoginView';
import { AuthenticatorsView } from 'src/components/views/AuthenticatorsView';
import { ChargerModelsView } from 'src/components/views/ChargerModels/ChargerModelsView';
import { ChargerModelView } from 'src/components/views/ChargerModels/ChargerModelView';
import { ChargersView } from 'src/components/views/ChargersView';
import { ChargerView } from 'src/components/views/ChargerView';
import { DriversView } from 'src/components/views/DriversView';
import { DriverView } from 'src/components/views/DriverView';
import { ErrorView } from 'src/components/views/ErrorView';
import { HostManagementView } from 'src/components/views/ExtendPlusView/Admin/HostManagement';
import { UserManagementView } from 'src/components/views/ExtendPlusView/Admin/UserManagement';
import {
  ChargerPricesDetailsView,
  ChargerPriceView,
  ChargerPricesListView,
  ChargerPricesPricingView,
} from 'src/components/views/ExtendPlusView/ChargerPrices';
import {
  ChargerGroupsListView,
  ChargerGroupDetailsView,
  ChargerGroupDescriptionView,
} from 'src/components/views/ExtendPlusView/ChargerGroups';
import { ChargersDetailsView, ChargersListView } from 'src/components/views/ExtendPlusView/Chargers';
import {
  CustomPlansCreateView,
  CustomPlansDetailsView,
  CustomPlansEditView,
  CustomPlansListView,
  CustomPlansPricingView,
} from 'src/components/views/ExtendPlusView/CustomPlans';
import { DashboardView } from 'src/components/views/ExtendPlusView/Dashboard';
import { HelpView } from 'src/components/views/ExtendPlusView/Help';
import { HelpArticleView } from 'src/components/views/ExtendPlusView/Help/Article';
import { HelpCategoryView } from 'src/components/views/ExtendPlusView/Help/HelpCategory';
import { ReportView } from 'src/components/views/ExtendPlusView/Reports';
import { SiteDetailsView, SitesListView } from 'src/components/views/ExtendPlusView/Sites';
import { HostsAndSitesView } from 'src/components/views/HostsAndSitesView';
import { HostView } from 'src/components/views/HostView';
import { PlansView } from 'src/components/views/PlansView';
import { PlanView } from 'src/components/views/PlanView';
import { PlanDriverRankView } from 'src/components/views/PlanDriverRankView';
import { SiteView } from 'src/components/views/SiteView';
import { StyleGuideView } from 'src/components/views/StyleGuideView';
import { DefaultRedirect } from './DefaultRedirect';
import { ExtendPlusRoute } from './ExtendPlusRoute';
import { PrivateRoute } from './PrivateRoute';

export interface Props {
  className?: string;
}

export const AppRoutes: React.FC<Props> = () => {
  const { pathname } = useLocation();

  // Restores scroll position when url pathname changes
  useEffect(() => {
    document.querySelector('main')?.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<DefaultRedirect />} />

      <Route path="/login" element={<LoginView />} />

      <Route path="/hosts" element={<Navigate to="/hosts/list" replace />} />
      <Route path="/hosts/list" element={<PrivateRoute component={HostsAndSitesView} />} />
      <Route path="/hosts/:altId/:section" element={<PrivateRoute component={HostView} />} />
      <Route path="/hosts/:altId" element={<PrivateRoute component={HostView} />} />

      <Route path="/hosts" element={<Navigate to="/hosts/list" replace />} />

      <Route path="/properties" element={<Navigate to="/properties/list" replace />} />
      <Route path="/properties/list" element={<PrivateRoute component={HostsAndSitesView} />} />

      <Route path="/sites" element={<Navigate to="/sites/list" replace />} />
      <Route path="/sites/list" element={<PrivateRoute component={HostsAndSitesView} />} />
      <Route path="/sites/new" element={<PrivateRoute component={SiteView} />} />
      <Route path="/sites/:altId/:section" element={<PrivateRoute component={SiteView} />} />
      <Route path="/sites/:altId" element={<PrivateRoute component={SiteView} />} />

      <Route path="/cards" element={<Navigate to="/cards/all" replace />} />
      <Route path="/cards/all" element={<PrivateRoute component={AuthenticatorsView} />} />
      <Route path="/cards/requested" element={<PrivateRoute component={AuthenticatorsView} />} />
      <Route path="/cards/import" element={<PrivateRoute component={AuthenticatorsView} />} />

      <Route path="/chargers" element={<Navigate to="/chargers/list" replace />} />
      <Route path="/chargers/list" element={<PrivateRoute component={ChargersView} />} />
      <Route path="/chargers/map" element={<PrivateRoute component={ChargersView} />} />
      <Route path="/chargers/new" element={<PrivateRoute component={ChargerView} />} />
      <Route path="/chargers/:altId/:tab" element={<PrivateRoute component={ChargerView} />} />
      <Route path="/chargers/:altId" element={<PrivateRoute component={ChargerView} />} />

      <Route path="/models" element={<Navigate to="/models/list" replace />} />
      <Route path="/models/list" element={<PrivateRoute component={ChargerModelsView} />} />
      <Route path="/models/new" element={<PrivateRoute component={ChargerModelView} />} />
      <Route path="/models/:altId/:section" element={<PrivateRoute component={ChargerModelView} />} />
      <Route path="/models/:altId" element={<PrivateRoute component={ChargerModelView} />} />

      <Route path="/drivers" element={<Navigate to="/drivers/list" replace />} />
      <Route path="/drivers/list" element={<PrivateRoute component={DriversView} />} />
      <Route path="/drivers/new" element={<PrivateRoute component={DriverView} />} />
      <Route path="/drivers/:altId/:tab" element={<PrivateRoute component={DriverView} />} />
      <Route path="/drivers/:altId" element={<PrivateRoute component={DriverView} />} />

      <Route path="/accounts" element={<Navigate to="/accounts/new" replace />} />
      {/* <Route path="/accounts/list" element={ AccountsView } <PrivateRoute component={/>} /> */}
      <Route path="/accounts/new" element={<PrivateRoute component={AccountView} />} />
      <Route path="/accounts/create" element={<PrivateRoute component={AccountCreate} />} />
      <Route path="/accounts/:altId/:section" element={<PrivateRoute component={AccountView} />} />
      <Route path="/accounts/:altId" element={<PrivateRoute component={AccountView} />} />

      <Route path="/plans" element={<Navigate to="/plans/list" replace />} />
      <Route path="/plans/list" element={<PrivateRoute component={PlansView} />} />
      <Route path="/plans/new" element={<PrivateRoute component={PlanView} />} />
      <Route path="/plans/:altId/:tab" element={<PrivateRoute component={PlanView} />} />
      <Route path="/plans/:altId" element={<PrivateRoute component={PlanView} />} />

      <Route path="/plans/display-rank" element={<PrivateRoute component={PlanDriverRankView} />} />

      <Route path="extend-plus">
        <Route index element={<Navigate to="/extend-plus/charger-prices" replace />} />

        <Route path="admin/user-management" element={<PrivateRoute component={UserManagementView} />} />
        <Route path="admin/host-management" element={<PrivateRoute component={HostManagementView} />} />

        <Route path="reports" element={<ExtendPlusRoute component={ReportView} />} />
        <Route path="dashboard" element={<ExtendPlusRoute component={DashboardView} />} />

        <Route path="sites">
          <Route index element={<ExtendPlusRoute component={SitesListView} />} />
          <Route path=":altId" element={<ExtendPlusRoute component={SiteDetailsView} />} />
        </Route>

        <Route path="chargers">
          <Route index element={<ExtendPlusRoute component={ChargersListView} />} />
          <Route path=":altId" element={<ExtendPlusRoute component={ChargersDetailsView} />} />
          <Route path=":altId/:tab" element={<ExtendPlusRoute component={ChargersDetailsView} />} />
        </Route>

        <Route path="charger-prices">
          <Route index element={<ExtendPlusRoute component={ChargerPricesListView} />} />
          <Route path="create" element={<ExtendPlusRoute component={ChargerPriceView} />} />
          <Route path=":altId/edit" element={<ExtendPlusRoute component={ChargerPriceView} />} />
          <Route path=":altId/details" element={<ExtendPlusRoute component={ChargerPricesDetailsView} />} />
          <Route path=":altId/pricing" element={<ExtendPlusRoute component={ChargerPricesPricingView} />} />
        </Route>

        <Route path="custom-plans">
          <Route index element={<ExtendPlusRoute component={CustomPlansListView} />} />
          <Route path="create" element={<ExtendPlusRoute component={CustomPlansCreateView} />} />
          <Route path=":altId/edit" element={<ExtendPlusRoute component={CustomPlansEditView} />} />
          <Route path=":altId/pricing" element={<ExtendPlusRoute component={CustomPlansPricingView} />} />
          <Route path=":altId/details" element={<ExtendPlusRoute component={CustomPlansDetailsView} />} />
        </Route>

        <Route path="help">
          <Route index element={<ExtendPlusRoute component={HelpView} />} />
          <Route path=":slug" element={<ExtendPlusRoute component={HelpArticleView} />} />
          <Route path="categories/:slug" element={<ExtendPlusRoute component={HelpCategoryView} />} />
        </Route>

        <Route path="charger-groups">
          <Route index element={<ExtendPlusRoute component={ChargerGroupsListView} />} />
          <Route path="create" element={<ExtendPlusRoute component={ChargerGroupDescriptionView} />} />
          <Route path=":altId/edit" element={<ExtendPlusRoute component={ChargerGroupDescriptionView} />} />
          <Route path=":altId/details" element={<ExtendPlusRoute component={ChargerGroupDetailsView} />} />
        </Route>
      </Route>

      <Route path="/styleguide" element={<PrivateRoute component={StyleGuideView} />} />
      <Route element={<ErrorView error="not-found" />} />
    </Routes>
  );
};
