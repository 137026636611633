import { useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import { SessionAggregation } from 'src/@types';
import { MetricCard, MetricCardGroup, MetricCardPlaceholder } from 'src/components/shared/MetricCard';
import { formatNumber } from 'src/lib/helpers//formatNumber';
import { useGetSessionsConsumptionForExtend } from 'src/lib/hooks/useGetSessionsConsumptionForExtend';
import { getMetricData } from './helpers';
import { dateRange } from '../helpers';

type LiveSessionMetricsProps = {
  timePeriod: number;
};

const testid = (value: string) => ({ 'data-testid': `live-session-card-metric-${value}` });

export const LiveSessionMetrics = ({ timePeriod }: LiveSessionMetricsProps) => {
  const input = {
    aggregateBy: SessionAggregation.Day,
    ...dateRange(timePeriod),
  };

  const { data, loading } = useGetSessionsConsumptionForExtend({ input });

  const metricData = useMemo(() => getMetricData(data), [data]);

  return (
    <Box mt={4}>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <MetricCardGroup id={testid('total-sessions-group')['data-testid']}>
            {loading ? (
              <MetricCardPlaceholder {...testid('network-loading-sessions')} />
            ) : (
              <MetricCard
                primaryText={metricData?.totalSessions || 0}
                tertiaryFeedbackText="TOTAL # OF CHARGING SESSIONS"
                {...testid('total-sessions')}
              />
            )}
          </MetricCardGroup>
        </Grid>
        <Grid item xs={4}>
          <MetricCardGroup id={testid('total-income-group')['data-testid']}>
            {loading ? (
              <MetricCardPlaceholder {...testid('network-loading-income')} />
            ) : (
              <MetricCard
                primaryText={`$${formatNumber(metricData?.totalIncome || 0)}`}
                tertiaryFeedbackText="TOTAL INCOME EARNED"
                {...testid('total-income')}
              />
            )}
          </MetricCardGroup>
        </Grid>
        <Grid item xs={4}>
          <MetricCardGroup id={testid('total-kWh-group')['data-testid']}>
            {loading ? (
              <MetricCardPlaceholder {...testid('network-loading-kWh')} />
            ) : (
              <MetricCard
                primaryText={`${formatNumber(metricData?.totalEnergy || 0)}`}
                tertiaryFeedbackText="TOTAL KWH DISPENSED"
                {...testid('total-kWh')}
              />
            )}
          </MetricCardGroup>
        </Grid>
      </Grid>
    </Box>
  );
};
