import { TariffCustomPricingForExtend, DefaultPricingForExtendInput, Maybe, PricingForExtend } from 'src/@types';
import { DisplayRank } from 'src/@types/shared';

export type FormPricing = {
  pricing: {
    altId?: string;
    perKwh: number | false;
    perSession: number | false;
    perMinute: number | false;
  };
  displayRank?: number;
  displayName?: string;
  displayDescription1?: string;
  displayDescription2?: string;
};

const transformPricing = (
  pricing: Maybe<Pick<PricingForExtend, 'perKwh' | 'perMinute' | 'perSession'>> | undefined,
) => {
  return {
    perKwh: pricing?.perKwh || pricing?.perKwh === 0 ? pricing?.perKwh : false,
    perMinute: pricing?.perMinute || pricing?.perMinute === 0 ? pricing?.perMinute : false,
    perSession: pricing?.perSession || pricing?.perSession === 0 ? pricing?.perSession : false,
  };
};

const transformTariffDefaultPricingForExtend = (defaultPricing: Maybe<DefaultPricingForExtendInput> | undefined) => {
  return {
    pricing: transformPricing(defaultPricing?.pricing),
    displayName: defaultPricing?.displayName,
    displayDescription1: defaultPricing?.displayDescription1,
    displayDescription2: defaultPricing?.displayDescription2,
    displayRank: defaultPricing?.displayRank || DisplayRank.NotDisplayed,
  } as FormPricing;
};

const transformTariffCustomPricingForExtend = (customPricing: TariffCustomPricingForExtend) => {
  return {
    altId: customPricing.altId,
    pricing: transformPricing(customPricing.pricing),
    displayName: customPricing?.displayName,
    displayDescription1: customPricing?.displayDescription1,
    displayDescription2: customPricing?.displayDescription2,
    displayRank: customPricing?.displayRank || DisplayRank.NotDisplayed,
    tariffSchedules: (customPricing.tariffSchedules || []).map((ts) => ({
      daysOfWeek: (ts?.daysOfWeek || []).map((d) => d),
      endTime: ts?.endTime || '',
      startTime: ts?.startTime || '',
    })),
  };
};

export { transformTariffDefaultPricingForExtend, transformTariffCustomPricingForExtend };
