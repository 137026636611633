import { subDays } from 'date-fns';
import { ListChargerGroupsForExtendQuery, Site } from 'src/@types';
import { DateRangeValue } from 'src/components/shared/DateRangeField';

type ChargerGroups = NonNullable<ListChargerGroupsForExtendQuery['listChargerGroupsForExtend']>['edges'];

export const initialDateRange = {
  startDate: subDays(new Date(), 1),
  endDate: subDays(new Date(), 1),
};

export type State = {
  initial: {
    sites: Site[];
    chargerGroupPrices: ChargerGroups;
    chargerGroupPlans: ChargerGroups;
    dateRange: Required<DateRangeValue>;
  };
  filter: {
    siteIds: string[];
    chargerGroupIds: string[];
    planIds: string[];
    dateRange: Required<DateRangeValue>;
    transactionTypes: string[];
  };
};

export const initialState: State = {
  initial: {
    sites: [],
    chargerGroupPlans: [],
    chargerGroupPrices: [],
    dateRange: initialDateRange,
  },
  filter: {
    siteIds: [],
    chargerGroupIds: [],
    planIds: [],
    dateRange: initialDateRange,
    transactionTypes: [],
  },
};

export type Action =
  | { type: 'setInitialSites'; sites: Site[] }
  | { type: 'setDateRange'; dateRange: DateRangeValue }
  | {
      type: 'setInitialChargerGroups';
      chargerGroups: ChargerGroups;
    }
  | {
      type: 'setInitialPlans';
      plans: ChargerGroups;
    }
  | {
      type: 'setSiteIds';
      siteIds: string[];
    }
  | {
      type: 'setPlanIds';
      planIds: string[];
    }
  | {
      type: 'setChargerGroupIds';
      chargerGroupIds: string[];
    }
  | {
      type: 'setTransactionTypes';
      transactionTypeIds: string[];
    }
  | { type: 'resetFilter' };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setInitialSites':
      return {
        ...state,
        initial: {
          ...state.initial,
          sites: action.sites,
        },
      };
    case 'setInitialChargerGroups':
      return {
        ...state,
        initial: {
          ...state.initial,
          chargerGroupPrices: action.chargerGroups,
        },
      };
    case 'setInitialPlans':
      return {
        ...state,
        initial: {
          ...state.initial,
          chargerGroupPlans: action.plans,
        },
      };
    case 'setSiteIds':
      return {
        ...state,
        filter: {
          ...state.filter,
          siteIds: action.siteIds,
        },
      };
    case 'setPlanIds':
      return {
        ...state,
        filter: {
          ...state.filter,
          planIds: action.planIds,
        },
      };
    case 'setChargerGroupIds':
      return {
        ...state,
        filter: {
          ...state.filter,
          chargerGroupIds: action.chargerGroupIds,
        },
      };
    case 'setTransactionTypes':
      return {
        ...state,
        filter: {
          ...state.filter,
          transactionTypes: action.transactionTypeIds,
        },
      };
    case 'setDateRange':
      return {
        ...state,
        filter: {
          ...state.filter,
          dateRange: action.dateRange as Required<DateRangeValue>,
        },
      };
    case 'resetFilter':
      return {
        ...state,
        filter: {
          ...initialState.filter,
        },
      };
    default:
      throw new Error();
  }
};
