import { gql } from '@apollo/client';
import { TariffForExtend } from './fragments';

export const listTariffsForExtend = gql`
  query listTariffsForExtend($input: ListTariffsForExtendInput!) {
    listTariffsForExtend(input: $input) {
      edges {
        ...TariffForExtend
      }
      total
    }
  }
  ${TariffForExtend}
`;
