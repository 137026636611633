import { gql } from '@apollo/client';
import { ChargerGroupForExtend } from './fragments';

export const listChargerGroupsForExtend = gql`
  query listChargerGroupsForExtend($input: ListChargerGroupsForExtendInput!) {
    listChargerGroupsForExtend(input: $input) {
      edges {
        ...ChargerGroupForExtend
        chargers: chargersLoaded {
          edges {
            altId
          }
          total
        }
      }
      total
    }
  }
  ${ChargerGroupForExtend}
`;
