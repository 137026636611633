import { TariffItemReservationTermForExtendInput, Maybe } from 'src/@types';

export type ReservationPricing = {
  fee: number | false;
  noShowFee: number | false;
};

const transformReservationPricingForExtend = (
  reservationPricing: Maybe<TariffItemReservationTermForExtendInput> | undefined,
) => {
  const enabled = reservationPricing?.fee != undefined && reservationPricing?.noShowFee != undefined;

  return {
    fee: reservationPricing?.fee == null ? false : reservationPricing.fee,
    noShowFee: reservationPricing?.noShowFee == null ? false : reservationPricing.noShowFee,
    enabled,
  } as ReservationPricing;
};

const transformReservationCustomPricingForExtend = (
  reservationPricing: Maybe<TariffItemReservationTermForExtendInput> | undefined,
) => {
  return transformReservationPricingForExtend(reservationPricing);
};

export { transformReservationPricingForExtend, transformReservationCustomPricingForExtend };
