import { gql } from '@apollo/client';

export const getChargerGroupsForChargerPrice = gql`
  query getChargerGroupsForChargerPrice($input: GetTariffForExtendInput!) {
    getTariffForExtend(input: $input) {
      chargerGroups {
        altId
        chargerGroupName
      }
    }
  }
`;
