import { gql } from '@apollo/client';

export const getSitesForExtend = gql`
  query GetSitesForExtend {
    getSitesForExtend {
      altId
      siteName
      siteStatusId
      address1
      address2
      locality
      administrativeArea
      postalCode
      country
      evseCount
      chargers {
        edges {
          altId
          chargerName
        }
        total
      }
    }
  }
`;
