import { Formik } from 'formik';
import React, { useState } from 'react';
import { useFormikSubmit } from 'src/lib/hooks';
import { syncUser } from '../../../../apollo/queries/accounts';
import { AccountSyncModalForm, FormValues, initialValues } from './AccountSyncModalForm';
import * as Yup from 'yup';

interface Props {
  open?: boolean;
  className?: string;
}

export const AccountSyncModal: React.FC<Props> = (props) => {
  const transformValues = (values: FormValues) => ({
    setPhoneNumber: values.setPhoneNumber,
    email: values.email,
  });
  const [shouldVerifyPhone, setShouldVerifyPhone] = useState(false);

  const handleSubmit = useFormikSubmit('AccountSync', syncUser, syncUser, {
    noRedirect: true,
    callback: () => {
      setTimeout(() => {}, 2500); // introducing a slight delay to allow user to read the success message.
      window.location.reload();
    },
    transformValues,
    initialValues,
  }) as never;

  const validationSchema = Yup.object({
    setPhoneNumber: Yup.string()
      .matches(/^(\d{3})(\d{3})(\d{4})$/, '* Must be a valid 10-digit phone number.')
      .required('* A phone number is required.'),
  });

  return (
    <Formik
      initialStatus={initialValues}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange
      validationSchema={!!shouldVerifyPhone ? validationSchema : undefined}
    >
      <AccountSyncModalForm
        open={props.open}
        shouldVerifyPhone={shouldVerifyPhone}
        setShouldVerifyPhone={setShouldVerifyPhone}
      />
    </Formik>
  );
};
