import { Box } from '@material-ui/core';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ChargerGroupForExtend,
  useGetTariffForExtendQuery,
  useCreateTariffForExtendMutation,
  useUpdateTariffForExtendMutation,
} from 'src/@types';
import { BreadcrumbLink, Breadcrumbs, BreadcrumbText } from 'src/components/shared/Breadcrumbs';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { SubTitle } from 'src/components/shared/SubTitle';
import { Title } from 'src/components/shared/Title';
import { useSnackbar } from 'src/lib/hooks';
import { ChargerPriceForm, FormValues } from '../shared/ChargerPriceForm';
import { validationSchema } from '../shared/ChargerPriceForm/validations';
import { formatErrorMessage } from '../utils/formatErrorMessage';
import { ChargerPriceStepper, Step } from '../ChargerPriceStepper';

type Param = { altId: ChargerGroupForExtend['altId'] };

export const ChargerPriceView: React.FC = () => {
  const id = 'charger-price';
  const snackbar = useSnackbar();
  const { altId = '' } = useParams<Param>();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const isNew = Boolean(queryParams.get('new'));

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: '',
    displayName: '',
    chargerGroupIds: [] as string[],
  });

  const variables = {
    input: { altId },
  };
  const { loading } = useGetTariffForExtendQuery({
    variables,
    fetchPolicy: 'cache-and-network',
    onError: (error) => snackbar.error(error?.message || 'Something went wrong.'),
    onCompleted: ({ getTariffForExtend }) => {
      if (getTariffForExtend) {
        const chargerGroupIds = (getTariffForExtend.chargerGroups || []).map((group) => group?.altId);
        setInitialValues({
          name: getTariffForExtend.tariffName,
          displayName: getTariffForExtend.tariffDisplayName,
          chargerGroupIds: chargerGroupIds as string[],
        });
      } else {
        snackbar.error('Charger Price not found.');
      }
    },
    skip: !altId,
  });

  const [createChargerPrice] = useCreateTariffForExtendMutation();
  const [updateChargerPrice] = useUpdateTariffForExtendMutation();

  const handleSubmit = (formValues: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    const mutation = isNew && !altId ? createChargerPrice : updateChargerPrice;
    let input: { name: string; displayName: string; chargerGroupIds: string[]; altId?: string } = {
      ...formValues,
    };
    if (altId) input = { ...input, altId };
    mutation({
      variables: {
        input,
      },
      onError: (error) => {
        snackbar.error(formatErrorMessage({ message: error?.message, altId }));
        resetForm({ values: formValues });
      },
      onCompleted: (res) => {
        snackbar.success('Your Charger Price has been saved.');
        if (!isNew) navigate(`/extend-plus/charger-prices/${altId}/details`);
        else
          navigate(
            `/extend-plus/charger-prices/${
              'createTariffForExtend' in res ? res?.createTariffForExtend?.altId : altId
            }/pricing?new=true`,
          );
      },
    });
  };

  return (
    <PageContent
      loading={loading}
      hasFloatingPageActions
      breadcrumbs={
        !isNew && (
          <Breadcrumbs>
            <BreadcrumbLink data-testid={`${id}-charger-price-list-link`} to="/extend-plus/charger-prices">
              Charger Prices
            </BreadcrumbLink>
            {initialValues.name && (
              <Breadcrumbs>
                <BreadcrumbLink
                  data-testid={`${id}-charger-price-details-link`}
                  to={`/extend-plus/charger-prices/${altId}/details`}
                >
                  {initialValues.name}
                </BreadcrumbLink>
                <BreadcrumbText data-testid={`${id}-price-details-breadcrumb`}>Pricing Details</BreadcrumbText>
              </Breadcrumbs>
            )}
          </Breadcrumbs>
        )
      }
      pageHeader={
        <PageHeader
          childrenLeft={
            <Box className="page-header-children">
              <Title>Charger Price Details</Title>
              <SubTitle color="textPrimary">Manage and apply pricing for your charger groups.</SubTitle>
            </Box>
          }
        />
      }
    >
      {isNew && <ChargerPriceStepper currentStep={Step.Details} />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <ChargerPriceForm isNew={isNew} altId={altId} />
      </Formik>
    </PageContent>
  );
};
