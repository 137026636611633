import { useListChargersConsumptionOnlyStatusForExtendQuery, SessionDatePreset } from 'src/@types';
import { ArrowForward, OfflineBolt } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { ConnectorStatusId } from 'src/@types/shared';
import { BuildCircleIcon } from 'src/components/svgs';
import { MetricCard, MetricCardPlaceholder } from 'src/components/shared/MetricCard';
import { LabelDiv } from '../styles';

type ConnectorStatusMetricsProps = {
  siteIds?: string[];
};

const testid = (value: string) => ({ 'data-testid': `metric-card-connector-status-${value}` });

export const ConnectorStatusMetrics = ({ siteIds }: ConnectorStatusMetricsProps) => {
  const { data, loading } = useListChargersConsumptionOnlyStatusForExtendQuery({
    variables: {
      input: {
        siteIds,
        datePreset: SessionDatePreset.Today,
      },
    },
    skip: !siteIds?.length,
    pollInterval: 5000,
  });

  const chargersConsumptionData = data?.listChargersConsumptionForExtend || [];
  const offlineChargers = chargersConsumptionData.filter((charger) => charger.connectorStatus === 'Unavailable');
  const connectorsInUse = chargersConsumptionData.filter((charger) => charger.connectorStatus === 'Charging');

  const OfflineMetricLabel = offlineChargers.length ? (
    <Link to={`/extend-plus/chargers?statusFilter=${ConnectorStatusId.Unavailable}`}>
      <LabelDiv>
        <span>VIEW LIST OF CHARGERS</span>
        <ArrowForward />
      </LabelDiv>
    </Link>
  ) : null;

  if (loading)
    return (
      <>
        <MetricCardPlaceholder {...testid('charging-loading')} />
        <MetricCardPlaceholder {...testid('offline-loading')} />
      </>
    );

  return (
    <>
      <MetricCard
        icon={<OfflineBolt />}
        primaryText={connectorsInUse.length || 0}
        secondaryText="In Use"
        label="Currently Charging"
        {...testid('charging')}
      />

      <MetricCard
        icon={<BuildCircleIcon />}
        primaryText={offlineChargers.length || 0}
        secondaryText="Offline"
        label={OfflineMetricLabel}
        {...testid('offline')}
      />
    </>
  );
};
