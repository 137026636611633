import { gql } from '@apollo/client';
import { PlanTariffTag } from './fragments';

export const listChargerGroupsPlanTariffTags = gql`
  query listChargerGroupsPlanTariffTagsForExtend($input: ListChargerGroupsForExtendInput!) {
    listChargerGroupsForExtend(input: $input) {
      edges {
        altId
        chargerGroupName
        planTariffTags {
          ...PlanTariffTag
        }
      }
    }
  }
  ${PlanTariffTag}
`;
