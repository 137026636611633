import { track } from '@amplitude/analytics-browser';
import { theme } from '@evgo/react-material-components';
import { Box } from '@material-ui/core';
import React from 'react';
import { ChargerGroupForExtend, useGetChargerGroupPlansForExtendQuery } from 'src/@types';
import { Card } from 'src/components/shared/Card';
import { TableLink } from 'src/components/shared/TableLink';
import { events } from 'src/lib/utils/analytics-events';
import styled from 'styled-components';
import { CustomPlanPriceGroupsList } from './CustomPlanPriceGroupsList';

export const StyledArticle = styled.article`
  margin-top: ${theme.spacing(4)}px;
  .table-container > h6 {
    text-align: right;
  }
`;

type Props = {
  altId: ChargerGroupForExtend['altId'];
};

export const CustomPlansListView: React.FC<Props> = ({ altId }) => {
  const { data, loading, error } = useGetChargerGroupPlansForExtendQuery({
    variables: {
      input: { altId },
    },
    fetchPolicy: 'cache-and-network',
  });
  const { planTariffTags } = data?.getChargerGroupForExtend || { planTariffTags: [] };
  const plans = planTariffTags?.flatMap((pT) => (pT?.planChargerGroup ? pT.planChargerGroup : [])) || [];

  return plans.length ? (
    <StyledArticle>
      <Card className="table-container">
        <CustomPlanPriceGroupsList data={plans} loading={loading} error={error} />
      </Card>
    </StyledArticle>
  ) : (
    <Box mt={4}>
      <Card>
        <p>No plans on this charger group.</p>
        <br />
        <TableLink
          to={'/extend-plus/help/create-custom-plan'}
          onClick={() => track(events.chargerGroupCustomPlansTab.CLICKED_CUSTOM_PLAN_LEARN_MORE)}
        >
          Learn more
        </TableLink>{' '}
        or add this charger group to a plan{' '}
        <TableLink
          onClick={() => track(events.chargerGroupCustomPlansTab.CLICKED_ADD_CHARGER_GROUP_TO_PLAN)}
          to={'/extend-plus/custom-plans'}
        >
          here
        </TableLink>
      </Card>
    </Box>
  );
};
