import { Box, Button, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import React, { useEffect, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useListChargersV2Query } from 'src/@types';
import { Card } from 'src/components/shared/Card';
import { PageContent } from 'src/components/shared/PageContent';
import { PageHeader } from 'src/components/shared/PageHeader';
import { SubTitle } from 'src/components/shared/SubTitle';
import { Title } from 'src/components/shared/Title';
import { events } from 'src/lib/utils/analytics-events';
import { ChargerGroupsList } from './ChargerGroupsList';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { ChargerGroupsListFilter } from './ChargerGroupsList/ChargerGroupsListFilter';
import { reducer, initialState, chargerGroupTypes } from './filterHelpers';
import config from 'src/config';

type Search = string | undefined;

export const ChargerGroupsListView: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const id = 'charger-groups';
  const [search, setSearch] = React.useState<Search>('');
  const handleSetSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(value);
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => track(events.chargerGroupsList.SEARCHED_CHARGER_GROUP_LIST), 1000);
  };

  useEffect(() => {
    dispatch({ type: 'setInitialChargerGroupTypes', chargerGroupTypes });
  }, []);

  const unassignedChargersInput = {
    pageSize: config.maxPageSize,
    filter: {
      chargerGroupName: { eq: null },
    },
  };
  const { data } = useListChargersV2Query({
    variables: {
      input: unassignedChargersInput,
    },
    fetchPolicy: 'cache-and-network',
  });

  const totalCount = data?.listChargersV2?.total || 0;

  return (
    <PageContent
      pageHeader={
        <PageHeader
          childrenLeft={
            <>
              <Title>Charger Groups</Title>
              <SubTitle color="textPrimary">List of your charger groups</SubTitle>
            </>
          }
        />
      }
    >
      <article>
        <Card className="table-container">
          <Box pb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <Box marginRight={2}>
                <ChargerGroupsListFilter state={state} dispatch={dispatch} />
              </Box>
              <Box marginRight={2}>
                <TextField
                  value={search}
                  onChange={handleSetSearch}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <Box marginRight={1} display="flex" alignItems="center" justifyContent="center">
                        <SearchIcon />
                      </Box>
                    ),
                  }}
                  variant="outlined"
                  size="small"
                  data-testid={`${id}-search-textfield`}
                />
              </Box>

              <Button
                onClick={() => {
                  navigate('/extend-plus/charger-groups/create');
                  track(events.chargerGroupsList.CLICKED_ADD_CHARGER_GROUPS);
                }}
                variant="contained"
                color="secondary"
                size="large"
                data-testid={`${id}-add-groups-button`}
              >
                Add Charger Groups
              </Button>
            </Box>

            <SubTitle>You have {totalCount} chargers without prices</SubTitle>
          </Box>
          <ChargerGroupsList search={search} filter={state.filter} />
        </Card>
      </article>
    </PageContent>
  );
};
