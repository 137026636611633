import { gql } from '@apollo/client';
import { ChargerGroupForExtend } from './fragments';

export const getChargerGroupPlansForExtend = gql`
  query getChargerGroupPlansForExtend($input: GetChargerGroupForExtendInput!) {
    getChargerGroupForExtend(input: $input) {
      planTariffTags {
        planChargerGroup {
          ...ChargerGroupForExtend
        }
      }
    }
  }
  ${ChargerGroupForExtend}
`;
