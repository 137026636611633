/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, theme } from '@evgo/react-material-components';
import { Button, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { events } from 'src/lib/utils/analytics-events';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { useFormikSubmit } from '../../../../lib/hooks';
import { auth } from '../../../../firebase';
import { Styled } from './styles';
import { validations as validationSchema } from './LoginForm/validations';
import { login } from 'src/apollo/queries/login';
import { LoginForm } from './LoginForm';
import styled from 'styled-components';

const initialValues = { username: '', password: '' };
const StyledContactButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing(7)}px;
`;

export const LoginView: React.FC = () => {
  const className = 'LoginView';
  const location = useLocation();
  const [currentUser, firebaseLoading] = useAuthState(auth);
  const { track } = useAnalytics();

  const handleSubmit = useFormikSubmit('Login', login, login, { snackVisible: false });

  if (firebaseLoading) return null;
  if (currentUser) return <Navigate to={(location?.state as any)?.from || '/'} />;

  return (
    <Styled className={className}>
      <header className={className}>
        <Typography className={className} component="h1" variant="h6">
          Login
        </Typography>
      </header>

      <Divider />

      <section className={className}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          <LoginForm className={className} />
        </Formik>
        <StyledContactButtonContainer>
          <Button
            onClick={() => {
              track(events.helpAndSupport.CLICKED_HELP_COSTUMER_SUPPORT_BUTTON);
            }}
            data-testid={'customer-support-button'}
            variant="outlined"
            color="secondary"
            href={'https://evgoteam.atlassian.net/servicedesk/customer/portal/18'}
          >
            Contact Customer Support
          </Button>
        </StyledContactButtonContainer>
      </section>
    </Styled>
  );
};
