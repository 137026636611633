import moment from 'moment-timezone';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';
import { TariffCustomPricesForExtend } from 'src/@types';
import { cardPadding } from '../helpers';

type PriceCardContentProps = {
  color: string;
  name: string;
  startDay: string;
  endDay: string;
  startTime: string;
  endTime: string;
  pricing: TariffCustomPricesForExtend;
};

const StyledP = styled.p<{ color: string; size?: number; weight?: number; mb?: number }>`
  color: ${(p) => p.color};
  font-weight: ${(p) => p.weight};
  font-size: ${(p) => p.size || theme.typography.fontSize}px;
  margin-bottom: ${(p) => (p?.mb ? theme.spacing(p.mb) : 0)}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledUl = styled.ul<{ color: string; size?: number }>`
  margin-top: ${theme.spacing(1)}px;
  padding-left: ${theme.spacing(3)}px;
  color: ${(p) => p.color};
  font-size: ${(p) => p.size || theme.typography.fontSize}px;
  li {
    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const PriceCardContent = ({
  color,
  name,
  startDay,
  endDay,
  startTime,
  endTime,
  pricing,
}: PriceCardContentProps) => {
  const startTimeFormated = moment(startTime, ['HH:mm']).format('h:mm A');
  const endTimeFormated = moment(endTime, ['HH:mm']).format('h:mm A');

  return (
    <Box
      marginX={`${cardPadding * 2}px`}
      marginY={`${cardPadding}px`}
      overflow="hidden"
      height={`calc(100% - ${cardPadding * 2}px)`}
    >
      <StyledP color={color} weight={theme.typography.fontWeightBold * 0.9} mb={1}>
        {name}
      </StyledP>
      <StyledP color={color} size={theme.typography.fontSize * 0.9}>{`${startDay} - ${endDay}`}</StyledP>
      <StyledP
        color={color}
        size={theme.typography.fontSize * 0.9}
      >{`${startTimeFormated} - ${endTimeFormated} local timezone`}</StyledP>
      <StyledUl color={color} size={theme.typography.fontSize * 0.9}>
        {pricing.perKwh && (
          <li>
            <p>per kWh: ${pricing.perKwh}</p>
          </li>
        )}
        {pricing.perMinute && (
          <li>
            <p>per minute: ${pricing.perMinute}</p>
          </li>
        )}
        {pricing.perSession && (
          <li>
            <p>per session: ${pricing.perSession}</p>
          </li>
        )}
      </StyledUl>
    </Box>
  );
};
