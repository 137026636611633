import moment from 'moment-timezone';
import { DateRange, UtilizationChartTab } from 'src/components/shared/UtilizationBarChartCard';
import {
  Maybe,
  SessionAggregation,
  SessionsConsumptionForExtendData,
  GetSessionsConsumptionForExtendResponse,
} from 'src/@types';
import { BarChartData } from '../UtilizationBarGraph';

function getValueForTab(data: SessionsConsumptionForExtendData, tab: UtilizationChartTab): number {
  switch (tab) {
    case UtilizationChartTab.kWh:
      return data.powerValue as number;
    case UtilizationChartTab.Income:
      return data.powerCostValue as number;
  }

  throw new Error(`Invalid tab (${tab})`);
}

function getLabel(timestamp: string, aggregateBy: SessionAggregation) {
  const date = moment(timestamp).tz('UTC');

  switch (aggregateBy) {
    case SessionAggregation.Hour:
      return date.format('hh a');
    case SessionAggregation.Day:
      return date.format('ddd MM/DD');
    case SessionAggregation.Week:
      const startsAt = date.startOf('week');
      const endsAt = date.endOf('week');
      return `${startsAt.format('MM/DD/YY')} - ${endsAt.format('MM/DD/YY')}`;
    case SessionAggregation.Month:
      return date.format('MMM YYYY');
    default:
      throw new Error(`Invalid aggregateBy (${aggregateBy})`);
  }
}

export function createChartData(
  tab: UtilizationChartTab,
  aggregateBy: SessionAggregation,
  data: Maybe<GetSessionsConsumptionForExtendResponse> | undefined,
): BarChartData[] {
  const timeSeries = data?.timeSeries || [];

  return timeSeries.map((value) => ({
    label: getLabel(value?.timestamp as string, aggregateBy),
    value: getValueForTab(value as SessionsConsumptionForExtendData, tab),
  }));
}

export function getDateRange(data?: Maybe<GetSessionsConsumptionForExtendResponse>): DateRange {
  const timeSeries = data?.timeSeries || [];
  const dates = timeSeries.map((row) => moment(row?.timestamp as string).tz('UTC'));

  return {
    startDate: moment.min(dates).format('MM/DD/YY'),
    endDate: moment.max(dates).format('MM/DD/YY'),
  };
}

export function getCaptionText(aggregateBy: SessionAggregation) {
  switch (aggregateBy) {
    case SessionAggregation.Day:
      return 'Total consumption by day';
    case SessionAggregation.Week:
      return 'Total consumption by week';
    case SessionAggregation.Month:
      return 'Total consumption by month';
    default:
      return 'Total consumption by hour';
  }
}
