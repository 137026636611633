/* eslint-disable @typescript-eslint/no-explicit-any */
import { signInWithEmailAndPassword } from 'src/firebase';
import { Mutation, MutationLoginArgs } from 'src/@types';

export const login = {
  Mutation: {
    login: async (parent: any, { input }: MutationLoginArgs): Promise<Mutation['login']> => {
      try {
        const { username, password } = input;
        await signInWithEmailAndPassword(username, password);
      } catch (err) {
        throw new Error('Invalid login.');
      }
    },
  },
};
