import { gql } from '@apollo/client';

export const getSessionsConsumptionForExtend = gql`
  query GetSessionsConsumptionForExtend($input: GetSessionsConsumptionForExtendInput!) {
    getSessionsConsumptionForExtend(input: $input) {
      timeSeries {
        totalSessions
        powerCostValue
        powerValue
        timestamp
      }
    }
  }
`;
