import { gql } from '@apollo/client';
import { ChargerGroupForExtend, ChargersWithMeta, PlanTariffForExtend, TariffForExtend } from './fragments';

export const updateChargerGroupPlanForExtend = gql`
  mutation updateChargerGroupPlanForExtend($input: UpdateChargerGroupPlanForExtendInput!) {
    updateChargerGroupPlanForExtend(input: $input) {
      ...ChargerGroupForExtend
      tariff {
        ...TariffForExtend
      }
      planTariffs {
        ...PlanTariffForExtend
      }
      chargers {
        ...ChargersWithMeta
      }
    }
  }
  ${ChargerGroupForExtend}
  ${TariffForExtend}
  ${PlanTariffForExtend}
  ${ChargersWithMeta}
`;
