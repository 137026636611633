import React, { Fragment } from 'react';
import { Formik } from 'formik';
import { Styled } from './styles';
import { TariffCustomPricingForExtend } from 'src/@types';
import { Box } from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import { CustomPricingInput } from 'src/components/shared/CustomPricingInput';
import { Card, CardHeader } from 'src/components/shared/Card';
export interface Props {
  customPricing?: Array<TariffCustomPricingForExtend>;
  loading?: boolean;
  error?: ApolloError | undefined;
}

export const TimeSpecificPriceSection: React.FC<Props> = ({ customPricing }) => {
  return (
    <Styled>
      <Card>
        <CardHeader title="Time Of Use Prices" subtitle="Prices defined for specific time frames" />
        {customPricing?.length ? (
          <Formik initialValues={{ customPricing }} onSubmit={() => {}} enableReinitialize>
            <Fragment>
              {customPricing.map((item, index) => (
                <CustomPricingInput
                  showHmiDisplay={true}
                  index={index}
                  title={`Time of use price ${index + 1}`}
                  name={`customPricing.${index}`}
                  readonly={true}
                />
              ))}
            </Fragment>
          </Formik>
        ) : (
          <Box display="flex" justifyContent="space-between" paddingTop={2} width={520}>
            <span>N/A</span>
          </Box>
        )}
      </Card>
    </Styled>
  );
};
